export const GET_USERS = '@ocr/getAllDocument';
export const GET_USERS_SUCCESS = '@ocr/getUsersSuccessDocument';
export const GET_USERS_ERROR = '@ocr/getUsersErrorDocument';
export const LOAD_MENU = '@ocr/loadMenuDocument';
export const LOAD_MENU_SUCCES = '@ocr/loadMenuSucessDocument';
export const COMPANY_DATA_SUCCESS = '@ocr/companyDataSucessDocument';
export const COMPANY_DATA = '@ocr/companyDataDocument';
export const UPDATE_FIELD_ATTRIBUTE = '@ocr/updpateAttribute';
export const DATA_DOC_CARGADOS = '@ocr/dataDocCargados';
export const DATA_DOC_CARGADOS_SUCESS = '@ocr/dataDocCargadosSuccess';

export const LINE_CHART = '@ocr/lineChart';
export const LINE_CHART_SUCESS = '@ocr/lineChartSuccess';



export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];
