import {
  UPDATE_FIELD_ATTRIBUTE,
  GUARDAR_ENVIO_SUCCES,

  REMOVE_FILE,
  SEND_ENVIO_SUCCES,
  LIST_ENVIO_SUCCES,
  LOAD_CSV_SUCCES,
  LIST_USERS_SUCCES,
  IR_NUEVO,
  VER_ENVIO_SUCCES,
  EDITAR_ENVIO,
  LIMPIAR_FORM,
  BORRAR_ENVIO_SUCCESS,
  BORRAR_ENVIO,
  TOTAL_ENVIOS,
  TOTAL_ENVIOS_SUCCESS,
  TOTAL_MENSAJES_LEIDOS_SUCCESS,
  CAMPOS_PLANTILLA_SUCCESS,
  ARCHIVOS_ADJUNTOS_SUCCESS,
  DISABLE_CHOOSE_BUTTON,
  GUARDAR_ENVIO,
  LIST_ENVIO,
  PROM_TIEMPO_LECTURA_SUCCESS,
  TOTAL_MENSAJES_LEIDOS,
  ARCHIVOS_ADJUNTOS,
  TOTAL_DESTINATARIOS_SUCCESS,
  FILTRAR_DESTINATARIOS_SUCCESS,
  FILTRAR_DESTINATARIOS,
  TOTAL_LEIDOS,
  TOTAL_LEIDOS_SUCESS,
  ENVIOS_INDIVIDUALES_SUCCESS,
  PAGINAR_ENVIOS_INDIVIDUALES_SUCCESS,
  PAGINAR_ENVIOS_INDIVIDUALES,
  FILTRAR_ENVIOS_API_SUCCESS,
  FILTRAR_ENVIOS_API,
  DESCARGAR_CSV_ENVIOS_SUCCESS,
  CONTAR_LEADS_POR_ENVIO,
  SELECT_CONVERSATION_DETAIL_SUCCESS,
  CLOSE_MODAL_CONVERSATION_DETAIL_ENVIOS
} from './constants';

export const initialState = {
  selectedForm: {},
  enviosForm: {},
  envios: false,
  formFiles: [],
  csv: [],
  totalCsv: false,
  envio: false,
  toastMensaje: false,
  toastDetail: false,
  usuarios: [],
  ocultarDialogo: false,
  updateTable: false,
  totalEnviados: false,
  totalLeidos: false,
  totalNoLeidos: false,
  porcentajeLeidos: false,
  porcentajeLeads: false,
  numeroLeads: false,
  opcionesPlantillaCombo: false,
  parametrosArchivo: false,
  archivoSeleccionado: false,
  loadingUsuarios: false,
  numeroDestinatarios: false,
  verLista: false,
  leadsPorEnvio: 0,
  promedioLectura: false,
  numFailed: false,
  loadingArchivo: false,
  totalDestinatarios: false,
  busquedaActivada: false,
  totalLeidosEnvio: false,
  enviosApi: false,
  consultaPaginada: false,
  enviosApiPaginados: false,
  busquedaEnviosApiActiva: false,
  listaEnviosApiFiltrada: false,
  dataCsvDestinatarios: false,
  convDataEnvio: false,
  openModalEnvioDetail: false,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;
  const { enviosApi } = payload ? payload : {};
  newState.toastMensaje = false;
  newState.sessionTimeout = false;
  newState.ocultarDialogo = false;
  newState.consultaPaginada = true;


  switch (type) {
    case UPDATE_FIELD_ATTRIBUTE:
      const { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;
      newState.selectedForm = newFormObject;
      newState.message = false;


      return {
        ...newState
      };

    case ENVIOS_INDIVIDUALES_SUCCESS:
      newState.enviosApi = payload.enviosApi;

      return {
        ...newState
      }

    case PAGINAR_ENVIOS_INDIVIDUALES:
      newState.consultaPaginada = false;
      return {
        ...newState
      }

    case PAGINAR_ENVIOS_INDIVIDUALES_SUCCESS:
      if (payload.esPrimeraPagina) {
        newState.enviosApiPaginados = enviosApi;
      } else {
        newState.enviosApiPaginados = payload.enviosApiPaginados;
      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case FILTRAR_ENVIOS_API_SUCCESS:
      newState.enviosApi = payload.enviosApi;
      console.log('enviosApi', payload.enviosApi);
      newState.listaEnviosApiFiltrada = payload.listaFiltradaCompleta;
      if (payload.textInput !== "") {
        newState.busquedaEnviosApiActiva = true;
      } else {
        newState.busquedaEnviosApiActiva = false;

      }
      return {
        ...newState
      }

    case FILTRAR_ENVIOS_API:
      newState.busquedaEnviosApiActiva = true;
      return {
        ...newState
      }
    case GUARDAR_ENVIO_SUCCES:
      newState.envio = payload.envio;
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;
      newState.loadingUsuarios = false;
      newState.ocultarDialogo = true;
      newState.parametrosArchivo = false;

      newState.updateTable = true;
      return {
        ...newState
      };

    case GUARDAR_ENVIO:
      console.log('ENTRO A GUARDAR_ENVIO');
      newState.loadingUsuarios = {
        "loading": true,
        "text": "Cargando los registros, por favor no cierre esta ventana hasta que se complete el cargue, este proceso puede tardar varios minutos según el número de registros en el archivo CSV"
      };

      return {
        ...newState
      }

    case BORRAR_ENVIO_SUCCESS:

      newState.envio = false;
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;
      newState.ocultarDialogo = payload.ocultarModalDialogo;
      newState.updateTable = true;
      newState.verLista = true;

      return {
        ...newState
      };
    case CAMPOS_PLANTILLA_SUCCESS:
      newState.opcionesPlantillaCombo = payload;
      return {
        ...newState
      };
    case BORRAR_ENVIO:
      newState.envio = false;

      return {
        ...newState
      };
    case VER_ENVIO_SUCCES:
      newState.envio = payload.envio;
      newState.verLista = false;
      return {
        ...newState
      };



    case CLOSE_MODAL_CONVERSATION_DETAIL_ENVIOS:
      newState.openModalEnvioDetail = false;
      return {
        ...newState
      }

    case SELECT_CONVERSATION_DETAIL_SUCCESS:
      console.log("SELECT", payload)
      newState.convDataEnvio = payload;
      newState.openModalEnvioDetail = true;
      return {
        ...newState
      }

    case CONTAR_LEADS_POR_ENVIO:
      newState.leadsPorEnvio = payload;
      return {
        ...newState
      };

    case IR_NUEVO:
      newState.envio = false;
      return {
        ...newState
      };
    case SEND_ENVIO_SUCCES:
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;
      newState.verLista = false;
      return {
        ...newState
      };

    case LIST_ENVIO:
      newState.updateTable = false;
      newState.loadingUsuarios = { "loading": true, "text": "Cargando tabla de envíos, un momento por favor..." }
      newState.verLista = true;
      newState.totalDestinatarios = false;
      newState.usuarios = false;
      newState.totalLeidosEnvio = false;
      return {
        ...newState
      };

    case LIST_ENVIO_SUCCES:
      newState.loadingUsuarios = false;
      newState.envios = payload.envios;

      return {
        ...newState
      };
    case TOTAL_ENVIOS_SUCCESS:
      newState.totalEnviados = payload.numeroEnviados;
      newState.loadingUsuarios = false;

      return {
        ...newState
      }
    case TOTAL_ENVIOS:
      newState.loadingUsuarios = { "loading": true, "text": "Cargando la gráfica por favor espere un momento..." };
      return {
        ...newState
      }
    case TOTAL_MENSAJES_LEIDOS:
      newState.loadingUsuarios = { "loading": true, "text": "Cargando la gráfica por favor espere un momento..." };
      return {
        ...newState
      }

    case TOTAL_MENSAJES_LEIDOS_SUCCESS:
      console.log(payload)
      newState.totalLeidos = payload.numeroLeidos;
      newState.totalNoLeidos = payload.numeroNoLeidos;
      newState.porcentajeLeidos = payload.porcentajeLeidos;
      newState.numFailed = payload.numFailed;
      newState.porcentajeLeads = payload.porcentajeLeads;
      newState.numeroLeads = payload.numeroLeads;
      newState.loadingUsuarios = { "loading": false, "text": "Cargando la gráfica por favor espere un momento..." };
      return {
        ...newState
      }

    case LOAD_CSV_SUCCES:

      newState.csv = payload;
      newState.totalCsv = payload.length;
      return {
        ...newState
      }

    case LIST_USERS_SUCCES:
      newState.verLista = false;
      newState.usuarios = payload.usuarios;
      newState.loadingUsuarios = false;
      newState.ocultarDialogo = true;

      return {
        ...newState
      };

    case TOTAL_LEIDOS_SUCESS:
      newState.totalLeidosEnvio = payload

      return {
        ...newState
      }

    case TOTAL_DESTINATARIOS_SUCCESS:

      newState.totalDestinatarios = payload.usuarios;


      return {
        ...newState
      }
    case FILTRAR_DESTINATARIOS_SUCCESS:
      newState.usuarios = payload.usuarios;
      newState.listaFiltradaCompleta = payload.listaFiltradaCompleta;
      newState.totalDestinatarios = false;
      if (payload.textInput !== "") {
        newState.busquedaActivada = true;
      } else {
        newState.busquedaActivada = false;

      }

      return {
        ...newState
      }

    case FILTRAR_DESTINATARIOS:
      newState.busquedaActivada = true;
      return {
        ...newState
      }

    case REMOVE_FILE:
      newState.totalCsv = false;

      return {
        ...newState
      }
    case PROM_TIEMPO_LECTURA_SUCCESS:
      newState.promedioLectura = payload;

      return {
        ...newState
      }
    case EDITAR_ENVIO:


      return {
        ...newState
      }
    case LIMPIAR_FORM:
      newState.selectedForm = {};

      return {
        ...newState
      }
    case ARCHIVOS_ADJUNTOS_SUCCESS:

      newState.parametrosArchivo = payload;
      newState.loadingArchivo = false;
      return {
        ...newState
      }
    case ARCHIVOS_ADJUNTOS:
      newState.loadingArchivo = true;

      return {
        ...newState
      }
    case DISABLE_CHOOSE_BUTTON:
      newState.archivoSeleccionado = true
      return {
        ...newState
      }
    case LIST_ENVIO:
      newState.verLista = true;
      return {
        ...newState
      }

    case DESCARGAR_CSV_ENVIOS_SUCCESS:
      newState.dataCsvDestinatarios = payload;
      return {
        ...newState
      }

    default:
      return {
        ...newState
      };
  }

}
export default reducer;