import { DOWNLOAD_FILE, MANAGE_NAV_BUTTON, REMOVE_FILE, SEE_REPORTS, SET_URL_AUDITLOG, START_WAITING, UPLOAD_FILE } from './constants';

export const initialState = {
    formFiles: [],
    showSnackBar: false,
    seeReports: false,
    manageBase: false,
    importAndExport: false,
    auditLog: false,
    urlAuditLog: false,
    startWaiting: false,
    toastMensaje: false,
    //{state: false, error:false, message:false},
    dynamicDisable: {
        importFile: true,
        uploadExcel: false,
    }
};


const reducer = (state = initialState, action) => {
    let newState = { ...state };
    const { payload, type, value } = action;

    switch (type) {
        case UPLOAD_FILE:
            newState.dynamicDisable['importFile'] = false;
            newState.dynamicDisable['uploadExcel'] = true;
            newState.showSnackBar = true;
            if (value.event.files) {
                value.event.files.map(uploadedFile => {
                    newState.formFiles.push({
                        file: uploadedFile,
                        lastModified: uploadedFile.lastModified,
                        fileName: uploadedFile.name,
                    })
                });
            }

            return {
                ...newState
            }

        case REMOVE_FILE:
            newState.dynamicDisable['importFile'] = true;
            newState.dynamicDisable['uploadExcel'] = false;
            const removedFile = value.event.file.lastModified;
            newState.showSnackBar = false;
            if (value.event) {
                newState.formFiles = newState.formFiles.filter(item => {
                    return item.file.lastModified !== removedFile;
                });

            }

            return {
                ...newState
            }

        case DOWNLOAD_FILE:
            newState.startWaiting = true;
            //invocar modal de espera
            return {
                ...newState
            }

        case MANAGE_NAV_BUTTON:
            if (payload === "reboot") {
                newState.seeReports = false;
                newState.manageBase = false;
                newState.importAndExport = false;
                newState.auditLog = false;
                newState.urlAuditLog = false;
                newState.dynamicDisable['importFile'] = true;
                newState.dynamicDisable['uploadExcel'] = false;
            } else {
                if (value?.value === "importAndExport" || payload?.value === "importAndExport") {
                    newState.seeReports = false;
                    newState.manageBase = false;
                    newState.importAndExport = true;
                    newState.auditLog = false;
                    newState.urlAuditLog = false;
                }
                else if (value?.value === "seeReports") {
                    newState.seeReports = true;
                    newState.manageBase = false;
                    newState.importAndExport = false;
                    newState.auditLog = false;
                    newState.urlAuditLog = false;
                }
                else if (value?.value === "manageBase") {
                    newState.seeReports = false;
                    newState.manageBase = true;
                    newState.importAndExport = false;
                    newState.auditLog = false;
                    newState.urlAuditLog = false;
                }
                else if (value?.value === "auditLog") {
                    newState.seeReports = false;
                    newState.manageBase = false;
                    newState.importAndExport = false;
                    newState.auditLog = true;
                }
            }
            return {
                ...newState
            }

        case START_WAITING:
            console.log("", payload)
            newState.startWaiting = payload.state;
            if (payload.error)
                newState.toastMensaje = true;

            if (payload.success)
                newState.toastMensaje = "success";


            return {
                ...newState
            }

        case SET_URL_AUDITLOG:
            
            newState.urlAuditLog = payload;
            return {
                ...newState
            }
        default:
            return {
                ...newState
            };
    }

};

export default reducer;
