import React, { Component } from "react";
import "./style.css";
import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { DOWNLOAD_DOC } from "./constants";
import { Button } from "@material-ui/core";
import PDFViewer from "./ocrTable/vistaPreviaDoc";
import CircularProgress from "@material-ui/core/CircularProgress";
import ComponentError from "../errorPopUpComponent";

import { InputText } from "primereact/inputtext";
import { selectorCrossDocument, selectorDatosDocPrincipal, selectorLoadingCircular, selectorLoadingCircularCruce, selectorShowDocument } from "./selectors";

class ComponentMisDocumentosCruzados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pdfData: false,
            idDoc: false,
            bodyError: 'El documento no coincide con los parametros de cruce configurados',
            filtroTable: false,
            labelTable: {
                columna1: 'Fecha de cargue',
                columna2: 'Nombre',
                columna3: 'Resumen',
                columna4: 'Acciones',
                columna7: 'Id documento'
            }
        }

    }
    pdfData = (newData) => {
        this.setState({ pdfData: newData });
    }
    // componentWillMount() {
    //     const {
    //         showDocumentSelector
    //     } = this.props;
    //     this.setState({
    //         filtroTable: showDocumentSelector
    //     })
    // }

    componentDidUpdate(prevProps) {
        const {
            showDocumentSelector
        } = this.props;
        if(prevProps.showDocumentSelector !== showDocumentSelector){
            this.setState({
                filtroTable: showDocumentSelector
            })
        }
    }



    handlerClose = () => {
        this.setState({ pdfData: false });

    }

    idDocumento = (newData) => {
        const {
            mostrarDocumentosProcesados,
            showDocumentSelector
        } = this.props;
        this.setState({ idDoc: newData });
        mostrarDocumentosProcesados(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    descargarDocumento = (newData) => {
        const {
            downloadDoc
        } = this.props;
        console.log("descargarDocumento", newData)
        downloadDoc(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { showDocumentSelector } = this.props;

         console.log(" filtro showDocumentSelector", showDocumentSelector)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = showDocumentSelector.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }


    render() {
        const {
            user,
            data,
            handlerCloseCruce,
            pdfData,
            crossDocumentSelector,
            loadingCircular,
            crossDocumentSelectorData,
            showDocumentSelector,
            selectorDatosDocPrincipal
        } = this.props;
        console.log("loadingCircular", loadingCircular)

        console.log("selectorDatosDocPrincipal", selectorDatosDocPrincipal)
        console.log("showDocumentSelector", showDocumentSelector)
        return (
            <>
                {!loadingCircular && showDocumentSelector.length === 0 ?
                    <ComponentError
                        onModalCancelError={handlerCloseCruce}
                        errorUpload={this.state.errorUpload}
                        errorTitle={'Error cruzando documentos'}
                        body={this.state.bodyError}
                    />
                    :
                    ''
                }


                {loadingCircular &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                }

                {this.state.pdfData ?

                    <PDFViewer
                        handlerClose={this.handlerClose}
                        pdfUrl={this.state.pdfData}
                        idDoc={crossDocumentSelectorData}
                    /> :
                    <Paper elevation={10} className="main-wrapper home container">
                        <div className="button-ocr-left">
                            <Button style={{ "marginTop": "10px" }} onClick={() => handlerCloseCruce()} color='primary' variant="contained" >Volver</Button>
                        </div>
                        <div className="search-container">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                            </span>

                        </div>
                        <ComponentTableDoc
                            downloadDoc={this.descargarDocumento}
                            labelTable={this.state.labelTable}
                            id={user}
                            pdfData={this.pdfData}
                            idDocumento={this.idDocumento}
                            data={this.state.filtroTable}
                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                            title={'Cruce'} />
                    </Paper >
                }
            </>


        );
    }
}

const mapStateToProps = state => {
    return {
        showDocumentSelector: selectorCrossDocument(state),
        loadingCircular: selectorLoadingCircularCruce(state),
        crossDocumentSelectorData: selectorShowDocument(state),
        selectorDatosDocPrincipal: selectorDatosDocPrincipal(state)

    };
};

const mapDispachToProps = dispatch => {
    return {
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value })

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentMisDocumentosCruzados);