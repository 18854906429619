import axios from "axios";
import { call } from "redux-saga/effects";
import { getFromSession } from "./session";

export function* traerDataGenerarCSV(rango = false, nombreConsulta, data = false) {
  try {
    const company = getFromSession("company_id");

    let body = {
      'company': company,
      'fechaIni': rango?.fechaIni?rango.fechaIni:false,
      'fechaFin': rango?.fechaFin?rango.fechaFin:false,
      'nombreConsulta': nombreConsulta,
      'data': data
    }

    const url = process.env.REACT_APP_GENERAR_CSV
    const url2 = 'http://localhost:5003/chat-asesores-3/us-central1/generardatadescargacsv'
    const url3 = 'https://generardatadescargacsv-gvpdazpgjq-uc.a.run.app'

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "*",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let resp = true;
    resp = yield call(axios.post, url, body, headers);

    return resp.data;
  } catch (error) {
    throw `csv.js - traerDataGenerarCSV - ${error}`
  }

}