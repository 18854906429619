import {
  GET_PLANES_SUCCESS,
  GET_USERS_SUCCESS,
  LOAD_MENU_SUCCES,
  USER_DATA,
  USER_HEADERS,
  ADQUIRIR_PRODUCTO_SUCCES,
  GET_PLANES_SUCCES,
  GET_TRANSACCION_SUCCES
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItems: false,
  menuItemsChatBot: false,
  planes: false,
  planesList: false,
  transaccion: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  switch (type) {
    case GET_USERS_SUCCESS:
      newState.users = payload.data;

      return {
        ...newState
      };
    case LOAD_MENU_SUCCES:
      newState.menuItemsChatBot = payload;

      return {
        ...newState
      };
    case ADQUIRIR_PRODUCTO_SUCCES:
      newState.planes = payload;

      return {
        ...newState
      };

    case GET_PLANES_SUCCES:
      newState.planesList = payload;

      return {
        ...newState
      };

    case GET_TRANSACCION_SUCCES:
      newState.transaccion = payload;

      return {
        ...newState
      };


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
