import "./style.css";
import 'primeflex/primeflex.css';
import SideBar from "../../components/sidebar/sidebar";
import { menuItems, menuItemsChatBot } from "../../Constants";
import {
    CARGA,
    CERRAR_SESION_AGENTE,
    CHANGE_FLAG,
    CONVS_ABANDONADAS,
    CONVS_CERRADAS,
    CONVS_EN_CURSO,
    CONVS_RESUELTAS,
    INFO_AGENTES,
    MOSTRAR_RANGOS,
    OCULTAR_ESTADISTICAS,
    OCULTAR_ESTADISTICA_ENCUESTA,
    OCULTAR_RANGOS,
    PORC_SATISFACCION,
    TMPESP_PROM,
    TMP_RESPUESTA,
    UPDATE_FIELD_ATTRIBUTE,
    UPD_AGENTES_UPDATE,
    USUARIOS_EN_ESPERA,
} from './constants';
import { getFromSession } from "../../controller/session";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
    carga,
    cargaTotal,
    convsCerradas,
    convsEnCurso,
    convsResueltas,
    datosConvsAbandonadas,
    estadisticaEncuesta,
    getSelectedForm,
    infoAgentes,
    isUpdAgentes,
    mostrarRangosFecha,
    ocultarGrafica,
    porcSatisfaccion,
    promedioTmpEsp,
    promedioTmpResp,
    tmpEsperaProm,
    tmpRespuesta,
    totalConvs,
    totalConvsCerradas,
    totalUsuarios,
    usuariosEnEspera
} from "./selectors";

import { Card } from 'primereact/card';
import CustomForm from "../../components/customform/customform";
import DataGridView from "../../components/dataViewLayout/dataView";
import LineChart from "../../components/Charts/lineChart";
import BarChart from "../../components/Charts/barChart";




class ComponentSupervisorPanel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            menu: false,
        }
    }
    componentWillMount() {
        
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const {
            obtenerConvsEnCurso,
            obtenerUsuariosEnEspera,
            obtenerConvsCerradas,
            obtenerCarga,
            obtenerTmpEspPromedio,
            obtenerTmpRespuesta,
            obtenerConvsResueltas,
            obtenerPorcSatisfaccion,
            obtenerInfoAgentes,
            selectedForm,
            obtenerPorcConvsAbandonadas,
            ocultarEstadisticasConvsAbandonadas,
            ocultarEstadisticaEncuesta
        }
            = this.props;

        selectedForm.fechaRango = 'HOY'
        
        obtenerCarga();
        obtenerConvsEnCurso();
        obtenerUsuariosEnEspera();
        obtenerConvsCerradas();
        obtenerTmpEspPromedio();
        obtenerTmpRespuesta();
        obtenerConvsResueltas();
        ocultarEstadisticasConvsAbandonadas();
        ocultarEstadisticaEncuesta();
        obtenerPorcSatisfaccion();
        obtenerPorcConvsAbandonadas();
        obtenerInfoAgentes();


       
        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
    } else {
        this.setState({ menu: menuItemsChatBot });
    }


    }

    componentDidUpdate(prevProps) {
        const {
            obtenerConvsEnCurso,
            obtenerUsuariosEnEspera,
            obtenerConvsCerradas,
            obtenerCarga,
            obtenerTmpEspPromedio,
            obtenerTmpRespuesta,
            obtenerConvsResueltas,
            obtenerPorcSatisfaccion,
            obtenerInfoAgentes,
            obtenerPorcConvsAbandonadas,
            verGrafica,
        } = this.props

        if (this.props.selectedForm.fechaRango && prevProps.selectedForm.fechaRango !== this.props.selectedForm.fechaRango
            && this.props.selectedForm.fechaRango !== 'CUSTOM') {
            obtenerConvsEnCurso(this.props.selectedForm.fechaRango);
            obtenerUsuariosEnEspera(this.props.selectedForm.fechaRango);
            obtenerConvsCerradas(this.props.selectedForm.fechaRango);
            obtenerCarga(this.props.selectedForm.fechaRango);
            obtenerTmpEspPromedio(this.props.selectedForm.fechaRango);
            obtenerTmpRespuesta(this.props.selectedForm.fechaRango);
            obtenerConvsResueltas(this.props.selectedForm.fechaRango);
            obtenerPorcSatisfaccion(this.props.selectedForm.fechaRango);
            obtenerInfoAgentes(this.props.selectedForm.fechaRango);

            if (verGrafica) {
                obtenerPorcConvsAbandonadas(this.props.selectedForm.fechaRango);
            }

        }

    }


    render() {

        const {
            updateAttributes,
            selectedForm,
            convsEnCurso,
            totalConvs,
            usuariosEnEspera,
            totalUsuarios,
            convsCerradas,
            totalConvsCerradas,
            carga,
            cargaTotal,
            tmpEsperaProm,
            promedioTmpEsp,
            tmpRespuesta,
            promedioTmpResp,
            convsResueltas,
            porcSatisfaccion,
            infoAgentes,
            cerrarSesionAgente,
            datosConvsAbandonadas,
            verGrafica,
            isUpdAgentes,
            changeFlag,
            estadisticaEncuesta

        } = this.props;

        
        
        let company = getFromSession('company')
        //console.log("company supervisor panel", company)

        let opcionesRangos = [
            { label: "Hoy", value: 'HOY' },
            { label: "Ultimas 24 horas", value: '24HORAS' },
            { label: "Ayer", value: 'AYER' }
        ];
        let FILTER = [
            {
                name: "fechaRango",
                placeholder: "Seleccione un rango de tiempo",
                value: false,
                options: opcionesRangos,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione un rango",
                display: true
            }
        ]

        const header = (textTooltip, j) => {
            let style = textTooltip ? textTooltip.style : false;
            let text = textTooltip ? textTooltip.text : false;
            return [
                <span key={j} className={`${style}`} data-title={text}>
                    <img className="icon-table4 float-right" src="/assets/icons/info.png" />
                </span>
            ]
        }



        let convertirSegundos = (segundos) => {
            let respuesta;
            if (segundos > 1) {
                let valorMin = 604801;
                let intervalos = {
                    'semanas': 604800,
                    'dias': 86400,
                    'hrs': 3600,
                    'min': 60,
                    'seg': 1
                }
                for (let intervalo of Object.keys(intervalos)) {
                    let resultado = segundos / intervalos[intervalo];
                    if (resultado > 1 && resultado < valorMin) {
                        valorMin = Number((resultado).toFixed(2));
                        respuesta = valorMin.toString() + ' ' + intervalo;
                    }
                }
            } else {
                respuesta = segundos.toString() + ' seg';
            }
            return respuesta;

        }
        let htmlResueltas;

        if (convsResueltas.porcResueltas) {
            htmlResueltas = <div className="p-text-bold-big">{convsResueltas.porcResueltas}%</div>
        } else {
            htmlResueltas = <div className="p-text-bold-big">0</div>
        }
        let mensajeCarga
        if (carga.flag) {
            mensajeCarga = <div className="p-text-normal"></div>
        } else {
            if (totalConvs > 0) {
                mensajeCarga = <div className="p-text-normal">No hay agentes en línea</div>

            } else {
                mensajeCarga = <div className="p-text-normal">No hay conversaciones abiertas</div>
            }


        }
        let promTmpEsp = promedioTmpEsp ? convertirSegundos(promedioTmpEsp) : 0;
        let promTmpResp = promedioTmpResp ? convertirSegundos(promedioTmpResp) : 0;
        let totalUsersEspera = totalUsuarios ? totalUsuarios : 0;
        let tooltipPromResp = {
            'text': 'Gráfico de tiempo promedio que los agentes humanos tardan en aceptar una conversación por hora o por día',
            'style': 'littleTooltip'
        }
        let tooltipPromEsp = {
            'text': 'Gráfico de tiempo promedio de los usuarios esperando atención de un agente humano',
            'style': 'littleTooltip'
        }
        let tooltipConvEspera = {
            'text': 'Gráfico de tiempo promedio de una conversación en espera a ser asignada a un agente humano',
            'style': 'littleTooltip'
        }

       

        return (
            <React.Fragment>
                <SideBar logo={company.logo}  items={this.state.menu}></SideBar>
                <div>
                    <div className="p-shadow-8  p-mt-3 p-p-3 styleBackground">
                        <h3 className="h3-title">Panel del Supervisor</h3>
                        <div className="p-col-6">
                        </div>
                        <div className='row'>
                            <h3 className="h3-fixed">Resumen de Actividad:</h3>

                            <CustomForm
                                formName="filterFechaSupervisor"
                                items={FILTER}
                                values={selectedForm}
                                updateAction={updateAttributes}
                                style="splash-container-search"
                                bodyStyle="body-filtrarfecha"
                                styleFormgroup="group-windowSearch"
                                classnameDiv="none"
                                formRow='form-row'
                            >
                            </CustomForm>


                        </div>

                        <div className="row">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                <Card title='Conversaciones en espera' header={header(tooltipConvEspera, 't')}>
                                    
                                    <div className="p-text-bold-big"> {totalUsersEspera}</div>
                                    
                                </Card>

                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                <Card title="Conversaciones en curso" header={header(convsEnCurso.textTooltip, 'j')}>
                                    <div className="p-text-bold">{totalConvs}</div>

                                    <LineChart
                                        data={convsEnCurso}>

                                    </LineChart>


                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                <Card title="Conversaciones cerradas" header={header(convsCerradas.textTooltip, 'k')}>
                                    <div className="p-text-bold"> {totalConvsCerradas}</div>
                                    <LineChart
                                        data={convsCerradas}>

                                    </LineChart>

                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card title="Carga" header={header(carga.textTooltip, 'm')}>
                                    <div className="p-text-bold">{cargaTotal}%</div>
                                    {mensajeCarga}

                                    <BarChart
                                        data={carga}>

                                    </BarChart>

                                </Card>
                            </div>


                        </div>
                        <div className="row margin-top">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                <Card title="Tiempo de espera" header={header(tooltipPromEsp, 'n')}>
                                    <div className="p-text-normal">Promedio Total</div>
                                    <div className="p-text-bold">{promTmpEsp} </div>
                                    <LineChart
                                        data={tmpEsperaProm}>

                                    </LineChart>
                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                <Card title="Tiempo de Aceptacion" header={header(tooltipPromResp, 'o')}>
                                    <div className="p-text-normal">Promedio Total</div>
                                    <div className="p-text-bold">{promTmpResp}</div>
                                    <LineChart
                                        data={tmpRespuesta}>

                                    </LineChart>
                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12  margin-right">
                                <Card title="Conversaciones resueltas" header={header(convsResueltas.tooltip, 'p')}>
                                    <div className="p-text-bold-big"> {htmlResueltas}</div>
                                    <div className="p-text-normal-big p-mb-5">{convsResueltas.numResueltas}/{convsResueltas.numConvs} conversaciones</div>
                                    {/* <div className="p-mt-5 p-mb-5"></div> */}
                                </Card>
                            </div>
                            {estadisticaEncuesta ? (
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <Card title="Porcentaje de satisfacción" header={header(porcSatisfaccion.tooltip, 'k')} >
                                        <div className="p-text-bold-big">{porcSatisfaccion.numero}</div>
                                        <div className="p-text-normal-big p-mb-5">{porcSatisfaccion.encuestadas ? porcSatisfaccion.encuestadas : 0} Encuestas realizadas</div>
                                    </Card>
                                </div>

                            ) : null}
                            {
                                verGrafica && !estadisticaEncuesta ? (
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                        <Card title="Conversaciones abandonadas" header={header(datosConvsAbandonadas.parametros, 'n')}>
                                            <div className="p-text-normal">Porcentaje</div>
                                            <div className="p-text-bold-big">{datosConvsAbandonadas.porcConvs ? datosConvsAbandonadas.porcConvs : '0'}% </div>
                                            <div className="p-text-normal-big p-mb-5">{ datosConvsAbandonadas.convsEstado ? datosConvsAbandonadas.convsEstado : 0}
                                                /{datosConvsAbandonadas.totalConvs ? datosConvsAbandonadas.totalConvs : '0'} conversaciones</div>
                                        </Card>
                                    </div>
                                ) : null
                            }


                        </div>
                        {verGrafica && estadisticaEncuesta ? (
                            <div>
                                <div className="row margin-top">
                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                                        <Card title="Conversaciones abandonadas" header={header(datosConvsAbandonadas.parametros, 'n')}>
                                            <div className="p-text-normal">Porcentaje</div>
                                            <div className="p-text-bold-big">{datosConvsAbandonadas.porcConvs ? datosConvsAbandonadas.porcConvs : '0'}% </div>
                                            <div className="p-text-normal-big p-mb-5">{datosConvsAbandonadas.convsEstado ? datosConvsAbandonadas.convsEstado : 0}
                                                /{datosConvsAbandonadas.totalConvs ? datosConvsAbandonadas.totalConvs : '0'} conversaciones</div>

                                        </Card>
                                    </div>

                                </div>
                            </div>) : null}

                    </div>
                    <div className="p-shadow-8  p-mt-3  p-pb-2 p-pl-3">
                        <div className='row'>
                            <div className="p-col-2" >
                                <h3>Agentes</h3>
                            </div>
                        </div>
                        {infoAgentes ? (<DataGridView
                            dataInfo={infoAgentes} changeFlag={changeFlag} updAgentes={isUpdAgentes} action={cerrarSesionAgente}>
                        </DataGridView>) : null}
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

const mapStateToProps = state => {

    return {
        selectedForm: getSelectedForm(state),
        mostrarRangosFecha: mostrarRangosFecha(state),

        convsEnCurso: convsEnCurso(state),
        totalConvs: totalConvs(state),

        datosConvsAbandonadas: datosConvsAbandonadas(state),

        usuariosEnEspera: usuariosEnEspera(state),
        totalUsuarios: totalUsuarios(state),

        convsCerradas: convsCerradas(state),
        totalConvsCerradas: totalConvsCerradas(state),

        carga: carga(state),
        cargaTotal: cargaTotal(state),

        tmpEsperaProm: tmpEsperaProm(state),
        promedioTmpEsp: promedioTmpEsp(state),

        tmpRespuesta: tmpRespuesta(state),
        promedioTmpResp: promedioTmpResp(state),

        convsResueltas: convsResueltas(state),
        porcSatisfaccion: porcSatisfaccion(state),

        infoAgentes: infoAgentes(state),
        isUpdAgentes: isUpdAgentes(state),

        verGrafica: ocultarGrafica(state),
        estadisticaEncuesta: estadisticaEncuesta(state),


    };

};


const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        obtenerConvsEnCurso: (value) => dispatch({ type: CONVS_EN_CURSO, value }),
        obtenerUsuariosEnEspera: (value) => dispatch({ type: USUARIOS_EN_ESPERA, value }),
        obtenerConvsCerradas: (value) => dispatch({ type: CONVS_CERRADAS, value }),
        obtenerCarga: (value) => dispatch({ type: CARGA, value }),
        obtenerTmpEspPromedio: (value) => dispatch({ type: TMPESP_PROM, value }),
        obtenerTmpRespuesta: (value) => dispatch({ type: TMP_RESPUESTA, value }),
        obtenerConvsResueltas: (value) => dispatch({ type: CONVS_RESUELTAS, value }),
        obtenerPorcSatisfaccion: (value) => dispatch({ type: PORC_SATISFACCION, value }),
        obtenerInfoAgentes: (value) => dispatch({ type: INFO_AGENTES, value }),
        obtenerPorcConvsAbandonadas: (value) => dispatch({ type: CONVS_ABANDONADAS, value }),
        mostrarCamposRangos: () => dispatch({ type: MOSTRAR_RANGOS }),
        ocultarCamposRangos: () => dispatch({ type: OCULTAR_RANGOS }),
        updAgente: (value) => dispatch({ type: UPD_AGENTES_UPDATE, value }),
        cerrarSesionAgente: (value) => dispatch({ type: CERRAR_SESION_AGENTE, value }),
        ocultarEstadisticasConvsAbandonadas: (value) => dispatch({ type: OCULTAR_ESTADISTICAS, value }),
        ocultarEstadisticaEncuesta: (value) => dispatch({ type: OCULTAR_ESTADISTICA_ENCUESTA, value }),
        changeFlag: (value) => dispatch({ type: CHANGE_FLAG, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentSupervisorPanel);