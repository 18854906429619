import "./chatCard.css";
import React, { Component } from "react";
import CustomForm from "../../components/customform/customform";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Dialog } from "primereact/dialog";
import Messages from "./messages";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import LoadingOverlay from 'react-loading-overlay';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import PrintConversationData from '../../components/printConversation/printConversation';
import { InputTextarea } from "primereact/inputtextarea";


export default class ChatWindowCard extends Component {

  constructor() {
    super();
    this.state = {
      globalFilter: null,
      valueMessage: ''
    };
  }

  componentDidUpdate(prevProps) {
    const { verPanelTemas, verPanelFaqs } = this.props
    if (prevProps.verPanelTemas !== this.props.verPanelTemas
    ) {
      if (verPanelTemas) {
        this.setState({
          globalFilter: null
        })
      }
    }
    if (prevProps.verPanelFaqs !== this.props.verPanelFaqs
    ) {
      if (verPanelFaqs) {
        this.setState({
          globalFilter: null
        })
      }
    }
  }


  renderTableUsuariosConectados(
    usuarios,
    onSelectUsuario,
    conversation,
    id
  ) {
    if (usuarios) {
      usuarios.forEach((usu) => {
        usu["id"] = { id };
        usu["conversation"] = conversation;
      });
    }

    return (
      <div className="card">
        <DataTable value={usuarios} onRowClick={e => { onSelectUsuario(e.data); }}
          paginator rows={10} rowsPerPageOptions={[5, 10, 25]} selectionMode="single" dataKey="id">
          <Column field="habilidad" header="Habilidad"></Column>
          <Column field="nombres" header="Nombre"></Column>
          <Column field="convsActuales" header="Conversaciones asignadas"></Column>
        </DataTable>
      </div>
    );
  }

  renderTableTemas(temas, fijarTema, id, loadingTemas) {

    if (temas) {
      temas.forEach((tema) => {
        tema["id"] = { id };
      });
    }
    const header = (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Search..." />
        </span>
      </div>
    );
    return (
      <div className="card">
        <LoadingOverlay
          active={loadingTemas}
          spinner
          text='Cargando Tabla'
        >
          <DataTable value={temas} onRowClick={e => { fijarTema(e.data); }} globalFilter={this.state.globalFilter}
            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} selectionMode="single" dataKey="id">
            <Column field="nivel1" header="Nivel 1"></Column>
            <Column field="nivel2" header="Nivel 2"></Column>
            <Column field="nivel3" header="Nivel 3"></Column>

          </DataTable>
        </LoadingOverlay>
      </div>
    );
  }

  renderModalNotasOcultas() {
    const { selectedForm, updateAttributes, addNotaOculta, id, conversation } = this.props
    const NOTAS_FORM = [
      {
        label: "",
        name: "notas",
        placeholder: "Escribe aquí",
        type: "text",
        typeForm: "text-area",
        value: false,
        options: [],
        disable: false,
        display: true
      }
    ]

    let button = (handlerSafe) => {
      return [
        {
          label: "Añadir",
          className: "btn btn-primary",
          action: false,
          style: "primary",
          onClick: handlerSafe,
          display: true
        }
      ]
    }

    return (
      <div>
        <CustomForm
          formName="notasOcultasForm"
          items={NOTAS_FORM}
          values={selectedForm}
          updateAction={updateAttributes}
          style="splash-container"
          positionButton='fixed-buttonNotas'
          buttons={button(() => addNotaOculta({ id, conversation, selectedForm }))}
        />
      </div>
    );
  }


  renderAdjuntarArchivos(adjuntarArchivosUpload, conversation, id, loadingAdjuntar) {
    return (
      <div className="card">
        <LoadingOverlay
          active={loadingAdjuntar}
          spinner
          text='Subiendo Archivo'
        >
          <FileUpload
            customUpload uploadHandler={($event) => adjuntarArchivosUpload({ $event, conversation, id })}
            multiple
            emptyTemplate={<p className="p-m-0">Seleccione o arrastre los archivos hasta aquí.</p>}
            maxFileSize={20000000}
            invalidFileSizeMessageDetail="El archivo no puede pesar mas de {0}."
            invalidFileSizeMessageSummary="{0}: Tamaño de archivo inválido"
            chooseLabel="Seleccionar"
            uploadLabel="Subir"
            cancelLabel="Cancelar"
          >
          </FileUpload>
        </LoadingOverlay>
      </div>
    );
  }

  renderTableFaqs(faqs, enviarRespuestaFaqs, id, conversation, loadingFaqs) {
    if (faqs) {
      faqs.forEach((faq) => {
        faq["id"] = { id };
        faq["conversation"] = { conversation };
        faq["isFaq"] = { isFaq: true }
      });
    }
    const header = (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Search..." />
        </span>
      </div>
    );

    return (
      <div className="card">
        {/* <LoadingOverlay
          active={loadingFaqs}
          spinner
          text='Cargando tabla'
        > */}
        <DataTable value={faqs} onRowClick={e => { enviarRespuestaFaqs(e.data); }} globalFilter={this.state.globalFilter}
          paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} selectionMode="single" dataKey="id">
          <Column field="pregunta" header="Pregunta"></Column>
          <Column field="respuesta" header="Respuesta"></Column>
        </DataTable>
        {/* </LoadingOverlay> */}
      </div>)
  }

  renderModal(open, body, onHide, headerTitle, id, style) {
    return (
      <Dialog
        header={headerTitle}
        visible={open}
        modal
        closeOnEscape
        id={id}
        onHide={onHide}
        style={{ width: style }}
      >
        {body}
      </Dialog>
    );
  }
  enviarMensaje() {
    const { sendMessage, id, conversation } = this.props
    if (this.state.valueMessage.trim().length !== 0) {
      sendMessage({
        id,
        conversation,
        selectedForm: { mensaje: this.state.valueMessage.trim() },
      })
    }
    this.setState({ valueMessage: '' })


  }

  render() {
    const {
      conversation,
      mensajes,
      loading,
      sendMessage,
      id,
      selectedForm,
      updateAttributes,
      MESSAGE_FORMS,
      setTema,
      transferir,
      loadingAdjuntar,
      loadingTemas,
      loadingFaqs,
      verPanelTransfer,

      usuariosDisplay,
      verPanelTemas,
      temasDisplay,
      onHideTemas,
      onHide,
      onHideAdjuntar,
      verPanelAdjuntar,
      modalAdjuntarOpen,
      adjuntarArchivosUpload,
      verPanelFaqs,
      faqsDisplay,
      onHideFaqs,
      faqsHeaders,
      verPanelPrint,
      infoConv,
      convMensajes,
      cerrarModalPrint,
      modalNotasOcutas,


    } = this.props;


    let spinner = <CircularProgress color="secondary"></CircularProgress>;

    return (
      <div className="chardcar_wrapper">
        <PrintConversationData datos={infoConv}
          verModalDescargarConversacion={verPanelPrint}
          mensajes={convMensajes} cerrarModalDescargarDatos={cerrarModalPrint}>
        </PrintConversationData>

        {this.renderModal(
          verPanelTransfer,
          this.renderTableUsuariosConectados(
            usuariosDisplay,
            transferir,
            conversation,
            id
          ),
          onHide,
          "Transferir conversación",
          "transferirConversacion",
          "50vw"

        )}
        {this.renderModal(
          verPanelTemas,
          this.renderTableTemas(temasDisplay, setTema, id, loadingTemas),
          onHideTemas,
          "Fijar Tema",
          "fijarTema",
          "50vw"
        )}
        {verPanelFaqs ? this.renderModal(
          verPanelFaqs,
          this.renderTableFaqs(faqsDisplay, sendMessage, id, conversation, loadingFaqs),
          onHideFaqs,
          "Seleccionar Respuesta",
          "respuestasFaqs",
          "50vw"
        ) : ""}
        {this.renderModal(
          verPanelAdjuntar,
          this.renderAdjuntarArchivos(adjuntarArchivosUpload, conversation, id, loadingAdjuntar),
          onHideAdjuntar,
          "Adjuntar archivo",
          "adjuntarArchivo",
          "50vw"
        )}
        {this.renderModal(
          modalNotasOcutas,
          this.renderModalNotasOcultas(),
          onHideFaqs,
          "Añadir Nota Oculta",
          "notasOcultasmodal",
          "30vw"
        )}

        {loading ? (
          spinner

        ) : (
          <div>
            {conversation ? (
              <div className="card">


                <Messages mensajes={mensajes} />

                <div className="card-footer pl-1 pr-1 ml-1 mr-1 pb-0 mt-0 pt-0">
                  {conversation.estado >= 10 ? (
                    <div className="card-footer pl-1 pr-1 ml-1 mr-1 pb-0 mt-0 pt-0">

                    </div>
                  ) : (
                    <div className="col-md-12 pr-1 ml-1 mr-1 pl-1 mt-0 pt-0 mt-0">
                      <div className="row">
                        <div className="col-md-1 ml-1  pl-0 mr-1 pr-0">
                          <Button
                            title="Adjuntar archivo"
                            className="p-button p-component p-button-rounded p-button-icon-only mt-4 float-right"
                            type="button"
                            onClick={() => modalAdjuntarOpen()}

                          >
                            <img src="/assets/icons/adjuntar2.png"></img>
                          </Button>
                        </div>
                        <div className="col-md-9 ml-0 pl-0 mr-0 pr-0">
                          <InputTextarea
                            className="splash-container-sendMessage"
                            value={this.state.valueMessage}
                            onChange={(e) => this.setState({ valueMessage: e.target.value })}
                            autoResize
                            rows={3}
                            placeholder="Escriba su mensaje..."
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.enviarMensaje()
                              }
                            }}

                          />

                        </div>
                        <div className="col-md-1  ml-0 pl-0 mr-0 pr-0">
                          <Button
                            title="Enviar Mensaje"
                            className="p-button p-component p-button-rounded p-button-icon-only mt-4 float-right"
                            type="button"
                            onClick={() =>
                              this.enviarMensaje()
                            }
                          >
                            <img src="/assets/icons/enviar.png"></img>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="card" style={{ "height": "85vh" }}>

                <div className="card-body">
                  <h5 className="float-left">
                    Por favor seleccione una conversación...
                  </h5>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
