import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { MESSAGE_DATA_CERO_NOTAS, TABLE_NOTAS_TITLE, TITLE_TABLE_CONTACT } from "./constants";
import { getConvsAgentes, getDataTableAgentes, getDataTableNotasOcultas } from "./selectors";
import { BarChartDemo } from '../../components/Charts/barrChart';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';



class InformesComponent extends Component {


    componentWillMount() {
        const { } = this.props

    }

    componentDidUpdate(prevProps) {
        const { } = this.props

    }

    informes() {
        return ["Registro de auditorias", "Informe 2"]
    }
    render() {
        const {
            dataTableNotasOcultas,
        } = this.props;
        return (
            <div>
                <div>
                    <h4 className="tituloComponente" >INFORMES DE DIALOGFLOW</h4>
                </div>
                {true ?
                    <div className="containerGeneral">
                        <div className="p-mt-3">

                            <div className="card">
                                <DataTable value={this.informes()} responsiveLayout="scroll">
                                    <Column field="nameInforme" header="Nombre de informe"></Column>
                                    <Column >
                                    <Button label="Descargar" onclick={console.log("Aqui deberia descargar")} />
                                    </Column>
                                </DataTable>
                            </div>

                        </div>
                    </div>
                    :
                    <div>
                        <h4 className="messageCero">{"NO HAY INFORMES PARA DESCARGAR"}</h4>
                    </div>
                }
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {


    }
}


const mapDispachToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(InformesComponent);