
import axios from "axios";
import { firebaseDatabase, firebaseAuthentication } from "./firebase";
import { sendEmailVerification } from "firebase/auth";
import { gethabilidadporId } from "./habilidades";
import { getFromSession } from './session'


export function* getUsuarios() {

    try {
        const company = getFromSession("company_id"); //`company/${company}/conversations`
        let usuarios = [];

        yield firebaseDatabase.collection(`company/${company}/usuarios`).get().then(docs => {
            docs.forEach(doc => {
                //console.log("DOCCCCC", doc.ref.id)
                usuarios.push(doc);

            });
        });
        return usuarios;
    } catch (error) {
        console.error('controller.usuarios.traerUsuarios', error);
        throw error;

    }
}

export function* editSkillInUser(idSkill, nombreNuevo) {
    try {
        let resp;
        const company = getFromSession("company_id");
        const reference = firebaseDatabase.collection(`company/${company}/usuarios`);
        yield reference.where("skill.id", "==", idSkill).get().then(results => {
            results.forEach(element => {
                reference.doc(element.data().idDoc).update({
                    skill: {
                        id: idSkill,
                        nombre: nombreNuevo
                    }
                })
            });
        }).then(() => {
            console.log('Documentos Actualizados, función editSkillInUser');
            resp = true;
        }).catch((err) => {
            console.error("Error", err);
            resp = false;
        })
        return resp;
    } catch (error) {
        console.error("controller.usuarios.editSkillInUser", error);
        throw error;
    }
}

export function* editUser(dataForm, idUser) {
    try {
        console.log('dataFormeditUser', dataForm);
        const company = getFromSession("company_id"); //`company/${company}/conversations`
        let newSkill = false;
        let idskill = dataForm.habilidadId ? dataForm.habilidadId : false;
        let resp = false

        if (idskill) {
            newSkill = yield gethabilidadporId(idskill)
        }

        yield firebaseDatabase.collection(`company/${company}/usuarios`).doc(idUser).update({
            nombres: dataForm.nombres,
            apellidos: dataForm.apellidos,
            nickname: dataForm.nickname,
            rol: dataForm.rol,
            skill: newSkill ? newSkill : dataForm.skill,
        }).then(() => {
            resp = true


        }).catch((error) => {
            console.error("Error", error);
            resp = false;
        })

        return resp;
    } catch (error) {
        console.error('controller.usuarios.editarUsuario', error);
        throw error;
    }
}

export function* cambiarUsuarioContraseña(uid, password) {
    try {
        let body = { 'password': password, 'uid': uid }
        const url = process.env.REACT_APP_GESTIONAR_USUARIO;
        let resp = false;
        yield axios.create({
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
        }).put(url, body).then(response => {
            resp = true;
            console.log('cambiarUsuarioContraseña realizado', response.status)
        }).catch(err => {
            resp = false;
            console.error('error cambiando la contraseña' + err)
            throw err;
        })
        return resp;

    } catch (error) {
        console.error('controller.usuarios.cambiarUsuarioContraseña');
        throw error;

    }
}

async function crearUsuarioContraseña(correo, contraseña) {
    try {

        const user = getFromSession("currentUser");
        let uidUser;
        let flag = false;
        let resp = false;

        await firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
            docs.forEach(doc => {
                resp = doc.data();
            });
        });
        //console.log("RESP *******", resp)
        // await firebaseAuthentication.fetchSignInMethodsForEmail(correo)
        //     .then((signInMethods) => {
        //         // Verificar si el correo electrónico ya está registrado
        //         console.log("Verificar", signInMethods)
        //         if (signInMethods.length > 0) {
        //             console.log(`El correo electrónico ${correo} ya está registrado.`);
        //             console.log('Métodos de inicio de sesión:', signInMethods);
        //         } else {
        //             console.log(`El correo electrónico ${correo} no está registrado.`);
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error al buscar el correo electrónico:', error);
        //     });

        // await firebaseAuthentication.getUserByEmail(correo)
        //     .then((userRecord) => {
        //         // Verificar si el correo electrónico ya está registrado
        //         console.log("Verificar", userRecord)
                
        //     })
        //     .catch((error) => {
        //         console.error('Error al buscar el correo electrónico:', error);
        //     });

        await firebaseAuthentication.createUserWithEmailAndPassword(correo, contraseña)
            .then((user) => {
                flag = true
                uidUser = { ...user };
            })
            .catch((error) => {

                uidUser = false;

            });

            console.log(uidUser);
        if (flag) {
            await sendEmailVerification(uidUser.user);

            const messages = `<p>¡Hola!</p> 

            <p>Te damos la bienvenida a Avanti-it. 
            A continuación te confirmo tus datos para hacer el 
            login en la aplicación.</p>
    
            <p>Número de cuenta: ${resp.cuenta}</p>
            <p>correo: ${correo}</p>
            <p>Contraseña: ${contraseña}</p>`


            const body = {
                from_: "Datos de cuenta <verajairo11@gmail.com>",
                to_: correo,
                subject_: "Verificación de datos cuenta Avanti-it",
                body: messages,
                user: "verajairo11@gmail.com",
                pass: "necctmuyawvxpblm"
            }

            await fetch(`${process.env.REACT_APP_CHATASESORES_SEND_MAIL}`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: { "Content-type": "application/json; charset=UTF-8" }
            })



            return uidUser.user.uid;
        } else {
            return false;
        }

    } catch (error) {
        console.error('controller.usuarios.crearUsuarioContraseña', error);
        throw error;

    }
}

export function* consultarCorreoExistente(mail) {
    try {

        let existe = false;
        const company = getFromSession("company_id");

        yield firebaseDatabase.collection(`company/${company}/usuarios`).where('mail', '==', mail).get().then(docs => {
            let size = docs.size
            //console.log('size', size)
            if (size) {
                existe = true
            }
        })

        return existe;

    } catch (error) {
        console.error('controller.usuarios.consultarCorreoExistente', error);
        throw error;
    }

}

export function* crearDatosGeneralesUsuario(formData) {
    try {
        const company = getFromSession("company_id");
        let resp = false
        let skill = yield gethabilidadporId(formData.habilidadId);
        const uidUser = yield crearUsuarioContraseña(formData.correo, formData.password);
        //const uid = yield createUserWithEmailAndPassword(auth, companyUser.correo, companyUser.password);


        if (uidUser) {
            const docRef = yield firebaseDatabase.collection(`company/${company}/usuarios`).doc();
            let body = {
                nombres: formData.nombres,
                apellidos: formData.apellidos,
                nickname: formData.nickname,
                rol: formData.rol,
                skill: skill,
                estado: 1,
                mail: formData.correo,
                uid: uidUser,
                idDoc: docRef.id,
            }
            docRef.set(body)
            resp = true;
        } else {
            resp = false;
        }
        return resp;
    } catch (error) {
        console.error('controller.usuarios.crearDatosGeneralesUsuario', error);
        throw error;
    }
}

export function* deleteUserInColletion(idDoc, uid) {

    try {
        const company = getFromSession("company_id");

        const deleteInAuth = yield deleteUserInAuth(uid);
        let resp = false;
        if (deleteInAuth) {

            yield firebaseDatabase.collection(`company/${company}/usuarios`).doc(idDoc).delete().then(() => {
                resp = true;
                console.log("Document successfully deleted!");
            }).catch((error) => {
                resp = false;
                console.error("Error removing document: ", error);
            });


        }
        return resp;
    } catch (error) {
        console.error('controller.usuarios.deleteUserInCollection', error);
        throw error;

    }
}

export function* deleteUserInAuth(uid) {
    try {
        let resp = false;
        console.log('uidDeleteUSERin auth', uid);
        let data = JSON.stringify({ 'uid': uid });
        var config = {
            method: 'delete',
            url: 'https://gestionarusuario-zbsxu2h3da-uc.a.run.app',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json'
            },
            data: data
        };

        yield axios(config).then(response => {
            resp = true;
            console.log('se ha eliminado el usuario', response.status)
        })
            .catch(err => {
                resp = false;
                console.error('error al eliminar el usuario' + err)
                throw err;
            });

        return resp;

    } catch (error) {
        console.error('controller.usuarios.deleteUser', error);
        throw error;
    }
}