
import "./style.css";
import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";

import { Alert } from 'react-bootstrap';
import CustomReduxForm from "../../components/materialform/customform";
import {
    getSelectedForm, getUsuarios, getHabilidades, toastDetail, showToast, updateTableUsuarios, ocultarModal, getSelectedReduxForm,
    abrirModalEditar,
    tituloModal,
    disabledEmailField,
    abrirModalBorrar,
    uidUser,
    message,
    modalHabilidades,
    abrirModalEditarHabilidad,
    getSelectedReduxFormHabilidad,
    abrirModalEliminarHabilidad,
    idDocHabilidad,
    nombreHabilidad
} from "./selector";
import {

    CREAR_USUARIO,
    EDITAR_USUARIO,
    GET_HABILIDADES,
    LISTAR_USUARIOS,
    UPDATE_FIELD_ATTRIBUTE,
    FORM_USUARIOS_NAME,
    SELECT_ROW,
    ABRIR_MODAL_USUARIOS,
    CERRAR_MODAL_USUARIOS,
    ELIMINAR_USUARIO,
    ABRIR_MODAL_BORRAR,
    ABRIR_MODAL_HABILIDADES,
    SELECT_ROW_HABILIDAD,
    FORM_HABILIDADES_NAME,
    CERRAR_MODAL_EDITAR_HABILIDAD,
    EDITAR_HABILIDAD,
    CREAR_HABILIDAD,
    MODAL_ELIMINAR_HABILIDAD,
    CERRAR_MODAL_ELIMINAR_HABILIDAD,
    ELIMINAR_HABILIDAD

} from "./constants";
import { Card } from 'primereact/card';

import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';




class UsuariosComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null,
            globalFilterHabilidades: null,
            message: false,
        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.actionBodyTemplateHabilidades = this.actionBodyTemplateHabilidades.bind(this)

    }


    componentWillMount() {
        const { listarUsuarios } = this.props
        listarUsuarios();
    }

    componentDidUpdate(prevProps) {
        const { showToast, toastDetail, updateTable, listarUsuarios } = this.props

        if (showToast) {
            this.toast.show({ severity: toastDetail.severity, summary: toastDetail.summary, detail: toastDetail.detail });
        }

        if (updateTable) {
            listarUsuarios();
        }

    }


    renderModal(open, body, onHide, headerTitle, id) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw" }}
            >
                {body}
            </Dialog>
        );
    }


    rightToolbarTemplate() {
        const { abrirModalEditarUsuarios, abrirModalHabilidades, adminOCR } = this.props;
        return (

            <React.Fragment>
                {adminOCR ?
                    <Button label="Nuevo usuario" icon="pi pi-plus" className="p-button-success" onClick={() => abrirModalEditarUsuarios()} />
                    :
                    <>
                        <Button label="Nueva habilidad" icon="pi pi-plus" className="p-button-help p-mr-3" onClick={() => abrirModalHabilidades()}></Button>
                        <Button label="Nuevo usuario" icon="pi pi-plus" className="p-button-success" onClick={() => abrirModalEditarUsuarios()} />
                    </>
                }
            </React.Fragment>
        )
    }

    renderDeleteDialog() {

        const { eliminarUsuario, cerrarModal, uidUser } = this.props

        return (
            <div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem', 'margin-left': '2rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2rem' }}></i>
                    <div className="p-text-normal" style={{ 'margin-left': '1rem' }}>¿Está seguro que desea borrar el usuario?</div>
                </div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem' }}>
                    <div style={{ 'margin-left': 'auto', 'margin-right': '2rem' }}>
                        <Button label="Si" onClick={() => eliminarUsuario(uidUser)}  ></Button>
                        <Button label="No" onClick={() => cerrarModal()} style={{ 'margin-left': '1rem' }} className="p-button-secondary"></Button>
                    </div>
                </div>
            </div>
        )
    }

    renderDeleteDialogHabilidad() {

        const { eliminarHabilidad, cerrarModalEditarHabilidad, habilidadId, nombreHabilidad } = this.props

        return (
            <div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem', 'margin-left': '2rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2rem' }}></i>
                    <div className="p-text-normal" style={{ 'margin-left': '1rem' }}>¿Está seguro que desea borrar la habilidad "{nombreHabilidad}"?</div>
                </div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem' }}>
                    <div style={{ 'margin-left': 'auto', 'margin-right': '2rem' }}>
                        <Button label="Si" onClick={() => eliminarHabilidad(habilidadId)}  ></Button>
                        <Button label="No" onClick={() => cerrarModalEditarHabilidad()} style={{ 'margin-left': '1rem' }} className="p-button-secondary"></Button>
                    </div>
                </div>
            </div>
        )
    }

    actionBodyTemplate(rowData) {
        const { seleccionarFila, abrirModalDialogo } = this.props;
        //console.log("rowData", rowData)
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" title='Editar' className="p-button-rounded p-button-info p-mr-1" onClick={() => seleccionarFila(rowData)} />
                <Button icon="pi pi-trash" title='Borrar' className="p-button-rounded p-button-help p-mr-1" onClick={() => abrirModalDialogo({ 'uid': rowData.uid, 'idDoc': rowData.id })}></Button>
            </React.Fragment>
        );
    }

    actionBodyTemplateHabilidades(rowData) {
        const { abrirModalEliminar, seleccionarFilaHabilidad } = this.props;

        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" title='Editar' className="p-button-rounded p-button-info p-mr-1" onClick={() => seleccionarFilaHabilidad(rowData)} />
                <Button icon="pi pi-trash" title='Borrar' className="p-button-rounded p-button-help p-mr-1" onClick={() => abrirModalEliminar({ 'idDoc': rowData.value, 'label': rowData.label })}></Button>
            </React.Fragment>
        );
    }
    validationsHabilidad = (values) => {
        const errors = {}
        const requiredFields = [
            'label',
        ]
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Obligatorio'
            }
        })
        return errors
    }

    renderFormEditarHabilidad() {
        const { message, editarHabilidad, selectedReduxFormHabilidades, cerrarModalEditarHabilidad } = this.props
        let FORM_HABILIDADES = [
            {
                name: "label",
                placeholder: "Escriba el nombre",
                options: [],
                disable: false,
                typeForm: 'text',
                display: true,
                label: 'Nombre',
                type: 'text',
            }
        ]

        let getFormButtons = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary btn-block btnGuardar",
                    action: false,
                    onClick: handlerSafe,
                    display: true,
                    primary: true,


                },
                {
                    label: "Cancelar",
                    className: "btn btn-primary btnCancelar",
                    action: false,
                    onClick: () => cerrarModalEditarHabilidad(),
                    display: true,

                }
            ]
        }

        return (
            <div>
                {message ?
                    <React.Fragment>
                        <Alert className="alert danger" variant="danger">{message}</Alert>
                    </React.Fragment>
                    : null
                }
                <CustomReduxForm
                    submitable={false}
                    formName={FORM_HABILIDADES_NAME}
                    items={FORM_HABILIDADES}
                    handleSubmit={() => editarHabilidad(selectedReduxFormHabilidades)}
                    className="splash"
                    positionButtons="habilidadesEditarButtons"
                    buttons={getFormButtons(() => editarHabilidad(selectedReduxFormHabilidades))}
                    validations={this.validationsHabilidad}
                />

            </div>)
    }



    renderHabilidadesTable() {
        const { listarHabilidades, selectedReduxFormHabilidades, habilidades, abrirModalEditarHabilidad, cerrarModalEditarHabilidad, crearHabilidad, cerrarModalEliminarHabilidad, abrirModalEliminarHabilidad } = this.props
        listarHabilidades();
        let getFormButtons = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary btn-block btnGuardar",
                    action: false,
                    onClick: handlerSafe,
                    display: true,
                    primary: true,
                }
            ]
        }
        let FORM_NUEVA_HABILIDAD = [
            {
                name: "nombre",
                placeholder: "Escriba el nombre",
                options: [],
                disable: false,
                typeForm: 'text',
                display: true,
                label: 'Nueva Habilidad',
                type: 'text',
            }
        ]
        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                        onInput={(e) => this.setState({ globalFilterHabilidades: e.target.value })} />
                </span>
            </div>
        );

        return (

            <div>
                {this.renderModal(
                    abrirModalEditarHabilidad,
                    this.renderFormEditarHabilidad(),
                    cerrarModalEditarHabilidad,
                    "Editar habilidad",
                    "editarHabilidad"
                )}
                {this.renderModal(
                    abrirModalEliminarHabilidad,
                    this.renderDeleteDialogHabilidad(),
                    cerrarModalEliminarHabilidad,
                    "Eliminar habilidad",
                    "eliminarHabilidad"
                )}

                <div>
                    <CustomReduxForm
                        submitable={false}
                        formName={FORM_HABILIDADES_NAME}
                        items={FORM_NUEVA_HABILIDAD}
                        handleSubmit={() => crearHabilidad(selectedReduxFormHabilidades)}
                        className="nuevaHabilidad"
                        positionButtons="habilidadesAnadirButton"
                        buttons={getFormButtons(() => crearHabilidad(selectedReduxFormHabilidades))}
                        validations={this.validationsHabilidad}
                    />
                </div>

                <DataTable value={habilidades} resizableColumns columnResizeMode="fit" className="p-datatable-gridlines" globalFilter={this.state.globalFilterHabilidades}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} dataKey="id"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} ">
                    <Column field="label" header="Nombre"></Column>
                    <Column headerStyle={{ width: '100px' }} header="Acciones" body={this.actionBodyTemplateHabilidades}></Column>

                </DataTable>

            </div>)
    }

    validations = (values) => {

        const errors = {}

        const requiredFields = [
            'nombres',
            'apellidos',
            'contraseña',
            'nickname',
            'rol',
            'habilidad',
            'correo'
        ]

        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Obligatorio'
            }
        })
        if (
            values.correo &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.correo)
        ) {
            errors.correo = 'Correo inválido'
        }

        if (values.password !== values.passwordValidacion) {
            errors.password = 'Las contraseña no coinciden'
        }
        if (values.password && values.password.length < 6) {
            errors.password = 'La contraseña debe tener mas de 6 caracteres'
        }

        return errors
    }

    renderFormEditar(buttons, itemsForm) {
        const { editarUsuario, crearUsuario, selectedReduxForm, listarHabilidades, disableField, message } = this.props;
        listarHabilidades();
        let accion;
        if (disableField) {
            accion = editarUsuario
        } else {
            accion = crearUsuario
        }

        return (
            <div>
                {message ?
                    <React.Fragment>
                        <Alert className="alert danger" variant="danger">{message}</Alert>
                    </React.Fragment>
                    : null
                }
                <CustomReduxForm
                    submitable={false}
                    formName={FORM_USUARIOS_NAME}
                    items={itemsForm}
                    handleSubmit={() => accion(selectedReduxForm)}
                    className="splash"
                    positionButtons="fixedPositionButton"
                    buttons={buttons(() => accion(selectedReduxForm))}
                    validations={this.validations}
                />
            </div>
        )

    }


    render() {
        const {
            usuarios,
            habilidades,
            abrirModalFormEditar, cerrarModal,
            tituloModal,
            disableField,
            abrirModalBorrar,
            modalHabilidades

        } = this.props;


        let OPCIONES_ROL = [
            { label: 'AGENTE', value: 'AGENTE' },
            { label: 'SUPERVISOR', value: 'SUPERVISOR' }
        ]

        let FORM_USUARIOS = [
            {
                name: "nombres",
                placeholder: "Escriba el nombre",
                options: [],
                disable: false,
                typeForm: 'text',
                display: true,
                label: 'Nombres',
                type: 'text',
            },
            {
                name: "apellidos",
                placeholder: "Escriba el apellido",
                options: [],
                disable: false,
                typeForm: 'text',
                display: true,
                label: 'Apellidos'

            },
            {
                name: "correo",
                placeholder: "Escriba el correo",
                options: [],
                disable: true,
                typeForm: disableField ? 'textDisabled' : 'text',
                display: true,
                label: 'Email'
            },
            {
                name: "nickname",
                placeholder: "Escriba el nickname",
                options: [],
                disable: false,
                typeForm: 'text',
                type: 'text',
                display: true,
                label: 'Nickname'
            },
            {
                name: "rol",
                placeholder: "Seleccione un rol",
                options: OPCIONES_ROL,
                disable: false,
                type: 'text',
                typeForm: 'select',
                display: true,
                label: 'Rol'

            },
            {
                name: "habilidadId",
                placeholder: "Seleccione una habilidad",
                options: habilidades ? habilidades : [],
                disable: false,
                type: 'text',
                typeForm: 'select',
                display: true,
                label: 'Habilidad'
            },
            {
                name: "password",
                placeholder: "Escriba la contraseña",
                options: [],
                disable: false,
                typeForm: 'password',
                type: 'password',
                display: true,
                label: 'Contraseña'
            },
            {
                name: "passwordValidacion",
                placeholder: "Escriba la contraseña nuevamente",
                options: [],
                disable: false,
                typeForm: 'password',
                type: 'password',
                display: true,
                label: 'Contraseña validación'
            }
        ]

        let getFormButtons = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary btn-block btnGuardar",
                    action: false,
                    onClick: handlerSafe,
                    display: true,
                    primary: true,


                },
                {
                    label: "Cancelar",
                    className: "btn btn-primary btnCancelar",
                    action: false,
                    onClick: () => cerrarModal(),
                    display: true,

                }
            ]
        }

        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })} />
                </span>
            </div>
        );



        return (
            <React.Fragment>
                <Card className='usuarios' >
                    <Toast ref={(el) => this.toast = el} />

                    {this.renderModal(
                        abrirModalFormEditar,
                        this.renderFormEditar(getFormButtons, FORM_USUARIOS),
                        cerrarModal,
                        tituloModal ? tituloModal : 'Sin título',
                        "editarUsuario"
                    )}
                    {this.renderModal(
                        abrirModalBorrar,
                        this.renderDeleteDialog(),
                        cerrarModal,
                        "Confirmación",
                        "eliminarUsuario"
                    )}
                    {this.renderModal(
                        modalHabilidades,
                        this.renderHabilidadesTable(),
                        cerrarModal,
                        "Habilidades",
                        "habilidades"
                    )}

                    <div className='p-card-title'>Usuarios</div>
                    <div className='p-card-content'>
                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable value={usuarios} resizableColumns columnResizeMode="fit" className="p-datatable-gridlines" globalFilter={this.state.globalFilter}
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} dataKey="id"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} ">
                            <Column field="nombres" header="Nombre"></Column>
                            <Column field="apellidos" header="Apellidos"></Column>
                            <Column field="correo" header="Correo"></Column>
                            <Column field="nickname" header="Nickname"></Column>
                            <Column field="rol" header="Rol"></Column>
                            <Column field="skill.nombre" header="habilidad"></Column>
                            <Column headerStyle={{ width: '100px' }} header="Acciones" body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    </div>

                </Card>

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuarios: getUsuarios(state),
        selectedForm: getSelectedForm(state),
        selectedReduxForm: getSelectedReduxForm(state),
        selectedReduxFormHabilidades: getSelectedReduxFormHabilidad(state),
        habilidades: getHabilidades(state),
        toastDetail: toastDetail(state),
        showToast: showToast(state),
        updateTable: updateTableUsuarios(state),
        ocultarModal: ocultarModal(state),
        abrirModalFormEditar: abrirModalEditar(state),
        tituloModal: tituloModal(state),
        disableField: disabledEmailField(state),
        abrirModalBorrar: abrirModalBorrar(state),
        abrirModalEliminarHabilidad: abrirModalEliminarHabilidad(state),
        uidUser: uidUser(state),
        message: message(state),
        modalHabilidades: modalHabilidades(state),
        abrirModalEditarHabilidad: abrirModalEditarHabilidad(state),
        habilidadId: idDocHabilidad(state),
        nombreHabilidad: nombreHabilidad(state)


    };
}

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        listarUsuarios: (value) => dispatch({ type: LISTAR_USUARIOS, value }),
        editarUsuario: (value) => dispatch({ type: EDITAR_USUARIO, value }),
        listarHabilidades: (value) => dispatch({ type: GET_HABILIDADES, value }),
        crearUsuario: (value) => dispatch({ type: CREAR_USUARIO, value }),
        seleccionarFila: (value) => dispatch({ type: SELECT_ROW, value }),
        abrirModalEditarUsuarios: (value) => dispatch({ type: ABRIR_MODAL_USUARIOS, value }),
        cerrarModal: (value) => dispatch({ type: CERRAR_MODAL_USUARIOS, value }),
        eliminarUsuario: (value) => dispatch({ type: ELIMINAR_USUARIO, value }),
        abrirModalDialogo: (value) => dispatch({ type: ABRIR_MODAL_BORRAR, value }),
        abrirModalHabilidades: (value) => dispatch({ type: ABRIR_MODAL_HABILIDADES, value }),
        seleccionarFilaHabilidad: (value) => dispatch({ type: SELECT_ROW_HABILIDAD, value }),
        cerrarModalEditarHabilidad: (value) => dispatch({ type: CERRAR_MODAL_EDITAR_HABILIDAD, value }),
        editarHabilidad: (value) => dispatch({ type: EDITAR_HABILIDAD, value }),
        crearHabilidad: (value) => dispatch({ type: CREAR_HABILIDAD, value }),
        abrirModalEliminar: (value) => dispatch({ type: MODAL_ELIMINAR_HABILIDAD, value }),
        cerrarModalEliminarHabilidad: (value) => dispatch({ type: CERRAR_MODAL_ELIMINAR_HABILIDAD, value }),
        eliminarHabilidad: (value) => dispatch({ type: ELIMINAR_HABILIDAD, value })


    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(UsuariosComponent);