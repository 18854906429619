import React, { Component } from "react";
import "./style.css";
//import "./verWidget.css";
//import "./widget-style.css";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Iframe from 'react-iframe'
import { getMenuItemsChatBot, getUsers, getuploadImage, selectDeleteIntentDialogflow, selectGetBots, selectGetIntent, selectGetIntents, selectIntent, selectLoading, selectUploadIntent, selectorShowToast, selectorToastDetail, selectupdateWidget } from "./selectors";
import { Spinner } from 'react-bootstrap';
import ScriptTag from 'react-script-tag';
import {
    CREATE_AGENT,
    DELETE_DIALOGFLOW_INTENT,
    DELETE_INTENT,
    EDIT_INTENT,
    EDIT_INTENT_SUCCESS,
    GET_BOTS,
    GET_INTENT,
    GET_INTENTS_SUCCESS,
    GET_USERS, LIST_DIALOGFLOW_INTENT, LOAD_MENU, NOMBRE_BOT, UPLOAD_IMAGE
} from './constants';
import { getFromSession } from "../../controller/session";
import { Box, Button, Paper, TextField } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import ComponentTable from "./intentTable/componentTable";
import ComponentCrearIntent from "./crearIntents/component";
import ComponentPublicar from "./publicar/component";
import ComponentWhatsApp from "./publicarWhatsApp/component";
import { Widget } from 'react-chat-widget';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-chat-widget/lib/styles.css';
import ComponentConfiguration from "./configuration/configuration"
import ComponentConfEliminar from "./componentConfEliminar";
import ComponentBotNoValido from "./componentBotNoValido";
import Configuration from "./configuration/configuration";
import CircularProgress from "@material-ui/core/CircularProgress";
import ComponentWidget from "./crearIntents/widget-chatbot";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Toast } from "primereact/toast";
import ComponentError from "./errorPopUpComponent";

class ComponentCrearChatBot extends Component {
    constructor(props) {
        super(props);
        const { intentsData } = props
        this.state = {
            activePath: '/',
            chatbot: false,
            publicar: false,
            whatsapp: false,
            deleteModal: false,
            configuration: false,
            popCancel: false,
            botWidget: false,
            popUpConFig: false,
            nombreNoValido: true,
            titlePopUp: false,
            bodyPopup: false,
            filtroTable: [],
            labelTable: 'Tabla preguntas creadas para el ChatBot',
            columnsTable: {
                columna1: 'Preguntas del Bot',
                columna2: 'Fecha de actualización'
            },
            columnField: {
                columna1: 'nombreIntent',
                columna2: 'fecha'
            }
        }
    }
    onConfigurationHandler = () => {
        this.setState({ configuration: true });
    }
    onConfigurationHandlerClose = () => {
        this.setState({ configuration: false });
        console.log("onConfigurationHandlerClose", this.state.configuration)
        //this.showWidgetChat()
    }

    onButonClickCrearIntent = () => {
        this.setState({ intent: true });
    }

    onReturnToComponentNombreBot = () => {
        this.setState({ intent: false });
        console.log("onReturnToComponentNombreBot")
    }

    onButonClickPublicar = () => {
        this.setState({ publicar: true }
        );
    }

    onPublicarCancel = () => {
        this.setState({ publicar: false }
        );
    }

    onModalPublicarWhatsApp = () => {
        this.setState({ whatsapp: true }
        )
        this.setState({ publicar: false }
        );
    }
    onModalWhatsAppCancel = () => {
        this.setState({ whatsapp: false }
        )
        this.setState({ publicar: false }
        );
    }
    onModalCancelClose = () => {
        this.setState({ deleteModal: false }
        )
        this.setState({ publicar: false }
        );
    }

    onGoBack = () => {
        window.history.back()
    }

    onEditButton = (value) => {
        const { intentsData, getBotsSelector, editIntent, intentSelect } = this.props

        const intentEdit = intentsData.find(
            val => val.nombreIntent === value
        );

        const infoIntent = {
            idIntent: intentEdit.id,
            nombreChatBot: getBotsSelector.nombreChatBot
        }

        this.setState({ intent: infoIntent });
    }

    // hideWidgetChat = () => {
    //     var widgetChatElement = document.getElementById("react-widget-frame");
    //     widgetChatElement.classList.remove("react-widget-show");
    //     widgetChatElement.classList.add("react-widget-hide");
    // }

    componentDidMount() {
        const { uploadImage, listProjectSelector, getBots, nombreBot, getIntents, createAgent, companyData } = this.props;
        //this.showWidgetChat()
        uploadImage()

        //getIntents(getBotsSelector.nombreChatBot);
        getIntents(nombreBot);





        //document.body.appendChild(script);
    }
    // showWidgetChat = () => {
    //     var widgetChatElement = document.getElementById("react-widget-frame");

    //     widgetChatElement.classList.remove("react-widget-hide");
    //     widgetChatElement.classList.add("react-widget-show");
    //     console.log("SHOOOW")

    // }

    componentWillMount() {
        const { getUsers, loading, getBots, createAgent, companyData, nombreBot, listProjectSelector, getBotsSelector, loadMenuChatbot, listIntentDialogflow, uploadImage, getIntents } = this.props;
        getUsers()
        uploadImage()
        getBots()
        loadMenuChatbot();
        //document.getElementsByClassName("rcw-launcher")[0].click()
        // if (listProjectSelector) {
        //     console.log("NO CREAR PROYECTO")
        //     this.setState({ nombreNoValido: false });
        // }
        // if (listProjectSelector === 'undefined') {
        //     this.setState({ nombreNoValido: true });

        // } if (listProjectSelector === false) {
        //     console.log("CREAR PROYECTO Y AGENT")
        //     createAgent(nombreBot);
        //     getBots(nombreBot);
        //     this.setState({ nombreNoValido: true });
        //     getIntents(nombreBot);
        // }


        //this.showWidgetChat();
        // setTimeout(() => {
        //     getIntents(nombreBot);
        // }, 21000)
        //console.log("getBotsSelector crar compo",getBotsSelector.nombreChatBot)
        //listIntentDialogflow();
    }

    componentWillUnmount() {
        //this.hideWidgetChat()
        //console.log("UNMOUNTTTT ******")
    }



    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { intentsData } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = intentsData.filter(function (objeto) {
            //  console.log("filer objet", objeto)
            // Convertir el valor de la propiedad y el texto de búsqueda a minúsculas para una comparación insensible a mayúsculas
            var valorPropiedad = String(objeto.nombreIntent).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }

    componentDidUpdate(prevProps) {
        const { intentSelect, getIntents,
            showToastSelector,
            toastDetailSelector,
            loading, updateWidgetSelector, getBots, nombreBot, intentsData, getBotsSelector, uploadIntentSelector, deleteIntentDialogflowSelector } = this.props;
        if (prevProps.showToastSelector !== showToastSelector) {
            this.toast.show({
                severity: toastDetailSelector.severity,
                summary: toastDetailSelector.summary,
                detail: toastDetailSelector.detail,
            });
        }

        if (prevProps.intentsData !== intentsData) {
            // console.log("intentsData", intentsData)
            //  console.log("prevProps.intentsData", prevProps.intentsData)
            this.setState({
                filtroTable: [...intentsData]
            })
        }
        //getBots();
        if (uploadIntentSelector) {


            getIntents(getBotsSelector.nombreChatBot);

            //getIntents(getBotsSelector.nombreChatBot)
        }
        if (deleteIntentDialogflowSelector) {

            getIntents(getBotsSelector.nombreChatBot);

        }

        if (prevProps.intentSelect !== intentSelect) {
            //console.log("intentSelect componentDidUpdate", JSON.stringify(intentSelect))
            this.setState({ intentData: intentSelect });
        }
        // if(!loading) { 
        //     setTimeout(() => {
        //         //getIntents(nombreBot);
        //         document.getElementsByClassName("rcw-launcher")[0].click()
        //         console.log("CLICKC")
        //     }, 9000)
        // }
    }
    onDeleteButton = (value) => {
        //console.log("VALLUE ONDELETE BUTTON", value)
        const { deleteIntent, intentsData, getBots, getBotsSelector, getIntents, deleteIntentDialogflow } = this.props
        getBots()

        const intent = intentsData.find(
            val => val.nombreIntent === value
        );

        const infoIntent = {
            idIntent: intent.id,
            nombreChatBot: getBotsSelector.nombreChatBot
        }

        deleteIntent(infoIntent);
        deleteIntentDialogflow(value);

        // setTimeout(() => {
        //     getIntents(getBotsSelector.nombreChatBot);
        // }, 2000)


        this.onConfirmDeletePopUp();

    }



    onDeleteModalClose = () => {
        this.setState({ popCancel: false });
    }

    onDeleteModalOpen = (value) => {
        this.setState({ popCancel: true });
        //console.log("ESTADO POPCANCEL", this.state.popCancel)
    }
    onConfirmarPopUp = (payload) => {
        console.log("CREATE POP UP", payload)
        if (payload === 'list') {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta creada',
                bodyPopup: 'La respuesta ha sido guardada exitosamente, recuerda crear respuestas para las opciones de la lista'
            });
        }
        if (payload === 'button') {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta creada',
                bodyPopup: 'La respuesta ha sido guardada exitosamente, recuerda crear respuestas para las opciones del botón'
            });
        } else {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta creada',
                bodyPopup: 'La respuesta ha sido guardada exitosamente.'
            });
        }

    }

    onCloseConfirmarPopUp = () => {
        this.setState({ popUpConFig: false });
    }

    onUpdatePopUp = (payload) => {
        console.log("UPDATE POP UP", payload)
        if (payload === 'list') {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta actualizada',
                bodyPopup: 'La respuesta ha sido modificada exitosamente, recuerda crear respuestas para las opciones de la lista'
            });
        }
        if (payload === 'button') {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta actualizada',
                bodyPopup: 'La respuesta ha sido modificada exitosamente, recuerda crear respuestas para las opciones de la lista'
            });
        } else {
            this.setState({
                popUpConFig: true,
                titlePopUp: 'Respuesta actualizada',
                bodyPopup: 'La respuesta ha sido actualizada exitosamente.'
            });
        }
    }

    onConfirmDeletePopUp = () => {
        this.setState({
            popUpConFig: true,
            titlePopUp: 'Respuesta eliminada',
            bodyPopup: 'La respuesta ha sido eliminada exitosamente'
        });
    }
    onUpdateProfilePopUp = () => {
        this.setState({
            popUpConFig: true,
            titlePopUp: 'Perfil actualizado',
            bodyPopup: 'Los datos ingresados se han actaulizado exitosamente'
        });
    }

    render() {
        const {
            nombreBot,
            getBotsSelector,
            intentsData,
            getIntents,
            listProjectSelector,
            intentSelect,
            updateWidgetSelector,
            companyData,
            onModalCancel,
            loading,

        } = this.props;
        const user = getFromSession("currentUser");
        // getIntents(nombreBot)
        //console.log("intentsData", intentsData)
        return (
            <div className="App home-container home-container-chatbots">

                {loading &&
                    <div className="loading-wrapper">

                        <CircularProgress color="secondary"></CircularProgress>
                    </div>
                }
                {this.state.popCancel ?

                    <ComponentConfEliminar
                        //onConfirmDeletePopUp={this.onConfirmDeletePopUp}
                        onModalCancel={this.onDeleteModalClose}
                        valueModal={this.state.popCancel}
                        onDelete={this.onDeleteButton}
                    /> : ''}
                {/*   {this.state.whatsapp ?
                                <ComponentWhatsApp
                                    onModalCancel={() => { this.onModalWhatsAppCancel() }}
                                />
                                : ''}

                                {this.state.publicar ?
                                <ComponentPublicar
                                    onModalClick={() => { this.onButonClickPublicar() }}
                                    onModalWhatsApp={() => { this.onModalPublicarWhatsApp() }}
                                    onModalCancel={() => { this.onPublicarCancel() }}
                                />
                                : ''}*/}

                {this.state.configuration ?
                    <Configuration style={{ "cursor": "pointer" }}
                        onConfigurationHandlerClose={this.onConfigurationHandlerClose}
                        company={companyData}
                        returnToNombreBot={this.onConfigurationHandlerClose}
                        onUpdateProfilePopUp={this.onUpdateProfilePopUp} /> :
                    <>

                        {this.state.intent ?
                            <>
                                <ComponentCrearIntent
                                    confirmarPopUp={this.onConfirmarPopUp}
                                    closePopUp={this.onCloseUpdatePopUp}
                                    updatePopUp={this.onUpdatePopUp}
                                    companyData={companyData}
                                    intent={this.state.intent}
                                    intentData={this.state.intentData}
                                    nombreBot={getBotsSelector.nombreChatBot}
                                    returnToNombreBot={this.onReturnToComponentNombreBot}
                                />

                            </>
                            :
                            <div className='chatbot-wrapper'>
                                <div className="titulo">
                                    <h1>
                                        Crea tu ChatBot
                                    </h1>

                                    <div className="align-button">
                                        {/* <SettingsIcon className="settings" onClick={() => this.onConfigurationHandler()} /> */}
                                    </div>
                                </div>


                                <div className="App bienvenida-container">
                                    <div className='crear-intent'>
                                        {companyData ?

                                            <>
                                                <div className="left">
                                                    <Paper elevation={10} className="paper-conversaciones">
                                                        <div className="align-button">
                                                            <Button color='primary' variant="contained" onClick={() => this.onButonClickCrearIntent()}>Crear pregunta</Button>
                                                            <Button color='primary' variant="contained" onClick={() => this.onConfigurationHandler()}>Configuración</Button>
                                                        </div>
                                                        <div className="search-container">

                                                            <span className="p-input-icon-left">
                                                                <i className="pi pi-search" />
                                                                <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar pregunta..." />
                                                            </span>
                                                        </div>
                                                        <div className="tabla-intents">
                                                            <ComponentTable
                                                                onEdit={this.onEditButton}
                                                                columnField={this.state.columnField}
                                                                columnsTable={this.state.columnsTable}
                                                                labelTable={this.state.labelTable}
                                                                onDelete={this.onDeleteButton}
                                                                data={this.state.filtroTable}
                                                            />
                                                        </div>
                                                        {/*<div className="align-button-auto">
                                                                    <Button color='primary' variant="contained">Cargar icono chatbot</Button>
                                                                    <Button color='primary' variant="contained" onClick={() => this.onButonClickPublicar()}>Publicar ChatBot</Button>
                                                                </div>*/}

                                                    </Paper>
                                                </div>

                                                {/*
                                                                    <div className="right-widget-hide">
                                                                
                                                                    {companyData.cuenta ?
                                                                        <iframe className="iframe" src={`${process.env.REACT_APP_CHAT_WIDGET}?companyId=${companyData.cuenta}`} />
                                                                        : <h1>La compañia selecciona no existe</h1>
                                                                    }

                                                                    <ComponentWidget
                                                                        company={companyData.cuenta} />
                                                                    
                                                                </div>
                                                                        */}
                                            </>

                                            :

                                            <div className="left">
                                                <Paper elevation={10} className="paper-conversaciones">
                                                    <div className="align-button">
                                                        <SettingsIcon className="settings" onClick={() => this.onConfigurationHandler()} />
                                                        <Button color='primary' variant="contained" onClick={() => this.onButonClickCrearIntent()}>Crear pregunta</Button>
                                                    </div>
                                                    <div className="search-container">

                                                        <span className="p-input-icon-left">
                                                            <i className="pi pi-search" />
                                                            <InputText type="search" placeholder="Buscar pregunta..." />
                                                        </span>
                                                    </div>
                                                    <div className="tabla-intents">
                                                        <ComponentTable
                                                            onEdit={this.onEditButton}
                                                            columnField={this.state.columnField}
                                                            columnsTable={this.state.columnsTable}
                                                            labelTable={this.state.labelTable}
                                                            onDelete={this.onDeleteButton}
                                                            data={intentsData}
                                                        />
                                                    </div>
                                                    {/*<div className="align-button-auto">
                                                                <Button color='primary' variant="contained">Cargar icono chatbot</Button>
                                                                <Button color='primary' variant="contained" onClick={() => this.onButonClickPublicar()}>Publicar ChatBot</Button>
                                                            </div>*/}

                                                </Paper>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="bot-wrapper">
                            {!loading ?
                                <>
                                    <iframe width="350px" className="react-widget-frame react-widget-show"
                                        id="react-widget-frame"
                                        src={`${process.env.REACT_APP_CHAT_WIDGET}?companyId=${companyData.cuenta}`}
                                    />

                                    <Toast ref={(el) => (this.toast = el)} />
                                </>
                                : ''
                            }
                        </div>
                    </>
                }


            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        intentsData: selectGetIntents(state),
        users: getUsers(state),
        menuItemsChatBot: getMenuItemsChatBot(state),
        intentSelect: selectIntent(state),
        uploadIntentSelector: selectUploadIntent(state),
        deleteIntentDialogflowSelector: selectDeleteIntentDialogflow(state),
        companyData: getuploadImage(state),
        getBotsSelector: selectGetBots(state),
        updateWidgetSelector: selectupdateWidget(state),
        loading: selectLoading(state),
        showToastSelector: selectorShowToast(state),
        toastDetailSelector: selectorToastDetail(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        getIntents: (value) => dispatch({ type: GET_INTENT, value }),
        uploadImage: () => dispatch({ type: UPLOAD_IMAGE, value: 1 }),
        loadMenuChatbot: (value) => dispatch({ type: LOAD_MENU, value }),
        deleteIntent: (value) => dispatch({ type: DELETE_INTENT, value }),
        deleteIntentDialogflow: (value) => dispatch({ type: DELETE_DIALOGFLOW_INTENT, value }),
        listIntentDialogflow: (value) => dispatch({ type: LIST_DIALOGFLOW_INTENT, value }),
        getBots: (value) => dispatch({ type: GET_BOTS, value }),
        getBots: () => dispatch({ type: GET_BOTS }),
        createAgent: (value) => dispatch({ type: CREATE_AGENT, value }),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentCrearChatBot);