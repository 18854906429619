import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PublicIcon from '@material-ui/icons/Public';
import { Icon } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ComponentDatosPedidos from "./datosPedido";
import ComponentDatosCliente from "./datosCliente";
import ComponentDatosTarjeta from "./datosTarjeta";
import Typography from '@material-ui/core/Typography';
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { getFromSession } from "../../../controller/session";

import { Paper } from "@material-ui/core";
import { ADQUIRIR_PRODUCTO, GET_TRANSACCION } from "../constants";
import { Polling, PollingComponent } from "./transaccion";
import { selectTransaccion } from "../selectors";

class ComponentConfPagos extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, } = this.props;

        this.state = {
            tipo: false,
            activePath: '/',
            activeStep: 0,
            valor: false,
            disabledButton: false,
            producto: false,
            path: false,
            pagos: true,
            toPull: false,
            refPagos: false,
            selectedOption: '¿Cuál es tu banco?',
            list: ['Datos del pedido', 'Datos de pago', 'Confirmar pago']
        }
    }

    handleRefNum = () => {
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let cadena = '';

        for (let i = 0; i < 15; i++) {
            const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
            cadena += caracteres.charAt(indiceAleatorio);
        }
        this.setState({
            refPagos: cadena,
        })
    }


    handlePull = () => {
        this.setState({ toPull: true })
        this.handleRefNum()
    }

    handleOptionChange = (option) => {
        this.setState({
            selectedOption: option
        })
        if (this.state.activeStep === 1 && option) {
            this.setState({
                disabledButton: false,
            })
        }
        console.log("HANDLE OPTION")
    };


    componentWillMount() {
        const {
            id,
            tipo
        } = this.props;
        if (id === '00003' || id === '00004') {
            this.setState({ pagos: false });
        }
        if (tipo === 'mensual') {
            if (id === '00001') {
                this.setState({ tipo: 'Prueba Gratis de 15 días' });

            } else if (id === '00002') {
                this.setState({ valor: 8900000 });
            }
        }
        if (tipo === 'anual') {
            if (id === '00005') {
                this.setState({ tipo: 'Prueba Gratis de 15 días' });

            } else if (id === '00006') {
                this.setState({ valor: 99900000 });
            }
        }
        // if(this.state.activeStep === 0 && this.state.selectedOption === '¿Cuál es tu banco?'){
        //     this.setState({ disabledButton: true});
        // }
        // if(this.state.selectedOption !== '¿Cuál es tu banco?'){
        //     this.setState({ disabledButton: false});
        // }

        // const queryString = window.location.search;
        //  const urlParams = new URLSearchParams(queryString);
        //   const oobCode = urlParams.get('oobCode');
        //bienvenidaPage(oobCode)
        //  console.log("bienvenidaPage(oobCode)", bienvenidaPage(oobCode))
    }

    handleNext = () => {
        this.setState({ activeStep: this.state.activeStep + 1 });
        console.log("handle newxt", this.state.activeStep)
        if (this.state.activeStep === 0) {
            this.setState({
                disabledButton: true,
            })
        }

    };
    handleBack = () => {
        this.setState({ activeStep: this.state.activeStep - 1 });
        console.log("handle back", this.state.activeStep)
        if (this.state.activeStep === 1) {
            this.setState({
                disabledButton: false,
            })
        }
    };


    handleReset = () => {
        this.setState({ activeStep: 0 })
    };

    confirmPagos = () => {
        const {
            id,
            adquirirProducto,
            tipo
        } = this.props;
        if (window.location.pathname === '/planes/:1050' || window.location.pathname === '/autobot') {
            this.setState({ producto: 'ChatBot AutoGestionable', path: '/autobot' });
            //  adquirirProducto(id, tipo, 'ChatBot AutoGestionable')

        }
        if (window.location.pathname === '/planes/:1051' || window.location.pathname === '/imageOCR') {
            this.setState({ producto: 'Reconocimiento de Imágenes', path: '/imageOCR' });
            //adquirirProducto(id, tipo, 'Reconocimiento de Imágenes')
        }
        this.handleNext()
        this.handlePull()
    }


    getStepContent(step) {
        const {
            id,
            tipo
        } = this.props;
        switch (step) {
            case 0:
                return <ComponentDatosPedidos
                    id={id}
                    tipo={tipo}
                />;
            case 1:
                return <ComponentDatosTarjeta
                    id={id}
                    tipo={tipo}
                    handleOptionChange={this.handleOptionChange}
                />;
            case 2:
                return <ComponentDatosCliente
                    activeStep={this.state.activeStep}
                    disabledButton={this.state.disabledButton}
                    producto={this.state.producto}
                    path={this.state.path}
                    tipo={this.state.tipo}
                    valor={this.state.valor}
                />;

            default:
                return 'Unknown step';
        }
    }


    render(

    ) {
        const {
            id,
            items,
            tipo,
            onBackToCard,
            adquirirProducto,
            getTransaccion,
            getTransaccionBot,
            transaccionSelector,
            getTransaccionOCR
        } = this.props;

        const user = getFromSession("currentUser");
        const company = getFromSession("company");
        return (
            <div className="App home-container">


                <Paper className="main-wrapper home container">

                    <div className="paper-conversaciones-planes">

                        <div className="button-ocr-left">
                            <Button style={{ "marginTop": "10px" }} onClick={() => onBackToCard()} color='primary' variant="contained">Volver</Button>
                        </div>
                        {this.state.pagos ?

                            <div className={'root'}>
                                <Stepper activeStep={this.state.activeStep} alternativeLabel>
                                    {this.state.list.map((label) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <div>
                                    {this.state.activeStep === this.state.list.length ? (
                                        <div>
                                            <Typography className={'instructions'}> Confirmar </Typography>
                                            {this.state.tipo === 'Prueba Gratis de 15 días' ?
                                                <>
                                                    {/* <Link style={{ "margin": "10px" }} to={process.env.REACT_APP_BIENVENIDA} target="_blank" onClick={() => adquirirProducto(id, tipo, this.state.producto)} className='sidebar-nav-link btn btn-primary '>
                                                        Abrir
                                                    </Link> */}

                                                    <Button color="primary" style={{ "margin": "10px" }} variant="contained" target="_blank" onClick={() => adquirirProducto(id, tipo, this.state.producto)} href={process.env.REACT_APP_BIENVENIDA}>
                                                        Comfirmar
                                                    </Button>
                                                </> :
                                                <form action="https://checkout.wompi.co/p/" method="GET" target="_blank">
                                                    <input type="hidden" name="redirect-url" value={process.env.REACT_APP_BIENVENIDA} />
                                                    <input type="hidden" name="public-key" value="pub_test_0r6VCNEh8pDLEJ9dkJKAMzj15wKBWLNB" />
                                                    <input type="hidden" name="currency" value="COP" />
                                                    <input type="hidden" name="amount-in-cents" value={this.state.valor} />
                                                    <input type="hidden" name="reference" value={this.state.refPagos} />
                                                    <input type="hidden" name="customer-data:email" value={company.correo} />
                                                    <input type="hidden" name="customer-data:full-name" value={company.nombres} />
                                                    <input type="hidden" name="customer-data:legal-id" value={company.num_documento} />
                                                    <input type="hidden" name="customer-data:legal-id-type" value={company.tipo_documento} />
                                                    <input type="hidden" name="shipping-address:country" value="CO" />
                                                    <input type="hidden" name="shipping-address:city" value="CALI" />
                                                    <input type="hidden" name="shipping-address:phone-number" value="+573104279839" />
                                                    <input type="hidden" name="shipping-address:region" value="VALLE DEL CAUCA" />
                                                    <input type="hidden" name="shipping-address:address-line-1" value="CALI" />

                                                    <Button type="submit" style={{ "margin": "10px" }} color='primary' variant="contained"

                                                    >Pagar con Wompi
                                                    </Button>
                                                </form>
                                            }
                                        </div>
                                    ) : (
                                        <div style={{ "padding": "20px", "margin": "15px" }}>
                                            <div className={'instructions'}>{this.getStepContent(this.state.activeStep)}</div>
                                            <div style={{ "margin": "15px" }}>
                                                <Button style={{ "margin": "10px" }} color='primary' variant="contained"
                                                    onClick={this.handleBack}
                                                    className="backButton"
                                                    disabled={this.state.activeStep === 0}
                                                >
                                                    Volver
                                                </Button>
                                                <Button style={{ "margin": "10px" }} color='primary' variant="contained"
                                                    onClick={this.state.activeStep === this.state.list.length - 1 ? this.confirmPagos : this.handleNext}
                                                    className="backButton"
                                                    disabled={this.state.disabledButton}

                                                >
                                                    {this.state.activeStep === this.state.list.length - 1 ? 'Siguiente' : 'Siguiente'}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <div className="label-align">
                                <h3>
                                    Para contactar con el area de ventas, por favor envia un correo a chatasesores@gmail.com con tu solicitud
                                </h3>
                            </div>
                        }
                        {this.state.toPull ?
                            <>
                                <PollingComponent
                                    getTransaccion={getTransaccion}
                                    getTransaccionBot={getTransaccionBot}
                                    getTransaccionOCR={getTransaccionOCR}
                                    referencia={this.state.refPagos}
                                    id={id}
                                    tipo={tipo}
                                    producto={this.state.producto}
                                    path={this.state.path}
                                    transaccionSelector={transaccionSelector}
                                />

                            </>
                            :
                            ''
                        }
                    </div>
                </Paper >
            </div >
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        transaccionSelector: selectTransaccion(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        adquirirProducto: (id, tipo, producto) => dispatch({ type: ADQUIRIR_PRODUCTO, id, tipo, producto }),
        getTransaccion: (referencia, id, tipo, producto, data) => dispatch({ type: GET_TRANSACCION, referencia, id, tipo, producto, data }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentConfPagos);