import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { menuItemsOCR } from "../../../Constants";
import SideBar from "../../../components/sidebar/sidebar";
import { DATA_DOC_CARGADOS, LINE_CHART, UPDATE_FIELD_ATTRIBUTE } from "./constants";
import { getFromSession } from "../../../controller/session";
import { getSelectedForm, } from "./selectors";
import { COMPANY_DATA } from "./constants";
import { Paper } from "@material-ui/core";
import ComponentEstadisticasGenerales from "./estadisiticasGenerales";
import { getDataCompanySelector } from "./selectors";
import CustomForm from "../../../components/customform/customform";


class ComponentEstadistica extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, tipoDoc } = this.props;
        this.state = {

        }

    }
    componentDidMount() {
        const { parameterUrl, selectedFormSelector } = this.props;

        const valores = window.location.search;
        var type = false;
        if (valores.length > 0) {
            const urlParams = new URLSearchParams(valores);
            type = urlParams.get('selectType');
            parameterUrl({ type })
            selectedFormSelector.fechaRango = 'HOY';
            selectedFormSelector.estadisticas = type;
        }
        console.log("selectedFormSelector d", selectedFormSelector)
    }

    componentWillMount() {
        const {
            companyData,
            parameterUrl,
            selectedFormSelector
        } = this.props;
        companyData();


        const valores = window.location.search;
        var type = false;
        if (valores.length > 0) {
            const urlParams = new URLSearchParams(valores);
            type = urlParams.get('selectType');
            parameterUrl({ type })
            selectedFormSelector.fechaRango = 'HOY';
            selectedFormSelector.estadisticas = type;
        }
        console.log("selectedFormSelector w", selectedFormSelector)

    }



    componentDidUpdate(prevProps) {
        const {
            dataDocumentParaGrafica,
            selectedFormSelector,
            obtenerDatosGraficaLineChart
        } = this.props;
        dataDocumentParaGrafica(selectedFormSelector)
        obtenerDatosGraficaLineChart(selectedFormSelector)

    }





    render() {
        const {
            selectorDataCompany,
            updateAttributes,
            selectedFormSelector
        } = this.props;




        let opcionesRangos = [
            { label: "Hoy", value: 'HOY' },
            { label: "Ayer", value: 'AYER' },
            { label: "Últimos 7 días", value: '7DIAS' },
            { label: "Últimos 15 días", value: '15DIAS' },
            { label: "Esta semana", value: 'ESTA_SEMANA' },
            { label: "Este mes", value: 'ESTE_MES' },
            { label: "Anterior mes", value: 'ANTERIOR_MES' },
            //{ label: "Personalizado", value: 'CUSTOM' }
        ];

        let FILTER_ESTADISTICAS = [
            {
                name: "estadisticas",
                placeholder: "Seleccione una opción",
                value: false,
                options: opcionesRangos,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione una opción",
                display: true

            }

        ]
        let FILTER = [
            {
                /* label: "Rango de tiempo", */
                name: "fechaRango",
                placeholder: "Seleccione una opción",
                value: false,
                options: opcionesRangos,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione una opción",
                display: true

            }
        ]

        const user = getFromSession("company_id")
        return (
            // <Paper className="main-wrapper home container"></Paper>
            <Paper className="main-wrapper home container">

                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}

                {/* {loading &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                } */}


                <>

                    <div className="subs-containere">
                        <div>
                            <h4 className="tituloComponente">INFORMES Y ESTADISTICAS</h4>
                        </div>

                        <div className='p-grid p-mt-3'>
                            <h4 className="h3-fixed p-ml-4">Seleccione el rango</h4>
                            <CustomForm
                                formName="filterFechaInformes"
                                items={FILTER}
                                values={FILTER}
                                updateAction={updateAttributes}
                                style="splash-container-search"
                                bodyStyle="body-informesPanel"
                                styleFormgroup="group-informesPanel"
                                classnameDiv="none"
                                formRow='form-row'
                                positionButton='fixed-button-filter'
                            >
                            </CustomForm>

                        </div>


                        <ComponentEstadisticasGenerales />


                    </div>



                </>

            </Paper>

        );
    }
}

const mapStateToProps = state => {
    return {
        selectorDataCompany: getDataCompanySelector(state),
        selectedFormSelector: getSelectedForm(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        dataDocumentParaGrafica: (value) => dispatch({ type: DATA_DOC_CARGADOS, value }), 
        obtenerDatosGraficaLineChart: (value) => dispatch({ type: LINE_CHART, value })



    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentEstadistica);