import { firebaseDatabase } from "./firebase";


export function* consultaProperty(company, prop) {
	try {
		var resp = false;
		if (company) { //Se consulta por company
			yield firebaseDatabase.collection(`company/${company}/configuration`).doc(prop).get().then(
				(snapshot) => {
					if (snapshot.exists) {
						resp = snapshot.data().value;
					};
				});
		} else {//Se consulta configuracion general
			yield firebaseDatabase.collection(`configuration`).doc(prop).get().then(
				(snapshot) => {
					if (snapshot.exists) {
						resp = snapshot.data().value;
					};
				});
		}
		return resp;
	} catch (err) {
		console.error('configuration.consultarProperty :' + err);
		throw err;
	}
}

export function* editProperty(company, prop, value) {
	try {
		let resp = false
		yield firebaseDatabase.collection(`company/${company}/configuration`).doc(prop).update({
			value: value
		}).then(() => {
			//console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})

		return resp;
	} catch (error) {
		console.error('configuration.editProperty' + error);
		throw error
	}
}

export function* guardarNuevoHorario(nuevoHorario, company) {
	try {

		let resp = false

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc('HORARIO_HABIL').update({
			value: nuevoHorario
		}).then(() => {
			console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})
		return resp;
	} catch (error) {
		console.error('controller.configuration.anadirDiasEspecificosHorario', error);
		throw error;
	}


}
export function* editarHorarioRegHours(horarioActual, nuevoValor, company) {
	try {
		horarioActual['REG_HOURS'] = nuevoValor;
		/* 	console.log('horarioActual', horarioActual); */

		let resp = false

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc('HORARIO_HABIL').update({
			value: horarioActual
		}).then(() => {
			console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})
		return resp;
	} catch (error) {
		console.error('controller.configuration.editarHorarioRegHours', error);
		throw error;
	}
}

export function* editarHorarioWeekendHours(horarioActual, nuevoValor, company) {
	try {
		horarioActual['WEEKEND_HOURS'] = nuevoValor;
		console.log('horarioActual', horarioActual);

		let resp = false

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc('HORARIO_HABIL').update({
			value: horarioActual
		}).then(() => {
			console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})
		return resp;
	} catch (error) {
		console.error('controller.configuration.editarHorarioWeekendHours', error);
		throw error;
	}
}


export function* guardarFormClienteWeb(formValues, company, document) {
	try {
		let resp = false

		yield firebaseDatabase.collection(`company/${company}/configuration`).doc(`${document}`).update({
			value: formValues
		}).then(() => {
			console.log('Document succesfully updated');
			resp = true

		}).catch((error) => {
			console.error("Error", error);
			resp = false
		})
		return resp;
	} catch (error) {
		console.error('controller.configuration.guardarFormClienteWeb', error);
		throw error;

	}
}



