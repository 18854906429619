import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

class ComponentWalcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
       
    }

    componentWillMount() {
      
    }

  


    render() {
        const {
            handlerOCR
        } = this.props;

        return (
            <div className="App borrador-container">
                <Button onClick={()=>handlerOCR()} color='primary' variant="contained">
                    Comenzar
                </Button>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentWalcome);