import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { COMPANY_DATA, FIN_SUSCRIBCION, GET_PLANES, GET_TRANSACCION } from "./constants";
import { getDataCompanySelector, selectorPlanes, selectorfinSuscribcion } from "./selectors";

import ComponentPlanes from "../planes/component";
import { menuItemsOCR } from "../../Constants";
import SideBar from "../../components/sidebar/sidebar";
import { getFromSession } from "../../controller/session";

class ComponentWalcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonCont: 0,
            disabled: true,
            finSuscribcion: false,
        }

    }
    componentDidMount() {
        const { companyData } = this.props;
        companyData()
    }
    componentWillMount() {
        const { finSuscribcion, finSuscribcionSelector } = this.props;
        finSuscribcion();
        if (finSuscribcionSelector.fecha_prueba_gratis) {
            if (finSuscribcionSelector.fecha_prueba_gratis >= 15) {
                this.setState({ finSuscribcion: true })
                if (finSuscribcionSelector.tipo === 'Gratis') {
                    this.setState({
                        actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
                        actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                    })

                }
            }
        }

    }

    componentDidUpdate(prevProps) {
        const { finSuscribcionSelector, selectorDataCompany } = this.props;
        if (prevProps.selectorDataCompany.productoOCR && this.state.botonCont === 0) {
            this.setState({ disabled: false, botonCont: 1 })
        }
        if (prevProps.finSuscribcionSelector.fecha_prueba_gratis !== finSuscribcionSelector.fecha_prueba_gratis) {
            if (finSuscribcionSelector.tipo === 'Gratis') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 15) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'Gratis') {
                        this.setState({
                            actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }
            if (finSuscribcionSelector.plan === 'Pymes') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 30) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'mensual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }
            if (finSuscribcionSelector.plan === 'Pymes') {
                if (finSuscribcionSelector.fecha_prueba_gratis >= 365) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'anual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })

                    }
                }
            }


        }
    }




    render() {
        const {
            handlerOCR,
            getPlanes,
            planesSelector,
            getTransaccion,
        } = this.props;
        let company = getFromSession('company')
        return (
            <div className="App borrador-container">
                {menuItemsOCR && <SideBar logo={company.logo} items={menuItemsOCR}></SideBar>}

                {this.state.finSuscribcion ?
                    <div className="App home-container">
                        <div className="noPlan">
                            <h1 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanTitle}
                            </h1>
                            <h3 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanSubtitle}
                            </h3>
                        </div>

                        <ComponentPlanes
                            finSuscribcionOCR={true}
                            getPlanesOCR={getPlanes}
                            planesSelectorOCR={planesSelector}
                            getTransaccionOCR={getTransaccion} />

                    </div>
                    :

                    <>


                        <div className="ocr-description-conteiner">
                            <div className="ocr-description-title">
                                <h1 style={{ "font-size": "72px", "text-align": "left", "max-width": "900px", "padding-bottom": "100px" }}>
                                    Ahorre tiempo con OCR (reconocimiento óptico de caracteres)
                                </h1>
                            </div>
                        </div>

                        <div className="ocr-description-conteiner">
                            <div className="ocr-tabs">
                                <div className="ocr-definition">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">

                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>
                                                    La tecnología OCR tiene la capacidad de leer texto en nuestro lugar y transformar esa información en datos valiosos. Ya sea incorporada en nuestra aplicación preferida para visualizar y gestionar archivos PDF, utilizada en conjunto con un software de traducción o integrada en una plataforma de gestión de procesos, el OCR ofrece una amplia variedad de aplicaciones.     </p>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="ocr-description-conteiner">
                            <div className="ocr-tabs">
                                <div className="ocr-definition">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">
                                            <div className="ocr-definition-title">
                                                <h2 style={{ "font-size": "48px" }}>¿Qué es OCR?</h2>
                                            </div>
                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>
                                                    En resumen, el OCR (reconocimiento óptico de caracteres) es capaz de leer el texto en documentos de la misma manera que lo hacen las personas. No obstante, a diferencia de los seres humanos, el OCR puede reconocer caracteres, números, letras y palabras en cualquier tipo de fuente o idioma.
                                                </p>
                                            </div>

                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "18px", "color": "#464e57", "textAlign": "justify" }}>

                                                    A comienzos de la década de 1980, el OCR fue una de las primeras manifestaciones de la inteligencia artificial (IA) y, desde entonces, ha experimentado una evolución significativa, llegando a convertirse en el punto de partida fundamental para la transformación digital. En la actualidad, el OCR es la tecnología subyacente en algunas de las herramientas de software más robustas y ampliamente utilizadas.
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="ocr-image-tab">
                                    <div className="image-container">
                                        <div className="image-div">

                                            <img src="https://global-uploads.webflow.com/64be86eaa29fa71f24b00685/64be86eaa29fa71f24b00eee_6296ed3f6519c0e160b16515_OCR%252520%252520Main%252520Image-p-800.png"
                                                style={{
                                                    "width": "100%",
                                                    "margin": "0 auto"
                                                }} />


                                        </div>
                                    </div>
                                </div>





                            </div>

                            <div className="ocr-tabs">
                                <div className="ocr-image-tab">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">
                                            <div className="ocr-definition-title">
                                                <h2 style={{ "font-size": "48px" }}>¿Por qué necesitas OCR?</h2>
                                            </div>
                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>

                                                    Prácticamente todas las transacciones e interacciones con los clientes se llevan a cabo en entornos digitales. Para lograr una auténtica transformación digital, las empresas requieren que el OCR evolucione de acuerdo con las crecientes demandas de los clientes y el constante aumento de datos no estructurados.
                                                </p>
                                            </div>

                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "18px", "color": "#464e57", "textAlign": "justify" }}>



                                                    Como una tecnología legada, el OCR tiene la capacidad de reconocer prácticamente cualquier tipo de carácter, letra y número, sin importar la fuente o el idioma, lo que elimina las tareas más laboriosas y que requieren más tiempo relacionadas con la entrada de datos. Además, puede distinguir entre texto mecanografiado y escrito a mano, incluyendo estilos como la cursiva y las mayúsculas.
                                                </p>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="ocr-definition">
                                    <div className="image-container">
                                        <div className="image-div">

                                            <img src="https://automationhero.ai/wp-content/uploads/2022/11/illustration-why-you-need-ocr-640x427.jpg"
                                                style={{
                                                    "width": "100%",
                                                    "margin": "0 auto"
                                                }} />


                                        </div>
                                    </div>
                                </div>





                            </div>


                        </div>

                        <div className="modal-generico-ocr">

                            <div className="data-modal-generico-ocr">


                                <div className="header-modal-generico-ocr">

                                    <div className="title-modal-generico-ocr">
                                        <h2 style={{ "font-size": "56px" }}>
                                            ¿Los límites del OCR heredado?
                                        </h2>

                                    </div>
                                    <div className="parrafo-modal-generico-ocr">
                                        <p style={{ "font-size": "21px", "textAlign": "justify" }}>
                                            La mayoría de los datos generados y recopilados por las nuevas empresas no están estructurados, lo que representa un desafío para el OCR.
                                        </p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="ocr-description-conteiner">

                            <div className="ocr-tabs">
                                <div className="ocr-definition">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">
                                            <div className="ocr-definition-title">
                                                <h2 style={{ "font-size": "21px" }}>Requiere escáneres de alta calidad.</h2>
                                            </div>
                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>

                                                    El OCR se adapta mejor a imágenes escaneadas de alta calidad con un alto contraste entre el texto y el fondo. Pero, ¿qué ocurre si el texto es irregular o la calidad del escaneo es baja? La precisión del OCR disminuye drásticamente. Incluso con los mejores escáneres y calidad de documentos, el OCR heredado aún alcanza su límite en un 60% de precisión.
                                                </p>
                                            </div>



                                        </div>
                                    </div>

                                </div>

                                <div className="ocr-image-tab">
                                    <div className="image-container">
                                        <div className="ocr-definition-title">
                                            <h2 style={{ "font-size": "21px" }}>Tiene problemas con la escritura a mano.</h2>
                                        </div>
                                        <div className="ocr-definition-title-description">
                                            <p style={{ "font-size": "21px", "textAlign": "justify" }}>


                                                Cuando se trata de reconocer la escritura a mano, el OCR por sí solo no es una solución adecuada. La escritura a mano suele ser confusa y, en muchas ocasiones, demasiado ilegible para ser interpretada de manera precisa incluso por expertos. A pesar de décadas de investigación, la mayoría de las soluciones OCR disponibles en el mercado aún no pueden lograr una precisión satisfactoria en la identificación de diversos estilos de escritura y firmas. ¿No podemos aspirar a una mejora en este aspecto?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div className="ocr-description-conteiner">

                            <div className="modal-generico-ocr">

                                <div className="data-modal-generico-ocr">


                                    <div className="header-modal-generico-ocr">

                                        <div className="title-modal-generico-ocr">
                                            <h2 style={{ "font-size": "56px" }}>
                                                Como nuestra solución puede ayudarte con el OCR
                                            </h2>

                                        </div>
                                        <div className="parrafo-modal-generico-ocr">
                                            <p style={{ "font-size": "21px", "textAlign": "justify" }}>
                                                Nuestra OCR captura toda la información esencial de facturas, órdenes de compra, formularios fiscales, contratos legales, reclamaciones de seguros, e imágenes que contienen texto manuscrito con mayor precisión y velocidad que lo que el OCR básico puede ofrecer. Incluso si los escaneos de documentos están borrosos o tienen baja resolución.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="ocr-description-conteiner">
                            <div className="ocr-tabs">
                                <div className="ocr-definition">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">
                                            <div className="ocr-definition-title">
                                                <h2 style={{ "font-size": "48px" }}>Reconocimiento de escritura a mano</h2>
                                            </div>
                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>

                                                    Escribir a mano es increíblemente complicado. Pero nuestro OCR es diferente. Construimos nuestro OCR a medida con un modelo de IA que es "Vertex AI Vision". La cúal
                                                    es bastante potente y puede reconocer tanto texto impreso como escritura a mano en varias fuentes y estilos. Utiliza algoritmos de procesamiento de imágenes y aprendizaje automático para identificar y convertir texto manuscrito en texto digital legible. Esto puede ser útil en una variedad de aplicaciones, desde la digitalización de documentos escritos a mano hasta la extracción de información de formularios escritos a mano.

                                                </p>
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                <div className="ocr-image-tab">
                                    <div className="image-container">
                                        <div className="image-div">

                                            <img src="https://automationhero.ai/wp-content/uploads/2022/11/illustration-ocr-handwriting-recognition-640x427.jpg"
                                                style={{
                                                    "width": "100%",
                                                    "margin": "0 auto"
                                                }} />


                                        </div>
                                    </div>
                                </div>





                            </div>

                            <div className="ocr-tabs">
                                <div className="ocr-image-tab">
                                    <div className="ocr-definition-wrapper">
                                        <div className="ocr-definition-text">
                                            <div className="ocr-definition-title">
                                                <h2 style={{ "font-size": "48px" }}>Documentos de baja calidad.</h2>
                                            </div>
                                            <div className="ocr-definition-title-description">
                                                <p style={{ "font-size": "21px", "textAlign": "justify" }}>

                                                    Los documentos de baja calidad se han vuelto más frecuentes debido al uso de cámaras de teléfonos inteligentes en lugar de escáneres para la digitalización de documentos en papel. Los seres humanos utilizan pistas contextuales para leer y comprender texto confuso, pero las herramientas genéricas de OCR carecen de esta capacidad de razonamiento. Si una letra o palabra está demasiado borrosa para que el software las reconozca, simplemente las omitirá sin percatarse de que se ha perdido información importante.


                                                </p>
                                            </div>



                                        </div>
                                    </div>

                                </div>

                                <div className="ocr-definition">
                                    <div className="image-container">
                                        <div className="image-div">

                                            <img src="https://automationhero.ai/wp-content/uploads/2022/11/illustration-ocr-bad-or-low-quality-documents-640x427.jpg"
                                                style={{
                                                    "width": "100%",
                                                    "margin": "0 auto"
                                                }} />


                                        </div>
                                    </div>
                                </div>





                            </div>


                        </div>

                        {/* <div className="contenido">

                            <div className="texto-contenido">
                                <h1 className="texto-contenido-title">
                                    Reconocimiento OCR
                                </h1>
                                <br></br>
                                <p className="title" style={{ "fontSize": "16px", "color": "black" }} >
                                    <strong>Nuestro OCR es un sistema fiable</strong> que permite el entendimiento
                                    <br></br>
                                    de información actuando,
                                    <br></br>
                                    <strong>según las reglas establecidas.</strong>
                                </p>
                                <hr></hr>
                                <p style={{ "fontSize": "12px", "color": "black" }}>
                                    Buscamos dejar atrás los procesos manuales utilizando herramientas automatizadas,
                                    <br></br>
                                    faciles de usar, programar y con accesos sencillos tanto para las empresas como para sus clientes
                                    <br></br>

                                </p>
                                <hr></hr>
                                <br></br><br></br><br></br> */}

                        <div className="ocr-description-conteiner">

                            <div className="button-container-ocr">

                                <Button disabled={this.state.disabled} onClick={() => handlerOCR()} color='primary' variant="contained">
                                    Comenzar
                                </Button>
                            </div>
                        </div>
                        {/*
                            </div>
                            <div className="imagen-container">
                                <img src="https://www.lms-la.com/wp-content/uploads/2022/10/ocr3.jpg"
                                    alt="Logo" width="660"
                                    height="440" sizes="(max-width: 660px) 100vw, 660px"
                                    style={{ "border-radius": "100%" }} />
                                <video src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fvideo%2FVideo_1684439769.mp4?alt=media&token=6bdc5971-d56b-485b-8284-c0cd12c2daf3" autoPlay={true} loop={true}></video>
                            <img src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fimages%2FChatBot.png?alt=media&token=89a62716-f43e-4b93-b424-745a382d53e2"></img>
                            </div>


                        </div>
                            */}
                    </>
                }

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        finSuscribcionSelector: selectorfinSuscribcion(state),
        selectorDataCompany: getDataCompanySelector(state),
        planesSelector: selectorPlanes(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        finSuscribcion: () => dispatch({ type: FIN_SUSCRIBCION, value: 1 }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        getPlanes: (value) => dispatch({ type: GET_PLANES, value }),
        getTransaccion: (referencia, id, tipo, producto, data) => dispatch({ type: GET_TRANSACCION, referencia, id, tipo, producto, data }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentWalcome);