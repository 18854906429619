import {
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_ERROR,
    GET_CONVERSATIONS_SUCCESS,
    SEARCH_CONVERSATIONS,
    SEARCH_CONVERSATIONS_ERROR,
    SEARCH_CONVERSATIONS_SUCCESS,
    SELECT_CONVERSATION,
    SELECT_CONVERSATION_SUCCESS, SELECT_CONVERSATION_MESSAGES_SUCCESS,
    SELECT_CONVERSATION_ERROR,
    FILTER_CONVERSATIONS_SUCCESS,

    SEND_MESSAGE,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_ERROR,
    UPDATE_FIELD_ATTRIBUTE,
    GENERAL_ERROR, SET_TEMA, SET_TEMA_SUCCESS,
    TRANSFER,
    TRANSFER_SUCCESS,
    END_CONVERSATION,
    RESUELTA,
    RESUELTA_SUCCESS,
    LISTAR_TEMAS, LISTAR_TEMAS_CLOSE, LISTAR_TEMAS_SUCCESS, LISTAR_USUARIOS_CONECTADOS, LISTAR_USUARIOS_CONECTADOS_SUCCESS, LISTAR_USUARIOS_CONECTADOS_CLOSE,
    ADJUNTAR_ARCHIVOS,
    ADJUNTAR_ARCHIVOS_SUCCESS,
    MODAL_ADJUNTAR_CLOSE,
    MODAL_ADJUNTAR_OPEN,
    END_CONVERSATION_SUCCESS,
    FILTRAR_BUSQUEDA,
    FILTRAR_BUSQUEDA_SUCCESS,
    LISTAR_ESTADOS,
    LISTAR_ESTADOS_SUCCESS,
    LISTAR_AGENTES,
    LISTAR_AGENTES_SUCCESS,
    LANZAR_EVENTOS,
    LISTAR_TIMEOUT_SUCCESS,

    CLEAN_FORM,

    LISTAR_FAQS,
    LISTAR_FAQS_CLOSE,
    LISTAR_FAQS_SUCCESS,

    TOMAR_CONTROL,
    PRINT_CONVERSATION,
    PRINT_CONVERSATION_SUCCESS,
    CLOSE_MODAL_PRINT,
    LOAD_MENU, LOAD_MENU_SUCCES, TRANSFERENCIAS_CONVERSACION, TRANSFERENCIAS_CONVERSACION_SUCCES, TRANSFERENCIAS_CONVERSACION_ERROR, INFO_GENERAL, NOTAS_OCULTAS, MODAL_NOTAS_OPEN, ADD_NOTA_OCULTA, ADD_NOTA_OCULTA_SUCCES, ADD_NOTA_OCULTA_ERROR, NOTAS_OCULTAS_SUCCESS, PAGINATE_CONVERSATIONS, PAGINATE_CONVERSATIONS_SUCCESS, PAGINATE_CONVERSATIONS_ERROR,
    CLEAR_CONVERSATION_START,
    SAVE_STORAGE_LIST_FAQS,
    OPEN_SPINER_FAQS,
    SAVE_TOTAL_CONVERSATIONS_REFRESH,
    GUARDAR_CONSULTA_BIGQUERY,
    OPEN_SPINNER_CHARGING,
    MANAGE_SPINER

} from './constants'

export function getConversations(payload) {
    return {
        type: GET_CONVERSATIONS,
        payload
    };
}

export function listenerTimeOutSuccess(payload) {
    return {
        type: LISTAR_TIMEOUT_SUCCESS,
        payload
    };
}

export function getConversationsSuccess(payload) {
    return {
        type: GET_CONVERSATIONS_SUCCESS,
        payload
    };
}

export function saveTotalConversationsRefresh(payload) {
    return {
        type: SAVE_TOTAL_CONVERSATIONS_REFRESH,
        payload
    };
}

export function clearConversationStart(payload) {
    return {
        type: CLEAR_CONVERSATION_START,
        payload
    };
}

export function getConversationsError(payload) {
    return {
        type: GET_CONVERSATIONS_ERROR,
        payload
    };
}


export function searchConversations(payload) {
    return {
        type: SEARCH_CONVERSATIONS,
        payload
    };
}

export function searchConversationsSuccess(payload) {
    return {
        type: SEARCH_CONVERSATIONS_SUCCESS,
        payload
    };
}

export function searchConversationsError(payload) {
    return {
        type: SEARCH_CONVERSATIONS_ERROR,
        payload
    };
}


export function selectConversation(payload, flag) {

    return {
        type: SELECT_CONVERSATION,
        payload
    };
}

export function selectConversationSuccess(payload) {
    return {
        type: SELECT_CONVERSATION_SUCCESS,
        payload
    };
}

export function selectConversationMessagesSuccess(payload) {
    return {
        type: SELECT_CONVERSATION_MESSAGES_SUCCESS,
        payload
    };
}

export function selectConversationError(payload) {
    return {
        type: SELECT_CONVERSATION_ERROR,
        payload
    };
}



export function filterConversationslistSuccess(payload) {
    return {
        type: FILTER_CONVERSATIONS_SUCCESS,
        payload
    };
}

export function guardarConsultaBigQuery(payload) {
    return {
        type: GUARDAR_CONSULTA_BIGQUERY,
        payload
    };
}



export function sendMessage(payload) {
    return {
        type: SEND_MESSAGE,
        payload
    };
}

export function sendMessageSucces(payload) {
    return {
        type: SEND_MESSAGE_SUCCESS,
        payload
    };
}

export function sendMessageError(payload) {
    return {
        type: SEND_MESSAGE_ERROR,
        payload
    };
}
export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}
export function cleanSelectedForm(payload) {
    return {
        type: CLEAN_FORM,
        payload
    }
}


export function generalError(payload) {
    return {
        type: GENERAL_ERROR,
        payload
    };
}



export function setTema(payload) {
    return {
        type: SET_TEMA,
        payload
    };
}

export function setTemaSuccess(payload) {
    return {
        type: SET_TEMA_SUCCESS,
        payload
    };
}

export function transferirConversacion(payload) {
    return {
        type: TRANSFER,
        payload
    };
}

export function transferirConversacionSuccess(payload) {
    return {
        type: TRANSFER_SUCCESS,
        payload
    };
}

export function finalizarConversacion(payload) {
    return {
        type: END_CONVERSATION,
        payload
    };
}

export function finalizarConversacionSuccess(payload) {
    return {
        type: END_CONVERSATION_SUCCESS,
        payload
    };
}

export function resuelta(payload) {
    return {
        type: RESUELTA,

    }
}
export function resueltaSuccess(payload) {

    return {
        type: RESUELTA_SUCCESS,
        payload

    }
}


export function listarFaqs(payload) {

    return {
        type: LISTAR_FAQS,
        payload
    }
}

export function printConversation(payload) {
    return {
        type: PRINT_CONVERSATION,
        payload
    }
}

export function printConversationSuccess(payload) {
    return {
        type: PRINT_CONVERSATION_SUCCESS,
        payload
    }
}

export function listarFaqsSuccess(payload) {

    return {
        type: LISTAR_FAQS_SUCCESS,
        payload
    }
}

export function listarFaqsClose(payload) {

    return {
        type: LISTAR_FAQS_CLOSE,
        payload
    }
}

export function listarTemas(payload) {

    return {
        type: LISTAR_TEMAS,
        payload
    };
}
export function listarTemasSuccess(payload) {
    //debugger;
    return {
        type: LISTAR_TEMAS_SUCCESS,
        payload
    };
}

export function listarTemasClose(payload) {
    //debugger;

    return {
        type: LISTAR_TEMAS_CLOSE,
        payload
    };
}

export function listarUsuariosConectados(payload) {
    return {
        type: LISTAR_USUARIOS_CONECTADOS,
        payload
    };
}

export function listarUsuariosConectadosSuccess(payload) {
    //debugger;
    return {
        type: LISTAR_USUARIOS_CONECTADOS_SUCCESS,
        payload
    };
}

export function listarUsuariosConectadosClose(payload) {
    return {
        type: LISTAR_USUARIOS_CONECTADOS_CLOSE,
        payload
    };
}

export function adjuntarArchivosUpload(payload) {
    return {
        type: ADJUNTAR_ARCHIVOS,
        payload
    }

}

export function adjuntarArchivosUploadSuccess(payload) {
    return {
        type: ADJUNTAR_ARCHIVOS_SUCCESS,
        payload
    }

}

export function modalAdjuntarClose(payload) {
    return {
        type: MODAL_ADJUNTAR_CLOSE,
        payload
    }
}

export function modalAdjuntarOpen(payload) {
    return {
        type: MODAL_ADJUNTAR_OPEN,
        payload
    }
}

export function filtrarBusquedaSuccess(payload) {
    return {
        type: FILTRAR_BUSQUEDA_SUCCESS,
        payload
    }
}

export function filtrarBusqueda(payload) {
    return {
        type: FILTRAR_BUSQUEDA,
        payload
    }
}

export function listarEstados(payload) {

    return {
        type: LISTAR_ESTADOS,
        payload
    }
}
export function listarEstadosSuccess(payload) {

    return {
        type: LISTAR_ESTADOS_SUCCESS,
        payload
    }
}
export function listarAgentes(payload) {

    return {
        type: LISTAR_AGENTES,
        payload
    }
}
export function listarAgentesSuccess(payload) {

    return {
        type: LISTAR_AGENTES_SUCCESS,
        payload
    }
}
export function desplegarEventosBuscador() {
    return {
        type: LANZAR_EVENTOS
    }
}

export function tomarControlConversation(payload) {
    return {
        type: TOMAR_CONTROL,
        payload

    }
}

export function cerrarModalPrint(payload) {
    return {
        type: CLOSE_MODAL_PRINT,
        payload
    }
}


export function loadMenu(payload) {
    return {
        type: LOAD_MENU,
        payload
    }
}

export function loadMenuSucces(payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    }
}

export function obtenerTransferenciasConversacion(payload) {
    return {
        type: TRANSFERENCIAS_CONVERSACION,
        payload
    }
}

export function obtenerTransferenciasConversacionSuccess(payload) {
    return {
        type: TRANSFERENCIAS_CONVERSACION_SUCCES,
        payload
    }
}
export function obtenerTransferenciasConversacionError(payload) {
    return {
        type: TRANSFERENCIAS_CONVERSACION_ERROR,
        payload
    }
}

export function activarTabInfoGeneral(payload) {
    return {
        type: INFO_GENERAL,
        payload

    }
}
export function activarTabNotasOcultas(payload) {
    return {
        type: NOTAS_OCULTAS,
        payload
    }
}
export function activarTabNotasOcultasSuccess(payload) {
    return {
        type: NOTAS_OCULTAS_SUCCESS,
        payload
    }
}

export function abrirModalNotasOcultas(payload){
    return{
        type:MODAL_NOTAS_OPEN,
        payload
    }
}

export function addNotaOcultaConv(payload){

    return{
        type:ADD_NOTA_OCULTA,
        payload
    }
}

export function addNotaOcultaConvSuccess(payload){

    return{
        type:ADD_NOTA_OCULTA_SUCCES,
        payload
    }
}

export function addNotaOcultaConvError(payload){

    return{
        type:ADD_NOTA_OCULTA_ERROR,
        payload
    }
}

export function paginateConversations(payload){
    return{
        type:PAGINATE_CONVERSATIONS,
        payload
    }

}
export function paginateConversationsSuccess(payload){
    return{
        type:PAGINATE_CONVERSATIONS_SUCCESS,
        payload
    }

}
export function paginateConversationsError(payload){
    return{
        type:PAGINATE_CONVERSATIONS_ERROR,
        payload
    }

}

export function saveStorageListFaqs(payload){
    return{
        type:SAVE_STORAGE_LIST_FAQS,
        payload
    }

}

export function openSpinnerFAQs(payload){
    return{
        type:OPEN_SPINER_FAQS,
        payload
    }

}

export function openSpinnerCharging(payload){
    return{
        type:OPEN_SPINNER_CHARGING,
        payload
    }

}


