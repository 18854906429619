export const UPDATE_FIELD_ATTRIBUTE = '@adminPanel/updateAttribute';
/* export const SELECT_INPUT = '@adminPanel/obtenerSeleccionCombo'; */


export const GET_FAQS = '@adminPanel/listarFaqs';
export const GET_FAQS_SUCCESS = '@adminPanel/listarFaqsSuccess';
export const GET_FAQS_ERROR = '@adminPanel/listarFaqsError';
export const EDITAR_FAQ = '@adminPanel/editarFaq';
export const EDITAR_FAQ_SUCCESS = '@adminPanel/editarFaqSuccess';
export const EDITAR_FAQ_ERROR = '@adminPanel/editarFaqError';
export const BORRAR_FAQ = '@adminPanel/borrarFaq';
export const BORRAR_FAQ_SUCCESS = '@adminPanel/borrarFaqSuccess';
export const BORRAR_FAQ_ERROR = '@adminPanel/borrarFaqError';
export const AGREGAR_FAQ = '@adminPanel/agregarFaq';
export const AGREGAR_FAQ_SUCCESS = '@adminPanel/agregarFaqSuccess';
export const AGREGAR_FAQ_ERROR = '@adminPanel/agregarFaqError';
export const EDITAR_TEMA = "@adminPanel/editarTema";
export const EDITAR_TEMA_SUCCESS = "@adminPanel/editarTemaSuccess";
export const EDITAR_TEMA_ERROR = "@adminPanel/editarTemaError";
export const BORRAR_TEMA = "@adminPanel/borrarTema";
export const BORRAR_TEMA_SUCCESS = "@adminPanel/borrarTemaSuccess";
export const BORRAR_TEMA_ERROR = "@adminPanel/borrarTemaError";
export const AGREGAR_TEMA = "@adminPanel/agregarTema";
export const AGREGAR_TEMA_SUCCESS = "@adminPanel/agregarTemaSuccess";
export const AGREGAR_TEMA_ERROR = "@adminPanel/agregarTemaError";
export const LIMPIAR_FORM_NIVEL2 = "@adminPanel/limpiarFormNivel1";
export const LIMPIAR_FORM = "@adminPanel/limpiarForm";
export const CREAR_USUARIO = "@adminPanel/crearUsuario";
export const CREAR_USUARIO_SUCCESS = "@adminPanel/crearUsuarioSuccess";
export const CREAR_USUARIO_ERROR = "@adminPanel/crearUsuarioError";
export const FORM_HABILIDADES_NAME = "habilidades"
export const FORM_USUARIOS_NAME = "usuarios";
export const FORM_CONF_NAME = "configuracion"
export const FORM_REGHOURS_NAME = "reg_hours";
export const FORM_SELECT_DIA = "diaEspecifico";
export const FORM_WEEKHOURS_NAME = "weekend_hours";
export const SELECT_ROW = "@adminPanel/seleccionarFila";
export const SELECT_ROW_SUCCESS = "@adminPanel/seleccionarFilaSuccess";
export const UPDATE_HORARIO = "@adminPanel/cambiarBanderUpdateHorario";



export const GET_TEMAS = '@adminPanel/listarTemas';
export const GET_TEMAS_SUCCESS = '@adminPanel/listarTemasSuccess';
export const GET_TEMAS_ERROR = '@adminPanel/listarTemasSuccessError';

export const LISTAR_USUARIOS = '@adminPanel/listarUsuarios';
export const LISTAR_USUARIOS_SUCCESS = '@adminPanel/listarUsuariosSuccess';
export const LISTAR_USUARIOS_ERROR = '@adminPanel/listarUsuariosError';
export const EDITAR_USUARIO = "@adminPanel/editarUsuario";
export const EDITAR_USUARIO_SUCCESS = "@adminPanel/editarUsuarioSuccess";
export const EDITAR_USUARIO_ERROR = "@adminPanel/editarUsuarioError";
export const GET_HABILIDADES = "@adminPanel/traerHabilidades";
export const GET_HABILIDADES_SUCCESS = "@adminPanel/traerHabilidadesSuccess";
export const GET_HABILIDADES_ERROR = "@adminPanel/traerHabilidadesError";
export const ABRIR_MODAL_USUARIOS = "@adminPanel/abrirModalEditar";
export const CERRAR_MODAL_USUARIOS = "@adminPanel/cerrarModalEditar";
export const ELIMINAR_USUARIO = "@adminPanel/eliminarUsuaurio";
export const ELIMINAR_USUARIO_ERROR = "@adminPanel/eliminarUsuaurioError";
export const ELIMINAR_USUARIO_SUCCESS = "@adminPanel/eliminarUsuaurioSuccess";
export const ABRIR_MODAL_BORRAR = "@adminPanel/abrirModalBorrar";
export const ABRIR_MODAL_BORRAR_SUCCESS = "@adminPanel/abrirModalBorrarSuccess";
export const VALIDAR_CORREO = "@adminPanel/validarCorreoExistente";
export const VALIDAR_CORREO_SUCCESS = "@adminPanel/validarCorreoExistenteSuccess";
export const CLEAR_PASSWORD = "@adminPanel/clearPasswordFiels";
export const VARIABLES_CONFIGURACION = "@adminPanel/traerVariablesConfiguracion";
export const VARIABLES_CONFIGURACION_SUCCESS = "@adminPanel/traerVariablesConfiguracionSuccess";
export const VARIABLES_CONFIGURACION_ERROR = "@adminPanel/traerVariablesConfiguracionError";
export const EDITAR_CONF = "@adminPanel/editarConfiguracion";
export const EDITAR_CONF_SUCCESS = "@adminPanel/editarConfiguracionSuccess";
export const EDITAR_CONF_ERROR = "@adminPanel/editarConfiguracionError";
export const SELECCIONAR_CONF = "@adminPanel/seleccionarConf";
export const SELECCIONAR_CONF_SUCCESS = "@adminPanel/seleccionarConfSuccess";
export const SELECCIONAR_CONF_ERROR = "@adminPanel/seleccionarConfError";
export const CERRAR_MODAL_CONF = "@adminPanel/cerrarModalEditarConf";
export const HORARIO_HABIL_DIAS = "@adminPanel/anadirDiasHorario";
export const HORARIO_HABIL_DIAS_SUCCESS = "@adminPanel/anadirDiasHorarioSuccess";
export const HORARIO_HABIL_DIAS_ERROR = "@adminPanel/anadirDiasHorarioError";
export const CONSULTAR_HORARIO = "@adminPanel/consultarHorario";
export const CONSULTAR_HORARIO_SUCCESS = "@adminPanel/consultarHorarioSuccess";
export const CONSULTAR_HORARIO_ERROR = "@adminPanel/consultarHorarioError";
export const ABRIR_MODAL_HABILIDADES = "@adminPanel/abrirModalHabilidades";
export const EDITAR_HABILIDAD = "@adminPanel/editarHabilidad";
export const EDITAR_HABILIDAD_SUCCESS = "@adminPanel/editarHabilidadSuccess";
export const EDITAR_HABILIDAD_ERROR = "@adminPanel/editarHabilidadError";
export const SELECT_ROW_HABILIDAD = "@adminPanel/seleccionarHabilidad";
export const SELECT_ROW_HABILIDAD_SUCCESS = "@adminPanel/seleccionarHabilidadSuccess";
export const SELECT_ROW_HABILIDAD_ERROR = "@adminPanel/seleccionarHabilidadError";
export const CERRAR_MODAL_EDITAR_HABILIDAD = "@adminPanel/cerrarModalEditarHabilidad";
export const CREAR_HABILIDAD = "@adminPanel/crearHabilidad";
export const CREAR_HABILIDAD_SUCCESS = "@adminPanel/crearHabilidadSuccess";
export const CREAR_HABILIDAD_ERROR = "@adminPanel/crearHabilidadError";
export const MODAL_ELIMINAR_HABILIDAD = "@adminPanel/abrirModalEliminarHabilidad";
export const CERRAR_MODAL_ELIMINAR_HABILIDAD = "@adminPanel/cerrarModalEliminarHabilidad";
export const ELIMINAR_HABILIDAD = "@adminPanel/eliminarHabilidad";
export const ELIMINAR_HABILIDAD_SUCCESS = "@adminPanel/eliminarHabilidadSuccess";
export const ELIMINAR_HABILIDAD_ERROR = "@adminPanel/eliminarHabilidadError";
export const CLIENT_FORM_WEB = "@adminPanel/traerConfFormClienteWeb";
export const CLIENT_FORM_WEB_SUCCESS="@adminPanel/traerConfFormClienteWebSuccess";
export const CLIENT_FORM_WEB_ERROR = "@adminPanel/traerConfFormClienteWebError"

export const DOCUMENT_FORM = "@adminPanel/traerConfDocument";
export const DOCUMENT_FORM_SUCCESS="@adminPanel/traerConfDocumentSuccess";
export const DOCUMENT_FORM_ERROR = "@adminPanel/traerConfDocumentError"

export const CRUCE_FORM = "@adminPanel/traerConfCruce";
export const CRUCE_FORM_SUCCESS="@adminPanel/traerConfCruceSuccess";
export const CRUCE_FORM_ERROR = "@adminPanel/traerConfCruceError"

export const GUARDAR_DOCUMENT_FORM_WEB = "@adminPanel/guardarDocumentFormWeb";
export const GUARDAR_DOCUMENT_FORM_WEB_SUCCESS = "@adminPanel/guardarDocumentFormWebSuccess";
export const GUARDAR_DOCUMENT_FORM_WEB_ERROR = "@adminPanel/guardarDocumentFormWebError";

export const GUARDAR_CRUCE_FORM = "@adminPanel/guardarCruceForm";
export const GUARDAR_CRUCE_FORM_SUCCESS = "@adminPanel/guardarCruceFormSuccess";
export const GUARDAR_CRUCE_FORM_ERROR = "@adminPanel/guardarCruceFormError";

export const GUARDAR_CLIENT_FORM_WEB = "@adminPanel/guardarFormularioClienteWeb";
export const GUARDAR_CLIENT_FORM_WEB_SUCCESS = "@adminPanel/guardarFormularioClienteWebSuccess";
export const GUARDAR_CLIENT_FORM_WEB_ERROR = "@adminPanel/guardarFormularioClienteWebError";
export const ENCUESTA_FORM_WEB = "@adminPanel/traerConfEncuestaClienteWeb";
export const ENCUESTA_FORM_WEB_SUCCESS = "@adminPanel/traerConfEncuestaClienteWebSuccess"
export const ENCUESTA_FORM_WEB_ERROR = "@adminPanel/traerConfEncuestaClienteWebError";
export const GUARDAR_ENCUESTA = "@adminPanel/guardarFormEncuestaClienteWeb";
export const GUARDAR_ENCUESTA_SUCCESS = "@adminPanel/guardarFormEncuestaClienteWebSuccess";
export const GUARDAR_ENCUESTA_ERROR = "@adminPanel/guardarFormEncuestaClienteWebError"
export const CONSULTAR_DIALOG_FLOW_PROPERTY = "@adminPanel/consultarDialogFlow";
export const MANAGE_SPINER = "@adminPanel/manageSpiner"


export const CAMPO_TYPE_TEXT = {
    name: "value",
    placeholder: "Escriba el valor de la configuración",
    options: [],
    disable: false,
    typeForm: "text",
    display: true,
    label: 'Configuración',
    type: "text",
};
export const CAMPO_TYPE_NUMBER = {
    name: "value",
    placeholder: "Escriba el valor de la configuración",
    options: [],
    disable: false,
    typeForm: "text",
    display: true,
    label: 'Configuración',
    type: "number",
}

export const CAMPO_TYPE_BOOLEAN = {
    name: "value",
    placeholder: "Escriba el valor de la configuración",
    options: [{ label: "Activar", value: true }, { label: "Desactivar", value: false }],
    disable: false,
    typeForm: "select",
    display: true,
    label: 'Configuración',
    type: "select",
}

export const CAMPO_TYPE_RADIO={
    name: "value",
    placeholder: "Escriba el valor de la configuración",
    disable: false,
    display: true,
    label: 'Configuración',
    type: "radio2",
    typeForm: "radio2",
    value: false,
    options: [
        { label: 'Transporte', value: '1', className: "radioSi" },
        { label: 'Transporte un trayecto', value: '2', className: "radioSi" },
        { label: 'Refrigerio', value: '3', className: "radioSi", },
        { label: 'Materiales de trabajo', value: '4', className: "radioSi" },
    ],
}


