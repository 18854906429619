import "./style.css";

import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";
import ComboRangos from "../../components/comboRangos/comboRangos"

import { Card } from 'primereact/card';

import {
    getNumFailed,
    getNumeroLeads,
    getPorcentajeLeads,
    getPromedioLectura,
    getSelectedForm, porcentajeLeidos, totalEnviados, totalLeidos, totalNoLeidos,

} from "./selectors";
import {
    FILTRAR_LISTA_LEADS,
    PROM_TIEMPO_LECTURA,
    TOTAL_ENVIOS,
    TOTAL_MENSAJES_LEIDOS,
    UPDATE_FIELD_ATTRIBUTE,


} from "./constants";
import PieChart from "../../components/Charts/pieChart"


class EstadisticasEnviosComponent extends Component {




    componentWillMount() {
        const {
            obtenerTotalEnviados,
            selectedForm,
            obtenerMensajesLeidos,
            obtenerTotalLeads,
            calcularTiempoPromedioLectura
        } = this.props
        selectedForm.fechaRango = 'HOY'
        obtenerTotalEnviados();
        obtenerMensajesLeidos();
        calcularTiempoPromedioLectura();
        obtenerTotalLeads();

    }

    componentDidUpdate(prevProps) {
        const {
            obtenerTotalEnviados,
            obtenerMensajesLeidos,
            obtenerTotalLeads,
            calcularTiempoPromedioLectura

        } = this.props

        if (this.props.selectedForm.fechaRango && prevProps.selectedForm.fechaRango !== this.props.selectedForm.fechaRango
            && this.props.selectedForm.fechaRango !== 'CUSTOM') {
            obtenerTotalEnviados(this.props.selectedForm.fechaRango);
            obtenerMensajesLeidos(this.props.selectedForm.fechaRango);
            obtenerTotalLeads(this.props.selectedForm.fechaRango);
            calcularTiempoPromedioLectura(this.props.selectedForm.fechaRango);
        }
    }

    filtrarBusqueda = (values) => {
        const {
            obtenerTotalEnviados,
            obtenerMensajesLeidos,
            obtenerTotalLeads,
            calcularTiempoPromedioLectura


        } = this.props
        let rango = {}
        let fechaIni;
        let fechaFin;

        fechaIni = values.fechaIni ? new Date(values.fechaIni) : false;
        fechaFin = values.fechaFin ? new Date(values.fechaFin) : false;
        if (fechaIni && fechaFin && fechaIni.getTime() < fechaFin.getTime()) {
            rango['fechaIni'] = fechaIni;
            rango['fechaFin'] = fechaFin;

            obtenerTotalEnviados(rango);
            obtenerMensajesLeidos(rango);
            obtenerTotalLeads(rango);
            calcularTiempoPromedioLectura(rango);
        } else {
            console.log('ERROR: La fecha inicial debe ser mayor a la final***|Debe seleccionar una fecha inicial o una final');
        }

    }


    render() {
        const {
            selectedForm,
            updateAttributes,
            totalEnviados,
            totalLeidos,
            totalNoLeidos,
            porcentajeLeidos,
            numeroLeads,
            porcentajeLeads,
            promedioLectura,
            numFailed

        } = this.props;

        let statusPieChart = false;
        let statusPieLeads = false;

        const labels = ['Failed', 'Submitted', 'Read']
        const titulo = 'Estado de los mensajes';
        const tooltip = 'Gráfico que muestra el estado de los mensajes enviados a los destinatarios, failed es fallido, submitted es envíado y read es leído'


        statusPieChart = {
            'labels': labels,
            'titulo': titulo,
            'tooltip': tooltip,
            'numeros': [numFailed, totalEnviados, totalLeidos]
        }

        statusPieLeads = {
            'labels': ['Leads', 'Send'],
            'titulo': 'Respuesta a los envios',
            'tooltip': "Gráfico que muestra la respuesta de los destinatarios a los mensajes, Leads es respuesta positiva, read es leído",
            'numeros': [numeroLeads, totalEnviados]
        }

        let convertirSegundos = (segundos) => {
            let respuesta;
            if (segundos > 1) {
                let valorMin = 604801;
                let intervalos = {
                    'semanas': 604800,
                    'dias': 86400,
                    'hrs': 3600,
                    'min': 60,
                    'seg': 1
                }
                for (let intervalo of Object.keys(intervalos)) {
                    let resultado = segundos / intervalos[intervalo];
                    if (resultado > 1 && resultado < valorMin) {
                        valorMin = Number((resultado).toFixed(2));
                        respuesta = valorMin.toString() + ' ' + intervalo;
                    }
                }
            } else {
                if (segundos == false) {
                    respuesta = '---'
                } else if (typeof segundos == 'string') {
                    respuesta = '---';
                } else {
                    respuesta = segundos.toString() + ' seg';
                }
            }
            return respuesta;
        }


        return (
            <React.Fragment>
                <div className="p-pl-6 p-pt-2 p-pr-6">
                    <h3 className="h3-title">Estadísticas de Envíos Masivos</h3>
                    <div className="p-col-6">
                    </div>
                    <ComboRangos selectedForm={selectedForm} updateAttributes={updateAttributes} accion={this.filtrarBusqueda}></ComboRangos>

                </div>
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                        <Card title='Mensajes enviados' /* header={header(usuariosEnEspera.textTooltip, 't')} */>
                            <div className="p-text-bold-big"> {totalEnviados}</div>

                        </Card>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                        <Card title="Mensajes leídos" /* header={header(convsEnCurso.textTooltip, 'j')} */>
                            <div className="p-text-bold-big">{totalLeidos}</div>



                        </Card>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                        <Card title="Mensajes no leídos" /* header={header(convsCerradas.textTooltip, 'k')} */>
                            <div className="p-text-bold-big">{totalNoLeidos}</div>


                        </Card>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <Card title="Número de Leads" /* header={header(carga.textTooltip, 'm')} */>
                            <div className="p-text-bold-big">{numeroLeads}</div>
                        </Card>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <Card title="Porcentaje de lectura" /* header={header(carga.textTooltip, 'm')} */>
                            <div className="p-text-bold-big">{porcentajeLeidos}%</div>
                        </Card>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <Card title="Promedio de leads" /* header={header(carga.textTooltip, 'm')} */>
                            <div className="p-text-bold-big">{porcentajeLeads}%</div>
                        </Card>
                    </div>
                    {/*<div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 margin-right">
                        <Card title='Promedio de lectura' /* header={header(usuariosEnEspera.textTooltip, 't')} >
                            <div className="p-text-bold-big"> {convertirSegundos(promedioLectura)}</div>

                        </Card>
                    </div>*/}
                </div>
                
                <div className="p-grid mt-4">

                    <div className="col-4" style={{ marginLeft: '30%' }}>
                        <PieChart data={statusPieChart}></PieChart>
                    </div>

                </div>

                <div className="p-grid mt-4">

                    <div className="col-4" style={{ marginLeft: '30%' }}>
                        <PieChart data={statusPieLeads}></PieChart>
                    </div>

                </div>

                <div className="p-grid mt-4"></div>

            </React.Fragment>
        )
    }



}

const mapStateToProps = state => {
    return {
        selectedForm: getSelectedForm(state),
        totalEnviados: totalEnviados(state),
        totalLeidos: totalLeidos(state),
        totalNoLeidos: totalNoLeidos(state),
        porcentajeLeidos: porcentajeLeidos(state),
        promedioLectura: getPromedioLectura(state),
        numFailed: getNumFailed(state),
        numeroLeads: getNumeroLeads(state),
        porcentajeLeads: getPorcentajeLeads(state),

    };
}

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),

        obtenerTotalEnviados: (value) => dispatch({ type: TOTAL_ENVIOS, value }),
        obtenerMensajesLeidos: (value) => dispatch({ type: TOTAL_MENSAJES_LEIDOS, value }),
        obtenerTotalLeads: (value) => dispatch({ type: FILTRAR_LISTA_LEADS, value }),
        calcularTiempoPromedioLectura: (value) => dispatch({ type: PROM_TIEMPO_LECTURA, value })

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstadisticasEnviosComponent);