import "./style.css";

import "primeflex/primeflex.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import {
  abrirModalEditarConf,
  encuestaActiva,
  getConfWebClient,
  getDataConfig,
  getDataSelect,
  getHorario,
  getInitialValuesEncuesta,
  getSelectedFormDiaEspecifico,
  getSelectedReduxFormConf,
  getSelectedReduxFormRegHours,
  getSelectedReduxFormWeekendHours,
  getSelectedReduxMaxConvs,
  getUpdateClientForm,
  getValuesInitialDocument,
  getValuesInitialFormWeb,
  ocultarModal,
  showToast,
  toastDetail,
  updateHorario,
  updateTableConf,
} from "./selector";
import {
  CERRAR_MODAL_CONF,
  CLIENT_FORM_WEB,
  CONSULTAR_HORARIO,
  CRUCE_FORM,
  DOCUMENT_FORM,
  EDITAR_CONF,

  ENCUESTA_FORM_WEB,

  FORM_CONF_NAME,

  GUARDAR_CLIENT_FORM_WEB,
  GUARDAR_CRUCE_FORM,
  GUARDAR_DOCUMENT_FORM_WEB,
  GUARDAR_ENCUESTA,
  HORARIO_HABIL_DIAS,

  SELECCIONAR_CONF,
  UPDATE_HORARIO,
  VARIABLES_CONFIGURACION,
} from "./constants";
import { Card } from "primereact/card";
import CustomReduxForm from "../../components/materialform/customform";
import { Toast } from "primereact/toast";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import ClienteForm from "./ClienteForm";
import HorarioConfig from "./HorarioConfig";
import EncuestaForm from "./EncuestaForm";

class ConfGeneralComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: null,
      activeIndex: 0,
      tabFormClientWeb: true,
      tabFormEncuesta: true,
      configuracionOCR: false,
    };
    this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  }

  componentWillMount() {
    const { traerVariablesConfiguracion, traerHorario,traerConfFormDocument, traerConfCruceDocument, adminOCR, traerConfFormClienteWeb, traerConfEncuestaClienteWeb } = this.props;
    if (adminOCR) {
      traerConfFormDocument();
      traerConfCruceDocument();
      this.setState({
        configuracionOCR: true
      });
    }else{
      traerVariablesConfiguracion();
      traerHorario();
      traerConfFormClienteWeb();
      traerConfEncuestaClienteWeb();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      showToast,
      toastDetail,
      updateTable,
      traerVariablesConfiguracion,
      ocultarModal,
      cerrarModalConf,
      updateHorario,
      traerHorario,
      changeFlagUpdateHorario,
      updateWebClientForm,
      traerConfFormClienteWeb,
      clientWebFormEnabled,
      traerConfEncuestaClienteWeb,
      encuestaActiva,
      traerConfFormDocument,
      traerConfCruceDocument,
      adminOCR

    } = this.props;

    if (showToast) {
      this.toast.show({
        severity: toastDetail.severity,
        summary: toastDetail.summary,
        detail: toastDetail.detail,
      });
    }

    if (clientWebFormEnabled !== prevProps.clientWebFormEnabled) {
      if (clientWebFormEnabled) {
        //console.log('cliente form web activo');
        this.setState({
          tabFormClientWeb: false
        });
      }
    }


    if (encuestaActiva !== prevProps.encuestaActiva) {
      if (encuestaActiva) {
        this.setState({
          tabFormEncuesta: false
        });
      }
    }

    if (updateTable) {
      traerVariablesConfiguracion();
    }

    if (ocultarModal) {
      cerrarModalConf();
    }
    if (updateHorario) {
      traerHorario();
      changeFlagUpdateHorario();
    }

    if (updateWebClientForm) {
      
      traerConfFormClienteWeb();
      traerConfEncuestaClienteWeb();
      
      if(adminOCR){
        traerConfFormDocument();
        traerConfCruceDocument();  
      }
    }
  }

  rightToolbarTemplate() {
    return (
      <React.Fragment>
        <Button
          label="Nuevo usuario"
          icon="pi pi-plus"
          className="p-button-help"
        />
      </React.Fragment>
    );
  }

  actionBodyTemplate(rowData) {
    const { seleccionarFila } = this.props;

    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          title="Editar"
          className="p-button-rounded p-button-info p-mr-1"
          onClick={() => seleccionarFila(rowData)}
        />
        {/* <Button icon="pi pi-trash" title='Borrar' className="p-button-rounded p-button-help p-mr-1" ></Button> */}
      </React.Fragment>
    );
  }


  renderFormEditar(buttons, itemsForm) {
    const { selectedReduxForm, editarConfiguracion } = this.props;
    return (
      <div>
        <CustomReduxForm
          submitable={false}
          formName={FORM_CONF_NAME}
          items={selectedReduxForm ? [selectedReduxForm.form] : itemsForm}
          handleSubmit={() => editarConfiguracion(selectedReduxForm)}
          className="splash"
          positionButtons="fixedPositionButton"
          buttons={buttons(() => editarConfiguracion(selectedReduxForm))}
          validations={this.validations}
        />
      </div>
    );
  }

  renderModal(open, body, onHide, headerTitle, id) {
    return (
      <Dialog
        header={headerTitle}
        visible={open}
        modal
        closeOnEscape
        id={id}
        onHide={onHide}
        style={{ width: "50vw" }}
      >
        {body}
      </Dialog>
    );
  }

  render() {
    const {
      abrirModalEditarConf,
      cerrarModalConf,
      valuesInitialhorario,
      traerHorario,
      editarDiasEspecificos,
      dataConfig,
      guardarFormularioDocumento,
      valuesInitialFormWeb,
      guardarClienteFormWeb,
      initialValuesEncuesta,
      guardarEncuestaClienteWeb,
      valuesInitialDocument,
      guardarCruceForm,
      dataSelect,
      adminOCR

    } = this.props;


    let FORM_CONF = [
      {
        name: "value",
        placeholder: "Escriba el valor de la configuración",
        options: [],
        disable: false,
        typeForm: "textArea",
        display: true,
        label: "Configuración",
        type: "textArea",
      },
    ];
    let getFormButtons = (handlerSafe) => {
      return [
        {
          label: "Guardar",
          className: "btn btn-primary btn-block btnGuardar",
          action: false,
          onClick: handlerSafe,
          display: true,
          primary: true,
        },
        {
          label: "Cancelar",
          className: "btn btn-primary btnCancelar",
          action: false,
          onClick: () => cerrarModalConf(),
          display: true,
        },
      ];
    };
    const header = (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
          />
        </span>
      </div>
    );

    const getActiveIndex = (index) => {
      if (index === 1) {
        traerHorario();
      }
      this.setState({ activeIndex: index });
    };

    return (

      <React.Fragment>
        {this.state.configuracionOCR ?
          <>
            <Toast ref={(el) => (this.toast = el)} />

            <TabView
              activeIndex={this.state.activeIndex}
              onTabChange={(e) => getActiveIndex(e.index)}
            >
              <TabPanel header="Vista previa documentos" disabled={false}>
                <Card>
                  <div className="p-text-normal p-text-center ml-5 mb-5">
                    Campos de visualizacion de documentos

                  </div>
                  <ClienteForm adminOCR={adminOCR} onSubmit={guardarFormularioDocumento} propsValues={''}></ClienteForm>
                </Card>
              </TabPanel>
              <TabPanel header="Parametros de cruce" disabled={false}>
                <Card>
                  <div className="p-text-normal p-text-center ml-5 mb-5">
                    Parametros de filtro para cruzar documentos

                  </div>
                  <EncuestaForm adminOCR={adminOCR} onSubmit={guardarCruceForm} dataSelect={dataSelect}propsValues={initialValuesEncuesta}></EncuestaForm>

                </Card>
              </TabPanel>


            </TabView>


          </>

          :
          <>
            <Toast ref={(el) => (this.toast = el)} />

            <TabView
              activeIndex={this.state.activeIndex}
              onTabChange={(e) => getActiveIndex(e.index)}
            >
              <TabPanel header="Configuración general">
                <Card className="configuracion">
                  {this.renderModal(
                    abrirModalEditarConf,
                    this.renderFormEditar(getFormButtons, FORM_CONF),
                    cerrarModalConf,
                    "Editar Configuración",
                    "editarConf"
                  )}
                  <div className="p-card-title">Configuración</div>
                  <div className="p-card-content">
                    <DataTable
                      resizableColumns
                      columnResizeMode="fit"
                      value={dataConfig}
                      className="p-datatable-gridlines"
                      globalFilter={this.state.globalFilter}
                      paginator
                      rows={10}
                      rowsPerPageOptions={[5, 10, 25]}
                      header={header}
                      dataKey="id"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} "
                    >
                      <Column field="nombre" header="Parámetro"></Column>
                      <Column field="value" header="Configuración"></Column>

                      <Column
                        headerStyle={{ width: "100px" }}
                        header="Acciones"
                        body={this.actionBodyTemplate}
                      ></Column>
                    </DataTable>
                  </div>
                </Card>
              </TabPanel>

              <TabPanel header="Horario">
                <Card>
                  <HorarioConfig onSubmit={editarDiasEspecificos} propsValues={valuesInitialhorario}> </HorarioConfig>
                </Card>
              </TabPanel>

              <TabPanel header="Formulario cliente web" disabled={this.state.tabFormClientWeb ? this.state.tabFormClientWeb : false}>
                <Card>
                  <div className="p-text-normal p-text-center ml-5 mb-5">
                    Formulario de inicio para cliente chat web

                  </div>
                  <ClienteForm onSubmit={guardarClienteFormWeb} propsValues={valuesInitialFormWeb}></ClienteForm>
                </Card>
              </TabPanel>
              <TabPanel header="Encuesta cliente web" disabled={this.state.tabFormEncuesta ? this.state.tabFormEncuesta : false}>
                <Card>
                  <div className="p-text-normal p-text-center ml-5 mb-5">
                    Formulario de encuesta para cliente chat web

                  </div>
                  <EncuestaForm onSubmit={guardarEncuestaClienteWeb} propsValues={initialValuesEncuesta}></EncuestaForm>

                </Card>
              </TabPanel>


            </TabView>


          </>

        }

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    abrirModalEditarConf: abrirModalEditarConf(state),
    selectedReduxForm: getSelectedReduxFormConf(state),
    toastDetail: toastDetail(state),
    showToast: showToast(state),
    updateTable: updateTableConf(state),
    ocultarModal: ocultarModal(state),
    updateHorario: updateHorario(state),
    dataConfig: getDataConfig(state),
    valuesInitialFormWeb: getValuesInitialFormWeb(state),
    valuesInitialDocument: getValuesInitialDocument(state),
    updateWebClientForm: getUpdateClientForm(state),
    valuesInitialhorario: getHorario(state),
    clientWebFormEnabled: getConfWebClient(state),
    initialValuesEncuesta: getInitialValuesEncuesta(state),
    encuestaActiva: encuestaActiva(state),
    dataSelect: getDataSelect(state)
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    traerVariablesConfiguracion: (value) => dispatch({ type: VARIABLES_CONFIGURACION, value }),
    editarConfiguracion: (value) => dispatch({ type: EDITAR_CONF, value }),
    cerrarModalConf: (value) => dispatch({ type: CERRAR_MODAL_CONF, value }),
    seleccionarFila: (value) => dispatch({ type: SELECCIONAR_CONF, value }),
    traerHorario: (value) => dispatch({ type: CONSULTAR_HORARIO, value }),
    changeFlagUpdateHorario: (value) => dispatch({ type: UPDATE_HORARIO, value }),
    editarDiasEspecificos: (value) => dispatch({ type: HORARIO_HABIL_DIAS, value }),
    traerConfFormClienteWeb: (value) => dispatch({ type: CLIENT_FORM_WEB, value }),
    guardarClienteFormWeb: (value) => dispatch({ type: GUARDAR_CLIENT_FORM_WEB, value }),
    guardarFormularioDocumento: (value) => dispatch({ type: GUARDAR_DOCUMENT_FORM_WEB, value }),
    guardarCruceForm: (value) => dispatch({ type: GUARDAR_CRUCE_FORM, value }),
    traerConfFormDocument: (value) => dispatch({ type: DOCUMENT_FORM, value }),
    traerConfCruceDocument: (value) => dispatch({ type: CRUCE_FORM, value }),
    traerConfEncuestaClienteWeb: (value) => dispatch({ type: ENCUESTA_FORM_WEB, value }),
    guardarEncuestaClienteWeb: (value) => dispatch({ type: GUARDAR_ENCUESTA, value })
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(ConfGeneralComponent);
