import { getFromSession } from "./session";
import { firebaseDatabase } from "./firebase";
import { enviarMensajeWhatsapp } from "./whatsapp";
import { procesarMensajeFacebook } from "./facebookAsesores";
import { firebaseField } from "./firebase";
import { consultaProperty } from "./configuration";
import axios from "axios";

import {
  where,
  getCountFromServer,
  query,
  collection,
  or,
  and,
  orderBy,
  getDocs,
  CollectionReference,
} from 'firebase/firestore';
import { retry, call, put } from "redux-saga/effects";
import { HEADERS_WS } from "../pages/informesPanel/constants";
import { saveSearchBigQueryTable } from "../pages/informesPanel/actions";

export function* enviarMensajeToUser(

  id,
  conversation,
  tipo,
  texto,
  url,
  usuario
) {

  //debugger;
  try {
    var m = false;

    if (tipo === "text") {
      m = {
        text: texto,
        type: "text",
      };
    } else if (tipo === "image") {
      m = {
        url: url,
        type: tipo,
      };
      if (texto) m.caption = texto;
    } else if (tipo === "file") {
      m = {
        url: url,
        type: tipo,
        filename: texto,
        caption: texto,
      };
    } else if (tipo === "audio") {
      m = {
        url: url,
        type: tipo,
      };
    } else if (tipo === "video") {
      m = {
        url: url,
        type: tipo,
        caption: texto,
      };
    }

    const company = getFromSession("company_id"); //`company/${company}/conversations`

    let user = usuario ? usuario : getFromSession("currentUser");
    var msge = {
      company: company,
      fecha: firebaseField.serverTimestamp(),
      from: {
        tipo: "agente",
        agente: {
          nombre: user.nickname,
        },
      },
      mensaje: m,
    };
    yield firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .collection("mensajes")
      .add(msge);
    let upd = {
      last_message: firebaseField.serverTimestamp(),
    };
    firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .update(upd);
    //ahora invoco la clase de whatsapp
    if (conversation.canal === "whatsapp") {
      yield enviarMensajeWhatsapp(company, conversation.cliente.telefono, m);
    }
    if (conversation.canal === "facebook") {
      yield procesarMensajeFacebook(company, conversation.cliente.id, m);
    }
  } catch (err) {
    console.error("conversations.enviarMensajeToUser: " + err);
    throw err;
  }
}

export function* anadirNotaOculta(id, nota) {
  let resp = false;
  const company = getFromSession("company_id");
  const currentUser = getFromSession("currentUser");
  try {
    var notaJSON = {
      fecha: firebaseField.serverTimestamp(),
      autor: {
        nombre: currentUser.nombres + " " + currentUser.apellidos,
        rol: currentUser.rol,
        habilidad: currentUser.skill.nombre,
      },
      nota: nota,
      company,
    };

    yield firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .collection("notasOcultas")
      .add(notaJSON)
      .then(() => {
        resp = true;
      })
      .catch((error) => {
        console.error("Document not added ", error);
        resp = false;
      });

    if (resp) {
      let upd = {};
      if (nota) {
        let notaSearch = "notaOculta: " + nota;
        upd["search"] = firebaseField.arrayUnion(notaSearch);
        firebaseDatabase
          .collection(`company/${company}/conversations`)
          .doc(id)
          .update(upd);
      }
    }
    return resp;
  } catch (err) {
    console.error("controller.conversation.anadirNotaOculta", err);
    throw err;
  }
}

export function* contarConvsPorAgente(company, idDocAgente) {
  let resp = 0;
  try {
    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    const query_ = query(coll, where("estado", "<", 10), where("agente.idDoc", "==", idDocAgente));

    const snapshot = yield getCountFromServer(query_);
    return snapshot.data().count;
  } catch (e) {
    console.error(e);
    throw e
  }
  /*    getCountFromServer
      yield firebaseDatabase
        .collection(`company/${company}/conversations`)
        .where()
        .where().c
        .get()
        .then((docs) => {
          resp = docs.size;
        }).catch((err) => {
          console.error('controller.conversation.contarConvsPorAgente.consulta', err);
          resp = false;
        });
      return resp;
    } catch (error) {
      console.error("controller.conversation.contarConvsPorAgente", error);
    }*/
}

async function traerHistorialConexiones(uidAgente) {
  const company = getFromSession("company_id");

  let listaRegistros = [];

  await firebaseDatabase
    .collection(`company/${company}/connectedUsers`)
    .where("agente.uid", "==", uidAgente)
    .orderBy("fecha_ini", "desc")
    .get()
    .then((results) => {
      results.forEach((registro) => {
        listaRegistros.push(registro.data());
      });
    });
  return listaRegistros;
}



async function borrarCampoAgente(idConv) {
  const company = getFromSession("company_id");
  try {
    await firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(idConv)
      .update({
        agente: firebaseField.delete(),
        fecha_asignacion: firebaseField.delete(),
        fecha_aceptacion: firebaseField.delete(),
      });
  } catch (error) {
    console.error("conversation.borrarCampoAgente", error);
    throw error;
  }
}

export function* tomarControl(id, estado, agente) {
  try {
    let user = getFromSession("currentUser");
    let uid = user.uid;
    let registrosUsuario = yield traerHistorialConexiones(uid);
    let usuarioActual = registrosUsuario[0];
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    let estadoConv;
    if (estado === 0) {
      estadoConv = 2;
    } else {
      estadoConv = estado;
    }

    if (agente) {
      if (agente.nombres == "Chatbot") {
        yield borrarCampoAgente(id);
      }
    }
    firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .update({
        agente: usuarioActual.agente,
        estado: estadoConv,
      });

  } catch (err) {
    console.error("conversations.tomarControl: " + err);
    throw err;
  }
}

export function* fijarTema(idConv, tema) {
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(idConv)
      .update({
        tema: tema,
      });
  } catch (err) {
    console.error("conversations.fijarTema: " + err);
    throw err;
  }
}

export function* devolverConversacionALaCola(idDocAgente) {
  const company = getFromSession("company_id");
  let idDocConv;
  debugger;
  yield firebaseDatabase
    .collection(`company/${company}/conversations`)
    .where("estado", "in", [1, 2, 3, 4, 5, 6, 7, 8, 9])
    .where("agente.idDoc", "==", idDocAgente)
    .get()
    .then((results) => {
      results.forEach((conv) => {
        idDocConv = conv.ref.id;
        firebaseDatabase
          .collection(`company/${company}/conversations`)
          .doc(idDocConv)
          .update({
            estado: 0,
          });
        borrarCampoAgente(idDocConv);
      });
    });
}

export async function obtenerMensajes(idConv, limit) {
  try {
    let msjs = [];
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    await firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(idConv)
      .collection("mensajes")
      .orderBy("fecha", "asc")
      .limit(limit)
      .get()
      .then((results) => {
        results.forEach((mensaje) => {
          msjs.push(mensaje);
        });
      });

    /* console.log("ANTES DE RETURN OBTENER MENSAJES"); */
    return msjs;
  } catch (error) {
    console.error("saga.obtenerMensajes", error);
    throw error;
  }
}



export function* transferir(id, conversation, newUser) {

  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    //Modificacion pjf 8 agosto 2021 para registrar las transferencias en una subcoleccion:
    let sessionUser = getFromSession("currentUser");
    let usr_origen;

    if (sessionUser.rol === "SUPERVISOR" && !conversation.agente) {
      //la conversacion esta sin asignar y el supervisor puede asignarla a un agente
      usr_origen = "Conversación sin asignar";
    } else {
      usr_origen = conversation.agente.nombres;
    }
    const transf = {
      fecha: firebaseField.serverTimestamp(),
      agente_nick: conversation.agente?.nickname
        ? conversation.agente?.nickname
        : "",
      agente_mail: conversation.agente?.mail ? conversation.agente?.mail : "",
      cliente_name: conversation.cliente?.nombre
        ? conversation.cliente?.nombre
        : "",
      cliente_mail: conversation.cliente?.mail
        ? conversation.cliente?.mail
        : "",
      canal: conversation.canal ? conversation.canal : "",
      usr_origen: conversation.agente
        ? conversation.agente.nombres
        : "Conversación sin asignar",
      habilidad_origen: conversation.skillName ? conversation.skillName : "",
      usr_destino: newUser.agente.nombres,
      habilidad_destino: newUser.agente.skill
        ? newUser.agente.skill.nombre
        : "",
      company,
    };

    yield firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .collection("transferencias")
      .add(transf).then(() => {
        console.log('Add en transferencias realizado');
      }).catch((error) => {
        console.error('Error en add en transferencias', error)
      });

    yield firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .update({
        agente: newUser.agente,
        estado: 1,
        skill: newUser.agente.skill.id,
        skillName: newUser.agente.skill.nombre,
      }).then(() => {
        console.log('Agente añadido a la conversación');
      }).catch((error) => {
        console.error('Error añadiendo el nuevo agente a la conv', error)
      });;

    //Se envia un mensaje al usuario diciendo que el nuevo usuario es el que lo atendera:
    const texto =
      "Usted está ahora hablando con " +
      newUser.agente.nombres +
      " " +
      newUser.agente.apellidos;
    enviarMensajeToUser(id, conversation, texto, newUser);
  } catch (err) {
    console.error("conversations.transferir: " + err);
    throw err;
  }
}

function verificarCero(valor) {
  let resultado;
  if (valor > 0) {
    resultado = valor - 1;
  } else {
    resultado = valor;
  }
  return resultado;
}

export function* generateConsultaAgente(fechaIni, fechaFin, agente, company) {
  try {

    let resp = []
    const coll = collection(firebaseDatabase, `company/${company}/conversations`);

    const query_1 = query(coll, where('fecha_ini', "<=", fechaFin), where('fecha_ini', '>=', fechaIni));

    let queryx = query(query_1,
      and(
        or(
          where('agente.mail', "==", agente),
          where('agenteCalificado.mail', "==", agente)
        )), orderBy("fecha_ini", "asc"));

    //query_ = query_.orderBy("fecha_ini", "asc");
    // yield queryx.get().then((convs) => {
    //   convs.forEach((c) => {
    //     resp.push(c);
    //   });
    // });
    const querySnapshot = yield getDocs(queryx);
    querySnapshot.forEach((doc) => {
      let conv = doc.data();
      conv.id = doc.id;
      resp.push(conv);
    });

    return resp;
  } catch (err) {
    console.error("conversations.generateConsultaAgente: " + err);
    throw err;
  }
}

function eliminarSesionDuplicadaAgente(data) {
  const dataOrdenada = data.sort(function (a, b) {
    a = a.fecha_ini.seconds;
    b = b.fecha_ini.seconds;
    return b - a;
  });
  let hash = {};
  const dataFiltrada = dataOrdenada.filter((current) => {
    let existe = !hash[current.uid];
    hash[current.uid] = true;
    return existe;
  });
  return dataFiltrada;
}

async function traerRegistrosConexionAgente(uidAgente) {
  try {
    let hoy = new Date();
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let diasAtras = new Date(hoy.getTime() - 4 * DIA_EN_MILISEGUNDOS);

    let resultados = [];
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    await firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("fecha_ini", "<", hoy)
      .where("agente.uid", "in", [uidAgente])
      .where("fecha_ini", ">", diasAtras)
      .get()
      .then((results) => {
        results.forEach((registro) => {
          resultados.push(registro.data());
        });
      });
    return resultados;
  } catch (error) {
    console.error("conversation.traerRegistrosConexionAgente", error);
    throw error;
  }
}

async function traerSesionActualAgente(uidAgente) {
  try {
    const resultados = await traerRegistrosConexionAgente(uidAgente);

    const currentSession = eliminarSesionDuplicadaAgente(resultados);
    return currentSession;
  } catch (error) {
    console.error("conversation.traerSesionActualAgente", error);
    throw error;
  }
}

async function traerUltimaSesionAyerAgente(uidAgente) {
  try {
    debugger;
    console.log("entro a funcion traer ultima sesison ayer agente");
    let registrosAyer = [];
    let fechaActual = new Date().setHours(0, 0, 0, 0);
    fechaActual = new Date(fechaActual);
    let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
    let fechaAyer = new Date(fechaActual.getTime() - 4 * DIA_EN_MILISEGUNDOS);

    const resultados = await traerRegistrosConexionAgente(uidAgente);

    resultados.forEach((resultado) => {
      let fechaResultado = resultado.fecha_ini.toDate();
      console.log("resultadooo fecha ini***", fechaResultado);
      if (
        resultado.fecha_ini.toDate() > fechaAyer &&
        resultado.fecha_ini.toDate() < fechaActual
      ) {
        registrosAyer.push(resultado);
      }
    });
    const ultimaSesionAyer = eliminarSesionDuplicadaAgente(registrosAyer);

    return ultimaSesionAyer;
  } catch (error) {
    console.error("conversation.traerUltimaSesionAyerAgente", error);
    throw error;
  }
}

function* activarEnvioEncuestaWhatsApp(bodyEncuesta) {
  console.log('ENTRO A ACTIVAR LA ENCUESTA');
  try {
    let body = bodyEncuesta
    const url = process.env.REACT_APP_DIALOGFLOW_EVENT;
    let resp = false;
    yield axios.create({
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
    }).put(url, body).then(response => {
      resp = true;
      console.log('activarEnvioEncuestaWhatsApp realizado', response.status)
    }).catch(err => {
      resp = false;
      console.error('error enviando encuesta' + err)
      throw err;
    })
    return resp;
  } catch (error) {
    console.error('controller.conversation.activarEnvioEncuestaWhatsApp');
    throw error;
  }
}

export function* finalizar(id, conversation) {


  let fechaActual = new Date().setHours(0, 0, 0, 0);
  fechaActual = new Date(fechaActual);
  let fechaIniConv = conversation.fecha_ini;
  fechaIniConv = fechaIniConv.toDate();
  let estadoActual;
  let agenteCalificado;
  estadoActual = conversation.estado;
  agenteCalificado = conversation.agente;

  try {
    let user = getFromSession("currentUser");
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    const encuestaWhatsapp = yield consultaProperty(
      company,
      "ENCUESTA_WHATSAPP"
    );

    let bodyFuncionEncuesta = {
      idCompany: company,
      idConv: id,
      event: "encuesta",
    };


    if (
      conversation.canal === "whatsapp" &&
      encuestaWhatsapp &&
      conversation.skill !== "BOT"
    ) {
      if (estadoActual < 10) {
        yield firebaseDatabase
          .collection(`company/${company}/conversations`)
          .doc(id)
          .update({
            estado: 4,
            agente: { nombres: "Chatbot" },
            skill: "BOT",
            agenteCalificado: agenteCalificado,
            fecha_fin_asesor: new Date()// cambiado pjf 2023-02-02 para que el campo "fecha_fin_asesor se fije al cerrar la conv"
          });
      }
      yield activarEnvioEncuestaWhatsApp(bodyFuncionEncuesta);
    } else {
      if (estadoActual < 10) {
        yield firebaseDatabase
          .collection(`company/${company}/conversations`)
          .doc(id)
          .update({
            estado: 10,
            fecha_fin: firebaseField.serverTimestamp(),
            closed_by: user.nickname,
          });
      }
    }
    if (conversation.agente && conversation.agente.uid) {
      if (fechaIniConv > fechaActual) {
        const sesionActualAgente = yield traerSesionActualAgente(
          conversation.agente.uid
        );

        console.log(sesionActualAgente)
        if (sesionActualAgente && sesionActualAgente[0] && sesionActualAgente[0].agente)
          firebaseDatabase
            .collection(`company/${company}/connectedUsers`)
            .doc(sesionActualAgente[0].agente.idDoc)
            .update({
              conv_atendidas: sesionActualAgente[0].conv_atendidas + 1,
              last_conv_closed: firebaseField.serverTimestamp(),
            });
      } else {
        //la conversacion inicio ayer pero se cierra hoy
        const ultimaSesionAyer = yield traerUltimaSesionAyerAgente(
          conversation.agente.uid
        );
        if (ultimaSesionAyer && ultimaSesionAyer[0] && ultimaSesionAyer[0].agente)
          firebaseDatabase
            .collection(`company/${company}/connectedUsers`)
            .doc(ultimaSesionAyer[0].agente.idDoc)
            .update({
              conv_atendidas: ultimaSesionAyer[0].conv_atendidas + 1,
              last_conv_closed: firebaseField.serverTimestamp(),
            });
      }
    }





    /*  yield firebaseDatabase.collection('company').doc(company).collection('conversations').doc(id).get()
       .then(conv => {
         estadoActual = conv.data().estado;
       })
 
     console.log('estadoActual', estadoActual);
     if (estadoActual < 10) {
       firebaseDatabase.collection(`company/${company}/conversations`).doc(id).update({
         estado: 10,
         fecha_fin: new Date(),
         closed_by: user.nickname
       });
     }
 
     //solo valida los datos cuando el agente sea humano:
     if (conversation.agente && conversation.agente.uid) {
       if (fechaIniConv > fechaActual) {
         console.log('entro al if')
         const sesionActualAgente = yield traerSesionActualAgente(conversation.agente.uid);
         console.log('idDocAgenteActual', sesionActualAgente[0]);
         const valorVerificado = verificarCero(sesionActualAgente[0].conv_actuales);
         firebaseDatabase.collection(`company/${company}/connectedUsers`).doc(sesionActualAgente[0].agente.idDoc).update({
           conv_actuales: valorVerificado,
           conv_atendidas: sesionActualAgente[0].conv_atendidas + 1,
           last_conv_closed: new Date(),
         })
 
       } else {
         //la conversacion inicio ayer pero se cierra hoy
         const ultimaSesionAyer = yield traerUltimaSesionAyerAgente(conversation.agente.uid);
         console.log('ultimaSesionAyer', ultimaSesionAyer);
         firebaseDatabase.collection(`company/${company}/connectedUsers`).doc(ultimaSesionAyer[0].agente.idDoc).update({
           conv_atendidas: ultimaSesionAyer[0].conv_atendidas + 1,
           last_conv_closed: new Date(),
         })
       }
     } */
  } catch (err) {
    console.error("conversations.finalizar: " + err);
    throw err;
  }
}

export function* resuelta(id, resuelta) {
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(id)
      .update({
        resuelta: resuelta,
      });
    let convsResueltas = 0;

    yield firebaseDatabase
      .collection("company")
      .doc(company)
      .collection("conversations")
      .doc(id)
      .get()
      .then((conv) => {
        let idDocAgente = conv.data().agente.idDoc
          ? conv.data().agente.idDoc
          : false;
        if (idDocAgente) {
          firebaseDatabase
            .collection("company")
            .doc(company)
            .collection("connectedUsers")
            .doc(idDocAgente)
            .get()
            .then((user) => {
              if (user.data().convs_resueltas) {
                if (resuelta) {
                  convsResueltas = user.data().convs_resueltas + 1;
                } else {
                  if (user.data().convs_resueltas > 0) {
                    convsResueltas = user.data().convs_resueltas - 1;
                  } else {
                    convsResueltas = user.data().convs_resueltas;
                  }
                }
              } else {
                if (resuelta) {
                  convsResueltas = convsResueltas + 1;
                } else {
                  convsResueltas = convsResueltas;
                }
              }
              firebaseDatabase
                .collection("company")
                .doc(company)
                .collection("connectedUsers")
                .doc(idDocAgente)
                .update({
                  convs_resueltas: convsResueltas,
                });
            });
        }
      });
  } catch (err) {
    console.error("conversations.resuelta: " + err);
    throw err;
  }
}

function* searchNoText(fechaIni, fechaFin, agente, bot, limit) {
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    let resp = [];

    let ref = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "in", [10, 11, 12, 13]);
    let query = false;

    if (bot) {

      if (fechaIni) {
        query = query
          ? query.where("fecha_ini", ">=", new Date(fechaIni))
          : ref.where("fecha_ini", ">=", new Date(fechaIni));
      }
      if (fechaFin) {
        query = query
          ? query.where("fecha_ini", "<=", new Date(fechaFin))
          : ref.where("fecha_ini", "<=", new Date(fechaFin));
      }
    } else {
      query = query
        ? query.where('fecha_aceptacion', '>=', new Date(fechaIni))
        : ref.where("fecha_aceptacion", ">=", new Date(fechaIni));
    }

    let consultaBig = {
      limit,
      page: 0,
      fechaIni,
      fechaFin,
      queryF: query
    }
    yield put(saveSearchBigQueryTable({ ...consultaBig }))

    if (agente) {
      query = query
        ? query.where("agente.mail", "==", agente.mail)
        : ref.where("agente.mail", "==", agente.mail);
    }

    if (bot) {
      query = query.orderBy("fecha_ini", "desc").limit(10);
      yield put(saveSearchBigQueryTable({ queryF: query }))
      yield query.get().then((convs) => {
        convs.forEach((c) => {
          resp.push(c);
        });
      });

    } else {
      query = query.orderBy("fecha_aceptacion", "desc").limit(10);
      yield put(saveSearchBigQueryTable({ queryF: query }))
      yield query.get().then((convs) => {
        convs.forEach((c) => {
          resp.push(c);
        });
      });

    }



    return resp;
  } catch (error) {
    console.error("conversations.searchNoText: " + error);
    throw error;
  }
}

function* searchOnlyText(fechaIni, fechaFin, texto) {
  try {

    let mess = [];
    const body = {
      company: getFromSession("company_id"),
      text: texto,
      fechaIni,
      fechaFin,
      page: 0,
      limit: 10,
      nombreGrafica: "busquedaPorTextoTable"
    }
    yield put(saveSearchBigQueryTable(body))

    let convsFiltradas = yield filtrarConversacionesBigQuery(body);
    console.log(convsFiltradas)

    if (convsFiltradas && convsFiltradas.conversations && convsFiltradas.conversations.length > 0)
      mess = { data: convsFiltradas.conversations, type: "bigquery" };
    return mess;
  } catch (error) {
    console.error("conversations.searchOnlyText:", error);
    throw error;
  }
}

function* filtrarConversacionesBigQuery(body) {
  try {
    const urlServicio = process.env.REACT_APP_CONSULTA_BIGQUERY;;
    const headers = HEADERS_WS;
    const respues = yield call(axios.post, urlServicio, body, headers);
    return respues.data;

  } catch (error) {
    throw `saja.js - filtrarConversacionesBigQuery - ${error}`
  }
}

function* searchMix(fechaIni, fechaFin, agente, texto) {
  try {
    let mess = [];

    const body = {
      company: getFromSession("company_id"),
      text: texto,
      agente: agente.mail,
      fechaIni,
      fechaFin,
      page: 0,
      limit: 10,
      nombreGrafica: "busquedaPorTextoTable"
    }
    yield put(saveSearchBigQueryTable(body))

    let convsFiltradas = yield filtrarConversacionesBigQuery(body);
    console.log(convsFiltradas)
    if (convsFiltradas && convsFiltradas.conversations && convsFiltradas.conversations.length > 0)
      mess = { data: convsFiltradas.conversations, type: "bigquery" };
    return mess;
  } catch (error) {
    console.error("conversations.searchMix:", error);
    throw error;
  }
}

function* searchAgentBigQuery(fechaIni, fechaFin, agente) {
  console.log("searchAgentBigQuery")
  try {
    let mess = [];

    const body = {
      company: getFromSession("company_id"),
      agente: agente.mail,
      fechaIni,
      fechaFin,
      page: 0,
      limit: 10,
      nombreGrafica: "busquedaPorAgenteTable"
    }
    yield put(saveSearchBigQueryTable(body))

    let convsFiltradas = yield filtrarConversacionesBigQuery(body);
    console.log(convsFiltradas)
    if (convsFiltradas && convsFiltradas.conversations && convsFiltradas.conversations.length > 0)
      mess = { data: convsFiltradas.conversations, type: "bigquery" };

    return mess;
  } catch (error) {
    throw `conversations.searchAgentBigQuery- ${error}`;
  }
}

export function* search(fechaIni, fechaFin, agente, texto, bot, idConversacion) {
  try {
    let resp = [];

    if (idConversacion) {
      resp = yield searchIdConv(idConversacion);
    } else if (!texto && !agente) {
      //1. busqueda solo con los campos generals sin el texto
      resp = yield searchNoText(fechaIni, fechaFin, agente, bot);
    } else if (!texto && agente) {
      resp = yield searchAgentBigQuery(fechaIni, fechaFin, agente);
    } else {
      //busqueda con el texto y algun otro campo
      //1. solo texto, los otros no vienen:
      if (fechaIni && fechaFin && texto && !agente) {
        // sin agente;
        resp = yield searchOnlyText(fechaIni, fechaFin, texto);
      } else {
        //con agente;
        resp = yield searchMix(fechaIni, fechaFin, agente, texto);
      }
    }
    return resp;
  } catch (err) {
    console.error("conversations.search: " + err);
    throw err;
  }
}
export function* filtrarConversaciones(terminoBusqueda) {
  let user = getFromSession("currentUser");
  let company = getFromSession("company_id");
  let fecha = new Date();
  let reference;
  let conversaciones = []
  let convsFiltradas = false;
  fecha.setDate(fecha.getDate() - 60);
  if (user.rol === "AGENTE") {
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("search", "array-contains", terminoBusqueda)
      .where("agente.mail", "==", user.mail)
      .where("last_message", ">", fecha)
      .orderBy("last_message", "desc")
  } else {
    firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("search", "array-contains", terminoBusqueda)
      .where("last_message", ">", fecha)
      .orderBy("last_message", "desc");

    /* reference = firebaseDatabase
    .collection(`company/${company}/conversations`)
    .where("search", ">=", terminoBusqueda)
    .where("search", "<", terminoBusqueda +' z' );

   firebaseDatabase
    .collection(`company/${company}/conversations`)
    .orderBy('search')
    .startAt([terminoBusqueda])
    .endAt([terminoBusqueda + '\uf8ff'])  */

    /* firebaseDatabase
    .collection(`company/${company}/conversations`)
    .where("search", "array-contains", terminoBusqueda)
    .where("last_message", ">", fecha)
    .orderBy("last_message", "desc"); */
  }
  yield reference.get().then((response) => {
    response.forEach(doc => {
      conversaciones.push(doc)
    });
  })

  if (conversaciones.length > 0) {
    convsFiltradas = conversaciones;
  }
  return convsFiltradas
}

export function* singleBusqueda(fieldValue, items) {

  let filtered = yield items.filter((item) => {
    // por cada hijo, crea una variable de estas
    let itemFiltered = false;
    let agenteFiltered = false;
    let estadoFiltered = false;
    let canalFiltered = false;
    let textFiltered = false;
    // Obtengo el objeto firebase
    const firebaseObject = item.data();
    const { cliente, agente, canal, estado, search } = firebaseObject;
    /*  firebaseObject.search.forEach(s => {
       if (typeof s !== 'string') {
         console.log('No tienen string ***', s);
       }
     }); */
    //para una propiedad
    estadoFiltered = estado
      ? estado.toString().toLowerCase().includes(fieldValue)
      : "";
    canalFiltered = estado
      ? canal.toString().toLowerCase().includes(fieldValue.toLowerCase())
      : "";
    //propiedad como objeto
    Object.keys(cliente).map((key) => {
      const keyToFilter =
        cliente[key] && typeof cliente[key] !== "object"
          ? cliente[key].toString().toLowerCase()
          : "";
      itemFiltered =
        itemFiltered ||
        keyToFilter.toLowerCase().includes(fieldValue.toLowerCase());
      return true;
    });
    // Esto recorre el hijo si es un objeto o json
    if (agente) {
      Object.keys(agente).map((key) => {
        const keyToFilter =
          agente[key] && typeof agente[key] !== "object"
            ? agente[key].toString().toLowerCase()
            : "";
        agenteFiltered =
          agenteFiltered ||
          keyToFilter.toLowerCase().includes(fieldValue.toLowerCase());
        return true;
      });
    }

    if (search) {
      //debugger
      search.forEach((s) => {
        if (typeof s === "string") {
          textFiltered =
            textFiltered || s.toLowerCase().includes(fieldValue.toLowerCase());
          return true;
        }
      });
    }
    return (
      itemFiltered ||
      agenteFiltered ||
      estadoFiltered ||
      canalFiltered ||
      textFiltered
    );
  });

  return filtered;
}

export function* searchIdConv(idConversacion) {
  try {
    let convsFiltradas = []

    const company = getFromSession("company_id"); //`company/${company}/conversations`
    let query = firebaseDatabase
      .collection(`company/${company}/conversations`).doc(idConversacion)
    convsFiltradas = yield query.get();

    if (convsFiltradas) {
      convsFiltradas = convsFiltradas.data()
    } else {
      convsFiltradas = []
    }

    return convsFiltradas
  } catch (error) {
    console.error("conversations.searchMix:", error);
    throw error;
  }
}