import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getMenuItemsOCR = (state) => state.menuItemsOCR;
export const getDataCompanySelector = (state) => state.dataCompany;
export const getSelectedForm = (state) => state.selectedForm;
export const getDataDocumentCargados = (state) => state.dataDocumentosCargados;
export const getLoading = (state) => state.loading;
export const getLineChart = (state) => state.lineChart;






export const usersSelector = createStructuredSelector({
    users: getUsers,
});