import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { getConsultaPaginada, getDataGeneradapCsv, getDataTableChatbot, getDataTableWsFallidos, getDatosTablaConvsPresupuesto, getDatosTablaConvsPresupuestoPaginados, getSelectedForm, getStateFilterButton } from "./selectors";
import { Card } from 'primereact/card';
import BarChart from "../../components/Charts/barChart";
import PieChart from '../../components/Charts/pieChart';
import { Chart } from "primereact/chart";
import DataTableWithPaginator from "../../components/tableWithPaginator/tablePaginator";
import { clearFields, Field, FieldArray, FormSection } from "redux-form";
import {
    CONVS_CHATBOT,
    DATOS_CONVS_PRESUPUESTOS,
    GENERAR_DATA_CSV,
    HEADER_DATOS_VOTOS,
    HEADER_DATOS_VOTOS_PIE,
    HEADER_DATOS_VOTOS_TOP_THREE,
    HEADER_PORCENTAJES_VOTACION,
    HEADER_REPORTE_WS_FALLIDOS,
    PAGINAR_DATA_CONVS_PRESUPUESTOS,
    PIE_VOTOS_PRESUPUESTOS,
    REPORTE_WS_FALLIDOS,
    TOAST_MESSAGE,
    MODAL_ESPERA_DATA
} from "./constants";




class EstOtrosReportes extends Component {

    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const { obtenerGraficoBarrasCausas, obtenerDataWsFallidos, obtenerDataConvsPresupuestos,
            activarSpinner } = this.props

        obtenerDataConvsPresupuestos()
        obtenerGraficoBarrasCausas({ selectedForm: 'HOY', causas: 'causas' });
        obtenerDataWsFallidos('HOY')
        activarSpinner()

    }

    componentDidUpdate(prevProps) {
        const {
            estadoBotonFiltrarRangos,
            showToast,
            obtenerGraficoBarrasCausas,
            obtenerDataWsFallidos,
            obtenerDataConvsPresupuestos,
            activarSpinner
        } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;


        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerGraficoBarrasCausas({ selectedForm: seleccionRango, causas: 'causas' })
            obtenerDataWsFallidos(seleccionRango)
            obtenerDataConvsPresupuestos(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerGraficoBarrasCausas({ selectedForm: seleccionRango, causas: 'causas' })
            obtenerDataWsFallidos(seleccionRango)
            obtenerDataConvsPresupuestos(seleccionRango)
            activarSpinner()


        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }
                obtenerGraficoBarrasCausas({ selectedForm: rango, causas: 'causas' })
                obtenerDataWsFallidos(rango)
                obtenerDataConvsPresupuestos(rango)
                activarSpinner()
            } else {
                showToast()
            }

        }

    }




    render() {
        const {
            dataTableChatbot,
            dataTableWsFallidos,
            dataTablaConvsPresupuesto,
            datosTablaConvsPresupuestoPaginados,
            consultaPaginada,
            paginarDatosConvsPresupuesto,
            generarDataCSV,
            dataGeneradaCsv,
        } = this.props;


        const header = (j) => {

            const {
                obtenerGraficoBarrasCausas
            } = this.props;

            let fecha_ini = new Date('2022-08-24T00:00:00')

            return (
                <div>
                    <Button onClick={() => obtenerGraficoBarrasCausas({ selectedForm: 'HOY', causas: 'causas' })} 
                    style={{ marginRight: "10px", marginTop: "10px" }} 
                    icon="pi pi-refresh" label="Actualizar hoy" 
                    className="p-button-rounded p-button-info" />
                    <Button onClick={() => obtenerGraficoBarrasCausas({ selectedForm: { fechaIni: fecha_ini, fechaFin: new Date() }, causas: 'causas' })} 
                    style={{ marginRight: "10px", marginTop: "10px" }} 
                    icon="pi pi-eye" label="Todos los votos" 
                    className="p-button-rounded p-button-success" />

                </div>

            )
        }



        return (
            <div>
                <div>
                    <h4 className="tituloComponente" >OTROS REPORTES</h4>
                </div>
                <div>
                    <h4 className="tituloComponente" >Votaciones presupuestos</h4>
                </div>
                <div className="containerGeneral">
                    <div className="p-mt-3">
                        <Card title="Top 10 de votaciones por presupuestos" className="causasCard" header={header()} /* className="p-grid" */>
                            <div className="p-grid">
                                <div className="p-col-7" style={{ marginRight: "60px", marginLeft: "70px" }}>
                                    <BarChart data={dataTableChatbot.graficoBarrasVotos} />
                                </div>
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 pr-5 mt-5">
                                    <Card title='Total de votos'>
                                        <div className="p-text-bold">{dataTableChatbot.totalGeneral}</div>

                                    </Card>
                                </div>
                            </div>

                        </Card>

                    </div>


                    {dataTableChatbot.votosPorcentajes ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_PORCENTAJES_VOTACION}
                                datosTable={dataTableChatbot.votosPorcentajes}
                                titleTable={"Porcentaje de votos equivalentes al top 10 de mas votados"}
                                exporExcel={false}
                                nameTable={"votos realizados"}
                            />
                        </div>) : false
                    }
                   
                    <div className="p-mt-3">
                        {dataTablaConvsPresupuesto && dataTablaConvsPresupuesto.length > 0 && (
                            <DataTableWithPaginator
                                data={dataTablaConvsPresupuesto}
                                columns={HEADER_DATOS_VOTOS} paginarData={paginarDatosConvsPresupuesto}
                                seleccionRangoBusqueda={this.state.seleccionRango}
                                tituloTabla={"Información general de las votaciones"}
                                dataPaginada={datosTablaConvsPresupuestoPaginados} consultaPaginada={consultaPaginada}
                                descargarCSV={generarDataCSV} nombreConsulta={"convsPresupuesto"} dataToDownloadCsv={dataGeneradaCsv}
                            /* totalConvsFiltradas={totalConvsFiltradas} descargarCSV={descargarCSV} convsToDownloadCsv={convsToDownloadCsv} */
                            />
                        )}
                    </div>

                    {dataTableChatbot.graficoTopThree ? (
                        <div className="p-mt-3">
                            <Chart data={dataTableChatbot.graficoTopThree} type="bar" />
                        </div>
                    ) : false}

                    {dataTableChatbot.tablaTopthree ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_DATOS_VOTOS_TOP_THREE}
                                datosTable={dataTableChatbot.tablaTopthree}
                                titleTable={"Información votaciones por propuesta"}
                                exporExcel={false}
                                nameTable={"registros de votaciones por propuesta"}
                            />

                        </div>
                    ) : false}



                    <div className="p-mt-3">

                        <div className="p-grid">
                            <div className="p-col-6" >
                                {dataTableChatbot.pie?.labels?.length > 0 ?

                                    <PieChart
                                        chartData={dataTableChatbot.pie}
                                        titlePie={PIE_VOTOS_PRESUPUESTOS}
                                        tipoPie={true}
                                    />
                                    : ''
                                }
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 pr-5 mt-5">
                                <Card title='Votos totales'>
                                    <div className="p-text-bold">{dataTableChatbot.totalGeneral}</div>

                                </Card>
                            </div>
                        </div>

                    </div>

                    {dataTableChatbot.tablaTotal ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_DATOS_VOTOS_PIE}
                                datosTable={dataTableChatbot.tablaTotal}
                                titleTable={"Información votaciones por localidad"}
                                exporExcel={false}
                                nameTable={"registros de votaciones por localidad"}
                            />

                        </div>
                    ) : false}

                </div>

            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableChatbot: getDataTableChatbot(state),
        selectedForm: getSelectedForm(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        dataTableWsFallidos: getDataTableWsFallidos(state),
        dataTablaConvsPresupuesto: getDatosTablaConvsPresupuesto(state),
        datosTablaConvsPresupuestoPaginados: getDatosTablaConvsPresupuestoPaginados(state),
        consultaPaginada: getConsultaPaginada(state),
        dataGeneradaCsv: getDataGeneradapCsv(state),

    }
}


const mapDispachToProps = dispatch => {
    return {
        paginarDatosConvsPresupuesto: (value) => dispatch({ type: PAGINAR_DATA_CONVS_PRESUPUESTOS, value }),
        obtenerGraficoBarrasCausas: (value) => dispatch({ type: CONVS_CHATBOT, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        obtenerDataWsFallidos: (value) => dispatch({ type: REPORTE_WS_FALLIDOS, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value }),
        generarDataCSV: (value) => dispatch({ type: GENERAR_DATA_CSV, value }),
        obtenerDataConvsPresupuestos: (value) => dispatch({ type: DATOS_CONVS_PRESUPUESTOS, value }),


    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstOtrosReportes);