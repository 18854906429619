import React from 'react';

export class PollingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataTransaction: null,
            cont: false,
            refPagos: false
        };
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ dataTransaction: null });
        }, 5000);
    }


    componentDidUpdate(prevProps, prevState) {
        const {
            referencia,
            getTransaccion,
            id,
            getTransaccionBot,
            tipo,
            producto,
            transaccionSelector,
            getTransaccionOCR
        } = this.props;

        if (prevProps.dataTransaction !== this.state.dataTransaction) {
            if (getTransaccionBot) {
                getTransaccionBot(referencia, id, tipo, producto, transaccionSelector)
            }
            if (getTransaccionOCR) {
                getTransaccionOCR(referencia, id, tipo, producto, transaccionSelector)
            }
            else {
                getTransaccion(referencia, id, tipo, producto, transaccionSelector)
            }
            //if (prevProps.transaccionSelector !== transaccionSelector) {

            //this.setState({ dataTransaction: transaccionSelector });

            //}
        }
    }
    componentWillMount() {
        const {
            referencia
        } = this.props;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {
            referencia
        } = this.props;
        return (
            <>
           
            </>
        );
    }
}
