import { firebaseDatabase } from "./firebase";
import moment from "moment";
import { getFromSession } from "./session";
import { put, take, call,select  } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { contarConvsPorAgente } from "./conversation";
import { totalConvs } from "../pages/supervisorPanel/selectors";
import axios from "axios";
import { convsAbandonadas } from "../pages/informesPanel/selectors";

import {
  where,
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  onSnapshot,
} from 'firebase/firestore';



export function* traerDatosGraficas(rango, nombreGrafica, limit = false) {
  try {
    const company = getFromSession("company_id");
    /*  console.log('rango.fechaIni', rango.fechaIni);
     console.log('rango.fechaFin', rango.fechaFin); */

    let body = {
      'company': company,
      'fechaIni': rango.fechaIni,
      'fechaFin': rango.fechaFin,
      'nombreGrafica': nombreGrafica,
      'limit': limit
    }
    

    const url = process.env.REACT_APP_ARMAR_DATOS_GRAFICAS
    

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "*",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, url, body, headers);

    return respues.data;
  } catch (error) {
    throw `estadisticas.js - traerDatosGraficas - ${error}`
  }

}


export function* traerDatosGraficasBigQuery(rango, nombreGrafica, limit = false) {
  try {
    const company = getFromSession("company_id");

    let body = {
      'company': company,
      'fechaIni': rango.fechaIni,
      'fechaFin': rango.fechaFin,
      'nombreGrafica': nombreGrafica,
      'limit': limit
    }
    

    const url = process.env.REACT_APP_CONSULTA_BIGQUERY
    

    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "*",
      'accept': "application/json, text/plain, */*",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    let respues = true;
    respues = yield call(axios.post, url, body, headers);

    return respues.data;
  } catch (error) {
    throw `estadisticas.js - traerDatosGraficas - ${error}`
  }

}


export function* totalConversacionesEstado(
  estado,
  fechaIni,
  fechaFin,
  action,
  payload,
  opcionEjeX = "ejeXfechaIni",
select_
) {

  
  let flag = false;
  let reference = false;
  let fecha;
  let ejeX = [];
  let ejeY = [];
  let size;
  let fechaInicial = moment(fechaIni);
  let fechaFinal = moment(fechaFin);
  let mapaHoras = new Map();
  let mapaDias = new Map();
  let diferenciaDias = fechaFinal.diff(fechaInicial, "days");

  if (diferenciaDias <= 1) {
    flag = false;
  } else {
    flag = true;
  }

  if (fechaInicial.hour() === fechaFinal.hour()) {
    let horaInicial = fechaInicial.hour();
    for (let i = horaInicial + 1; i <= 24; i++) {
      mapaHoras.set(i, 0);
    }
    for (let i = 1; i <= horaInicial; i++) {
      mapaHoras.set(i, 0);
    }
  } else {
    for (let i = 0; i < 24; i++) {
      mapaHoras.set(i, 0);
    }
  }

  let diasEntreFechas = function (desde, hasta) {
    let dia_actual = desde;
    while (dia_actual.isSameOrBefore(hasta)) {
      mapaDias.set(dia_actual.format("YYYY-MM-DD"), 0);
      dia_actual.add(1, "days");
    }
  };
  diasEntreFechas(fechaInicial, fechaFinal);

  try {
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "in", estado)
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    let cambios = false;
    while (true) {
      const tot = yield select( select_);

      ejeX = [];
      ejeY = [];

      if (fechaInicial.hour() === fechaFinal.hour()) {
        let horaInicial = fechaInicial.hour();
        for (let i = horaInicial + 1; i <= 24; i++) {
          mapaHoras.set(i, 0);
        }
        for (let i = 1; i <= horaInicial; i++) {
          mapaHoras.set(i, 0);
        }
      } else {
        for (let i = 0; i < 24; i++) {
          mapaHoras.set(i, 0);
        }
      }
  
      
      const realdata = yield take(channel);
      size = realdata.size;
      if(tot!=size) {
      realdata.docs.forEach(async (conv) => {
        
        if (opcionEjeX === "ejeXfechaIni") {

          fecha = conv.data().fecha_ini;
        } else {
          fecha = conv.data().fecha_fin;
        }

        if (!flag || estado === 0) {
          if (!fecha) {
            
            fecha = conv.data().fecha_encuesta;
          }
          if(!fecha) console.log('datoi:', conv.id, fecha);
          
          let hora = fecha.toDate().getHours();

          let totalHora = mapaHoras.has(hora) ? mapaHoras.get(hora) : 0;
          totalHora++;
          mapaHoras.set(hora, totalHora);
        } else {
          let dia = moment(fecha.toDate()).format("YYYY-MM-DD");
          let totaldia = mapaDias.has(dia) ? mapaDias.get(dia) : 0;
          totaldia++;
          mapaDias.set(dia, totaldia);
        }
          cambios = true;
      });
      if (flag && estado === 0) {
        size = 0;
      }
      if (!flag) {
        mapaHoras.forEach((values, keys) => {
          ejeY.push(values);
          ejeX.push(keys);
        });
      } else {
        mapaDias.forEach((values, keys) => {
          ejeY.push(values);
          ejeX.push(keys);
        });
      }
      payload["ejeX"] = ejeX;
      payload["ejeY"] = ejeY;
      if(cambios) {
        cambios = false;
      yield put(action({ payload, size }));
    }
  }
    }
  } catch (error) {
    console.error("estadisticas.totalConversacionesEstado: " + error);
    throw error;
  }
}

function inicializarMapasDeHoras(fechaInicial, fechaFinal) {

  let mapaHoras = new Map()
  if (fechaInicial.hour() === fechaFinal.hour()) {
    let horaInicial = fechaInicial.hour();
    for (let i = horaInicial + 1; i <= 24; i++) {
      mapaHoras.set(i, 0);
    }
    for (let i = 1; i <= horaInicial; i++) {
      mapaHoras.set(i, 0);
    }
  } else {
    for (let i = 0; i < 24; i++) {
      mapaHoras.set(i, 0);
    }
  }

  return mapaHoras;

}


export function* tiempoRespuesta(fechaIni, fechaFin, action, payload, selector_) {
  let flag = false;
  let ejeX = [];
  let ejeY = [];
  let reference;

  let fechaInicial = moment(fechaIni);
  let fechaFinal = moment(fechaFin);
  let diferenciaDias = fechaFinal.diff(fechaInicial, "days");

  if (diferenciaDias <= 1) {
    flag = false;
  } else {
    flag = true;
  }
  let mapaHoras = new Map();

  if (fechaInicial.hour() === fechaFinal.hour()) {
    let horaInicial = fechaInicial.hour();

    for (let i = horaInicial + 1; i <= 24; i++) {
      mapaHoras.set(i, [0, 0]);
    }
    for (let i = 1; i <= horaInicial; i++) {
      mapaHoras.set(i, [0, 0]);
    }
  } else {
    for (let i = 0; i < 24; i++) {
      mapaHoras.set(i, [0, 0]);
    }
  }

  let mapaDias = new Map();
  let diasEntreFechas = function (desde, hasta) {
    let dia_actual = desde;
    while (dia_actual.isSameOrBefore(hasta)) {
      mapaDias.set(dia_actual.format("YYYY-MM-DD"), [0, 0]);
      dia_actual.add(1, "days");
    }
  };
  diasEntreFechas(fechaInicial, fechaFinal);

  try {
    let promedioTmpResp;
    let prom;
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    let cont;
    let total;
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("fecha_ini", ">", timeIni)
      .where("estado", "in", [1, 2, 3, 4, 5, 6, 8, 13, 10, 11])
      .where("fecha_ini", "<", timeFin);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    if (fechaInicial.hour() === fechaFinal.hour()) {
      let horaInicial = fechaInicial.hour();

      for (let i = horaInicial + 1; i <= 24; i++) {
        mapaHoras.set(i, [0, 0]);
      }
      for (let i = 1; i <= horaInicial; i++) {
        mapaHoras.set(i, [0, 0]);
      }
    } else {
      for (let i = 0; i < 24; i++) {
        mapaHoras.set(i, [0, 0]);
      }
    }


    while (true) {
      promedioTmpResp = 0;
      cont = 0;
      total = 0;
      ejeX = [];
      ejeY = [];
      prom = 0;

      const realData = yield take(channel);
      const tot = yield select(selector_);

      realData.docs.forEach((conv) => {
        if (conv.data().agente && conv.data().agente.nombres !== "Chatbot") {
          let fechaAsigConv = conv.data().fecha_asignacion;
          let fechaAceptConv = conv.data().fecha_aceptacion;

          if (!flag) {
            let hora = conv.data().fecha_ini.toDate().getHours();
            let totalHora = mapaHoras.has(hora) ? mapaHoras.get(hora) : [0, 0];
            if (fechaAceptConv && fechaAsigConv) {
              let totalResta = fechaAceptConv.seconds - fechaAsigConv.seconds;
              totalHora[0] = totalHora[0] + totalResta;
              totalHora[1]++;
              mapaHoras.set(hora, totalHora);
            }
          } else {
            if (fechaAsigConv && fechaAceptConv) {
              let dia = moment(conv.data().fecha_ini.toDate()).format(
                "YYYY-MM-DD"
              );
              let totalDia = mapaDias.has(dia) ? mapaDias.get(dia) : [0, 0];
              let totalResta = fechaAceptConv.seconds - fechaAsigConv.seconds;
              totalDia[0] = totalDia[0] + totalResta;
              totalDia[1]++;
              mapaDias.set(dia, totalDia);
            }
          }
          if (fechaAsigConv && fechaAceptConv) {
            total += fechaAceptConv.seconds - fechaAsigConv.seconds;
            cont++;
          }
        }
      });

      if (cont > 0) promedioTmpResp = total / cont;
      promedioTmpResp = Number(promedioTmpResp.toFixed(2));

      if (promedioTmpResp > 0) {
        if (!flag) {
          mapaHoras.forEach((values, keys) => {
            if (values[1] > 0) {
              prom = values[0] / values[1] / 60;
              prom = Number(prom.toFixed(2));
            } else {
              prom = 0;
            }
            ejeY.push(prom);
            ejeX.push(keys);
          });
        } else {
          mapaDias.forEach((values, keys) => {
            if (values[1] > 0) {
              prom = values[0] / values[1] / 60;
              prom = Number(prom.toFixed(2));
            } else {
              prom = 0;
            }
            ejeY.push(prom);
            ejeX.push(keys);
          });
        }
        payload["ejeY"] = ejeY;
        payload["ejeX"] = ejeX;
      } else {
        payload["ejeY"] = [0, 0];
        payload["ejeX"] = [0, 0];
        promedioTmpResp = 0;
      }
      
      if(tot !=promedioTmpResp) {
        yield put(action({ payload, promedioTmpResp }));
      }
    }
  } catch (error) {
    console.error("estadisticas.TiempoRespuesta: " + error);
    throw error;
  }
}

export function* totalConversacionesResueltas(
  fechaIni,
  fechaFin,
  action,
  payload,
  selector_
) {
  let numResueltas = 0;
  let size;
  let reference;
  let porcResueltas;
  let conversations;
  try {
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const company = getFromSession("company_id"); //`company/${company}/conversations`


    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    const queryTot_ = query( coll, ("estado", "in", [ 1, 2, 3, 4, 5, 8, 13, 10, 11]), 
    where("fecha_ini", ">", timeIni)
    ,where("fecha_ini", "<", timeFin));
    const queryRes_ = query( coll, ("estado", "in", [ 1, 2, 3, 4, 5, 8, 13, 10, 11]), 
    where("fecha_ini", ">", timeIni)
    ,where("fecha_ini", "<", timeFin)
    ,where("resuelta", "==", true)
    );
  

    reference = firebaseDatabase
    .collection(`company/${company}/conversations`)
    .where("estado", "in", [ 1, 2, 3, 4, 5, 8, 13, 10, 11])
    .where("fecha_ini", ">", timeIni)
    .where("fecha_ini", "<", timeFin).limit(1).orderBy("fecha_ini", "desc"); 

  const channel = eventChannel((emit) => reference.onSnapshot(emit));

    while (true) {
   
      const realdata = yield take(channel);
      const tot = yield getCountFromServer(queryTot_);
      const res = yield getCountFromServer(queryRes_);
      size = tot.data().count;
      numResueltas = res.data().count
      let actual = yield select(selector_);
      porcResueltas = Math.ceil((numResueltas / size) * 100);

      let datos = {
        porcResueltas: porcResueltas,
        numConvs: size,
        numResueltas: numResueltas,
        tooltip: payload.tooltip,
      };
      if(actual.numResueltas != numResueltas) {
        console.log('tot: ', size);
        console.log('res: ', numResueltas);
  
        yield put(action(datos));
    }
    }
  } catch (error) {
    console.error("estadisticas.totalConversacionesResueltas: " + error);
    throw error;
  }
}

/**
 * Conversaciones actuales, es decir en estado >0 y >10 ??
 * @param {*} fechaIni
 * @param {*} fechaFin
 */


/**
 * El t iempo de espera se calcula con la diferencia entre fehca_ini y fecha_asignacion,
 * se deben filtrar las conversaciones en estado >0 para que sean la que ya se asignaron
 * @param {*} fechaIni
 * @param {*} fechaFin
 */
export function* tiempoEspera(fechaIni, fechaFin, action, payload, selector_) {
  let ejeX = [];
  let ejeY = [];
  let prom;

  let flag = false;
  let reference;

  let fechaInicial = moment(fechaIni);
  let fechaFinal = moment(fechaFin);
  let diferenciaDias = fechaFinal.diff(fechaInicial, "days");

  if (diferenciaDias <= 1) {
    flag = false;
  } else {
    flag = true;
  }
  let mapaHoras = new Map();
  if (fechaInicial.hour() === fechaFinal.hour()) {
    let horaInicial = fechaInicial.hour();
    for (let i = horaInicial + 1; i <= 24; i++) {
      mapaHoras.set(i, [0, 0]);
    }
    for (let i = 1; i <= horaInicial; i++) {
      mapaHoras.set(i, [0, 0]);
    }
  } else {
    for (let i = 0; i < 24; i++) {
      mapaHoras.set(i, [0, 0]);
    }
  }

  let mapaDias = new Map();
  let diasEntreFechas = function (desde, hasta) {
    let dia_actual = desde;
    while (dia_actual.isSameOrBefore(hasta)) {
      mapaDias.set(dia_actual.format("YYYY-MM-DD"), [0, 0]);
      dia_actual.add(1, "days");
    }
  };
  diasEntreFechas(fechaInicial, fechaFinal);

  try {
    let promedioTmpEsp = 0;
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    let cont = 0;
    let total = 0;
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("fecha_ini_cola", ">=", timeIni)
      .where("fecha_ini_cola", "<=", timeFin)




    const channel = eventChannel((emit) => reference.onSnapshot(emit));
    if (fechaInicial.hour() === fechaFinal.hour()) {
      let horaInicial = fechaInicial.hour();
      for (let i = horaInicial + 1; i <= 24; i++) {
        mapaHoras.set(i, [0, 0]);
      }
      for (let i = 1; i <= horaInicial; i++) {
        mapaHoras.set(i, [0, 0]);
      }
    } else {
      for (let i = 0; i < 24; i++) {
        mapaHoras.set(i, [0, 0]);
      }
    }

    while (true) {
      
      ejeX = [];
      ejeY = [];
      const realdata = yield take(channel);

      const tot = yield select(selector_);
      

      realdata.docs.forEach((conv) => {
        let data = conv.data()
        if (data.agente && data.agente.nombres && data.agente.nombres !== "Chatbot" && data.fecha_ini_cola) {

          let fechaIniConv = conv.data().fecha_ini_cola;
          let fechaAsigConv = conv.data().fecha_asignacion;
          if (!flag) {
            let hora = conv.data().fecha_ini_cola.toDate().getHours() ;
            
            let totalHora = mapaHoras.has(hora) ? mapaHoras.get(hora) : [0, 0];
            
            if (fechaIniConv && fechaAsigConv) {
              let totalResta = fechaAsigConv.seconds - fechaIniConv.seconds;
              totalHora[0] = totalHora[0] + totalResta;
              totalHora[1] =  totalHora[1]+ 1;
              mapaHoras.set(hora, totalHora);
              
            }
          } else {
            if (fechaIniConv && fechaAsigConv) {
              let dia = moment(conv.data().fecha_ini_cola.toDate()).format(
                "YYYY-MM-DD"
              );
              let totalDia = mapaDias.has(dia) ? mapaDias.get(dia) : [0, 0];
              let totalResta = fechaAsigConv.seconds - fechaIniConv.seconds;
              totalDia[0] = totalDia[0] + totalResta;
              totalDia[1]++;
              mapaDias.set(dia, totalDia);
            }
          }
          
          if (fechaIniConv && fechaAsigConv) {
            total += fechaAsigConv.seconds - fechaIniConv.seconds;
            cont++;
          }

        }

      });
      
      if (cont > 0) promedioTmpEsp = total / cont;
      promedioTmpEsp = Number(promedioTmpEsp.toFixed(2));

      if (!flag) {
        mapaHoras.forEach((values, keys) => {
          if (values[1] > 0) {
            prom = (values[0] / values[1])/60 ;
            prom = Number(prom.toFixed(2));
          } else {
            prom = 0;
          }
          ejeY.push(prom);
          ejeX.push(keys);
        });
      } else {
        mapaDias.forEach((values, keys) => {
          if (values[1] > 0) {
            prom = (values[0] / values[1] )/60;
            prom = Number(prom.toFixed(2));
          } else {
            prom = 0;
          }
          ejeY.push(prom);
          ejeX.push(keys);
        });
      }
      payload["ejeY"] = ejeY;
      payload["ejeX"] = ejeX;        
      if(tot!=promedioTmpEsp) {
         yield put(action({ payload, promedioTmpEsp }));
      }
    }
  } catch (error) {
    console.error("estadisticas.TiempoEspera: " + error);
    throw error;
  }
}


/**
 * Se toman las conversaciones que ya esten cerraddas (estado 10)
 * @param {*} fechaIni
 * @param {*} fechaFin
 */

export function* porcCargaActual(fechaIni, fechaFin, action, payload) {

  let ejeX;
  let ejeY;
  let carga=0;
  let reference;
  let convsActuales;
  let convsMaximas;
  let datos;
  let flag;
  let totalConvs;
  let cargaAgente, contadorAgente;
  
  try {
    const timeIni = new Date(fechaIni);
    let total=0;
    //obtengo primero cual es la maxima carga, de acuerdo al total de agentes conectados (estado 1 y sin fecha fin)
    const company = getFromSession("company_id");
    reference = firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("estado", "in", [1, 3])
      .where("fecha_ini", ">", timeIni);
    const channel = eventChannel((emit) => reference.onSnapshot(emit));
    while (true) {
      let usuData = [];
      totalConvs = 0;
      ejeX = [];
      ejeY = [];
      total = 0;
      carga =0;
      cargaAgente =0;
      convsActuales = {};
      datos = {};
      const realData = yield take(channel);
       contadorAgente = realData.size;
      realData.docs.forEach((usu) => {
        usuData.push(usu.data());
      });
      for (let usu of usuData) {
        let usuarioIdRef = usu.agente.idDoc;
        console.log('agenet a buscar:' , usu.agente)
        let convsAsignadas =  yield contarConvsPorAgente(company, usuarioIdRef);
        cargaAgente = convsAsignadas / usu.conv_maximas;;
        convsActuales[usu.agente.nombres + " " + usu.agente.apellidos] =
          convsAsignadas;
        carga+=cargaAgente;          
      }
      carga = carga*100/contadorAgente
      carga = carga.toFixed(1);

      if (contadorAgente === 0) {
        ejeX = ["Sin Agentes disponibles"];
        ejeY = [0];
        carga = 0;
        flag = false;

        datos = {
          ejeY,
          ejeX,
          flag,
        };
        datos["textTooltip"] = payload.textTooltip;
        datos["dataLabel"] = payload.dataLabel;
        yield put(action({ lista: datos, carga }));
      } else {
        ejeX = [];
        ejeY = [];
        for (let i of Object.keys(convsActuales)) {
          ejeX.push(i);
          ejeY.push(convsActuales[i]);
        }

        flag = true;
        datos = {
          ejeY,
          ejeX,
          flag,
          convsMaximas,
        };
        datos["textTooltip"] = payload.textTooltip;
        datos["dataLabel"] = payload.dataLabel;
        
        yield put(action({ lista: datos, carga }));
      }
    }
  } catch (error) {
    console.error("estadisticas.porcCargaActual: " + error);
    throw error;
  }
}

export function* consultarRegistroAgentesUpd(fechaIni, fechaFin, dato, action) {
  try {
    const company = getFromSession("company_id");
    let registroAgente = [];
    let uidAgente;
    let datosConversaciones;
    let dataFinal;
    let lista = [];

    uidAgente = dato.agente.uid;

    datosConversaciones = yield obtenerConversacionesporAgente(
      uidAgente,
      fechaIni,
      fechaFin
    );


    const document = yield firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("fecha_ini", ">", fechaIni)
      .where("fecha_ini", "<", fechaFin)
      .where("agente.uid", "==", uidAgente)
      .orderBy("fecha_ini", "desc")
      .get();

    document.docs.forEach((doc) => {
      registroAgente.push(doc.data());
    });

    //console.log('registroAgente', registroAgente );
    let convsAsignadas = yield contarConvsPorAgente(company, registroAgente[0].agente.idDoc)
    let datoAgente = yield armarJSONagente(
      datosConversaciones,
      registroAgente[0],
      registroAgente,
      convsAsignadas
    );
    //console.log('datoAgente', datoAgente );

    if (datoAgente) {
      /*   lista.push(datoAgente)
              dataFinal = { "data": eliminarSesionAgente(lista) } */
      dataFinal = { data: datoAgente };
    } else {
      dataFinal = { data: [] };
    }
    //console.log('dataFinal', dataFinal);
    yield put(action(dataFinal));
  } catch (error) {
    console.error("estadisticas.consultarRegistroAgentesUpd", error);
    throw error;
  }
}

async function obtenerConvsAbiertas(fechaIni, fechaFin) {
  try {
    const company = getFromSession("company_id");
    let totalConvs = 0;
    await firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "in", [1, 2, 3, 4, 5, 6, 7, 8, 9])
      .where("fecha_ini", ">", new Date(fechaIni))
      .where("fecha_ini", "<", new Date(fechaFin))
      .get()
      .then((results) => {
        results.forEach((result) => {
          if (result.data().agente.nombres !== "Chatbot") {
            totalConvs += 1;
          }
        });
      });
    return totalConvs;
  } catch (error) {
    console.error("estadisticas.obtenerConvsAbiertas", error);
    throw error;
  }
}

function calcularConvsResueltas(infoConvs) {
  let convsResueltas = 0;
  try {
    infoConvs.forEach((conv) => {
      convsResueltas = conv.resuelta ? convsResueltas + 1 : convsResueltas;
    });
    return convsResueltas;
  } catch (error) {
    console.error("saga.calcularConvsResueltas", error);
    throw error;
  }
}

function calcularConvsCerradas(infoAgente, uidAgente) {
  let convsCerradas = 0;
  try {
    infoAgente.forEach((agente) => {
      if (agente.agente.uid === uidAgente) {
        convsCerradas += agente.conv_atendidas;
      }
    });
    return convsCerradas;
  } catch (error) {
    console.error("saga.calcularConvsCerradas", error);
    throw error;
  }
}

function eliminarSesionAgente(data) {
  //AQUI VAMOS A ELIMINAR DUPLICADOS Y SESIONES DE AGENTES DESCONECTADAS
  try {
    let agentesDisponibles = [];
    const dataOrdenada = data.sort(function (a, b) {
      a = a.fechaIni.seconds;
      b = b.fechaIni.seconds;
      return b - a;
    });
    let hash = {};
    const dataFiltrada = dataOrdenada.filter((current) => {
      let existe = !hash[current.uid];
      hash[current.uid] = true;
      return existe;
    });
    //console.log('data filtrada', dataFiltrada);

    dataFiltrada.forEach((data) => {
      //console.log("data estado", data.estado);
      if (data.estado === "Disponible" || data.estado === "Ausente") {
        agentesDisponibles.push(data);
      }
    });

    //console.log('entro a eliminar sesion agente', agentesDisponibles);
    return agentesDisponibles;
  } catch (error) {
    console.error("saga.eliminarSesionDuplicadaAgente", error);
    throw error;
  }
}

const calcularTiempoConexion = (infoAgente, uid) => {
  let tiempoConexion = 0;
  let fechaActual = new Date();
  let hoy = new Date().setHours(0, 0, 0, 0);
  fechaActual = fechaActual.getTime() / 1000;
  let jsonFechaActual = { seconds: fechaActual };

  try {
    infoAgente.forEach((agente) => {
      if (agente.agente.uid === uid) {
        if (agente.fecha_ini.toDate() < hoy && !agente.fecha_fin) {
          let timeIni = agente.fecha_ini ? agente.fecha_ini : jsonFechaActual;
          let timeFin = agente.fecha_fin ? agente.fecha_fin : jsonFechaActual;
          tiempoConexion += timeFin.seconds - timeIni.seconds;
        } else if (agente.fecha_ini.toDate() >= hoy) {
          let timeIni = agente.fecha_ini ? agente.fecha_ini : jsonFechaActual;
          let timeFin = agente.fecha_fin ? agente.fecha_fin : jsonFechaActual;
          tiempoConexion += timeFin.seconds - timeIni.seconds;
        }
      }
    });
    tiempoConexion = convertirSegundos(tiempoConexion);
    return tiempoConexion;
  } catch (error) {
    console.error("estadisticas.calcularTiempoConexion", error);
    throw error;
  }
};

const calcularTiempoRespuesta = (infoConv, tipo) => {

  let tiempoPromedio = 0;
  let numConvs = 0;
  let fechaActual = new Date();
  fechaActual = fechaActual.getTime() / 1000;
  let jsonFechaActual = { seconds: fechaActual };
  let resp;
  //console.log("tipo", tipo);


  try {
    if (tipo === "respuesta") {
      //console.log("infoConv", infoConv);
      infoConv.forEach((conv) => {
        //console.log("conv", conv);

        let timeIni = conv.fecha_ini ? conv.fecha_ini : jsonFechaActual;
        let timeFin = conv.fecha_aceptacion ? conv.fecha_aceptacion : jsonFechaActual;
        tiempoPromedio += timeFin.seconds - timeIni.seconds;
        //console.log("tiempo promedio", tiempoPromedio);
        numConvs++;
      });
    } else if (tipo === "conversacion") {
      infoConv.forEach((conv) => {
        let timeIni = conv.fecha_ini ? conv.fecha_ini : jsonFechaActual;
        let timeFin = conv.fecha_fin ? conv.fecha_fin : jsonFechaActual;
        tiempoPromedio += timeFin.seconds - timeIni.seconds;
        numConvs++;
      });
    }
    tiempoPromedio /= numConvs;

    tiempoPromedio = convertirSegundos(tiempoPromedio);

    if (tiempoPromedio === "NaN seg") {
      resp = "Sin iniciar conversaciones";
    } else {
      resp = tiempoPromedio;
    }
  
 return resp;
  } catch (error) {
    console.error("estadisticas.calcularTiempoRespuesta", error);
    throw error;
  }
};
const convertirSegundos = (segundos) => {
  let respuesta;
  if (segundos > 1) {
    let valorMin = 604801;

    let intervalos = {
      semanas: 604800,
      dias: 86400,
      hrs: 3600,
      min: 60,
      seg: 1,
    };
    for (let intervalo of Object.keys(intervalos)) {
      let resultado = segundos / intervalos[intervalo];
      if (resultado > 1 && resultado < valorMin) {
        valorMin = Number(resultado.toFixed(2));
        respuesta = valorMin.toString() + " " + intervalo;
      }
    }
  } else {
    respuesta = segundos.toString() + " seg";
  }
  return respuesta;
};

function calcularPorcSatisfaccionAgente(infoConvs) {
  let calificadas = 0;
  let calificacion = 0;
  let maxCalificacion = 5;
  let resp;
  try {
    infoConvs.forEach((conv) => {
      if (conv.encuesta && conv.encuesta.ratingStars) {
        calificadas += 1;
        calificacion += conv.encuesta.ratingStars / maxCalificacion;
      }
    });
    if (calificadas === 0) {
      resp = 0 + "%";
    } else {
      resp = (calificacion / calificadas) * 100;
      resp = resp.toFixed(1) + "%";
    }

    return resp;
  } catch (error) {
    console.error("estadisticas.calcularPorcSatisfaccionAgente", error);
  }
}

function armarJSONagente(
  infoConvs,
  infoAgente,
  registrosAgentes,
  convsAsignadas
) {
 

  let estadoFormat = { 2: "Desconectado", 1: "Disponible", 3: "Ausente" };
  let cargaAgente = (convsAsignadas / infoAgente.conv_maximas) * 100;
  cargaAgente = cargaAgente.toFixed(1);
  cargaAgente = cargaAgente.toString() + "%";
  let ultimaConvTomada;

  if (infoAgente.last_conv_taked) {
    ultimaConvTomada = moment(infoAgente.last_conv_taked.toDate()).format(
      "LLL"
    );
  } else {
    let lista = [];
    registrosAgentes.forEach((registro) => {
      if (registro.last_conv_taked) {
        let copia = { ...registro };
        copia["last_conv_taked"] = copia.last_conv_taked.toDate();
        lista.push(copia.last_conv_taked);
      }
    });
    if (lista.length > 0) {
      lista.sort();
      ultimaConvTomada = lista[lista.length - 1];
      ultimaConvTomada = moment(ultimaConvTomada).format("LLL");
    } else {
      ultimaConvTomada = "Sin registro";
    }
  }

  try {
    const jsonInfo = {
      nombre: infoAgente.agente.nombres + " " + infoAgente.agente.apellidos,
      estado: estadoFormat[infoAgente.estado],
      convAsignadas: convsAsignadas,
      habilidad: infoAgente.agente.skill.nombre,
      convCerradas: calcularConvsCerradas(
        registrosAgentes,
        infoAgente.agente.uid
      ),
      maxConvs: infoAgente.conv_maximas,
      carga: cargaAgente,
      porcSatisfaccion: calcularPorcSatisfaccionAgente(infoConvs),
      tiempoConexion: calcularTiempoConexion(
        registrosAgentes,
        infoAgente.agente.uid
      ),
      tiempoRespuesta: calcularTiempoRespuesta(infoConvs, "respuesta"),
      tiempoConversacion: calcularTiempoRespuesta(infoConvs, "conversacion"),
      convResueltas: calcularConvsResueltas(infoConvs),
      fechaIni: infoAgente.fecha_ini,
      uid: infoAgente.agente.uid,
      ultimaConversacionTomada: ultimaConvTomada,
      idDoc: infoAgente.agente.idDoc,
    };
    return jsonInfo;
  } catch (error) {
    console.error("saga.armarJSONagentes", error);
    throw error;
  }
}

export function* obtenerConversacionesporAgente(agenteUid, fechaIni, fechaFin) {
  try {
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    let conversaciones = [];
    let reference;
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    /* console.log("agenteUid", timeIni, timeFin, agenteUid); */

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("agente.uid", "in", [agenteUid])
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin)

    yield reference.get().then((docs) => {
      docs.forEach((doc) => {
        
        if (
          doc.data().agenteCalificado &&
          doc.data().agenteCalificado.uid === agenteUid
        ) {
        
          conversaciones.push(doc.data());
        } else if (doc.data().agente && doc.data().agente.uid === agenteUid) {
          
          conversaciones.push(doc.data());
        }
      });
    });
    return conversaciones;
  } catch (error) {
    console.error("estadisticas.obtenerConversacionesporAgente", error);
    throw error;
  }
}

export function* consultarRegistroAgentesV2(
  fechaIni,
  fechaFin,
  action,
  actionAgente,
  fechaHoy
) {
  try {
    //primero se consulta a base de datos los agentes que esten disponibles;
    let reference;
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const timeIniHoy = new Date(fechaHoy.fechaIni);
    const timeFinHoy = new Date(fechaHoy.fechaFin);


    let datosAgentes = [];
    let uidAgente;
    let dataFinal;
    let fecha_ini;
    let estado;
    let datosConversaciones = [];
    let datosConversacionesHoy = [];
    let infoAgentes = [];
    let datosAgentesHoy = [];

    const company = getFromSession("company_id");
    reference = firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("agente.rol", "in", ["AGENTE"])
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin)
      .orderBy("fecha_ini", "desc");

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    let newAgente;

    let inited = false;
    while (true) {
      datosAgentes = [];
      infoAgentes = [];
      datosAgentesHoy = [];
      datosConversaciones = [];
      datosConversacionesHoy = [];
      const realData = yield take(channel);
      if (!inited) {
        realData.docs.forEach((doc) => {
          datosAgentes.push(doc.data());
          if (doc.data().fecha_ini.toDate() >= timeIniHoy) {
            datosAgentesHoy.push(doc.data());
          }
        });

        if (datosAgentes && datosAgentes.length > 0) {
          for (let dato of datosAgentes) {
        

            uidAgente = dato.agente.uid;
            fecha_ini = dato.fecha_ini.toDate();
            //console.log("fecha_ini", fecha_ini);
            estado = dato.estado;
            let convsAsignadas = yield contarConvsPorAgente(
              company,
              dato.agente.idDoc
            );
            if (fecha_ini < timeIniHoy && estado in [1, 3]) {
              //esto es para los agentes que llevan conectados desde "ayer" o mas tiempo
              datosConversaciones = yield obtenerConversacionesporAgente(uidAgente, fechaIni, fechaFin);
           
              let jsonAgente = yield armarJSONagente(datosConversaciones, dato, datosAgentes, convsAsignadas)
              yield put(action(jsonAgente));

            } else if (fecha_ini >= timeIniHoy) {
              
              //console.log("uidAgente****", uidAgente);
              datosConversacionesHoy = yield obtenerConversacionesporAgente(uidAgente, timeIniHoy, timeFinHoy);
         
              let jsonAgente = yield armarJSONagente(datosConversacionesHoy, dato, datosAgentes, convsAsignadas)

              yield put(action(jsonAgente));

            }
          }

        } else {
          //console.log('entro al elseeeee controller');
          dataFinal = {
            data: [],
          };
        }
        inited = true;

      } else {
        let data = yield realData.docChanges();
        for (let change of data) {
          if (change.type === "added") {
            //console.log('added*****', change.doc.data());
            newAgente = change.doc.data();
          }
          if (change.type === "modified") {
            //console.log("Modified: ", change.doc.data());
            newAgente = change.doc.data();
          }
          if (newAgente.fecha_ini.toDate() >= timeIniHoy) {
            yield put(
              actionAgente({
                timeIni: timeIniHoy,
                timeFin: timeFinHoy,
                datoAgente: newAgente,
              })
            );
          } else {
            yield put(
              actionAgente({ timeIni, timeFin, datoAgente: newAgente })
            );
          }
        }
      }
    }
  } catch (error) {
    console.error("estadisticas.consultarRegistroAgentes", error);
    throw error;
  }
}

export function* consultarRegistroAgentes(
  fechaIni,
  fechaFin,
  action,
  actionAgente,
  fechaHoy
) {
  try {
    let reference;
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const timeIniHoy = new Date(fechaHoy.fechaIni);
    const timeFinHoy = new Date(fechaHoy.fechaFin);

    let datosAgentes = [];
    let uidAgente;
    let dataFinal;
    let fecha_ini;
    let estado;
    let datosConversaciones = [];
    let datosConversacionesHoy = [];
    let infoAgentes = [];
    let datosAgentesHoy = [];

    const company = getFromSession("company_id");
    reference = firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("agente.rol", "in", ["AGENTE"])
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin)
      .orderBy("fecha_ini", "desc");

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    let newAgente;

    let inited = false;
    while (true) {
      datosAgentes = [];
      infoAgentes = [];
      datosAgentesHoy = [];
      datosConversaciones = [];
      datosConversacionesHoy = [];
      const realData = yield take(channel);
      if (!inited) {
        realData.docs.forEach((doc) => {
          datosAgentes.push(doc.data());
          if (doc.data().fecha_ini.toDate() >= timeIniHoy) {
            datosAgentesHoy.push(doc.data());
          }
        });
        if (datosAgentes && datosAgentes.length > 0) {
          for (let dato of datosAgentes) {
           
            uidAgente = dato.agente.uid;
            fecha_ini = dato.fecha_ini.toDate();
            estado = dato.estado;
            let convsAsignadas = yield contarConvsPorAgente(
              company,
              dato.agente.idDoc
            );
            if (fecha_ini < timeIniHoy && estado in [1, 3]) {
              datosConversaciones = yield obtenerConversacionesporAgente(
                uidAgente,
                fechaIni,
                fechaFin
              );
              infoAgentes.push(
                armarJSONagente(
                  datosConversaciones,
                  dato,
                  datosAgentes,
                  convsAsignadas
                )
              );

            } else if (fecha_ini >= timeIniHoy) {
              datosConversacionesHoy = yield obtenerConversacionesporAgente(
                uidAgente,
                timeIniHoy,
                timeFinHoy
              );
              infoAgentes.push(
                armarJSONagente(
                  datosConversacionesHoy,
                  dato,
                  datosAgentesHoy,
                  convsAsignadas
                )
              );
            }
          }
          dataFinal = { data: eliminarSesionAgente(infoAgentes) };
        } else {
          dataFinal = {
            data: [],
          };
        }
        inited = true;
        yield put(action(dataFinal));
      } else {
        let data = yield realData.docChanges();
        for (let change of data) {
          if (change.type === "added") {
            //console.log('added*****', change.doc.data());
            newAgente = change.doc.data();
          }
          if (change.type === "modified") {
            //console.log("Modified: ", change.doc.data());
            newAgente = change.doc.data();
          }
          if (newAgente.fecha_ini.toDate() >= timeIniHoy) {
            yield put(
              actionAgente({
                timeIni: timeIniHoy,
                timeFin: timeFinHoy,
                datoAgente: newAgente,
              })
            );
          } else {
            yield put(
              actionAgente({ timeIni, timeFin, datoAgente: newAgente })
            );
          }
        }
      }
    }
  } catch (error) {
    console.error("estadisticas.consultarRegistroAgentes", error);
    throw error;
  }
}





export function* obtenerDatosPorcSatisfaccion(
  fechaIni,
  fechaFin,
  action,
  parametros,
  selector_
) {
  try {
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    let totalCalificadas;
    let calificacion;
    let maxCalificacionConv = 5;
    let reference;
    let size;
    let porcentaje;
    let data;
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("encuesta.ratingStars", "in", ["1", "2","3","4","5"])
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    while (true) {
      size = 0;
      totalCalificadas = 0;
      calificacion = 0;
      data = {};

      const realData = yield take(channel);
      size = realData.size;
      const tot = yield select(selector_)

      realData.docs.forEach(async (conv) => {
        if (conv.data().encuesta && conv.data().encuesta.ratingStars) {
          totalCalificadas += 1;
          calificacion +=
            conv.data().encuesta.ratingStars / maxCalificacionConv;
        }
      });

      if (totalCalificadas === 0) {
        porcentaje = 0 + "%";
      } else {
        porcentaje = (calificacion / totalCalificadas) * 100;
        porcentaje = porcentaje.toFixed(1) + "%";
      }

      data = {
        numero: porcentaje,
        encuestadas: totalCalificadas,
        cerradas: size,
        tooltip: parametros,
      };
      if(tot.totalCalificadas != totalCalificadas) {        
      yield put(action(data));
      }
    }
  } catch (error) {
    console.error("estadisticas.porcSatisfaccion: " + error);
    throw error;
  }
}

export function* datosGraficaConversacionesActuales(
  fechaIni,
  fechaFin,
  action,
  payload, selector_
) {
  let flag = false;
  let size = 0;
  let ejeX = [];
  let ejeY = [];
  try {
    let reference;
    let fechaInicial = moment(fechaIni);
    let fechaFinal = moment(fechaFin);
    let diferenciaDias = fechaFinal.diff(fechaInicial, "days");
    if (diferenciaDias <= 1) {
      flag = false;
    } else {
      flag = true;
    }
    let mapaHoras = new Map();

    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "in", [1, 2, 3, 4, 5, 6, 7, 8, 9])
      .where("fecha_ini", ">", new Date(fechaIni))
      .where("fecha_ini", "<", new Date(fechaFin));

    const channel = eventChannel((emit) => reference.onSnapshot(emit));



    let cambios = false;
    while (true) {

      if (fechaInicial.hour() === fechaFinal.hour()) {
        let horaInicial = fechaInicial.hour();
  
        for (let i = horaInicial + 1; i <= 24; i++) {
          mapaHoras.set(i, 0);
        }
        for (let i = 1; i <= horaInicial; i++) {
          mapaHoras.set(i, 0);
        }
      } else {
        for (let i = 0; i < 24; i++) {
          mapaHoras.set(i, 0);
        }
      }

      
      ejeY = [];
      ejeX = [];
      const realdata = yield take(channel);
      const tot = yield select(selector_)
      
      size = realdata.size;
      if(tot!=size) {
      realdata.docs.forEach(async (conv) => {
        if (!flag) {
          let hora = conv.data().fecha_ini.toDate().getHours();
          let totalHora = mapaHoras.has(hora) ? mapaHoras.get(hora) : 0;
          totalHora++;
          mapaHoras.set(hora, totalHora);
        } else {
          size = 0;
        }
        cambios = true;
      });

      mapaHoras.forEach((values, keys) => {
        ejeY.push(values);
        ejeX.push(keys);
      });

      payload["ejeX"] = ejeX;
      payload["ejeY"] = ejeY;
      if(cambios) {
      yield put(action({ payload, size }));
      
      cambios = false;
    }
    }

    }
  } catch (error) {
    console.error("estadisticas.datosGraficaConversacionesActuales", error);
    throw error;
  }
}



export function* obtenerPorcConvsEstado(
  fechaIni,
  fechaFin,
  estado,
  action,
  parametros
) {
  try {
    let reference;
    let totalConvs;
    let convsEstado;
    let porcConvs;

    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const company = getFromSession("company_id");
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    while (true) {
      totalConvs = 0;
      convsEstado = 0;
      porcConvs = 0;
      const realData = yield take(channel);
      totalConvs = realData.size;
      realData.docs.forEach((conv) => {
        let estadoConv = conv.data().estado ? conv.data().estado : false;

        if (estadoConv === estado) {
          convsEstado += 1;
        }
      });

      if (convsEstado > 0) {
        porcConvs = (convsEstado * 100) / totalConvs;
        porcConvs = porcConvs.toFixed(1);
      } else {
        porcConvs = 0;
      }
      let datos = {
        parametros: parametros,
        totalConvs: totalConvs,
        convsEstado: convsEstado,
        porcConvs: porcConvs,
      };
      
      yield put(action(datos));
    }
  } catch (error) {
    console.error("estadisticas.obtenerPorcConvsEstado", error);
    throw error;
  }
}


export function* obtenerPorcConvsAbandono(
  fechaIni,
  fechaFin,
  action,
  parametros,
  selector_
) {
  try {
    let reference;
    let totalConvs;
    let totalAbandonadas;
    let porcConvs;

    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const company = getFromSession("company_id");

      const coll = collection(firebaseDatabase, `company/${company}/conversations`);
      const query_ = query( coll, where("fecha_aceptacion", ">", timeIni)
      ,where("fecha_aceptacion", "<", timeFin) );
    
      let x = yield getCountFromServer(query_);
      totalConvs = x.data().count
    console.log(totalConvs);
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin)
      .where("estado", "==", 12);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));

    while (true) {
      const realData = yield take(channel);
       let tot = yield select(selector_);
       totalAbandonadas = realData.size;

      if(totalAbandonadas != tot.totalConvs ) {
 
      if (totalConvs > 0) {
        porcConvs = (totalAbandonadas * 100) / totalConvs;
        porcConvs = porcConvs.toFixed(1);
      } else {
        porcConvs = 0;
      }
      let datos = {
        parametros: parametros,
        totalConvs: totalConvs,
        convsEstado: totalAbandonadas,
        porcConvs: porcConvs,
      };
      yield put(action(datos));
    }
    }
  } catch (error) {
    console.error("estadisticas.obtenerPorcConvsEstado", error);
    throw error;
  }
}



export function* obtenerDataGraficaPieConvsAbandonadas(
  fechaIni,
  fechaFin,
  action,
  parametros
) {
  try {
    let reference;
    let convsAtendidas;
    let convsAbandonadas;
    let numeros;
    let size;
    let conteo;
    const timeIni = new Date(fechaIni);
    const timeFin = new Date(fechaFin);
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "in", [10, 11, 12])
      .where("fecha_ini", ">", timeIni)
      .where("fecha_ini", "<", timeFin);

    const channel = eventChannel((emit) => reference.onSnapshot(emit));
    while (true) {
      convsAtendidas = 0;
      convsAbandonadas = 0;

      numeros = false;
      size = 0;
      const realData = yield take(channel);
      size = realData.size;
      realData.docs.forEach((dato) => {
        if (dato.data().estado === 12) {
          convsAbandonadas += 1;
        } else if (dato.data().estado === 10 || dato.data().estado === 11) {
          convsAtendidas += 1;
        }
      });
      if (convsAtendidas === 0 && convsAbandonadas === 0) {
        numeros = "Sin Datos";
      } else {
        numeros = [convsAtendidas, convsAbandonadas];
      }
      parametros["numeros"] = numeros;

      yield put(action(parametros));
    }
  } catch (error) {
    console.error("estadisticas.obtenerDataGraficaPieConvsAbandonadas", error);
    throw error;
  }
}


export function* usuariosEnEspera( 
  action,
  payload,
  select_
) {

  let reference = false;
  let size;
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`

    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("estado", "==", 0)
      .where("cola", "==", "S");

    const channel = eventChannel((emit) => reference.onSnapshot(emit));
    while (true) {
      const tot = yield select( select_);          
      const realdata = yield take(channel);
      size = realdata.size;
      if(tot!=size) {
      yield put(action({ payload, size }));
      }
    }
  } catch (error) {
    console.error("estadisticas.totalConversacionesEstado: " + error);
    throw error;
  }
}
