import { firebaseDatabase } from "./firebase";
import { getFromSession } from "./session";

export function* usuariosConectados() {
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    let users = [];
    let consulta = yield firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .where("estado", "==", 1)
      .orderBy("fecha_ini", "desc")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          users.push(doc);
        });
      })
      .catch((err) => {
          users = false;
        console.error("controller.connectedUsers.usuariosConectados.consulta", err);
      });

    return users;
  } catch (error) {
    console.error("controller.connectedUsers.usuariosConectados", error);
    throw error;
  }
}
export function* actualizarUltimaFechaAceptacion(idDoc) {
  try {
    const company = getFromSession("company_id");
    firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .doc(idDoc)
      .update({
        last_conv_taked: new Date(),
      });
  } catch (error) {
    console.error(
      "controller.connectedUsers.actualizarUltimaFechaAceptacion",
      error
    );
    throw error;
  }
}

export function* terminarSesionAgente(idDoc) {
  try {
    const company = getFromSession("company_id");
    yield firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .doc(idDoc)
      .update({
        estado: 2,
        fecha_fin: new Date(),
      
      });
      //conv_actuales: 0, <--esto iba en el update
  } catch (error) {
    console.error("connectedUsers.terminarSesionAgente", error);
    throw error;
  }
}

export function* setEstado(newEstado) {
  try {
    let resp = false;

    let connectedUser = getFromSession("connectedUser");
    let company = getFromSession("company_id");
    yield firebaseDatabase
      .collection(`company/${company}/connectedUsers`)
      .doc(connectedUser)
      .update({
        estado: newEstado,
      })
      .then((respuesta) => {
        resp = true;
      })
      .catch((err) => {
        resp = false;
      });

    return resp;
  } catch (error) {
    console.error("controller.connectedUser.setEstado", error);
    throw error;
  }
}
