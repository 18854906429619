import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import ComponentTableDoc from "./ocrTable/componentTable";
import { Paper } from "@material-ui/core";

class ComponentDocumentosFallidos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labelTable:{
                columna1:'Fecha de cargue',
                columna2:'Nombre',
                columna3:'Estado',
                columna4:'Motivo',
                columna7: 'Id'
            }
        }
    }

  
    componentWillMount() {
        const {
            data

        } = this.props;
        this.setState({
            filtroTable: data
        })
        console.log("DATA DOCUMENTOS CON ERROR", data)
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { data } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = data.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }
    componentDidUpdate(prevProps) {
        const {
            data
        } = this.props;
        // if (prevProps.data !== data) {
        //     console.log("datadata", data)
        //     this.setState({
        //         filtroTable: [...data]
        //     })
        // }

        if (prevProps.data !== data) {
            //  console.log("documentDataSelector", documentDataSelector);
             // getOCRDocumentos();
              this.setState({
                  filtroTable: [...data]
              })
          }
    }

    render() {
        const {
            data
        } = this.props;

        return (
            <div className="App borrador-container">
                  <Paper elevation={10} className="paper-conversaciones-ocr">
                 <ComponentTableDoc
                    data={data}
                    title={'Documentos Fallidos'}
                    labelTable={this.state.labelTable} />
                </Paper>
                
           
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDocumentosFallidos);