export const UPLOAD_FILE = '@flujoMensajes/uploadFile';
export const DOWNLOAD_FILE = '@flujoMensajes/downloadFile';
export const REMOVE_FILE = '@flujoMensajes/removeFile';
export const IMPORT_FILE = '@flujoMensajes/importFile';
export const MANAGE_NAV_BUTTON = '@flujoMensajes/manageNavButton';
export const START_WAITING = '@flujoMensajes/startWaiting';
export const REBOOT_PAGE = '@flujoMensajes/rebootPage';
export const SET_URL_AUDITLOG = '@flujoMensajes/setUrlAuditLog';

export const getRecursosEconomicos = (handleUpload,importarFile, handleDownload) => {
    const RECURSOS_ECONOMICOS = [

        {
            label: "Importar y Exportar Intents",
            name: "gestionarFiles",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: [
                {
                    name: "uploadExcel",
                    placeholder: "Subir Archivo Excel",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 7000000,
                    tooltip: "Adjunta en este campo la fotocopia del certificado de la entidad financiera donde cuenta con el ahorro programado con el monto y fecha de inmovilización, con fecha de expedición no mayor a 30 días",
                    className: "rs-80 adjuntar"
                },
                {
                    name: "importFile",
                    label: "Importar Archivo",
                    type: "button",
                    typeForm: "button",
                    primary: true,
                    value: false,
                    options: [],
                    hidden: false,
                    disabled: false,
                    className: "rs-30 buton-action",
                    onClick: importarFile,
                },
                {
                    name: "downloadExcel",
                    label: "Exportar Archivo",
                    type: "button",
                    typeForm: "button",
                    primary: true,
                    value: false,
                    options: [],
                    hidden: false,
                    disabled: false,
                    className: "rs-30  buton-action",
                    onClick: handleDownload,
                },
            ]
        }]
    return RECURSOS_ECONOMICOS;
}