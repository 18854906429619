export const GET_USERS = '@ocr/getAllDocument';
export const GET_USERS_SUCCESS = '@ocr/getUsersSuccessDocument';
export const GET_USERS_ERROR = '@ocr/getUsersErrorDocument';
export const LOAD_MENU = '@ocr/loadMenuDocument';
export const LOAD_MENU_SUCCES = '@ocr/loadMenuSucessDocument';
export const COMPANY_DATA_SUCCESS = '@ocr/companyDataSucessDocument';
export const COMPANY_DATA = '@ocr/companyDataDocument';
export const GET_OCR_DOC = '@ocr/getOcrDocumentsDocument';
export const GET_OCR_DOC_SUCCESS = '@ocr/getOcrDocumentsSuccessDocument';
export const GET_DOC_PROC = '@ocr/getProcessSideBarDocuments';
export const GET_DOC_PROC_SUCCESS = '@ocr/getProcessDocumentsSideBarSuccess';
export const GET_DOC_FALL = '@ocr/getFailDocuments';
export const GET_DOC_FALL_SUCCESS = '@ocr/getFailDocumentsSuccess';
export const DOWNLOAD_DOC = '@ocr/downloadDocu';
export const DOWNLOAD_DOC_SUCCESS = '@ocr/downloadDocuSuccess';
export const SHOW_DOC_PROC = '@ocr/showProcDocu';
export const SHOW_DOC_PROC_SUCCESS = '@ocr/showProcDocuSuccess';

export const DOCUMENT_FORM = '@ocr/DocumentForm';
export const DOCUMENT_FORM_SUCCESS = '@ocr/DocumentFormSuccess';
export const DOCUMENT_FORM_ERROR = '@ocr/DocumentFormError';




export const GUARDAR_DOCUMENT_FORM = '@ocr/guardarDocumentForm';
export const GUARDAR_DOCUMENT_FORM_SUCCESS = '@ocr/guardarDocumentFormSuccess';
export const GUARDAR_DOCUMENT_FORM_ERROR = '@ocr/guardarDocumentFormError';



export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];
