import {
  COMPANY_DATA_SUCCESS,
  CONTADOR_BUCKET_SUCCESS,
  CROSS_DOC,
  CROSS_DOC_SUCCESS,
  GET_DOC_FALL,
  GET_DOC_FALL_SUCCESS,
  GET_DOC_PROC,
  GET_DOC_PROC_SUCCESS,
  GET_OCR_DOC_SUCCESS,
  GET_OCR_SIZE_SUCCESS,
  GET_USERS_SUCCESS,
  LOAD_MENU_SUCCES,
  OCR_ACTIVO_SUCCESS,
  OCR_CREATE_SUCCESS,
  PRO_OCR_DOC,
  PRO_OCR_DOC_SUCCESS,
  SHOW_DOC_PROC_SUCCESS,
  UPLOAD_BUCKET,
  UPLOAD_BUCKET_SUCCESS,
  UPLOAD_DOC_SUCCESS,
  USER_DATA,
  USER_HEADERS,
  GET_LOTES_SUCCESS,
  DOCUMENTOS_CARGADOS_SUCCESS,
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItemsOCR: false,
  dataCompany: false,
  uploadBucket: false,
  loading: false,
  loadingCircular: false,
  loadingCircularFall: false,
  showToast: false,
  toastDetail: false,
  ocrActivo: false,
  ocrCreate: false,
  contador: 0,
  showTable: false,
  proDocument: false,
  longitud: 0,
  nombre: false,
  documentData: false,
  size: false,
  procDoc: false,
  showDoc: false,
  failDocs: false,
  crossDoc: false,
  loadingProc: false,
  showDoc: false,
  lotes: false,
  documentosCargados: false,
  alert: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  newState.showToast = false;
  //newState.showTable = false;
  switch (type) {
    case GET_USERS_SUCCESS:
      const { data } = payload;
      newState.users = data;

      return {
        ...newState
      };


    case GET_DOC_PROC_SUCCESS:
      //newState.loadingCircular = false;
      newState.procDoc = payload;
      console.log("documentosProcesadosSelector reducer lote", newState.procDoc)

      return {
        ...newState
      };


    case GET_DOC_PROC:
      //newState.loadingCircular = true;

      return {
        ...newState
      };


    case CROSS_DOC_SUCCESS:


      newState.crossDoc = payload.consultaData;
      newState.showCross = payload.showDocument;
      newState.datosDocPrincipal = payload.datosDocPrincipal;
      newState.loadingCircular = false;

      console.log("CROSS_DOC_SUCCESS PRINCOPAL", newState.datosDocPrincipal)
      //console.log("loadingCircular reducer LOTE", newState.loadingCircular)

      return {
        ...newState
      };


    case CROSS_DOC:

      newState.loadingCircular = true;
      //console.log("loadingCircular no success LOTE", newState.loadingCircular)

      return {
        ...newState
      };


    case GET_DOC_FALL_SUCCESS:
      //newState.loadingCircularFall = false;
      newState.failDocs = payload;
      //console.log("documentosProcesadosSelector reducer", newState.procDoc)

      return {
        ...newState
      };


    case GET_DOC_FALL:
      //newState.loadingCircular = true;

      return {
        ...newState
      };





    case SHOW_DOC_PROC_SUCCESS:
      newState.showDoc = payload;
      //console.log("newState.showDoc0", newState.showDoc)

      return {
        ...newState
      };

    case COMPANY_DATA_SUCCESS:
      newState.dataCompany = payload;

      return {
        ...newState
      };

    case UPLOAD_BUCKET:
      //console.log("reducer upload bucket", payload)
      newState.uploadBucket = payload;
      newState.loading = true;
      newState.showToast = false;
      newState.contador = 0;
      newState.longitud = 0;
      newState.showTable = false;
      newState.nombre = false;


      return {
        ...newState
      };


    case CONTADOR_BUCKET_SUCCESS:
      newState.contador = payload.contador;
      newState.longitud = payload.length;
      newState.nombre = payload.nombre;
      //console.log("CONTADOR_BUCKET_SUCCESS", payload);

      return {
        ...newState
      };

    case UPLOAD_DOC_SUCCESS:
      // console.log("reducer upload bucket success", payload)
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      newState.showTable = payload.showTable;


      return {
        ...newState
      };



    case UPLOAD_BUCKET_SUCCESS:
      // console.log("reducer upload bucket success", payload)
      newState.uploadBucket = payload;
      newState.loading = false
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      newState.showTable = payload.showTable;


      return {
        ...newState
      };


    case LOAD_MENU_SUCCES:
      newState.menuItemsOCR = payload;

      return {
        ...newState
      };


    case OCR_CREATE_SUCCESS:
      newState.ocrCreate = payload;

      return {
        ...newState
      };

    case OCR_ACTIVO_SUCCESS:
      newState.ocrActivo = payload;

      return {
        ...newState
      };

    case GET_OCR_DOC_SUCCESS:
      newState.documentData = payload.consultaData;
      newState.alert = payload.alert;
      // newState.showToast = payload.showToast;
      // newState.toastDetail = payload.toastDetail;
      // newState.showTable = payload.showTable;
      //console.log("ALERT REDUCER", alert)
      //console.log("PAYLOAD GET DOCUMENTS", payload)
      //console.log("newState.documentData", newState.documentData)

      return {
        ...newState
      };

    case PRO_OCR_DOC_SUCCESS:
      newState.proDocument = payload.value; 
      newState.loadingProc = false;
      //console.log("REDUCERRRR SUCESS PROCESAR", payload.value)

      return {
        ...newState
      };

    case PRO_OCR_DOC:

      //console.log("REDUCERRRR PROCESAR")
      newState.loadingProc = true;
      newState.documentosCargados = false;
      return {
        ...newState
      };

    // case PRO_OCR_DOC_SUCCESS:
    //   newState.loadingProc = true;

    //   return {
    //     ...newState
    //   };

    case DOCUMENTOS_CARGADOS_SUCCESS:

      newState.documentosCargados = payload;
      console.log("REDUCERRRR PROCESAR", newState.documentosCargados)
      newState.proDocument = false;
      return {
        ...newState
      };





    case GET_OCR_SIZE_SUCCESS:
      newState.size = payload;

      return {
        ...newState
      };

    case GET_LOTES_SUCCESS:
      newState.lotes = payload;

      return {
        ...newState
      };



    default:
      return {
        ...newState
      };
  }
};

export default reducer;
