export const UPDATE_FIELD_ATTRIBUTE = '@enviosMasivos/updateAttribute';
export const FORM_ENVIOS_NAME = "envios";
export const GUARDAR_ENVIO = '@enviosMasivos/guardarEnvio';
export const GUARDAR_ENVIO_SUCCES = '@enviosMasivos/guardarEnvioSucces';
export const GUARDAR_ENVIO_ERROR = '@enviosMasivos/guardarEnvioError';
export const SEND_ENVIO = '@enviosMasivos/sendEnvio';
export const SEND_ENVIO_SUCCES = '@enviosMasivos/sendEnvioSucces';
export const SEND_ENVIO_ERROR = '@enviosMasivos/sendEnvioError';
export const LIST_ENVIO = '@enviosMasivos/listEnvio';
export const LIST_ENVIO_SUCCES = '@enviosMasivos/listEnvioSucces';
export const LIST_ENVIO_ERROR = '@enviosMasivos/listEnvioError';
export const LOAD_CSV = '@enviosMasivos/loadCsv'
export const LOAD_CSV_SUCCES = '@enviosMasivos/loadCsvSucces'
export const LOAD_CSV_ERROR = '@enviosMasivos/loadCsvError'
export const LIST_USERS = '@enviosMasivos/listUsers';
export const LIST_USERS_SUCCES = '@enviosMasivos/listUsersSucces';
export const LIST_USERS_ERROR = '@enviosMasivos/listUsersError';
export const IR_NUEVO = '@enviosMasivos/irNuevo';
export const VER_ENVIO = '@enviosMasivos/verEnvio';
export const VER_ENVIO_SUCCES = '@enviosMasivos/verEnvioSucces';
export const VER_ENVIO_ERROR = '@enviosMasivos/verEnvioError';
export const REMOVE_FILE = '@enviosMasivos/removeFile';
export const EDITAR_ENVIO = '@enviosMasivos/editarEnvio';
export const EDITAR_ENVIO_SUCCESS = '@enviosMasivos/editarEnvioSuccess';
export const EDITAR_ENVIO_ERROR = '@enviosMasivos/editarEnvioError';
export const LIMPIAR_FORM = '@enviosMasivos/limpiarCamposForm';
export const BORRAR_ENVIO = '@enviosMasivos/borrarEnvio';
export const BORRAR_ENVIO_SUCCESS = '@enviosMasivos/borrarEnvioSuccess';
export const BORRAR_ENVIO_ERROR = '@enviosMasivos/borrarEnvioError';
export const TOTAL_ENVIOS = "@enviosMasivos/obtenerTotalEnviados";
export const TOTAL_ENVIOS_SUCCESS = "@enviosMasivos/obtenerTotalEnviadosSucces";
export const TOTAL_ENVIOS_ERROR = "@enviosMasivos/obtenerTotalEnviadosError";
export const TOTAL_MENSAJES_LEIDOS = "@enviosMasivos/obtenerMensajesLeidos";
export const TOTAL_MENSAJES_LEIDOS_SUCCESS = "@enviosMasivos/obtenerMensajesLeidosSuccess";
export const TOTAL_MENSAJES_LEIDOS_ERROR = "@enviosMasivos/obtenerMensajesLeidosError";
export const CAMPOS_PLANTILLA = "@enviosMasivos/traerCamposComboPlantilla";
export const CAMPOS_PLANTILLA_SUCCESS = "@enviosMasivos/traerCamposComboPlantillaSuccess";
export const CAMPOS_PLANTILLA_ERROR = "@enviosMasivos/traerCamposComboPlantillaError";
export const ARCHIVOS_ADJUNTOS = "@enviosMasivos/adjuntarArchivosEnviosMasivos";
export const ARCHIVOS_ADJUNTOS_SUCCESS = "@enviosMasivos/adjuntarArchivosEnviosMasivosSuccess";
export const ARCHIVOS_ADJUNTOS_ERROR = "@enviosMasivos/adjuntarArchivosEnviosMasivosError";
export const DISABLE_CHOOSE_BUTTON = "@enviosMasivos/desactivarBotonElegirArchivo";
export const CONTAR_USUARIOS = "@enviosMasivos/contarUsuariosListados";
export const CONTAR_USUARIOS_SUCCESS = "@enviosMasivos/contarUsuariosListadosSuccess";
export const PAGINAR_DESTINATARIOS = "@enviosMasivos/paginarDestinatarios";
export const PAGINAR_DESTINATARIOS_SUCCESS = "@enviosMasivos/paginarDestinatariosSuccess";
export const PROM_TIEMPO_LECTURA = "@enviosMasivos/calcularTiempoPromedioLectura";
export const PROM_TIEMPO_LECTURA_SUCCESS = "@enviosMasivos/calcularTiempoPromedioLecturaSuccess";
export const FILTRAR_DESTINATARIOS = "@enviosMasivos/filtrarDestinatarios";
export const FILTRAR_DESTINATARIOS_SUCCESS = "@enviosMasivos/filtrarDestinatariosSuccess";
export const FILTRAR_DESTINATARIOS_ERROR = "@enviosMasivos/filtrarDestinatariosError";
export const TOTAL_DESTINATARIOS = "@enviosMasivos/traerDestinatarios";
export const TOTAL_DESTINATARIOS_SUCCESS = "@enviosMasivos/traerDestinatariosSuccess";
export const TOTAL_DESTINATARIOS_ERROR = "@enviosMasivos/traerDestinatariosError";
export const TOTAL_LEIDOS = "@enviosMasivos/realizarConteoLeidos";
export const TOTAL_LEIDOS_SUCESS = "@enviosMasivos/realizarConteoLeidosSuccess";
export const ENVIOS_INDIVIDUALES = "@enviosMasivos/obtenerEnviosIndividuales";
export const ENVIOS_INDIVIDUALES_SUCCESS = "@enviosMasivos/obtenerEnviosIndividualesSuccess";
export const PAGINAR_ENVIOS_INDIVIDUALES = "@enviosMasivos/paginarEnviosApi";
export const PAGINAR_ENVIOS_INDIVIDUALES_SUCCESS = "@enviosMasivos/paginarEnviosApiSuccess";
export const FILTRAR_ENVIOS_API = "@enviosMasivos/filtrarEnviosApi";
export const FILTRAR_ENVIOS_API_SUCCESS = "@enviosMasivos/filtrarEnviosApiSuccess";
export const DESCARGAR_CSV_ENVIOS = "@enviosMasivos/descargarCsvEnvioMasivo";
export const DESCARGAR_CSV_ENVIOS_SUCCESS = "@enviosMasivos/descargarCsvEnvioMasivoSuccess"

export const FILTRAR_LISTA_LEADS_SUCCESS = "@enviosMasivos/filtrarListaLeadsSuccess";
export const FILTRAR_LISTA_LEADS = "@enviosMasivos/filtrarListaLeads";
export const CONTAR_LEADS_POR_ENVIO = "@enviosMasivos/contarLeadsPorEnvio";
export const OPEN_CONVERSATION_ENVIO_MASIVO = "@enviosMasivos/openConversationEnvioMasivo";
export const SELECT_CONVERSATION_DETAIL_SUCCESS = "@enviosMasivos/selectConversationDetailSuccess";
export const CLOSE_MODAL_CONVERSATION_DETAIL_ENVIOS = "@enviosMasivos/closeModalConversationDetailEnvios";
