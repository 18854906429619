import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Paper, Button } from "@material-ui/core";

class ComponentPublicar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            div: '<div data-company=',
            company: false,
            id: ' id="chatbot-avanti"></div>',
            script1: '<script src="https://cliente.avanti-it.co/static/js/main-chatbot.js"></script>',
            script2: '<script src="https://cliente.avanti-it.co/static/js/main2-chatbot.js"></script>',
            script3: '<script src="https://cliente.avanti-it.co/static/js/runtime-chatbot.js"></script>',
        }

    }

    componentWillMount() {

        const {
            company
        } = this.props
        console.log("compaany", company.cuenta)
        this.setState({company: this.state.div + '"'+ company.cuenta +'"' + this.state.id})
    }

    render() {
        const {
            onPublicarCloseHandler
        } = this.props;

        return (
            <div className="App borrador-container">

                <div className='chatbot-wrapper'>
                    <div className="titulo">
                        <h1>
                            Publica tu Bot
                        </h1>
                    </div>
                    <div className='crear-intent'>
                        <Paper elevation={10} className="paper-conversaciones" >
                            <div className="boton-volver">
                                <Button className="boton" color='primary' variant="contained" onClick={() => { onPublicarCloseHandler() }}>
                                    Volver
                                </Button>
                            </div>
                            <div className="label-align">
                                <h3>
                                    Para publicar tu bot, copia el codigo bajo este texto y pegalo en tu sitio web
                                </h3>
                            </div>
                            <div className="respuestas-container">

                                <div className="code-wrapper">
                                    <code className="code-estilos">
                                        {this.state.company}
                                    </code>
                                </div>

                                <div className="code-wrapper">
                                    <code className="code-estilos">
                                        {this.state.script1}
                                    </code>
                                </div>
                                <div className="code-wrapper">
                                    <code className="code-estilos">
                                        {this.state.script2}
                                    </code>
                                </div>
                                <div className="code-wrapper">
                                    <code className="code-estilos">
                                        {this.state.script3}
                                    </code>
                                </div>
                            </div>
                            <div className="label-align">
                                <h3>
                                    Para publicar tu bot en WhatsApp, por favor envia un correo a chatasesores@gmail.com con tu solicitud
                                </h3>
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentPublicar);

