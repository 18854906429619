import React, { Component } from "react";
import "./style.css";
import { getFromSession } from "../../../controller/session";
import { TextField, Button } from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

class ComponentBotton extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onTituloClick = (e) => {
        this.setState({
            title: e.target.value,
            respuesta: {
                title: e.target.value,
                subtitle: this.state.subtitle,
                type: 'quick_reply',
                options: [{
                    "value": this.state.opcion1,
                    "label": this.state.opcion1
                }, {
                    "value": this.state.opcion2,
                    "label": this.state.opcion2
                }]
            }

        })
        console.log("this TITLE", this.state.title)
    }

  
    onSubtituloClick = (e) => {
        this.setState({
            subtitle: e.target.value,

            respuesta: {
                title: this.state.title,
                subtitle: e.target.value,
                type: 'quick_reply',
                options: [{
                    "value": this.state.opcion1,
                    "label": this.state.opcion1
                }, {
                    "value": this.state.opcion2,
                    "label": this.state.opcion2
                }]
            }
        })
    }
    onOpcion1Click = (e) => {
        this.setState({
            opcion1: e.target.value,
            respuesta: {
                title: this.state.title,
                subtitle: this.state.subtitle,
                type: 'quick_reply',
                options: [{
                    "value": e.target.value,
                    "label": e.target.value
                }, {
                    "value": this.state.opcion2,
                    "label": this.state.opcion2
                }]
            }
        })
    }
    onAddButton = () => {
        this.setState({
            visibleButton: true
        })
    }
    onDeleteButton = () => {
        this.setState({
            visibleButton: false,
            //opcion3: e.target.value,
            respuesta: {
                title: this.state.title,
                subtitle: this.state.subtitle,
                type: 'quick_reply',
                options: [{
                    "value": this.state.opcion1,
                    "label": this.state.opcion1
                }, {
                    "value": this.state.opcion2,
                    "label": this.state.opcion2
                }]
            }
        })
    }

    onOpcion2Click = (e) => {
        this.setState({
            opcion2: e.target.value,
            respuesta: {
                title: this.state.title,
                subtitle: this.state.subtitle,
                type: 'quick_reply',
                options: [{
                    "value": this.state.opcion1,
                    "label": this.state.opcion1
                }, {
                    "value": e.target.value,
                    "label": e.target.value
                }]
            }
        })
    }

    onOpcion3Click = (e) => {
        this.setState({
            opcion3: e.target.value,
            respuesta: {
                title: this.state.title,
                subtitle: this.state.subtitle,
                type: 'quick_reply',
                options: [{
                    "value": this.state.opcion1,
                    "label": this.state.opcion1
                }, {
                    "value": this.state.opcion2,
                    "label": this.state.opcion2
                }, {
                    "value": e.target.value,
                    "label": e.target.value
                }]
            }
        })
    }

    onSendResponse = () => {
        this.onButtonHandler();
    }


    onButtonHandler = () => {
        const { onChange, index } = this.props;
        console.log("RESPUESTA ON BOTTON HANDLERE", this.state.respuesta)
        onChange({
            value: this.state.respuesta, index, type: 'button',
        })
    }

    componentDidMount() {
        const { respuestaButton } = this.props
        console.log("respuestaButton DIDUPDATE", respuestaButton)
        if (respuestaButton && respuestaButton.type === 'quick_reply' && respuestaButton.options === undefined) {
            this.setState({
                subtitle: respuestaButton.subtitle,
                title: respuestaButton.title,
                //opcion1: respuestaButton.options[0]?.value,
                //opcion2: respuestaButton.options[1]?.value,
                //opcion3: respuestaButton.options[2]?.value,
                type: respuestaButton.type,
                options: respuestaButton.options
            })
        }
        if (respuestaButton && respuestaButton.type === 'quick_reply' && respuestaButton.options !== undefined) {
            this.setState({
                subtitle: respuestaButton.subtitle,
                title: respuestaButton.title,
                opcion1: respuestaButton.options[0]?.value,
                opcion2: respuestaButton.options[1]?.value,
                opcion3: respuestaButton.options[2]?.value,
                type: respuestaButton.type,
                options: respuestaButton.options
            })
            if (respuestaButton && respuestaButton.type === 'quick_reply' && respuestaButton.options[2]) {
                this.setState({
                    visibleButton: true
                })
            }
        }



    

    }

    render() {
        const { placeholder, respuestaButton } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="formGroupLabel-button">
                {placeholder}

                <div className="formGroup-button">

                    <TextField placeholder="Agrege el titulo del botón"
                        onChange={this.onTituloClick}
                        value={this.state.title}>
                    </TextField>

                    <TextField placeholder="Agrege la descripcion del botón"
                        onChange={this.onSubtituloClick}
                        value={this.state.subtitle}>
                    </TextField>

                    <div className="respuesta-botones"  >
                        <div className="botones-respuesta">

                            <TextField className="textfield-botton"
                                placeholder="Respuesta uno"
                                onChange={this.onOpcion1Click}
                                value={this.state.opcion1}>
                            </TextField>

                            <TextField className="textfield-botton"
                                placeholder="Respuesta dos"
                                onChange={this.onOpcion2Click}
                                value={this.state.opcion2}>
                            </TextField>
                            {this.state.visibleButton ?

                                <TextField className="textfield-botton"
                                    placeholder="Respuesta tres"
                                    onChange={this.onOpcion3Click}
                                    value={this.state.opcion3}>
                                </TextField>

                                : ''}
                        </div>

                    </div>

                    <div className="icons">
                        <div className="añadir-botton" >
                            <AddCircleIcon color='primary' onClick={this.onAddButton} variant="contained" />
                            Añadir Boton
                        </div>
                        <div className="añadir-botton" onClick={this.onDeleteButton}>
                            <RemoveCircleIcon color='error' variant="contained" />
                            Eliminar Boton
                        </div>
                    </div>
                    <div className="boton-respuesta-boton">
                        <Button color='primary' variant="contained" onClick={this.onSendResponse}>Guardar botón</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComponentBotton;