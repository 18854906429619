import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';

import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';

export class DataTableCrudDemo extends Component {

    datasTable() {
    }

    constructor(props) {
        super(props);

        this.state = {
        };

        
        this.exportCSV = this.exportCSV.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        
    }



    componentDidMount() {
    }    

    exportExcel(data) {

        // const inth = []
        // data.forEach(element => {
        //     if (element.inthogar) {
        //         inth.push(element.inthogar);
        //     }
        // });
        // import('xlsx').then(xlsx => {
        //     const worksheet = xlsx.utils.json_to_sheet(data);
        //     const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        //     const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        //     this.saveAsExcelFile(excelBuffer, 'solicitudes');
        // });
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }
    
    exportCSV() {
        this.dt.exportCSV();
    }
   

    rightToolbarTemplate(props) {
        const { datosTable,exporExcel } = props;
        return (
            <React.Fragment>
                <Button label="Descargar CSV" icon="pi pi-upload" className="p-button-help" onClick={() => this.exportCSV(datosTable)} />
            </React.Fragment>
        )
    }



    render() {
        const {
            datosTable,
            headerTable,
            titleTable,
            nameTable,
        } = this.props;


        const header = (
            <div className="table-header">
                <h5 className="tituloChart">{titleTable}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Buscar..." />
                </span>
            </div>
        );


        return (
            <div className="datatable-crud-demo">
                <Toast ref={(el) => this.toast = el} />

                <div className="card">
                    <DataTable ref={(el) => this.dt = el} value={datosTable} scrollable  // style={{ fontWeight: '700', width: '1200px' }}
                        dataKey="numDoc" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate={`Observando {first} a {last} de {totalRecords} ${nameTable}`}
                        globalFilter={this.state.globalFilter} className="claseTablas"
                        onRowExpand={this.onRowExpand} onRowCollapse={this.onRowCollapse}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                        header={header}>
                        {
                            headerTable?.map((item, i) => {
                                    return <Column className="columaTable" columnKey={item.field + i} cellIndex={i} field={item.field} header={item.header} sortable={item.sortable?true:false}></Column>
                            })
                        }


                    </DataTable>
                        {datosTable.length > 0 ?

                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate(this.props)}></Toolbar>
                        : ""}
                </div>
            </div>
        );
    }
}


