import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';

export function* lstrAgentes(){
let agentes=[];

const company = getFromSession("company_id");

yield firebaseDatabase.collection(`company/${company}/usuarios`).orderBy('nombres').get().then(docs=>{
 
    docs.forEach(doc=>{
        agentes.push(doc);
        
    });
});
return agentes;
}



