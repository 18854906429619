import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import validate from './validateHorarioConfig';
import { Button } from 'primereact/button';
import { connect } from 'react-redux'


const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderSelectDaysField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <select className="selectStyle p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus"  {...input} type={type} placeholder={label} >
                <option />
                <option value="LUNES">Lunes</option>
                <option value="MARTES">Martes</option>
                <option value="MIERCOLES">Miercoles</option>
                <option value="JUEVES">Jueves</option>
                <option value="VIERNES">Viernes</option>
                <option value="SABADO">Sabado</option>
                <option value="DOMINGO">Domingo</option>
            </select>
        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderDays = ({ fields, meta: { touched, error, submitFailed } }) => (

    <div className="pl-0 mt-3 ml-2 p-grid">
        <div className="p-col-fixed p-mt-3" style={{ width: "335px" }}>
            <div className="p-text-normal p-text-left ml-2">
                Añadir día de la semana en específico:
            </div>
        </div>
        <div>
            {fields.map((dia, index) => (
                <div key={index}>
                    <div className="p-grid ">
                        <div className="p-col mr-2"><h4>{index + 1}</h4></div>
                        <Field
                            name={`${dia}.nombreDia`}
                            label="Seleccione un día"
                            component={renderSelectDaysField}
                            type="text"
                        >
                        </Field>
                        <Field
                            name={`${dia}.ini`}
                            type="time"
                            component={renderField}
                            label="Desde"
                        />
                        <Field
                            name={`${dia}.fin`}
                            type="time"
                            component={renderField}
                            label="Hasta"
                        />
                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}
        </div>
        <div className='p-grid p-align-end'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir día" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" type="button" onClick={() => fields.push({})} />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>


    </div>
);
const renderFechas = ({ fields, meta: { touched, error, submitFailed } }) => (

    <div className="pl-0 p-grid mt-4 ml-2">
        <div className="p-col-fixed p-mt-3" style={{ width: "335px" }}>
            <div className="p-text-normal p-text-left ml-2">
                Añadir fecha en específico:
            </div>
        </div>
        <div>
            {fields.map((fecha, index) => (
                <div key={index}>

                    <div className="p-grid">
                        <div className="p-col"><h4>{index + 1}</h4></div>
                        <Field
                            name={`${fecha}.fecha`}
                            label="Seleccione una fecha"
                            component={renderField}
                            type="date"
                        >
                        </Field>
                        <Field
                            name={`${fecha}.ini`}
                            type="time"
                            component={renderField}
                            label="Desde"
                        />
                        <Field
                            name={`${fecha}.fin`}
                            type="time"
                            component={renderField}
                            label="Hasta"
                        />
                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}
        </div>
        <div className='p-grid p-align-end'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir fecha" icon="pi pi-plus" className="p-button-raised p-button-rounded p-button-success" type="button" onClick={() => fields.push({})} />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>
    </div>
);


const FieldArraysForm = props => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (

        <form onSubmit={handleSubmit} >
            {/*   <FieldArray name="reg_hours" component={renderRegHours}/> */}
            <div className="p-grid">
                <div className="p-col-fixed" style={{ width: "350px" }}>
                    <div className="p-text-normal p-text-left p-ml-4">
                        Escriba el horario de lunes a viernes:
                    </div>
                </div>
                <Field
                    name="reg_hours_ini"
                    type="time"
                    component={renderField}
                    label="Desde"
                />
                <Field
                    name="reg_hours_fin"
                    type="time"
                    component={renderField}
                    label="Hasta"
                />
            </div>
            <div className="p-grid">
                <div className="p-col-fixed" style={{ width: "350px" }}>
                    <div className="p-text-normal p-text-left p-ml-4">
                        Escriba el horario para sábados y domingos:
                    </div>
                </div>
                <Field
                    name="weekend_hours_ini"
                    type="time"
                    component={renderField}
                    label="Desde"
                />
                <Field
                    name="weekend_hours_fin"
                    type="time"
                    component={renderField}
                    label="Hasta"
                />
            </div>


            <FieldArray name="diasEspeciales" component={renderDays} />
            <FieldArray name="fechasEspeciales" component={renderFechas} />
            <div className="p-mt-2 p-grid p-justify-center">
                <div>
                    <Button icon="pi pi-save" label="Guardar" tooltip="Guardar" className="mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-info" type="submit" disabled={submitting || pristine} />
                    <Button icon="pi pi-ban" tooltip="limpiar" className="p-button-raised p-button-rounded p-button-success p-button-warning" type="button" disabled={pristine || submitting} onClick={reset} />
                </div>

            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    //console.log('***state****', state.form.fieldArraysHorario);
    return {
        initialValues: {
            "diasEspeciales": state.localReducer.horario.diasEspeciales,
            "fechasEspeciales": state.localReducer.horario.fechasEspeciales,
            "reg_hours_ini": state.localReducer.horario.reg_hours.ini,
            "reg_hours_fin": state.localReducer.horario.reg_hours.fin,
            "weekend_hours_ini": state.localReducer.horario.weekend_hours.ini,
            "weekend_hours_fin": state.localReducer.horario.weekend_hours.fin,
        },

    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'fieldArraysHorario',
    enableReinitialize: true,
    validate,

})(FieldArraysForm));
