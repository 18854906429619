import { MANAGE_SPINER } from '../adminPanel/constants';
import {
    UPDATE_FIELD_ATTRIBUTE,
    MOSTRAR_RANGOS,
    OCULTAR_RANGOS,
    DATA_LINECHART,
    DATA_LINECHART_SUCCESS,
    TMPESP_PROM,
    TMPESP_PROM_SUCCESS,
    TMPESP_PROM_ERROR,
    TMPRESP_PROM,
    TMPRESP_PROM_SUCCESS,
    TMPCONV_PROM,
    TMPCONV_PROM_SUCCESS,
    CONVS_RESUELTAS,
    CONVS_RESUELTAS_SUCCESS,
    CONVS_CANAL,
    CONVS_CANAL_SUCCESS,

    CONVS_CERRADAS,
    CONVS_CERRADAS_SUCCESS,
    CONVS_CERRADAS_ERROR,

    CONVS_ABANDONADAS,
    CONVS_ABANDONADAS_SUCCESS,
    CONVS_ABANDONADAS_ERROR,

    LANZAR_EVENTOS,
    LISTAR_AGENTES,
    LISTAR_AGENTES_SUCCESS,
    LISTAR_ESTADOS,
    LISTAR_ESTADOS_SUCCESS,
    GENERAL_ERROR,
    FILTRAR_BUSQUEDA,
    FILTRAR_BUSQUEDA_SUCCESS,
    FILTRAR_BUSQUEDA_ERROR,

    SELECT_CONVERSATIONDETAIL,
    SELECT_CONVERSATIONDETAIL_SUCCESS,
    SELECT_CONVERSATIONDETAIL_ERROR,

    MODAL_DESCARGAR_DATOS,
    MODAL_DESCARGAR_DATOS_SUCCESS,
    MODAL_DESCARGAR_DATOS_ERROR,
    OCULTAR_MODAL_DESCARGAR,
    DATA_LINECHART_ERROR,
    TMPRESP_PROM_ERROR,
    TMPCONV_PROM_ERROR,
    CONVS_RESUELTAS_ERROR,
    CONVS_CANAL_ERROR,
    OCULTAR_ESTADISTICAS,
    OCULTAR_ESTADISTICAS_SUCCESS,
    SINGLE_BUSQUEDA_SUCCESS,
    SINGLE_BUSQUEDA_ERROR,
    GRAFICOS_TEMAS,
    GRAFICOS_TEMAS_SUCCESS,
    GRAFICOS_TEMAS_ERROR,
    LIMPIAR_FORM,
    TOAST_MESSAGE,
    SELECCION_FORM_ESTADISTICA,
    SELECCION_FORM_ESTADISTICA_SUCCESS,
    CONVS_AGENTES_SUCCESS,
    TABLE_AGENTES_SUCCESS,
    CONVS_NOTAS_OCULTAS_SUCCESS,
    CONVS_TRANSFERENCIAS_SUCCESS,
    CONVS_CONECTED_USERS_SUCCESS,
    CONVS_CHATBOT_SUCCESS,
    SAVE_DATA_NOTAS_OCULTAS,
    SAVE_DATA_TRANSFERENCIAS,
    MODAL_ESPERA_DATA,
    CONVS_COLAS_ATENCION_SUCCESS,
    CONVS_ATENDIDAS_CHATBOT,
    CONVS_ATENDIDAS_CHATBOT_SUCCESS,
    CONVS_ATENDIDAS_CHATBOT_ERROR,
    GRAFICA_QUERY_SENTIMENT,
    GRAFICA_QUERY_SENTIMENT_SUCCESS,
    GRAFICA_QUERY_SENTIMENT_ERROR,
    ENCUESTA_GENERAL,
    GRAFICA_ENCUESTA_GENERAL,
    GRAFICA_ENCUESTA_GENERAL_SUCCESS,
    FILTRAR_RANGOS_FECHA,
    PAGINAR_CONVS_CERRADAS,
    PAGINAR_CONVS_CERRADAS_SUCCESS,
    PAGINAR_CONVS_CERRADAS_ERROR,
    DESCARGAR_CSV_CONVS_CERRADAS,
    DESCARGAR_CSV_CONVS_CERRADAS_SUCCESS,
    DESCARGAR_CSV_CONVS_CERRADAS_ERROR,
    REPORTE_WS_FALLIDOS_ERROR,
    REPORTE_WS_FALLIDOS,
    REPORTE_WS_FALLIDOS_SUCCESS,
    DATOS_CONVS_CHATBOT,
    DATOS_CONVS_CHATBOT_SUCCESS,
    DATOS_CONVS_CHATBOT_ERROR,
    PAGINAR_DATA_CONVS_CHABOT,
    PAGINAR_DATA_CONVS_CHABOT_SUCCESS,
    PAGINAR_DATA_CONVS_CHABOT_ERROR,
    DATOS_RESP_UNKNOWN_BOT,
    DATOS_RESP_UNKNOWN_BOT_SUCCESS,
    DATOS_RESP_UNKNOWN_BOT_ERROR,
    PAGINAR_DATA_UNKNOWNRESP_BOT,
    PAGINAR_DATA_UNKNOWNRESP_BOT_SUCCESS,
    PAGINAR_DATA_UNKNOWNRESP_BOT_ERROR,
    GRAFICA_TASA_COINCIDENCIAS,
    GRAFICA_TASA_COINCIDENCIAS_SUCCESS,
    GRAFICA_TASA_COINCIDENCIAS_ERROR,
    DATOS_COMENTARIOS_INSATISFACCION,
    DATOS_COMENTARIOS_INSATISFACCION_SUCCESS,
    DATOS_COMENTARIOS_INSATISFACCION_ERROR,
    PAGINAR_COMENTARIOS_INSATISFACCION,
    PAGINAR_COMENTARIOS_INSATISFACCION_SUCCESS,
    PAGINAR_COMENTARIOS_INSATISFACCION_ERROR,
    DATOS_INTENTS_PORCENTAJE,
    DATOS_INTENTS_PORCENTAJE_SUCCESS,
    DATOS_INTENTS_PORCENTAJE_ERROR,
    GENERAR_DATA_CSV,
    GENERAR_DATA_CSV_SUCCESS,
    DATOS_GRAFICA_TOP_10,
    DATOS_GRAFICA_TOP_10_SUCCESS,
    DATOS_GRAFICA_TOP_10_ERROR,
    PAGINAR_DATA_CONVS_PRESUPUESTOS_ERROR,
    PAGINAR_DATA_CONVS_PRESUPUESTOS_SUCCESS,
    PAGINAR_DATA_CONVS_PRESUPUESTOS,
    DATOS_CONVS_PRESUPUESTOS_SUCCESS,
    TMP_PROM_AGENTES,
    TMP_PROM_AGENTES_SUCCESS,
    CONVS_RESUELTAS_AGENTES,
    CONVS_RESUELTAS_AGENTES_SUCCESS,
    GRAFICO_PROM_RESP_AGENTES,
    GRAFICO_PROM_RESP_AGENTES_SUCCESS,
    GRAFICO_PROM_ESP_AGENTES,
    GRAFICO_PROM_ESP_AGENTES_SUCCESS,
    TABLES_PROM_AGENT,
    SEND_TABLA_DATOS_REPORTE_HUECOS,
    SEND_GRAFICO_DATOS_REPORTE_HUECOS,
    SEND_GRAFICO_DATOS_REPORTE_HUECOS_SUCCESS,
    CSV_CONVS_CHATBOT,
    CSV_DATA_UNKNOWN_BOT,
    CSV_DATA_INSATISFACCION,
    SEND_TABLA_DATOS_ZONA_PARQUEO,
    SEND_TABLA_DATOS_REGISTRO_GAB,
    SEND_TABLA_DATOS_ATENCION_CANAL,
    SEND_TABLA_DATOS_ATENCION_AGENTE,
    ENCUESTAS_AGENTES_SUCESS,
    ENCUESTAS_AGENTES,
    TABLE_AGENTES_ERROR,
    CONVS_RESUELTAS_AGENTES_ERROR,
    ENCUESTAS_AGENTES_ERROR,
    CONVS_CERRADAS_AGENTES,
    CONVS_CERRADAS_AGENTES_SUCCESS,
    CONVS_CERRADAS_AGENTE_SUCCESS,
    PAGINAR_CONVS_CERRADAS_AGENTES_ERROR,
    PAGINAR_CONVS_CERRADAS_AGENTES_SUCCESS,
    PAGINAR_CONVS_CERRADAS_AGENTES,
    DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS,
    FILTRAR_BUSQUEDA_AGENTE_SUCCESS,
    FILTRAR_BUSQUEDA_AGENTE,
    SAVE_SEARCH_BIGQUERY_TABLE,
    SAVE_SEARCH_TABLE_GENERALES


} from './constants';

export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function limpiarForm(payload) {
    return {
        type: LIMPIAR_FORM,
        payload
    }
}

export function mostrarCamposRangos() {
    return {
        type: MOSTRAR_RANGOS

    }
}
export function ocultarCamposRangos() {
    return {
        type: OCULTAR_RANGOS

    }
}

export function obtenerDatosGraficaLineChart(payload) {
    return {
        type: DATA_LINECHART,
        payload
    }

}

export function obtenerDatosGraficaLineChartSuccess(payload) {
    return {
        type: DATA_LINECHART_SUCCESS,
        payload
    }

}

export function obtenerDatosGraficaLineChartError(payload) {
    return {
        type: DATA_LINECHART_ERROR,
        payload
    }

}


export function obtenerTmpPromEspConvsCerradas(payload) {
    return {
        type: TMPESP_PROM,
        payload
    }
}

export function obtenerTmpPromEspConvsCerradasSuccess(payload) {
    return {
        type: TMPESP_PROM_SUCCESS,
        payload
    }
}

export function obtenerTmpPromEspConvsCerradasError(payload) {
    return {
        type: TMPESP_PROM_ERROR,
        payload
    }
}



export function obtenerTmpPromRespConvsCerradas(payload) {
    return {
        type: TMPRESP_PROM,
        payload
    }
}

export function obtenerTmpPromRespConvsCerradasSuccess(payload) {
    return {
        type: TMPRESP_PROM_SUCCESS,
        payload
    }
}

export function obtenerTmpPromRespConvsCerradasError(payload) {
    return {
        type: TMPRESP_PROM_ERROR,
        payload
    }
}



export function obtenerTmpPromConvSuccess(payload) {
    return {
        type: TMPCONV_PROM_SUCCESS,
        payload
    }
}


export function obtenerTmpPromConv(payload) {
    return {
        type: TMPCONV_PROM,
        payload
    }
}



export function obtenerTmpPromConvError(payload) {
    return {
        type: TMPCONV_PROM_ERROR,
        payload
    }
}


export function obtenerConvsCerradasResueltas(payload) {
    return {
        type: CONVS_RESUELTAS,
        payload
    }
}

export function obtenerConvsCerradasResueltasSuccess(payload) {
    return {
        type: CONVS_RESUELTAS_SUCCESS,
        payload
    }
}
export function obtenerConvsCerradasResueltasError(payload) {
    return {
        type: CONVS_RESUELTAS_ERROR,
        payload
    }
}

export function obtenerCanalConvsCerradas(payload) {
    return {
        type: CONVS_CANAL,
        payload
    }
}

export function obtenerCanalConvsCerradasSuccess(payload) {
    return {
        type: CONVS_CANAL_SUCCESS,
        payload
    }
}

export function obtenerCanalConvsCerradasError(payload) {
    return {
        type: CONVS_CANAL_ERROR,
        payload
    }
}

export function obtenerConvsCerradasSuccess(payload) {
    return {
        type: CONVS_CERRADAS_SUCCESS,
        payload
    }
}
export function obtenerConvsCerradasAgenteSuccess(payload) {
    return {
        type: CONVS_CERRADAS_AGENTE_SUCCESS,
        payload
    }
}

export function obtenerConvsCerradas(payload) {
    return {
        type: CONVS_CERRADAS,
        payload
    }
}

export function obtenerConvsCerradasError(payload) {
    return {
        type: CONVS_CERRADAS_ERROR,
        payload
    }
}

export function filtrarBusquedaSuccess(payload) {
    return {
        type: FILTRAR_BUSQUEDA_SUCCESS,
        payload
    }
}

export function saveSearchBigQueryTable(payload) {
    return {
        type: SAVE_SEARCH_BIGQUERY_TABLE,
        payload
    }
}

export function filtrarBusqueda(payload) {
    return {
        type: FILTRAR_BUSQUEDA,
        payload
    }
}

export function filtrarBusquedaError(payload) {
    return {
        type: FILTRAR_BUSQUEDA_ERROR,
        payload
    }
}

export function listarEstados(payload) {

    return {
        type: LISTAR_ESTADOS,
        payload
    }
}
export function listarEstadosSuccess(payload) {

    return {
        type: LISTAR_ESTADOS_SUCCESS,
        payload
    }
}
export function listarAgentes(payload) {

    return {
        type: LISTAR_AGENTES,
        payload
    }
}
export function listarAgentesSuccess(payload) {

    return {
        type: LISTAR_AGENTES_SUCCESS,
        payload
    }
}

export function desplegarEventosBuscador() {
    return {
        type: LANZAR_EVENTOS
    }
}

export function generalError(payload) {
    return {
        type: GENERAL_ERROR,
        payload
    };
}


export function selectConversationDetail(payload) {

    return {
        type: SELECT_CONVERSATIONDETAIL,
        payload
    }

}

export function selectConversationDetailSuccess(payload) {

    return {
        type: SELECT_CONVERSATIONDETAIL_SUCCESS,
        payload
    }

}

export function selectConversationDetailError(payload) {

    return {
        type: SELECT_CONVERSATIONDETAIL_ERROR,
        payload
    }

}

export function abrirModalDescargarDatos(payload) {

    return {
        type: MODAL_DESCARGAR_DATOS,
        payload
    }
}

export function cerrarModalDescargarDatos(payload) {

    return {
        type: OCULTAR_MODAL_DESCARGAR,
        payload
    }

}

export function abrirModalDescargarDatosSuccess(payload) {

    return {
        type: MODAL_DESCARGAR_DATOS_SUCCESS,
        payload
    }
}

export function abrirModalDescargarDatosError(payload) {
    return {
        type: MODAL_DESCARGAR_DATOS_ERROR,
        payload

    }
}

export function singleBusquedaSuccess(payload) {
    return {
        type: SINGLE_BUSQUEDA_SUCCESS,
        payload
    }
}

export function singleBusquedaError(payload) {
    return {
        type: SINGLE_BUSQUEDA_ERROR,
        payload
    }
}

export function obtenerDatosGraficaTemas(payload) {
    return {
        type: GRAFICOS_TEMAS,
        payload
    }

}
export function obtenerDatosGraficaTemasSuccess(payload) {
    return {
        type: GRAFICOS_TEMAS_SUCCESS,
        payload
    }
}

export function obtenerDatosGraficaTemasError(payload) {
    return {
        type: GRAFICOS_TEMAS_ERROR,
        payload
    }
}

export function showToast(payload) {

    return {
        type: TOAST_MESSAGE,
        payload
    }
}

export function obtenerDataPieConvsAbandonadas(payload) {
    return {
        type: CONVS_ABANDONADAS,
        payload
    }
}

export function obtenerDataPieConvsAbandonadasSuccess(payload) {
    return {
        type: CONVS_ABANDONADAS_SUCCESS,
        payload
    }
}
export function obtenerDataPieConvsAbandonadasError(payload) {
    return {
        type: CONVS_ABANDONADAS_ERROR,
        payload
    }
}

export function ocultarEstadisticasConvsAbandonadas(payload) {
    return {
        type: OCULTAR_ESTADISTICAS,
        payload
    }
}
export function ocultarEstadisticasConvsAbandonadasSuccess(payload) {
    return {
        type: OCULTAR_ESTADISTICAS_SUCCESS,
        payload
    }
}

export function obtenerOpcionFormEstadisticas(payload) {

    return {
        type: SELECCION_FORM_ESTADISTICA,
        payload
    }
}

export function obtenerOpcionFormEstadisticasSuccess(payload) {

    return {
        type: SELECCION_FORM_ESTADISTICA_SUCCESS,
        payload

    }
}


export function obtenerDataConvsAgentesSuccess(payload) {

    return {
        type: CONVS_AGENTES_SUCCESS,
        payload

    }
}

export function obtenerDatatTableAgentesSuccess(payload) {

    return {
        type: TABLE_AGENTES_SUCCESS,
        payload

    }
}
export function obtenerDataConvsAgentesError(payload) {

    return {
        type: TABLE_AGENTES_ERROR,
        payload

    }
}


export function obtenerDataNotasOcultasSuccess(payload) {

    return {
        type: CONVS_NOTAS_OCULTAS_SUCCESS,
        payload

    }
}

export function obtenerDataTransferenciasSuccess(payload) {

    return {
        type: CONVS_TRANSFERENCIAS_SUCCESS,
        payload

    }
}

export function obtenerDataConectedUsersSuccess(payload) {

    return {
        type: CONVS_CONECTED_USERS_SUCCESS,
        payload

    }
}


export function obtenerDataChatBotSuccess(payload) {
    return {
        type: CONVS_CHATBOT_SUCCESS,
        payload

    }
}

export function saveDataNotasOcultas(payload) {
    return {
        type: SAVE_DATA_NOTAS_OCULTAS,
        payload

    }
}

export function saveDataTransferencias(payload) {
    return {
        type: SAVE_DATA_TRANSFERENCIAS,
        payload

    }
}

export function modalEsperaData(payload) {
    return {
        type: MODAL_ESPERA_DATA,
        payload

    }
}
export function obtenerColasAtencionSuccess(payload) {
    return {
        type: CONVS_COLAS_ATENCION_SUCCESS,
        payload

    }
}

export function obtenerPieConvsAtendidasChatBot(payload) {
    return {
        type: CONVS_ATENDIDAS_CHATBOT,
        payload
    }
}
export function obtenerPieConvsAtendidasChatBotSuccess(payload) {
    return {
        type: CONVS_ATENDIDAS_CHATBOT_SUCCESS,
        payload
    }
}

export function obtenerPieConvsAtendidasChatBotError(payload) {
    return {
        type: CONVS_ATENDIDAS_CHATBOT_ERROR,
        payload
    }
}

export function obtenerDataGraficaQuerySentiment(payload) {
    return {
        type: GRAFICA_QUERY_SENTIMENT,
        payload
    }
}
export function obtenerDataGraficaQuerySentimentSuccess(payload) {
    return {
        type: GRAFICA_QUERY_SENTIMENT_SUCCESS,
        payload
    }
}
export function obtenerDataGraficaQuerySentimentError(payload) {
    return {
        type: GRAFICA_QUERY_SENTIMENT_ERROR,
        payload
    }
}

export function obtenerDatosGraficaEncuesta(payload) {
    return {
        type: GRAFICA_ENCUESTA_GENERAL,
        payload
    }
}

export function obtenerDatosGraficaEncuestaSuccess(payload) {
    return {
        type: GRAFICA_ENCUESTA_GENERAL_SUCCESS,
        payload
    }
}

export function filtrarRangoFecha(payload) {
    return {
        type: FILTRAR_RANGOS_FECHA,
        payload
    }
}

export function paginarConvsCerradas(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS,
        payload
    }
}

export function paginarConvsCerradasSuccess(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS_SUCCESS,
        payload
    }
}

export function paginarConvsCerradasError(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS_ERROR,
        payload
    }
}

export function descargarCSVConvsCerradas(payload) {
    return {
        type: DESCARGAR_CSV_CONVS_CERRADAS,
        payload
    }

}

export function descargarCSVconvsCerradasSuccess(payload) {
    return {
        type: DESCARGAR_CSV_CONVS_CERRADAS_SUCCESS,
        payload
    }

}


export function descargarCSVconvsCerradasError(payload) {
    return {
        type: DESCARGAR_CSV_CONVS_CERRADAS_ERROR,
        payload
    }
}
export function obtenerDataWsFallidosSuccess(payload) {
    return {
        type: REPORTE_WS_FALLIDOS_SUCCESS,
        payload
    }
}

export function obtenerDataWsFallidosError(payload) {
    return {
        type: REPORTE_WS_FALLIDOS_ERROR,
        payload
    }
}
export function obtenerDataWsFallidos(payload) {
    return {
        type: REPORTE_WS_FALLIDOS,
        payload
    }
}
export function obtenerDatosConvsChatbot(payload) {
    return {
        type: DATOS_CONVS_CHATBOT,
        payload
    }
}
export function obtenerDatosConvsChatbotSuccess(payload) {
    return {
        type: DATOS_CONVS_CHATBOT_SUCCESS,
        payload
    }
}

export function obtenerDatosConvsPresupuestosSuccess(payload) {
    return {
        type: DATOS_CONVS_PRESUPUESTOS_SUCCESS,
        payload
    }
}

export function obtenerDatosConvsChatbotError(payload) {
    return {
        type: DATOS_CONVS_CHATBOT_ERROR,
        payload
    }
}
export function paginarDatosConvsChatbot(payload) {
    return {
        type: PAGINAR_DATA_CONVS_CHABOT,
        payload

    }

}

export function paginarDatosConvsChatbotSuccess(payload) {
    return {
        type: PAGINAR_DATA_CONVS_CHABOT_SUCCESS,
        payload

    }

}
export function paginarDatosConvsChatbotError(payload) {
    return {
        type: PAGINAR_DATA_CONVS_CHABOT_ERROR,
        payload

    }
}

export function paginarDatosConvsPresupuestos(payload) {
    return {
        type: PAGINAR_DATA_CONVS_PRESUPUESTOS,
        payload

    }

}

export function paginarDatosConvsPresupuestosSuccess(payload) {
    return {
        type: PAGINAR_DATA_CONVS_PRESUPUESTOS_SUCCESS,
        payload

    }

}
export function paginarDatosConvsPresupuestosError(payload) {
    return {
        type: PAGINAR_DATA_CONVS_PRESUPUESTOS_ERROR,
        payload

    }
}

export function obtenerDatosRespUnknownBot(payload) {
    return {
        type: DATOS_RESP_UNKNOWN_BOT,
        payload
    }
}

export function obtenerDatosRespUnknownBotSuccess(payload) {
    return {
        type: DATOS_RESP_UNKNOWN_BOT_SUCCESS,
        payload
    }
}
export function obtenerDatosRespUnknownBotError(payload) {
    return {
        type: DATOS_RESP_UNKNOWN_BOT_ERROR,
        payload
    }
}

export function paginarDatosRespUnknownBot(payload) {
    return {
        type: PAGINAR_DATA_UNKNOWNRESP_BOT,
        payload

    }
}

export function paginarDatosRespUnknownBotSuccess(payload) {
    return {
        type: PAGINAR_DATA_UNKNOWNRESP_BOT_SUCCESS,
        payload

    }
}

export function paginarDatosRespUnknownBotError(payload) {
    return {
        type: PAGINAR_DATA_UNKNOWNRESP_BOT_ERROR,
        payload

    }
}

export function obtenerDatosIntentsPorcentajeUso(payload) {
    return {
        type: DATOS_INTENTS_PORCENTAJE,
        payload
    }
}
export function obtenerDatosIntentsPorcentajeUsoSuccess(payload) {
    return {
        type: DATOS_INTENTS_PORCENTAJE_SUCCESS,
        payload
    }
}
export function obtenerDatosIntentsPorcentajeUsoError(payload) {
    return {
        type: DATOS_INTENTS_PORCENTAJE_ERROR,
        payload
    }
}

export function obtenerDataTasaCoincidenciaBot(payload) {
    return {
        type: GRAFICA_TASA_COINCIDENCIAS,
        payload
    }

}

export function obtenerDataTasaCoincidenciaBotSuccess(payload) {
    return {
        type: GRAFICA_TASA_COINCIDENCIAS_SUCCESS,
        payload
    }

}

export function obtenerDataTasaCoincidenciaBotError(payload) {
    return {
        type: GRAFICA_TASA_COINCIDENCIAS_ERROR,
        payload
    }

}

export function obtenerDataComentariosInsatisfaccion(payload) {
    return {
        type: DATOS_COMENTARIOS_INSATISFACCION,
        payload
    }
}

export function obtenerDataComentariosInsatisfaccionSuccess(payload) {
    return {
        type: DATOS_COMENTARIOS_INSATISFACCION_SUCCESS,
        payload
    }
}

export function obtenerDataComentariosInsatisfaccionError(payload) {
    return {
        type: DATOS_COMENTARIOS_INSATISFACCION_ERROR,
        payload
    }
}

export function paginarDataComentariosInsatisfaccion(payload) {
    return {
        type: PAGINAR_COMENTARIOS_INSATISFACCION,
        payload
    }
}

export function paginarDataComentariosInsatisfaccionSuccess(payload) {
    return {
        type: PAGINAR_COMENTARIOS_INSATISFACCION_SUCCESS,
        payload
    }
}

export function paginarDataComentariosInsatisfaccionError(payload) {
    return {
        type: PAGINAR_COMENTARIOS_INSATISFACCION_ERROR,
        payload
    }
}

export function generarCsv(payload) {
    return {
        type: GENERAR_DATA_CSV,
        payload
    }

}


export function generarCsvSuccess(payload) {
    return {
        type: GENERAR_DATA_CSV_SUCCESS,
        payload
    }

}

export function obtenerDatosGraficaTop10(payload) {
    return {
        type: DATOS_GRAFICA_TOP_10,
        payload
    }
}

export function obtenerDatosGraficaTop10Success(payload) {
    return {
        type: DATOS_GRAFICA_TOP_10_SUCCESS,
        payload
    }
}

export function obtenerDatosGraficaTop10Error(payload) {
    return {
        type: DATOS_GRAFICA_TOP_10_ERROR,
        payload
    }
}

export function obtenerTmpPromAgentes(payload) {
    return {
        type: TMP_PROM_AGENTES,
        payload
    }
}

export function obtenerTmpPromAgentesSuccess(payload) {
    return {
        type: TMP_PROM_AGENTES_SUCCESS,
        payload
    }
}

export function obtenerConvsResueltasAgentes(payload) {
    return {
        type: CONVS_RESUELTAS_AGENTES,
        payload
    }

}
export function obtenerConvsResueltasAgentesSuccess(payload) {
    return {
        type: CONVS_RESUELTAS_AGENTES_SUCCESS,
        payload
    }
}
export function obtenerConvsResueltasAgentesError(payload) {
    return {
        type: CONVS_RESUELTAS_AGENTES_ERROR,
        payload
    }
}

export function graficarTmpPromRespAgentes(payload) {
    return {
        type: GRAFICO_PROM_RESP_AGENTES,
        payload
    }
}
export function graficarTmpPromRespAgentesSuccess(payload) {
    return {
        type: GRAFICO_PROM_RESP_AGENTES_SUCCESS,
        payload
    }
}

export function graficarTmpPromEspAgentes(payload) {
    return {
        type: GRAFICO_PROM_ESP_AGENTES,
        payload
    }
}
export function graficarTmpPromEspAgentesSuccess(payload) {
    return {
        type: GRAFICO_PROM_ESP_AGENTES_SUCCESS,
        payload
    }
}

export function tablesPromAgent(payload) {
    return {
        type: TABLES_PROM_AGENT,
        payload
    }
}
export function sendTablaDatosReporteHuecos(payload) {
    return {
        type: SEND_TABLA_DATOS_REPORTE_HUECOS,
        payload
    }
}
export function sendGraficoDatosReporteHuecos(payload) {
    return {
        type: SEND_GRAFICO_DATOS_REPORTE_HUECOS,
        payload
    }
}
export function sendGraficoDatosReporteHuecosSuccess(payload) {
    return {
        type: SEND_GRAFICO_DATOS_REPORTE_HUECOS_SUCCESS,
        payload
    }
}
export function generarCsvConvsChatbotSuccess(payload){
    return {
        type: CSV_CONVS_CHATBOT,
        payload
    }
}

export function generarCsvDataUnknownBotSuccess(payload){
    return {
        type: CSV_DATA_UNKNOWN_BOT,
        payload
    }
}

export function generarCsvDataInsatisfaccionSuccess(payload){
    return {
        type: CSV_DATA_INSATISFACCION,
        payload
    }
}

export function sendTablaDatosZonaParqueo(payload) {
    return {
        type: SEND_TABLA_DATOS_ZONA_PARQUEO,
        payload
    }
}
export function sendTablaDatosRegistroGAB(payload) {
    return {
        type: SEND_TABLA_DATOS_REGISTRO_GAB,
        payload
    }
}
export function sendTablaDatosAtencionCanal(payload) {
    return {
        type: SEND_TABLA_DATOS_ATENCION_CANAL,
        payload
    }
}
export function sendTablaDatosAtencionAgente(payload) {
    return {
        type: SEND_TABLA_DATOS_ATENCION_AGENTE,
        payload
    }
}

export function encuestasAgentes(payload) {
    return {
        type: ENCUESTAS_AGENTES,
        payload
    }
}

export function encuestasAgentesSucess(payload) {
    return {
        type: ENCUESTAS_AGENTES_SUCESS,
        payload
    }
}
export function encuestasAgentesError() {
    return {
        type: ENCUESTAS_AGENTES_ERROR
    }
}

export function paginarConvsCerradasAgentes(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS_AGENTES,
        payload
    }
}
export function paginarConvsCerradasAgentesSuccess(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS_AGENTES_SUCCESS,
        payload
    }
}

export function paginarConvsCerradasAgentesError(payload) {
    return {
        type: PAGINAR_CONVS_CERRADAS_AGENTES_ERROR,
        payload
    }
}
export function descargarCSVconvsCerradasAgentesSuccess(payload) {
    return {
        type: DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS,
        payload
    }

}

export function filtrarBusquedaAgente(payload) {
    return {
        type: FILTRAR_BUSQUEDA_AGENTE,
        payload
    }
}

export function filtrarBusquedaAgenteSuccess(payload) {
    return {
        type: FILTRAR_BUSQUEDA_AGENTE_SUCCESS,
        payload
    }
}

export function saveSearchTableGenerales(payload) {
    return {
        type: SAVE_SEARCH_TABLE_GENERALES,
        payload
    }
}

export function manageSpiner(payload){
    return{
        type:MANAGE_SPINER,
        payload

    }
}
/* export function paginar */


