import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { getConsultaPaginada, getDataGeneradapCsv, getGraficoReporteHuecos, getDataReporteHuecos, getDataTableChatbot, getDataTableWsFallidos, getDatosTablaConvsPresupuesto, getDatosTablaConvsPresupuestoPaginados, getSelectedForm, getStateFilterButton, getDataZonaParqueo, getDataRegistroGAB } from "./selectors";
import { Card } from 'primereact/card';
import LineChart from "../../components/Charts/lineChart";

import BarChart from "../../components/Charts/barChart";
import PieChart from '../../components/Charts/pieChart';
import { Chart } from "primereact/chart";
import DataTableWithPaginator from "../../components/tableWithPaginator/tablePaginator";
import {
    CONVS_CHATBOT,
    GENERAR_DATA_CSV,
    PAGINAR_DATA_CONVS_PRESUPUESTOS,
    REPORTE_WS_FALLIDOS,
    TOAST_MESSAGE,
    CONVERSACIONES_SERVICIOS_EXTERNOS,
    HEADER_TABLE_REPORTE_HUECOS,
    GET_GRAFICO_DATOS_REPORTE_HUECOS,
    HEADER_TABLE_ZONA_PARQUEO,
    GET_TABLA_DATOS_ZONA_PARQUEO,
    GET_TABLA_DATOS_REGISTRO_GAB,
    HEADER_TABLE_REGISTRO_GAB,
} from "./constants";
import { getFromSession } from "../../controller/session";
import { convsCerradas, graficoReporteHuecos } from "./selectors";




class EstServiciosExternos extends Component {

    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }
        this.company = getFromSession("company_id");
    }


    componentWillMount() {
        const {
            obtenerGraficosServiciosExternos,
            obtenerGraficoeporteHuecos,
            obtenerTablaZonaParqueo,
            obtenerTablaRegistroGAB,
            //activarSpinner
        } = this.props
        obtenerGraficosServiciosExternos()
        obtenerGraficoeporteHuecos()
        obtenerTablaZonaParqueo()
        obtenerTablaRegistroGAB()
        //activarSpinner()


    }

    componentDidUpdate(prevProps) {
        const {
            estadoBotonFiltrarRangos,
            showToast,
            obtenerGraficosServiciosExternos,
            obtenerDatosGraficaLineChart,
            convsCerradas,
            obtenerGraficoeporteHuecos,
            obtenerTablaZonaParqueo,
            obtenerTablaRegistroGAB,
        } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;


        if (seleccionRango === anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerGraficosServiciosExternos(seleccionRango)
            obtenerGraficoeporteHuecos(seleccionRango)
            obtenerTablaZonaParqueo(seleccionRango)
            obtenerTablaRegistroGAB(seleccionRango)
            
            //activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }
        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            this.setState({
                seleccionRango: seleccionRango
            })
            obtenerGraficosServiciosExternos(seleccionRango)
            obtenerGraficoeporteHuecos(seleccionRango)
            obtenerTablaZonaParqueo(seleccionRango)
            obtenerTablaRegistroGAB(seleccionRango)

            // activarSpinner()

        }
        if (seleccionRango === 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)
            

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                this.setState({
                    seleccionRango: rango
                })
                console.log(seleccionRango,rango)
                obtenerGraficosServiciosExternos(rango)
                obtenerGraficoeporteHuecos(rango)
                obtenerTablaZonaParqueo(rango)
                obtenerTablaRegistroGAB(rango)

                // activarSpinner()
            } else {
                showToast()
            }
        }

    }




    render() {
        const {
            dataReporteHuecos,
            convsCerradas,
            graficoReporteHuecos,
            dataZonaParqueo,
            dataRegistroGAB,
        } = this.props;
        let tooltipLineChart = 'Gráfico que muestra el número de conversaciones cerradas por hora o por día';
        const header = (j) => {

            const {
                obtenerGraficoBarrasCausas
            } = this.props;

            let fecha_ini = new Date('2022-08-24T00:00:00')

            return (
                <div>
                    <Button onClick={() => obtenerGraficoBarrasCausas({ selectedForm: 'HOY', causas: 'causas' })}
                        style={{ marginRight: "10px", marginTop: "10px" }}
                        icon="pi pi-refresh" label="Actualizar hoy"
                        className="p-button-rounded p-button-info" />
                
                </div>

            )
        }



        return (
            <React.Fragment>
                <div>
                    <h4 className="tituloComponente" >OTROS REPORTES</h4>
                </div>
                <div>
                    <h4 className="tituloComponente" >Reportes de huecos</h4>
                </div>
                <div className="containerGeneral">

                    {dataReporteHuecos ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_REPORTE_HUECOS}
                                datosTable={dataReporteHuecos}
                                titleTable={"Información reporte de huecos"}
                                exporExcel={false}
                                nameTable={"reporteHuecos"}
                            />

                        </div>



                    ) : false}
                    {/* LINE CHART REPORTE HUECOS */}
                    {/* {graficoReporteHuecos && graficoReporteHuecos.payload ? 
                    <div className="p-grid">
                        <div className="p-col-9 margin-left">
                            <Card header={header(tooltipLineChart, 'j')} >
                                <LineChart
                                    data={graficoReporteHuecos.payload}></LineChart>
                            </Card>
                        </div>
                    </div> : "" } */}
                </div>
                    {/*  TABLA REPORTES ZONA DE PARQUEO  */}
                <div>
                    <h4 className="tituloComponente" >Reportes zonas de parqueo</h4>
                </div>
                <div className="containerGeneral">

                    {dataZonaParqueo ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_ZONA_PARQUEO}
                                datosTable={dataZonaParqueo}
                                titleTable={"Información Zona de Parqueo"}
                                exporExcel={false}
                                nameTable={"zonaParqueo"}
                            />

                        </div>



                    ) : false}

                </div>
                    {/*  TABLA REGISTRO GAB  */}

                <div>
                    <h4 className="tituloComponente" >Reporte Registro GAB</h4>
                </div>
                <div className="containerGeneral">

                    {dataRegistroGAB ? (
                        <div className="p-mt-3">
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_REGISTRO_GAB}
                                datosTable={dataRegistroGAB}
                                titleTable={"Información Registro GAB"}
                                exporExcel={false}
                                nameTable={"registroGab"}
                            />

                        </div>



                    ) : false}

                </div>


            </React.Fragment>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableChatbot: getDataTableChatbot(state),
        selectedForm: getSelectedForm(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        dataTableWsFallidos: getDataTableWsFallidos(state),
        dataTablaConvsPresupuesto: getDatosTablaConvsPresupuesto(state),
        datosTablaConvsPresupuestoPaginados: getDatosTablaConvsPresupuestoPaginados(state),
        consultaPaginada: getConsultaPaginada(state),
        dataGeneradaCsv: getDataGeneradapCsv(state),
        dataReporteHuecos: getDataReporteHuecos(state),
        convsCerradas: convsCerradas(state),
        graficoReporteHuecos: getGraficoReporteHuecos(state),
        dataZonaParqueo: getDataZonaParqueo(state),
        dataRegistroGAB: getDataRegistroGAB(state),
    }
}


const mapDispachToProps = dispatch => {
    return {
        paginarDatosConvsPresupuesto: (value) => dispatch({ type: PAGINAR_DATA_CONVS_PRESUPUESTOS, value }),
        obtenerGraficoBarrasCausas: (value) => dispatch({ type: CONVS_CHATBOT, value }),
        obtenerGraficosServiciosExternos: (value) => dispatch({ type: CONVERSACIONES_SERVICIOS_EXTERNOS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        obtenerDataWsFallidos: (value) => dispatch({ type: REPORTE_WS_FALLIDOS, value }),
        //activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value }),
        generarDataCSV: (value) => dispatch({ type: GENERAR_DATA_CSV, value }),
        obtenerGraficoeporteHuecos: (value) => dispatch({type: GET_GRAFICO_DATOS_REPORTE_HUECOS, value}),
        obtenerTablaZonaParqueo: (value) => dispatch({type: GET_TABLA_DATOS_ZONA_PARQUEO, value}),
        obtenerTablaRegistroGAB: (value) => dispatch({type: GET_TABLA_DATOS_REGISTRO_GAB, value}),
        

        


    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstServiciosExternos);