import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getMenuItemsOCR = (state) => state.menuItemsOCR;
export const getDataCompanySelector = (state) => state.dataCompany;
export const selectordocumentData = (state) => state.documentData;
export const selectorProccessDocument = (state) => state.proccessDocument;
export const selectorLoadingCircular = (state) => state.loadingCircular;
export const selectorFailDocument = (state) => state.failDocument;
export const selectorShowDocument = (state) => state.showDoc;
export const selectorCrossDocument = (state) => state.crossDoc;


export const usersSelector = createStructuredSelector({
    users: getUsers,
});