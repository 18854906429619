import React, { Component, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from '../constants';
import { getMenuItemsChatBot, getUsers } from "../selectors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Button } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';




const ComponentTableDoc = (props) => {
    console.log("PROPS TABLE ", props.data)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [first, setFirst] = useState(0); // Estado para controlar el primer registro mostrado
    const [rows, setRows] = useState(10); // Estado para controlar el número de filas por página

    const handlePreview = (rowData) => {
        let datosDocumento = {}
        setRowClick(rowData);
        const pdfUrl = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${props.id}/productos/ocr/${rowData.name}`
        //console.log("pdfUrl", pdfUrl)
        props.pdfData(pdfUrl)
        datosDocumento = {
            lote: rowData.lote,
            name: rowData.name
        }
        props.idDocumento(datosDocumento)
    };

    const handleDownload = (rowData) => {
        // console.log("PROPSS", props)
        setRowClick(rowData);
        const pdfUrl = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${props.id}/productos/ocr/${rowData.name}`
        //console.log("pdfUrl descarga", pdfUrl)
        props.downloadDoc(pdfUrl)

    };

    const handleCross = (rowData) => {
        setRowClick(rowData);
        //console.log("handleCros", rowData);
        props.cruceData(rowData)
        props.crossDoc(rowData)
    };

    const renderStatus = (rowData) => {
        if (props.labelTable.columna4 === 'Acciones') {
            return (
                <>
                    <Tooltip title="Ver Documento" arrow>
                        <IconButton onClick={() => handlePreview(rowData)}>
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Descargar Documento" arrow>

                        <IconButton onClick={() => handleDownload(rowData)}>
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        } else if (props.labelTable.columna4 === 'Motivo') {
            return <span className="status-rejected">Error procesando el documento</span>;
        }
    }
    const ResumenBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                {rowData.resumen.slice(0, 100)}...
            </React.Fragment>
        );
    };

    const NameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.nombre}

                {/* Renderizar el CircularProgress junto al nombre si isLoading es true */}
                <>
                    {rowData.name.length <= 20 ?
                        <>
                            {rowData.name}
                        </>
                        :
                        <>
                            {rowData.name.match(/.{1,20}/g).join('\n')}
                        </>
                    }
                </>

            </React.Fragment>
        );
    };

    const actionButtons = (rowData) => {
        setRowClick(rowData);
        console.log(rowClick);
        return (
            <div>
                <IconButton>
                    <RemoveRedEyeIcon />
                </IconButton>
                <IconButton >
                    <CloudDownloadIcon />
                </IconButton>
                <IconButton >
                    <ShuffleIcon />
                </IconButton>
            </div>
        )
    };

    return (
        <div className="tabla-crear-pregunta">
            {/* {this.state.modalEliminar && <PDFViewer
                    handlerAceptar={()=> this.onDelete(this.state.valRow)}
                    value={this.state.valRow} 
                    handlerCancelar={this.onModalElimarClose}
                    />
                } */}
            {/* {!props.documentDataSelector &&

                <div className="loading-wrapper">
                    <h1>
                        Cargando...
                    </h1>
                </div>

            } */}
            <h2 style={{ "padding": "20px" }}>{props.title}</h2>

            {props.selectorDatosDocPrincipal ?
                <div style={{ "font-size": "15px", "display": "flex", "flex-direction": "column", "align-items": "baseline" }}>
                    <p style={{ "font-size": "18px" }}>Documento Principal</p>
                    {
                        Object.entries(props.selectorDatosDocPrincipal).map(([objKey, objValue]) => (
                            <>
                                <b>
                                    <span>{objKey}:</span>
                                </b>
                                <span>
                                    {objValue}
                                </span>
                            </>
                        ))
                    }
                </div>
                : ''}




            <DataTable value={props.data} selectionMode={rowClick ? null : 'checkbox'} first={first}
                rows={rows} paginator rowsPerPageOptions={[5, 10, 20]}
                selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column field={'fecha'} header={props.labelTable.columna1} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                <Column field={'name'} header={props.labelTable.columna2} body={NameBodyTemplate} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                <Column field={'resumen'} header={props.labelTable.columna3} body={ResumenBodyTemplate} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                {/* <Column field={'id'} header={'Id'} sortable style={{ 'width': 'auto' }}></Column> */}
                <Column header={props.labelTable.columna4}

                    body={renderStatus} sortable style={{ width: 'auto' }}></Column>
            </DataTable>
            {/* <Button onClick={() => props.procesarDocs(selectedProducts)} color='primary' variant="contained">Procesar documentos</Button> */}

        </div>


    );

}


export default ComponentTableDoc;