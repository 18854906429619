import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";

class ComponentWidget extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        //console.log("componentWillMount WIDGET")

        const containerChatbot = document.createElement("div");
        containerChatbot.setAttribute("id", "chatbot-avanti")

        containerChatbot.setAttribute("class", "ver-pluggin")
        //console.log("COMPANYYY", this.props.company)
        containerChatbot.setAttribute("data-company", this.props.company)

        //console.log("containerChatbot", containerChatbot);

        // document.getElementById("chatbot-avanti").classList.add("ver-pluggin");
        // document.getElementById("chatbot-avanti").setAttribute("data-company", "1287");
        //this.setState({ botWidget: true })

        const script = document.createElement("script");
        const scriptMain = document.createElement("script");
        const scriptMain2 = document.createElement("script");

        script.src = `${process.env.REACT_APP_SCRIPT}`;
        script.async = true;

        scriptMain.src = `${process.env.REACT_APP_SCRIPT_MAIN}`;
        scriptMain.async = true;

        scriptMain2.src = `${process.env.REACT_APP_SCRIPT_MAIN_2}`;
        scriptMain2.async = true;
        
        setTimeout(()=>{
            document.getElementById("container-chatbot").innerHTML = "ComponentWidget.componentWillMount.inner"
            document.getElementById("container-chatbot").appendChild(containerChatbot);
            document.getElementById("scripts-chatbot").appendChild(script);
            document.getElementById("scripts-chatbot").appendChild(scriptMain);
            document.getElementById("scripts-chatbot").appendChild(scriptMain2);

        }, 1500)
    }


    componentWillUnmount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
    }



    render() {
        const {} = this.props;

        return ( <></> );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentWidget);