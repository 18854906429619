const validate = values => {
    const errors = {};

    if (values.diasEspeciales && values.diasEspeciales.length) {
        const diasArrayErrors = [];
        values.diasEspeciales.forEach((dia, diaIndex) => {
            const diasErrors = {};
            if (!dia || !dia.ini) {
                diasErrors.ini = 'Obligatorio';
                diasArrayErrors[diaIndex] = diasErrors;
            }
            if (!dia || !dia.fin) {
                diasErrors.fin = 'Obligatorio';
                diasArrayErrors[diaIndex] = diasErrors;
            }
            if (!dia || !dia.nombreDia) {
                diasErrors.nombreDia = 'Obligatorio';
                diasArrayErrors[diaIndex] = diasErrors;
            }
            if (dia && dia.ini && dia.fin) {
                let dateIni = new Date('January 01, 2011 ' + dia.ini);
                let dateFin = new Date('January 01, 2011 ' + dia.fin);
                if (dateIni.getTime() > dateFin.getTime()) {
                    diasErrors.ini = 'Hora inválida';
                    diasArrayErrors[diaIndex] = diasErrors;
                }
            }
        });
        if (diasArrayErrors.length) {
            errors.diasEspeciales = diasArrayErrors;
          }
    }

    if (values.fechasEspeciales && values.fechasEspeciales.length) {
        const fechasArrayErrors = [];
        values.fechasEspeciales.forEach((fecha, fechaIndex) => {
          const fechasErrors = {};
          if (!fecha || !fecha.ini) {
              fechasErrors.ini = 'Obligatorio';
            fechasArrayErrors[fechaIndex] = fechasErrors;
          }
          if (!fecha || !fecha.fin) {
              fechasErrors.fin = 'Obligatorio';
            fechasArrayErrors[fechaIndex] = fechasErrors;
          }
          if (!fecha || !fecha.fecha) {
              fechasErrors.fecha = 'Obligatorio';
            fechasArrayErrors[fechaIndex] = fechasErrors;
          }
          if(fecha && fecha.ini && fecha.fin){
              let dateIni = new Date('January 01, 2011 ' + fecha.ini);
              let dateFin = new Date('January 01, 2011 ' + fecha.fin);
  
              if(dateIni.getTime() > dateFin.getTime()){
                  fechasErrors.ini = 'Hora inválida';
                  fechasArrayErrors[fechaIndex] = fechasErrors;
              }
          }
        });
        if (fechasArrayErrors.length) {
            errors.fechasEspeciales = fechasArrayErrors;
          }
    }
   
    return errors;
};

export default validate;
