import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import { Button, TextField } from "@material-ui/core";
import ComponentImagen from "./componentImagen";
import ComponentBotton from "./componentButton";
import ComponentText from "./componentText";
import ComponentLista from "./componentList";
import { getuploadImage } from "../selectors";
import { UPLOAD_IMAGE } from "../constants";

class ComponentRespuesta extends Component {
    constructor(props) {
        super(props);

    }
    onComponentHandler = (payload) => {
        const { onChange } = this.props;
        //console.log("COMPONENTEN RESPUESTAAA", payload)
        onChange(payload);
    }

    componentWillMount() {
        
    }

    renderComponent(component) {
        const { onChange, index, imageData, nombreBot, respuestaText, respuestaButton, respuestaList, respuestaImg } = this.props
        let renderedComponent = "";
        switch (component) {

            case "imagen":
                renderedComponent =
                    <ComponentImagen
                        respuestaImg={respuestaImg}
                        nombreBot={nombreBot}
                        imageData={imageData}
                        chooseLabel="Seleccione un archivo"
                        uploadLabel="Subir"
                        cancelLabel="Cancelar"
                        index={index} 
                        onChange={this.onComponentHandler}/>
                break;

            case "button":
                renderedComponent =
                    <ComponentBotton
                        title="Escriba el titulo de la pregunta"
                        subtitle="Escriba el subtitulo de la pregunta"
                        onChange={this.onComponentHandler}
                        index={index}
                        respuestaButton={respuestaButton} />
                break;

            case "text":
                renderedComponent =
                    <ComponentText
                        respuestaText={respuestaText}
                        onChange={this.onComponentHandler}
                        index={index} 
                        />
                break;

            case "list":
                renderedComponent =
                    <ComponentLista
                        label=""
                        placeholder="Escriba el elemento de la lista"
                        onChange={this.onComponentHandler}
                        index={index}
                        respuestaList = {respuestaList} />
                break;

            default:
                break;
        }
        return renderedComponent;
    }

    render() {
        const {
            component
        } = this.props;

        return (
            <div className="respuesta-container">
                {this.renderComponent(component)}

            </div>

        );
    }
}

export default ComponentRespuesta;