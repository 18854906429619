import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session'


export async function lstTemas() {
    try {
        const company = getFromSession("company_id"); //`company/${company}/conversations`

        let temasNiv = await lstTemasNiveles(company);
        return temasNiv;

    } catch (error) {
        console.error('controller.temas.lstTemas', error);
        throw error;
    }


}

async function lstTemasNiveles(company) {
    let temas = [];

    await firebaseDatabase.collection(`company/${company}/temas`).orderBy('nombre', 'asc').get().then(async niveles1 => {
        for await (let doc of niveles1.docs) {
            //ahora verifico si hay nivel2:
            await firebaseDatabase.collection(`company/${company}/temas/${doc.id}/NIVEL2`).orderBy('nombre', 'asc').get().then(async niveles2 => {
                if (niveles2.empty) {
                    temas.push({ nivel1: doc.data().nombre, idNivel1: doc.ref.id });
                } else {
                    /**SI HAY NIVEL 2: verifico si hay nivel3 */
                    for await (let n2 of niveles2.docs) {

                        await firebaseDatabase.collection(`company/${company}/temas/${doc.id}/NIVEL2/${n2.id}/NIVEL3`).orderBy('nombre', 'asc').get().then(async niveles3 => {
                            if (niveles3.empty) {
                                /** SOLO HAY NIVEL 2, se agrega en formato: nivel1 - nivel2  */
                                temas.push({ nivel1: doc.data().nombre, idNivel1: doc.ref.id, nivel2: n2.data().nombre, idNivel2: n2.ref.id });
                            } else {
                                for await (let n3 of niveles3.docs) {
                                    temas.push({ nivel1: doc.data().nombre, idNivel1: doc.ref.id, nivel2: n2.data().nombre, idNivel2: n2.ref.id, nivel3: n3.data().nombre, idNivel3: n3.ref.id });
                                }
                            }
                        });
                    };
                }
            });
        };
    });
    return temas;
}

export function* addTemaNivel1(nombreNivel1) {
    try {
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas`);
        let resp = false;

        const docRef = ref.doc();
        let body = {
            nombre: nombreNivel1,
            idDoc: docRef.id
        }
        yield docRef.set(body).then(() => {
            console.log("Tema añadido satisfactoriamente");
            resp = true;

        }).catch((error) => {
            resp = false;
            console.log("Tema no añadido" + error);

        });
        return resp;
    } catch (error) {
        console.error('controller.temas.addTemaNivel1', error);
        throw error;
    }
}

export function* addTemaNivel2(idNivel1, nombreNivel2) {
    try {
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2`);
        let resp = false
        const docRef = ref.doc();
        let body = {
            nombre: nombreNivel2,
            idDoc: docRef.id
        }
        yield docRef.set(body).then(() => {
            console.log("Tema añadido satisfactoriamente");
            resp = true;

        }).catch((error) => {
            resp = false;
            console.log("Tema no añadido" + error);

        });

        return resp

    } catch (error) {
        console.error('controller.temas.addTemaNivel2', error);
        throw error;
    }

}

export function* addTemaNivel3(idNivel1, idNivel2, nombreNivel3) {
    try {
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2/${idNivel2}/NIVEL3`);
        let resp = false
        const docRef = ref.doc();
        let body = {
            nombre: nombreNivel3,
            idDoc: docRef.id
        }
        yield docRef.set(body).then(() => {
            console.log("Tema añadido satisfactoriamente");
            resp = true;

        }).catch((error) => {
            resp = false;
            console.log("Tema no añadido" + error);

        });
        return resp

    } catch (error) {
        console.error('controller.temas.addTemaNivel3', error);
        throw error;
    }

}


export function* deleteTemaNivel1(idNivel1) {
    try {
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas`);

        ref.doc(idNivel1).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });

    } catch (error) {
        console.error('controller.temas.addTemaNivel1', error);
        throw error;
    }
}

export function* deleteTemaNivel2(idNivel1, idNivel2) {
    try {
        console.log('idNivel1, idNivel 2', idNivel1, idNivel2)
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2`);

        ref.doc(idNivel2).delete().then(() => {
            console.log("Document successfully deleted Nivel 2!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });

    } catch (error) {
        console.error('controller.temas.addTemaNivel2', error);
        throw error;
    }

}

export function* deleteTemaNivel3(idNivel1, idNivel2, idNivel3) {
    try {
        const company = getFromSession("company_id");
        const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2/${idNivel2}/NIVEL3`);

        ref.doc(idNivel3).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    } catch (error) {
        console.error('controller.temas.addTemaNivel3', error);
        throw error;
    }

}



export function* editTema(idsTemas, data) {
    try {
        const company = getFromSession("company_id");
        let nombreNivel1;
        console.log('dataEditTemas', data);

        yield firebaseDatabase.collection(`company/${company}/temas`).where('idDoc', '==', idsTemas.idNivel1).get().then(docs => {
            docs.forEach(doc => {
                nombreNivel1 = doc.data().nombre;
            });
        })

        yield firebaseDatabase.collection(`company/${company}/temas`).doc(idsTemas.idNivel1).update({
            nombre: data.editNivel1 ? data.editNivel1 : nombreNivel1
        })

        let idTemaNivel2 = yield modificarNivel2(idsTemas.idNivel2, idsTemas.idNivel1, data.editNivel2);

        if (idTemaNivel2) {
            idsTemas.idNivel2 = idTemaNivel2;
        }
        yield modificarNivel3(idsTemas.idNivel3, idsTemas.idNivel2, idsTemas.idNivel1, data.editNivel3);

    } catch (error) {
        console.error('controller.temas.editTema', error);
        throw error;
    }

}

async function modificarNivel2(idNivel2, idNivel1, dataNivel2) {
    const company = getFromSession("company_id");
    let nombreNivel2;
    let idDoc = false;
    const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2`);
    try {
        if (idNivel2) {
            if (dataNivel2 === "") {
                await ref.doc(idNivel2).delete().then(() => {
                    console.log("Document successfully deleted!");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });

            } else {
                await ref.where('idDoc', '==', idNivel2).get().then(docs => {
                    docs.forEach(doc => {
                        nombreNivel2 = doc.data().nombre;
                    });
                    ref.doc(idNivel2).update({
                        nombre: dataNivel2 ? dataNivel2 : nombreNivel2
                    })
                })
            }

        } else if (!idNivel2 && dataNivel2 !== "") {
            const docRef = ref.doc();
            let body = {
                nombre: dataNivel2,
                idDoc: docRef.id
            }
            idDoc = body.idDoc
            await docRef.set(body);
        }

        return idDoc;

    } catch (error) {
        console.error('controller.temas.modificarNivel2', error);
        throw error;
    }
}
async function modificarNivel3(idNivel3, idNivel2, idNivel1, dataNivel3) {
    let nombreNivel3;
    const company = getFromSession("company_id");
    const ref = firebaseDatabase.collection(`company/${company}/temas/${idNivel1}/NIVEL2/${idNivel2}/NIVEL3`);
    try {
        if (idNivel3) {
            if (dataNivel3 === "") {
                ref.doc(idNivel3).delete().then(() => {
                    console.log("Document successfully deleted!");
                }).catch((error) => {
                    console.error("Error removing document: ", error);
                });
            } else {
                await ref.where('idDoc', '==', idNivel3).get().then(docs => {
                    docs.forEach(doc => {
                        nombreNivel3 = doc.data().nombre;
                    });
                    ref.doc(idNivel3).update({
                        nombre: dataNivel3 ? dataNivel3 : nombreNivel3
                    })
                })
            }
        } else if (!idNivel3 && dataNivel3 && dataNivel3 !== "") {
            const docRef = ref.doc();
            let body = {
                nombre: dataNivel3,
                idDoc: docRef.id
            }
            docRef.set(body);
        }
    } catch (error) {
        console.error('controller.temas.modificarNivel3', error);
        throw error;
    }

}

