import {
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}