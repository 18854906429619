import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { firebaseDatabase, firebaseField, storage } from "../../../controller/firebase";
import moment from "moment";

import {
  COMPANY_DATA,
  CROSS_DOC,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_USERS, LOAD_MENU, PAGINAR_DOCUMENTOS_PROCESADOS, SHOW_DOC_PROC,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  getOCRDocumentosSuccess,
  documentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  showDocumentosProcesadosSuccess,
  crossSuccess,
} from './actions';

import { getFromSession, saveInSession } from "../../../controller/session";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {
  console.log("SAGA COMPANY DATA")
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}



function* getOCRDocumentos(action) {
  console.log("ASDSDADDASDASDASDASDASDASD")
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let estado = false;
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      //.where('estado', '==', 0)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        //.where('datosDocumento.estado', '==', 1)
        .get();

      documentosOCRSnapshotCargados.forEach(doc => {
        const documentData = doc.data();
        if (documentData.datosDocumento.estado === 0) {
          estado = 'Cargado en bucket'
        } else if (documentData.datosDocumento?.estado === 1) {
          estado = 'Orden de procesamiento'
        } else if (documentData.datosDocumento?.estado === 2) {
          estado = 'En procesamiento'
        } else if (documentData.datosDocumento?.estado === 3) {
          estado = 'Procesado'
        } else if (documentData.datosDocumento?.estado === 4) {
          estado = 'Procesado con error'
        }
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : '',
          estado: estado
        };
        consultaData.push(dataDocument);
      });

      const documentosOCRSnapshotError = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 4).get();

      documentosOCRSnapshotError.forEach(doc => {
        const documentData = doc.data();
        if (documentData.datosDocumento.estado === 0) {
          estado = 'Cargado en bucket'
        } else if (documentData.datosDocumento?.estado === 1) {
          estado = 'Orden de procesamiento'
        } else if (documentData.datosDocumento?.estado === 2) {
          estado = 'En procesamiento'
        } else if (documentData.datosDocumento?.estado === 3) {
          estado = 'Procesado'
        } else if (documentData.datosDocumento?.estado === 4) {
          estado = 'Procesado con error'
        }
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : '',
          estado: estado
        };
        consultaData.push(dataDocument);
      });



    }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(getOCRDocumentosSuccess(consultaData));

}

function* getDocumentosProc(action) {
  console.log("getDocumentosProcesados SAGA mis documentos")
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const companyOcr = `${resp.tipo_documento + resp.num_documento}`
    saveInSession('companyOcr', companyOcr);

    const documentosOCRSnapshot = yield firebaseDatabase.collectionGroup(`Documentos`)
      .where('datosDocumento.company', '==', companyOcr)
      .where('datosDocumento.estado', '==', 3)
      .orderBy("datosDocumento.fecha_cargue", "desc")
      .limit(10)
      .get();

    console.log(documentosOCRSnapshot, "documentosOCRSnapshot")

    documentosOCRSnapshot.forEach(doc => {
      
      const documentData = doc.data();

      const dataDocument = {
        lote: doc._delegate._document.key.path.segments[8],
        fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
        name: documentData?.datosDocumento?.name,
        size: documentData?.datosDocumento?.tamano,
        id: doc.id,
        resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
        fecha_proc: moment(documentData?.respuesta?.fecha_de_procesamiento?.toDate()).format("ll, h:mm:ss a")
      };
      consultaData.push(dataDocument);


    });

    // const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //   // .where('estado', '==', 1)
    //   .limit(5)
    //   .get();

    // documentosSnapshot.forEach(doc => {
    //   const idDoc = doc.id
    //   documentos.push(idDoc);
    // });

    // for (const id of documentos) {
    //   const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
    //     .where('datosDocumento.estado', '==', 3).get();

    //   documentosOCRSnapshot.forEach(doc => {
    //     const documentData = doc.data();
    //     const dataDocument = {
    //       lote: id,
    //       fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
    //       name: documentData?.datosDocumento?.name,
    //       size: documentData?.datosDocumento?.tamano,
    //       id: doc.id,
    //       resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
    //       fecha_proc: moment(documentData?.respuesta?.fecha?.toDate()).format("ll, h:mm:ss a")
    //     };
    //     consultaData.push(dataDocument);


    //   });

    // }

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  yield put(documentosProcesadosSuccess(consultaData))


}

function* getDocumentosFallidos(action) {
  // console.log("getDocumentosFallidos SAGA mis documentos")
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .where('estado', '==', 1)
      .where('estado', '==', 2)
      .where('estado', '==', 4).get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 4).get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        console.log("documentData", documentData)
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.respuesta.texto_Full ? documentData.respuesta.texto_Full : ''
        };
        consultaData.push(dataDocument);
      });
    }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosFallidosSuccess(consultaData));
}

function* mostrarDocumentosProcesados(action) {
  console.log("ACTION MOSTRAR DOCUMENTOS PROCESADOS SAGA DOCS", action)


  let user = getFromSession("currentUser");
  let dataVistaPrevia = [];
  let resp = false
  let dataDocument = false;
  let datosRender = {};
  let showDataDocument = [];
  let DataBD = false;
  let nuevoArreglo = false;
  const resultado = {};
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('DOCUMENT_FORM');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
          //console.log('datadata DOCUMENT_FORM', DataBD);
          // for (const document in data) {
          //   if (data.hasOwnProperty(document)) {
          //     const objetoInterno = data[document];
          //     //console.log(`Objeto externo: ${document}`);
          //     for (const claveInterna in objetoInterno) {
          //       let valorInterno = objetoInterno[claveInterna];
          //       //console.log(`claveInterna: ${claveInterna}`);
          //       if (claveInterna === 'tipoDocumento') {
          //         dataVistaPrevia.push({
          //           tipoDocumento: valorInterno
          //         })
          //       }
          //       console.log(`**************************************+`);
          //       if (typeof (valorInterno) === 'object') {
          //         valorInterno.map((element) => {
          //           dataVistaPrevia.push({
          //             campos: element
          //           })
          //         })
          //       }
          // if (objetoInterno.hasOwnProperty(claveInterna)) {

          //   if (typeof(valorInterno) === 'object') {
          //     valorInterno.map((element)=>{
          //       console.log(`Clave: ${claveInterna}`);
          //       console.log("TIpos Elemento", element)

          //   })
          //   }
          //   // dataVistaPrevia.push(valorInterno)
          // }
          //     }

          //   }

          // }
        } else {
          console.log('El documento no existe');
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  console.log('DataBD*********', DataBD)
  let arrayCheckbox = []
  let arrayText = []
  let arraysolo = []
  let arregloNew = {};

  let arregloNew2 = {};
  let arregloNew3 = {};
  let textoFull = false;
  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)

      .get().then(docs => {
        docs.forEach(doc => {
          //console.log('datosDocumento', doc.data())




          if (doc.data().datosDocumento.name === action.value.name) {

            //console.log("doc.data().datosDocumento.name", doc.data().datosDocumento.name)
            //console.log("action.value.name", action.value.name)
            dataDocument = doc.data()?.respuesta.pagina
            textoFull = doc.data()?.respuesta.texto_Full
            //console.log("dataDocument", JSON.stringify(dataDocument))


            let separador2 = '-'
            let separador = '/'
            dataDocument.forEach((objeto) => {

              if (objeto.etiqueta.includes(separador)) {
                //console.log("objeto", objeto)
                const etiqueta = objeto.etiqueta.split("/")[0];
                const nombreEtiqueta = objeto.etiqueta.split("/")[1];
                if (etiqueta) {

                  arregloNew[etiqueta] = arregloNew[etiqueta] || [];

                  for (const propiedad in objeto.value) {
                    //console.log("PROPIEDAD", propiedad)
                    if (propiedad === 'booleanValue') {
                      arregloNew[etiqueta].push({
                        [nombreEtiqueta]: objeto.value.booleanValue,
                      });
                    }
                  }

                }
              }
              else if (objeto.etiqueta.includes(separador2)) {
                const etiqueta = objeto.etiqueta.split("-")[0];
                if (etiqueta) {
                  arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

                  if (objeto.value.subdatos) {
                    arregloNew2[etiqueta].push(
                      objeto.value.subdatos.map((subdato) => ({
                        [subdato.Type]: subdato.mentiontext,
                      }))
                    );
                  }
                }
              } else {
                const etiqueta = objeto.etiqueta;
                if (objeto.value.texto !== '') {
                  arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
                  arregloNew3[etiqueta].push(objeto.value.texto)
                }

              }
            });


            // nuevoArreglo = dataDocument.map((objeto) => {
            //   let separador = '/'
            //   let separador2 = '-'
            //   // const arregloDePalabras = frase.split(',');
            //   //console.log ("Etiquetas", objeto.etiqueta.split('/')[0])
            //   if (objeto.etiqueta.includes(separador)) {
            //     arrayCheckbox.push(objeto.etiqueta.split(separador));
            //   } else if (objeto.etiqueta.includes(separador2)) {


            //console.log("OBJ", objeto)
            // objeto.value.subdatos.map((subdatos) => { 
            //   arrayText.push({
            //     type: subdatos.Type,
            //     text: subdatos.mentiontext
            //   })
            // })
            // arrayText.push(objeto.etiqueta.split(separador2));
            // }
            // else {
            //   arraysolo.push({
            //     etiqueta: objeto.etiqueta,
            //     text: objeto.value.texto
            //   }
            //   )
            // }
            // const nuevoObjeto = {
            //   //etiqueta: objeto.etiqueta,
            //   //texto: objeto.value.texto,
            // };

            // if (objeto.value.subdatos) {
            //   nuevoObjeto.subdatos = objeto.value.subdatos.map((subObjeto) => {
            //     return {
            //       etiqueta: objeto.etiqueta,
            //       type: subObjeto.Type,
            //       menciontext: subObjeto.mentiontext
            //     };
            //   });

            // console.log("objeto.value.subdato",objeto.value.subdatos);
            //   } else {
            //     nuevoObjeto.etiqueta = objeto.etiqueta
            //     nuevoObjeto.texto = objeto.value.texto
            //   }
            //   return nuevoObjeto;
            // });

            //console.log("nuevoArreglo", nuevoArreglo);

            resultado.checkbox = arregloNew;
            resultado.texto = arregloNew2;
            resultado.campoUnico = arregloNew3;
            resultado.textoFull = textoFull;

            //console.log("resultado", JSON.stringify(resultado));

            if (DataBD) {

              if (DataBD.length !== 0) {
                DataBD.forEach((element) => {
                  //console.log("ELMENT DATA DB", doc.data().respuesta.extraccion.tipoDeDocumento)
                  //console.log("ELMENT DOCUMENT FORM DATA DB", element.tipoDocumento)

                  if (doc.data().respuesta?.tipoDeDocumento === element.tipoDocumento) {
                    //console.log("doc.data().respuesta?.tipoDocumento ", doc.data().respuesta?.tipoDocumento )
                    //console.log("element.tipoDocumento ", element.tipoDocumento )
                    element.campos.forEach((e) => {


                      let arraySubdatos = [];
                      for (const propiedad in resultado.texto) {
                        if (typeof resultado.texto[propiedad] === "object") {
                          for (const propiedadSub in resultado.texto[propiedad]) {
                            // Imprime el elemento de la matriz
                            resultado.texto[propiedad][propiedadSub].forEach((data) => {
                              //console.log("e.value, data", e.value, Object.keys(data).toString())
                              if (e.value === Object.keys(data).toString()) {
                                // console.log("e.value, data", e.value, Object.keys(data))
                                //datosRender[propiedadSub] =  Object.values(data).toString();
                                // Agregamos una variable para almacenar los valores del arreglo
                                let valores = Object.values(data);

                                arraySubdatos.push(valores)

                              }
                            })
                          }
                        } else {
                          // Imprime el valor del objeto
                        }
                      }
                      console.log("arraySubdatos", arraySubdatos);

                      datosRender[e.value] = arraySubdatos
                      for (const propiedad in resultado) {

                        if (typeof resultado[propiedad] === "object") {
                          for (const propiedadSub in resultado[propiedad]) {
                            //console.log("e.value", e.value)
                            //console.log("propiedadSub", propiedadSub)
                            if (e.value === propiedadSub) {
                              datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                            }

                            //console.log("resultado.texto", JSON.stringify(resultado.texto))
                          }
                        } else if (e.value === propiedad) {
                          datosRender[propiedad] = resultado[propiedad];
                        }
                      }

                    });

                  }
                })
              }
            }
            if (Object.keys(datosRender).length === 0) {
              datosRender = resultado;
            }


            //console.log('datosDocumento if', doc.data().respuesta.extraccion)
            //dataDocument = doc.data().respuesta.extraccion
            // for (const key in dataDocument) {
            //   if (dataDocument.hasOwnProperty(key)) { // Para asegurarte de que solo consideras las propiedades propias del objeto
            //     for (let i = 0; i < dataVistaPrevia.length; i++) {
            //       if (key === dataVistaPrevia[i]) {
            //         //datosRender.push(key);
            //         //console.log(`La clave ${key} coincide con el elemento ${dataVistaPrevia[i]} en el arreglo.`);
            //         //console.log("dataDocument", dataDocument)
            //         datosRender[key] = dataDocument[key];
            //         console.log("datosRender", datosRender)

            //       }
            //     }
            //   }
            //   showDataDocument = datosRender
            // } 
          }
        });
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  // console.log("dataVistaPrevia",dataVistaPrevia)
  //  console.log("datosRender", datosRender)
  //  console.log("dataDocument", dataDocument)
  //  if(datosRender.length !== 0){
  //    showDataDocument = datosRender
  //    console.log("Arreglo no vacio")
  //   }else if(datosRender.length === 0){
  //     console.log("Arreglo vacio")
  //     showDataDocument = dataDocument
  //   }
  // if (dataVistaPrevia.length === 0) {
  //   showDataDocument = dataDocument
  // }


  //console.log("resultadoresultado", JSON.stringify(resultado))

  // arrayCheckbox.forEach((subarreglo) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado[clave] = [];
  //   }

  //   resultado[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // arraysolo.forEach(() => {

  // })

  // console.log("arrayText", arrayText)
  // // arrayText.forEach((subarreglo) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado2[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado2[clave] = [];
  //   }

  //   resultado2[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // arrayText.forEach((subarreglo, i) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado[clave] = valor;
  //   }

  //   resultado[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // const arregloNuevo = [];

  // arraysolo.forEach((objeto) => {
  //   console.log("OBJETO", objeto)
  //   const objetoNuevo = {
  //     label: objeto.etiqueta,
  //     value: [objeto.text],
  //   };

  //   arregloNuevo.push(objetoNuevo);
  // });
  // const objetoNuevo = arraysolo.reduce((resultado, objeto) => {
  //   resultado[objeto.etiqueta] = [objeto.text];

  //   return resultado;
  // }, {});

  // console.log(objetoNuevo);


  // console.log(arregloNuevo);
  // console.log("resultado2", resultado)
  // console.log("resultado2", resultado2)
  // console.log("objetoNuevo", objetoNuevo)

  // resultado3.checkbox = resultado;
  // resultado3.texto = resultado2;
  // resultado3.solo = objetoNuevo;
  // console.log("arrayText", arrayText)
  //console.log("showDataDocument", extraccionDocument.result.Documentos_Base64[0].pagina)
  //console.log("datosRender", datosRender)

  yield put(showDocumentosProcesadosSuccess(datosRender))

}


function* downloadDoc(action) {
  console.log("URL PDF SAGA MIS OCU", action)
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put(downloadDocuSuccess())
}

function* cruzarDoc(action) {
  //console.log("mis documentos CROSS", action.value)
  let user = getFromSession("currentUser");
  let cruzar = false;
  let resp = false
  let cruce = false;
  let documentos = [];
  let consultaData = [];
  let showDocument = false;
  let valoresParaCruce = [];
  let DataBD = false;
  let valorCorrespondiente = false;
  let datosDocPrincipal = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('CRUCE_CONF');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  //console.log("DataBD", DataBD)

  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .doc(action.value.id)
      .get().then(doc => {
        datosDocPrincipal = {
          nombre: doc.data().datosDocumento.name,
          tipoDocumento: doc.data().respuesta?.tipoDeDocumento
        }
        const BdTipoDocumento = doc.data().respuesta?.tipoDeDocumento;
        //console.log("dataDB", DataBD)
        if (DataBD) {

          DataBD.forEach((element) => {
            //console.log("elment DATABD", element)

            Object.values(element).forEach((resp) => {
              //console.log("resp elment DATABD", resp)
              valoresParaCruce.push(resp)
              if (resp === BdTipoDocumento) {
                cruzar = true;
              }

            })
          })
        }
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  valoresParaCruce = valoresParaCruce.filter((valor, indice, self) => self.indexOf(valor) === indice);

  //console.log("ARRAY DE CRUCE", valoresParaCruce)


  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //.where('estado', '==', 1)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  if (cruzar) {
    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        //.where('respuesta.extraccion.id', '==', cruce)
        .get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        if (documentData.respuesta) {
          documentData.respuesta.pagina.forEach((resp) => {
            valoresParaCruce.forEach((val) => {
              if (resp.etiqueta === val) {
                //console.log("resp.etiqueta", resp)
                valorCorrespondiente = resp.value.texto;
              }
              if (resp.value.subdatos) {
                resp.value.subdatos.forEach((subadto) => {
                  Object.values(subadto).forEach((dato) => {

                    //console.log("subadto", dato, val, subadto.mentiontext);
                    if (dato === val) {
                      //console.log("coinciden")
                      valorCorrespondiente = subadto.mentiontext;
                    }
                  })
                })
              }
            })
          })
        }

        //console.log("documentData", documentData)
      });
    }

    console.log("valorCorrespondiente", valorCorrespondiente)

    for (const id of documentos) {
      const ref = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get().then((snapshot) => {
          snapshot.forEach(doc => {

            const datos = doc.data();
            //console.log("datos", doc.data())
            if (datos?.respuesta?.pagina) {
              const elementos = datos.respuesta.pagina.map((pagina) => {

                //  console.log("pagina", pagina)

                if (pagina.value.texto === valorCorrespondiente) {
                  consultaData.push({
                    lote: id,
                    fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                    name: datos?.datosDocumento.name,
                    size: datos?.datosDocumento.tamano,
                    id: doc.id,
                    resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                    estado: datos?.datosDocumento.estado
                  });
                }
                Object.values(pagina).forEach((dato) => {
                  if (dato.subdatos) {
                    dato.subdatos.forEach((subdato) => {
                      if (subdato.mentiontext === valorCorrespondiente) {
                        consultaData.push({
                          lote: id,
                          fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: datos?.datosDocumento.name,
                          size: datos?.datosDocumento.tamano,
                          id: doc.id,
                          resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                          estado: datos?.datosDocumento.estado
                        });
                      }
                    })
                  }

                })
              });
            }
          })
        });
      // const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      //   .where('respuesta.extraccion.id', '==', valorCorrespondiente)
      //   .get();
      let documentosOCRSnapshot = false;



      //for (let valor of valoresParaCruce) {
      //let estado = false;
      //   console.log("valorCorrespondiente", valorCorrespondiente)
      //console.log("id doc", id)
      const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .get()
        .then((docs) => {
          const documentosFiltrados = [];
          docs.forEach(doc => {
            const data = doc.data();
            //console.log("data", data)

            const pagina = data?.respuesta?.pagina;

            for (let valor of valoresParaCruce) {
              if (valor === data?.respuesta?.tipoDeDocumento) {
                if (pagina && Array.isArray(pagina)) {
                  //console.log("pagina", pagina)
                  // Buscamos en todos los elementos de respuesta.pagina
                  for (const item of pagina) {
                    if (item.value && item.value.subdatos && Array.isArray(item.value.subdatos)) {
                      //console.log("item.value.subdatos", item.value.subdatos)
                      const subdato = item.value.subdatos.find(sub => sub.mentiontext === valorCorrespondiente);
                      if (subdato) {
                        consultaData.push({
                          lote: id,
                          fecha_cargue: data.respuesta?.fecha_de_procesamiento ? moment(data.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: data.datosDocumento.name,
                          size: data.datosDocumento.tamano,
                          id: doc.id,
                          resumen: data.respuesta?.texto_Full ? data.respuesta?.texto_Full : '',
                          estado: data.datosDocumento.estado
                        });
                        //break; // Terminamos la búsqueda en esta página al encontrar una coincidencia
                      }
                    }
                  }
                }
              }
            }

          });
          // documentosFiltrados contiene los documentos que cumplen con la condición
        }).catch((error) => {
          console.log("error", error)
        });

      // const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      // //.where('datosDocumento.estado', '==', 1)
      // .get();




      // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`).where("pagina.value.subdatos", "array-contains", { Type: 'Nombre_Menor', mentiontext: valorCorrespondiente })
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       // Aquí puedes acceder a los documentos que cumplen con la condición
      //       console.log("Documento encontrado:", doc.id, doc.data());
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Error al realizar la consulta:", error);
      //   });






      // Recorremos el campo pagina
      // Comparamos el elemento con el valor almacenado en la variable
      //return pagina === valor;

      // Imprimimos el resultado de la comparación

      //}




      // documentosOCRSnapshot.forEach(doc => {
      //   const documentData = doc.data();
      //   const dataDocument = {
      //     lote: id,
      //     fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
      //     name: documentData.datosDocumento.name,
      //     size: documentData.datosDocumento.tamano,
      //     id: doc.id,
      //     resumen: documentData.resumen ? documentData.resumen : ''
      //   };
      //   consultaData.push(dataDocument);
      //   if (consultaData.length !== 0) {
      //     showDocument = true
      //   }

      //   //console.log("documentData", documentData)
      // });
    }
  }

  //console.log("consultaData", consultaData)

  if (consultaData.length !== 0) {
    showDocument = true
  }






  //console.log("DOCUMENTOS CRUCE", consultaData)



  yield put(crossSuccess({
    consultaData: consultaData,
    showDocument: showDocument,
    datosDocPrincipal: datosDocPrincipal
  }))
}
function generarQueryConsultaPaginada(reference, first, fecha, nextPage, rows) {

  let consulta = reference
  if (first == 0) {
    if (fecha) {
      consulta = reference.limit(rows)
    } else if (!nextPage) {
      consulta = reference.limit(rows)
    } else {
      consulta = false
    }

  } else if (first > 0) {
    if (nextPage) {
      consulta = reference.startAfter(fecha).limit(rows)
    } else {
      consulta = reference.endBefore(fecha).limitToLast(rows)
    }
  }
  return consulta;
}


function* paginarDocumentosProcesados(action) {

  try {
    const { first, rows } = action.value.event;
    const { ultimoDocProc, nextPage, seleccionRangoBusqueda } = action.value;

    const companyOcr = getFromSession('companyOcr');

    let ultimoDocProcFecha = new Date(ultimoDocProc.fecha_cargue)



    let reference = yield firebaseDatabase.collectionGroup(`Documentos`)
      .where('datosDocumento.company', '==', companyOcr)
      .where('datosDocumento.estado', '==', 3)
      .orderBy("datosDocumento.fecha_cargue", "desc")


    let query = generarQueryConsultaPaginada(reference, first, ultimoDocProcFecha, nextPage, rows);

    let resultadoConsulta = []
    let esPrimeraPagina = false;
    let consultaData = [];
    if (query) {
      //const documentosOCRSnapshot = yield query.get();
      yield query.get().then(response => {
        response.docs.map((doc, i) => {
          const documentData = doc.data();
          const dataDocument = {
            lote: doc._delegate._document.key.path.segments[8],
            fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
            name: documentData?.datosDocumento?.name,
            size: documentData?.datosDocumento?.tamano,
            id: doc.id,
            resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
            fecha_proc: moment(documentData?.respuesta?.fecha_de_procesamiento?.toDate()).format("ll, h:mm:ss a")
          };
          consultaData.push(dataDocument);
        });

    })
      // documentosOCRSnapshot.forEach(doc => {
      //   const documentData = doc.data();
      //   const dataDocument = {
      //     lote: "56446545464564",
      //     fecha_cargue: documentData?.datosDocumento?.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue.toDate()).format("ll, h:mm:ss a") : '',
      //     name: documentData?.datosDocumento?.name,
      //     size: documentData?.datosDocumento?.tamano,
      //     id: doc.id,
      //     resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
      //     fecha_proc: moment(documentData?.respuesta?.fecha?.toDate()).format("ll, h:mm:ss a")
      //   };
      //   consultaData.push(dataDocument);


      // });

    } else {
      esPrimeraPagina = true
    }


    yield put(documentosProcesadosSuccess([...consultaData]))
    // yield put(paginarDatosConvsChatbotSuccess(
    //   {
    //     "dataPaginada": data,
    //     "esPrimeraPagina": esPrimeraPagina
    //   }
    // ))
  } catch (error) {

    throw error;

  }
}


export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(GET_DOC_PROC, getDocumentosProc);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(CROSS_DOC, cruzarDoc);
  yield takeLatest(PAGINAR_DOCUMENTOS_PROCESADOS, paginarDocumentosProcesados);


}
