import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { storage } from "../../../controller/firebase";
import { ref, uploadBytes } from "firebase/storage";

export default function ComponentImagen(props) {
    const estadoActual = 'Adjunto actual- '
    const company = props.imageData.tipo_documento + props.imageData.num_documento;
    const [urlImg, setUrlImg] = React.useState(false);
    const nombreBot = props.nombreBot?.nombreChatBot
    //console.log("PROPS COMPONENTE IMAGEN", props)

    if (props.respuestaImg && props.respuestaImg.url) {
        var pathReference = storage.ref(props.respuestaImg.url);

        pathReference.getDownloadURL().then((url) => {
            //console.log("URL IMAGEN COMPONENT", url)
            setUrlImg(url)
        })
            .catch((error) => {
                console.log("ERROR", error)
            });
    }

    const toast = useRef(null);

    //console.log("respuestaImg", props.respuestaImg)

    const customBase64Uploader = async (event) => {
        if (event) {

            const file = event.files[0];
            const reader = new FileReader();

            console.log("FILE UPLOAD", file.type)

            const spaceRef = ref(storage, `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);
            await uploadBytes(spaceRef, file).then((snapshot) => {
            });

            const index = props.index
            let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
            reader.readAsDataURL(blob);

            reader.onloadend = function () {
                const base64data = reader.result;
            };

            if (file && file.type === 'application/pdf') {
               // console.log("PDF")
                let pdfUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    pdfUrl = url
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR PDF", error)
                    // Manejar errores
                });


                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        imagen: pdfUrl,
                        type: 'pdf',
                        name: file.name,
                        company: company
                    }, index, type: 'imagen',
                })
            }
            if (file && file.type === 'audio/mpeg') {
                //console.log("PDF")
                let audioUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    audioUrl = url
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR audioUrl", error)
                    // Manejar errores
                });


                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        imagen: audioUrl,
                        name: file.name,
                        type: 'audio',
                        company: company
                    }, index, type: 'imagen',
                })
            }

            if (file && file.type === 'image/png' || file.type === 'image/jpeg') {
                console.log("imagen")
                let imageUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    //console.log("URLLLLL im", url)
                    imageUrl = url
                   // console.log("URLLLLL imageUrl", imageUrl)
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR image", error)
                    // Manejar errores
                });
                //console.log("IMAGENURRRRLLLLLLLL", imageUrl)


                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        imagen: imageUrl,
                        type: 'image',
                        name: file.name,
                        company: company
                    }, index, type: 'imagen',
                })
            }

            if (file && file.type === 'video/mp4') {
                //console.log("video")
                let videoUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    videoUrl = url
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR video", error)
                    // Manejar errores
                });

                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        imagen: videoUrl,
                        type: 'video',
                        name: file.name,
                        company: company
                    }, index, type: 'imagen',
                })
            }

            if (file && file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                //console.log("word")
                let wordUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    wordUrl = url
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR word", error)
                    // Manejar errores
                });

                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        type: 'word',
                        imagen: wordUrl,
                        name: file.name,
                        company: company
                    }, index, type: 'imagen',
                })

            }

            if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                //console.log("word")
                let excelUrl = false;
                const storageRef = storage.ref();

                // Obtener referencia del archivo
                const fileRef = storageRef.child(`${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`);

                // Obtener URL del archivo
                await fileRef.getDownloadURL().then((url) => {
                    excelUrl = url
                    // Usar la URL
                }).catch((error) => {
                    console.log("ERROR word", error)
                    // Manejar errores
                });

                props.onChange({
                    value: {
                        url: `${company}/productos/chatbot/${nombreBot}/respuestas/imagenes/${file.name}`,
                        type: 'excel',
                        imagen: excelUrl,
                        name: file.name,
                        company: company
                    }, index, type: 'imagen',
                })

            }



            



        }
    };

    const handleRemove = () => {
        //console.log("PROPSS HANDLWREMOVE", props)
        if (props.respuestaImg) {
            var storageRef = storage.ref(props.respuestaImg?.url);

            // Delete the file
            storageRef.delete().then(function () {
                //console.log("IMAGE DELETED")
            }).catch(function (error) {
                //console.log("IMAGE ERROR", error)
            });
        }

    }

    return (
        <div className="card">
            {urlImg
                && <div className='imagenBD'>
                    <p>
                        <strong>
                            {estadoActual}
                        </strong>
                    </p>
                    <p>
                        {props.respuestaImg.name}
                    </p>

                </div>
            }
            <FileUpload
                name="demo[]"
                url={"/api/upload"}
                customUpload
                multiple={false}
                uploadHandler={customBase64Uploader}
                accept="image/*,video/*,.pdf" maxFileSize={1000000}
                uploadLabel={props.uploadLabel}
                cancelLabel={props.cancelLabel}
                chooseLabel={props.chooseLabel}
                onRemove={handleRemove}
                emptyTemplate={<p className="m-0" style={{"fontSize":"16px"}}>
                    Seleccione o arrastre los archivos hasta aquí.
                </p>} />
        </div>
    )
}