import React, { Component } from "react";
//import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getUsers } from "./selectors";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS
} from './constants';


class ComponentHome extends Component {

    componentWillMount () {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { getUsers } = this.props;
        getUsers();
    }

    render() {
        const {
            userHeaders, 
            users,
        } = this.props;

    return (
        <div className="App home-container">
            <div className='user-list_wrapper'>
            { users && !users.empty ? 
                <CustomTable 
                    className="users-table" 
                    items={users} 
                    headers={userHeaders}
                    onSelectRow={false}
                /> :
                <Spinner className="spinner-wrapper" animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            }
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
    };
};

const mapDispachToProps = dispatch => {
    return { 
        getUsers: () => dispatch({ type: GET_USERS, value: 1 })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentHome);