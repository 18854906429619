import { takeLatest, put, call, select, actionChannel } from "redux-saga/effects";

import axios from "axios";
import { getFromSession } from '../../controller/session';
import { firebaseDatabase } from '../../controller/firebase'
import { DOWNLOAD_FILE, IMPORT_FILE, MANAGE_NAV_BUTTON, REBOOT_PAGE } from "./constants";
import { getFormFiles } from "./selectors";
import * as XLSX from 'xlsx';

import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { manageNaveButton, setUrlAuditLog, startWaiting } from "./actions";

const getJsonDataToXLSX = async (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: "buffer" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });

}


function* importarExcelToIntent(action) {
    //yield createCatalog();
    try {
        const files = yield select(getFormFiles);
        let jsonFile = false;
        const company = yield getFromSession("company_id");
        jsonFile = yield getJsonDataToXLSX(files[0].file);

        console.log(jsonFile)
        let body = {
            import: true,
            company: company,
            intents: []
        }
        let intentsToSend = [];
        if (jsonFile != false) {
            jsonFile.forEach(element => {
                let tempIntent = {
                    intentName: false,
                    trainingPhrases: [],
                    textResponse: []
                }
                if (element.IntentName) {
                    tempIntent.intentName = cortarMSM(element.IntentName, 100);
                }
                if (element.TrainingPhrases) {
                    let temp = element.TrainingPhrases.replace(/\r/g, "");
                    let auxPhrases = temp.split('#');
                    let temAux = [];
                    auxPhrases.forEach(element => {
                        temAux.push(cortarMSM(element, 768))
                    })
                    tempIntent.trainingPhrases = temAux;

                }
                if (element.TextResponse) {
                    tempIntent.textResponse = [cortarMSM(element.TextResponse, 4000)];
                }
                intentsToSend.push(tempIntent);
            });
        }
        yield put(startWaiting({ state: true }));
        yield put(manageNaveButton("reboot"));
        if (intentsToSend.length > 60) {
            let cont = 0;
            let finaliza = false;
            let responses = [];
            for (let e of intentsToSend) {
                cont++;
                if (body.intents.length < 60) {
                    body.intents.push(e);
                } else {

                    const responseService = yield consumirAPICreateIntent(body);
                    yield sleep(60000);
                    responses.push(responseService);
                    body.intents = [];
                }
                if (cont === intentsToSend.length) {
                    finaliza = true;
                    if (body.intents.length > 0) {

                        const responseService = yield consumirAPICreateIntent(body);
                        responses.push(responseService);
                    }
                }
            }
            if (finaliza) {
                let error = false;
                responses.forEach(item => {
                    if (item.status !== 200)
                        error = true;
                });
                if (error) {
                    yield put(manageNaveButton({ value: "importAndExport" }));
                    yield put(startWaiting({ state: false, success: true }));
                } else {

                    yield put(manageNaveButton({ value: "importAndExport" }));
                    yield put(startWaiting({ state: false, error: true }));
                }
            }
        } else {
            body.intents = intentsToSend;
            const responseService = yield consumirAPICreateIntent(body);
            console.log(responseService)
            if (responseService && responseService.status === 200) {
                yield put(manageNaveButton({ value: "importAndExport" }));
                yield put(startWaiting({ state: false, success: true }));
            } else {

                yield put(manageNaveButton({ value: "importAndExport" }));
                yield put(startWaiting({ state: false, error: true }));
            }
        }

    } catch (error) {
        console.error("exception:", error);
        yield put(startWaiting({ state: false, error: true }));
        throw `saga.js - importarExcelToIntent - ${error}`;
    }

}

function cortarMSM(mens, tamanio) {
    try {
        let newMsm = mens;
        if (mens && mens.length > tamanio) {
            newMsm = mens.substr(0, tamanio - 1)
        }
        return newMsm;
    } catch (error) {
        throw `saja.js- cortarMSM - ${error}`

    }
}

export function* createCatalog() {

    const datos = []
    try {
        yield datos.map(item => {
            firebaseDatabase.collection(`company/alcaldiaBogota/presupuestos`).add(item);
        });
        // yield firebaseDatabase.collection(`company/alcaldiaBogota/presupuestos`).get().then(item => {
        //     console.log(item.size,"ñññññññññ")
        // })

        console.log("hecho");
    } catch (e) {
        console.error(e);
    }
}

function* consumirAPICreateIntent(body) {

    try {
        const urlDespliegue = process.env.REACT_APP_DIALOGFLOW_INTENTS;
        //const urlDespliegue = "http://localhost:5003/chat-asesores-3/us-central1/dialogflowintents";//

        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
            'accept': "application/json, text/plain, */*",
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        //console.log("body envia excel", body,urlServicio)
        let respues = true;
        respues = yield call(axios.post, urlDespliegue, body, headers);
        return respues;
    } catch (error) {
        console.error("exception:", error);
        throw `saga.js - consumirAPICreateIntent - ${error}`;
    }
}
function getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
            return row[fieldName] ? row[fieldName] : "";
        });
    });
    sheetData.unshift(header);
    return sheetData;
}

function* exportIntentToExcel(action) {
    try {
        const company = yield getFromSession("company_id");
        let body = {
            export: true,
            company: company,
            intentsFaltantes: false,
        }
        let result = {
            return: false,
            listIntent: []
        }
        let cont = 0;
        do {
            const before = Date.now();
            const temp = yield consumirAPICreateIntent(body);
            result.return = temp.data.return;
            result.listIntent = result.listIntent.concat(temp.data.listIntent);
            const later = Date.now();
            console.log(later - before);
            const espera = (later - before) > 60000 ? 60000 : (60000 - (later - before)) + 5000;
            if (temp.data.return) {
                yield sleep(espera);
                body.intentsFaltantes = temp.data.intentsFaltantes;
            }
            cont++;
        } while (result.return);

        const jsonToExcel = [];
        if (result.listIntent.length > 0) {
            result.listIntent.forEach(element => {
                let auxPhrases = ``;
                let auxMessages = ``;
                if (element.trainingPhrases.length > 1) {
                    element.trainingPhrases.forEach(element => {
                        if (element.parts[0].text != undefined)
                            if (auxPhrases.length > 0)
                                auxPhrases = `${auxPhrases} | ${element.parts[0].text}`;
                            else
                                auxPhrases = `${element.parts[0].text}`;
                    });
                } else {
                    if (element.trainingPhrases.length > 0) {
                        if (element.trainingPhrases[0].parts[0].text != undefined)
                            auxPhrases = `${element.trainingPhrases[0].parts[0].text} |`;
                    }
                }

                if (element.messages[0]?.text?.text?.length > 1) {
                    element.messages[0].text.text.forEach(element => {
                        if (element != undefined)
                            if (auxMessages.length > 0)
                                auxMessages = `${auxMessages} | ${element}`;
                            else
                                auxMessages = `${element}`;
                    });
                } else {
                    if (element.messages[0]?.text?.text?.length > 0) {
                        if (element.messages[0].text.text[0] != undefined)
                            auxMessages = `${element.messages[0].text.text[0]} |`;
                    }
                }
                jsonToExcel.push({
                    IntentName: element.displayName,
                    TrainingPhrases: auxPhrases,
                    TextResponse: auxMessages,
                })

            });
            const header = ["IntentName", "TrainingPhrases", "TextResponse"];
            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                const sheet1 = workbook.sheet(0);
                const sheetData = getSheetData(jsonToExcel, header);
                const totalColumns = sheetData[0].length;

                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                range.style("border", true);
                return workbook.outputAsync().then((res) => {
                    saveAs(res, "intentsDialogFlow.xlsx");
                });
            });

            yield put(startWaiting({ state: false, success: true }));

        }


    } catch (error) {
        yield put(startWaiting({ state: false, error: true }));
        throw `saga.js - exportIntentToExcel - ${error}`;
    }
}



function* manageNavButton(action) {
    const { value } = action;
    if (value.value === "chatBot") {
        yield sleep(2000);
        yield put(manageNaveButton("reboot"));
    }
}
const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function* openURL(action) {
    const proyectoUrl = "chaticopruebas-cnhr";
    let url = `https://console.cloud.google.com/logs/query?hl=es-419&project=${proyectoUrl}`;
    yield put(setUrlAuditLog(url));
}

export function* watchAgent() {
    yield takeLatest(IMPORT_FILE, importarExcelToIntent);
    yield takeLatest(DOWNLOAD_FILE, exportIntentToExcel);
    yield takeLatest(REBOOT_PAGE, manageNavButton);
    yield takeLatest(MANAGE_NAV_BUTTON, openURL)
}

