export const GET_USERS = '@users/getAll';
export const GET_USERS_SUCCESS = '@users/getUsersSuccess';
export const GET_USERS_ERROR = '@users/getUsersError';
export const LOAD_MENU_SUCCES = '@inicio/loadMenuSucess';
export const LOAD_MENU = '@inicio/loadMenu';
export const ADQUIRIR_PRODUCTO_SUCCES = '@planes/adquirirProductoSucces'
export const ADQUIRIR_PRODUCTO = '@planes/adquirirProducto'
export const GET_PLANES_SUCCES = '@planes/getPlanesSuccess'
export const GET_PLANES = '@planes/getPlanes'
export const GET_TRANSACCION_SUCCES = '@planes/getTransaccionSuccess'
export const GET_TRANSACCION = '@planes/getTransaccion'



export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];

export const CARD_ITEMS =   [
    {
        id: '1050',
        type: 'button',
        buttonLabel: 'Adquirir',
        title: 'ChatBot Autogestionable',
        path: '/',
        description: 'Crea de forma sencilla conversaciones con los usuarios de forma automatizada',
        key: '0001',
        picture: 'https://atrevete.academy/blog/wp-content/uploads/2019/06/0_oz2e-hQtsHOWzoB4_.jpg'
    },
    {
        id: '1051',
        type: 'button',
        buttonLabel: 'Adquirir',
        title: 'Reconocimiento de imagenes',
        path: '/',
        description: 'Extrae el texto y caracteres de documentos PDF y documentos escaneados (incluido archivos de varias paginas), fotos e imagenes',
        key: '0002',
        picture: 'https://economia3.com/wp-content/uploads/2021/11/04-OCR-digitalizar-facturas-e3.jpg'
    }

]
