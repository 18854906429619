import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { menuItemsOCR } from "../../../Constants";
import { Box, Tabs, Tab } from "@material-ui/core";
import SideBar from "../../../components/sidebar/sidebar";
import { DOWNLOAD_DOC, GET_DOC_PROC, GET_OCR_DOC, SHOW_DOC_PROC } from "./constants";
import { getFromSession } from "../../../controller/session";
import { selectorLoadingCircular, selectorProccessDocument, selectorShowDocument, selectordocumentData } from "./selectors";
import { COMPANY_DATA, GET_DOC_FALL } from "./constants";
import { Paper } from "@material-ui/core";
import ComponentGestionDocumentos from "./documentosGesDoc";
import { getDataCompanySelector, selectorFailDocument } from "./selectors";
import AdminPanelPage from "../../adminPanel";


class ComponentAdmin extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, tipoDoc } = this.props;
        const renderDoc = tipoDoc ? 'documento' : 'usuarios'
        this.state = {
            tipo: renderDoc,
            adminOCR: true
        }

    }

    selectTab = (e, newValue) => {
        this.setState({ tipo: newValue });
    }

    componentWillMount() {
    }
    componentDidUpdate(prevProps) {
    }





    render() {
        const {
        } = this.props;

        const user = getFromSession("company_id")
        return (
            // <Paper className="main-wrapper home container"></Paper>
            <Paper className="main-wrapper home container">

                {menuItemsOCR && <SideBar logo={''} items={menuItemsOCR}></SideBar>}

                {/* {loading &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                } */}


                <>
                    <div className="subs-containere">

                        <AdminPanelPage
                            adminOCR={this.state.adminOCR} />



                    </div>



                </>

            </Paper>

        );
    }
}

const mapStateToProps = state => {
    return {
        proccessDocumentSelector: selectorProccessDocument(state),
        loadingCircular: selectorLoadingCircular(state),
        selectorDataCompany: getDataCompanySelector(state),
        failSelector: selectorFailDocument(state),
        showDocument: selectorShowDocument(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getDocumentosProc: (value) => dispatch({ type: GET_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentAdmin);