import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { CONVS_ABANDONADAS, TOAST_MESSAGE } from "./constants";
import { convsAbandonadas, getConvsFiltradas, getManageSpinerState, getSelectedForm, getShowSnackBar, getStateFilterButton } from "./selectors";


import PieChart from "../../components/Charts/pieChart"
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Toolbar } from "primereact/toolbar";
import { Button } from 'primereact/button';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";


class EstAbandonosComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            iniciaComponente: true
        }
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
    }


    componentWillMount() {
        const { obtenerDataPieConvsAbandonadas } = this.props
        obtenerDataPieConvsAbandonadas()

    }

    componentDidUpdate(prevProps) {
        const { obtenerDataPieConvsAbandonadas, estadoBotonFiltrarRangos, showToast } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerDataPieConvsAbandonadas(seleccionRango)
            this.setState({
                iniciaComponente: false
            })
        }

        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerDataPieConvsAbandonadas(seleccionRango)

        }

        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {

            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }
                obtenerDataPieConvsAbandonadas(rango)
            } else {
                showToast()


            }
        }
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    rightToolbarTemplate() {

        return (
            <React.Fragment>

                <Button label="Descargar CSV" icon="pi pi-cloud-download" className="p-button-help p-ml-2" onClick={this.exportCSV} />

            </React.Fragment>
        )
    }
    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }
    render() {
        const { convsAbandonadas, convsFiltradas, spinerState } = this.props;

        let convsAbandonadasData = []
        convsFiltradas.forEach(conv => {
            if (conv.estado === 12) {
                let data = {
                    fechaInicio: conv.fechaIni,
                    fechaAbandono: conv.fechaFin,
                    nombreCliente: conv.nombreCliente,
                    contacto: conv.contactoCliente
                }
                convsAbandonadasData.push(data)
            }
        });

        return (
            <div className="  p-mt-3 p-p-3">
                <h3 className="h3-title">Estadísticas de abandonos</h3>
                <div className="p-col-6">
                </div>
                <div className="p-grid p-mt-3 p-dir-col">
                    <div className="p-col-5 p-mr-auto p-ml-auto">
                        {spinerState.pieAbandono ? this.spinnerGraficos() : ""}

                        <PieChart data={convsAbandonadas}
                        ></PieChart>
                    </div>
                    <Toolbar className="p-mt-5" right={this.rightToolbarTemplate}></Toolbar>
                    <div className="p-col card">
                        <DataTable value={convsAbandonadasData} resizableColumns columnResizeMode="fit"
                            className="p-datatable-gridlines" ref={(el) => this.dt = el}
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} ">
                            <Column field="fechaInicio" header="Fecha inicio"></Column> 
                            <Column field="fechaAbandono" header="Fecha abandono"></Column>
                            <Column field="nombreCliente" header="Cliente"></Column>
                            <Column field="contacto" header="Contacto"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>)

    }
}


const mapStateToProps = state => {

    return {
        convsAbandonadas: convsAbandonadas(state),
        convsFiltradas: getConvsFiltradas(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),

    }
}


const mapDispachToProps = dispatch => {
    return {

        obtenerDataPieConvsAbandonadas: (value) => dispatch({ type: CONVS_ABANDONADAS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstAbandonosComponent);