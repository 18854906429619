import React, { Component } from "react";
import "./style.css";

import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from '../constants';
import { getMenuItemsChatBot, getUsers } from "../selectors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { Button } from "@material-ui/core";
import ComponentEliminar from "../componentConfEliminar";

class ComponentTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePath: '/',
            botName: '',
            chatbot: false,
            valRow: false,
            modalEliminar: false,
            first: 0,
            rows: 10
        }
    }

    onButonClick = () => {
        this.setState({ chatbot: true }
        );
    }

    componentWillMount() {
        const { getUsers, loadMenuChatbot } = this.props;
        getUsers();
        loadMenuChatbot();
    }

    onModalElimarOpen=(value)=>{
        console.log("VALUE ELIMINAR MODAL", value)
        this.setState({
            modalEliminar: true,
            valRow: value
        })
        console.log("VALUE ELIMINAR MODAL STATE", this.state.valRow)
    }

    onModalElimarClose=()=>{
        this.setState({
            modalEliminar: false,
            valRow: false
        })
        console.log("modalEliminar0,0, ",this.state.modalEliminar)
    }

    onDelete=(value)=>{
        const { onDelete } = this.props
        onDelete(value)
        this.setState({
            modalEliminar: false,
            valRow: false
        })
    }

    actionButtons = (rowData) => {
        const { onEdit, onAddQuestion, preguntasTabla } = this.props

        const valRow = rowData['nombreChatBot'] || rowData['nombreIntent']
        return (
            <div>
                <IconButton onClick={() => onEdit(valRow)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => this.onModalElimarOpen(valRow)}>
                    <DeleteIcon />
                </IconButton>
                {preguntasTabla ?
                    <Button color='primary' variant="contained" onClick={() => onAddQuestion(valRow)}>Gestionar preguntas</Button>
                    : ''
                }
            </div>
        )
    };

    render() {
        const {
            labelTable,
            columnsTable,
            columnField,
            data
        } = this.props;
        //  let fechaCreacion = false;     
        //    fechaCreacion = botsData.fecha_creacion ? moment(botsData.fecha_creacion.toDate()).format("ll, h:mm:ss a") : false;
        // console.log("botsData", botsData?.fecha_creacion?.seconds)-
        return ( 
            <div className="App home-container">
                 
                {this.state.modalEliminar && <ComponentEliminar
                    handlerAceptar={()=> this.onDelete(this.state.valRow)}
                    value={this.state.valRow} 
                    handlerCancelar={this.onModalElimarClose}
                    />
                }
                <div className="tabla-crear-pregunta" style={{"margin":"20px"}}>
                    <h2>{labelTable}</h2>
                </div>

                <DataTable style={{ width: '850px', height: 'auto' }} value={data}
                first={this.state.first}
                rows={this.state.rows} paginator rowsPerPageOptions={[5, 10, 20]}
                >
                    <Column field={columnField.columna1} header={columnsTable.columna1} sortable style={{ width: 'auto' }}></Column>
                    <Column field={columnField.columna2} header={columnsTable.columna2} sortable style={{ width: 'auto' }}></Column>
                    <Column header="Acciones" body={(rowData) => this.actionButtons(rowData)} sortable style={{ width: 'auto' }}></Column>
                </DataTable>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsChatBot: getMenuItemsChatBot(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenuChatbot: (value) => dispatch({ type: LOAD_MENU, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentTable);