import { getFormValues, } from 'redux-form'
import { FORM_ENVIOS_NAME } from "./constants";
export const getSelectedReduxFormEnvios = (state) => getFormValues(FORM_ENVIOS_NAME)(state);
export const getEnviosForm = (state) => state.localReducer.enviosForm;
export const getSelectedForm = (state) => state.localReducer.selectedForm;
export const getEnvios = (state) => state.localReducer.envios;
export const getUsuarios = (state) => state.localReducer.usuarios;
export const getFormFiles = (state) => state.localReducer.formFiles;
export const getVerLista = (state) => state.localReducer.verLista;
export const getCsv = (state) => state.localReducer.csv;
export const getTotalCsv = (state) => state.localReducer.totalCsv;
export const getEnvio = (state) => state.localReducer.envio;
export const ocultarDialogo = (state) => state.localReducer.ocultarDialogo;
export const toastDetail = (state) => state.localReducer.toastDetail;
export const toastMensaje = (state) => state.localReducer.toastMensaje;
export const updateTable = (state) => state.localReducer.updateTable;
export const totalEnviados = (state) => state.localReducer.totalEnviados;
export const totalLeidos = (state) => state.localReducer.totalLeidos;
export const totalNoLeidos = (state) => state.localReducer.totalNoLeidos;
export const porcentajeLeidos = (state) => state.localReducer.porcentajeLeidos;
export const getNumeroLeads = (state) => state.localReducer.numeroLeads;
export const getPorcentajeLeads = (state) => state.localReducer.porcentajeLeads;
export const getCamposPlantillaCombo = (state) => state.localReducer.opcionesPlantillaCombo;
export const getParametrosArchivo = (state) => state.localReducer.parametrosArchivo;
export const archivoSeleccionado = (state) => state.localReducer.archivoSeleccionado;
export const getPromedioLectura = (state) => state.localReducer.promedioLectura;
export const getSpinnerDestinatarios = (state) => state.localReducer.loadingUsuarios;
export const getNumFailed = (state) => state.localReducer.numFailed;
export const getSpinnerArchivo = (state) => state.localReducer.loadingArchivo;
export const getTotalDestinatarios = (state) => state.localReducer.totalDestinatarios;
export const getBusquedaActiva = (state) => state.localReducer.busquedaActivada;
export const getListaCompletaDestinatariosFiltrados = (state) => state.localReducer.listaFiltradaCompleta;
export const getTotalLeidosEnvio = (state) => state.localReducer.totalLeidosEnvio;
export const getEnviosApi = (state) => state.localReducer.enviosApi;
export const getEnviosApiPaginados = (state) => state.localReducer.enviosApiPaginados;
export const getConsultaPaginada = (state) => state.localReducer.consultaPaginada;
export const getlistaEnviosApiFiltrada = (state) => state.localReducer.listaEnviosApiFiltrada;
export const getFiltroEnviosActivo = (state) => state.localReducer.busquedaEnviosApiActiva;
export const getDataCsvDestinatarios = (state) => state.localReducer.dataCsvDestinatarios;
export const getLeadsPorEnvio = (state) => state.localReducer.leadsPorEnvio;
export const getConvDataEnvio = (state) => state.localReducer.convDataEnvio;
export const getOpenModalEnvioDetail = (state) => state.localReducer.openModalEnvioDetail;