import { MANAGE_SPINER } from '../adminPanel/constants';
import {
  UPDATE_FIELD_ATTRIBUTE,
  MOSTRAR_RANGOS,
  OCULTAR_RANGOS,
  DATA_LINECHART_SUCCESS,
  TMPESP_PROM_SUCCESS,
  TMPRESP_PROM_SUCCESS,
  TMPCONV_PROM_SUCCESS,
  CONVS_RESUELTAS_SUCCESS,
  CONVS_CANAL_SUCCESS,
  CONVS_CERRADAS_SUCCESS,

  LISTAR_ESTADOS_SUCCESS,
  LISTAR_AGENTES_SUCCESS,
  FILTRAR_BUSQUEDA_SUCCESS,
  SELECT_CONVERSATIONDETAIL_SUCCESS,

  OCULTAR_MODAL_DESCARGAR,

  MODAL_DESCARGAR_DATOS_SUCCESS,
  GRAFICOS_TEMAS_SUCCESS,
  SINGLE_BUSQUEDA_SUCCESS,
  LIMPIAR_FORM,
  TOAST_MESSAGE,
  CONVS_ABANDONADAS_SUCCESS,
  OCULTAR_ESTADISTICAS_SUCCESS,
  SELECCION_FORM_ESTADISTICA,
  CONVS_AGENTES_SUCCESS,
  TABLE_AGENTES_SUCCESS,
  CONVS_NOTAS_OCULTAS_SUCCESS,
  CONVS_CONECTED_USERS_SUCCESS,
  CONVS_TRANSFERENCIAS_SUCCESS,
  CONVS_CHATBOT_SUCCESS,
  SAVE_DATA_NOTAS_OCULTAS,
  SAVE_DATA_TRANSFERENCIAS,
  MODAL_ESPERA_DATA,
  CONVS_COLAS_ATENCION_SUCCESS,
  CONVS_ATENDIDAS_CHATBOT_SUCCESS,
  PARAMETER_URL,
  GRAFICA_QUERY_SENTIMENT_SUCCESS,
  GRAFICA_ENCUESTA_GENERAL_SUCCESS,
  FILTRAR_RANGOS_FECHA,
  PAGINAR_CONVS_CERRADAS_SUCCESS,
  PAGINAR_CONVS_CERRADAS,
  DESCARGAR_CSV_CONVS_CERRADAS_SUCCESS,
  REPORTE_WS_FALLIDOS_SUCCESS,
  DATOS_CONVS_CHATBOT_SUCCESS,
  PAGINAR_DATA_CONVS_CHABOT_SUCCESS,
  PAGINAR_DATA_CONVS_CHABOT,
  DATOS_RESP_UNKNOWN_BOT_SUCCESS,
  PAGINAR_DATA_UNKNOWNRESP_BOT_SUCCESS,
  PAGINAR_DATA_UNKNOWNRESP_BOT,
  PAGINAR_COMENTARIOS_INSATISFACCION,
  PAGINAR_COMENTARIOS_INSATISFACCION_SUCCESS,
  DATOS_COMENTARIOS_INSATISFACCION_SUCCESS,
  DATOS_INTENTS_PORCENTAJE_SUCCESS,
  GRAFICA_TASA_COINCIDENCIAS_SUCCESS,
  GENERAR_DATA_CSV_SUCCESS,
  DATOS_GRAFICA_TOP_10_SUCCESS,
  PAGINAR_DATA_CONVS_PRESUPUESTOS,
  PAGINAR_DATA_CONVS_PRESUPUESTOS_SUCCESS,
  DATOS_CONVS_PRESUPUESTOS_SUCCESS,
  TMP_PROM_AGENTES_SUCCESS,
  CONVS_RESUELTAS_AGENTES_SUCCESS,
  GRAFICO_PROM_ESP_AGENTES_SUCCESS,
  GRAFICO_PROM_RESP_AGENTES_SUCCESS,
  TABLES_PROM_AGENT,
  SEND_TABLA_DATOS_REPORTE_HUECOS,
  SEND_GRAFICO_DATOS_REPORTE_HUECOS,
  SEND_GRAFICO_DATOS_REPORTE_HUECOS_SUCCESS,
  CSV_CONVS_CHATBOT,
  CSV_DATA_UNKNOWN_BOT,
  CSV_DATA_INSATISFACCION,
  SEND_TABLA_DATOS_ZONA_PARQUEO,
  SEND_TABLA_DATOS_REGISTRO_GAB,
  SEND_TABLA_DATOS_ATENCION_CANAL,
  SEND_TABLA_DATOS_ATENCION_AGENTE,
  ENCUESTAS_AGENTES_SUCESS,
  TABLE_AGENTES_ERROR,
  CONVS_RESUELTAS_AGENTES_ERROR,
  ENCUESTAS_AGENTES_ERROR,
  CONVS_CERRADAS_AGENTE,
  CONVS_CERRADAS_AGENTE_SUCCESS,
  PAGINAR_CONVS_CERRADAS_AGENTES_SUCCESS,
  PAGINAR_CONVS_CERRADAS_AGENTES,
  PAGINAR_CONVS_CERRADAS_AGENTES_ERROR,
  DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS,
  FILTRAR_BUSQUEDA_AGENTE_SUCCESS,
  SAVE_SEARCH_BIGQUERY_TABLE,
  SAVE_SEARCH_TABLE_GENERALES





} from './constants';

export const initialState = {

  selectedForm: {},
  rangosForm: {},
  mostrarRangosFecha: false,
  //--------grafica---------------
  convsCerradas: false,

  totalConvsCerradas: false,
  tmpPromEsp: false,
  tmpPromResp: false,
  tmpPromConv: false,
  convsResueltas: false,
  convsAbandonadas: false,
  canalConv: false,
  ocultarGrafica: false,
  dataCsvPie: false,

  conversacionesCerradas: false,
  totalConversacionesCerradas: false,
  conversacionesFiltradas: false,

  pagination: false,

  page: 0,

  estadosDisplay: false,
  objEstados: false,
  agentesDisplay: false,
  objAgentes: false,
  mensajes: false,

  dataConv: false,
  convsTemaNivel1: false,
  convsTemaNivel2: false,
  convsTemaNivel3: false,
  toastMensaje: false,
  toastDetalle: false,

  verModalDetalleMensajes: false,
  verModalDescargarConversacion: false,
  columnsTable: false,

  showEstGeneralesComponent: false,
  showEstTipificacionComponent: false,
  showEstAgentesComponent: false,
  showEstAbandonosComponent: false,
  showEstNotasComponent: false,
  showEstTransferenciasComponent: false,
  showEstConectedUsersComponent: false,
  showEstChatbotComponent: false,
  showOtrosInformesComponent: false,
  showServiciosExternosComponent: false,
  showEstColasAtencionComponent: false,
  convsAgentes: false,
  convsColasAtencion: false,
  dataTableNotasOcultas: false,
  dataNotasOcultas: [],
  dataTransferencias: [],
  dataTableTransferencias: false,
  dataTableConectedUsers: false,
  dataTableChatbot: false,
  dataTableAgentes: false,
  openModal: false,
  convsAtendidasChatBot: false,
  parameterUrl: false,
  searchBigqueryTable: false,
  dataSentimentalAnalysis: false,
  dataCalificacionEncuesta: false,
  btnFiltrar: false,
  convsCerradasPaginadas: false,
  consultaPaginada: false,
  totalConvsFiltradas: false,
  busquedaActiva: false,
  convsCerradasConsultaCsv: false,
  searchTableGeneral:false,
  dataTableWsFallidos: false,
  datosTablaConvsChatbot: false,
  datosTablaConvsChatbotPaginados: false,
  datosTablaConvsPresupuesto: false,
  datosTablaConvsPresupuestoPaginados: false,
  datosTablaDataUnknown: false,
  datosTablaDataUnknownPaginados: false,
  dataIntentsPorcentajes: false,
  datosComentariosInsatisfaccion: false,
  datosComentariosInsatisfaccionPaginados: false,
  dataGraficaDataCoincidencia: false,
  dataTop10Intents: false,
  datosPieIntentsAgrupados: false,
  tmpPromConvsAgente: false,
  convsResueltasAgentes: false,
  dataBarTmpRespAgentes: false,
  dataBarTmpEspAgentes: false,
  dateTableProm: {
    tableResAgent: [],
    tableEspeAgentes: [],
  },
  dataReporteHuecos: false,
  graficoReporteHuecos: false,
  dataCsvConvsBot: false,
  dataCsvUnknownBot: false,
  dataCsvInsatisfaccion: false,
  dataZonaParqueo: false,
  encuestaAgente: false,
  hayBot: false,
  dataAgrupadosCsv: false,
  arrayCsvConversacionesCerradas: false,
  busquedaTablasGenerales: false,
  manageSpinerState: {},
  showSnackBar: false,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;
  const { convsCerradas, convsCerradasDisplay, columns } = payload ? payload : {};
  //newState.verModalDescargarConversacion = false;
  newState.toastMensaje = false;
  newState.btnFiltrar = false;
  newState.consultaPaginada = true;



  switch (type) {


    case UPDATE_FIELD_ATTRIBUTE:
      let { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;
      newState.selectedForm = newFormObject;
      newState.message = false;

      return {
        ...newState
      };

    case LIMPIAR_FORM:
      newState.selectedForm.dateIni = "";
      newState.selectedForm.dateFin = "";
      newState.selectedForm.agente = "";
      newState.selectedForm.texto = "";
      newState.selectedForm.idConversacion = "";
      newState.selectedForm.cleaning = false;
      newState.totalConvsFiltradas = false;


      return {
        ...newState
      };

    case CONVS_CERRADAS_SUCCESS:

      /* newState.conversacionesCerradas = convsCerradasDisplay; */
      newState.conversacionesFiltradas = convsCerradasDisplay;
      newState.columnsTable = columns;

      return {
        ...newState
      };
    case CONVS_CERRADAS_AGENTE_SUCCESS:

      /* newState.conversacionesCerradas = convsCerradasDisplay; */
      newState.conversacionesFiltradas = convsCerradasDisplay;
      newState.columnsTable = columns;

      return {
        ...newState
      };


    case CONVS_ATENDIDAS_CHATBOT_SUCCESS:
      newState.convsAtendidasChatBot = payload;
      return {
        ...newState
      }

    case PARAMETER_URL:

      newState.parameterUrl = value.type;
      return {
        ...newState
      }

    case SAVE_SEARCH_BIGQUERY_TABLE:
      newState.searchBigqueryTable = payload;
      return {
        ...newState
      }

    case FILTRAR_BUSQUEDA_SUCCESS:
      //newState.conversacionesCerradas = convsCerradasDisplay;
      newState.conversacionesFiltradas = payload.convsCerradasDisplay;
      newState.totalConvsFiltradas = payload.listaFiltradaCompleta;
      newState.columnsTable = payload.columns;
      newState.busquedaActiva = true;
      newState.convsCerradasPaginadas = false


      return {
        ...newState
      };
    case FILTRAR_BUSQUEDA_AGENTE_SUCCESS:
      //newState.conversacionesCerradas = convsCerradasDisplay;
      newState.conversacionesFiltradas = payload.convsCerradasDisplay;
      newState.totalConvsFiltradas = payload.listaFiltradaCompleta;
      newState.busquedaActiva = true;
      newState.convsCerradasPaginadas = false
      newState.hayBot = true


      return {
        ...newState
      };



    case SINGLE_BUSQUEDA_SUCCESS:
      newState.conversacionesCerradas = convsCerradasDisplay;
      newState.conversacionesFiltradas = convsCerradasDisplay;

      return {
        ...newState
      }

    case CONVS_ABANDONADAS_SUCCESS:
      newState.convsAbandonadas = payload;

      return {
        ...newState
      }

    case CONVS_AGENTES_SUCCESS:
      newState.convsAgentes = payload;
      newState.openModal = false;

      return { ...newState }

    case CONVS_COLAS_ATENCION_SUCCESS:
      newState.convsColasAtencion = payload;
      newState.openModal = false;

      return { ...newState }

    case CONVS_NOTAS_OCULTAS_SUCCESS:
      newState.dataTableNotasOcultas = payload;
      newState.openModal = false;

      return { ...newState }

    case SAVE_DATA_NOTAS_OCULTAS:
      newState.dataNotasOcultas = payload;
      return { ...newState }

    case SAVE_DATA_TRANSFERENCIAS:
      newState.dataTransferencias = payload;
      return { ...newState }

    case MODAL_ESPERA_DATA:
      newState.openModal = payload;
      return { ...newState }



    case CONVS_TRANSFERENCIAS_SUCCESS:
      newState.dataTableTransferencias = payload;
      newState.openModal = false;

      return { ...newState }

    case CONVS_CONECTED_USERS_SUCCESS:
      newState.dataTableConectedUsers = payload;
      newState.openModal = false;

      return { ...newState }

    case CONVS_CHATBOT_SUCCESS:
      newState.dataTableChatbot = payload;
      newState.openModal = false;


      return { ...newState }

    case TABLE_AGENTES_SUCCESS:
      newState.dataTableAgentes = payload;
      return {
        ...newState
      }
    case TABLE_AGENTES_ERROR:
      newState.dataTableAgentes = false;
      return {
        ...newState
      }

    case OCULTAR_ESTADISTICAS_SUCCESS:
      newState.ocultarGrafica = payload;
      return {
        ...newState
      }



    case TOAST_MESSAGE:
      newState.toastMensaje = true;

      return {
        ...newState
      }


    case MOSTRAR_RANGOS:
      newState.mostrarRangosFecha = true;

      return {
        ...newState
      };

    case OCULTAR_RANGOS:
      newState.mostrarRangosFecha = false;

      return {
        ...newState
      };
    case DATA_LINECHART_SUCCESS:
      newState.openModal = false;
      newState.convsCerradas = payload.payload;
      newState.totalConvsCerradas = payload.size;
      return {
        ...newState
      };
    case TMPESP_PROM_SUCCESS:
      newState.tmpPromEsp = payload;


      return {
        ...newState
      };

    case TMPRESP_PROM_SUCCESS:
      newState.tmpPromResp = payload;

      return {
        ...newState
      };

    case TMPCONV_PROM_SUCCESS:
      newState.tmpPromConv = payload;
      return {
        ...newState
      };
    case CONVS_RESUELTAS_SUCCESS:
      newState.convsResueltas = payload;
      return {
        ...newState
      };

    case CONVS_CANAL_SUCCESS:
      newState.canalConv = payload;
      return {
        ...newState
      };

    case LISTAR_ESTADOS_SUCCESS:
      newState.objEstados = payload.objEstados;
      newState.estadosDisplay = payload.estadosDisplay;
      return {
        ...newState
      };
    case LISTAR_AGENTES_SUCCESS:
      newState.objAgentes = payload.objAgentes;
      newState.agentesDisplay = payload.agentesDisplay;
      return {
        ...newState
      };

    case SELECT_CONVERSATIONDETAIL_SUCCESS:

      newState.mensajes = payload.conversation;

      return {
        ...newState
      }

    case OCULTAR_MODAL_DESCARGAR:
      newState.verModalDescargarConversacion = false;

      return {
        ...newState
      }

    case GRAFICA_QUERY_SENTIMENT_SUCCESS:

      newState.dataSentimentalAnalysis = payload


      return {
        ...newState
      }

    case MODAL_DESCARGAR_DATOS_SUCCESS:
      newState.verModalDescargarConversacion = payload.verModalDescargarConversacion;
      newState.mensajes = payload.conversation;
      newState.dataConv = payload.dataInfo;


      return {
        ...newState
      }

    case GRAFICOS_TEMAS_SUCCESS:
      newState.convsTemaNivel1 = payload.graficaNivel1;
      newState.convsTemaNivel2 = payload.graficaNivel2;
      newState.convsTemaNivel3 = payload.graficaNivel3;
      newState.openModal = false;
      return {
        ...newState
      }

    case SELECCION_FORM_ESTADISTICA:
      if (value === 'general') {
        newState.showEstGeneralesComponent = true;
        newState.showEstTipificacionComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;

      } else if (value === 'tipificacion') {
        newState.showEstTipificacionComponent = true;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;

      } else if (value === 'agentes') {
        newState.showEstAgentesComponent = true;
        newState.showEstGeneralesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;

      } else if (value === 'convsAbandono') {
        newState.showEstAbandonosComponent = true;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;

      } else if (value === 'servExterno') {
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = true;

      } else if (value === 'notasOcultas') {
        newState.showEstNotasComponent = true;
        newState.showEstAgentesComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstConectedUsersComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;

      } else if (value === 'accesosUsuarios') {
        newState.showEstConectedUsersComponent = true;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;
      }
      else if (value === 'transferencias') {
        newState.showEstConectedUsersComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = true;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;
      }
      else if (value === 'chatBot') {
        newState.showEstConectedUsersComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = true;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;
      }
      else if (value === 'colaAtencion') {
        newState.showEstConectedUsersComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = true;
        newState.showServiciosExternosComponent = false;
      }
      else if (value === 'masInformes') {
        newState.showEstConectedUsersComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = true;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = false;
      }
      else if (value === 'servExterno') {
        newState.showEstConectedUsersComponent = false;
        newState.showEstAbandonosComponent = false;
        newState.showEstGeneralesComponent = false;
        newState.showEstAgentesComponent = false;
        newState.showEstTipificacionComponent = false;
        newState.showEstNotasComponent = false;
        newState.showEstTransferenciasComponent = false;
        newState.showEstChatbotComponent = false;
        newState.showOtrosInformesComponent = false;
        newState.showEstColasAtencionComponent = false;
        newState.showServiciosExternosComponent = true;

      }
    case GRAFICA_ENCUESTA_GENERAL_SUCCESS:
      newState.dataCalificacionEncuesta = payload;

      return {
        ...newState
      }

    case FILTRAR_RANGOS_FECHA:
      newState.btnFiltrar = true;

      return {
        ...newState
      }

    case PAGINAR_CONVS_CERRADAS:
      newState.consultaPaginada = false;
      return {
        ...newState
      }


    case PAGINAR_CONVS_CERRADAS_SUCCESS:
      newState.convsCerradasPaginadas = payload.convsCerradasPaginadas
      if (payload.esPrimeraPagina) {
        newState.convsCerradasPaginadas = convsCerradasDisplay;
      } else {
        newState.convsCerradasPaginadas = payload.convsCerradasPaginadas;
      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case DESCARGAR_CSV_CONVS_CERRADAS_SUCCESS:
      newState.convsCerradasConsultaCsv = payload;

      return {
        ...newState
      }

    case DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS:
      newState.convsCerradasConsultaCsv = payload;

      return {
        ...newState
      }


    case REPORTE_WS_FALLIDOS_SUCCESS:
      newState.dataTableWsFallidos = payload.dataTableWsFallidos

      return {
        ...newState
      }

    case SEND_TABLA_DATOS_REPORTE_HUECOS:
      newState.dataReporteHuecos = payload

      return {
        ...newState
      }
    case SEND_GRAFICO_DATOS_REPORTE_HUECOS:
      newState.graficoReporteHuecos = payload

      return {
        ...newState
      }

    case DATOS_CONVS_CHATBOT_SUCCESS:
      newState.datosTablaConvsChatbot = payload.datosTablaConvsChatBot

      return {
        ...newState
      }

    case SEND_TABLA_DATOS_REPORTE_HUECOS:
      newState.dataReporteHuecos = payload

      return { ...newState }


    case PAGINAR_DATA_CONVS_CHABOT:
      newState.consultaPaginada = false;
      return {
        ...newState
      }

    case PAGINAR_DATA_CONVS_CHABOT_SUCCESS:

      newState.datosTablaConvsChatbotPaginados = payload.dataPaginada;

      if (payload.esPrimeraPagina) {
        newState.datosTablaConvsChatbotPaginados = newState.datosTablaConvsChatbot
      } else {
        newState.datosTablaConvsChatbotPaginados = payload.dataPaginada;
      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case DATOS_CONVS_PRESUPUESTOS_SUCCESS:

      newState.datosTablaConvsPresupuesto = payload;

      return {
        ...newState
      }

    case PAGINAR_DATA_CONVS_PRESUPUESTOS:
      newState.consultaPaginada = false;
      return {
        ...newState
      }

    case PAGINAR_DATA_CONVS_PRESUPUESTOS_SUCCESS:

      newState.datosTablaConvsPresupuestoPaginados = payload.dataPaginada;

      if (payload.esPrimeraPagina) {

        newState.datosTablaConvsPresupuestoPaginados = newState.datosTablaConvsPresupuesto
      } else {
        newState.datosTablaConvsPresupuestoPaginados = payload.dataPaginada;
      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case DATOS_RESP_UNKNOWN_BOT_SUCCESS:

      newState.datosTablaDataUnknown = payload.datosRespBot;

      return {
        ...newState
      }

    case PAGINAR_DATA_UNKNOWNRESP_BOT:
      newState.consultaPaginada = false;

      return {
        ...newState
      }

    case PAGINAR_DATA_UNKNOWNRESP_BOT_SUCCESS:

      newState.datosTablaDataUnknownPaginados = payload.dataPaginada;

      if (payload.esPrimeraPagina) {
        newState.datosTablaDataUnknownPaginados = newState.datosTablaDataUnknown;
      } else {
        newState.datosTablaDataUnknownPaginados = payload.dataPaginada;

      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case DATOS_INTENTS_PORCENTAJE_SUCCESS:
      newState.dataIntentsPorcentajes = payload.dataIntent;
      return {
        ...newState
      }

    case DATOS_COMENTARIOS_INSATISFACCION_SUCCESS:
      newState.datosComentariosInsatisfaccion = payload.comentariosInsatisfaccion;
      return {
        ...newState
      }
    case PAGINAR_COMENTARIOS_INSATISFACCION_SUCCESS:
      newState.datosComentariosInsatisfaccionPaginados = payload.dataPaginada
      if (payload.esPrimeraPagina) {

        newState.datosComentariosInsatisfaccionPaginados = newState.datosComentariosInsatisfaccion;
      } else {
        newState.datosComentariosInsatisfaccionPaginados = payload.dataPaginada;

      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case PAGINAR_COMENTARIOS_INSATISFACCION:
      newState.consultaPaginada = false;

      return {
        ...newState
      }

    case GRAFICA_TASA_COINCIDENCIAS_SUCCESS:
      newState.dataGraficaDataCoincidencia = payload;
      newState.openModal = false;


      return {
        ...newState
      }


    case CSV_CONVS_CHATBOT:
      newState.dataCsvConvsBot = payload.dataGenerada

      return {
        ...newState
      }
    case CSV_DATA_UNKNOWN_BOT:
      newState.dataCsvUnknownBot = payload.dataGenerada

      return {
        ...newState
      }
    case CSV_DATA_INSATISFACCION:
      newState.dataCsvInsatisfaccion = payload.dataGenerada

      return {
        ...newState
      }

    case DATOS_GRAFICA_TOP_10_SUCCESS:
      newState.dataTop10Intents = payload.pie;
      newState.datosPieIntentsAgrupados = payload.datosPieIntentsAgrupados
      newState.dataCsvTop10 = payload.datosPieCsv;
      newState.dataAgrupadosCsv = payload.datosAgrupadoCsv;


      // console.log("informes panel reducer", newState.dataAgrupadosCsv)
      return {
        ...newState
      }
    case TMP_PROM_AGENTES_SUCCESS:
      newState.tmpPromConvsAgente = payload

      return {
        ...newState
      }

    case CONVS_RESUELTAS_AGENTES_SUCCESS:
      newState.convsResueltasAgentes = payload

      return {
        ...newState
      }
    case CONVS_RESUELTAS_AGENTES_ERROR:
      newState.convsResueltasAgentes = false

      return {
        ...newState
      }

    case GRAFICO_PROM_ESP_AGENTES_SUCCESS:
      newState.dataBarTmpEspAgentes = payload
      newState.openModal = false;

      return {
        ...newState
      }

    case GRAFICO_PROM_RESP_AGENTES_SUCCESS:
      newState.dataBarTmpRespAgentes = payload

      return {
        ...newState
      }

    case TABLES_PROM_AGENT:
      newState.dateTableProm[payload.field] = payload.data

      return { ...newState }

    case SEND_TABLA_DATOS_ZONA_PARQUEO:
      newState.dataZonaParqueo = payload

      return {
        ...newState
      }
    case SEND_TABLA_DATOS_REGISTRO_GAB:
      newState.dataRegistroGAB = payload

      return {
        ...newState
      }
    case SEND_TABLA_DATOS_ATENCION_CANAL:
      newState.dataRegistroAtencionCanal = payload

      return {
        ...newState
      }
    case SEND_TABLA_DATOS_ATENCION_AGENTE:
      newState.dataRegistroAtencionAgente = payload

      return {
        ...newState
      }

    case ENCUESTAS_AGENTES_SUCESS:
      newState.encuestaAgente = payload

      return {
        ...newState
      }
    case ENCUESTAS_AGENTES_ERROR:
      newState.encuestaAgente = false

      return {
        ...newState
      }

    case PAGINAR_CONVS_CERRADAS_AGENTES_SUCCESS:
      newState.convsCerradasPaginadas = payload.convsCerradasPaginadas
      if (payload.esPrimeraPagina) {
        newState.convsCerradasPaginadas = convsCerradasDisplay;
      } else {
        newState.convsCerradasPaginadas = payload.convsCerradasPaginadas;
      }
      newState.consultaPaginada = true;

      return {
        ...newState
      }

    case PAGINAR_CONVS_CERRADAS_AGENTES:
      newState.consultaPaginada = false;
      return {
        ...newState
      }
    case PAGINAR_CONVS_CERRADAS_AGENTES_ERROR:
      newState.consultaPaginada = false;
      return {
        ...newState
      }

    case DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS:
      newState.convsCerradasConsultaCsv = payload;

      return {
        ...newState
      }

    case SAVE_SEARCH_TABLE_GENERALES:
      newState.searchTableGeneral = payload;

      return {
        ...newState
      }

      return {
        ...newState
      }

      case MANAGE_SPINER:
        newState.manageSpinerState[payload.key] = payload.state;  
        newState.showSnackBar = !newState.showSnackBar;

        return {
            ...newState
        }


    default:
      return {
        ...newState
      };
  }

}
export default reducer;