import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { menuItemsOCR } from "../../../Constants";
import { Box, Tabs, Tab } from "@material-ui/core";
import SideBar from "../../../components/sidebar/sidebar";
import ComponentDocumentosFallidos from "./documentosFallidos";
import ComponentDocumentosCruzados from "../cruce/componenteCruce";
import ComponentDocumentosCargados from "./documentosProcesados";
import ComponentMisDocumentosCruzados from "./documentosCruzados";


import { CROSS_DOC, DOWNLOAD_DOC, GET_DOC_PROC, GET_OCR_DOC, SHOW_DOC_PROC } from "./constants";
import { getFromSession } from "../../../controller/session";
import { selectorLoadingCircular, selectorProccessDocument, selectorShowCross, selectorShowDocument, selectordocumentData } from "./selectors";
import CircularProgress from "@material-ui/core/CircularProgress";
import PDFViewer from "./ocrTable/vistaPreviaDoc";
import { Button } from "@material-ui/core";
import { COMPANY_DATA, GET_DOC_FALL } from "./constants";
import { Paper } from "@material-ui/core";
import { getDataCompanySelector, selectorFailDocument } from "./selectors";


class ComponentMisDocumentos extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, tipoDoc } = this.props;
        const renderDoc = tipoDoc ? 'fallido' : 'procesados'
        this.state = {
            activePath: '/',
            tipo: renderDoc,
            pdfData: false,
            dataTableProcesados: false,
            dataTableFallidos: false,
            cruzarDocumento: false,
            error: false,
        }

    }
    handlerClose = () => {
        this.setState({ pdfData: false });

    }

    handlerCloseCruce = () => {
        this.setState({ cruzarDocumento: false });

    }

    selectTab = (e, newValue) => {
        this.setState({ tipo: newValue });
    }

    componentWillMount() {
        const { getDocumentosProc,
            getDocumentos,
            companyData,
            cargarDocumento,
            getFailDocument,
            proccessDocumentSelector,
            getDocumentosFallidos,
            documentosProcesadosSelector,
        } = this.props;
        //getOCRDocumentos();

        companyData();
        if (cargarDocumento) {
            console.log("DOC PROPS")
            getDocumentos();
            getFailDocument();
            this.setState({ dataTableProcesados: documentosProcesadosSelector });



        } else {
            console.log("DOC SIDE BAR")
            getDocumentosProc();
            getDocumentosFallidos();
            this.setState({ dataTableProcesados: proccessDocumentSelector });
            //console.log("DOC SIDE BAR selector", proccessDocumentSelector)
        }


        //console.log("data procesados", this.state.dataTableProcesados)
        // console.log("proccessDocumentSelector", proccessDocumentSelector)
        //console.log("documentosProcesadosSelector", documentosProcesadosSelector)

    }



    componentDidUpdate(prevProps) {
        const {
            proccessDocumentSelector,
            documentosProcesadosSelector,
            failDocumentSelector,
            failSelector,
            nombreSelector,
            showDocument,
            getFailDocument,
            showDocumentSelector,
            getDocumentos,
            getDocumentosProc,
            getDocumentosFallidos
        } = this.props;
        // if (prevProps.documentosProcesadosSelector !== documentosProcesadosSelector) {
        //     getDocumentos()
        //     console.log("documentosProcesadosSelector PROPS", documentosProcesadosSelector)
        //     this.setState({ dataTableProcesados: documentosProcesadosSelector });
        // } else if (prevProps.proccessDocumentSelector !== proccessDocumentSelector) {
        //     getDocumentosProc()
        //     console.log("documentosProcesadosSelector SIDE BAR", proccessDocumentSelector)
        //     this.setState({ dataTableProcesados: proccessDocumentSelector });
        // }

        // if (prevProps.failDocumentSelector !== failDocumentSelector) {
        //     getFailDocument()
        //     this.setState({ dataTableFallidos: failDocumentSelector });
        // } else if (prevProps.failSelector !== failSelector) {
        //     getDocumentosFallidos()
        //     this.setState({ dataTableFallidos: failSelector });
        // }
        // if (prevProps.nombreSelector !== nombreSelector) {
        //     //  console.log("PREVPROPS nombreSelector")
        // }

    }


    pdfData = (newData) => {
        this.setState({ pdfData: newData });
    }

    onModalCancelError = () => {
        this.setState({ error: false });
    }
    onCerrarCruce = () => {
        this.setState({ cruzarDocumento: false });

    }

    cruceData = (newData) => {
        //showDocument
        const {
            crossDocumentSelector,
            crossDoc,
            cargarDocumento,
            cruzarDoc
        } = this.props;
        if (cargarDocumento) {
            console.log("IF CUCRE DATA")
            crossDoc(newData)
        } else {
            console.log("ELSE CUCRE DATA")
            cruzarDoc(newData)
        }
        // console.log("crossDocumentSelector",crossDocumentSelector)
        // if (!crossDocumentSelector) {
        //     this.setState({ error: true });
        // } else {
        // }
        this.setState({ cruzarDocumento: newData.name });
    }


    render() {
        const {
            mostrarDocumentos,
            showDocumentSelector,
            loading,
            download,
            failDocumentSelector,
            loadingCircular,
            cargarDocumento,
            crossDocumentSelector,
            volverADocPro,
            mostrarDocumentosProcesados,
            crossDoc,
            downloadDoc,
            showDocument,
            selectorDataCompany,
            showCrossSelector,
            proccessDocumentSelector,
            documentosProcesadosSelector,
            showCrossDocumentSelector,
            loadingProc,
            selectorDatosDocPrincipal,
            cruzarDoc,
            loadingFall,
            getFailDocument
        } = this.props;
        const user = getFromSession("company_id")
        return (
            <>
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}
                <>

                    {cargarDocumento ?
                        <>

                            {/*
                            {!this.state.dataTableProcesados &&

                                <div className="loading-wrapper">
                                    <CircularProgress color="secondary"></CircularProgress>
                                    </div>
                                    
                                }
                            */}
                            {/* 
                            */}
                            {/* {loading &&

                                <div className="loading-wrapper">
                                <CircularProgress color="secondary"></CircularProgress>
                                </div>
                                
                            } */}
                            {/* {loadingCircular &&

                            <div className="loading-wrapper">
                            <CircularProgress color="secondary"></CircularProgress>
                            </div>

                            } */}

                            {/* {loadingProc &&

                                <div className="loading-wrapper">
                                    <CircularProgress color="secondary"></CircularProgress>
                                </div>

                            }  */}


                            {this.state.cruzarDocumento ?
                                <>

                                    {/* 
                                <div className="button-ocr-right">
                            <Button onClick={() => onCerrarCruce()} color='primary' variant="contained" >Documentos procesadoooos</Button>
                            </div> 
                            */}
                                    {/* <h1>
                                        DOCUMENTOS PROCESADOS IMAGEN
                                    </h1> */}
                                    <ComponentDocumentosCruzados
                                    selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                        onCerrarCruce={this.onCerrarCruce}
                                        mostrarDocumentos={mostrarDocumentos}
                                        cruzarDocu={crossDoc}
                                        showDocument={showDocumentSelector}
                                        showCrossDocumentSelector={showCrossDocumentSelector}
                                        handlerCloseCruce={this.handlerCloseCruce}
                                    />
                                </>
                                :
                                <>
                                    {this.state.pdfData ?
                                        <>
                                            <PDFViewer
                                                handlerClose={this.handlerClose}
                                                pdfUrl={this.state.pdfData}
                                                data={documentosProcesadosSelector}
                                                idDoc={cargarDocumento ? showDocumentSelector : showDocument}
                                            />


                                            {/* <div className="titulo-documentos">
                                    <Box sx={{ width: '100%' }}
                                        variant="fullWidth"
                                    >
                                        <Tabs className="tabs-container-ocr"
                                            value={this.state.tipo}
                                            style={{ display: 'initial' }}
                                            onChange={this.selectTab}
                                            centered
                                        >
                                            <Tab className="tab" value="procesados" style={{ "font-size": "24px", "font-weight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Procesados"

                                            />
                                            <Tab className="tab" value="fallido" style={{ "font-size": "24px", "font-weight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Fallidos"

                                            />
                                        </Tabs>
                                    </Box>
                                </div> 
                                
                                <div className="subs-containere">
                                {this.state.tipo === 'procesados' ?
                                <ComponentDocumentosCargados
                                cruzarDocumento={this.state.cruzarDocumento}
                                downloadDoc={downloadDoc}
                                            data={documentosProcesadosSelector}
                                            mostrarDocumentosProcesados={mostrarDocumentosProcesados}
                                            showDocumentSelector={showDocumentSelector}
                                            pdfData={this.pdfData}
                                            cruceData={this.cruceData}
                                            user={user} /> :
                                        <ComponentDocumentosFallidos
                                            data={failDocumentSelector} />
                                    }
                                </div>
                            */}
                                        </>
                                        :
                                        <>
                                            <div className="align-button-ocr">
                                                <div className="button-ocr-left">
                                                    <Button style={{ "marginTop": "10px" }} onClick={() => volverADocPro()} color='primary' variant="contained">Volver</Button>
                                                </div>
                                            </div>

                                            <div className="titulo-documentos">
                                                <Box sx={{ width: '100%' }}
                                                    variant="fullWidth"
                                                >
                                                    <Tabs className="tabs-container-ocr"
                                                        value={this.state.tipo}
                                                        style={{ display: 'initial' }}
                                                        onChange={this.selectTab}
                                                        centered
                                                    >
                                                        <Tab className="tab" value="procesados" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                            sx={{ width: 200 }}
                                                            label="Procesados"

                                                        />
                                                        <Tab className="tab" value="fallido" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                            sx={{ width: 200 }}
                                                            label="Fallidos"

                                                        />
                                                    </Tabs>
                                                </Box>
                                            </div>

                                            <div className="subs-containere">
                                                {this.state.tipo === 'procesados' ?
                                                    <>
                                                        {/* <h1>
                                                            IMAGE OCR
                                                        </h1> */}
                                                        <ComponentDocumentosCargados
                                                            crossDoc={crossDoc}
                                                            cruzarDocumento={this.state.dataTableProcesados}
                                                            downloadDoc={cargarDocumento ? download : downloadDoc}
                                                            data={documentosProcesadosSelector}
                                                            mostrarDocumentosProcesados={mostrarDocumentos}
                                                            showDocumentSelector={showDocumentSelector}
                                                            pdfData={this.pdfData}
                                                            cruceData={this.cruceData}
                                                            user={user} />
                                                    </>
                                                    :
                                                    <ComponentDocumentosFallidos
                                                        data={this.state.dataTableFallidos} />
                                                }
                                            </div>
                                        </>
                                    }
                                </>
                            }

                        </>
                        :
                        <Paper elevation={10} className="main-wrapper">

                            {/*
                        {!this.state.dataTableProcesados &&

                            <div className="loading-wrapper">
                                <CircularProgress color="secondary"></CircularProgress>
                                </div>
                                
                            }
                        */}
                            {/* 
                        */}
                            {/* {loading &&

                            <div className="loading-wrapper">
                            <CircularProgress color="secondary"></CircularProgress>
                            </div>
                            
                        } */}
                            {/* {loadingCircular &&

                    <div className="loading-wrapper">
                    <CircularProgress color="secondary"></CircularProgress>
                    </div>

                        } */}

                            {/* {loadingProc &&

                            <div className="loading-wrapper">
                                <CircularProgress color="secondary"></CircularProgress>
                            </div>

                        }  */}


                            {this.state.cruzarDocumento ?
                                <Paper elevation={10} className="main-wrapper home container">

                                    {/* <div className="button-ocr-right">
                            <Button onClick={() => this.handlerCloseCruce()} color='primary' variant="contained" >Documentos procesadoooos</Button>
                            </div> 
                                    <h1>
                                        DOCUMENTOS PROCESADOS MIS DOC
                                    </h1>
                                    */}
                                    <ComponentMisDocumentosCruzados
                                        user={user}
                                        pdfData={this.pdfData}
                                        selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                        onCerrarCruce={this.onCerrarCruce}
                                        mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                                        showDocument={showDocumentSelector}
                                        showCrossDocumentSelector={cargarDocumento ? showDocumentSelector : showCrossSelector}
                                        handlerCloseCruce={this.handlerCloseCruce}
                                    />
                                </Paper>
                                :
                                <>
                                    {this.state.pdfData ?
                                        <>
                                            <Paper elevation={10} className="main-wrapper home container">

                                                <PDFViewer
                                                    handlerClose={this.handlerClose}
                                                    pdfUrl={this.state.pdfData}
                                                    data={documentosProcesadosSelector}
                                                    idDoc={cargarDocumento ? showDocumentSelector : showDocument}
                                                />
                                            </Paper>

                                            {/* <div className="titulo-documentos">
                                <Box sx={{ width: '100%' }}
                                    variant="fullWidth"
                                >
                                    <Tabs className="tabs-container-ocr"
                                        value={this.state.tipo}
                                        style={{ display: 'initial' }}
                                        onChange={this.selectTab}
                                        centered
                                    >
                                        <Tab className="tab" value="procesados" style={{ "font-size": "24px", "font-weight": "bold" }}
                                            sx={{ width: 200 }}
                                            label="Procesados"

                                        />
                                        <Tab className="tab" value="fallido" style={{ "font-size": "24px", "font-weight": "bold" }}
                                            sx={{ width: 200 }}
                                            label="Fallidos"

                                        />
                                    </Tabs>
                                </Box>
                            </div> 
                            
                            <div className="subs-containere">
                            {this.state.tipo === 'procesados' ?
                            <ComponentDocumentosCargados
                            cruzarDocumento={this.state.cruzarDocumento}
                            downloadDoc={downloadDoc}
                                        data={documentosProcesadosSelector}
                                        mostrarDocumentosProcesados={mostrarDocumentosProcesados}
                                        showDocumentSelector={showDocumentSelector}
                                        pdfData={this.pdfData}
                                        cruceData={this.cruceData}
                                        user={user} /> :
                                    <ComponentDocumentosFallidos
                                        data={failDocumentSelector} />
                                }
                            </div>
                            */}
                                        </>
                                        :
                                        <Paper elevation={10} className="main-wrapper home container">
                                            <div className="titulo-documentos">
                                                <Box sx={{ width: '100%' }}
                                                    variant="fullWidth"
                                                >
                                                    <Tabs className="tabs-container-ocr"
                                                        value={this.state.tipo}
                                                        style={{ display: 'initial' }}
                                                        onChange={this.selectTab}
                                                        centered
                                                    >
                                                        <Tab className="tab" value="procesados" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                            sx={{ width: 200 }}
                                                            label="Procesados"

                                                        />
                                                        <Tab className="tab" value="fallido" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                            sx={{ width: 200 }}
                                                            label="Fallidos"

                                                        />
                                                    </Tabs>
                                                </Box>
                                            </div>

                                            {/* 
                                            */}
                                            <div className="subs-containere">
                                                {this.state.tipo === 'procesados' ?
                                                    <>
                                                        {/* <h1>
                                                            MIS DOC
                                                        </h1> */}
                                                        <ComponentDocumentosCargados
                                                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                                                            handlerClose={this.handlerClose}
                                                            cruzarDocumento={this.state.dataTableProcesados}
                                                            downloadDoc={cargarDocumento ? download : downloadDoc}
                                                            data={proccessDocumentSelector}
                                                            mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                                                            showDocumentSelector={cargarDocumento ? showDocumentSelector : showCrossSelector}
                                                            pdfData={this.pdfData}
                                                            cruceData={this.cruceData}
                                                            user={user} />

                                                    </>
                                                    :
                                                    <ComponentDocumentosFallidos
                                                        data={this.state.dataTableFallidos} />
                                                }
                                            </div>
                                        </Paper>
                                    }
                                </>
                            }

                        </Paper>
                    }
                </>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        proccessDocumentSelector: selectorProccessDocument(state),
        loadingCircular: selectorLoadingCircular(state),
        selectorDataCompany: getDataCompanySelector(state),
        failSelector: selectorFailDocument(state),
        showDocument: selectorShowDocument(state),
        showCrossSelector: selectorShowCross(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getDocumentosProc: (value) => dispatch({ type: GET_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        cruzarDoc: (value) => dispatch({ type: CROSS_DOC, value }),




    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentMisDocumentos);