import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { menuItemsOCR } from "../../../../Constants";
import { Box, Tabs, Tab } from "@material-ui/core";
import SideBar from "../../../../components/sidebar/sidebar";
import ComponentDocumentosFallidos from "./documentosFallidos";
import ComponentDocumentosCruzados from "../cruce/componenteCruce";
import ComponentDocumentosCargados from "./documentosProcesados";
import { DOWNLOAD_DOC, GET_DOC_PROC, GET_OCR_DOC, SHOW_DOC_PROC } from "./constants";
import { getFromSession } from "../../../../controller/session";
import { selectorLoadingCircular, selectorProccessDocument, selectorShowDocument, selectordocumentData } from "./selectors";
import CircularProgress from "@material-ui/core/CircularProgress";
import PDFViewer from "./ocrTable/vistaPreviaDoc";
import { Button } from "@material-ui/core";
import { COMPANY_DATA, GET_DOC_FALL } from "./constants";
import { Paper } from "@material-ui/core";
import { getDataCompanySelector, selectorFailDocument } from "./selectors";


class ComponentMisDocumentos extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, tipoDoc } = this.props;
        const renderDoc = tipoDoc ? 'fallido' : 'procesados'
        this.state = {
            activePath: '/',
            tipo: renderDoc,
            pdfData: false,
            dataTableProcesados: false,
            dataTableFallidos: false,
            cruzarDocumento: false
        }

    }
    handlerClose = () => {
        const {
            getDocumentos,
            documentosProcesadosSelector,
            lote
        } = this.props;

        getDocumentos(lote)
        this.setState({ dataTableProcesados: documentosProcesadosSelector });

        this.setState({ pdfData: false });
    }

    handlerCloseCruce = () => {
        const {
            getDocumentos,
            documentosProcesadosSelector,
            lote
        } = this.props;

        getDocumentos(lote)
        this.setState({ dataTableProcesados: documentosProcesadosSelector });
        this.setState({ cruzarDocumento: false });

    }

    selectTab = (e, newValue) => {
        this.setState({ tipo: newValue });
    }

    componentWillMount() {
        const { getDocumentosProc,
            getDocumentos,
            companyData,
            cargarDocumento,
            getFailDocument,
            lote,
            getDocumentosFallidos,
            failDocumentSelector,
            documentosProcesadosSelector
        } = this.props;
        //getOCRDocumentos();

        // if(lote){
        //     console.log("LOTE IFFFF", lote)
        //     companyData();
        //     getDocumentos(lote)
        //     this.setState({ dataTableProcesados: documentosProcesadosSelector });
        // }

        if (cargarDocumento) { 
            console.log("DOC PROPS")
            getDocumentos(lote);
            getFailDocument(lote);
            this.setState({ dataTableProcesados: documentosProcesadosSelector });

            this.setState({ dataTableFallidos: failDocumentSelector });

        } else {
            //  console.log("DOC SIDE BAR")
            getDocumentosProc();
            getDocumentosFallidos();
        }

    }
    componentDidUpdate(prevProps) {
        const {
            proccessDocumentSelector,
            documentosProcesadosSelector,
            failDocumentSelector,
            failSelector,
            nombreSelector,
            showDocument,
            getFailDocument,
            showDocumentSelector,
            getDocumentos,
            getDocumentosProc,
            lote,
            data,
            getDocumentosFallidos
        } = this.props;
        // if (prevProps.documentosProcesadosSelector !== documentosProcesadosSelector) {
        //     console.log("prevProps.documentosProcesadosSelector", prevProps.documentosProcesadosSelector)
        //     console.log("documentosProcesadosSelector",  documentosProcesadosSelector)
        //     getDocumentos(lote)
        //     //this.setState({ dataTableProcesados: documentosProcesadosSelector });
        //  } 
        // else if (prevProps.proccessDocumentSelector !== proccessDocumentSelector) {
        //     console.log("LOTEEEEEEE else if")
        //     getDocumentosProc()
        //     this.setState({ dataTableProcesados: proccessDocumentSelector });
        // }

        // if (prevProps.failDocumentSelector !== failDocumentSelector) {
        //     getFailDocument()
        //     this.setState({ dataTableFallidos: failDocumentSelector });
        // } else if (prevProps.failSelector !== failSelector) {
        //     getDocumentosFallidos()
        //     this.setState({ dataTableFallidos: failSelector });
        // }
        // if (prevProps.nombreSelector !== nombreSelector) {
        //     //  console.log("PREVPROPS nombreSelector")
        // }

    }


    pdfData = (newData) => {
        console.log("PDF DATA", newData)
        this.setState({ pdfData: newData });
    }

    cruceData = (newData) => {
        this.setState({ cruzarDocumento: newData.name });
        console.log("DATA DOCUMENTO PRINCIPAL CRUCE", newData)
    }


    render() {
        const {
            mostrarDocumentos,
            showDocumentSelector,
            loading,
            download,
            failDocumentSelector,
            loadingCircular,
            cargarDocumento,
            crossDocumentSelector,
            mostrarDocumentosProcesados,
            crossDoc,
            downloadDoc,
            showDocument,
            selectorDataCompany,
            proccessDocumentSelector,
            selectorShowCrossDocument,
            documentosProcesadosSelector,
            loadingProc,
            selectorDatosDocPrincipal,
            loadingFall,
            getFailDocument,
            handlerClose
        } = this.props;

        const user = getFromSession("company_id")
        return (
            // <Paper className="main-wrapper home container"></Paper>
            // <Paper className="main-wrapper home container">
            <>
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}
                {/*
                {!this.state.dataTableProcesados &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                }
            */}
                {/* 
                */}
                {/* {loading &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                } */}
                {/* {loadingCircular &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                        </div>
                        
                } */}

                {/* {loadingProc &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                }  */}

                {this.state.cruzarDocumento ?
                    <>

                        {/*
                         <div className="button-ocr-left">
                            <Button onClick={() => handlerCloseCruce()} color='primary' variant="contained" style={{ "marginBottom": "10px" }}>Documentos procesados</Button>
                        </div> 
                        */}
                        <ComponentDocumentosCruzados
                            handlerCloseCruce={this.handlerCloseCruce}
                            crossDocu={crossDoc}

                            mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                            showDocument={showDocument}
                            showCrossDocumentSelector={cargarDocumento ? showDocumentSelector : selectorShowCrossDocument}

                            selectorDatosDocPrincipal={cargarDocumento ? selectorDatosDocPrincipal : selectorDatosDocPrincipal}


                        />
                    </>
                    :
                    <>
                        {this.state.pdfData ?
                            <>
                                <PDFViewer
                                    handlerClose={this.handlerClose}
                                    pdfUrl={this.state.pdfData}
                                    data={documentosProcesadosSelector}
                                    idDoc={cargarDocumento ? showDocumentSelector : showDocument}
                                />


                                {/* <div className="titulo-documentos">
                                    <Box sx={{ width: '100%' }}
                                    variant="fullWidth"
                                    >
                                    <Tabs className="tabs-container-ocr"
                                    value={this.state.tipo}
                                    style={{ display: 'initial' }}
                                    onChange={this.selectTab}
                                            centered
                                        >
                                            <Tab className="tab" value="procesados" style={{ "font-size": "24px", "font-weight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Procesados"

                                            />
                                            <Tab className="tab" value="fallido" style={{ "font-size": "24px", "font-weight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Fallidos"
                                                
                                                />
                                        </Tabs>
                                    </Box>
                                </div> 

                                <div className="subs-containere">
                                    {this.state.tipo === 'procesados' ?
                                        <ComponentDocumentosCargados
                                            cruzarDocumento={this.state.cruzarDocumento}
                                            downloadDoc={downloadDoc}
                                            data={documentosProcesadosSelector}
                                            mostrarDocumentosProcesados={mostrarDocumentosProcesados}
                                            showDocumentSelector={showDocumentSelector}
                                            pdfData={this.pdfData}
                                            cruceData={this.cruceData}
                                            user={user} /> :
                                        <ComponentDocumentosFallidos
                                            data={failDocumentSelector} />
                                    }
                                </div>
                            */}
                            </>
                            :
                            <>
                                <div className="align-button-ocr">
                                    <div className="button-ocr-left">
                                        <Button style={{ "marginTop": "10px" }} onClick={() => handlerClose()} color='primary' variant="contained">Volver</Button>
                                    </div>

                                </div>
                                <div className="titulo-documentos">
                                    <Box sx={{ width: '100%' }}
                                        variant="fullWidth"
                                    >
                                        <Tabs className="tabs-container-ocr"
                                            value={this.state.tipo}
                                            style={{ display: 'initial' }}
                                            onChange={this.selectTab}
                                            centered
                                        >
                                            <Tab className="tab" value="procesados" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Procesados"

                                            />
                                            <Tab className="tab" value="fallido" style={{ "fontSize": "24px", "fontWeight": "bold" }}
                                                sx={{ width: 200 }}
                                                label="Fallidos"

                                            />
                                        </Tabs>
                                    </Box>
                                </div>

                                <div className="subs-containere">
                                    {this.state.tipo === 'procesados' ?
                                        <ComponentDocumentosCargados
                                            crossDoc={crossDoc}
                                            cruzarDocumento={this.state.dataTableProcesados}
                                            downloadDoc={cargarDocumento ? download : downloadDoc}
                                            data={documentosProcesadosSelector}
                                            mostrarDocumentosProcesados={cargarDocumento ? mostrarDocumentos : mostrarDocumentosProcesados}
                                            showDocumentSelector={showDocumentSelector}
                                            pdfData={this.pdfData}
                                            cruceData={this.cruceData}
                                            user={user} /> :
                                        <ComponentDocumentosFallidos
                                            data={this.state.dataTableFallidos} />
                                    }
                                </div>
                            </>
                        }
                    </>
                }
                {/* </Paper> */}

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        proccessDocumentSelector: selectorProccessDocument(state),
        loadingCircular: selectorLoadingCircular(state),
        selectorDataCompany: getDataCompanySelector(state),
        failSelector: selectorFailDocument(state),
        showDocument: selectorShowDocument(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getDocumentosProc: (value) => dispatch({ type: GET_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentMisDocumentos);