import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class ComponentDatosTarjeta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: false,
            tipo: false,
            valor: '$0',
            periodo: '15 días',
            confirmarDatos: false
        }

    }

    componentWillMount() {
        const {
            banco,
            id,
            tipo,
            handleOptionChange
        } = this.props;

        if (window.location.pathname === '/planes/:1050') {
            this.setState({ producto: 'ChatBot AutoGestionable' });
            if (tipo === 'mensual') {
                if (id === '00001') {
                    this.setState({ tipo: 'Prueba Gratis' });
    
                } else if (id === '00002') {
                    this.setState({ tipo: 'Pymes', valor: '$89.000', periodo: '1 Mes' });
                }
            }
            if (tipo === 'anual') {
                if (id === '00005') {
                    this.setState({ tipo: 'Prueba Gratis de 15 días' });
    
                } else if (id === '00006') {
                    this.setState({ tipo: 'Pymes', valor: '$999.000', periodo: '1 Año' });
                }
            }
        }
        if (window.location.pathname === '/planes/:1051') {
            console.log("tipooo", tipo)
            console.log("IDDDD", id)
            this.setState({ producto: 'Reconocimiento de Imágenes' });
            if (tipo === 'mensual') {
                if (id === '00001') {
                    this.setState({ tipo: 'Prueba Gratis', periodo: '90 días'  });
    
                } else if (id === '00002') {
                    this.setState({ tipo: 'Pymes', valor: '$89.000', periodo: '1 Mes' });
                }
            }
            if (tipo === 'anual') {
                if (id === '00005') {
                    this.setState({ tipo: 'Prueba Gratis de 90 días' });
    
                } else if (id === '00006') {
                    this.setState({ tipo: 'Pymes', valor: '$349.000', periodo: '1 Año' });
                }
            }
        
        }
        
        handleOptionChange('pago');

    }

    render() {
        const {
            banco,
            id,
            tipo
        } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="pedidos-container">
                    <h1>Avanti-it</h1>
                    <h2>Revisa y confirma tu compra</h2>
                <div className="cb-transition__slide">

                    <h3>Detalle del pedido</h3>
                    <h4>
                        {this.state.tipo}
                    </h4>
                  
                    <h4>
                        {this.state.periodo}
                    </h4>
                    <h4>
                        {this.state.producto}
                    </h4>
                </div>
                <div className="cb-body__content">
                    <h3>Detalle del pago</h3>
                    <h4>
                        Total a pagar:{this.state.valor}
                    </h4>
                   
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDatosTarjeta);