import "./style.css";

import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";
import SideBar from "../../components/sidebar/sidebar";
import { menuItems, menuItemsChatBot, menuItemsOCR } from "../../Constants";
import CustomForm from "../../components/customform/customform";
import { getManageSpinerState, getSelectedForm } from "./selector";
import { CONSULTAR_DIALOG_FLOW_PROPERTY, UPDATE_FIELD_ATTRIBUTE } from "./constants";
import FaqsComponent from "./faqs";
import TemasComponent from "./temas";
import UsuariosComponent from "./usuarios";
import { getFromSession } from "../../controller/session";
import ConfGeneralComponent from "./configuracion";
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';



class ComponentAdminPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            faqsComponent: false,
            usersComponent: false,
            temasComponent: false,
            confGeneralComponent: false,
            menu: false,
        }
    }

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { selectedForm, adminOCR } = this.props;

        selectedForm.configuracion = 'usuarios'
        this.setState({
            faqsComponent: false,
            usersComponent: true,
            temasComponent: false,
            confGeneralComponent: false,
        })

        const company = getFromSession("company");


        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
        } else {
            if (adminOCR) {
                this.setState({ menu: menuItemsOCR });
            } else {
                this.setState({ menu: menuItemsChatBot });
            }
        }

    }

    componentDidUpdate(prevProps) {

        if (this.props.selectedForm.configuracion && prevProps.selectedForm.configuracion != this.props.selectedForm.configuracion) {
            this.obtenerSeleccionCombo(this.props.selectedForm.configuracion);
        }
    }

    obtenerSeleccionCombo = (value) => {
        if (value === 'faqs') {
            this.setState({
                faqsComponent: true,
                usersComponent: false,
                temasComponent: false,
                confGeneralComponent: false,
            })
        } else if (value === 'temas') {
            this.setState({
                faqsComponent: false,
                usersComponent: false,
                temasComponent: true,
                confGeneralComponent: false,
            })
        } else if (value === 'usuarios') {
            this.setState({
                faqsComponent: false,
                usersComponent: true,
                temasComponent: false,
                confGeneralComponent: false,
            })
        } else if (value === 'configuracion') {
            this.setState({
                confGeneralComponent: true,
                faqsComponent: false,
                usersComponent: false,
                temasComponent: false,
            })
        }
    }

    openModalComponent() {
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="loading-wrapperx">
                    <LinearProgress />

                    <p className="loading-text">
                        Cargando información ...

                    </p>
                </div>
            </Modal>
        );
    }


    render() {

        const {
            selectedForm,
            updateAttributes,
            consultarDialogFlow,
            adminOCR,
            onReturnConfiguration,
            openSpiner
        } = this.props;

        let user = getFromSession('company');
        let opcionesConfiguracion = [
            { label: "Usuarios", value: 'usuarios' },
            { label: "Temas", value: 'temas' },
            { label: "Preguntas frecuentes", value: 'faqs' },
            { label: "Configuración general", value: 'configuracion' },
        ];
        let opcionesConfiguracionOCR = [
            { label: "Usuarios", value: 'usuarios' },
            { label: "Configurar documentos", value: 'configuracion' },
        ];

        let COMBO = [
            {
                name: "configuracion",
                placeholder: "Seleccione una configuración",
                value: false,
                options: adminOCR ? opcionesConfiguracionOCR : opcionesConfiguracion,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione una opción",
                display: true
            }
        ]

        consultarDialogFlow();

        return (
            <React.Fragment>

                {openSpiner ? this.openModalComponent() : ""}

                <SideBar logo={user.logo} items={this.state.menu}></SideBar>
                <div className="container-admin-panel">
                    <div className="p-shadow-8 backgroundColor p-mt-3 p-p-3">

                        <h3 className="h3-title">Panel de Configuración</h3>
                        <div className='row marginBottom p-ml-2'>
                            <h3 className="h3-fixed">Configurar:</h3>
                            <div className="p-ml-2">
                                <CustomForm
                                    formName="configuracion"
                                    items={COMBO}
                                    values={selectedForm}
                                    updateAction={updateAttributes}
                                    style="splash-container-search"
                                />
                            </div>
                        </div>
                        {this.state.faqsComponent ? (
                            <div className='p-col'>
                                <FaqsComponent>
                                </FaqsComponent>
                            </div>

                        ) : null}
                        {this.state.temasComponent ? (
                            <div className='p-col'>
                                <TemasComponent>
                                </TemasComponent>
                            </div>

                        ) : null}
                        {this.state.usersComponent ? (
                            <div className='p-col'>
                                <UsuariosComponent
                                    adminOCR={adminOCR}>
                                </UsuariosComponent>
                            </div>

                        ) : null}
                        {this.state.confGeneralComponent ? (
                            <div className='p-col'>
                                <ConfGeneralComponent
                                    adminOCR={adminOCR}>
                                </ConfGeneralComponent>
                            </div>

                        ) : null}
                    </div>
                </div>

            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        selectedForm: getSelectedForm(state),
        openSpiner: getManageSpinerState(state),
    };
};

const mapDispachToProps = dispatch => {

    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        consultarDialogFlow: (value) => dispatch({ type: CONSULTAR_DIALOG_FLOW_PROPERTY, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentAdminPanel);