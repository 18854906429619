import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";

class ComponentDatosPedidos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tipo: false,
            valor: '$0',
            periodo: '15 días',
            producto: false,
            confirmarDatos: false
        }

    }


    componentWillMount() {
        const {
            id,
            producto,
            tipo,
            confirmPagos,
        } = this.props;
        if (window.location.pathname === '/planes/:1050') {
            this.setState({ producto: 'ChatBot AutoGestionable' });
            if (tipo === 'mensual') {
                if (id === '00001') {
                    this.setState({ tipo: 'Prueba Gratis de 15 días' });

                } else if (id === '00002') {
                    this.setState({ tipo: 'Pymes', valor: '$89.000 COP', periodo: '1 Mes' });
                }
            }

            if (tipo === 'anual') {
                if (id === '00005') {
                    this.setState({ tipo: 'Prueba Gratis de 15 días' });

                } else if (id === '00006') {
                    this.setState({ tipo: 'Pymes', valor: '$999.000 COP', periodo: '1 Año' });
                }
            }
        }
        if (window.location.pathname === '/planes/:1051') {
            this.setState({ producto: 'Reconocimiento de Imágenes' });
            if (tipo === 'mensual') {
                if (id === '00001') {
                    this.setState({ tipo: 'Prueba Gratis de 90 días' });

                } else if (id === '00002') {
                    this.setState({ tipo: 'Pymes', valor: '$349.000 COP', periodo: '1 Mes' });
                }
            }

            if (tipo === 'anual') {
                if (id === '00005') {
                    this.setState({ tipo: 'Prueba Gratis de 90 días' });

                } else if (id === '00006') {
                    this.setState({ tipo: 'Pymes', valor: '$349.000 COP', periodo: '1 Año' });
                }
            }
        }




    }

    render() {
        const {
        } = this.props;
        const user = getFromSession("currentUser");
        return (
            <div className="pedidos-container">
                <div className="cb-transition__slide">
                    <h1>Avanti-it</h1>
                    <h3>Su pedido</h3>
                    <h3>{this.state.producto}</h3>
                </div>
                <div className="cb-body__content">
                    <h4>{this.state.tipo} : {this.state.valor}</h4>
                    <h4>Duracion de Suscripción {this.state.periodo}</h4>

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDatosPedidos);