import { Button } from '@material-ui/core';
import React, { Component } from 'react';


export default class CustomButton extends Component {
    render() {
        const { items, positionButton, styleButton } = this.props;
    
        return (
            <div className={`button-wrapper ${styleButton}`} >
                <div className={positionButton} color="primary" aria-label="outlined primary button group">
                    {
                        items && items.map((button, k) => {
                            const classStyle = button.display? button.className + ' ' + button.style:'displayNone'
                          

                            return <Button
                                key={`button-${button.label}-${k}`}
                                variant={button.variant}
                                type="submit"
                                className={classStyle}
                                onClick={button.onClick}
                                color={button.color}
                                                           >
                                {button.label}
                            </Button>
                        })
                    }
                </div>
            </div>
        );
    }
}
