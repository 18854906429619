import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getMenuItemsOCR = (state) => state.menuItemsOCR;
export const getDataCompanySelector = (state) => state.dataCompany;
export const getUploadBucketSelector = (state) => state.uploadBucket;
export const selectLoading = (state) => state.loading;
export const selectLoadingCircular = (state) => state.loadingCircular;
export const selectLoadingCircularFall = (state) => state.loadingCircularFall;


export const selectLoadingProc = (state) => state.loadingProc;

export const selectorShowToast = (state) => state.showToast;
export const selectorToastDetail = (state) => state.toastDetail;
export const selectorOcrCreate = (state) => state.ocrCreate;
export const selectorOcrActivo = (state) => state.ocrActivo;

export const selectorContador = (state) => state.contador;
export const selectorLongitud = (state) => state.longitud;
export const selectorShowTable = (state) => state.showTable;
export const selectordocumentData = (state) => state.documentData;
export const selectorProDocument = (state) => state.proDocument;
export const selectorSize = (state) => state.size;
export const selectorName = (state) => state.nombre;
export const selectorDocumetosProcesados = (state) => state.procDoc;
export const selectorFailDocument = (state) => state.failDocs;
export const selectorShowDocument = (state) => state.showDoc;
export const selectorCrossDocument = (state) => state.crossDoc;
export const selectorShowCrossDocument = (state) => state.showCross;
export const selectorDatosDocPrincipal= (state) => state.datosDocPrincipal;

export const selectorGetLotes = (state) => state.lotes;
















export const usersSelector = createStructuredSelector({
    users: getUsers,
});