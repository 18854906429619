import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getMenuItemsChatBot, getMenuItemsOCR, getMenuItemsPerfil, getUsers } from "./selectors";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from './constants';
import SideBar from "../../components/sidebar/sidebar";
import CustomCard from "../../components/customcard/customcard";
import { CARD_ITEMS } from "../inicio/constants";
import { menuItems } from "../agentPanel/selectors";
import { getFromSession } from "../../controller/session";
import { Box, Button, Card, TextField } from "@material-ui/core";
import { InputText } from "primereact/inputtext";

class ComponentPerfilUsuario extends Component {

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { getUsers, loadMenuImagePerfilUsuario } = this.props;
        getUsers();
        loadMenuImagePerfilUsuario();
    }

    render() {
        const {
            userHeaders,
            menuItemsPerfil,
            users,
        } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="App home-container">
                {menuItemsPerfil && <SideBar items={menuItemsPerfil}></SideBar>}
                <div className='user-list_wrapper'>
                    <div className="titulo">

                        <h1>
                            Mi perfil
                        </h1>
                    </div>

                  
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsPerfil : getMenuItemsPerfil(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenuImagePerfilUsuario: (value) => dispatch({ type: LOAD_MENU, value }),
        
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentPerfilUsuario);