import React, { Component } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import CustomForm from "../../components/customform/customform";
import { CSVLink } from "react-csv";
import { Button } from 'primereact/button';

import './searchaccordion.css';


import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

export default class SearchAccordion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cleaning: false,
        }

    }
    showSnackBar(open) {
        return (
            <Snackbar open={open} autoHideDuration={2} className="ocultar">
                <Alert severity="success">{""}</Alert>
            </Snackbar>
        );
    }

    limpiarBusqueda(action) {
        const { getConversations, limpiarForm
        } = this.props;
        this.setState({ cleaning: true })
        limpiarForm();

        getConversations();
    }

    render() {

        const { selectedForm, updateAttributes, filtrarBusqueda,
            lanzarEventos, optionsAgentes, getConversations, limpiarForm, showToast,
            totalConvsFiltradas
        } = this.props;


        const SEARCH_FORMS = [
            {
                typeform: 'filter',
                label: "*Fecha Inicio",
                name: "dateIni",
                placeholder: "AAAA-MM-DDTHH:MM",
                type: "datetime-local",
                value: false,
                /* options: [], */
                disable: false,
                labelClass: "label-name-show",
                display: true


            },
            {
                typeform: 'filter',
                label: "*Fecha Fin",
                name: "dateFin",
                placeholder: "AAAA-MM-DDTHH:MM", 
                type: "datetime-local",
                value: false,
                /* options: [], */
                disable: false,
                labelClass: "label-name-show",
                display: true
            },

            {
                label: "Agente",
                name: "agente",
                placeholder: "Seleccione un agente",
                typeForm: "primeFace-dropdown",
                value: false,
                options: optionsAgentes ? optionsAgentes : [],
                disable: false,
                optionlabel: "nombre",
                labelClass: "label-name-show",
                display: true
            },
            {
                label: "Texto",
                name: "texto",
                placeholder: "texto",
                type: "text",
                value: false,
                /* options: [], */
                disable: false,
                labelClass: "label-name-show",
                display: true
            },

        ]

        function activarFiltrarBusqueda(form) {
          
            /* filtrarBusqueda(form) */
            if (!form.dateIni || !form.dateFin || (form.dateIni > form.dateFin)) {
                showToast()
            } else {
                filtrarBusqueda(form)
            }


        }


        const getSearchButton = (selectedForm) => {
            return [
                {
                    label: "Filtrar",
                    className: "btn-princ btn-primary",
                    action: false,
                    style: "primary",
                    onClick: () => activarFiltrarBusqueda(selectedForm),
                    display: true
                },
                {
                    label: "Limpiar",
                    className: "btn-second btn-primary",
                    action: false,
                    style: "custom-margin",
                    onClick: () => this.limpiarBusqueda(selectedForm),
                    display: true

                },



            ]
        }
        if (!selectedForm.cleaning && this.state.cleaning) {
            this.setState({ cleaning: false })
        }
        return (
            <React.Fragment>


                <Accordion onTabOpen={lanzarEventos}>
                    <AccordionTab header="Buscador de la tabla" >
                        {!this.state.cleaning ?
                            <CustomForm
                                formName="searchForm"
                                items={SEARCH_FORMS}
                                values={selectedForm}
                                updateAction={updateAttributes}
                                style="splash-container-search"
                                bodyStyle="body"
                                styleFormgroup="group-windowSearch col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-2"
                                classnameDiv="form-searh-table"
                                formRow='form-row'
                                positionButton='positionButton'
                                styleButton='styleButton'
                                buttons={getSearchButton(selectedForm)}
                            />
                            : ""}
                        {totalConvsFiltradas ?
                            <div style={{ "display": "flex", justifyContent: 'flex-end' }}>
                                <CSVLink data={totalConvsFiltradas} >
                                    <Button label="Descargar CSV" icon="pi pi-download" className="p-button-success p-ml-2"
                                    />
                                </CSVLink>
                            </div> : false
                        }
                    </AccordionTab>
                </Accordion>

            </React.Fragment>
        );

    }

}



