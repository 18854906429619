import { takeLatest, put, take, call, takeEvery, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import axios from "axios";
import moment from "moment";
import {
  finalizar,
  transferir,
  fijarTema,
  tomarControl,
  enviarMensajeToUser,
  resuelta,
  search,
  singleBusqueda,
  obtenerMensajes,
  anadirNotaOculta,
  contarConvsPorAgente,
  filtrarConversaciones,
  searchIdConv,
} from "../../controller/conversation";
import {
  usuariosConectados,
  actualizarUltimaFechaAceptacion,
  setEstado,
} from "../../controller/connectedUsers";
import { lstTemas } from "../../controller/temas";
import { lstrEstados } from "../../controller/estados";
import { lstrAgentes } from "../../controller/agentes";
import { traerFaqs } from "../../controller/faqs";
import { initialize } from "redux-form";
import { menuItems } from "../../Constants";
import { faGamepad, faNewspaper } from "@fortawesome/free-solid-svg-icons";

import {
  GET_CONVERSATIONS,
  SEARCH_CONVERSATIONS,
  SELECT_CONVERSATION,
  FILTER_CONVERSATIONS,
  SEND_MESSAGE,
  TOMAR_CONTROL,
  LISTAR_ESTADOS,
  LISTAR_AGENTES,
  TRANSFER,
  END_CONVERSATION,
  SET_TEMA,
  RESUELTA,
  LISTAR_USUARIOS_CONECTADOS,
  LISTAR_TEMAS,
  ADJUNTAR_ARCHIVOS,
  LANZAR_EVENTOS,
  LISTENER_LOGOUT,
  LISTAR_FAQS,
  REDUX_FORM_CHANGE,
  INIT_TOGGLE_STATE,
  PRINT_CONVERSATION,
  LOAD_MENU,
  TRANSFERENCIAS_CONVERSACION,
  ADD_NOTA_OCULTA,
  NOTAS_OCULTAS,
  PAGINATE_CONVERSATIONS,
  CONSULTAR_DIALOG_FLOW_PROPERTY,
  HEADERS_WS,
} from "./constants";

import {
  getConversationsSuccess,
  getConversationsError,
  selectConversationSuccess,
  selectConversationError,
  filterConversationslistSuccess,
  generalError,
  listarUsuariosConectadosSuccess,
  selectConversationMessagesSuccess,
  listarTemasSuccess,
  adjuntarArchivosUploadSuccess,
  resueltaSuccess,
  setTemaSuccess,
  transferirConversacionSuccess,
  finalizarConversacionSuccess,
  listarEstadosSuccess,
  listarAgentesSuccess,
  listenerTimeOutSuccess,
  listarFaqsSuccess,
  listarTemasDisplay,
  printConversationSuccess,
  sendMessageSucces,
  loadMenuSucces,
  obtenerTransferenciasConversacionError,
  obtenerTransferenciasConversacionSuccess,
  addNotaOcultaConvError,
  addNotaOcultaConvSuccess,
  activarTabNotasOcultasSuccess,
  paginateConversationsSuccess,
  clearConversationStart,
  saveStorageListFaqs,
  openSpinnerFAQs,
  saveTotalConversationsRefresh,
  guardarConsultaBigQuery,
  openSpinnerCharging,
} from "./actions";

import { firebaseDatabase, firebaseField, storage, Timestamp } from "../../controller/firebase";
import {
  getFromSession,
  logout,
  storeInSession,
} from "../../controller/session";
import { consultaProperty } from "../../controller/configuration";
import { obtenerTransferenciasConv } from "../../controller/transferencias";
import { consultarRegistroAgentes } from "../../controller/estadisticas";
import { obtenerNotasConv } from "../../controller/notasOcultas";
import { getConsultaBigQuery, getListFaqs } from "./selectors";
import { utcToZonedTime } from "date-fns-tz";

import {
  where,
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  onSnapshot,
} from 'firebase/firestore';



function* getConversations(action) {

  let limit = action.value;

  let conversations = [];
  let reference = false;
  /*Se verifica si el usuario es agente para solo mostrarle sus conversaciones */
  let user = getFromSession("currentUser");
  let company = getFromSession("company_id");

  let fecha = new Date();
  // let limiteDias = yield consultaProperty(company, 'LIMITE_DIAS_CONVS');

  // if (limiteDias && limiteDias > 0) {
  //   fecha.setDate(fecha.getDate() - limiteDias);
  // } else {
  //   limiteDias = 60
  //   fecha.setDate(fecha.getDate() - 60);
  // }
  let totalConversations = 0;
  let queryTot_ = false;
  if (user.rol === "AGENTE") {
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .where("agente.mail", "==", user.mail)
      //.where("last_message", ">", fecha)
      .orderBy("last_message", "desc")

    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    queryTot_ = query(coll, where("agente.mail", "==", user.mail));

  } else {
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      //.where("last_message", ">", fecha)
      .orderBy("last_message", "desc")

    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    queryTot_ = query(coll);
  }

  totalConversations = yield getCountFromServer(queryTot_);
  totalConversations = totalConversations?._data?.count ? totalConversations._data.count : 0;
  yield put(saveTotalConversationsRefresh(totalConversations));

  const channel = eventChannel((emit) => reference.limit(limit).onSnapshot(emit));
  let response = {};
  let inited = false;
  try {
    yield put(clearConversationStart(response));

    while (true) {
      conversations = [];
      const realData = yield take(channel);
      realData.docs.forEach((doc) => {

        conversations.push(doc);
      });

      const { convsDisplay, nuevasConv } = buildDisplayList(
        conversations,
        inited
      );
      response = {
        conversations,
        convsDisplay,
        limiteDias: false,
        playSoundConv: nuevasConv
      };

      yield put(getConversationsSuccess({ ...response }));
      inited = true;
    }

  } catch (err) {
    console.error("getConversations:" + err);
    yield put(getConversationsError(err));
  }
}

function buildDisplayList(conversations, inited) {
  let nuevasConv = false;
  const currentUser = getFromSession("currentUser");
  if (conversations) {
    conversations.forEach((doc) => {
      if (doc.data().estado === 1) {
        if (inited && currentUser.mail === doc.data().agente.mail) {
          nuevasConv = true;
        }
      }
    });
    return { nuevasConv };
  }
}

// function* filtrarConversacionesAlgolia(action) {
//   try {
//     const { search, page } = action;
//     const urlServicio = process.env.REACT_APP_SEARCH_WHITH_ALGOLIA;
//     const body = {
//       phrase: search,
//       page,
//       hitsPerPage: 10
//     }
//     const headers = HEADERS_WS;
//     const respues = yield call(axios.post, urlServicio, body, headers);
//     return respues.data;

//   } catch (error) {

//   }
// }

function* filtrarConversacionesPorTextoBigQuery(body) {
  try {
    const urlServicio = process.env.REACT_APP_CONSULTA_BIGQUERY;;
    const headers = HEADERS_WS;
    const respues = yield call(axios.post, urlServicio, body, headers);
    return respues.data;

  } catch (error) {
    throw `saja.js - filtrarConversacionesPorTextoBigQuery - ${error}`
  }
}

function* filterConversationslist(action) {
  try {
    const { value } = action;
    yield put(openSpinnerCharging(true));
    if (value && value !== "cleanInputSearch") {
      const body = {
        company: getFromSession("company_id"),
        text: value,
        page: 0,
        limit: 10,
        nombreGrafica: "busquedaPorTexto"
      }
      yield put(guardarConsultaBigQuery(body))

      let convsFiltradas = yield filtrarConversacionesPorTextoBigQuery(body);

      if (convsFiltradas.conversations.length > 0) {
        yield put(filterConversationslistSuccess({ "convsFiltradas": convsFiltradas.conversations, total: convsFiltradas.total }));
      } else {
        //limpiar y regresar al registro de datos de la bandeja
        yield put(filterConversationslistSuccess({ "convsFiltradas": false }));
      }
    } else {
      //limpiar y regresar al registro de datos de la bandeja
      yield put(filterConversationslistSuccess({ "convsFiltradas": false }));
    }
    yield put(openSpinnerCharging(false));
  } catch (error) {
    console.error("agentPanel.saga.filterConverationsList", error);
  }
}

/**
 * @TODO este metodo consulta el detalle de una conversacion junto con los mensajes y
 * actualizara en tiempo real el panel de mensajes
 */
function* selectConversation(params) {

  try {
    const company = getFromSession("company_id");
    const currentUser = getFromSession("currentUser");
    const referenceConv = firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(params.value.id);

    //let fechaServer = firebaseField.serverTimestamp()
    //referenceConv.update({ db_server_date: firebaseField.serverTimestamp()});

    //se valida si esta en estado 1 (asignada) y si el que la esta tomando es el asesor, para cambiar el etado
    //a 2 (aceptada)
    yield referenceConv.get().then((conv) => {

      let idDocAgente;
      if (conv.data() &&
        conv.data().estado === 1 &&
        currentUser.mail === conv.data().agente.mail
      ) {
        referenceConv.update({ estado: 2, fecha_aceptacion: firebaseField.serverTimestamp(), db_server_date: firebaseField.serverTimestamp() });
        idDocAgente = conv.data().agente.idDoc ? conv.data().agente.idDoc : false;
        if (idDocAgente) {
          firebaseDatabase
            .collection(`company/${company}/connectedUsers`)
            .doc(idDocAgente)
            .update({
              last_conv_taked: firebaseField.serverTimestamp(),
            });
        }
      }
    });

    let data = false;

    const channelConv = yield eventChannel((emit) => referenceConv.onSnapshot(emit));

    while (true) {
      const realData = yield take(channelConv);
      data = realData;
      let resp = { conv: data };
      yield put(selectConversationSuccess(resp));
    }
  } catch (err) {
    console.error("selectConversation:" + err);
    yield put(selectConversationError(err));
    return;
  }
}
function* obtenerNotasOcultasConv(idConv) {

  try {
    let objNotas = yield obtenerNotasConv(idConv.value);
    let notasDisplay = [];

    objNotas.forEach((nota) => {
      let doc = nota.data();
      let fecha = doc.fecha.toDate();

      let notaJson = {
        fecha: moment(fecha).format("lll"),
        nota: doc.nota,
        autor: doc.autor.nombre,
      };
      notasDisplay.push(notaJson);
    });
    yield put(activarTabNotasOcultasSuccess(notasDisplay));
  } catch (error) {
    console.error("saga.obtenerNotasOcultasConv", error);
    put(generalError(error));
  }
}

function* obtenerTransferenciasConversacion(idConv) {
  try {
    let transferenciasDisplay = [];
    let objTransferencias = yield obtenerTransferenciasConv(idConv.value);

    objTransferencias.forEach((transferencia) => {
      let doc = transferencia.data();
      let fecha = doc.fecha.toDate();

      let trans = {
        fecha: moment(fecha).format("lll"),
        origen: doc.usr_origen + "\r\n" + "-" + doc.habilidad_origen,
        destino: doc.usr_destino + "\r\n" + "-" + doc.habilidad_destino,
      };
      transferenciasDisplay.push(trans);
    });
    yield put(obtenerTransferenciasConversacionSuccess(transferenciasDisplay));
  } catch (err) {
    console.error("saga.obtenerTransferenciasConversacion", err);
    put(obtenerTransferenciasConversacionError(err));
  }
}

function* selectConversationMessages(idConv) {
  let messages = [];
  try {
    const company = getFromSession("company_id"); //`company/${company}/conversations`
    const reference = yield firebaseDatabase
      .collection(`company/${company}/conversations`)
      .doc(idConv.value.id)
      .collection("mensajes")
      .orderBy("fecha", "asc");
    const channel = yield eventChannel((emit) => reference.onSnapshot(emit));
    let inited = false;


    while (true) {
      messages = [];
      let lastElement = false;
      const realData = yield take(channel);
      if (!inited && realData.docs) {
        //la primera vez se carga todo:
        realData.docs.forEach((doc) => {
          if (doc !== undefined) {
            messages.push(doc);
          }
        });
      } else {
        realData.docChanges().forEach((change) => {
          lastElement = change.doc;
        });
      }

      let resp = { mensajes: messages, inited, lastElement };

      yield put(selectConversationMessagesSuccess(resp));
      inited = true;
    }
  } catch (err) {
    console.error("selectConversationMessages:" + err);
    yield put(selectConversationError(err));
    return;
  }
}

function* addNotaOcultaConv(payload) {
  try {
    let id = payload.value.id;
    let nota = payload.value.selectedForm.notas;
    let response = yield anadirNotaOculta(id, nota);

    if (response) {
      let toastDetail = {
        summary: "Realizado",
        severity: "success",
        detail: "Nota añadita exitosamente",
      };

      yield put(
        addNotaOcultaConvSuccess({ toastDetail, verModalNotas: false })
      );
    } else {
      let toastDetail = {
        summary: "No Realizado",
        severity: "success",
        detail: "No se ha podido añadir la nota",
      };
      yield put(addNotaOcultaConvSuccess({ toastDetail, verModalNotas: true }));
    }
  } catch (err) {
    console.error(err);
    put(generalError(err));
  }
}

/**
 * Funcion que envia un mensaje al usuario
 * Si la conversacion la tiene el bot, el mensaje se envia a nombre del bot
 * @param {*} idConv
 */
function* sendMessage(conv) {

  let isFaq = false;
  let texto;
  let conversation;
  let id;
  if (conv.value.isFaq) {
    isFaq = true;

  }
  if (conv.value.selectedForm && !conv.value.selectedForm.mensaje && !isFaq)
    return;
  if (isFaq) {
    let faq = conv.value.respuesta;
    conversation = conv.value.conversation.conversation;
    id = conv.value.id.id;
    if (faq.includes("{$agente}")) {
      const agente = conversation.agente
      const nombreAgente = agente.nombres + ` ${agente.apellidos ? agente.apellidos : ""}`
      faq = faq.replace("{$agente}", `*${nombreAgente}*`)
    }
    texto = faq
    yield put(sendMessageSucces({ verModalFaqs: false }));
  } else {
    texto = conv.value.selectedForm.mensaje;
    if (texto)
      id = conv.value.id;
    conversation = conv.value.conversation;
  }

  try {
    yield enviarMensajeToUser(id, conversation, "text", texto);
  } catch (error) {
    console.error("saga agent Panel.sendMessage", error);
    put(generalError(error));
  }
}

/**
 * para tomar control de una conversacion (se fija al agente a si mismo el usuario qeu esta en linea)
 * @param {*} action
 */
function* tomarControlConversation(action) {
  let estado = action.value.conversation.estado;
  let agente = action.value.conversation.agente
    ? action.value.conversation.agente
    : false;

  try {
    const id = action.value.id;
    yield tomarControl(id, estado, agente);
  } catch (error) {
    console.error("saga agent Panel. tomarControl:", error);
    put(generalError(error));
  }
}

function* fijarTemaConversation(action) {
  try {
    const id = action.value.id.id;
    const nivel1 = action.value.nivel1;
    const nivel2 = action.value.nivel2;
    const nivel3 = action.value.nivel3;
    const tema = { nivel1 };
    if (nivel2) tema.nivel2 = nivel2;
    if (nivel3) tema.nivel3 = nivel3;
    yield fijarTema(id, tema);

    let toastNivel1 = nivel1 ? "Nivel1:" + " " + nivel1 + " " : "";
    let toastNivel2 = nivel2 ? "Nivel2:" + " " + nivel2 + " " : "";
    let toastNivel3 = nivel3 ? "Nivel3:" + " " + nivel3 + " " : "";
    let temaToast = toastNivel1 + toastNivel2 + toastNivel3;

    let toastDetail = {
      summary: "Tema fijado",
      severity: "success",
      detail: "Tema: " + temaToast,
    };
    yield put(setTemaSuccess({ toastDetail, verModalTemas: false }));
  } catch (error) {
    console.error("saga agent Panel. fijarTema:", error);
    put(generalError(error));
  }
}

function* finalizarConversation(action) {
  try {
    const id = action.value.id;
    const conversation = action.value.conversation;
    yield finalizar(id, conversation);

    let toastDetail = {
      summary: "Conversación Cerrada",
      severity: "info",
      detail: "Estado de la conversación: CERRADA",
    };
    yield put(finalizarConversacionSuccess(toastDetail));
  } catch (error) {
    console.error("saga agent Panel. finalizar:", error);
    put(generalError(error));
  }
}

function* fijarResuelta(action) {
  try {
    const id = action.value.id;
    const resuelta_ = action.value.value;
    let toastDetail = {
      summary: "Cambio realizado",
    };

    yield resuelta(id, resuelta_);

    if (resuelta_ === true) {
      toastDetail["severity"] = "success";
      toastDetail["detail"] = "Conversación  RESUELTA";
    } else {
      toastDetail["severity"] = "info";
      toastDetail["detail"] = "Conversación NO RESUELTA";
    }

    yield put(resueltaSuccess(toastDetail));
  } catch (error) {
    console.error("saga agent Panel. finalizar:", error);
    put(generalError(error));
  }
}

function* listarFaqs() {
  try {
    yield put(openSpinnerFAQs(true))
    let objFaqs = yield select(getListFaqs);
    if (!objFaqs) {
      objFaqs = yield traerFaqs();
      yield put(saveStorageListFaqs(objFaqs))
    }
    let faqsDisplay = [];

    objFaqs.forEach((faq) => {
      const faqs = {
        pregunta: faq.data().pregunta,
        respuesta: faq.data().respuesta,
      };
      faqsDisplay.push(faqs);
    });

    yield put(
      listarFaqsSuccess({
        faqsDisplay: faqsDisplay,
        objFaqs: objFaqs,
        verPanelFaqs: true,
      })
    );
  } catch (error) {
    console.error("saga.listarFaQs", error);
    put(generalError(error));
  }
}

function* listarAgentes() {
  //
  try {
    let objAgentes = yield lstrAgentes();
    let agentesDisplay = [];

    objAgentes.forEach((obj) => {
      const agentes = {
        nombre: obj.data().nombres + " " + obj.data().apellidos,
        mail: obj.data().mail,
      };
      agentesDisplay.push(agentes);
    });

    yield put(
      listarAgentesSuccess({
        agentesDisplay: agentesDisplay,
        objAgentes: objAgentes,
      })
    );
  } catch (error) {
    console.error("saga.listarAgentes", error);
    put(generalError(error));
  }
}

function* listarTemas(action) {
  try {
    let objTemas = yield lstTemas();
    yield put(
      listarTemasSuccess({ temasDisplay: objTemas, objTemas: objTemas })
    );
  } catch (error) {
    console.error("saga agent Panel. finalizar:", error);
    put(generalError(error));
  }
}

function* listarEstados() {
  try {
    let objEstados = yield lstrEstados();
    let estadosDisplay = [];

    objEstados.forEach((obj) => {
      const estados = {
        nombre: obj.data().nombre,
        code: obj.data().value,
      };
      estadosDisplay.push(estados);
    });

    yield put(
      listarEstadosSuccess({
        estadosDisplay: estadosDisplay,
        objEstados: objEstados,
      })
    );
  } catch (error) {
    console.error("Listar estados SAGA:", error);
    put(generalError(error));
  }
}

function* desplegarEventosBuscador(action) {
  yield listarAgentes();
  yield listarEstados();
}

/**
 * Se transfiere a otro agente seleccionado
 * @param {*} action: conversacion origen, habilidad? asesor destino
 */
function* transferirConversation(action) {
  try {
    const id = action.value.id.id;
    const conversation = action.value.conversation;
    const newUser = action.value.usuario;
    let toastDetail = {
      summary: "Conversación transferida",
      severity: "success",
      detail:
        "Transferido a: " +
        newUser.agente.nombres +
        " " +
        newUser.agente.apellidos,
    };

    yield transferir(id, conversation, newUser);

    yield put(
      transferirConversacionSuccess({ toastDetail, conversation: true })
    );
  } catch (error) {
    console.error("saga agent Panel.transfer", error);
    put(generalError(error));
  }
}

function* sessionTimeOutLogout(action) {
  logout();
  yield put(listenerTimeOutSuccess(action));
}

function* listarUsuariosConectados() {
  const currentUser = getFromSession("currentUser");
  const company = getFromSession("company_id");
  const uidCurrentUser = currentUser.uid;
  try {
    let usuarios = yield usuariosConectados();
    let usuariosDisplay = [];
    let posicion;
    let listaDisplay;
    let flag = false;
    let usuData = [];
    if (usuarios) {
      usuarios.forEach((usu) => {
        usuData.push(usu.data());
      });
      for (let usu of usuData) {
        let usuarioIdRef = usu.agente.idDoc;
        let convsAsignadas = yield contarConvsPorAgente(company, usuarioIdRef);
        const usuarioInfo = {
          habilidad: usu.agente.skill.nombre,
          nombres: usu.agente.nombres + " " + usu.agente.apellidos,
          convsActuales: convsAsignadas,
          usuario: usu
        }
        usuariosDisplay.push(usuarioInfo);
      }
    }
    if (currentUser.rol === "SUPERVISOR" || currentUser.estado === 3) {
      listaDisplay = usuariosDisplay;
    } else {
      for (let i = 0; i < usuariosDisplay.length; i++) {
        if (usuariosDisplay[i].usuario.agente.uid === uidCurrentUser) {
          posicion = i;
          flag = true;
          break;
        }
      }
      if (flag) {
        listaDisplay = [...usuariosDisplay];
        listaDisplay.splice(posicion, 1);
      }
    }
    yield put(
      listarUsuariosConectadosSuccess({
        usuariosDisplay: listaDisplay,
        usuariosConectados: usuarios,
        verPanelTransfer: true,
      })
    );
  } catch (error) {
    console.error("agentPanel.saga.listarUsuariosConectados", error);
    put(generalError(error));
  }
}

async function armarJSONparametros(
  company,
  idConversation,
  conversation,
  archivos,
  timestampDate
) {
  //Creamos una función asincrona cuyas tareas asincronas las vamos a sincronizar
  //debugger;
  try {
    let parametros = [];
    for (const archivo of archivos) {
      let ref = `${company}/conversations/${idConversation}/${timestampDate}_${archivo.name}`;
      const storageRef = storage.ref(ref);
      let typeArchivo = archivo.type;
      let tipo = typeArchivo.split(["/"])[0];
      let diccionarioTiposArchivo = {
        application: "file",
        image: "image",
        audio: "audio",
        video: "video",
      };
      let tipoArchivo = diccionarioTiposArchivo[tipo];
      tipoArchivo = !tipoArchivo ? "file" : tipoArchivo;
      //primera tarea asincrona, usamos await para esperar a que se resuelva la promesa
      const task = await storageRef.put(archivo).then(async (result) => {
        //segunta tarea asincrona, usamos el async luego el await para esperar a que esta promesa se resuelva
        console.log('result', result, result.ref.bucket +
          "/" +
          result.ref.fullPath,);
        parametros.push({
          idConversation: idConversation,
          conversation: conversation,
          tipo: tipoArchivo,
          nombre: archivo.name,
          url:
            "https://storage.googleapis.com/" +
            result.ref.bucket +
            "/" +
            result.ref.fullPath,
        });
      });
    }
    return parametros;
  } catch (error) {
    console.error("saga.js-armarJSONparametros", error);
  }
}

function* printConversation(convData) {
  try {
    let data = convData.value.conversation;

    let idReference = convData.value.id;

    let conversation = yield obtenerMensajes(idReference, 40);

    let { infoOrdenada } = yield ordenarInfoConversacion(data);
    console.log("antes de ir al PUT");

    yield put(
      printConversationSuccess({
        infoOrdenada,
        conversation,
        verModalPrint: true,
      })
    );
  } catch (error) {
    console.error("saga.printConversation", error);
    throw error;
  }
}

async function ordenarInfoConversacion(convInfo) {
  let fechaIni;
  fechaIni = convInfo.fecha_ini.toDate();
  fechaIni = moment(fechaIni).format("lll");
  let fechaFin;
  fechaFin = convInfo.fecha_fin ? convInfo.fecha_fin.toDate() : false;
  fechaFin = fechaFin ? moment(fechaFin).format("lll") : "Sin Finalizar";
  let contacto;
  if (convInfo.canal === "whatsapp") {
    contacto = convInfo.cliente.telefono
      ? convInfo.cliente.telefono
      : "Sin teléfono";
  } else {
    contacto = convInfo.cliente.mail ? convInfo.cliente.mail : "Sin correo";
  }
  const infoOrdenada = {
    fechaIni: fechaIni,
    fechaFin: fechaFin,
    agente: convInfo.agente
      ? convInfo.agente.nombres
        ? convInfo.agente.nombres + " " + convInfo.agente.apellidos
        : convInfo.agente.nombre
      : "No existe el campo agente",
    nickName: convInfo.agente
      ? convInfo.agente.nickname
        ? convInfo.agente.nickname
        : "------"
      : "No existe el campo agente",
    emailAgente: convInfo.agente
      ? convInfo.agente.mail
        ? convInfo.agente.mail
        : "------"
      : "No existe el campo agente",
    cliente: convInfo.cliente.nombre + " - " + contacto,
    canal: convInfo.canal,
    asunto: convInfo.asunto ? convInfo.asunto : "Sin Asunto",
    temaNivel1:
      convInfo.tema && convInfo.tema.nivel1 ? convInfo.tema.nivel1 : "Sin tema",
    temaNivel2:
      convInfo.tema && convInfo.tema.nivel2 ? convInfo.tema.nivel2 : "Sin tema",
    temaNivel3:
      convInfo.tema && convInfo.tema.nivel3 ? convInfo.tema.nivel3 : "Sin tema",
    encuestaRating:
      convInfo.encuesta && convInfo.encuesta.ratingStars
        ? convInfo.encuesta.ratingStars
        : "",
    encuestaConocimientos:
      convInfo.encuesta && convInfo.encuesta.conocimientos
        ? convInfo.encuesta.conocimientos
        : "",
    encuestaAtencion:
      convInfo.encuesta && convInfo.encuesta.atencion
        ? convInfo.encuesta.atencion
        : "",
    encuestaSatisfaccion:
      convInfo.encuesta && convInfo.encuesta.satisfaccion
        ? convInfo.encuesta.satisfaccion
        : "",
    resuelta: convInfo.resuelta ? "Si" : "No",
  };
  return { infoOrdenada };
}

function* adjuntarArchivosUpload(event) {
  try {
    let idConversation = event.value.id;
    let conversation = event.value.conversation;
    let archivos = event.value.$event.files;
    let timestampDate = Date.now();

    const company = getFromSession("company_id");
    let parametros = yield armarJSONparametros(
      company,
      idConversation,
      conversation,
      archivos,
      timestampDate
    );
    for (const parametro of parametros) {
      let toastDetail = {
        summary: "Archivos Enviados",
        severity: "success",
        detail: "Archivo: " + parametro.nombre,
      };
      yield enviarMensajeToUser(
        parametro.idConversation,
        parametro.conversation,
        parametro.tipo,
        parametro.nombre,
        parametro.url
      );
      yield put(adjuntarArchivosUploadSuccess(toastDetail));
    }
  } catch (error) {
    console.error("saga.js- adjuntarArchivosUpload", error);
  }
}

function* changeUserState(action) {
  let currentUser = getFromSession("currentUser");
  const { payload } = action;
  if (action.meta.field === "ausente") {
    const estado = payload ? 1 : 3;
    let resp = yield setEstado(estado);
    if (resp) {
      //console.log('storeCurrenteUser', currentUser);
      currentUser["estado"] = payload ? 1 : 3;
      //console.log('storeCurrenteUserUpdated', currentUser);
      storeInSession("currentUser", JSON.stringify(currentUser));
    }
  }
}

function* initToogleState(action) {
  yield put(initialize("switchUserState", { ausente: true }));
}

function* obtenerReferenciaConvs(company, limit, first, page, ultimaConv, nextPage) {
  let user = getFromSession("currentUser");
  //let fecha = new Date();
  let reference;
  let totalConversations = 0;
  //fecha.setDate(fecha.getDate() - 60);
  if (user.rol === "AGENTE") {
    reference = firebaseDatabase.collection(`company/${company}/conversations`).where("agente.mail", "==", user.mail)
      //.where("last_message", ">", fecha)
      .orderBy("last_message", "desc");

    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    const queryTotAgen_ = query(coll, where("agente.mail", "==", user.mail));
    totalConversations = yield getCountFromServer(queryTotAgen_);
    totalConversations = totalConversations?._data?.count ? totalConversations._data.count : 0;

    if (first === 0) {
      reference = false;
    } else if (first > 0) {
      if (nextPage) {
        reference = reference.startAfter(ultimaConv.last_message).limit(limit)
      } else {
        reference = reference.endBefore(ultimaConv.last_message).limitToLast(limit)
      }
    }
  } else {
    reference = firebaseDatabase
      .collection(`company/${company}/conversations`)
      //.where("last_message", ">", fecha)
      .orderBy("last_message", "desc");

    const coll = collection(firebaseDatabase, `company/${company}/conversations`);
    const queryTot_ = query(coll);
    totalConversations = yield getCountFromServer(queryTot_);
    totalConversations = totalConversations?._data?.count ? totalConversations._data.count : 0;

    if (first == 0) {
      reference = false;
    } else if (first > 0) {
      if (nextPage) {
        reference = reference.startAfter(ultimaConv.last_message).limit(limit)
      } else {
        reference = reference.endBefore(ultimaConv.last_message).limitToLast(limit)
      }
    }
  }
  return { reference, totalConversations };
}

function* paginarConversaciones(action) {
  try {

    const { first, rows, page } = action.value.event;
    const { ultimaConv, nextPage, bigQuery } = action.value;

    const company = getFromSession("company_id");
    if (bigQuery) {
      let consulta = yield select(getConsultaBigQuery);
      consulta.limit = rows;
      consulta.page = first;

      yield put(guardarConsultaBigQuery({ ...consulta }))
      let convsFiltradas = yield filtrarConversacionesPorTextoBigQuery(consulta);
      console.log(convsFiltradas)
      if (convsFiltradas.conversations.length > 0) {
        yield put(filterConversationslistSuccess({ "convsFiltradas": convsFiltradas.conversations, esPrimeraPagina: false, paginada: true }));

      } else {
        //limpiar y regresar al registro de datos de la bandeja
        yield put(filterConversationslistSuccess({ "convsFiltradas": false }));
      }
    } else if (ultimaConv) {
      let reference = yield obtenerReferenciaConvs(company, rows, first, page, ultimaConv, nextPage);

      let conversations = [];
      let esPrimeraPagina = false;
      if (reference && reference.reference) {
        yield reference.reference.get().then((response) => {
          //console.log('response.size', response.size);
          response.forEach(doc => {
            conversations.push(doc);
          });
        })
      } else {
        esPrimeraPagina = true;
      }

      const response = { "conversacionesPaginadas": [...conversations], "esPrimeraPagina": esPrimeraPagina, totalConversations: reference.totalConversations }
      yield put(paginateConversationsSuccess({ ...response }));
    }
  } catch (error) {
    console.error('saga.agentPanel.paginarConversaciones', error);
    throw error;
  }

}

function* loadMenu(action) {
  let list = [];
  let company = getFromSession("company_id");
  let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItems.forEach((menu) => {
    if (menu.roles.includes(rol) && menu.configuration === enviosMasivos) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

export function* consultarDialogFlow() {
  const company = getFromSession("company_id");
  let dialogFlowProject = yield consultaProperty(company, 'DIALOGFLOW_PROJECT');
  storeInSession('dialogFlowProject', dialogFlowProject);
}

export function* watchAgent() {
  yield takeLatest(GET_CONVERSATIONS, getConversations);
  yield takeLatest(SEARCH_CONVERSATIONS, getConversations);
  yield takeLatest(SELECT_CONVERSATION, selectConversation);
  yield takeLatest(SELECT_CONVERSATION, selectConversationMessages);
  yield takeLatest(FILTER_CONVERSATIONS, filterConversationslist);
  yield takeEvery(SEND_MESSAGE, sendMessage);
  yield takeLatest(TOMAR_CONTROL, tomarControlConversation);
  yield takeLatest(TRANSFER, transferirConversation);
  yield takeLatest(END_CONVERSATION, finalizarConversation);
  yield takeLatest(SET_TEMA, fijarTemaConversation);
  yield takeLatest(RESUELTA, fijarResuelta);
  yield takeLatest(LISTAR_TEMAS, listarTemas);
  yield takeLatest(LISTAR_USUARIOS_CONECTADOS, listarUsuariosConectados);
  yield takeLatest(ADJUNTAR_ARCHIVOS, adjuntarArchivosUpload);
  yield takeLatest(LISTAR_ESTADOS, listarEstados);
  yield takeLatest(LISTAR_AGENTES, listarAgentes);
  yield takeLatest(LANZAR_EVENTOS, desplegarEventosBuscador);
  yield takeLatest(LISTENER_LOGOUT, sessionTimeOutLogout);
  yield takeLatest(LISTAR_FAQS, listarFaqs);
  yield takeLatest(REDUX_FORM_CHANGE, changeUserState);
  yield takeLatest(INIT_TOGGLE_STATE, initToogleState);
  yield takeLatest(PRINT_CONVERSATION, printConversation);
  yield takeLatest(TRANSFERENCIAS_CONVERSACION, obtenerTransferenciasConversacion);
  yield takeLatest(NOTAS_OCULTAS, obtenerNotasOcultasConv);
  yield takeLatest(LOAD_MENU, loadMenu);
  yield takeLatest(ADD_NOTA_OCULTA, addNotaOcultaConv);
  yield takeLatest(PAGINATE_CONVERSATIONS, paginarConversaciones);
  yield takeLatest(CONSULTAR_DIALOG_FLOW_PROPERTY, consultarDialogFlow);
}
