// import exp from "constants";

export const UPDATE_FIELD_ATTRIBUTE = '@informesPanel/updateAttribute';

export const MOSTRAR_RANGOS = '@informesPanel/mostrarCamposRangos';
export const OCULTAR_RANGOS = '@informesPanel/ocultarCamposRangos';
export const PARAMETER_URL = '@informesPanel/parameterUrl';
//------------GRAFICAS-----------------
export const DATA_LINECHART = '@informesPanel/obtenerDatosGraficaLineChart';
export const DATA_LINECHART_SUCCESS = '@informesPanel/obtenerDatosGraficaLineChartSuccess';
export const DATA_LINECHART_ERROR = '@informesPanel/obtenerDatosGraficaLineChartError';

export const TMPESP_PROM = '@informesPanel/obtenerTmpPromEspConvsCerradas';
export const TMPESP_PROM_SUCCESS = '@informesPanel/obtenerTmpPromEspConvsCerradasSuccess';
export const TMPESP_PROM_ERROR = '@informesPanel/obtenerTmpPromEspConvsCerradasError';

export const TMPRESP_PROM = '@informesPanel/obtenerTmpPromRespConvsCerradas';
export const TMPRESP_PROM_SUCCESS = '@informesPanel/obtenerTmpPromRespConvsCerradasSuccess';
export const TMPRESP_PROM_ERROR = '@informesPanel/obtenerTmpPromRespConvsCerradasError';


export const TMPCONV_PROM = '@informesPanel/obtenerTmpPromConv';
export const TMPCONV_PROM_SUCCESS = '@informesPanel/obtenerTmpPromConvSuccess';
export const TMPCONV_PROM_ERROR = '@informesPanel/obtenerTmpPromConvError';


export const CONVS_RESUELTAS = '@informesPanel/obtenerConvsCerradasResueltas';
export const CONVS_RESUELTAS_SUCCESS = '@informesPanel/obtenerConvsCerradasResueltasSuccess';
export const CONVS_RESUELTAS_ERROR = '@informesPanel/obtenerConvsCerradasResueltasError'


export const CONVS_CANAL = '@informesPanel/obtenerCanalConversacion';
export const CONVS_CANAL_SUCCESS = '@informesPanel/obtenerCanalConvsCerradasSuccess';
export const CONVS_CANAL_ERROR = '@informesPanel/obtenerCanalConvsCerradasError';


export const CONVS_CERRADAS = '@informesPanel/obtenerConvsCerradas';
export const CONVS_CERRADAS_SUCCESS = '@informesPanel/obtenerConvsCerradasSuccess';
export const CONVS_CERRADAS_ERROR = '@informesPanel/obtenerConvsCerradasError';

export const CONVS_CERRADAS_AGENTE = '@informesPanel/obtenerConvsCerradasAgentes';
export const CONVS_CERRADAS_AGENTE_SUCCESS = '@informesPanel/obtenerConvsCerradasAgenteSuccess';


export const SELECT_CONVERSATIONDETAIL = '@informesPanel/selectConversationDetail';
export const SELECT_CONVERSATIONDETAIL_SUCCESS = '@informesPanel/selectConversationDetailSuccess';
export const SELECT_CONVERSATIONDETAIL_ERROR = '@informesPanel/selectConversationDetailError';

export const FILTRAR_BUSQUEDA = '@informesPanel/filtrarBusqueda';
export const FILTRAR_BUSQUEDA_SUCCESS = '@informesPanel/filtrarBusquedaSuccess';
export const FILTRAR_BUSQUEDA_ERROR = '@informesPanel/filtrarBusquedaError';


export const LISTAR_ESTADOS = '@informesPanel/listarEstados';
export const LISTAR_ESTADOS_SUCCESS = '@informesPanel/listarEstadosSuccess';
export const LISTAR_AGENTES = '@informesPanel/listarAgentes';
export const LISTAR_AGENTES_SUCCESS = '@informesPanel/listarAgentesSucess';

export const LANZAR_EVENTOS = '@informesPanel/desplegarEventosBuscador';

export const CONVS_ABANDONADAS = '@informesPanel/obtenerDataPieConvsAbandonadas';
export const CONVS_ABANDONADAS_SUCCESS = '@informesPanel/obtenerDataPieConvsAbandonadasSuccess';
export const CONVS_ABANDONADAS_ERROR = '@informesPanel/obtenerDataPieConvsAbandonadasError';

export const CONVS_AGENTES = '@informesPanel/obtenerDataConvsAgentes';
export const CONVS_AGENTES_SUCCESS = '@informesPanel/obtenerDataConvsAgentesSuccess';
export const CONVS_AGENTES_ERROR = '@informesPanel/obtenerDataConvsAgentesError';

export const CONVS_NOTAS_OCULTAS = '@informesPanel/obtenerDataNotasOcultas';
export const CONVS_NOTAS_OCULTAS_SUCCESS = '@informesinformesPanel/obtenerDataNotasOcultasSuccess';
export const CONVS_NOTAS_OCULTAS_ERROR = '@informesPanel/obtenerDataNotasOcultasError';
export const SAVE_DATA_NOTAS_OCULTAS = '@informesPanel/saveDataNotasOcultas';
export const MESSAGE_DATA_CERO_NOTAS = 'No se encuentran estadísticas de Notas ocultas para el rango seleccionado';

export const CONVS_TRANSFERENCIAS = '@informesPanel/obtenerDataTranferencias';
export const CONVS_TRANSFERENCIAS_SUCCESS = '@informesinformesPanel/obtenerDataTranferenciasSuccess';
export const CONVS_TRANSFERENCIAS_ERROR = '@informesPanel/obtenerDataTranferenciasError';
export const SAVE_DATA_TRANSFERENCIAS = '@informesPanel/saveDataTransferencias';
export const MESSAGE_DATA_CERO_TRANFERENCIAS = 'No se encuentran estadísticas de Transferencias para el rango seleccionado';

export const CONVS_CONECTED_USERS = '@informesPanel/obtenerDataConectedUsers';
export const CONVS_CONECTED_USERS_SUCCESS = '@informesinformesPanel/obtenerConectedUsersSuccess';
export const CONVS_CONECTED_USERS_ERROR = '@informesPanel/obtenerDataConectedUsersError';
export const MESSAGE_DATA_CERO_CONECTED_USERS = 'No se encuentran estadísticas de Conexiones de usuarios para el rango seleccionado';

export const CONVS_ATENDIDAS_CHATBOT = '@informesPanel/obtenerPieConvsAtendidasChatBot';
export const CONVS_ATENDIDAS_CHATBOT_SUCCESS = '@informesPanel/obtenerPieConvsAtendidasChatBotSuccess';
export const CONVS_ATENDIDAS_CHATBOT_ERROR = '@informesPanel/obtenerPieConvsAtendidasChatBotError';

export const CONVS_CHATBOT = '@informesPanel/obtenerChatbot';
export const CONVS_CHATBOT_SUCCESS = '@informesinformesPanel/obtenerChatbotSuccess';
export const CONVS_CHATBOT_ERROR = '@informesPanel/obtenerChatbotError';
export const MESSAGE_DATA_CERO_CHATBOT = 'No se encuentran estadísticas de Chatbot para el rango seleccionado';

export const CONVS_COLAS_ATENCION = '@informesPanel/obtenerColasAtencion';
export const CONVS_COLAS_ATENCION_SUCCESS = '@informesinformesPanel/obtenerColasAtencionSuccess';
export const CONVS_COLAS_ATENCION_ERROR = '@informesPanel/obtenerColasAtencionError';
export const TITLE_TABLE_COLAS_ATENCION = 'Tabla data colas de atención';
export const MESSAGE_COLAS_ATENCION_CERO = 'No se encuentran estadísticas de Colas de Atención para el rango seleccionado';

//export const CONVS_AGENTES = '@informesPanel/obtenerDataConvsAgentes';
export const TABLE_AGENTES_SUCCESS = '@informesPanel/obtenerDatatTableAgentesSuccess';
export const TABLE_AGENTES_ERROR = '@informesPanel/obtenerDataConvsAgentesError';
export const TITLE_TABLE_CONTACT = 'Tabla de conversaciones por agente';
export const TITLE_TABLE_CONTACT2 = 'Tabla de conversaciones fuera de horario por agente';
export const MESSAGE_DATA_CERO_AGENTE = 'No se encuentran estadísticas de Agentes para el rango seleccionado';

export const GENERAL_ERROR = '@informesPanel/generalError';
export const OCULTAR_MODAL_DESCARGAR = '@informesPanel/cerradModalDescargarDatos';

export const MODAL_DESCARGAR_DATOS = '@informesPanel/abrirModalDescargarDatos';
export const MODAL_DESCARGAR_DATOS_SUCCESS = '@informesPanel/abrirModalDescargarDatosSuccess';
export const MODAL_DESCARGAR_DATOS_ERROR = '@informesPanel/abrirModalDescargarDatosError'


export const SINGLE_BUSQUEDA = '@informesPanel/singleBusqueda';
export const SINGLE_BUSQUEDA_SUCCESS = '@informesPanel/singleBusquedaSuccess';
export const SINGLE_BUSQUEDA_ERROR = '@informesPanel/singleBusquedaError';



export const GRAFICOS_TEMAS = '@informesPanel/obtenerDatosGraficaTemas';
export const GRAFICOS_TEMAS_SUCCESS = '@informesPanel/obtenerDatosGraficaTemasSuccess';
export const GRAFICOS_TEMAS_ERROR = '@informesPanel/obtenerDatosGraficaTemasError';
export const LIMPIAR_FORM = '@informesPanel/limpiarForm';
export const TOAST_MESSAGE = '@informesPanel/showToast';

export const OCULTAR_ESTADISTICAS = '@informesPanel/ocultarEstadisticasConvsAbandonadas';
export const OCULTAR_ESTADISTICAS_SUCCESS = '@informesPanel/ocultarEstadisticasConvsAbandonadasSuccess';


export const SELECCION_FORM_ESTADISTICA = '@informesPanel/obtenerOpcionFormEstadisticas';
export const SELECCION_FORM_ESTADISTICA_SUCCESS = '@informesPanel/obtenerOpcionFormEstadisticasSuccess';
export const MODAL_ESPERA_DATA = '@informesPanel/modalEsperaData';

export const TABLE_NOTAS_TITLE = 'Tabla de datos para notas ocultas';
export const TABLE_TRANSFERENCIAS_TITLE = 'Tabla de datos generales tranferencias';
export const TABLE_TRANSFERENCIAS_CHATBOT_TITLE = 'Tabla de datos tranferencias de ChatBot a Agentes';
export const TABLE_CONECTEDUSERS_TITLE = 'Tabla de datos para conexiones de usuarios';
export const TABLE_CHATBOT_TITLE = 'Tabla de datos conversaciones chatbot';
export const TABLE_CHATBOT_INTENT_TITLE = 'Tabla de datos por respuestas de chatbot';
export const TABLE_CHATBOT_MESSAGES_UNKNOWN_TITLE = 'Tabla de datos por respuestas no contestadas de chatbot';
export const TABLE_CHATBOT_CALIFICATION_TITLE = 'Tabla de datos por comentarios de insatisfacción de chatbot';

export const BAR_CHATBOT_TITLE = 'Tasa de coicidencias de chatbot';
export const BAR_AGENTE_TITLE = 'Conversaciones por agente por día';
export const BAR_AGENTE_TITLE2 = 'Conversaciones totales por agente';
export const BAR_AGENTE_TITLE3 = 'Conversaciones fuera de horario';
export const BAR_TRANSFERENCIAS_TITLE = 'Transferencias totales de Chatbot a Agentes';

export const PIE_CHATBOT_TITLE = 'Top 10 de respuestas de chatbot';
export const PIE_INTENTS_AGRUPADOS = 'Usos de intents agrupados por temas';
export const PIE_VOTOS_PRESUPUESTOS = 'Total de votos por cada localidad'
export const GRAFICA_QUERY_SENTIMENT = "@informesPanel/obtenerDataGraficaQuerySentiment";
export const GRAFICA_QUERY_SENTIMENT_SUCCESS= "@informesPanel/obtenerDataGraficaQuerySentimentSuccess"
export const GRAFICA_QUERY_SENTIMENT_ERROR="@informesPanel/obtenerDataGraficaQuerySentimentError"
export const GRAFICA_ENCUESTA_GENERAL = "@informesPanel/obtenerDatosGraficaEncuesta";
export const GRAFICA_ENCUESTA_GENERAL_SUCCESS ="@informesPanel/obtenerDatosGraficaEncuestaSuccess";
export const GRAFICA_ENCUESTA_GENERAL_ERROR = "@informesPanel/obtenerDatosGraficaEncuestaError";
export const FILTRAR_RANGOS_FECHA ="@informesPanel/filtrarRangoFecha";
export const PAGINAR_CONVS_CERRADAS ="@informesPanel/paginarConvsCerradas";
export const PAGINAR_CONVS_CERRADAS_SUCCESS = "@informesPanel/paginarConvsCerradasSuccess"
export const PAGINAR_CONVS_CERRADAS_ERROR = "@informesPanel/paginarConvsCerradasError"
export const DESCARGAR_CSV_CONVS_CERRADAS = "@informesPanel/descargarCSVconvsCerradas"
export const DESCARGAR_CSV_CONVS_CERRADAS_SUCCESS = "@informesPanel/descargarCSVconvsCerradasSuccess"
export const DESCARGAR_CSV_CONVS_CERRADAS_ERROR = "@informesPanel/descargarCSVconvsCerradasError"
export const REPORTE_WS_FALLIDOS = "@informesPanel/obtenerDataWsFallidos"
export const REPORTE_WS_FALLIDOS_SUCCESS ="@informesPanel/obtenerDataWsFallidosSuccess"
export const REPORTE_WS_FALLIDOS_ERROR ="@informesPanel/obtenerDataWsFallidosError"
export const DATOS_CONVS_CHATBOT = "@informesPanel/obtenerDataConvsChatbot";
export const DATOS_CONVS_CHATBOT_SUCCESS = "@informesPanel/obtenerDataConvsChatbotSuccess";
export const DATOS_CONVS_CHATBOT_ERROR = "@informesPanel/obtenerDataConvsChatbotError";
export const PAGINAR_DATA_CONVS_CHABOT = "@informesPanel/paginarDatosConvsChatbot";
export const PAGINAR_DATA_CONVS_CHABOT_SUCCESS = "@informesPanel/paginarDatosConvsChatbotSuccess";
export const PAGINAR_DATA_CONVS_CHABOT_ERROR = "@informesPanel/paginarDatosConvsChatbotError";
export const DATOS_CONVS_PRESUPUESTOS= "@informesPanel/obtenerDataConvsPresupuestos";
export const DATOS_CONVS_PRESUPUESTOS_SUCCESS = "@informesPanel/obtenerDataConvsPresupuestosSuccess";
export const PAGINAR_DATA_CONVS_PRESUPUESTOS = "@informesPanel/paginarDatosConvsPresupuestos";
export const PAGINAR_DATA_CONVS_PRESUPUESTOS_SUCCESS = "@informesPanel/paginarDatosConvsPresupuestosSuccess";
export const PAGINAR_DATA_CONVS_PRESUPUESTOS_ERROR = "@informesPanel/paginarDatosConvsPresupuestosError";

export const DATOS_RESP_UNKNOWN_BOT = "@informesPanel/obtenerDatosRespUnknownBot";
export const DATOS_RESP_UNKNOWN_BOT_SUCCESS = "@informesPanel/obtenerDatosRespUnknownBotSuccess";
export const DATOS_RESP_UNKNOWN_BOT_ERROR = "@informesPanel/obtenerDatosRespUnknownBotBotError";
export const PAGINAR_DATA_UNKNOWNRESP_BOT = "@informesPanel/paginarDatosRespUnknownBot";
export const PAGINAR_DATA_UNKNOWNRESP_BOT_SUCCESS = "@informesPanel/paginarDatosRespUnknownBotSuccess";
export const PAGINAR_DATA_UNKNOWNRESP_BOT_ERROR = "@informesPanel/paginarDatosRespUnknownBotError";
export const DATOS_INTENTS_PORCENTAJE = "@informesPanel/obtenerDatosIntentsPorcentajeUso";
export const DATOS_INTENTS_PORCENTAJE_SUCCESS = "@informesPanel/obtenerDatosIntentsPorcentajeUsoSuccess";
export const DATOS_INTENTS_PORCENTAJE_ERROR= "@informesPanel/obtenerDatosIntentsPorcentajeUsoError";
export const GRAFICA_TASA_COINCIDENCIAS ="informesPanel/obtenerDataTasaCoincidenciaBot";
export const GRAFICA_TASA_COINCIDENCIAS_SUCCESS ="informesPanel/obtenerDataTasaCoincidenciaBotSuccess";
export const GRAFICA_TASA_COINCIDENCIAS_ERROR ="informesPanel/obtenerDataTasaCoincidenciaBotError";
export const DATOS_COMENTARIOS_INSATISFACCION ="informesPanel/obtenerDataComentariosInsatisfaccion";
export const DATOS_COMENTARIOS_INSATISFACCION_SUCCESS ="informesPanel/obtenerDataComentariosInsatisfaccionSuccess";
export const DATOS_COMENTARIOS_INSATISFACCION_ERROR ="informesPanel/obtenerDataComentariosInsatisfaccionError";
export const PAGINAR_COMENTARIOS_INSATISFACCION = "informesPanel/paginarDataComentariosInsatisfaccion";
export const PAGINAR_COMENTARIOS_INSATISFACCION_ERROR = "informesPanel/paginarDataComentariosInsatisfaccionError";
export const PAGINAR_COMENTARIOS_INSATISFACCION_SUCCESS = "informesPanel/paginarDataComentariosInsatisfaccionSuccess";
export const GENERAR_DATA_CSV = "informesPanel/generarCsv";
export const GENERAR_DATA_CSV_SUCCESS = "informesPanel/generarCsvSuccess";
export const DATOS_GRAFICA_TOP_10 = "informesPanel/obtenerDatosGraficaTop10";
export const DATOS_GRAFICA_TOP_10_SUCCESS = "informesPanel/obtenerDatosGraficaTop10Success";
export const DATOS_GRAFICA_TOP_10_ERROR = "informesPanel/obtenerDatosGraficaTop10Error";
export const TMP_PROM_AGENTES = "informesPanel/obtenerTmpPromAgentes";
export const TMP_PROM_AGENTES_SUCCESS = "informesPanel/obtenerTmpPromAgentesSuccess";
export const CONVS_RESUELTAS_AGENTES = "informesPanel/obtenerConvsResueltasAgentes";
export const CONVS_RESUELTAS_AGENTES_SUCCESS = "informesPanel/obtenerConvsResueltasAgentesSuccess";
export const CONVS_RESUELTAS_AGENTES_ERROR = "informesPanel/obtenerConvsResueltasAgentesError";
export const GRAFICO_PROM_RESP_AGENTES = "informesPanel/graficarTmpPromRespAgentes";
export const GRAFICO_PROM_RESP_AGENTES_SUCCESS = "informesPanel/graficarTmpPromRespAgentesSuccess";
export const GRAFICO_PROM_ESP_AGENTES = "informesPanel/graficarTmpPromEspAgentes";
export const GRAFICO_PROM_ESP_AGENTES_SUCCESS = "informesPanel/graficarTmpPromEspAgentesSuccess";
export const TABLES_PROM_AGENT = "informesPanel/tablesPromAgent";
export const CONVERSACIONES_SERVICIOS_EXTERNOS = "@inofrmesPanel/conversacioneServiciosExternos";

export const SEND_TABLA_DATOS_REPORTE_HUECOS = "@informesPanel/sendTablaDatosReporteHuecos"
export const SEND_GRAFICO_DATOS_REPORTE_HUECOS = "@informesPanel/sendGraficoDatosReporteHuecos"
export const SEND_GRAFICO_DATOS_REPORTE_HUECOS_SUCCESS = "@informesPanel/sendGraficoDatosReporteHuecosSuccess"

export const GET_GRAFICO_DATOS_REPORTE_HUECOS = "@informesPanel/getGraficoDatosReporteHuecos"
export const SEND_TABLA_DATOS_ZONA_PARQUEO = "@informesPanel/sendTablaDatosZonaParqueo"
export const GET_TABLA_DATOS_ZONA_PARQUEO = "@informesPanel/getTablaDatosZonaParqueo"
export const SEND_TABLA_DATOS_REGISTRO_GAB = "@informesPanel/sendTablaDatosRegistroGAB"
export const GET_TABLA_DATOS_REGISTRO_GAB = "@informesPanel/getTablaDatosRegistroGAB"

export const SEND_TABLA_DATOS_ATENCION_CANAL = "@informesPanel/sendTablaDatosAtencionCanal"
export const GET_TABLA_DATOS_ATENCION_CANAL = "@informesPanel/getTablaDatosAtencionCanal"

export const SEND_TABLA_DATOS_ATENCION_AGENTE = "@informesPanel/sendTablaDatosAtencionAgente"
export const GET_TABLA_DATOS_ATENCION_AGENTE = "@informesPanel/getTablaDatosAtencionAgente"



export const CSV_CONVS_CHATBOT = "informesPanel/generarCsvConvsChatbotSuccess"; 
export const CSV_DATA_UNKNOWN_BOT= "informesPanel/generarCsvDataUnknownBotSuccess";
export const CSV_DATA_INSATISFACCION= "informesPanel/generarCsvDataInsatisfaccionSuccess";

export const ENCUESTAS_AGENTES = "informesPanel/encuentasAgente";
export const ENCUESTAS_AGENTES_SUCESS = "informesPanel/encuentasAgenteSucess"
export const ENCUESTAS_AGENTES_ERROR = "informesPanel/encuentasAgenteError"

export const PAGINAR_CONVS_CERRADAS_AGENTES_SUCCESS = "@informesPanel/paginarConvsCerradasAgentesSuccess"
export const PAGINAR_CONVS_CERRADAS_AGENTES_ERROR = "@informesPanel/paginarConvsCerradasAgentesError"
export const PAGINAR_CONVS_CERRADAS_AGENTES ="@informesPanel/paginarConvsCerradasAgentes";
export const DESCARGAR_CSV_CONVS_CERRADAS_AGENTES = "@informesPanel/descargarCSVconvsCerradasAgentes"
export const DESCARGAR_CSV_CONVS_CERRADAS_AGENTES_SUCCESS = "@informesPanel/descargarCSVconvsCerradasAgentesSuccess"

export const FILTRAR_BUSQUEDA_AGENTE = '@informesPanel/filtrarBusquedaAgente';
export const FILTRAR_BUSQUEDA_AGENTE_SUCCESS = '@informesPanel/filtrarBusquedaAgenteSuccess';
export const FILTRAR_BUSQUEDA_AGENTE_ERROR = '@informesPanel/filtrarBusquedaAgenteSuccess';
export const SAVE_SEARCH_BIGQUERY_TABLE = '@informesPanel/saveSearchBigQueryTable';
export const SAVE_SEARCH_TABLE_GENERALES = '@informesPanel/saveSearchTableGenerales';

export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "content-type": "application/json;charset=iso-8859-1",
    "Cache-Control": "no-cache",
    
}

export const HEADER_REPORTE_WS_FALLIDOS = [

    { field: "fechaRegistro", header: "Fecha registro", sortable: true },
    { field: "fechaFalloRegistro", header: "Fecha fallo", sortable: false },
    { field: "flujoWS", header: "Flujo", sortable: false },
    { field: "canal", header: "Canal", sortable: false },
    { field: "telefono", header: "contacto", sortable: false },
    { field: "numeroDoc", header: "Documento", sortable: false },
    { field: "intent", header: "Intent de origen", sortable: false },
    { field: "nombreCausa", header: "Nombre causa", sortable: false },
    { field: "estado", header: "Estado", sortable: false },
    { field: "idConv", header: "id Conversacion", sortable: true },
   


]
export const HEADER_TABLE_NOTAS = [
    { field: "fecha", header: "Fecha nota", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "notaOculta", header: "Nota oculta", sortable: false },
    { field: "autorNo", header: "Autor nota", sortable: true },
    { field: "rolAutorNo", header: "Rol Autor", sortable: false },
    { field: "habilidad", header: "Habilidad autor", sortable: false },
    { field: "agente", header: "Agente conversacíon", sortable: false },
    { field: "correoAgente", header: "Correo agente", sortable: false },
    { field: "skillName", header: "Habilidad agente", sortable: false },
]
export const HEADER_TABLE_REPORTE_HUECOS = [
    { field: "fecha", header: "Fecha reporte", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "telefono", header: "N° Telefono", sortable: false },
    { field: "correo", header: "Correo electrónico", sortable: true },
    { field: "clasificacion", header: "Clasificación hueco", sortable: false },
    { field: "latitud", header: "Latitud", sortable: false },
    { field: "longitud", header: "Loingitud", sortable: false },
    { field: "exitosoFile", header: "Reporte exitoso", sortable: false },
    { field: "exitosoReport", header: "Adjunto exitoso", sortable: false },
    { field: "idReport", header: "Id reporte", sortable: false },
    { field: "idFile", header: "Id adjunto", sortable: false },
]

export const HEADER_TRANSFERENCIAS_CHATBOT=[
    { field: "fecha", header: "Fecha transferencia", sortable: true },
    { field: "total", header: "Total transferencias desde chatbot", sortable: true },
];

export const HEADER_DATOS_VOTOS=[
    { field: "fecha", header: "Fecha", sortable: true },
    { field: "canal", header: "Canal", sortable: true },
    { field: "codigoPro", header: "Código propuesta", sortable: true },
    { field: "titulo", header: "Nombre propuesta", sortable: true },
    { field: "tema", header: "Tema", sortable: true },
    { field: "nombreLocalidad", header: "Localidad", sortable: true },
    { field: "cedula", header: "Documento", sortable: true },
    { field: "telefono", header: "Telefono", sortable: true },
]

export const HEADER_DATOS_VOTOS_TOP_THREE=[
    
    { field: "nameLo", header: "Localidad", sortable: true },
    { field: "tema", header: "Tema", sortable: true },
    { field: "propuesta", header: "Nombre propuesta", sortable: true },
    { field: "votos", header: "Toltal de votos", sortable: true },
]

export const HEADER_DATOS_VOTOS_PIE=[
    
    { field: "nameLo", header: "Localidad", sortable: true },
    { field: "votos", header: "Toltal de votos", sortable: true },
]

export const HEADER_DATOS_PROM_AGENTES=[
    
    { field: "nameAgent", header: "Nombre de agente", sortable: true },
    { field: "promAgen", header: "Promedio de tiempo", sortable: true },
]

export const HEADER_PORCENTAJES_VOTACION =[
    { field: "nombreCausa", header: "Nombre causa", sortable: true },
    { field: "numeroVotos", header: "Numero votos", sortable: true },
    { field: "porcentaje", header: "Porcentaje", sortable: true },

]
export const HEADER_TABLE_TRANSFERENCIAS = [
    { field: "fecha", header: "Fecha transferencia", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "agente", header: "Agente conversacíon", sortable: true },
    { field: "nickname", header: "Nickname agente", sortable: true },
    { field: "correoAgente", header: "Correo agente", sortable: false },
    { field: "usr_origen", header: "Usuario origen", sortable: false },
    { field: "habilidadorigen", header: "Habilidad de origen", sortable: false },
    { field: "usr_destino", header: "Usuario destino", sortable: false },
    { field: "habilidadDestino", header: "Habilidad de destino", sortable: false },
    { field: "cliente", header: "Nombre cliente",sortable: false },
    { field: "mailcliente", header: "Correo cliente", sortable: false },
]
export const HEADER_TABLE_CONECTEDUSERS = [
    { field: "fechaini", header: "Fecha conexión inicial", sortable: true },
    { field: "fechafin", header: "Fecha conexión final", sortable: true },
    { field: "agente", header: "Agente conversacíon", sortable: true },
    { field: "correoAgente", header: "Correo agente", sortable: false },
    { field: "nickname", header: "Nickname agente", sortable: false },
    { field: "conv_actuales", header: "N° conversaciones actuales", sortable: false },
    { field: "conv_atendidas", header: "N° conversaciones atendidas", sortable: false },
    { field: "conv_maximas", header: "N° máximo de conversaciones", sortable: false },
    { field: "estado", header: "Estado ", sortable: true },
];

export const HEADER_TABLE_COLAS_ATENCION = [
    { field: "fecha_ini_cola", header: "Fecha inicio cola", sortable: true },
    { field: "fecha_fin_cola", header: "Fecha fin cola", sortable: true },
    { field: "tiempoEs", header: "Tiempo espera (h:m:s:ms)", sortable: true },
    { field: "agente", header: "Agente", sortable: false },
    { field: "correoAgente", header: "Correo agente", sortable: false },
    { field: "clienteName", header: "Nombre cliente", sortable: false },
    { field: "correoCliente", header: "Correo cliente", sortable: false },
    { field: "cellCliente", header: "Telefono cliente", sortable: false },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "skillName", header: "SkillName", sortable: false },
     
];

export const HEADER_TABLE_CHATBOT = [
    { field: "fecha", header: "Fecha conexión", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "user_message", header: "Mensaje usuario", sortable: false },
    { field: "constestada", header: "Constestada", sortable: true },
    { field: "intentName", header: "Intent", sortable: false },
    
]

export const HEADER_TABLE_CHATBOT_INTENT = [
    { field: "intent", header: "Respuesta chatbot", sortable: true },
    { field: "total", header: "N° total respuestas", sortable: true },
    { field: "porcentaje", header: "Porcentaje %", sortable: false },
];

export const HEADER_TABLE_CHATBOT_MESSAGES_UNKNOWN = [
    { field: "fecha", header: "Fecha conexión", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "user_message", header: "Mensaje usuario", sortable: false },
];

export const HEADER_TABLE_CHATBOT_CALIFICATON = [
    { field: "fecha", header: "Fecha conexión", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "calification", header: "Calificación", sortable: false },
    { field: "comentario", header: "Comentario insatisfaccion", sortable: false },
];

export const HEADER_TABLE_ZONA_PARQUEO = [
    { field: "fecha", header: "Fecha reporte", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "telefono", header: "N° Telefono", sortable: false },
    { field: "correo", header: "Correo electrónico", sortable: true },
    { field: "razon", header: "Razon", sortable: false },
    { field: "latitud", header: "Latitud", sortable: false },
    { field: "longitud", header: "Loingitud", sortable: false },
    { field: "exitosoFile", header: "Reporte exitoso", sortable: false },
    { field: "exitosoReport", header: "Adjunto exitoso", sortable: false },
    { field: "idReport", header: "Id reporte", sortable: false },
    { field: "idFile", header: "Id adjunto", sortable: false },
];
export const HEADER_TABLE_REGISTRO_GAB = [
    { field: "fecha", header: "Fecha reporte", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "numeroDoc", header: "N° Documento", sortable: false },
    { field: "nombre", header: "Nombre", sortable: false },
    { field: "apellidos", header: "Apellido", sortable: false },
    { field: "telefono", header: "N° Telefono", sortable: false },
    { field: "correo", header: "Correo electrónico", sortable: true },
    { field: "latitud", header: "Latitud", sortable: false },
    { field: "longitud", header: "Loingitud", sortable: false },
    { field: "exitosoReport", header: "Reporte exitoso", sortable: false },
    { field: "idConv", header: "Id Convesación", sortable: false },
];
export const HEADER_TABLE_ATENCION_AGENTE = [
    { field: "fecha", header: "Fecha reporte", sortable: true },
    { field: "agente", header: "Asesor", sortable: false },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "hora", header: "Hora intervalo", sortable: false },
    { field: "interaccionesAtendidas", header: "Interacciones atendidas", sortable: false },
    { field: "tiempoProd", header: "Tiempo productivo*", sortable: false },
    { field: "tiempoTotal", header: "Tiempo Total*", sortable: false },
    { field: "tiempoEspera", header: "Tiempo de espera*", sortable: false },
    { field: "tiempoRespuesta", header: "Tiempo de Respuesta* ", sortable: false },
    { field: "tiempoEsperaAgente", header: "Tiempo de Espera Agente* ", sortable: false },
];
export const HEADER_TABLE_ATENCION_CANAL = [
    { field: "fecha", header: "Fecha reporte", sortable: true },
    { field: "canal", header: "Canal conversación", sortable: false },
    { field: "hora", header: "Hora intervalo", sortable: false },
    { field: "InteraccionesOfrecidas", header: "Interacciones ofrecidas", sortable: false },
    { field: "interaccionesAtendidas", header: "Interacciones atendidas", sortable: false },
    { field: "abandonadas", header: "interacciones abandonadas", sortable: false },
    { field: "nivelAtencion", header: "NIVEL ATENCION", sortable: false },
    { field: "tasaAbandonoWeb", header: "Tasa abandono WEB", sortable: false },
    { field: "tiempoProductivo", header: "Tiempo productivo*", sortable: false },
    { field: "tiempoEspera", header: "Tiempo de espera*", sortable: false },
    { field: "tiempoTotal", header: "Tiempo total*", sortable: true },
];

export const HEADER_TABLE_CONTACT= [
    { field: 'f0_', header: 'Nombres Agente', sortable: true },
    { field: 'tot', header: 'Total conversaciones', sortable: false },
    { field: 'tiempoRespuesta', header: 'Tiempo de respuesta*', sortable: false },
    { field: 'tiempoEsperaAgente', header: 'Tiempo de Espera Agente*', sortable: false },

];

export const catalogoE=[
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "VALLEDUPAR",
            "Nombre": "12 DE OCTUBRE",
            "Dirección": "CARRERA 7A # 23 - 215 LOCAL 3",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PALMIRA",
            "Nombre": "AGUACLARA",
            "Dirección": "CARRERA 28 # 47-03 LOCAL 1",
            "LUNES A VIERNES": "06:00 - 19:00",
            "SÁBADO": "06:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ALBANIA (GUAJIRA)",
            "Nombre": "ALBANIA",
            "Dirección": "CALLE 3 # 3 - 60",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "VALLEDUPAR",
            "Nombre": "ALFONSO LOPEZ",
            "Dirección": "CARRERA 15 # 11- 85 SAN JOAQUIN",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ARMENIA",
            "Nombre": "ALTAMIRA",
            "Dirección": "CARRERA 14 # 7NORTE-82",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "ALTOS DE PRADO ESPECIAL MAC",
            "Dirección": "CARRERA 49C # 80-209",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ARMENIA",
            "Nombre": "ARMENIA ESPECIALIZADO MAC",
            "Dirección": "CALLE 1A NORTE # 13-42",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "ATLANTICO",
            "Dirección": "CARRERA 50 # 80 - 81",
            "LUNES A VIERNES": "07:00 - 17:30",
            "SÁBADO": "NA",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA MARTA",
            "Nombre": "AVENIDA LIBERTADOR",
            "Dirección": "AVENIDA LIBERTADOR # 14-123 LOCAL 101",
            "LUNES A VIERNES": "06:30 - 17:00",
            "SÁBADO": "06:30 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA MARTA",
            "Nombre": "AVENIDA QUINTA",
            "Dirección": "CARRERA 5 # 26 - 35 LOCAL 106 C.C QUINTA AVENIDA",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "AVENIDA SEXTA",
            "Dirección": "CLL 23 D N # 5C N - 64 LOCAL 01",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "FLORIDA",
            "Nombre": "BARI",
            "Dirección": "CARRERA 19 # 10-17",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANCAS",
            "Nombre": "BARRANCAS",
            "Dirección": "CALLE 11 # 9 - 65 LOCAL 4",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "BATACLAN",
            "Dirección": "CLL 25N # 3BIS - 43",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "VALLEDUPAR",
            "Nombre": "BILBAO",
            "Dirección": "CALLE 15 # 16 - 26",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BOSCONIA",
            "Nombre": "BOSCONIA",
            "Dirección": "CARRERA 19 # 14 - 20 LOCAL 1",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BUENAVENTURA",
            "Nombre": "BUENAVENTURA",
            "Dirección": "CRA 3A # 1 - 11",
            "LUNES A VIERNES": "07:30 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BUGA",
            "Nombre": "BUGA",
            "Dirección": "CARRERA 12 # 5-74",
            "LUNES A VIERNES": "06:00 - 18:00",
            "SÁBADO": "06:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGO",
            "Nombre": "CACERES",
            "Dirección": "CRA 14 NORTE # 17-40 LOCAL 2",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CAICEDONIA",
            "Nombre": "CAICEDONIA",
            "Dirección": "CRA 15 # 7-04 ESQUINA (CAICEDONIA VALLE)",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALARCA",
            "Nombre": "CALARCA",
            "Dirección": "CARRERA 24  # 40-59 LOCAL 01",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PALMIRA",
            "Nombre": "CALIMA",
            "Dirección": "CALLE 31 # 28-62 LOCAL 2",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PALMIRA",
            "Nombre": "CAPRI",
            "Dirección": "CALLE 27 CRUCE CON CARRERA 29 # 29 - 03",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "CARIBE",
            "Dirección": "CARRERA 47 # 82-44 LOCAL 101 C.C. ALIADOS DEL CARIBE",
            "LUNES A VIERNES": "07:00 - 17:30",
            "SÁBADO": "07:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGO",
            "Nombre": "CARTAGO ESPECIALIZADO MAC",
            "Dirección": "C.C. TORRES DE SAN FRANCISCO CARRERA 4 # 9-73 LOCAL 7",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTANDER DE QUILICHAO",
            "Nombre": "CELESTE",
            "Dirección": "CARRERA 9 # 5 - 71 LOCAL 2",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "VALLEDUPAR",
            "Nombre": "CENTRO LOPERENA",
            "Dirección": "DIAGONAL 16 # 14 - 61 VALLEDUPAR",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PEREIRA",
            "Nombre": "CIUDAD PEREIRA",
            "Dirección": "CALLE 25 # 9-48 CLINICA LOS ROSALES TORRE D",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ARMENIA",
            "Nombre": "CIUDAD REAL",
            "Dirección": "CARRERA 23 # 20-36",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "AGUSTIN CODAZZI",
            "Nombre": "CODAZZI",
            "Dirección": "CALLE 15 # 15-69 BARRIO EL CENTRO",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "COMPOSTELA",
            "Dirección": "CALLE 6 # 39 - 70",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "COROZAL",
            "Nombre": "COROZAL",
            "Dirección": "CARRERA 27 # 30-76 BARRIO SAN JUAN SECTOR CENTRO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "08:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "EL CERRITO",
            "Nombre": "EL CERRITO",
            "Dirección": "CALLE 7 # 12 - 20 EL CERRITO - VALLE",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PEREIRA",
            "Nombre": "EL LAGO",
            "Dirección": "CARRERA 7 # 25-35",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SABANALARGA (ATL)",
            "Nombre": "EL PUEBLO",
            "Dirección": "CALLE 22 # 17-53 SABANA LARGA",
            "LUNES A VIERNES": "07:00 - 17:30",
            "SÁBADO": "NA",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PASTO",
            "Nombre": "EL ROBLE",
            "Dirección": "CARRERA 29A # 18-53 BARRIO LAS CUADRAS",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "ESMERALDA",
            "Dirección": "CARRERA 37 # 5 B 4 - 64 BARRIO NUEVO SAN FERNANDO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "FONSECA",
            "Nombre": "FONSECA",
            "Dirección": "CALLE 14 # 15 - 79",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SINCELEJO",
            "Nombre": "FORD",
            "Dirección": "CARRERA 20 # 17-17 BARRIO FORD",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "FUNDACION",
            "Nombre": "FUNDACION",
            "Dirección": "CALLE 7 # 6-25",
            "LUNES A VIERNES": "07:00 - 16:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "GUACARI",
            "Nombre": "GUACARI",
            "Dirección": "CARRERA 7 # 6 - 67 GUACARI#I - VALLE",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "07:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BUGA",
            "Nombre": "GUADALAJARA",
            "Dirección": "CALLE 7 # 13-48 LOCAL 113 CC CONDADO PLAZA",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "MANIZALES",
            "Nombre": "IBIZA",
            "Dirección": "CALLE 51 # 24 - 50 CLINICA VERSALLES",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA MARTA",
            "Nombre": "LA ESPERANZA",
            "Dirección": "CARRERA 5 # 26-35 C.C. QUINTA AVENIDA LOCAL 105",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "LA FLORA ESPECIALIZADO MAC",
            "Dirección": "CALLE 38 NORTE # 4 N 182",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "LA JAGUA DE IBIRICO",
            "Nombre": "LA JAGUA DE IBIRICO",
            "Dirección": "CALLE 7 # 6 - 56 BARRIO OVIEDO JIMENEZ",
            "LUNES A VIERNES": "08:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PASTO",
            "Nombre": "LAS LAJAS",
            "Dirección": "CALLE 13 # 39-30 TORRE3 LOCAL 2",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "LA TEBAIDA",
            "Nombre": "LA TEBAIDA",
            "Dirección": "CALLE 12 # 9-18 LOCAL 3",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "LA VIRGINIA",
            "Nombre": "LA VIRGINIA",
            "Dirección": "CALLE 6 # 10 - 16 BARRIO SAN CAYETANO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "JAMUNDI",
            "Nombre": "LIBERTADORES",
            "Dirección": "CARRERA 10 # 13 - 11 BARRIO LIBERTADOR JAMUNDI",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "LIDO",
            "Dirección": "CRA. 39 # 3 - 40 BARRIO LIDO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "MANGA",
            "Dirección": "BARRIO MANGA CALLE 26 AV. ALFONSO ARAUJO # 22 - 50 ",
            "LUNES A VIERNES": "06:00 - 17:00",
            "SÁBADO": "06:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "MANIZALES",
            "Nombre": "MANIZALES ESPECIALIZADO MAC",
            "Dirección": "CRA  23 # 59-70 CC MULTICENTRO ESTRELLA LOCAL 3-4-5",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "MERCED",
            "Dirección": "CARRERA 38 # 54-107 LOCAL 2  BARRANQUILLA",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "TULUA",
            "Nombre": "MIRAVALLES",
            "Dirección": "CALLE 27 # 26 - 60 LOCALES 105 B Y 105 C",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SINCELEJO",
            "Nombre": "NAPOLES",
            "Dirección": "CALLE 15 # 13-110 AVENIDA SAN CARLOS",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "NAVARRA",
            "Dirección": "DIAGONAL 31 # 54 - 175 C.C. SANTA LUCIA",
            "LUNES A VIERNES": "06:00 - 17:00",
            "SÁBADO": "06:00 - 13:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CIENAGA",
            "Nombre": "NEVADA",
            "Dirección": "CALLE 12 # 11-32 LOCAL 3",
            "LUNES A VIERNES": "06:30 - 17:00",
            "SÁBADO": "06:30 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "DOS QUEBRADAS",
            "Nombre": "OMNES",
            "Dirección": "CARRERA 16 # 38-130 AVENIDA SIMON BOLIVAR  LOCAL 152 B C.C EL PROGRESO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PALMIRA",
            "Nombre": "PALMIRA",
            "Dirección": "CALLE 31 # 31-36",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "PASOANCHO",
            "Dirección": "CARRERA 100 # 5-169 LOCAL 805 PISO 3 TORRE PASO ANCHO CC UNICENTRO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "PEDRO DE HEREDIA",
            "Dirección": "ALCIBIA CALLE 31 # 39 -144 O CALLE 31 NUMERO 39 -132",
            "LUNES A VIERNES": "06:00 - 17:00",
            "SÁBADO": "06:00 - 14:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PEREIRA",
            "Nombre": "PEREIRA ESPECIALIZADO MAC",
            "Dirección": "AVENIDA 30 DE AGOSTO # 33 - 67  Y  33 - 69",
            "LUNES A VIERNES": "07:00 - 17:30",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "MANIZALES",
            "Nombre": "PLAZA 51",
            "Dirección": "AVENIDA SANTANDER CRA 23 # 61-12",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "CENTRO COMERCIAL ACUARELA",
            "Dirección": "CRA 80 # 13A-261 LOCAL P117 Y P118 CENTRO COMERCIAL ACUARELA",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "PLAZA SEVILLA",
            "Dirección": "CRA 43 # 69 F 57 LOCAL 3A AL 4B CC PLAZA SEVILLA",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PRADERA",
            "Nombre": "PRADERA",
            "Dirección": "CARRERA 8 # 7-21 LOCAL 04 (PRADERA, VALLE DEL CAUCA)",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "PRADO",
            "Dirección": "CALLE 74 # 50-41 PISO 1",
            "LUNES A VIERNES": "07:00 - 17:30",
            "SÁBADO": "07:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "PROVIDENCIA",
            "Dirección": "CRA 71 # 31B-25 URBANIZACION SANTA LUCIA",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "PUERTA DE ORO",
            "Dirección": "CARRERA 21 # 45-28",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "QUIMBAYA",
            "Nombre": "QUIMBAYA",
            "Dirección": "CALLE 21  # 5-07",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "RIOHACHA",
            "Nombre": "RIOHACHA",
            "Dirección": "CARRERA 11 # 11 - 13",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ROLDANILLO",
            "Nombre": "ROLDANILLO",
            "Dirección": "CARRERA 9 # 9 - 39",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "ROSARIO AMBULATORIO",
            "Dirección": "CALLE 10 # 33-51 FARMACIA CLINICA NTRA SEÑORA DEL ROSARIO",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "NA",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "SAF EL POZON",
            "Dirección": "CARRERA 88 # 55-21 LOCAL 1",
            "LUNES A VIERNES": "07:00 - 16:00",
            "SÁBADO": "08:00 - 11:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "TURBACO",
            "Nombre": "SAF TURBACO",
            "Dirección": "AVENIDA PASTRANA CRA 15 # 24-23 LOCAL 2 B",
            "LUNES A VIERNES": "06:30 - 16:30",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CARTAGENA",
            "Nombre": "SAN FELIPE",
            "Dirección": "CALLE 32 # 20 - 128  AVENIDA PEDRO DE HEREDIA",
            "LUNES A VIERNES": "06:30 - 18:00",
            "SÁBADO": "07:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PEREIRA",
            "Nombre": "SAN FERNANDO",
            "Dirección": "CARRERA 24 B # 69 BIS-19 (MANZANA 8 CASA 16  LOCAL 1) SAN FERNANDO CUBA",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SAN JUAN DEL CESAR",
            "Nombre": "SAN JUAN DEL CESAR",
            "Dirección": "CALLE 4 # 3-74 PISO 1",
            "LUNES A VIERNES": "07:00 - 16:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "SAN MARCOS CFV",
            "Dirección": "CALLE 75B # 20-170 C.C RIO CAUCA L 120 121 122 123 BARRIO VALLE GRANDE",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CANDELARIA (VALLE)",
            "Nombre": "SAN REMO",
            "Dirección": "CALLE 8 # 7-39",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA MARTA",
            "Nombre": "SANTA MARTA ESPECIALIZADO MAC",
            "Dirección": "CARRERA 5TA # 28-19 LOCAL 7",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA ROSA DE CABAL",
            "Nombre": "SANTA ROSA",
            "Dirección": "CALLE 17 # 14-48",
            "LUNES A VIERNES": "07:30 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "SEVILLANA",
            "Dirección": "CALLE 45 # 10 E 66",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "SOLEDAD",
            "Dirección": "CALLE 18 # 26-19 IPS INTEGRAL DE COLOMBIA (SOLEDAD)",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "SANTA MARTA",
            "Nombre": "TAYRONA",
            "Dirección": "CALLE 14 # 30 B-35 AVENIDA LIBERTADOR",
            "LUNES A VIERNES": "24 H",
            "SÁBADO": "24 H",
            "DOMINGOS Y FESTIVOS": "24 H"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "CALI",
            "Nombre": "TREBOL",
            "Dirección": "CALLE 48 # 12C-67",
            "LUNES A VIERNES": "07:00 - 19:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "TULUA",
            "Nombre": "VICTORIA",
            "Dirección": "CARRERA 32 A # 25 - 24",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "PEREIRA",
            "Nombre": "VIENA",
            "Dirección": "AV 30 AGOSTO CON CALLE 18 # 14-35 BARRIO OLAYA HERRERA LOCAL 1 BAJO LOCATEL",
            "LUNES A VIERNES": "06:30 - 17:00",
            "SÁBADO": "07:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "LA UNION (VALLE)",
            "Nombre": "VIÑEDOS",
            "Dirección": "CARRERA 14 # 13 - 22 EDIFICIO SANTA INES",
            "LUNES A VIERNES": "08:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "YUMBO",
            "Nombre": "YUMBO",
            "Dirección": "CARRERA 7 # 9 - 02 BARRIO URIBE",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "07:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "AUDIFARMA S.A.",
            "Ciudad": "ZARZAL",
            "Nombre": "ZARZAL",
            "Dirección": "CARRERA 8 # 8-14 ESQUINA",
            "LUNES A VIERNES": "07:00 - 17:00",
            "SÁBADO": "08:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "AGUACHICA",
            "Nombre": "AGUACHICA",
            "Dirección": "CRA 31 # 4A-76 LOCAL 5 EDIFICIO MELO",
            "LUNES A VIERNES": "7:00 - 12:00 y 14:00 - 18:00",
            "SÁBADO": "7:00 - 11:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "BUCARAMANGA",
            "Nombre": "CABECERA",
            "Dirección": "CRA 33 # 46 - 49",
            "LUNES A VIERNES": "6:00 - 22:00",
            "SÁBADO": "7:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "7:00 - 19:00"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "BARRANCABERMEJA",
            "Nombre": "CLINICA SAN JOSE (ALIADO)",
            "Dirección": "CALLE 47 # 28 - 05",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "OCAÑA",
            "Nombre": "COOMEVA OCAÑA",
            "Dirección": "CALLE 7 # 29-255 AVE FRANCISCO FERNANDEZ",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "BUCARAMANGA",
            "Nombre": "IPS MEDEX",
            "Dirección": "CALLE 48  #  26 - 24 PISO 6 EDIFICIO TITANIO PROFESIONAL B/ NUEVO SOTO MAYOR",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "CUCUTA ",
            "Nombre": "CUCUTA AV UNO",
            "Dirección": "AV.0 # 16-43 CAOBOS",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "6:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "CUCUTA ",
            "Nombre": "CUCUTA CENTRO",
            "Dirección": "AVDA 0 # 8-53 B LATINO",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "7:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "7:00 - 19:00"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "CUCUTA ",
            "Nombre": "BIOREUMA SAS (ALIADO)",
            "Dirección": "CALLE 13 A  # 2 E - 87 ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "GIRON",
            "Nombre": "GIRON",
            "Dirección": "CARRERA 25 # 29 - 25 ",
            "LUNES A VIERNES": "7:00 - 12:00 y 13:00 - 17:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "LEBRIJA",
            "Nombre": "LEBRIJA",
            "Dirección": "CLL 12 # 6 A 44 ",
            "LUNES A VIERNES": "7:00 - 12:00 y 13:00 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "BARBOSA",
            "Nombre": "OFFIMEDICAS BARBOSA",
            "Dirección": "CALLE 8 # 8-53",
            "LUNES A VIERNES": "7:00 - 12:00 y 14:00 - 18:00",
            "SÁBADO": "8:00 - 11:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "PIEDECUESTA",
            "Nombre": "PIEDECUESTA",
            "Dirección": "CALLE 7 # 6 -73 PRIMER PISO",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "PUENTE NACIONAL",
            "Nombre": "PUENTE NACIONAL ",
            "Dirección": "CARRERA 5 # 5 - 48 LOCAL 2 BARRIO COMUNEROS",
            "LUNES A VIERNES": "7:00 - 12:00 y 13:00 - 18:00",
            "SÁBADO": "NA",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "CALI",
            "Nombre": "MEDITEC CALI",
            "Dirección": "CRA 37 # 5B2 - 54",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "MEDITEC BOGOTÁ (ALIADO)",
            "Dirección": "CALLE 97 # 23 - 37",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDITEC MEDELLÍN",
            "Dirección": "CRA 80A # 32E - 72",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "CARTAGENA",
            "Nombre": "MEDITEC CARTAGENA (ALIADO)",
            "Dirección": "CALLE 31N # 58 - 38",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "MEDITEC BARRANQUILLA (ALIADO)",
            "Dirección": "CRA 44 # 72 -131",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDITEC CALIDAD EN SALUD SAS",
            "Ciudad": "PEREIRA",
            "Nombre": "MEDITEC PEREIRA (ALIADO)",
            "Dirección": "CALLE 19 # 5 -13",
            "LUNES A VIERNES": "7:30 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "PLAZA CENTRAL",
            "Dirección": "CARRERA 65 # 11 - 50 CC PLAZA CENTRAL LOCAL 40 PISO 2",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "6:00 - 20:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "RESTREPO",
            "Dirección": "TRANSVERSAL 21A #  21A - 34 SUR",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "6:00 - 20:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "SF COLSUBSIDIO CALLE 100",
            "Dirección": "AV CARRERA 45 # 101 - 03",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "DISPENSARIO MINUTO DE DIOS",
            "Dirección": "CRA 76 # 73A - 03",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "DROGUERIA COLSUBSIDIO AVENIDA 14",
            "Dirección": "CARRERA 13 # 60 - 90",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "SF OLAYA (NOCTURNO)",
            "Dirección": "Cr 19 B # 27 - 25  SUR",
            "LUNES A VIERNES": "20:00 - 8:00",
            "SÁBADO": "20:00 - 8:00",
            "DOMINGOS Y FESTIVOS": "20:00 - 8:00"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "FLORENCIA",
            "Nombre": "SF COLSUBIDIO JUAN XXIII",
            "Dirección": "CALLE 6 # 15-36",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 18:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "YOPAL",
            "Nombre": "SF COLSUBSIDIO YOPAL",
            "Dirección": "CALLE 8 # 23 – 98",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 17:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "NEIVA",
            "Nombre": "SF COLSUBSIDIO CASA NEIVA ",
            "Dirección": "CALLE 16A # 6 - 51",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "6:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "CAJA COLOMBIANA DE SUBSIDIO FAMILIAR COLSUBSIDIO",
            "Ciudad": "IBAGUE",
            "Nombre": "SF CADIZ",
            "Dirección": "CLL 40 # 4C - 23 MACARENA ",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "CAREPA",
            "Nombre": "MEDICAMENTOS POS CAREPA",
            "Dirección": "CALLE 81 # 76-06 ",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "TURBO",
            "Nombre": "MEDICAMENTOS POS TURBO",
            "Dirección": "CARRERA 14 # 98-22 ",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "ARBOLETES",
            "Nombre": "MEDICAMENTOS POS ARBOLETES",
            "Dirección": "CALLE 32 # 28 - 29 ",
            "LUNES A VIERNES": "8:00 - 12:00 y 13:00 - 17:00",
            "SÁBADO": "7:00 - 11:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "BARBOSA",
            "Nombre": "MEDICAMENTOS POS BARBOSA",
            "Dirección": "CARRERA 13 # 11-107 ",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "BELLO",
            "Nombre": "MEDICAMENTOS POS INTEGRADOS",
            "Dirección": "CARRERA 51N # 45-22",
            "LUNES A VIERNES": "24 HORAS",
            "SÁBADO": "24 HORAS",
            "DOMINGOS Y FESTIVOS": "24 HORAS"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "CALDAS",
            "Nombre": "MEDICAMENTOS POS CALDAS",
            "Dirección": "CALLE 127 SUR  # 50-36  ",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "ENVIGADO",
            "Nombre": "MEDICAMENTOS POS ENVIGADO",
            "Dirección": "CARRERA 48 # 19 SUR -29 PISO 2",
            "LUNES A VIERNES": "8:00 - 20:00",
            "SÁBADO": "8:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "ITAGUI",
            "Nombre": "MEDICAMENTOS POS ITAGUI N-2",
            "Dirección": "CARRERA 49 # 45-35 LOCAL 101",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "CAUCASIA",
            "Nombre": "MEDICAMENTOS POS CAUCASIA",
            "Dirección": "CARRERA 20 # 27-44 CC SAN LUCAS PLAZA LOCAL 110-111",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "YARUMAL",
            "Nombre": "MEDICAMENTOS POS YARUMAL",
            "Dirección": "CALLE 20 # 19-64",
            "LUNES A VIERNES": "8:00 - 19:00",
            "SÁBADO": "8:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "EL CARMEN DE VIBORAL",
            "Nombre": "MEDICAMENTOS POS CARMEN DE VIBORAL",
            "Dirección": "CARRERA 31 # 35-72 ED. PROMEDAN",
            "LUNES A VIERNES": "7:00 - 18:30",
            "SÁBADO": "7:00 - 13:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "GUARNE",
            "Nombre": "MEDICAMENTOS POS GUARNE",
            "Dirección": "CALLE 48 # 50-22 ",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "EL SANTUARIO",
            "Nombre": "MEDICAMENTOS POS SANTUARIO",
            "Dirección": "CALLE 48B # 50-78 ",
            "LUNES A VIERNES": "8:00 - 18:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "LA CEJA",
            "Nombre": "MEDICAMENTOS POS LA CEJA",
            "Dirección": "CALLE 21 # 20-20",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 19:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "LA UNION",
            "Nombre": "MEDICAMENTOS POS LA UNION",
            "Dirección": "CALLE 12 # 10-19",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:30 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MARINILLA",
            "Nombre": "MEDICAMENTOS POS MARINILLA",
            "Dirección": "CALLE 26 # 28-11 LOCAL 209 CC LOS SAUCES",
            "LUNES A VIERNES": "7:00 - 19:30",
            "SÁBADO": "7:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "RIONEGRO",
            "Nombre": "MEDICAMENTOS POS CORDOBA",
            "Dirección": "CARRERA 50 # 45-27 LOCAL 216 CC CORDOBA",
            "LUNES A VIERNES": "7:00 - 17:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "RIONEGRO",
            "Nombre": "MEDICAMENTOS POS SAN NICOLAS",
            "Dirección": "DIAGONAL 50B #46 - 33",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS SAN CRISTOBAL",
            "Dirección": "CARRERA 131 # 63–41",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "SAN PEDRO",
            "Nombre": "MEDICAMENTOS POS SAN PEDRO",
            "Dirección": "CARRERA 51 # 47-39",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:30 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "AMAGA",
            "Nombre": "MEDICAMENTOS POS AMAGA",
            "Dirección": "CALLE 49 # 51-07",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:30 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "SABANETA",
            "Nombre": "MEDICAMENTOS POS SABANETA",
            "Dirección": "CARRERA 43A # 71 SUR -73",
            "LUNES A VIERNES": "8:00 - 18:00",
            "SÁBADO": "8:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "SANTA ROSA",
            "Nombre": "MEDICAMENTOS POS SANTA ROSA",
            "Dirección": "CALLE 30 # 31-27 LOCAL 104-105 ED. VILLA REAL",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS LA 70",
            "Dirección": "CARRERA 70 # 26A-10",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "8:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS BOSQUES DE SAN PABLO (SF ROBLEDO)",
            "Dirección": "CALLE 78 # 79A-03",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "6:00 - 16:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS BELEN",
            "Dirección": "DIAGONAL 75B  # 6-105 LOCAL 259",
            "LUNES A VIERNES": "6:00 - 20:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS CENTRO N-2 (SF MEDELLÍN)",
            "Dirección": "CALLE 54  # 49-120 LOCAL 129-130 CENTRO COMERCIAL EL CID",
            "LUNES A VIERNES": "6:30 - 19:30",
            "SÁBADO": "6:30 - 14:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS PREPAGADA (LAURELES)",
            "Dirección": "CARRERA 66B # 32D-36",
            "LUNES A VIERNES": "7:00 - 20:00",
            "SÁBADO": "7:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "MEDICAMENTOS POS POBLADO",
            "Dirección": "CARRERA 43A # 18 SUR-135 LOCAL 460 CENTRO COMERCIAL SAO PAULO",
            "LUNES A VIERNES": "7:00 - 20:00",
            "SÁBADO": "7:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "PUERTO WILCHES",
            "Nombre": "PUERTO WILCHES",
            "Dirección": "CARRERA 3 # 4 - 45 BARRIO EL CENTRO",
            "LUNES A VIERNES": "8:00 - 12:00 y 14:00 - 18:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "CERETE",
            "Nombre": "OFFIMEDICAS CERETE",
            "Dirección": "CRA 14A # 15A 13 LOCAL 14",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "MONTELIBANO",
            "Nombre": "OFFIMEDICAS MONTELIBANO",
            "Dirección": "CRA 5 # 15 - 41",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "MONTERIA",
            "Nombre": "OFFIMEDICAS MONTERIA",
            "Dirección": "CARRERA 6  # 25 - 37",
            "LUNES A VIERNES": "7:00 - 20:00",
            "SÁBADO": "7:00 - 18:00",
            "DOMINGOS Y FESTIVOS": "8:00 - 16:00"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "MONTERIA",
            "Nombre": "OFFIMEDICAS SERVISALUD",
            "Dirección": "CARRERA 7 # 12 - 64 LOCAL 102",
            "LUNES A VIERNES": "7:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "PLANETA RICA",
            "Nombre": "OFFIMEDICAS PLANETA RICA",
            "Dirección": "CARRERA 10 # 17 - 55",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "8:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "QUIBDO",
            "Nombre": "MEDICAMENTOS POS QUIBDO",
            "Dirección": "CARRERA 4 # 30-37",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 18:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "CHIGORODO ",
            "Nombre": "MEDICAMENTOS POS CHIGORODO 1",
            "Dirección": "CARRERA 104A # 97A-65",
            "LUNES A VIERNES": "7:00 - 18:00",
            "SÁBADO": "7:00 - 14:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "CALI",
            "Nombre": "IPS MEDEX",
            "Dirección": "CRA 39 # 8A-09",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "CALI",
            "Nombre": "DISPENSACIÓN MEDEX",
            "Dirección": "CRA 40 # 6-50 LOCAL 101 ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "TULUÁ",
            "Nombre": "CLINIMAGENES (ALIADO)",
            "Dirección": "CRA 33 # 24 - 06 BARRIO ALVERNIA",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "BUGA",
            "Nombre": "VITALSALUD IPS (ALIADO)",
            "Dirección": "CRA 8A # 5 SUR 32 BARRIO ALBERGUE",
            "LUNES A VIERNES": "7:30 -12:30 / 14:00 18:00",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "PALMIRA",
            "Nombre": "CLINICA PALMA REAL",
            "Dirección": "CARRERA 28 # 44-35 ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "PASTO",
            "Nombre": "SOCIEDAD MEDICA INTEGRAL SIGLO XXI (ALIADO)",
            "Dirección": "CRA 42 #18 A - 56  PISO 8 OFICINA 802",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "TULUÁ",
            "Nombre": "DISPENSARIO MEDEX TULUÁ",
            "Dirección": "CALLE 26 # 35 - 13 LOCAL 404 TORRE NUEVA",
            "LUNES A VIERNES": "7:30 - 12:30 / 13:30 - 17:30",
            "SÁBADO": "9:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "PALMIRA",
            "Nombre": "DISPENSACION MEDEX",
            "Dirección": "CALLE 47 CON CRA 33 ESQUINA LOCAL 30 ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "PASTO",
            "Nombre": "DISPENSACION MEDEX",
            "Dirección": "CRA 34 #19 - 56 OFIC 102 EDIF. PARQUE VERSALLES",
            "LUNES A VIERNES": "7:30 -12:30 / 13:30  -17:30",
            "SÁBADO": "9:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "PEREIRA",
            "Nombre": "IPS MEDEX",
            "Dirección": "CARRERA 19 # 8-13 AV. JUAN B GUTIERREZ EDIFICIO TRIPOLI 4 PISO",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "MANIZALES",
            "Nombre": "IPS MEDEX (ALIADO)",
            "Dirección": "CRA 23 B 64-22 ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "MANIZALES",
            "Nombre": "MEDEX MANIZALES",
            "Dirección": "CARRERA 22 # 29 - 29 CENTRO COMERCIAL PARQUE CALDAS LOCAL PB 35D -36D",
            "LUNES A VIERNES": "7:30 - 13:00 Y 14:00 - 17:30",
            "SÁBADO": "9:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "BOGOTÁ D.C.",
            "Nombre": "IPS MEDEX",
            "Dirección": "CARRERA 49 # 94-75 B/CASTELLANA",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "FLORENCIA",
            "Nombre": "CORPOMEDICA (ALIADO)",
            "Dirección": "CARRERA 9  # 8 - 77 BARRIO PRADO",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "NEIVA",
            "Nombre": "UNIDAD ONCOLOGICA SURCOLOMBIANA (ALIADO)",
            "Dirección": "CALLE 9  # 13 - 24",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "IBAGUE",
            "Nombre": "CLINICA ONCOSALUD IPS SAS (ALIADO)",
            "Dirección": "CALL 35  # 4C -  28 BARRIO CADIZ",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "SABANA DE TORRES",
            "Nombre": "SABANA DE TORRES",
            "Dirección": "CALLE 18 # 10 - 29 BARRIO CARVAJAL",
            "LUNES A VIERNES": "8:00 - 12:30 y 14:30 - 18:30",
            "SÁBADO": "14:30 - 18:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "SAN ALBERTO",
            "Nombre": "SAN ALBERTO",
            "Dirección": "CARRERA 4  # 5 - 52  BARRIO CENTRO ",
            "LUNES A VIERNES": "7:00 - 13:00 y 14:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "SAN MARTIN",
            "Nombre": "SAN MARTIN",
            "Dirección": "CALLE 14 # 7-65 PISO 2",
            "LUNES A VIERNES": "8:00 - 12:00 y 14:00 - 18:00",
            "SÁBADO": "8:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "BARRANQUILLA",
            "Nombre": "IPS MEDEX",
            "Dirección": "CRA 47 # 82-189 BARRIO PORVENIR.",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "CARTAGENA",
            "Nombre": "IPS MEDEX",
            "Dirección": "CRA 69 # 31 - 139 LOCAL 402 , 403 Y 410 CENTRO DE SALUD Y NEGOCIOS RONDA REAL",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "MAICAO",
            "Nombre": "SOCIEDAD MEDICA CLINICA MAICAO S.A. (ALIADO)",
            "Dirección": "CALLE 16 # 6 - 41",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "SAN JUAN DEL CESAR",
            "Nombre": "CLINICA SOMEDA (ALIADO)",
            "Dirección": "CALLE 7 # 2 - 16",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "SINCELEJO",
            "Nombre": "CLINICA LAS PEÑITAS SAS (ALIADO)",
            "Dirección": "CARRERA 16 # 27-49",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "VALLEDUPAR",
            "Nombre": "IPS ESPECIALIZADA (ALIADO)",
            "Dirección": "CALLE 14 # 14-51 PISO 3 EDIFICIO MILENIO EN VALLEDUPAR ",
            "LUNES A VIERNES": "7:30 - 12:00",
            "SÁBADO": "NA",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "MEDELLIN",
            "Nombre": "IPS MEDEX",
            "Dirección": "CRA. 43A # 34 - 95 CENTRO COMERCIAL ALMA CENTRO",
            "LUNES A VIERNES": "7:30 - 17:30",
            "SÁBADO": "9:00 - 12:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "APARTADO",
            "Nombre": "MEDICAMENTOS POS APARTADO",
            "Dirección": "CALLE 100 # 97-88 LOCAL 104 SALUD PLAZA ",
            "LUNES A VIERNES": "7:00 - 12:00 y 13:00 - 17:00",
            "SÁBADO": "7:00 – 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "CURRULAO",
            "Nombre": "MEDICAMENTOS POS CURRULAO",
            "Dirección": "CALLE 50 # 49A-15 ",
            "LUNES A VIERNES": "8:00 - 19:00",
            "SÁBADO": "8:00 – 19:00 ",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "NUEVA COLONIA",
            "Nombre": "MEDICAMENTOS POS NUEVA COLONIA",
            "Dirección": "CARRERA 32 # 31A-2024",
            "LUNES A VIERNES": "7:00 - 19:30",
            "SÁBADO": "7:00 – 13:30",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "MEDELLIN",
            "Nombre": "CENTRO AMBULATORIO DE ADMINISTRACION DE MEDICAMENTOS",
            "Dirección": "CARRERA 69B # 24-215",
            "LUNES A VIERNES": "8:00 - 12:00 y 14:00 - 16:00",
            "SÁBADO": "8:00 - 12:00 y 14:00- 18:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS POS - DEMPOS",
            "Ciudad": "ISTMINA",
            "Nombre": "MEDICAMENTOS POS ISTMINA",
            "Dirección": "BARRIO OFFEL CLINICA MARIA CONCEPCIÓN ISTMINA",
            "LUNES A VIERNES": "L,M, J,V 7:30 – 12:00 y 14:00 – 17:30 y Mi 7:00 – 15:00",
            "SÁBADO": "7:00 – 15:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "OFFIMEDICAS S.A.",
            "Ciudad": "FLORIDABLANCA",
            "Nombre": "SIGEMA GESTIONAR ",
            "Dirección": "CARRERA 26 # 30-83 LOCAL 1 CAÑAVERAL",
            "LUNES A VIERNES": "6:00 - 19:00",
            "SÁBADO": "7:00 - 13:00",
            "DOMINGOS Y FESTIVOS": "NA"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "ARMENIA",
            "Nombre": "IPS MEDEX (ALIADO ",
            "Dirección": "AV BOLIVAR 1-133 LOCAL 11 CENTRO COMERCIAL BALYARES"
        },
        {
            "OPERADOR": "MEDICAMENTOS ESPECIALIZADOS SAS - MEDEX",
            "Ciudad": "ARMENIA",
            "Nombre": "MEDEX ARMENIA",
            "Dirección": "CARRERA 12 0 NORTE-20 EDIFICIO MEDISALUD  CONSULTORIO 905"
        }
    ]

export const catalogoSa= [
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip Aguachica",
        "DIRECCION": "Carrera 34 No.4 - 44 ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Riohacha",
        "NOMBRE": "Sip Albania",
        "DIRECCION": "Carrera 4 No. 7-17",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip Amagá",
        "DIRECCION": "Carrera 50 No. 49 - 23 Piso 2",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Apartado",
        "NOMBRE": "Sip Apartadó",
        "DIRECCION": "Calle 100 No. 99 - 15/17/37 Barrio Chinita",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Armenia",
        "NOMBRE": "Sip  Norte Armenia",
        "DIRECCION": "Carrera 13 No. 15 Norte -02 ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bogota",
        "NOMBRE": "Sip Barbosa",
        "DIRECCION": "Carrera 9 No.11 - 14 local 103. Edficio Claudia María ",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Barrancabermeja",
        "NOMBRE": "Sip Barrancabermeja",
        "DIRECCION": "Calle 52 No. 27- 65. Barrio Galán",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Barranquilla",
        "NOMBRE": "Sala Sip Norte",
        "DIRECCION": "Carrera 58 No. 74 - 30",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bogota",
        "NOMBRE": "Sip 76",
        "DIRECCION": "Carrera 16A No.  76-65",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bogota",
        "NOMBRE": "Sip Calle 80",
        "DIRECCION": "Calle 80 No. 92-27 ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bogota",
        "NOMBRE": "Sip Lourdes",
        "DIRECCION": "Calle 63 No. 9A-45  ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sala Sip Mega Sala ",
        "DIRECCION": "Carrera 27 No. 45-38",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip Cañaveral",
        "DIRECCION": "Carrera 24 No. 30 - 19. Cañaveral",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Buenaventura",
        "NOMBRE": "Sip Buenaventura",
        "DIRECCION": "Carrera 3 No. 1-25. Centro",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Buga",
        "NOMBRE": "Sip Buga",
        "DIRECCION": "Calle 6 No. 13 - 71",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Armenia",
        "NOMBRE": "Sip Caicedonia",
        "DIRECCION": "Calle 7 No. 14-49",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Cali",
        "NOMBRE": "Sip Norte Cali",
        "DIRECCION": "Av 4 No 23N-48",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cali",
        "NOMBRE": "Sip Imbanaco",
        "DIRECCION": "Calle 5 No. 38D - 153",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Palmira",
        "NOMBRE": "Sip Candelaria",
        "DIRECCION": "Carrera 7 No. 12 - 02",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Apartado",
        "NOMBRE": "Sip Carepa",
        "DIRECCION": "Carrera 78A No. 77 - 23 Promedan Carepa - barrio guamo.  calle de la alcaldía Carepa",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip Carmen de Viboral",
        "DIRECCION": "Carrera 31 # 35 - 76",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Cartagena",
        "NOMBRE": "Sip Manga",
        "DIRECCION": "Manga Avenida Alfonso Araujo 22-50",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cartagena",
        "NOMBRE": "Sip Plazuela",
        "DIRECCION": "CC. Shopinng Center La Plazuela. Local 4",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cartago",
        "NOMBRE": "Sip Cartago",
        "DIRECCION": "Calle 13 No. 2-02",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip Caucasia",
        "DIRECCION": "Carrera 11 No. 19 - 06 Local 101 y 102",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Palmira",
        "NOMBRE": "Sip Cerrito",
        "DIRECCION": "Carrera 10 # 5 - 09 Cerrito",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Apartado",
        "NOMBRE": "Sip Chigorodó",
        "DIRECCION": "Carrera  104A N° 97 A-55",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Santa Marta",
        "NOMBRE": "Sip Ciénaga",
        "DIRECCION": "Calle 12 No. 12-44",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Cucuta",
        "NOMBRE": "Sip Aliados Cúcuta",
        "DIRECCION": "Av. 1 No. 16-69. Barrio La Playa",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cucuta",
        "NOMBRE": "Sip Centro Cúcuta",
        "DIRECCION": "Calle 9 No. 0e-58. Barrio Latino",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Florencia",
        "NOMBRE": "Sip Florencia",
        "DIRECCION": "Carrera 15 con calle 15 esquina. Edificio de Sinergia Piso 1.",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Palmira",
        "NOMBRE": "Sip Florida",
        "DIRECCION": "Calle 8 No. 21- 25",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Riohacha",
        "NOMBRE": "Sip Fonseca",
        "DIRECCION": "Calle 16 No. 17-105",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Santa Marta",
        "NOMBRE": "Sip Fundación ",
        "DIRECCION": "Carrera 7 No. 11-57 ",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip Girón",
        "DIRECCION": "Carrera 25 No. 29-37. Centro ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip Guarne",
        "DIRECCION": "Calle 47 # 50-18 sector abedules frente al comando municipal",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Ibague",
        "NOMBRE": "Sip Ibagué ",
        "DIRECCION": "Carrera 5 No. 39-56 Barrio Macarena Parte Alta",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip La Ceja",
        "DIRECCION": "Calle 19 # 21- 60",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cartago",
        "NOMBRE": "Sip La Unión, Cartago",
        "DIRECCION": "Calle 14 No. 12 – 27",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip La Unión, Rionegro",
        "DIRECCION": "Calle 12   7-63",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Riohacha",
        "NOMBRE": "Sip Maicao ",
        "DIRECCION": "Carrera 13 No. 15-26",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Manizales",
        "NOMBRE": "Sip Manizales",
        "DIRECCION": "Calle 57 No. 24A - 31. Barrio Belén",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip Marinilla",
        "DIRECCION": "CALLE 27 N 28 - 11  LOCAL 104",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip Medellín La 70",
        "DIRECCION": "Carrera 70 No. 26 A - 10 Ingreso por Carrera 69 al frente del Coliseo Carlos Mauro Hoyos",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Monteria",
        "NOMBRE": "Sip Montelíbano",
        "DIRECCION": "Carrera 6 No. 16-10  Centro",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Monteria",
        "NOMBRE": "Sip Montería",
        "DIRECCION": "Cra. 12 No. 40 – 21 Av. Circunvalar.",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Neiva",
        "NOMBRE": "Sip Neiva",
        "DIRECCION": "Calle 13 No. 5-44. Barrio Centro ",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip Ocaña",
        "DIRECCION": "Carrera 33 No. 7A - 29. La Primavera",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Palmira",
        "NOMBRE": "Sip Palmira",
        "DIRECCION": "Carrera 28 44-73, bloque B, piso 1",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Pasto",
        "NOMBRE": "Sip Pasto Medicoop",
        "DIRECCION": "Calle 16 No. 35 -10  Edificio Rincon de Atocha",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Pereira",
        "NOMBRE": "Sip Pereira ( Lago Plaza)",
        "DIRECCION": "Carrera 7 No. 22-73",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip Piedecuesta",
        "DIRECCION": "Carrera 7 No. 7-15. Centro",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Palmira",
        "NOMBRE": "Sip Pradera",
        "DIRECCION": "Calle 7 No. 5 - 35",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Barrancabermeja",
        "NOMBRE": "Sip Puerto Wilches",
        "DIRECCION": "Carrera 6 No. 6-29. Barrio Ciénaga ",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Quibdo",
        "NOMBRE": "Sip Quibdó",
        "DIRECCION": "Carrera 29 No. 4 - 28",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip Rionegro",
        "DIRECCION": "Calle 51 # 50 - 34",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Tulua",
        "NOMBRE": "Sip Roldanillo",
        "DIRECCION": "Carrera 6 No. 6 - 52 ",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Barrancabermeja",
        "NOMBRE": "Sip Sabana De Torres",
        "DIRECCION": "Calle 12 No. 10-46. Barrio Centro",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Barranquilla",
        "NOMBRE": "Sip Sabanalarga",
        "DIRECCION": "Carrera 21No. 22A - 13",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Bucaramanga",
        "NOMBRE": "Sip San Alberto",
        "DIRECCION": "Carrera 4 No. 4-41. Centro",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Riohacha",
        "NOMBRE": "Sip San Juan",
        "DIRECCION": "Calle 7 No. 2 -121. Edificio Coomeva",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip San Pedro",
        "DIRECCION": "Cra. 49   46 - 04 Centro Comercial el Faro Local 114 ",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Santa Marta",
        "NOMBRE": "Sip Libertador",
        "DIRECCION": "Calle 14 No.14 - 13",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Santa Marta",
        "NOMBRE": "Sip Integral  Santa Marta",
        "DIRECCION": "Calle 24 No. 2 - 150",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip Santa Rosa De Osos",
        "DIRECCION": "Carrera 28 No. 28 - 52 Local 103",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Rionegro",
        "NOMBRE": "Sip Santuario, Rionegro",
        "DIRECCION": "CRA 49 # 51 - 11 ED. ALCALA 3 PISO 304",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Sincelejo",
        "NOMBRE": "No Pos Sincelejo",
        "DIRECCION": "Calle Las Flores  Carrera19 No. 16A- 87 Piso 1",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Tulua",
        "NOMBRE": "Sip Tuluá ",
        "DIRECCION": "Carrera 30 No. 27- 20. Piso 3",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cartagena",
        "NOMBRE": "Sip Turbaco ",
        "DIRECCION": "Turbaco calle del cerro #11-26",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Apartado",
        "NOMBRE": "Sip Turbo",
        "DIRECCION": "Carrera 14 No. 98 A - 22 Promedan Turbo Piso 2",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Valledupar",
        "NOMBRE": "Sip No Pos Valledupar - Alfonso López",
        "DIRECCION": "Carrera  No.  19 11-57 Esquina Barrio Alfonso López",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Medellin",
        "NOMBRE": "Sip Yarumal",
        "DIRECCION": "Calle 20 No. 21 - 33",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Bogota",
        "NOMBRE": "Sip Yopal ",
        "DIRECCION": "Carrera 25 No. 10 - 55",
        "HORARIO": "Lunes a viernes de 7 a 3 pm Jornada continua"
    },
    {
        "CIUDAD ": "Cali",
        "NOMBRE": "Sip Yumbo",
        "DIRECCION": "Calle 6 No. 4 – 43",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    },
    {
        "CIUDAD ": "Tulua",
        "NOMBRE": "Sip Zarzal",
        "DIRECCION": "Carrera 11 # 12-79",
        "HORARIO": "Lunes a viernes de 7am  a 11 y de 1 a 3 pm "
    }
]
