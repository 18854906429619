import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, PATH_API } from '../../Constants';

import { 
  GET_USERS, VERIFICATED_EMAIL,
} from './constants';

import { 
  getUsersSuccess,
  getUsersError,
} from './actions';

import { applyActionCode } from "firebase/auth";

import { firebaseDatabase, firebaseAuthentication } from "../../controller/firebase";

function* getUsers() {
  const urlUsers = `${ PATH_API }${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
      method: 'GET',
  });



  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if(fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const {data} = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* bienvenidaPage(payload){
 // console.log("payload", payload)
  let applyActionResponse = false;
  try{
    applyActionResponse = yield applyActionCode(firebaseAuthentication, payload.value);
    console.log("applyActionResponse",applyActionResponse)
  }catch(e){
    if(e.message){
      let errorUSer = false;
     // console.log("Erorr bienvenidaPage", e.message)
      errorUSer = "Activación invalida"
      console.log("Erorr bienvenidaPage", errorUSer)
      return errorUSer;
    }
    yield put(getUsersError(e));
  }
}

export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(VERIFICATED_EMAIL, bienvenidaPage);
}
