import React, { Component } from 'react'

export default class InfoItem extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { itemLabel, itemValue } = this.props;

        return (

            <div className="row info-chat-row">
                <div className="col-5 pr-0">
                    <h6 className="mb-0">{itemLabel}</h6>
                </div>
                <div className="col-7 pl-0">
                    <label>{itemValue}</label>
                </div>
            </div>
        )
    }
}
