import "./chatCard.css";
import React, { Component } from 'react';
import moment from "moment";
import Switch from "react-switch";
import { Rating } from 'primereact/rating';
import { getFromSession } from "../../controller/session";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabMenu } from 'primereact/tabmenu';
import { Card } from 'primereact/card';
import { convertMsToTime, convertMsToTimeObj } from "../../controller/utilidades";
import Cronometro from "./cronometro/cronometro";
import InfoItem from "./infoItem";
import { firebaseField, Timestamp } from "../../controller/firebase";
import { format, utcToZonedTime } from 'date-fns-tz'
import "./info.scss";


export default class Info extends Component {
  componentInterval = false;
  constructor(props) {
    super(props)
    this.state = {
      duracion: 0
    }

    this.handleEvent = this.handleEvent.bind(this)
  }

  componentDidMount() {
    const titleInfo = document.querySelector('.tabsMenuInfo .pi-info');
    titleInfo.title = 'Información general';
    const titleUsers = document.querySelector('.tabsMenuInfo .pi-users');
    titleUsers.title = 'Transferencias';
    const titleNotes = document.querySelector('.tabsMenuInfo .pi-pencil');
    titleNotes.title = 'Notas Ocultas';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.name !== this.state.name) { this.handler() }

  }

  // Prototype methods, Bind in Constructor (ES2015)
  handleEvent() { }

  // Class Properties (Stage 3 Proposal)
  handler = () => { this.setState() }

  bodyNota = (doc) => {


    return <Card className='bodyNotasCard mb-1' subTitle={doc.fecha}>
      <div className="pl-2 pr-2 p-text-left">{doc.nota}</div>
      <div className='notaAutor mt-2 '>

        <div className='p-text-capitalize '><b>Autor:</b> {doc.autor}</div>
      </div>

    </Card>
  }

  render() {
    const { conversation, resuelta, id,
      listarUsuariosconectados, listarTemas, endConversation, tomarControlConversation,
      listarFaqs, printConversation, listaTransferencias, listaNotas, obtenerTransferenciasConv, showInfoGeneral,
      showTransferencias, showNotasOcultas, activarInfoGeneral, activarNotasOcultas, abrirModalNotasOcultas } = this.props;

    let estado;
    let imgCanal;
    let fechaIni;
    let tooltip;
    let fechaLastMessage;
    let fechaCierre;
    let fechaAceptacion;
    let fechaAsignacion;
    let closedBy;
    let fechaAbandono;
    let tema;
    let fechaActual;
    let nombreAgente;
    let aceptacionDate;
    let cierreDate;
    let duracionConversacion;
    let duracionConversacionObj;
    let asignacionDate;
    let fechaFinAsesor;
    let duracionConAsesor;
    let suscribeContactar = 0;
    let aceptaTerminos = 0;

    let user = getFromSession('currentUser');
    let rolCurrentUser = user ? user.rol : false;

    const itemsMenu = [
      { icon: 'pi pi-fw pi-info', command: () => { activarInfoGeneral() } },
      { icon: 'pi pi-fw pi-users', command: () => { obtenerTransferenciasConv(id) } },
      { icon: 'pi pi-fw pi-pencil', command: () => { activarNotasOcultas(id) } }

    ];

    let clienteConv = false;
    if (conversation) {
      clienteConv = conversation.cliente;

      if (conversation.estado === 0) {
        estado = (
          <div>
            <img
              className="icon-table2"
              src="/assets/icons/circle-yellow.png"
            />
            <span className="ml-2">En espera</span>
          </div>
        );
      } else if (conversation.estado === 10 || conversation.estado === 11) {
        estado = (
          <div>
            <img className="icon-table2" src="/assets/icons/circle-red.png" />
            <span className="ml-2">Cerrada</span>
          </div>
        );
      } else if (conversation.estado === 12) {
        estado = (
          <div>
            <img className="icon-table2" src="/assets/icons/circle-gray.png" />
            <span className="ml-2">Abandonada</span>
          </div>
        );
      }
      else if (conversation.estado > 0 && conversation.estado < 10) {
        estado = (
          <div>
            <img className="icon-table2" src="/assets/icons/circle-green.png" />
            <span className="ml-2">Abierta</span>
          </div>
        );
      }

      if (conversation.canal === "whatsapp") {
        imgCanal = "/assets/icons/whatsapp.png";
        tooltip = "Whatsapp";
      } else if (conversation.canal === "web") {
        imgCanal = "/assets/icons/world-wide-web.svg";
        tooltip = "Web";
      } else if (conversation.canal === "facebook") {
        imgCanal = "/assets/icons/logo-facebook.png";
        tooltip = "Facebook";
      }



      aceptacionDate = conversation.fecha_aceptacion?.toDate().getTime()
      cierreDate = conversation.fecha_fin?.toDate().getTime()
      fechaActual = conversation.db_server_date?.toDate().getTime()
      asignacionDate = conversation.fecha_asignacion?.toDate().getTime()

      fechaIni = conversation.fecha_ini?.toDate();
      fechaIni = moment(fechaIni).format("ll, h:mm:ss a");
      fechaLastMessage = conversation.last_message ? conversation.last_message.toDate() : new Date();
      fechaLastMessage = moment(fechaLastMessage).format("ll, h:mm:ss a");
      closedBy = conversation.closed_by ? conversation.closed_by : false;

      fechaCierre = conversation.fecha_fin ? conversation.fecha_fin.toDate() : false;
      fechaAbandono = conversation.fecha_abandono ? conversation.fecha_abandono.toDate() : false;

      fechaAceptacion = conversation.fecha_aceptacion ? moment(conversation.fecha_aceptacion.toDate()).format("ll, h:mm:ss a") : false;
      fechaAsignacion = conversation.fecha_asignacion ? moment(conversation.fecha_asignacion.toDate()).format("ll, h:mm:ss a") : false;

      if (typeof conversation.suscribeContactar == "boolean")
        suscribeContactar = conversation.suscribeContactar ? "Activada" : "Inactiva";

      if (typeof conversation.aceptaTerminos == "boolean")
        aceptaTerminos = conversation.aceptaTerminos ? "Acepta" : "No acepta";


      if (conversation.canal === "whatsapp" && conversation.fecha_fin_asesor) {
        fechaFinAsesor = conversation.fecha_fin_asesor?.toDate().getTime()

      } else if (conversation.canal === "web" && conversation.fecha_fin) {
        fechaFinAsesor = conversation.fecha_fin?.toDate().getTime()
      }

      if (conversation.canal === "whatsapp" && conversation.fecha_aceptacion) {
        duracionConAsesor = fechaFinAsesor - aceptacionDate
        duracionConAsesor = convertMsToTime(duracionConAsesor)

      } else if (conversation.canal === "web" && conversation.fecha_aceptacion) {
        duracionConAsesor = fechaCierre - aceptacionDate
        duracionConAsesor = convertMsToTime(duracionConAsesor)
      }

      fechaCierre = fechaCierre ? moment(fechaCierre).format("ll, h:mm:ss a") : "";
      fechaAbandono = fechaAbandono ? moment(fechaAbandono).format("ll, h:mm:ss a") : "";
      fechaFinAsesor = fechaFinAsesor ? moment(fechaFinAsesor).format("ll, h:mm:ss a") : "";


      if (aceptacionDate) {
        duracionConversacionObj = aceptacionDate - asignacionDate;
      }

      if (aceptacionDate && cierreDate) {
        duracionConversacion = cierreDate - aceptacionDate;
        duracionConversacion = convertMsToTime(duracionConversacion)
      }

      if (conversation.tema) {
        if (conversation.tema.nivel1 && !conversation.tema.nivel2 && !conversation.tema.nivel3) {
          tema = conversation.tema.nivel1;
        } else if (conversation.tema.nivel1 && conversation.tema.nivel2 && !conversation.tema.nivel3) {
          tema = (<div>Nivel1: {conversation.tema.nivel1}<br />
            Nivel2: {conversation.tema.nivel2}
          </div>);
        } else if (conversation.tema.nivel1 && conversation.tema.nivel2 && conversation.tema.nivel3) {
          tema = (<div>Nivel1: {conversation.tema.nivel1}<br />
            Nivel2: {conversation.tema.nivel2}<br />
            Nivel3: {conversation.tema.nivel3}<br />
          </div>);
        }

      } else {
        tema = 'Sin tipificación'
      }


      if (conversation.agente && conversation.agente.nombres) {
        nombreAgente = conversation.agente.nombres

      } else if (conversation.agente && conversation.agente.nombre) {
        nombreAgente = conversation.agente.nombre
      } else {
        nombreAgente = 'Sin asignar'
      }
    }

    return (



      <div className="card ">
        <div className="card-header d-flex col-md-12 align-items-end cardInfoConv">
          <h4 className="mb-1 sr-only">Conversación</h4>
          <div className="dropdown ml-auto btnAcciones">
            <a
              className="btn btn-link btn-xs btn-action"
              href="#"
              role="button"
              id="dropdownMenuLink5"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>Acciones</span>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuLink5"
            >
              <a
                className="dropdown-item"
                href="#"
                onClick={() => listarTemas()}
              >Fijar Tema</a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => abrirModalNotasOcultas()}
              >Añadir nota oculta</a>
              <a
                className="dropdown-item"
                href="#"
                onClick={() => printConversation({ id, conversation })}
              >Descargar conversación</a>
              {conversation.estado >= 10 ? (<div>
              </div>) : (
                <div>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => listarFaqs({})}
                  >FAQs
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => listarUsuariosconectados({})}
                  >Transferir Conversación
                  </a>

                  {rolCurrentUser === 'SUPERVISOR' ?
                    (<div>
                      <a
                        className="dropdown-item"
                        href="#"
                        onClick={() => tomarControlConversation({ id, conversation })}
                      >Tomar control</a>
                    </div>) :
                    (<div></div>)}

                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => endConversation({ id, conversation })}
                  >Cerrar Conversación</a>
                </div>
              )}

            </div>
          </div>
        </div>
        <div className="card-header headerTab">
          <TabMenu model={itemsMenu} className="tabsMenuInfo" />
        </div>

        <div className="card-body mb-0 pb-0 mt-0 pt-1 pl-0 pr-1" >
          {showInfoGeneral ? (
            <div id="infoGeneral">
              <div className="row flex-row mr-0 ml-0 px-0">
                <div className="col-md-12 pl-0 px-0">
                  <div className="row info-chat-row">
                    <div className="col-5 pr-0">
                      <h6 className="mb-0">Resuelta:</h6>
                    </div>
                    <div className="col-7 pl-0">
                      <Switch
                        checked={
                          conversation.resuelta ? conversation.resuelta : false
                        }
                        onChange={(value) => resuelta({ id, value })}
                        handleDiameter={20}
                        offColor="#E8233E"
                        onColor="#07C64F"
                        offHandleColor="#FD7C6F"
                        onHandleColor="#6FDE99"
                        height={20}
                        width={50}
                        className="react-switch"
                        id="small-radius-switch"
                      />
                    </div>
                  </div>

                  <InfoItem itemLabel={"Estado:"} itemValue={estado} />
                  <div className="row info-chat-row">
                    <div className="col-5 pr-0">
                      <h6 className="mb-0">Canal:</h6>
                    </div>
                    <div className="col-7 pl-0">
                      <img
                        className="little-icon"
                        src={imgCanal}
                        data-toggle="tooltip"
                        title={tooltip}
                      />
                    </div>
                  </div>
                  <InfoItem
                    itemLabel={"Nombre:"}
                    itemValue={clienteConv ? `${clienteConv.nombre} - ${clienteConv.telefono ? clienteConv.telefono : ""} ${clienteConv.mail} ${clienteConv.tipoId}` : ''}
                  />

                  <InfoItem itemLabel={"Agente:"} itemValue={nombreAgente} />

                  <InfoItem itemLabel={"Fecha Inicio:"} itemValue={fechaIni} />

                </div>
              </div>

              <InfoItem itemLabel={"Fecha último mensaje:"} itemValue={fechaLastMessage} />

              {fechaAsignacion ? (

                <InfoItem itemLabel={"Fecha Asignacion:"} itemValue={fechaAsignacion} />

              ) : null}

              {fechaAceptacion ? (


                <InfoItem itemLabel={"Fecha Aceptacion:"} itemValue={fechaAceptacion} />

              ) : null}

              {fechaFinAsesor ? (

                <InfoItem itemLabel={"Fecha fin asesor:"} itemValue={fechaFinAsesor} />

              ) : null}

              {duracionConAsesor ? (

                <InfoItem itemLabel={"Duración con asesor:"} itemValue={duracionConAsesor} />

              ) : null}

              <div>
                <Cronometro
                  className={fechaAceptacion && (conversation.estado > 0 && conversation.estado < 10) ? "" : "comp-hide"}
                  fechaActual={conversation.fecha_asignacion?.toDate().getTime()}
                  fechaAceptacion={conversation.fecha_aceptacion?.toDate().getTime()}
                  id={id}
                  duracionConversacionObj={duracionConversacionObj}
                />
                {/*<Cronometro fechaAceptacion={conversation.fecha_aceptacion?.toDate().getTime()} id={id} />*/}
              </div>

              {fechaCierre && (conversation.estado === 10 || conversation.estado === 11) ? (

                <InfoItem itemLabel={"Tiempo Conversacion:"} itemValue={duracionConversacion} />

              ) : null}

              {fechaCierre ? (

                <div>
                  <InfoItem itemLabel={"Fecha cierre:"} itemValue={fechaCierre} />
                  <InfoItem itemLabel={"Cerrado por:"} itemValue={closedBy} />

                </div>

              ) : null}
              {fechaAbandono ? (
                <InfoItem itemLabel={"Fecha Abandono:"} itemValue={fechaAbandono} />

              ) : null}

              {aceptaTerminos !== 0 ? (
                <InfoItem itemLabel={"Terminos y condiciones: "} itemValue={aceptaTerminos} />

              ) : null}

              {suscribeContactar !== 0 ? (
                <InfoItem itemLabel={"Suscripciòn a Notificaciones: "} itemValue={suscribeContactar} />

              ) : null}

              {id ? (
                <InfoItem itemLabel={"ID conversacion:"} itemValue={id} />

              ) : null}

              <InfoItem itemLabel={"Tema:"} itemValue={tema} />

              {conversation.encuesta ? (
                <div>
                  <div className="row info-chat-row">
                    <div className="col-5 pr-0">
                      <h6 className="mb-0">Calificación:</h6>
                    </div>
                    <Rating readonly stars={5} value={conversation.encuesta.ratingStars} cancel={false}></Rating>
                  </div>
                  <div className="row info-chat-row">
                    <div className="col-5 pr-0">
                      <h6 className="mb-0">Satisfacción:</h6>
                    </div>
                    <div className="col-7 pl-0">{conversation.encuesta.satisfaccion}</div>

                  </div>
                  <div className="row info-chat-row">
                    <div className="col-5 pr-0">
                      <h6 className="mb-0">Atención:</h6>
                    </div>
                    <div className="col-7 pl-0">{conversation.encuesta.atencion}</div>

                  </div>
                  <div className="row info-chat-row">
                    <div className="col-6 pr-0">
                      <h6 className="mb-0">Conocimientos:</h6>
                    </div>
                    <div className="col-6 pl-0">{conversation.encuesta.conocimientos}</div>
                    <div className="col-12">
                      <label>{""}</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <label>{""}</label>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          {showTransferencias ? (
            <div id="transferencias">
              <DataTable
                dataKey="id" value={listaTransferencias} className="tableTransfers" header="Transferencias">
                <Column field="fecha" header="Fecha"></Column>
                <Column field="origen" header="Usuario Origen"></Column>
                <Column field="destino" header="Usuario Destino"></Column>
              </DataTable>
            </div>
          ) : null}

          {showNotasOcultas ? (
            <div id="notasOcultas">
              <DataTable
                dataKey="id" value={listaNotas} className="tableTransfers" header="Notas Ocultas">
                <Column body={this.bodyNota}></Column>
              </DataTable>

            </div>
          ) : null}
        </div>
      </div>

    )
  }
}
