import React, { Component } from "react";
import { connect } from "react-redux";
import ReactPlayer from 'react-player';
import { storage } from "../../controller/firebase";

class ComponentVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePath: '/',
            urlVideo: false,
        }

    }

    componentWillMount() {
        const storageRef = storage.ref();
        const videoRef = storageRef.child(`${process.env.REACT_APP_VIDEO_INTRO}`); // Ruta del video en Firebase Storage
    
        // videoRef.getDownloadURL().then((url) => {
        //     console.log("URL VIDEO", url)
        //     this.setState({ urlVideo: url });
          
        // });
    }

    render() {
        const {
        } = this.props;

        return (
            <div>
                <ReactPlayer
                    url={process.env.REACT_APP_VIDEO_INTRO}
                    playing={true}
                    autoPlay={true}
                    loop={true}
                    controls={true}
                    muted={true}
                    width="440px"
                    height="500px"
                />
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentVideo);