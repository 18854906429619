import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Collapse,
    TablePagination,
    Box
} from '@material-ui/core/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'react-bootstrap/Image';
import { faThumbsUp, faThumbsDown, faChevronUp, faChevronDown, faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import CustomButton from '../custombutton/button';
import './table.css';
import CustomField from '../customform/customfield';
import { Link } from 'react-router-dom';
/* import {  } from 'bootstrap'; */
import { Button } from 'primereact/button';


export default class CustomTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: [],
            ordered: []
        }
    }

    setOrder = (column, items, sense) => {
        const { ordered } = this.state;
        const { orderTable } = this.props;

        ordered[column] = !ordered[column];

        this.setState({
            ordered: ordered
        })

        orderTable({ column, items, sense });
    }

    setOpen = (position) => {
        const { open } = this.state;
        const { items, subObject } = this.props;
        const subRow = items[position];

        if (open[position]) {
            open[position] = false;
        } else {
            open[position] = subRow[subObject];
        }
        this.setState({
            open: open

        })
    }

    render() {
        const {
            items,
            headers,
            className,
            onSelectRow,
            buttons,
            subObject,
            subLabel,
            childObject,
            childLabel,
            orderTable,
            filterTable,
            totalItems,
            paginator,
            pageSize,
            rowsPerPage,
            page,
            handlerPagination,
            onChangeRowsPerPage,


            selectedItem
        } = this.props;
        const { open, ordered } = this.state;
        //console.log('ITEEMS', items);


        return (

            <div className="custom-table_wrapper">
                
                <TableContainer className="table table-bordered" component={Paper}>
                    {
                        filterTable &&
                        <CustomField
                            id="search-001"
                            type="text"
                            typeForm="filter"
                            placeholder="Filtrar"
                            updateAction={filterTable}
                            name={"filter"}
                            items={totalItems}
                        />
                    }
                    <Table className={className} striped="true" bordered="true" hover="true" responsive="true" >
                        <TableHead className="custom-table_head">
                            <TableRow className="custom-table_header" >
                                {
                                    headers && headers.map((head, i) => {
                                        return (
                                            <TableCell key={i}>
                                                {
                                                    orderTable &&
                                                    <React.Fragment>
                                                        {ordered[head.column] ?
                                                            <FontAwesomeIcon
                                                                onClick={() => this.setOrder(head.column, items, -1)}
                                                                className='table-icon'
                                                                icon={faArrowUp} /> :
                                                            <FontAwesomeIcon
                                                                onClick={() => this.setOrder(head.column, items, 1)}
                                                                className='table-icon'
                                                                icon={faArrowDown} />
                                                        }
                                                    </React.Fragment>
                                                }
                                                <span className="header-title">{head.label}</span>
                                            </TableCell>
                                        );
                                    })
                                }
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            {
                                items && items.map((item, j) => {


                                    return (
                                        <React.Fragment key={`frag-${j}`}>
                                            <TableRow className={`custom-table_rows ${selectedItem && item.id !== null && item.id.value === selectedItem ? 'selected' : ''} ${item.rowStyle && item.rowStyle.value ? item.rowStyle.value : ''}`} key={j} >
                                                {subObject &&
                                                    <TableCell onClick={() => this.setOpen(j)}  >
                                                        {open[j] ?
                                                            <FontAwesomeIcon className='table-icon' icon={faChevronUp} /> :
                                                            <FontAwesomeIcon className='table-icon' icon={faChevronDown} />
                                                        }
                                                    </TableCell>
                                                }
                                                {
                                                    item && Object.keys(item).map(e => {


                                                        let tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>{item[e]}</TableCell>

                                                        if (item[e] === true) {
                                                            tableCell =
                                                                <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e} >
                                                                    <FontAwesomeIcon className='table-icon' icon={faThumbsUp} />
                                                                </TableCell>;
                                                        } else if (item[e] === false) {
                                                            tableCell =
                                                                <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                                    <FontAwesomeIcon className='table-icon' icon={faThumbsDown} />
                                                                </TableCell>;
                                                        } else if (item[e] && typeof item[e] === 'object' && item[e].type === 'image') {
                                                            tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                                <Image className={item[e].style} src={item[e].source}></Image>

                                                            </TableCell>

                                                        } else if (item[e] && typeof item[e] === 'object' && item[e].type === 'imageText') {
                                                            tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                                <Image className={item[e].style} src={item[e].source} title={item[e].tittle}></Image>
                                                            </TableCell>

                                                        }
                                                        else if (item[e] && typeof item[e] === 'object' && item[e].type === 'bigText') {
                                                            tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                                <div className={item[e].style} ><span>{item[e].text}</span></div>
                                                            </TableCell>

                                                        }
                                                        else if (item[e] && typeof item[e] === 'object' && item[e].type === 'button') {
                                                            tableCell = <TableCell onClick={() => onSelectRow ? onSelectRow(item) : false} key={e}>
                                                                <Button label={item[e].label}
                                                                    className="p-button-sm"
                                                                    onClick={item[e].onClick}></Button>
                                                            </TableCell>

                                                        }
                                                        else if (item[e] && typeof item[e] === 'object') {
                                                            tableCell = <TableCell key={e} className='hideCell'></TableCell>;
                                                        }
                                                        return (tableCell);
                                                    })
                                                }
                                            </TableRow>
                                            { subObject &&
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                        <Collapse in={open[j]} timeout="auto" unmountOnExit>
                                                            {
                                                                open[j] && open[j].length ?
                                                                    <Box className="custom-table_subtable">
                                                                        <h3 className="sub-object_title">
                                                                            <Link to={`/${subObject}`} className='table-link-title '>
                                                                                {subLabel}
                                                                            </Link>
                                                                        </h3>
                                                                        <CustomTable
                                                                            onSelectRow={false}
                                                                            className={`sub-object-${subObject}`}
                                                                            items={open[j]}
                                                                            buttons={[]}
                                                                            subObject={childObject}
                                                                            subLabel={childLabel}

                                                                        />
                                                                    </Box> : ''
                                                            }
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                { paginator ?
                    <TablePagination
                        rowsPerPageOptions={pageSize}
                        component="div"
                        labelRowsPerPage="Registros por página"
                        count={totalItems ? totalItems.length : 10}
                        rowsPerPage={rowsPerPage ? rowsPerPage : 2}
                        page={page ? page : 0}
                        onChangePage={handlerPagination}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                    : ''}

                <CustomButton items={buttons} />
            </div>
        );
    }
}
