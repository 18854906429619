const validate = values => {
    console.log("values", values)
    const errors = {};
    if (!values.preguntas) {
        errors.preguntas = { _error: 'Debe llenar todos los campos' };
    } 
    else {
        const preguntasArrayErrors = [];
        values.preguntas.forEach((pregunta, preguntaIndex) => {
            console.log("pregunta values", pregunta)
            console.log("pregunta.docPpal-0", pregunta['docPpal-0'])
            const preguntasErrors = {};
            if (!pregunta || !pregunta.type) {
                preguntasErrors.type = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta.nombre) {
                preguntasErrors.nombre = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta.nombreCorto) {
                preguntasErrors.nombreCorto = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta.campoDocumento) {
                preguntasErrors.campoDocumento = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta.tipoDocumento) {
                preguntasErrors.tipoDocumento = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }



            if (!pregunta || !pregunta['docPpal-0']) {
                preguntasErrors['docPpal-0'] = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta['valorPpal-0']) {
                preguntasErrors['valorPpal-0'] = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta['docPpal-1']) {
                preguntasErrors['docPpal-0'] = 'Debe añadir una opción de cruce con el botón "+"';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }
            if (!pregunta || !pregunta['valorPpal-1']) {
               preguntasErrors['valorPpal-0'] = 'Debe añadir una opción de cruce con el botón "+"';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }



            if (!pregunta || !pregunta.docPpal) {
                preguntasErrors.docPpal = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;

            }
            if (!pregunta || !pregunta.valorPpal) {
                preguntasErrors.valorPpal = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;

            }
            if (!pregunta || !pregunta.valorCruce) {
                preguntasErrors.valorCruce = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;

            }
            if (!pregunta || !pregunta.docCruce) {
                preguntasErrors.docCruce = 'Obligatorio';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            }

            // if (!pregunta || !pregunta.docPpal-1) {
            //     preguntasErrors.docCruce = 'Obligatorio';
            //     preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            // }
            // if (!pregunta || !pregunta.valorPpal-1) {
            //     preguntasErrors.docCruce = 'Obligatorio';
            //     preguntasArrayErrors[preguntaIndex] = preguntasErrors;
            // }


            if (pregunta && pregunta.type && (pregunta.type == 'radio' || pregunta.type == 'select') && (pregunta.oppcionesRespuesta == 'opcionesRespuesta' || pregunta.opcionesRespuesta == 'campos')) {

                preguntasErrors.type = 'Debe añadir una opción con el botón "Añadir Opción"';
                preguntasArrayErrors[preguntaIndex] = preguntasErrors;


            }

            if (pregunta && pregunta.opcionesRespuesta && pregunta.opcionesRespuesta.length) {

                const opcionesArrayErrors = [];

                pregunta.opcionesRespuesta.forEach((opcion, opcionIndex) => {
                    const opcionesErrors = {};

                    if (!opcion || !opcion.label) {
                        opcionesErrors.label = 'Obligatorio'
                        opcionesArrayErrors[opcionIndex] = opcionesErrors;
                    }
                    if (!opcion || !opcion.value) {
                        opcionesErrors.value = 'Obligatorio'
                        opcionesArrayErrors[opcionIndex] = opcionesErrors;
                    }
                });
                if (opcionesArrayErrors.length) {
                    preguntasErrors.opcionesRespuesta = opcionesArrayErrors;
                    preguntasErrors[preguntaIndex] = preguntasErrors;
                }

            }

            if (pregunta && pregunta.campos && pregunta.campos.length) {

                const opcionesArrayErrors = [];

                pregunta.campos.forEach((opcion, opcionIndex) => {
                    const opcionesErrors = {};

                    if (!opcion || !opcion.label) {
                        opcionesErrors.label = 'Obligatorio'
                        opcionesArrayErrors[opcionIndex] = opcionesErrors;
                    }
                    if (!opcion || !opcion.value) {
                        opcionesErrors.value = 'Obligatorio'
                        opcionesArrayErrors[opcionIndex] = opcionesErrors;
                    }
                });
                if (opcionesArrayErrors.length) {
                    preguntasErrors.campos = opcionesArrayErrors;
                    preguntasErrors[preguntaIndex] = preguntasErrors;
                }

            }

        });
        if (preguntasArrayErrors.length) {
            errors.preguntas = preguntasArrayErrors;
        }
    }
    return errors;
};

export default validate;
