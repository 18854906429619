import {
    CARGA,
    CERRAR_SESION_AGENTE,
    CONVS_ABANDONADAS,
    CONVS_CERRADAS,
    CONVS_EN_CURSO,
    CONVS_RESUELTAS,
    INFO_AGENTES,
    OCULTAR_ESTADISTICAS,
    OCULTAR_ESTADISTICA_ENCUESTA,
    PORC_SATISFACCION,
    TMPESP_PROM,
    TMP_RESPUESTA,
    UPD_AGENTES_UPDATE,
    USUARIOS_EN_ESPERA
}
    from './constants';
import { put, takeEvery, takeLatest, select } from "redux-saga/effects";


import {
    obtenerCargaSuccess,
    obtenerCargaError,

    obtenerConvsCerradasSuccess,
    obtenerConvsCerradasError,

    obtenerConvsEnCursoSuccess,
    obtenerConvsEnCursoError,

    obtenerConvsResueltasSuccess,
    obtenerConvsResueltasError,

    obtenerInfoAgentesSuccess,
    obtenerInfoAgentesError,

    obtenerTmpEspPromedioSuccess,
    obtenerTmpEspPromedioError,

    obtenerTmpRespuestaSuccess,
    obtenerTmpRespuestaError,

    obtenerUsuariosEnEsperaSuccess,
    obtenerUsuariosEnEsperaError,

    obtenerPorcConvsAbandonadasError,

    obtenerPorcSatisfaccionSuccess,
    updAgenteSuccess,
    updAgenteDoneSuccess,
    updAgente,
    obtenerPorcConvsAbandonadasSuccess,
    ocultarEstadisticasConvsAbandonadasSuccess,
    ocultarEstadisticaEncuestaSuccess,
    ocultarEstadisticaEncuestaError

}
    from './actions';

import { totalConvs, totalUsuarios , totalConvsCerradas , promedioTmpEsp, promedioTmpResp, convsResueltas, porcSatisfaccion, datosConvsAbandonadas } from './selectors';

import {
    datosGraficaConversacionesActuales,
    totalConversacionesEstado,
    totalConversacionesResueltas,
    tiempoEspera,
    tiempoRespuesta,
    obtenerDatosPorcSatisfaccion,

    usuariosEnEspera,
    porcCargaActual,
    consultarRegistroAgentesUpd,
    consultarRegistroAgentesV2,
    obtenerPorcConvsAbandono
} from './../../controller/estadisticas';
import { terminarSesionAgente } from '../../controller/connectedUsers';
import { devolverConversacionALaCola } from '../../controller/conversation';
import { consultaProperty } from '../../controller/configuration';

import { getFromSession } from "../../controller/session";


function* obtenerRangoDeFechas(caso) {
    let numero;
    let rango = {};
    let fechaIni;
    let fechaFin;
    let hoy = new Date();
    let flag = false;


    try {
        if (caso === undefined || caso === "HOY") {
            numero = 0;
            flag = true;
        }
        else if (caso === "AYER") {
            numero = 1;
        } else if (caso === "24HORAS") {
            numero = 1;
            fechaIni = hoy.setDate(hoy.getDate() - numero);
            fechaIni = new Date(fechaIni);
            fechaFin = new Date();

            rango['fechaIni'] = fechaIni;
            rango['fechaFin'] = fechaFin;
            return rango;
        } else if (caso === "12DIAS") {
            numero = 12;

            fechaIni = hoy.setDate(hoy.getDate() - numero);
            fechaIni = new Date(fechaIni).setHours(0, 0, 0, 0);
            fechaFin = new Date();
            fechaFin = fechaFin.setDate(fechaFin.getDate())
            fechaFin = new Date(fechaFin).setHours(23, 59, 59, 59);


            rango['fechaIni'] = fechaIni;
            rango['fechaFin'] = fechaFin;
            return rango;
        }


        let diasAtras = hoy.setDate(hoy.getDate() - numero);
        fechaIni = new Date(diasAtras).setHours(0, 0, 0, 0);
        fechaIni = new Date(fechaIni);

        if (numero === 0) {
            fechaFin = new Date().setHours(23, 59, 59, 59);
            fechaFin = new Date(fechaFin);
        } else {
            fechaFin = new Date(diasAtras).setHours(23, 59, 59, 59);
            fechaFin = new Date(fechaFin);
        }

        rango['fechaIni'] = fechaIni;
        rango['fechaFin'] = fechaFin;
        rango['flag'] = flag;
        console.log('rango de fechas:', rango)
        return rango;

    } catch (error) {
        console.error('saga.obtenerRangoDeFechas', error);
        throw error;
    }

}
function* obtenerPorcConvsAbandonadas(action) {
    try {

        let rango = yield obtenerRangoDeFechas(action.value);

        let tooltip = {
            'text': 'Gráfico que muestra el porcentaje de conversaciones abandonadas',
            'style': 'littleTooltip'
        }

        yield obtenerPorcConvsAbandono(rango.fechaIni, rango.fechaFin,  obtenerPorcConvsAbandonadasSuccess, tooltip, datosConvsAbandonadas)

    } catch (error) {
        console.error('saga.obtenerPorcConvsAbandonadas', error);
        yield put(obtenerPorcConvsAbandonadasError(error))
    }
}
function* ocultarEstadisticasConvsAbandonadas() {
    try {
        const company = getFromSession("company_id");
        let verAbandono = yield consultaProperty(company, 'VER_ABANDONO');
        yield put(ocultarEstadisticasConvsAbandonadasSuccess(verAbandono));
    } catch (error) {
        console.error('saga.ocultarEstadisticasConvsAbandonadas', error);
        throw error;

    }
}

function* ocultarEstadisticaEncuesta() {
    try {
        const company = getFromSession("company_id");
        let enabledSurvey = yield consultaProperty(company, 'SURVEY_ENABLED');

        yield put(ocultarEstadisticaEncuestaSuccess(enabledSurvey));
    } catch (error) {
        console.error('saga.ocultarEstadisticaEncuesta', error);
        throw error;
    }

}


function* obtenerConvsEnCurso(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);
        let color = '#ed4561';
        let colorFondo = '#f291a2';
        let label = 'Conversaciones';
        let tooltip = {
            'text': 'Gráfico que indica cuantas conversaciones estan abiertas por hora',
            'style': 'littleTooltip'
        }
        let flag = false;

        let parametros = {

            'textTooltip': tooltip,
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': false
        }

        yield datosGraficaConversacionesActuales(rango.fechaIni, rango.fechaFin, obtenerConvsEnCursoSuccess, parametros, totalConvs);

    } catch (error) {
        console.error('saga.Supervisro.obtenerConvsEnCurso', error)
        yield put(obtenerConvsEnCursoError(error));
    }
}

function* obtenerUsuariosEnEspera(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);
        let estado = [0];


        let color = '#f2e013';
        let colorFondo = '#f5eb76';
        let label = 'Conversaciones en espera';
        let tooltip = {
            'text': 'Gráfico de tiempo promedio de una conversación en cola por hora',
            'style': 'littleTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': false
        }

        yield usuariosEnEspera( obtenerUsuariosEnEsperaSuccess, parametros,  totalUsuarios);

    } catch (error) {
        console.error('saga.obtenerUsuariosEnEspera', error)
        yield put(obtenerUsuariosEnEsperaError(error));

    }
}
function* cerrarSesionAgente(action) {
    try {
        let idDoc = action.value.idDoc;

        yield devolverConversacionALaCola(idDoc)
        yield terminarSesionAgente(idDoc);

    } catch (error) {
        console.error('saga.cerrarSesionAgente', error);
        throw error;

    }


}

function* obtenerConvsCerradas(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);
        let estado = [10, 11,13];

        let color = '#d35ce6';
        let colorFondo = '#e49cf0'
        let label = 'Conversaciones';
        let tooltip = {
            'text': 'Gráfico que muestra el conteo de conversaciones cerradas por hora o por día',
            'style': 'littleTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': false
        }
        yield totalConversacionesEstado(estado, rango.fechaIni, rango.fechaFin, obtenerConvsCerradasSuccess, parametros, 'ejeXfechaFin' , totalConvsCerradas);

    } catch (error) {
        console.error('saga.obtenerConvsCerradas', error);
        yield put(obtenerConvsCerradasError(error));

    }
}

function* obtenerCarga(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);
        let flag = true;
        let label = 'Conversaciones';
        let tooltip = {
            'text': 'Gráfica que muestra la carga actual de los agentes segun el máximo de conversaciones que puede tener un agente',
            'style': 'bigTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'graficoGrande': false
        }

        yield porcCargaActual(rango.fechaIni, rango.fechaFin, obtenerCargaSuccess, parametros)

    } catch (error) {
        console.error('saga.ObtenerCarga', error);
        yield put(obtenerCargaError(error));
    }
}

function* obtenerTmpEspPromedio(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);

        let color = '#5c71e6';
        let colorFondo = '#939edb'
        let label = 'Promedio (min)';
        let tooltip = {
            'text': 'Gráfico de tiempo promedio de los usuarios en cola por hora o por día',
            'style': 'littleTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': false
        }
        yield tiempoEspera(rango.fechaIni, rango.fechaFin, obtenerTmpEspPromedioSuccess, parametros, promedioTmpEsp);

    } catch (error) {
        console.error('saga.obtenerTmpEspPromedio', error);
        yield put(obtenerTmpEspPromedioError(error));

    }

}

function* obtenerTmpRespuesta(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);

        let color = '#65e65c';
        let colorFondo = '#a5f59f'
        let label = 'Promedio (min)';
        let tooltip = {
            'text': 'Gráfico de tiempo promedio que los agentes tardan en aceptar una conversación por hora o por día',
            'style': 'littleTooltip'
        }

        let parametros = {
            'textTooltip': tooltip,
            'dataLabel': label,
            'color': color,
            'colorBackground': colorFondo,
            'graficoGrande': false
        }

        yield tiempoRespuesta(rango.fechaIni, rango.fechaFin, obtenerTmpRespuestaSuccess, parametros  , promedioTmpResp);


    } catch (error) {
        console.error('saga.ObtenerTmpRespuesta', error)
        yield put(obtenerTmpRespuestaError(error));
    }
}

function* obtenerConvsResueltas(action) {
    try {
        let rango = yield obtenerRangoDeFechas(action.value);

        let tooltip = {
            'text': 'Gráfica que muestra el porcentaje de conversaciones marcadas como resueltas',
            'style': 'littleTooltip'
        }

        let parametros = {
            'tooltip': tooltip
        }
        yield totalConversacionesResueltas(rango.fechaIni, rango.fechaFin, obtenerConvsResueltasSuccess, parametros , convsResueltas);

    } catch (error) {
        console.error('saga.obtenerConvsResueltas', error);
        yield put(obtenerConvsResueltasError(error));
    }
}

function* obtenerPorcSatisfaccion(action) {
    try {

        let rango = yield obtenerRangoDeFechas(action.value);
        let tooltip = {
            'text': 'Este porcentaje se obtiene haciendo la suma de todas las calificaciones de las conversaciones dividido por el numero total de conversaciones calificadas multiplicado por 100%',
            'style': 'bigTooltip'
        }
        yield obtenerDatosPorcSatisfaccion(rango.fechaIni, rango.fechaFin, obtenerPorcSatisfaccionSuccess, tooltip, porcSatisfaccion);

    } catch (error) {
        console.error('saga.obtenerPorcSatisfaccion', error);

    }
}

function* updAgenteTiempoReal(action) {

    let agente = action.payload.datoAgente;
    try {
        yield consultarRegistroAgentesUpd(action.payload.timeIni, action.payload.timeFin, agente, updAgenteDoneSuccess);
    }
    catch (error) {
        console.error('saga.obtenerInfoAgentes', error);
        yield put(obtenerInfoAgentesError(error));
    }
}


function* obtenerInfoAgentes(action) {
   
    try {
        let rango = yield obtenerRangoDeFechas(action.value);
        let fechaHoy = yield obtenerRangoDeFechas("HOY");
        yield consultarRegistroAgentesV2(rango.fechaIni, rango.fechaFin, obtenerInfoAgentesSuccess, updAgente, fechaHoy);
    }
    catch (error) {
        console.error('saga.obtenerInfoAgentes', error);
        yield put(obtenerInfoAgentesError(error));

    }
}


export function* watchAgent() {

    yield takeLatest(CONVS_EN_CURSO, obtenerConvsEnCurso);
    yield takeLatest(USUARIOS_EN_ESPERA, obtenerUsuariosEnEspera);
    yield takeLatest(CONVS_CERRADAS, obtenerConvsCerradas);
    yield takeLatest(CARGA, obtenerCarga);
    yield takeLatest(TMPESP_PROM, obtenerTmpEspPromedio);
    yield takeLatest(TMP_RESPUESTA, obtenerTmpRespuesta);
    yield takeLatest(CONVS_RESUELTAS, obtenerConvsResueltas);
    yield takeLatest(PORC_SATISFACCION, obtenerPorcSatisfaccion);
    yield takeEvery(INFO_AGENTES, obtenerInfoAgentes);
    yield takeEvery(UPD_AGENTES_UPDATE, updAgenteTiempoReal);
    yield takeLatest(CERRAR_SESION_AGENTE, cerrarSesionAgente);
    yield takeLatest(CONVS_ABANDONADAS, obtenerPorcConvsAbandonadas);
    yield takeLatest(OCULTAR_ESTADISTICAS, ocultarEstadisticasConvsAbandonadas);
    yield takeLatest(OCULTAR_ESTADISTICA_ENCUESTA, ocultarEstadisticaEncuesta);


}
