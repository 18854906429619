import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../controller/session";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";

class ComponentEliminar extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        const { value
        } = this.props;
        const user = getFromSession("currentUser");

        console.log("VALUEEE COMPONENTE ELIMINar", value)

        return (
            <div className="App borrador-container modal-wrapper-bot" >
                <div className="modal-background-bot" onClick={() => this.props.handlerCancelar()}> </div>
                <div className="modal-content-bot">
                    <h2>Eliminar pregunta</h2>
                    <div className="descripcion-delete">
                        <p>¿Está seguro de eliminar la pregunta <b>{value}</b>?</p>
                    </div>
                    <div className="align-button-auto">
                        <Button variant="contained" color="primary" onClick={() => this.props.handlerCancelar()}  >Cancelar</Button>
                        <Button variant="contained" color="secondary" onClick={() => this.props.handlerAceptar(value)} >Eliminar</Button>
                    </div>
                </div>
            </div>
        );
    }
}


//onClick={() => this.props.onModalCancel(this.props.valueModal)}
//onClick={() => this.props.onDeleteButton()}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentEliminar);