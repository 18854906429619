import React, { Component } from 'react';
import CustomForm from "../../components/customform/customform";


export default class ComboRangos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mostrarRangosFecha: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedForm.fechaRango && prevProps.selectedForm.fechaRango !== this.props.selectedForm.fechaRango) {
            if (this.props.selectedForm.fechaRango === 'CUSTOM') {
                this.mostrarCamposRangos();
            } else {
                this.ocultarCamposRangos();
            }
        }
    }

    mostrarCamposRangos() {
        this.setState({
            mostrarRangosFecha: true

        })
    }
    ocultarCamposRangos() {
        this.setState({
            mostrarRangosFecha: false
        })
    }

    render() {

        const { selectedForm, updateAttributes, accion } = this.props;

        let opcionesRangos = [
            { label: "Hoy", value: 'HOY' },
            { label: "Ayer", value: 'AYER' },
            { label: "Últimos 7 días", value: '7DIAS' },
            { label: "Últimos 15 días", value: '15DIAS' },
            { label: "Esta semana", value: 'ESTA_SEMANA' },
            { label: "Este mes", value: 'ESTE_MES' },
            { label: "Anterior mes", value: 'ANTERIOR_MES' },
            { label: "Personalizado", value: 'CUSTOM' }
        ];


        let FILTER = [
            {
                /* label: "Rango de tiempo", */
                name: "fechaRango",
                placeholder: "Seleccione un rango de tiempo",
                value: false,
                options: opcionesRangos,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione un rango",
                display: true

            },
            {

                typeform: 'filter',
                label: "Fecha Inicio",
                name: "fechaIni",
                placeholder: "AAAA-MM-DDTHH:MM",
                type: 'datetime-local',
                value: false,
                disable: false,
                labelClass: "label-name-show",
                display: this.state.mostrarRangosFecha

            },
            {
                typeform: 'filter',
                label: "Fecha Fin",
                name: "fechaFin",
                placeholder: "AAAA-MM-DDTHH:MM",
                type: 'datetime-local',
                value: false,
                disable: false,
                labelClass: "label-name-show",
                display: this.state.mostrarRangosFecha

            },
        ]


        let getFilterButton = (handlerSafe) => {
            return [
                {
                    label: "Filtrar",
                    className: "btn btn-primary",
                    action: false,
                    style: "primary",
                    onClick: handlerSafe,
                    display: this.state.mostrarRangosFecha

                }
            ]
        }

        return (

            <React.Fragment>
                <div className='p-grid p-mt-3'>
                    <h4 className="h3-fixed p-ml-4">Ver resumen de</h4>
                    <CustomForm
                        formName="filterFechaInformes"
                        items={FILTER}
                        values={selectedForm}
                        updateAction={updateAttributes}
                        style="splash-container-search"
                        bodyStyle="body-informesPanel"
                        styleFormgroup="group-informesPanel"
                        classnameDiv="none"
                        formRow='form-row'
                        positionButton='fixed-button'
                        buttons={getFilterButton(() => accion(selectedForm))}>
                    </CustomForm>

                </div>
            </React.Fragment>
        );
    }

}