import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';
import { eventChannel } from "@redux-saga/core";
import { put } from "@redux-saga/core/effects";
const CSVToJSON = require('csvtojson');
const axios = require('axios');
const testenviosMasivos = require('./testenviosMasivos.csv');



/**
 * Metodo que crea un nuevo registro de un envio masivo de whatsapp para una compañia.
 * se debe crear el registro
 * se debe abrir el archivo (debe ser un CSV) y crear la coleccion "usuarios"
 * Se crea en estado 0
 * Estados: 
 * 0 borrador
 * 1 finalizad
 * 2 en envio
 * 3 enviado
 * @param {*} nombre 
 * @param {*} mensaje 
 * @param {*} archivo 
 */
export function* crearCargueMasivoWhatsapp(company, nombre, total, mensaje, template_name, type, csv, parametrosArchivo = false, actionSave, actionList) {
    try {
      
        let toastDetail = {}
        let idDocument;
        const ref = firebaseDatabase.collection(`company/${company}/enviosMasivos`);
        const docRef = yield ref.doc();
        idDocument = docRef.id
        let body = {
            fecha_cargue: new Date(),
            nombre,
            registros: total,
            mensaje,
            estado: 0,
            idDoc: idDocument,
            template_name,
            type
        }

        if (type !== "TEXT") {
            if (parametrosArchivo[0] && parametrosArchivo[0].nombre) {
                body['filename'] = parametrosArchivo[0].nombre;
                body['url'] = parametrosArchivo[0].url;
            } else {

                toastDetail['summary'] = 'No realizado'
                toastDetail['severity'] = 'error'
                toastDetail['detail'] = 'Ha ocurrido un error con el archivo adjunto, intente de nuevo'
                yield put(actionSave({ envio: false, toastDetail }));
            }
        }
        console.log('body', body);
        docRef.set(body);

        let envio = false;

        const cargueMasivoTerminado = yield realizarCargueCsv(csv, company, idDocument, mensaje);

        let realDataReference = ref.doc(idDocument)
        const channel = eventChannel(emit => realDataReference.onSnapshot(emit))

        let activated = true;

        yield realDataReference.get().then((doc) => {
            console.log('doc', doc.data());
            envio = doc;
        })

        if (envio) {
            toastDetail['summary'] = 'Cargue Realizado'
            toastDetail['severity'] = 'success'
            toastDetail['detail'] = 'Los registros se guardaron en base de datos'
        } else {
            toastDetail['summary'] = 'No enviado'
            toastDetail['severity'] = 'error'
            toastDetail['detail'] = 'Se ha producido un error al realizar el cargue'
        }
        yield put(actionSave({ envio: envio, toastDetail }));
      
    } catch (error) {
        console.error('enviosMAsivos.crearEnvio', error)
        throw error;
    }
}

async function realizarCargueCsv(csv, company, idDocument, mensaje) {
    let conteo = 0;

    await Promise.all(csv.map(async (doc) => {
        const refDestinatarios = firebaseDatabase.collection(`company/${company}/enviosMasivos/${idDocument}/destinatarios`);
        let mensajeUser = mensaje;

        for (let i = 1; i < doc.data.length; i++) {
            mensajeUser = mensajeUser.replace(`{{${i}}}`, doc.data[i]);
        }

        const objMsge = {
            estado: 0,
            telefono: `${doc.data[0]}`,
            mensaje: mensajeUser,
            company,
            fecha_registro: new Date(),
            index: conteo
        };
        conteo = conteo + 1

        await refDestinatarios.add(objMsge).then(() => {
            console.log('Document added succesfully!!!');
        }).catch((error) => {
            console.error('Document not added!!!', error);
        });
    }));

    return true;
}

export function* eliminarBorradorEnvio(idEnvio) {
    try {
        let resp = false;

        const company = getFromSession("company_id");
        firebaseDatabase.collection(`company/${company}/enviosMasivos`).doc(idEnvio).delete().then(() => {
            resp = true

        }).catch((error) => {
            resp = false;
            console.error('controller.enviosMasivos.eliminarBorradorEnvio', error);
        });
        return resp;
    } catch (error) {
        console.error('controller.enviosMasivos.eliminarBorradorEnvio', error);
        throw error;
    }
}

export function* buscarEnEnviosApi(textInput, company) {
    try {
        let listaEnviosFiltrada = []
        yield firebaseDatabase.collection(`company/${company}/enviosAPI`)
            .where('telefono', "==", textInput).get().then((response) => {
                if (response.length = 0) {
                    listaEnviosFiltrada = []
                    return listaEnviosFiltrada
                } else {
                    response.forEach(doc => {


                        listaEnviosFiltrada.push(doc)
                    });
                }
            }).then(() => {
                console.log('Consulta realizada!');
            }).catch((error) => {
                console.error('Ha ocurrido un error en controller.enviosMasivos.buscarEnEnviosApi', error);
            });

        return listaEnviosFiltrada;


    } catch (error) {
        console.error('controller.enviosMasivos.buscarEnEnviosApi', error);
        throw error

    }

}


export function* buscarEnDestinatarios(textInput, /* destinatarios */idEnvio, company) {
    try {
        /* let listaDestinatariosFiltrada = yield destinatarios.filter((destinatario) => {
            let claves = Object.keys(destinatario);
            return claves.some((clave) => {
                return clave !== "company" && typeof destinatario[clave] === "string" &&
                    destinatario[clave].toLowerCase().includes(textInput.toLowerCase())
            })
        });
        return listaDestinatariosFiltrada; */

        let listaDestinatariosFiltrada = []
        yield firebaseDatabase.collection(`company/${company}/enviosMasivos/${idEnvio}/destinatarios`)
            .where('telefono', "==", textInput).get().then((response) => {
                if (response.length = 0) {
                    listaDestinatariosFiltrada = []
                    return listaDestinatariosFiltrada
                } else {
                    response.forEach(doc => {
                        listaDestinatariosFiltrada.push(doc.data())
                    });
                }
            }).then(() => {
                console.log('Consulta realizada!');
            }).catch((error) => {
                console.error('Ha ocurrido un error en controller.enviosMasivos.filtrarDestinatarios', error);
            });

        return listaDestinatariosFiltrada;

    } catch (error) {
        console.error('controller.enviosMasivos.buscarEnDestinatarios', error);
        throw error;
    }
}

/**
 * Metodo que invoca el API  de whatsapp para enviar el mensaje:
 * @param {*} company 
 * @param {*} idEnvio 
 */
export function* enviar(company, idEnvio) {

    try {
        //Se pone en estado 1 - en envio
        firebaseDatabase.collection(`company/${company}/enviosMasivos`).doc(idEnvio).update({
            estado: 1,
            fecha_ini_envio: new Date()
        });
        return true;
    } catch (error) {
        console.error('enviosMAsivos.enviar', error)
        throw error;
    }
}


