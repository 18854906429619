import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Dropdown from 'react-bootstrap/Dropdown';
import { Button } from "@material-ui/core";


class ComponentDatosCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePath: '/',
            nombre: false,
            correo: true,
            apellido: false,
            empresa: false,
            codigoPostal: false,
            redirect: false,
            description: '¿Cómo quieres pagar?',
            pago: true,
            selected: 'option1'
        }

    }



    componentWillMount() {
        const {
            path,
            tipo,
        } = this.props;
        this.setState({ redirect: `${process.env.REACT_APP_URL_CHAT_ASESORES}${path}` });
        console.log("TIPPOOO", tipo)
        if (tipo === 'Prueba Gratis de 15 días') {
            this.setState({ pago: false, description: tipo});
        }
    }


    render() {
        const {
            selectedOption,
            handleOptionChange,
            path,
            producto,
            valor
        } = this.props;
        const user = getFromSession("currentUser");
        const company = getFromSession("company");
        console.log("COMPANY PAGOS", company)
        console.log("VALOR", valor)
        return (
            <div className="pedidos-container">
                <div className="cb-transition__slide">
                    <h1>Avanti-it</h1>
                    <h3>{this.state.description}</h3>
                    
                </div>
                <div className="cb-body__content">

                    {this.state.pago ?
                        <>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label"><h4>PSE</h4></FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="female" control={<Radio />} label="PSE" />
                                </RadioGroup>
                            </FormControl>
                        


                        </>
                        : ''
                    }

                    {/* <Button style={{ "marginTop": "10px" }} onClick={() => handleOptionChange('pago')} color='primary' variant="contained"
                    
                    >
                    <a href="https://wompi.com" target="_blank" rel="noopener noreferrer">
                        Ir a PSE
                    </a>
                        </Button> */}



                    {/* <Dropdown>
                        <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                            {selectedOption}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Agrario')} >Banco Agrario</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('NEQUI')}>NEQUI</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco AV Villas')}>Banco AV Villas</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Caja Social')} >Banco Caja Social</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Cooperativo Coopcentral')} >Banco Cooperativo Coopcentral</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Davivienda')} >Banco Davivienda</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco De Bogota')} >Banco De Bogota</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Cooperativo Coopcentral')} >Banco Cooperativo Coopcentral</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco De Occidente')} >Banco De Occidente</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Falabella')} >Banco Falabella</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco GNB Sudameris')} >Banco GNB Sudameris</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Pichincha S.A.')} >Banco Pichincha S.A.</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Popular')} >Banco Popular</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Bancolombia')} >Bancolombia</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Bancoomeva S.A.')} >Bancoomeva S.A.</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco BBVA Colombia S.A.')} >Banco BBVA Colombia S.A.</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Citibank')} >Citibank</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Itaú')} >Banco Itaú</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Confiar Cooperativa Financiera')} >Confiar Cooperativa Financiera</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('DaviPlata')} >DaviPlata</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('BANCO DE LAS MICROFINANZAS BANCAMIA<')} >BANCO DE LAS MICROFINANZAS BANCAMIA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('BANCO CREDIFINANCIERA')} >BANCO CREDIFINANCIERA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('COLTEFINANCIERA')} >COLTEFINANCIERA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('CFA COOPERATIVA FINANCIERA')} >CFA COOPERATIVA FINANCIERA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('RAPPIPAY')} >RAPPIPAY</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('COTRAFA')} >COTRAFA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('BANCO SANTANDER COLOMBIA')} >BANCO SANTANDER COLOMBIA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('BANCO SERFINANZA')} >BANCO SERFINANZA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('BANCO UNION')} >BANCO UNION</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('FINANCIERA DANN REGIONAL - IRIS')} >FINANCIERA DANN REGIONAL - IRIS</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('SCOTIABANK COLPATRIA')} >SCOTIABANK COLPATRIA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Dale')} >Dale</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('COOFINEP COOPERATIVA FINANCIERA')} >COOFINEP COOPERATIVA FINANCIERA</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Lulo Bank')} >Lulo Bank</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('MOVii')} >MOVii</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleOptionChange('Banco Finandina')} >Banco Finandina</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    {/* <TextField required onChange={this.onCorreoHandler} id="standard-required" label="Correo" />
                    <TextField required onChange={this.onNombreHandler} id="standard-required" label="Nombre"  />
                    <TextField required onChange={this.onApellidoHandler} id="standard-required" label="Apellido"  />
                    <TextField required onChange={this.onEmpresaHandler} id="standard-required" label="Empresa"  />
                    <TextField required onChange={this.onPostalHandler} id="standard-required" label="Codigo postal"  /> */}

                    {/* <Select
                        label='País'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={''}
                        onChange={''}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select> */}


                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDatosCliente);