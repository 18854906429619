import "./style.css";
import 'primeflex/primeflex.css';
import React, { Component } from "react";
import { connect } from "react-redux";

import SideBar from "../../components/sidebar/sidebar";
import { menuItems, menuItemsChatBot } from "../../Constants";
import { Toast } from 'primereact/toast';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { getFromSession } from '../../controller/session'


import CustomForm from "../../components/customform/customform";

import {
    getSelectedForm,
    mostrarRangosFecha,
    toastMensaje,
    toastDetail,
    showEstGeneralesComponent,
    showEstTipificacionComponent,
    showEstAgentesComponent,
    showEstAbandonosComponent,
    showEstNotasComponent,
    showEstConectedUsersComponent,
    showEstTransferenciasComponent,
    showEstChatbotComponent,
    showOtrosInformesComponent,
    showServiciosExternosComponent,
    showEstColasAtencionComponent,
    getOpenModal,
    ocultarGrafica,
} from "./selectors";

import {
    UPDATE_FIELD_ATTRIBUTE,
    MOSTRAR_RANGOS,
    OCULTAR_RANGOS,
    LANZAR_EVENTOS,
    OCULTAR_MODAL_DESCARGAR,
    MODAL_DESCARGAR_DATOS,
    LIMPIAR_FORM,
    TOAST_MESSAGE,
    OCULTAR_ESTADISTICAS,
    SELECCION_FORM_ESTADISTICA,
    PARAMETER_URL,
    FILTRAR_RANGOS_FECHA

} from "./constants";


import EstGeneralesComponent from "./estadisticasGenerales";
import EstTipificacionComponent from "./tipificacion";
import EstAgentesComponent from "./estadisticasAgentes";
import EstNotasComponent from "./estadisticasNotas";
import EstAbandonosComponent from "./abandonos";
import EstTransferenciasComponent from "./estadisticasTransferencias";
import EstConectedUsersComponent from "./estadisticasConectedUser";
import EstColasAtencionComponent from "./estadisticasColasAtencion";
import EstChatBotComponent from "./estadisticasChatBot";
import EstOtrosReportes from "./estadisticasOtrosReportes";
import InformesComponent from "./informes";
import EstServiciosExternos from "./estadisticasServiciosExternos";


class ComponentInformesPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false,
        }
    }

    componentWillMount() {
        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
        } else {
            this.setState({ menu: menuItemsChatBot });
        }

        const {
            selectedForm,
            ocultarEstadisticasConvsAbandonadas,
            obtenerSeleccionFormEstadisticas,
            parameterUrl

        } = this.props;
        selectedForm.fechaRango = 'HOY';
        if (!selectedForm.estadisticas)
            selectedForm.estadisticas = 'general';

        const valores = window.location.search;
        var type = false;
        if (valores.length > 0) {
            const urlParams = new URLSearchParams(valores);
            type = urlParams.get('selectType');
            parameterUrl({ type })
            selectedForm.fechaRango = 'HOY';
            selectedForm.estadisticas = type;
        }

        ocultarEstadisticasConvsAbandonadas();
        obtenerSeleccionFormEstadisticas(selectedForm.estadisticas);

    }

    componentDidUpdate(prevProps) {

        const {
            mostrarCamposRangos,
            ocultarCamposRangos,
            obtenerSeleccionFormEstadisticas,
            toastMensaje,
            selectedForm
        } = this.props


        if (toastMensaje) {
            this.toast.show({
                severity: 'error',
                summary: 'Rango de fechas incorrecto',
                detail: 'Debe seleccionar una rango de fechas. Recuerde que la fecha final debe ser más reciente que la inicial.'
            });
        }

        if (this.props.selectedForm.fechaRango === 'CUSTOM') {
            mostrarCamposRangos();
        } else {
            ocultarCamposRangos();
        }

        if (this.props.selectedForm.estadisticas && prevProps.selectedForm.estadisticas != this.props.selectedForm.estadisticas) {
            console.log('selectedForm*****', selectedForm.estadisticas);
            obtenerSeleccionFormEstadisticas(selectedForm.estadisticas);
        }
    }


    componentDidMount() {
        const { parameterUrl, selectedForm } = this.props;

        const valores = window.location.search;
        var type = false;
        if (valores.length > 0) {
            const urlParams = new URLSearchParams(valores);
            type = urlParams.get('selectType');
            parameterUrl({ type })
            selectedForm.fechaRango = 'HOY';
            selectedForm.estadisticas = type;
        }
    }

    openModalComponent() {
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="loading-wrapperx">
                    <LinearProgress />

                    <p className="loading-text">
                        Cargando estadistica ...

                    </p>
                </div>
            </Modal>
        );
    }


    render() {
        const {
            updateAttributes,
            selectedForm,
            mostrarRangosFecha,
            openModal,
            showEstGeneralesComponent,
            showEstTipificacionComponent,
            showEstAgentesComponent,
            showEstNotasComponent,
            showEstConectedUsersComponent,
            showEstTransferenciasComponent,
            showEstChatbotComponent,
            showOtrosInformesComponent,
            showEstColasAtencionComponent,
            showEstAbandonosComponent,
            showServiciosExternosComponent,
            verAbandono,
            filtrarRangoFecha


        } = this.props

        let opcionesRangos = [
            { label: "Hoy", value: 'HOY' },
            { label: "Ayer", value: 'AYER' },
            { label: "Últimos 7 días", value: '7DIAS' },
            { label: "Últimos 15 días", value: '15DIAS' },
            { label: "Esta semana", value: 'ESTA_SEMANA' },
            { label: "Este mes", value: 'ESTE_MES' },
            { label: "Anterior mes", value: 'ANTERIOR_MES' },
            { label: "Personalizado", value: 'CUSTOM' }
        ];



        let opcionesEstadisticas = [
            { label: "Estadisticas generales", value: 'general' },
            { label: "Tipos de solicitudes", value: 'tipificacion' },
            { label: "Estadisticas por agente", value: 'agentes' },
            { label: "Notas ocultas", value: 'notasOcultas' },
            { label: "Accesos de usuarios", value: 'accesosUsuarios' },
            { label: "Transferencias entre agentes", value: 'transferencias' },
            { label: "Chatbot", value: 'chatBot' },
            { label: "Colas de atencion", value: 'colaAtencion' },
            { label: "Otros Informes", value: 'masInformes' },
            { label: "Servicios Externos", value: 'servExterno' },
            { label: "Abandonos", value: 'convsAbandono' }


        ]


        if (!verAbandono) {
            opcionesEstadisticas.splice(10, 1);
        }



        let FILTER_ESTADISTICAS = [
            {
                name: "estadisticas",
                placeholder: "Seleccione una opción",
                value: false,
                options: opcionesEstadisticas,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione una opción",
                display: true

            }

        ]


        let FILTER = [
            {
                /* label: "Rango de tiempo", */
                name: "fechaRango",
                placeholder: "Seleccione un rango de tiempo",
                value: false,
                options: opcionesRangos,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: "Seleccione un rango",
                display: true

            },
            {

                typeform: 'filter',
                label: "Fecha Inicio",
                name: "fechaIni",
                placeholder: "AAAA-MM-DDTHH:MM",
                type: 'datetime-local',
                value: false,
                disable: false,
                labelClass: "label-name-show",
                display: mostrarRangosFecha

            },
            {
                typeform: 'filter',
                label: "Fecha Fin",
                name: "fechaFin",
                placeholder: "AAAA-MM-DDTHH:MM",
                type: 'datetime-local',
                value: false,
                disable: false,
                labelClass: "label-name-show",
                display: mostrarRangosFecha

            },
        ]


        let getFilterButton = (handlerSafe) => {
            return [
                {
                    label: "Filtrar",
                    className: "btn btn-primary",
                    action: false,
                    style: "primary",
                    onClick: handlerSafe,
                    display: mostrarRangosFecha

                }
            ]
        }

        const company = getFromSession("company_id");
        let user = getFromSession('company');
        

        return (
            <React.Fragment>
                <SideBar logo={user.logo} items={this.state.menu}></SideBar>
                <div>
                    <Toast ref={(el) => this.toast = el} />
                    <div className="p-shadow-8  p-mt-3 p-p-3">

                        <div>
                            <h4 className="tituloComponente">PANEL DE INFORMES</h4>
                        </div>

                        <div className='p-grid p-mt-3'>
                            <h4 className="h3-fixed p-ml-4">Ver estadísticas de</h4>
                            <CustomForm
                                formName="filterFechaInformes"
                                items={FILTER_ESTADISTICAS}
                                values={selectedForm}
                                updateAction={updateAttributes}
                                style="splash-container-search"
                                bodyStyle="body-informesPanel"
                                styleFormgroup="group-informesPanel"
                                classnameDiv="none"
                                formRow='form-row'

                            >
                            </CustomForm>
                            <h4 className="h3-fixed p-ml-4">Seleccione el rango</h4>
                            <CustomForm
                                formName="filterFechaInformes"
                                items={FILTER}
                                values={selectedForm}
                                updateAction={updateAttributes}
                                style="splash-container-search"
                                bodyStyle="body-informesPanel"
                                styleFormgroup="group-informesPanel"
                                classnameDiv="filter-fecha"
                                formRow='form-row'
                                positionButton='fixed-button-filter'
                                buttons={getFilterButton(() => filtrarRangoFecha(selectedForm))}>
                            </CustomForm>

                        </div>
                        {openModal ? this.openModalComponent() : ""}



                        {showEstGeneralesComponent ? (
                            <EstGeneralesComponent></EstGeneralesComponent>
                        ) : null}
                        {showEstTipificacionComponent ? (
                            <EstTipificacionComponent></EstTipificacionComponent>
                        ) : null}
                        {showEstAbandonosComponent ? (
                            <EstAbandonosComponent></EstAbandonosComponent>
                        ) : null}
                        {showEstAgentesComponent ? (
                            <EstAgentesComponent></EstAgentesComponent>
                        ) : null}
                        {showEstNotasComponent ? (
                            <EstNotasComponent></EstNotasComponent>
                        ) : null}
                        {showEstConectedUsersComponent ? (
                            <EstConectedUsersComponent></EstConectedUsersComponent>
                        ) : null}
                        {showEstTransferenciasComponent ? (
                            <EstTransferenciasComponent></EstTransferenciasComponent>
                        ) : null}
                        {showEstColasAtencionComponent ? (
                            <EstColasAtencionComponent></EstColasAtencionComponent>
                        ) : null}
                        {showEstChatbotComponent ? (
                            <EstChatBotComponent></EstChatBotComponent>
                        ) : null}


                        {showOtrosInformesComponent && company === 'alcaldiaBogota' ? (
                            <EstOtrosReportes></EstOtrosReportes>)
                            : null}
                        {showServiciosExternosComponent ? (
                            <EstServiciosExternos></EstServiciosExternos>)
                            : null}
                        {/* (
                            <InformesComponent></InformesComponent>
                        ) */}
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => {
    return {
        verAbandono: ocultarGrafica(state),
        openModal: getOpenModal(state),
        toastMensaje: toastMensaje(state),
        toastDetail: toastDetail(state),
        selectedForm: getSelectedForm(state),
        mostrarRangosFecha: mostrarRangosFecha(state),
        showEstGeneralesComponent: showEstGeneralesComponent(state),
        showEstTipificacionComponent: showEstTipificacionComponent(state),
        showEstAgentesComponent: showEstAgentesComponent(state),
        showEstAbandonosComponent: showEstAbandonosComponent(state),
        showEstNotasComponent: showEstNotasComponent(state),
        showEstConectedUsersComponent: showEstConectedUsersComponent(state),
        showEstTransferenciasComponent: showEstTransferenciasComponent(state),
        showEstChatbotComponent: showEstChatbotComponent(state),
        showOtrosInformesComponent: showOtrosInformesComponent(state),
        showEstColasAtencionComponent: showEstColasAtencionComponent(state),
        showServiciosExternosComponent: showServiciosExternosComponent(state),
    }

}
const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        limpiarForm: (value) => dispatch({ type: LIMPIAR_FORM, value }),
        mostrarCamposRangos: () => dispatch({ type: MOSTRAR_RANGOS }),
        ocultarCamposRangos: () => dispatch({ type: OCULTAR_RANGOS }),
        parameterUrl: (value) => dispatch({ type: PARAMETER_URL, value }),
        desplegarEventosBuscador: (value) => dispatch({ type: LANZAR_EVENTOS, value: 1 }),
        cerrarModalDescargarDatos: () => dispatch({ type: OCULTAR_MODAL_DESCARGAR, payload: 1 }),
        abrirModalDescargarDatos: (value) => dispatch({ type: MODAL_DESCARGAR_DATOS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        ocultarEstadisticasConvsAbandonadas: (value) => dispatch({ type: OCULTAR_ESTADISTICAS, value }),
        obtenerSeleccionFormEstadisticas: (value) => dispatch({ type: SELECCION_FORM_ESTADISTICA, value }),
        filtrarRangoFecha: (value) => dispatch({ type: FILTRAR_RANGOS_FECHA, value })
    }

}
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentInformesPanel);
