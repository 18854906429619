export const getSelectedForm = (state) => state.selectedForm;

export const mostrarRangosFecha = (state) => state.mostrarRangosFecha;
//----------------graficas-------------------------------
export const convsCerradas = (state) => state.convsCerradas;
export const totalConvsCerradas = (state) => state.totalConvsCerradas;
export const tmpPromEsp = (state) => state.tmpPromEsp;
export const tmpPromResp = (state) => state.tmpPromResp;
export const tmpPromConv = (state) => state.tmpPromConv;
export const convsResueltas = (state) => state.convsResueltas;
export const convsAbandonadas = (state) => state.convsAbandonadas;
export const getConvsAgentes = (state) => state.convsAgentes;
export const convsCanal = (state) => state.canalConv;
export const ocultarGrafica = (state) => state.ocultarGrafica;
//-----------------------------------------


/* Lista de conversaciones para la pantalla */
export const getConvsCerradas = (state) => state.conversacionesCerradas;
export const getConvsFiltradas = (state) => state.conversacionesFiltradas;
export const toastMensaje = (state) => state.toastMensaje;
export const toastDetail = (state) => state.toastDetail;
export const getColumns = (state) => state.columnsTable;

export const getItemsPerPage = (state) => state.itemsPerPage;
export const getPagination = (state) => state.pagination;
export const getPage = (state) => state.page;

export const objEstados = (state) => state.objEstados;
export const estadosDisplay = (state) => state.estadosDisplay;

export const objAgentes = (state) => state.objAgentes;
export const agentesDisplay = (state) => state.agentesDisplay;
export const getDataTableAgentes = (state) => state.dataTableAgentes;
export const getDataTableConectedUsers = (state) => state.dataTableConectedUsers;
export const getDataTableChatbot = (state) => state.dataTableChatbot;
export const getDataTableTransferencias = (state) => state.dataTableTransferencias;
export const getDataTableNotasOcultas = (state) => state.dataTableNotasOcultas;
export const getDataNotasOcultas = (state) => state.dataNotasOcultas;
export const getDataTransferencias = (state) => state.dataTransferencias;
export const getDataColasAtencion = (state) => state.convsColasAtencion;
export const getOpenModal = (state) => state.openModal;

export const mensajes = (state) => state.mensajes;
export const verModalDescargarConversacion = (state) => state.verModalDescargarConversacion;
export const dataConv = (state) => state.dataConv;
export const getParameterUrl = (state) => state.parameterUrl;

export const convsTemaNivel1 = (state) => state.convsTemaNivel1;
export const convsTemaNivel2 = (state) => state.convsTemaNivel2;
export const convsTemaNivel3 = (state) => state.convsTemaNivel3;
export const convsAtendidasChatBot = (state) => state.convsAtendidasChatBot;

export const showEstGeneralesComponent = (state) => state.showEstGeneralesComponent;
export const showEstTipificacionComponent = (state) => state.showEstTipificacionComponent;
export const showEstAgentesComponent = (state) => state.showEstAgentesComponent;
export const showEstAbandonosComponent = (state) => state.showEstAbandonosComponent;
export const showEstNotasComponent = (state) => state.showEstNotasComponent;
export const showEstConectedUsersComponent = (state) => state.showEstConectedUsersComponent;
export const showEstTransferenciasComponent = (state) => state.showEstTransferenciasComponent;
export const showEstChatbotComponent = (state) => state.showEstChatbotComponent;
export const showOtrosInformesComponent = (state) => state.showOtrosInformesComponent;
export const showServiciosExternosComponent = (state) => state.showServiciosExternosComponent;
export const showEstColasAtencionComponent = (state) => state.showEstColasAtencionComponent;
export const getDataSentimentAnalysis = (state) => state.dataSentimentalAnalysis;
export const getDataCalificacionEncuesta = (state) => state.dataCalificacionEncuesta;
export const getStateFilterButton = (state) => state.btnFiltrar;
export const getConvsCerradasPaginadas = (state) => state.convsCerradasPaginadas;
export const getConsultaPaginada = (state) => state.consultaPaginada;
export const getlistaCompletaConvsPaginadas = (state) => state.totalConvsFiltradas;
export const getConvsToDownloadCsv = (state) => state.convsCerradasConsultaCsv;
export const getDataTableWsFallidos = (state) => state.dataTableWsFallidos;
export const getDatosTablaConvsChatBot = (state) => state.datosTablaConvsChatbot;
export const getDatosTablaConvsChatBotPaginados = (state) => state.datosTablaConvsChatbotPaginados;
export const getDatosTablaConvsPresupuesto = (state) => state.datosTablaConvsPresupuesto;
export const getDatosTablaConvsPresupuestoPaginados = (state) => state.datosTablaConvsPresupuestoPaginados;
export const getDatosTablaDataUnknown = (state) => state.datosTablaDataUnknown;
export const getDatosTablaDataUnknownPaginados = (state) => state.datosTablaDataUnknownPaginados;
export const getDataIntentsPorcentajes = (state) => state.dataIntentsPorcentajes;
export const getComentariosInsatisfaccion = (state) => state.datosComentariosInsatisfaccion;
export const getComentariosInsatisfaccionPaginados = (state) => state.datosComentariosInsatisfaccionPaginados;
export const getDataGraficaTasaCoincidencia = (state) => state.dataGraficaDataCoincidencia;
export const getDataGeneradapCsv = (state) => state.dataCsvGenerada;
export const getDataPieTop10 = (state) => state.dataTop10Intents;
export const getDatosPieIntentsAgrupados = (state) => state.datosPieIntentsAgrupados;
export const getTmpPromConvsAgente = (state) => state.tmpPromConvsAgente;
export const getConvsResueltasAgentes = (state) => state.convsResueltasAgentes;
export const getDataBarTmpRespAgentes = (state) => state.dataBarTmpRespAgentes;
export const getDataBarTmpEspAgentes = (state) => state.dataBarTmpEspAgentes;
export const getTablesAgentProm = (state) => state.dateTableProm;
export const getDataReporteHuecos = (state) => state.dataReporteHuecos;
export const getGraficoReporteHuecos = (state) => state.graficoReporteHuecos;
export const getDataZonaParqueo = (state) => state.dataZonaParqueo;
export const getDataRegistroGAB = (state) => state.dataRegistroGAB;
export const getDataRegistroAtencionCanal = (state) => state.dataRegistroAtencionCanal;
export const getDataRegistroAtencionAgente = (state) => state.dataRegistroAtencionAgente;

export const getCsvConvsBot = (state) => state.dataCsvConvsBot;
export const getCsvDataUnknownBot = (state) => state.dataCsvUnknownBot;
export const getCsvDataInsatisfaccion = (state) => state.dataCsvInsatisfaccion;

export const getEncuestaAgente = (state) => state.encuestaAgente;
export const getDataCsvPie = (state) => state.dataCsvTop10;
export const getDataCsvAgrupados = (state) => state.dataAgrupadosCsv;
export const getSearchBigqueryTable = (state) => state.searchBigqueryTable;
export const getSearchTableGeneral = (state) => state.searchTableGeneral;


export const getManageSpinerState = (state) => state.manageSpinerState;
export const getShowSnackBar = (state) => state.showSnackBar;  