import React, { Component } from "react";
import "./style.css";
import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { DOWNLOAD_DOC, PAGINAR_DOCUMENTOS_PROCESADOS } from "./constants";
import { InputText } from "primereact/inputtext";
import SearchAccordion from "../../../components/searchaccordion/searchaccordion";

class ComponentDocumentosCargados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idDoc: false,
            filtroTable: false,
            labelTable: {
                columna1: 'Fecha de cargue',
                columna2: 'Nombre',
                columna3: 'Resumen',
                columna4: 'Acciones',
                columna5: 'Fecha procesamiento',
                columna6: 'Lote',
                columna7: 'Id Documento',


            }
        }

    }
    componentWillMount() {
        const {
            data

        } = this.props;
        this.setState({
            filtroTable: data
        })
        console.log("DATA DOCUMENTOS PROCESADOS", data)
    }
    idDocumento = (newData) => {
        const {
            mostrarDocumentosProcesados,
            showDocumentSelector
        } = this.props;
        this.setState({ idDoc: newData });
        mostrarDocumentosProcesados(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    descargarDocumento = (newData) => {
        const {
            downloadDoc
        } = this.props;
        //  console.log("descargarDocumento", newData)
        downloadDoc(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { data } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = data.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }

    componentDidUpdate(prevProps) {
        const {
            data
        } = this.props;
        if (prevProps.data !== data) {
            //  console.log("documentDataSelector", documentDataSelector);
            // getOCRDocumentos();
            this.setState({
                filtroTable: [...data]
            })
        }
    }

    

    render() {
        const {
            user,
            data,
            pdfData,
            cruceData,
            paginarDocumentosProcesados,
        } = this.props;
        return (
            <div className="App borrador-container">

                <Paper elevation={10} className="paper-conversaciones-ocr">
                    {/* 
            */}
                    {/* 
                    */}
                    <div className="search-container">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                        </span>

                    </div>
                    {/* <SearchAccordion
                            selectedForm={''} updateAttributes={''}
                            filtrarBusqueda={''} optionsEstados={''} lanzarEventos={''}
                            optionsAgentes={''} getConversations={''} limpiarForm={''}
                            showToast={''} totalConvsFiltradas={''}
                        ></SearchAccordion> */}
                    <ComponentTableDoc
                        downloadDoc={this.descargarDocumento}
                        labelTable={this.state.labelTable}
                        id={user}
                        cruceData={cruceData}
                        pdfData={pdfData}
                        idDocumento={this.idDocumento}
                        data={this.state.filtroTable}
                        dataPaginada={this.state.filtroTable}
                        paginarDocumentosProcesados={paginarDocumentosProcesados}
                        enablePaginacion={false}//{enablePaginacion}
                        title={'Documentos Procesados'} />
                </Paper>

            </div>


        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {
        paginarDocumentosProcesados: (value) => dispatch({ type: PAGINAR_DOCUMENTOS_PROCESADOS, value })

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDocumentosCargados);