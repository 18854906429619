import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../controller/session";
import { Button } from "@material-ui/core";

class ComponentError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorBot: false,
            colorBottom: false
        }

    }

    componentWillMount() {
        const {errorTitle} = this.props
        if(errorTitle === 'Error creando la pregunta'){
            this.setState({
                colorBottom: 'secondary',
            });
        }else{
            this.setState({
                colorBottom: 'primary',
            });
        }

    }

    render() {
        const {
        } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="modal-wrapper-bot">
                <div className="modal-background-bot" onClick={() => this.props.onModalCancelError()}> </div>
                <div className="modal-content-bot">
                    <h2>{this.props.errorTitle}</h2>
                <div className="description-text">
                    <h4>{this.props.body}</h4>
                </div>
                    <div className="align-button-auto">
                        <Button variant="contained" color={this.state.colorBottom} onClick={() => this.props.onModalCancelError()}>Cerrar</Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentError);