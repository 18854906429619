export const GET_USERS = '@users/getAll';
export const GET_USERS_SUCCESS = '@users/getUsersSuccess';
export const GET_USERS_ERROR = '@users/getUsersError';
export const VERIFICATED_EMAIL = '@users/verificatedEmail';
export const VERIFICATED_EMAIL_SUCCESS = '@users/verificatedEmailSucces';
export const VERIFICATED_EMAIL_ERROR = '@users/verificatedEmailError';

export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];
