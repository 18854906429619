import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Messages from "../../../components/chatCard/messages";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import PrintConversationData from '../../../components/printConversation/printConversation';
import './DataTableInformes.css';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";


export default class DataTableInformes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            convs: this.props.data,
            messagesDialog: false,
            selectedConv: null,
            submitted: false,
            globalFilter: null,
            basicFirst: 0,
            basicRows: 10,
            firstLastState: 2,
            paginaAnterior: {},
            listaPaginada: false,
            generandoCsv: false,
            btnDescargar: false

        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.verConversacion = this.verConversacion.bind(this);
        this.generarCSV = this.generarCSV.bind(this)

    }

    componentDidMount() {
        let prepareData = this.props.data;
        this.setState({
            convs: prepareData
        })
    }
    componentDidUpdate(prevProps) {
        const { convsCerradasPaginadas, consultaPaginada, data, totalConvsFiltradas,
            convsToDownloadCsv
        } = this.props
        if (this.state.convs != this.props.data) {
            this.setState({
                convs: this.props.data
            });
        }
        const paginatorPrev = document.querySelector('.p-paginator-prev');
        const paginatorNext = document.querySelector('.p-paginator-next');

        if (prevProps.convsToDownloadCsv != convsToDownloadCsv) {
            if (convsToDownloadCsv) {
                this.setState({
                    generandoCsv: false,
                    btnDescargar: true
                });

            }


        }


        if (prevProps.totalConvsFiltradas != totalConvsFiltradas) {
            if (!totalConvsFiltradas) {
                this.setState({
                    listaPaginada: false
                })
            }
        }

        if (convsCerradasPaginadas) {

            if (!consultaPaginada) {
                paginatorNext.classList.add('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else {
                if (convsCerradasPaginadas.length < this.state.basicRows) {
                    paginatorNext.classList.add('p-disabled');
                } else {
                    paginatorNext.classList.remove('p-disabled');
                }
                if (this.state.basicFirst == 0) {
                    paginatorPrev.classList.add('p-disabled');
                } else {
                    paginatorPrev.classList.remove('p-disabled');
                }

            }
        } else if (!convsCerradasPaginadas) {

            if (!this.state.listaPaginada && (data.length === this.state.basicRows)) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else if (this.state.listaPaginada.length < this.state.basicRows) {
                paginatorNext.classList.add('p-disabled');

            } else if ((this.state.listaPaginada.length === this.state.basicRows) && this.state.basicFirst == 0) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');

            }

        }
    }

    paginarConvsFiltradas(event) {
        const { totalConvsFiltradas } = this.props
        //console.log('totalConvsFiltradas', totalConvsFiltradas.length);

        let listaPaginada;

        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = totalConvsFiltradas.slice(event.first, last)
        } else {
            listaPaginada = totalConvsFiltradas.slice(event.first, event.rows)
        }
        //console.log("listaFiltradaCortada", listaPaginada);
        this.setState({
            listaPaginada: listaPaginada,
            basicFirst: event.first,
            basicRows: event.rows
        })
    }

    onBasicPageChange(event) {
        const { paginarConvsCerradas, seleccionRangoBusqueda, data,
            convsCerradasPaginadas, totalConvsFiltradas } = this.props
            console.log('totalConvsFiltradas', totalConvsFiltradas);
        if (totalConvsFiltradas) {
            this.paginarConvsFiltradas(event)

        } else {
            let ultimaConv = false;
            let primeraConv = false;
            let nextPage = this.state.firstLastState < event.first;

            this.setState({
                firstLastState: event.first,
                listaPaginada: false,
            });

            if (convsCerradasPaginadas) {
                if (convsCerradasPaginadas.length === event.rows && event.first > 0) {
                    //console.log('entro a paginar conversacionesPaginadas');
                    ultimaConv = convsCerradasPaginadas[event.rows - 1];
                    primeraConv = convsCerradasPaginadas[0];
                } else {
                    //console.log('entro a ultima pagina');
                    ultimaConv = convsCerradasPaginadas[convsCerradasPaginadas.length - 1];
                    primeraConv = convsCerradasPaginadas[0];
                }
            } else if (!convsCerradasPaginadas && data) {
                if (data.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimaConv = data[event.rows - 1];
                    primeraConv = data[0];
                }
            }
            this.setState({
                basicFirst: event.first,
                basicRows: event.rows
            });

            if (nextPage) {
                paginarConvsCerradas({ event, "ultimaConv": ultimaConv, nextPage, seleccionRangoBusqueda });
            } else {
                paginarConvsCerradas({ event, "ultimaConv": primeraConv, nextPage, seleccionRangoBusqueda });
            }
        }
    }

    openNew() {
        this.setState({
            submitted: false,
            messagesDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            messagesDialog: false
        });
    }

    generarCSV() {
        const { descargarCSV, seleccionRangoBusqueda } = this.props

        this.setState({
            generandoCsv: true
        })
        descargarCSV(seleccionRangoBusqueda)


    }

    verConversacion(product) {
        const { selectItem } = this.props

        selectItem(product.idReference);

        this.setState({
            product: { ...product },
            messagesDialog: true
        });
    }



    rightToolbarTemplate() {
        const { convsToDownloadCsv } = this.props


        return (
            <React.Fragment>

                <Button label="Generar CSV" icon="pi pi-file-excel"
                    loading={this.state.generandoCsv} className="p-button-help p-ml-2" onClick={this.generarCSV} />
                {convsToDownloadCsv && this.state.btnDescargar ? (<CSVLink data={convsToDownloadCsv}  separator={";"} filename={'conversacionesCerradas.csv'}>
                    <Button label="Descargar CSV" icon="pi pi-download" className="p-button-success p-ml-2"
                        onClick={e => {
                            this.setState({
                                btnDescargar: false
                            })
                            return false; // 👍🏻 You are stopping the handling of component
                        }}
                    />
                </CSVLink>) : false}

            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        // console.log(this.props)
        const { abrirModalDescargarDatos } = this.props


        return (
            <React.Fragment>
                <Button icon="pi pi-eye" title='Ver conversación' className="p-button-rounded p-button-info p-mr-1" onClick={() => this.verConversacion(rowData)} />
                <Button icon="pi pi-download" title='Descargar datos y conversación' className="p-button-rounded p-button-help p-mr-1" onClick={() => abrirModalDescargarDatos(rowData)}></Button>
            </React.Fragment>
        );
    }

    render() {
        const {
            itemsModal,
            columns,
            dataConv,
            verModalDescargarConversacion,
            cerrarModalDescargarDatos,
            convsCerradasPaginadas

        } = this.props

        const header = (
            <div className="table-header">
                <h5 className="p-m-0">Conversaciones</h5>

            </div>
        );

        const dynamicColumns = columns.map((col, i) => {
            return <Column key={col.field} field={col.field} header={col.header} />;
        });

        return (
            <div>
                <PrintConversationData
                    datos={dataConv}
                    verModalDescargarConversacion={verModalDescargarConversacion}
                    mensajes={itemsModal} cerrarModalDescargarDatos={cerrarModalDescargarDatos}
                >
                </PrintConversationData>

                <div className="card">
                    <DataTable id="convsCerradasTable" columnResizeMode="fit" className="p-datatable-gridlines"
                        ref={(el) => this.dt = el} value={this.state.listaPaginada ? this.state.listaPaginada : convsCerradasPaginadas ? convsCerradasPaginadas : this.state.convs} selection={this.state.selectedConv}
                        onSelectionChange={(e) => this.setState({ selectedConv: e.value })}
                        dataKey="id" /* paginator */ header={header} emptyMessage="No hay registros">
                        {dynamicColumns}
                        <Column headerStyle={{ width: '85px' }} header="Acciones" body={this.actionBodyTemplate}></Column>
                    </DataTable>
                    <Paginator first={this.state.basicFirst} rows={this.state.basicRows} rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        template="FirstPageLink PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport"
                        currentPageReportTemplate="Página {currentPage} de la lista de conversaciones"
                        onPageChange={this.onBasicPageChange} totalRecords={1000000}>

                    </Paginator>
                </div>
                <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>

                <Dialog visible={this.state.messagesDialog} style={{ width: '450px' }} header="Conversación" modal className="p-fluid" onHide={this.hideDialog}>
                    <Messages mensajes={itemsModal} ></Messages>

                </Dialog>
            </div>

        );
    }
}


