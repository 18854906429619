import {
  CONVERSATIONS_HEADERS,
  USUARIOSCONECTADOS_HEADERS,
  FILTER_CONVERSATIONS_SUCCESS,
  GET_CONVERSATIONS_ERROR,
  GET_CONVERSATIONS_SUCCESS,
  ITEMS_PER_PAGE,
  CLEAR_CONVERSATION_START,
  SEARCH_CONVERSATIONS_SUCCESS,
  SELECT_CONVERSATION,
  SELECT_CONVERSATION_SUCCESS,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_SUCCESS,
  UPDATE_FIELD_ATTRIBUTE,

  LISTAR_USUARIOS_CONECTADOS_SUCCESS,
  LISTAR_USUARIOS_CONECTADOS_CLOSE,
  SELECT_CONVERSATION_MESSAGES_SUCCESS,
  LISTAR_TEMAS,
  LISTAR_TEMAS_CLOSE,
  LISTAR_TEMAS_SUCCESS,
  MODAL_ADJUNTAR_CLOSE,
  MODAL_ADJUNTAR_OPEN,
  ADJUNTAR_ARCHIVOS,
  ADJUNTAR_ARCHIVOS_SUCCESS,
  RESUELTA_SUCCESS,
  SET_TEMA_SUCCESS,
  TRANSFER_SUCCESS,
  END_CONVERSATION_SUCCESS,

  LISTAR_ESTADOS_SUCCESS,
  LISTAR_AGENTES_SUCCESS,
  LISTAR_TIMEOUT_SUCCESS,
  CLEAN_FORM,
  LISTAR_FAQS,
  LISTAR_FAQS_SUCCESS,
  LISTAR_FAQS_CLOSE,
  PRINT_CONVERSATION_SUCCESS,
  CLOSE_MODAL_PRINT,
  LOAD_MENU_SUCCES,
  TRANSFERENCIAS_CONVERSACION_SUCCES,
  TRANSFERENCIAS_CONVERSACION,
  INFO_GENERAL,
  NOTAS_OCULTAS,
  MODAL_NOTAS_OPEN,
  ADD_NOTA_OCULTA_SUCCES,
  NOTAS_OCULTAS_SUCCESS,
  PAGINATE_CONVERSATIONS,
  PAGINATE_CONVERSATIONS_SUCCESS,
  SAVE_STORAGE_LIST_FAQS,
  OPEN_SPINER_FAQS,
  SAVE_TOTAL_CONVERSATIONS_REFRESH,
  GUARDAR_CONSULTA_BIGQUERY,
  OPEN_SPINNER_CHARGING,

} from './constants';

export const initialState = {
  conversationsHeaders: CONVERSATIONS_HEADERS,
  conversations: false,
  conversationsListFiltered: false,
  conversationData: {},
  spinnerBoolean: false,
  usuariosConectadosHeaders: USUARIOSCONECTADOS_HEADERS,

  totalConversationslist: 0,
  pagination: false,
  itemsPerPage: ITEMS_PER_PAGE,
  page: 0,
  messageForm: {},
  selectedForm: {},
  usuariosConectados: false,
  verPanelTransfer: false,
  playSound: false, playSoundConv: false,
  usuariosDisplay: false,
  verPanelAdjuntar: false,


  estadosDisplay: false,
  objEstados: false,
  agentesDisplay: false,
  objAgentes: false,

  temasDisplay: false,
  verPanelTemas: false,
  objTemas: false,

  spinnerAdjuntar: false,
  spinnerTemas: false,
  spinnerFaqs: false,

  toastMensaje: false,
  toastDetail: false,
  sessionTimeout: false,

  faqsDisplay: false,
  objFaqs: false,
  verPanelFaqs: false,
  convTransferida: false,

  infoConv: false,
  convMensajes: false,
  verPanelPrint: false,

  menuItems: false,
  listaTransferencias: false,
  listaNotas: false,
  showTransferencias: false,
  showNotasOcultas: false,
  showInfoGeneral: false,
  modalNotasOcutas: false,
  listFaqs: false,
  conversacionesPaginadas: false,
  consultaPaginada: false,
  convsFiltradas: false,
  consultaBigQuery: false,
  manageSpinerState: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;
  const { conversations, playSoundConv, limiteDias } = payload ? payload : {};
  newState.playSound = false;
  newState.verPanelTransfer = false;
  newState.toastMensaje = false;
  newState.sessionTimeout = false;
  newState.consultaPaginada = true;

  switch (type) {

    case PAGINATE_CONVERSATIONS:
      newState.consultaPaginada = false;

      return {
        ...newState
      };

    case PAGINATE_CONVERSATIONS_SUCCESS:

      if (payload.esPrimeraPagina) {
        newState.conversations = conversations;
        newState.playSoundConv = payload.playSoundConv;
      } else {
        newState.conversations = payload.conversacionesPaginadas;
        newState.playSoundConv = payload.playSoundConv;
      }
      //newState.totalConversationslist = payload.totalConversations;
      newState.consultaPaginada = true;
      return {
        ...newState
      };

    case GET_CONVERSATIONS_SUCCESS:

      //newState.totalConversationslist = payload.conversations;
      newState.conversations = payload.conversations;
      newState.playSoundConv = payload.playSoundConv;

      return {
        ...newState
      };

    case SAVE_TOTAL_CONVERSATIONS_REFRESH:
      newState.totalConversationslist = payload;


      return {
        ...newState
      };
    case CLEAR_CONVERSATION_START:
      newState.conversationData = {};
      return {
        ...newState
      };
    case GET_CONVERSATIONS_ERROR:
      return {
        ...newState
      };
    case SEARCH_CONVERSATIONS_SUCCESS:
      newState.conversations = payload;
      //newState.totalConversationslist = payload;
      return {
        ...newState
      };

    case SELECT_CONVERSATION_MESSAGES_SUCCESS:
      let { mensajes, inited, lastElement } = payload;
      //console.log('mensajes', mensajes)
      if (!inited) {
        newState.conversationData.mensajes = mensajes;
        newState.spinnerBoolean = false;
        newState.pagination = false;
      } else {
        newState.conversationData.mensajes.push(lastElement)
        if (lastElement.data().from.tipo === 'cliente') {
          newState.playSound = true;
        }
      }
      return {
        ...newState
      };

    case SELECT_CONVERSATION:
      newState.spinnerBoolean = true;
      newState.showInfoGeneral = true;
      newState.showNotasOcultas = false;
      newState.showTransferencias = false;
      //console.log('selectConversation', action)


      return {
        ...newState
      };

    case SELECT_CONVERSATION_SUCCESS:

      let { conv } = payload;
      newState.playSoundConv = playSoundConv;
      newState.conversationData.conv = conv;
      return {
        ...newState
      };

    case FILTER_CONVERSATIONS_SUCCESS:

      newState.convsFiltradas = payload.convsFiltradas;
      newState.totalConversationslist = payload.total;
      if (payload.paginada) {
        if (payload.esPrimeraPagina) {
          newState.conversacionesPaginadas = conversations;
        } else {
          newState.conversacionesPaginadas = payload.convsFiltradas;
        }
        newState.consultaPaginada = true;
      }
      //newState.convsFiltradas = payload.convsFiltradas;
      return {
        ...newState
      };

    case SEND_MESSAGE_SUCCESS:
      newState.verPanelFaqs = false;
      newState.spinnerFaqs = false;

      return {
        ...newState
      };

    case SEND_MESSAGE_ERROR:

      return {
        ...newState
      };

    case GUARDAR_CONSULTA_BIGQUERY:
      newState.consultaBigQuery = payload;
      return { ...newState };

    case UPDATE_FIELD_ATTRIBUTE:
      const { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;
      newState.messageForm = newFormObject;
      newState.selectedForm = newFormObject;
      newState.message = false;
      return {
        ...newState
      };
    case CLEAN_FORM:
      newState.selectedForm = {};
      return {
        ...newState
      };
    case LISTAR_USUARIOS_CONECTADOS_SUCCESS:
      newState.usuariosConectados = payload.usuariosConectados;
      newState.verPanelTransfer = payload.verPanelTransfer;
      newState.usuariosDisplay = payload.usuariosDisplay;

      return {
        ...newState
      };

    case LISTAR_FAQS_SUCCESS:
      newState.faqsDisplay = payload.faqsDisplay;
      newState.objFaqs = payload.objFaqs;
      newState.spinnerFaqs = false;

      return {
        ...newState
      };

    case OPEN_SPINER_FAQS:
      newState.verPanelFaqs = true;
      newState.spinnerFaqs = true;

      return {
        ...newState
      };

    case LISTAR_TEMAS_SUCCESS:
      newState.temasDisplay = payload.temasDisplay;
      if (!newState.objTemas) {
        newState.objTemas = payload.objTemas;
        newState.spinnerTemas = false
      }



      return {
        ...newState
      };

    case PRINT_CONVERSATION_SUCCESS:
      newState.infoConv = payload.infoOrdenada;
      newState.convMensajes = payload.conversation;
      newState.verPanelPrint = payload.verModalPrint;

      return {
        ...newState
      };

    case CLOSE_MODAL_PRINT:
      newState.verPanelPrint = false;

      return {
        ...newState
      };


    case LISTAR_TEMAS:

      newState.verPanelTemas = true;
      newState.spinnerTemas = newState.verPanelTemas ? true : false;
      if (newState.objTemas) {
        newState.spinnerTemas = false;
      }

      return {
        ...newState
      };


    case LISTAR_TEMAS_CLOSE:
      newState.verPanelTemas = false;


      return {
        ...newState
      };

    case MODAL_ADJUNTAR_CLOSE:
      newState.verPanelAdjuntar = false;

      return {
        ...newState
      }
    case MODAL_ADJUNTAR_OPEN:
      newState.verPanelAdjuntar = true;
      return {
        ...newState
      };

    case ADJUNTAR_ARCHIVOS_SUCCESS:
      newState.verPanelAdjuntar = false;
      newState.spinnerAdjuntar = false;
      newState.toastDetail = payload;
      newState.toastMensaje = true;
      return {
        ...newState
      };
    case ADJUNTAR_ARCHIVOS:
      newState.spinnerAdjuntar = true;
      return {
        ...newState
      };

    case RESUELTA_SUCCESS:
      newState.toastMensaje = true;
      newState.toastDetail = payload;
      return {
        ...newState
      };

    case SET_TEMA_SUCCESS:
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;
      newState.verPanelTemas = payload.verModalTemas;

      return {
        ...newState
      };

    case TRANSFER_SUCCESS:
      newState.conversationData = {};
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;

      return {
        ...newState
      };


    case END_CONVERSATION_SUCCESS:
      newState.toastMensaje = true;
      newState.toastDetail = payload;
      newState.conversationData = {};
      return {
        ...newState
      };
    case LISTAR_FAQS_CLOSE:
      newState.verPanelFaqs = false;
      newState.modalNotasOcutas = false;

      return {
        ...newState
      };

    case LISTAR_USUARIOS_CONECTADOS_CLOSE:
      newState.verPanelTransfer = false
      return {
        ...newState
      };
    case LISTAR_ESTADOS_SUCCESS:
      newState.objEstados = payload.objEstados;
      newState.estadosDisplay = payload.estadosDisplay;
      return {
        ...newState
      };
    case LISTAR_AGENTES_SUCCESS:
      newState.objAgentes = payload.objAgentes;
      newState.agentesDisplay = payload.agentesDisplay;
      return {
        ...newState
      };
    case LISTAR_TIMEOUT_SUCCESS:
      newState.sessionTimeout = true
      return {
        ...newState
      }
    case LOAD_MENU_SUCCES:
      newState.menuItems = payload

      return {
        ...newState
      }

    case TRANSFERENCIAS_CONVERSACION:
      newState.showTransferencias = true;
      newState.showInfoGeneral = false;
      newState.showNotasOcultas = false;
      return {
        ...newState
      }
    case TRANSFERENCIAS_CONVERSACION_SUCCES:
      newState.listaTransferencias = payload;
      return {
        ...newState
      }
    case NOTAS_OCULTAS_SUCCESS:
      newState.listaNotas = payload;
      return {
        ...newState
      }
    case INFO_GENERAL:
      newState.showInfoGeneral = true;
      newState.showNotasOcultas = false;
      newState.showTransferencias = false;

      return {
        ...newState
      }
    case NOTAS_OCULTAS:
      newState.showNotasOcultas = true;
      newState.showInfoGeneral = false;
      newState.showTransferencias = false;
      return {
        ...newState
      }

    case MODAL_NOTAS_OPEN:
      newState.modalNotasOcutas = true;

      return {
        ...newState
      }

    case SAVE_STORAGE_LIST_FAQS:
      newState.listFaqs = payload;

      return { ...newState }

    case ADD_NOTA_OCULTA_SUCCES:
      newState.modalNotasOcutas = payload.verModalNotas;
      newState.toastMensaje = true;
      newState.toastDetail = payload.toastDetail;
      newState.selectedForm = {};

      return {
        ...newState
      }

    case OPEN_SPINNER_CHARGING:
      newState.manageSpinerState = payload;

      return { ...newState }


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
