export const GET_CONVERSATIONS = '@conversations/getAll';
export const GET_CONVERSATIONS_SUCCESS = '@conversations/getConversationsSuccess';
export const CLEAR_CONVERSATION_START = '@conversations/clearConversationStart';
export const GET_CONVERSATIONS_ERROR = '@conversations/getConversationsError';
export const SEARCH_CONVERSATIONS = '@conversations/searchConversations';
export const SEARCH_CONVERSATIONS_SUCCESS = '@conversations/searchConversationsSucces';
export const SEARCH_CONVERSATIONS_ERROR = '@conversations/searchConversationsError';
export const SELECT_CONVERSATION = '@conversations/getDetail';
export const SELECT_CONVERSATION_SUCCESS = '@conversations/getDetailSuccess';
export const SELECT_CONVERSATION_MESSAGES_SUCCESS = '@conversations/getDetailMessagesSuccess';
export const SELECT_CONVERSATION_ERROR = '@conversations/getDetailError';

export const FILTER_CONVERSATIONS = '@conversations/filterConversationslist'
export const FILTER_CONVERSATIONS_SUCCESS = '@conversations/filterConversationslistSuccess';
export const PAGINATE_CONVERSATIONS = '@conversations/paginateConversations';
export const PAGINATE_CONVERSATIONS_SUCCESS = '@conversations/paginateConversationsSuccess';
export const PAGINATE_CONVERSATIONS_ERROR = '@conversations/paginateConversationsError';
export const SELECT_ROWS_PER_PAGE = '@conversations/selectRowsPageSuccess';

export const SEND_MESSAGE = '@conversations/sendMessage';
export const SEND_MESSAGE_SUCCESS = '@conversations/sendMessageSuccess';
export const SEND_MESSAGE_ERROR = '@conversations/sendMessageError';
export const UPDATE_FIELD_ATTRIBUTE  = '@conversations/updateAttribute';
export const LISTENER_LOGOUT  = '@conversations/listenerLogout';
export const LISTAR_TIMEOUT_SUCCESS  = '@conversations/timeOutSuccess';
export const LOAD_MENU_SUCCES= '@conversations/loadMenuSucces';
export const LOAD_MENU= '@conversations/loadMenu';
export const PRUEBA='@conversations/prueba';
export const ITEMS_PER_PAGE = 10;
export const TRANSFERENCIAS_CONVERSACION = '@conversations/obtenerTransferenciasConversacion';
export const TRANSFERENCIAS_CONVERSACION_SUCCES = '@conversations/obtenerTransferenciasConversacionSuccess';
export const TRANSFERENCIAS_CONVERSACION_ERROR ='@conversations/obtenerTransferenciasConversacionError';

export const SESSION_TIMEOUT  = 1800000;
export const INFO_GENERAL = '@conversations/activarTabInfoGeneral';
export const NOTAS_OCULTAS = '@conversations/activarTabNotasOcultas';
export const NOTAS_OCULTAS_SUCCESS = '@conversations/activarTabNotasOcultasSuccess'
export const DATA_CONVERSATION ={ name: 'Nombre', mail:'Correo Electronico'};

export const MESSAGE_FORMS = [
    {
        label: "",
        name: "mensaje",
        placeholder: "Escribe un mensaje...",
        type: "text",
        typeForm:"text-area",
        value: false,
        options: [],
        disable: false,
        display:true
    }
]

export const CONVERSATIONS_HEADERS = [
    {label: "", column: "estado"}, 
    {label: "Nombre", column: "nombre"},
    {label: "Agente", column:"agente"},
    {label: "Fecha Inicio", column: "fecha_ini"},
    {label:"Resuelta", columns:"resuelta"},
    {label: "Canal", column: "canal"},
    
    
];

export const USUARIOSCONECTADOS_HEADERS = [
    {label: "Habilidad", column: "habilidad"}, 
    {label: "Nombre", column: "nombreAgente"},
    {label: "Conversaciones Actuales", column:"convsActuales"},
    //{label: "Acción", column: "accion"},
];

export const TEMAS_HEADERS = [
    {label:"Nivel 1", columns: "nivel1" },
    {label:"Nivel 2", columns: "nivel2" },
    {label:"Nivel 3", columns: "nivel3" },
]

export const FAQS_HEADERS = [
    {label:"Pregunta", columns: "pregunta"},
    {label:"Respuesta",column:"respuesta"},
    
]


export const PAGE_SIZE = [10, 15, 20];

export const GENERAL_ERROR = '@conversations/generalError';

export const SET_TEMA = '@conversations/setTema';
export const SET_TEMA_SUCCESS = '@conversations/setTemaSuccess';
export const TRANSFER = '@conversations/transferirConversacion';
export const TRANSFER_SUCCESS = '@conversations/transferirConversacionSuccess';
export const END_CONVERSATION = '@conversations/finalizarConversacion';
export const END_CONVERSATION_SUCCESS = '@conversations/finalizarConversacionSuccess';

export const RESUELTA = '@conversations/resuelta';
export const RESUELTA_SUCCESS = '@conversations/resueltaSuccess';
export const LISTAR_USUARIOS_CONECTADOS = '@conversations/listarUsuariosconectados';
export const LISTAR_USUARIOS_CONECTADOS_SUCCESS = '@conversations/listarUsuariosconectadosSuccess';
export const LISTAR_USUARIOS_CONECTADOS_CLOSE = '@conversations/listarUsuariosconectadosClose';
export const LISTAR_TEMAS = '@conversations/listarTemas';
export const LISTAR_TEMAS_CLOSE ='@conversations/listarTemasClose';
export const LISTAR_TEMAS_SUCCESS = '@conversations/listarTemasSuccess';

export const ADJUNTAR_ARCHIVOS = '@conversations/adjuntarArchivosUpload';
export const ADJUNTAR_ARCHIVOS_SUCCESS = '@conversations/adjuntarArchivosUploadSuccess';
export const MODAL_ADJUNTAR_CLOSE = '@conversations/modalAdjuntarClose';
export const MODAL_ADJUNTAR_OPEN = '@conversations/modalAdjuntarOpen';
export const FILTRAR_BUSQUEDA = '@conversations/filtrarBusqueda';
export const FILTRAR_BUSQUEDA_SUCCESS = '@conversations/filtrarBusquedaSuccess';
export const LISTAR_ESTADOS = '@conversations/listarEstados';
export const LISTAR_ESTADOS_SUCCESS = '@conversations/listarEstadosSuccess';
export const LISTAR_AGENTES = '@conversations/listarAgentes';
export const LISTAR_AGENTES_SUCCESS = '@conversations/listarAgentesSucess';
export const LANZAR_EVENTOS = '@conversations/desplegarEventosBuscador';

export const CLEAN_FORM = '@conversations/cleanSelectedForm';
export const TOMAR_CONTROL = '@conversations/tomarControlConversation';

export const LISTAR_FAQS = '@conversations/listarFaQs';
export const OPEN_SPINER_FAQS = '@conversations/openSpinnerFAQs'
export const SAVE_STORAGE_LIST_FAQS = '@conversations/saveStorageListFaqs'
export const LISTAR_FAQS_SUCCESS = '@conversations/listarFaQsSuccess';
export const LISTAR_FAQS_CLOSE = '@conversations/listarFaQsClose';

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';
export const INIT_TOGGLE_STATE = '@conversations/initToggleState';
export const PRINT_CONVERSATION='@conversations/printConversation';
export const PRINT_CONVERSATION_SUCCESS='@conversations/printConversationSuccess';
export const CLOSE_MODAL_PRINT='@conversations/cerrarModalPrint';
export const MODAL_NOTAS_OPEN = '@conversations/abrirModalNotasOcultas';
export const ADD_NOTA_OCULTA ='@conversations/addNotaOcultaConv';
export const ADD_NOTA_OCULTA_SUCCES ='@conversations/addNotaOcultaConvSuccess';
export const ADD_NOTA_OCULTA_ERROR ='@conversations/addNotaOcultaConvError';
export const CONSULTAR_DIALOG_FLOW_PROPERTY = "@conversations/consultarDialogFlow";
export const SAVE_TOTAL_CONVERSATIONS_REFRESH = '@conversations/saveTotalConversationsRefresh';
export const GUARDAR_CONSULTA_BIGQUERY = '@conversations/guardarConsultaBigQuery';
export const OPEN_SPINNER_CHARGING = '@conversations/openSpinnerCharging';


export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "content-type": "application/json;charset=iso-8859-1",
    "Cache-Control": "no-cache",
    
}


export const getSearchButton = (handlerSafe) => {
    return [
        {
            label: "Filtrar",
            className: "btn btn-primary",
            action: false,
            style: "primary",
            onClick: handlerSafe,
            display:true

            
        },
        {
            label: "Limpiar",
            className: "btn btn-primary",
            action: false,
            style: "primary",
            onClick: handlerSafe,
            display:true

            
        }

    ]
}