import axios from "axios";
import querystring from 'querystring';
import { call } from "redux-saga/effects";
import { firebaseDatabase, firebaseController } from "./firebase";
import { consultaProperty } from "./configuration"
import { getFromSession } from './session'

export function* enviarMensajeWhatsapp(company, telefono, mensaje) {
  //Se debe consumir el API externo para enviar el mensaje
  try {
    let apikey = yield consultaProperty(company, 'KEY_WHATSAPP');
    var payload = yield buildMessage(company, telefono, mensaje);


    // axios.create({
    //   headers: {
    //     accept: 'application/json, text/plain, */*',
    //     apikey: apikey,
    //     'content-type': 'application/x-www-form-urlencoded',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    // }).post('https://api.gupshup.io/sm/api/v1/msg', querystring.stringify(payload))
    //   .then(response => {
    //     console.log(response.data)
    //   })
    //   .catch(err => {
    //     console.error('error invocando gupshup:' + err + " " + err.message)
    //     throw err;
    //   }
    //   )
    let data = querystring.stringify(payload);
    const headers = {
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "origin, content-type, accept, authorization, apikey, token, cbUrl",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
      "Allow": "HEAD,POST,GET,OPTIONS",
      'Content-Type': 'application/json',

    }

    let config = {
      method: 'post',
      url: 'https://api.gupshup.io/sm/api/v1/msg',
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "origin, content-type, accept, authorization, apikey, token, cbUrl",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
        "Allow": "HEAD,POST,GET,OPTIONS",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        'Content-Type': 'application/x-www-form-urlencoded',
        'apikey': apikey,
      },
      data: data
    };



    yield call(axios.post, process.env.REACT_APP_AXIOS_GENERICO_BACKEND, config, headers);


  } catch (err) {
    console.error('gupshup.enviarmensaje: ' + err);
    throw err;
  }
}


export function* getTemplateList() {
  const company = getFromSession("company_id");
  let apiKey = yield consultaProperty(company, 'KEY_WHATSAPP');
  let appName = yield consultaProperty(company, 'APP_GUPSHUP');  /* 'chatasesores' */
  let resp = false;
  
  try {

    const headers = {
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Headers": "origin, content-type, accept, authorization, apikey, token, cbUrl",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
      "Allow": "HEAD,POST,GET,OPTIONS",
      'Content-Type': 'application/json',

    }

    let config = {
      method: 'get',
      url: `https://api.gupshup.io/sm/api/v1/template/list/${appName}`,
      headers: {
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": "origin, content-type, accept, authorization, apikey, token, cbUrl",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
        "Allow": "HEAD,POST,GET,OPTIONS",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        "accept": 'application/json, text/plain, */*',
        "apikey": apiKey,
        'content-type': 'application/x-www-form-urlencoded'
      }
    };


    resp =  yield call(axios.post, process.env.REACT_APP_AXIOS_GENERICO_BACKEND, config, headers);

    console.log("data*****", resp);
    return resp;

  } catch (err) {
    console.error('controller.whatsapp.getTemplateList: ' + err);
    throw err;
  }

}

function* buildMessage(company, telefono, mensaje) {
  try {
    let numWhatsapp = yield consultaProperty(company, 'TELEFONO_WHATSAPP');;
    let payload = {
      channel: 'whatsapp',
      source: numWhatsapp,
      destination: telefono,
    };

    if (mensaje.type === 'text') {
      payload['message.payload'] = {
        type: 'text',
        text: mensaje.text,
      };
    } else if (mensaje.type === 'image') {
      payload['message.payload'] = {
        type: mensaje.type,
        originalUrl: mensaje.url,
        previewUrl: mensaje.url,
        caption: mensaje.caption,
        filename: mensaje.caption
      }
    } else if (mensaje.type === 'file') {
      payload['message.payload'] = {
        type: mensaje.type,
        filename: mensaje.filename,
        url: mensaje.url
      }
    } else if (mensaje.type === 'audio') {
      payload['message.payload'] = {
        url: mensaje.url,
        type: mensaje.type,
      }
    } else if (mensaje.type === 'video') {
      payload['message.payload'] = {
        url: mensaje.url,
        type: mensaje.type,
        caption: mensaje.caption
      }
    } else if (mensaje.type === 'location') {
      payload['message.payload'] = {
        longitude: mensaje.longitude,
        latitude: mensaje.latitude,
        address: mensaje.address,
        name: mensaje.name,
        type: mensaje.type
      }
    }
    payload['message.payload'] = JSON.stringify(payload['message.payload']);

    return payload;
  } catch (err) {
    console.error('gupshup.buildMessage: ' + err);
    throw err;
  }
}
