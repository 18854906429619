import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getDataCompanySelector, getMenuItemsChatBot, getMenuItemsOCR, getUploadBucketSelector, getUsers, selectLoading, selectLoadingCircular, selectLoadingCircularFall, selectLoadingProc, selectorAlert, selectorContador, selectorCrossDocument, selectorDatosDocPrincipal, selectorDocumetosProcesados, selectorFailDocument, selectorGetLotes, selectorLongitud, selectorName, selectorOcrActivo, selectorOcrCreate, selectorProDocument, selectorShowCrossDocument, selectorShowDocument, selectorShowTable, selectorShowToast, selectorSize, selectorToastDetail, selectordocumentData } from "./selectors";
import {
    COMPANY_DATA,
    CROSS_DOC,
    DOCUMENTOS_CARGADOS_SUCCESS,
    DOWNLOAD_DOC,
    GET_DOC_FALL,
    GET_DOC_PROC,
    GET_LOTES,
    GET_OCR_DOC,
    GET_OCR_SIZE,
    GET_USERS, LOAD_MENU, OCR_ACTIVO, OCR_CREATE, PRO_OCR_DOC, SHOW_DOC_PROC, UPLOAD_BUCKET
} from './constants';
import SideBar from "../../../components/sidebar/sidebar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Button, Card, TextField, Paper } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import File from "./fileUpload";
import ComponentWalcome from "./walcomeOCR";
import ComponentError from "./errorPopUpComponent";
import { Toast } from "primereact/toast";
import ComponentTable from "./ocrTable/componentTable";
import ComponentMisDocumentos from "./misDocumentos/component";
import ComponentConfirmar from "./modalConfirmar";

class ComponentLoteOCR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorUpload: false,
            filtroTable: [],
            table: false,
            cargarDocumento: true,
            errorTitle: false,
            proDoc: false,
            lote: false,
        }
    }
    componentDidMount() {
        const { companyData, getOCRDocumentos, ocrActivoSelector, sizeSelector, getMaxSize, isOcrEnabled } = this.props;
        //console.log("sizeSelector", sizeSelector)
        isOcrEnabled();
        companyData();
        getMaxSize();



    }


    componentWillMount() {
        const { getUsers, lotesSelector, getLotes, loadMenuImageOCR, getOCRDocumentos, getMaxSize, showTableSelector, sizeSelector, ocrActivoSelector, isOcrEnabled, uploadToBucket } = this.props;
        getMaxSize()
        getUsers();
        isOcrEnabled();
        loadMenuImageOCR();
        //getOCRDocumentos();
        getLotes();
    }

    componentWillUnmount() {

    }
    handlerOCR = (e) => {
        const { ocrActivoSelector, ocrCreate, isOcrEnabled } = this.props
        //console.log("ocrComponent", this.state.ocrComponent)
        ocrCreate();
        //console.log("selectorOcrActivo", selectorOcrActivo)
        this.setState({ ocrComponent: true });
        //console.log("ocrComponent", this.state.ocrComponent)
        isOcrEnabled()
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { lotesSelector } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = lotesSelector.filter(function (objeto) {
            var valorPropiedad = String(objeto.lote).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }

    handleDataChange = (newData) => {
        const { uploadToBucket, sizeSelector } = this.props;
        console.log("sizeSelector", sizeSelector)
        if (newData.length === 0) {
            this.setState({
                bodyError: 'Por favor seleccione al menos un archivo para cargar',
                errorUpload: true
            });
            return;
        }

        for (const archivo of newData) {
            if (archivo.size >= sizeSelector.value) {
                //console.log("TRUEEEEEE 0", newData)
                this.setState({
                    bodyError: 'Los archivos deben ser menores a ' + sizeSelector.valueMB + 'MB',
                    errorUpload: true
                });
                return;
            }
        }

        uploadToBucket(newData)
        this.onOpenTableHandler()

    };

    onModalCancelError = () => {
        this.setState({ errorUpload: false });
    }

    onProcesarDoc = (payload) => {
        console.log("DOCUMENTOS A PROESAR", payload)

        const { procesarDocs } = this.props;
        if (payload.length === 0) {
            this.setState({
                bodyError: 'Por favor seleccione al menos un archivo para cargar',
                errorUpload: true,
                errorTitle: "Error cargando archivos"
            });
            return;
        }
        for (const doc of payload) {
            if (doc.estado === 'Orden de procesamiento'
                || doc.estado === 'En procesamiento'
                || doc.estado === 'Procesado'
            ) {
                this.setState({
                    bodyError: 'Solo puedes procesar archivos con estado, Cargado en bucket y Procesado con error',
                    errorUpload: true,
                    errorTitle: "Error procesando archivos"
                });
                return;
            }
        }

        this.setState({
            lote: payload[0].lote
        });
        procesarDocs(payload)
        //this.onOpenDocPro()

    }
    onCloseTableHandler = () => {
        const { getLotes } = this.props;
        getLotes()
        this.setState({ table: false })
    }

    onOpenTableHandler = (dataLote) => {
        const { getOCRDocumentos, documentDataSelector } = this.props;

        this.setState({ proDoc: true, lote: dataLote.lote })

        console.log("LOTEEE", dataLote.lote)
        //getOCRDocumentos(dataLote.lote)
    }

    onOpenTable = (dataLote) => {
        const { getOCRDocumentos, documentDataSelector } = this.props;

        this.setState({ table: true, lote: dataLote.lote })

        console.log("LOTEEE", dataLote.lote)
        getOCRDocumentos(dataLote)
    }
    onOpenDocPro = () => {
        this.setState({ proDoc: true })
    }
    onCloseDocPro = () => {
        this.setState({ proDoc: false })
    }

    onCloseConfirmar = () => {
        const {confirmarDocumentoCargado} = this.props
        confirmarDocumentoCargado()
    }

    componentDidUpdate(prevProps) {

        const { showToastSelector, ocrActivoSelector, lotesSelector, showTableSelector, getOCRDocumentos, documentProSelector, documentDataSelector, toastDetailSelector } = this.props;
        // if (showTableSelector && this.state.table === false) {
        //     this.setState({ table: true })
        // }

        // if(documentProSelector && this.state.proDoc === false){
        //     this.setState({ proDoc: true })
        // }

        //console.log("selectorShowToast", showToastSelector)
        if (prevProps.showToastSelector !== showToastSelector && !this.state.proDoc) {
            this.toast.show({
                severity: toastDetailSelector.severity,
                summary: toastDetailSelector.summary,
                detail: toastDetailSelector.detail,
            });
        }

        if (prevProps.lotesSelector !== lotesSelector) {
            // console.log("intentsData", intentsData)
            //  console.log("prevProps.intentsData", prevProps.intentsData)
            this.setState({
                filtroTable: [...lotesSelector]
            })
            //getOCRDocumentos()
        }
        if (prevProps.lotesSelector !== lotesSelector) {
            console.log("lotesSelector", lotesSelector)
        }


    }



    render() {
        const {
            getDocumentosProcesados,
            uploadToBucket,
            selectorDataCompany,
            menuItemsOCR,
            contadorSelector,
            logintudSelector,
            loading,
            loadingCircular,
            ocrActivoSelector,
            documentDataSelector,
            nombreSelector,
            documentosProcesadosSelector,
            showDocumentSelector,
            mostrarDocumentosProcesados,
            getDocumentosFallidos,
            downloadDoc,
            crossDoc,
            crossDocumentSelector,
            loadingProc,
            failDocumentSelector,
            loadingFall,
            lotesSelector,
            alertSelector,
            confirmarDocumentoCargado
        } = this.props;
        return (
            /*
        <div>
        <h1>OCR</h1>f 
        </div>*/

            <Paper className="App home-container">
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}

                {alertSelector ?
                //console.log("alertSelector")
                    // <div className="modalDoc">
                    //     <p className="textDocument">
                    //         Documentos cargados exitosamente
                    //     </p>
                    //     <Button onClick={()=> this.onCloseConfirmar()}>
                            
                    //         Aceptar
                    //     </Button>
                    // </div>     
                    <ComponentConfirmar
                        title={'Procesamiento terminado'}
                        body={'Para visualizar los documentos, por favor haga clic en el botón "Documentos Procesados".'}
                        cerrarModal={this.onCloseConfirmar}
                        botonText={'Aceptar'} />
                        : ''
                }
                {loading &&

                    <div className="loading-wrapper-documentos">
                        <div className="titulo-documentos">
                            <h1>
                                Cargando Archivo - {nombreSelector}
                            </h1>
                        </div>
                        <div>
                            <CircularProgress color="secondary"></CircularProgress>
                            {/* <h1>
                                Archivos cargados {contadorSelector} de {logintudSelector}
                            </h1> */}
                        </div>
                    </div>
                }
                {/*
                {loadingProc &&

                    <div className="loading-wrapper-documentos">
                        <div className="titulo-documentos">
                            <h1>
                                Procesando Archivo - {nombreSelector}
                            </h1>
                        </div>
                        <div>
                            <CircularProgress color="secondary"></CircularProgress>
                             <h1>
            Archivos cargados {contadorSelector} de {logintudSelector}
        </h1> 
                        </div>
                    </div>

                }
        */}


                {this.state.errorUpload ?
                    <ComponentError
                        onModalCancelError={this.onModalCancelError}
                        errorUpload={this.state.errorUpload}
                        errorTitle={'Error cargando archivos'}
                        body={this.state.bodyError}
                    /> : ''
                }
                {this.state.proDoc ?
                    <div className="paper-conversaciones-ocr">
                        {/* 
                        <div className="align-button-ocr">
                            <div className="button-ocr-left">
                                <Button style={{ "marginTop": "10px" }} onClick={() => this.onCloseDocPro()} color='primary' variant="contained">Volver</Button>
                            </div>
                            handlerClose
                        </div> 
                        */}

                        <ComponentMisDocumentos
                            handlerClose={this.onCloseDocPro}
                            crossDoc={crossDoc}
                            selectorShowCrossDocument={selectorShowCrossDocument}
                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                            crossDocumentSelector={crossDocumentSelector}
                            download={downloadDoc}
                            getFailDocument={getDocumentosFallidos}
                            failDocumentSelector={failDocumentSelector}
                            cargarDocumento={this.state.cargarDocumento}
                            getDocumentos={getDocumentosProcesados}
                            data={this.state.filtroTable}
                            loading={loadingCircular}
                            loadingFall={loadingFall}
                            lote={this.state.lote}
                            documentosProcesadosSelector={documentosProcesadosSelector}
                            mostrarDocumentos={mostrarDocumentosProcesados}
                            showDocumentSelector={showDocumentSelector}
                            loadingProc={loadingProc}
                        />
                        <Toast ref={(el) => (this.toast = el)} />
                    </div>


                    :
                    <>
                        {this.state.table ?

                            <div className="paper-conversaciones-ocr">
                                <div className="align-button-ocr">

                                    <div className="button-ocr-left">
                                        <Button onClick={() => this.onCloseTableHandler()} color='primary' variant="contained">Volver</Button>
                                    </div>
                                    <div className="button-ocr-right">
                                        <Button onClick={() => this.onOpenDocPro()} color='primary' variant="contained">Documentos procesados</Button>
                                    </div>
                                </div>
                                <div className="search-container">

                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                                    </span>

                                </div>


                                <ComponentTable
                                    documentDataSelector={this.state.table}
                                    loadingTable={documentDataSelector}
                                    data={documentDataSelector}
                                    procesarDocs={this.onProcesarDoc}
                                    title={'Seleccione los documentos para procesar'} />
                                <Toast ref={(el) => (this.toast = el)} />
                            </div>
                            :
                            <div className="paper-conversaciones-ocr">
                                {/* <div className="button-ocr-right">
                                    <Button style={{ "marginTop": "20px" }} onClick={() => this.onOpenTableHandler()} color='primary' variant="contained">Documentos Cargados</Button>
                                </div> */}
                                <div className="search-container">

                                    <span className="p-input-icon-left">
                                        <i className="pi pi-search" />
                                        <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                                    </span>

                                </div>
                                <ComponentTable
                                    onOpenTableHandler={this.onOpenTable}
                                    loadingTable={lotesSelector}
                                    data={this.state.filtroTable}
                                    lote={true}
                                    procesarDocs={this.onProcesarDoc}
                                    title={'Documentos Pendientes'}
                                />

                                <Toast ref={(el) => (this.toast = el)} />

                            </div>
                        }
                    </>
                }

            </Paper>


        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsOCR: getMenuItemsOCR(state),
        selectorUploadBucket: getUploadBucketSelector(state),
        loading: selectLoading(state),
        loadingCircular: selectLoadingCircular(state),
        loadingProc: selectLoadingProc(state),
        showToastSelector: selectorShowToast(state),
        toastDetailSelector: selectorToastDetail(state),
        ocrCreateSelector: selectorOcrCreate(state),
        ocrActivoSelector: selectorOcrActivo(state),
        selectorDataCompany: getDataCompanySelector(state),
        contadorSelector: selectorContador(state),
        logintudSelector: selectorLongitud(state),
        showTableSelector: selectorShowTable(state),
        documentDataSelector: selectordocumentData(state),
        documentProSelector: selectorProDocument(state),
        sizeSelector: selectorSize(state),
        nombreSelector: selectorName(state),
        documentosProcesadosSelector: selectorDocumetosProcesados(state),
        showDocumentSelector: selectorShowDocument(state),
        failDocumentSelector: selectorFailDocument(state),
        crossDocumentSelector: selectorCrossDocument(state),
        loadingFall: selectLoadingCircularFall(state),
        lotesSelector: selectorGetLotes(state),
        selectorShowCrossDocument: selectorShowCrossDocument(state),
        selectorDatosDocPrincipal: selectorDatosDocPrincipal(state),
        alertSelector: selectorAlert(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenuImageOCR: (value) => dispatch({ type: LOAD_MENU, value }),
        uploadToBucket: (value) => dispatch({ type: UPLOAD_BUCKET, value }),
        ocrCreate: (value) => dispatch({ type: OCR_CREATE, value }),
        isOcrEnabled: (value) => dispatch({ type: OCR_ACTIVO, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        getOCRDocumentos: (value) => dispatch({ type: GET_OCR_DOC, value }),
        procesarDocs: (value) => dispatch({ type: PRO_OCR_DOC, value }),
        getMaxSize: (value) => dispatch({ type: GET_OCR_SIZE, value }),
        getDocumentosProcesados: (value) => dispatch({ type: GET_DOC_PROC, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),
        getDocumentosFallidos: (value) => dispatch({ type: GET_DOC_FALL, value }),
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        crossDoc: (value) => dispatch({ type: CROSS_DOC, value }),
        getLotes: (value) => dispatch({ type: GET_LOTES, value }),
        confirmarDocumentoCargado: () => dispatch ({type: DOCUMENTOS_CARGADOS_SUCCESS, payload: false })


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentLoteOCR);