import { Input, MenuItem, Select, Switch, TextField, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import { Form } from "react-bootstrap";

export default class CustomField extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            fieldValue: props.value,
            fieldName: props.name,
        };
        
    }
    

    componentDidUpdate(nextProps) {
        if (nextProps.fieldValue) {
            this.setState({
                fieldValue: nextProps.fieldValue,
                fieldName: nextProps.fieldName,

            });
        }
    }
    handleChange = (e) => {
        /* console.log('e', e.target.value, e.target.name); */
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    onChange = (e) => {

        //debugger;
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: this.state.fieldName,

        });

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: this.state.fieldName
        });

    };

    onBlur = (e) => {
        const { fieldValue, fieldName } = this.state;
        //
        this.props.updateAction({
            fieldValue,
            fieldName,

        });


    };

    onKeyDown = (e) => {
        const { fieldValue, fieldName } = this.state;
        if (e.key === "Enter" && e.shiftKey === false) {

            //actualizar el estado de redux en el campo
            this.props.updateAction({
                fieldValue: "",
                fieldName,
            });

            //limpiamos el valor del campo pero local
            this.setState({
                fieldValue: "",
                fieldName
            });
            //debugger
            this.props.onEnterFunction(fieldValue);
        }
    };

    onToggleChange = (e) => {
        let targetValue = e.target.value;
        const targetName = e.target.name;

        targetValue =
            typeof targetValue === "boolean" ? targetValue : targetValue === "true";

        this.setState({
            fieldValue: !targetValue,
            fieldName: targetName,
        });

        this.props.updateAction({
            fieldValue: !targetValue,
            fieldName: targetName,
        });
    };

    onFilterValue = (e) => {
        const { items } = this.props;
        const targetValue = e.target.value;
        const targetName = e.target.name;

        this.setState({
            fieldValue: targetValue,
            fieldName: targetName,
        });

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: targetName,
            items: items,
        });
    };

    selectInput = (
        type,
        typeForm,
        options,
        value,
        label,
        placeholder,
        name,
        key,
        disable,
        onEnterFunction,
        optionlabel,
        labelClass,

    ) => {
        let formControl = false;
        const customKey = `input-${label}-${key}`;
        if (type === "toggle" && typeof value !== "boolean") {
            value = value === "true";
        }

        switch (typeForm) {

            case "filter":
                formControl = (
                   
                    <TextField
                        className="custom-input"
                        key={customKey}
                        name={name}
                        label={placeholder}
                        value={value}
                        type={type}
                        onChange={this.onFilterValue}
                        disabled={disable}
                        
                        
                    />
                    
                );
                break;
            case "area":
                formControl = (
                    
                    <Input
                        key={customKey}
                        className="form-control form-control-lg"
                        name={name}
                        rows="6"
                        as="textarea"
                        label={label}
                        type={type}
                        value={value}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                        placeholder={placeholder}
                    />
                );
              
                break;
            case "selections":
                formControl = (
                    <div className="check-control">
                        {options.map((type, i) => (
                            <Form.Check
                                type={type.type}
                                id={`${type.type}-${i}`}
                                label={type.label}
                            />
                        ))}
                    </div>
                );
                break;
            case "toggle":
                formControl = (
                    <Switch
                        size="medium"
                        checked={value}
                        name={name}
                        value={value}
                        onChange={this.onToggleChange}
                        disabled={disable}
                    />
                );
                return formControl;

            case "select":
                formControl = (
                    <Select
                        name={name}
                        key={customKey}
                        as="select"
                        className="custom-input mr-sm-2"
                        id="inlineFormCustomSelect"
                        value={value}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                    >
                        {options.map((type, i) => {
                            return (
                                <MenuItem
                                    key={`option-${i}`}
                                    value={type.value}
                                    id={`option-${i}`}
                                >
                                    {type.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                );
                break;
            case "multiple":
                break;

            case "login-input":
                formControl = (
                    <input
                        className="form-control form-control-lg"
                        key={customKey}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                        
                    />
                );
                break;
            case "text-area":
                formControl = (
                    <textarea
                        className="form-control form-control-lg"
                        key={customKey}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                        onKeyDown={this.onKeyDown}
                    />
                );
                break;
            case "normal-text-area":
                formControl = (
                    <textarea
                        className="form-control form-control-lg"
                        key={customKey}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                      
                    />
                );
                break;
                
            case "calendar":
                formControl = (
                    <div className="p-field p-col-12 p-md-4">
                        <Calendar
                            value={value}
                            showTime
                            showSeconds
                            key={customKey}
                            name={name}
                            onChange={this.onChange}
                            disabled={disable} />
                    </div>
                );

                break;
            case "primeFace-Multiselect":

                formControl = (
                    <MultiSelect
                        className="form-control form-control-sm"
                        key={customKey}
                        options={options}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.onChange}
                        disabled={disable}
                        optionLabel={optionlabel}
                    />
                );
                break;
            case "primeFace-dropdown":
                formControl = (
                    <Dropdown
                        className="form-control form-control-sm p-p-0 "
                        key={customKey}
                        options={options}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.onChange}
                        disabled={disable}
                        optionLabel={optionlabel}

                    />
                );
                break

            default:
                formControl = (
                    <input
                        className="form-control form-control-lg"
                        key={customKey}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        type={type}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                        label={label}
                    />
                );
                break;
        }

        return formControl;
    };

    render() {
        const {
            placeholder,
            label,
            type,
            typeForm,
            options,
            key,
            name,
            disable,
            onEnterFunction,
            optionlabel,
            labelClass,

        } = this.props;

        const customKey = label ? label.replace(/\s/g, "").toLowerCase() : "";
        const { fieldValue } = this.state;
        const customlabelClass = labelClass ? labelClass : "label-name";
        const customlabelGroup = labelClass ? labelClass : "form-custom-group-edit";

        return (
            <div key={customKey} className={customlabelGroup}>
                <label className={customlabelClass}>
                    <span className="content-name">{label}</span>
                </label>
                {this.selectInput(
                    type,
                    typeForm,
                    options,
                    fieldValue,
                    label,
                    placeholder,
                    name,
                    key,
                    disable,
                    onEnterFunction,
                    optionlabel,
                    labelClass,

                )}
            </div>
        );
    }
}
