import React, { Component } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './customcard.css';
import { Image, Button } from 'react-bootstrap';

export default class CustomCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePath: '/',
        }

    }

    handleClick = (itemId) => {
        const { onItemClick } = this.props;
        console.log("handleClick CARDDD", itemId)
        onItemClick(itemId);
    }

    render() {
        const { items, onItemClick, showBot } = this.props;
        return (
            <Accordion className='cards-wrapper' defaultActiveKey="0">
                {
                    items.map((item) => {
                        const { title, description, path, icon, buttonLabel, key, type, role, picture, accordion, show, link } = item;

                        return (
                            <>
                                    <Card key={key}>
                                        <Card.Header>
                                            {accordion ?
                                                <Accordion.Toggle as={Button} variant="link" eventKey={key}>
                                                    {picture ?
                                                        <Image className='card-image' src={picture} roundedCircle style={{
                                                            "width": "200px",
                                                            "height": "125px"
                                                        }} /> :
                                                        icon ? <FontAwesomeIcon className='card-icon' icon={icon} /> : false
                                                    }
                                                    <Card.Title style={{ "padding": "20px", "font-size": "19px" }}>{title}</Card.Title>
                                                </Accordion.Toggle>
                                                : <div className="header-wrapper">
                                                    {picture ?
                                                        <Image className='card-image' src={picture} roundedCircle style={{
                                                            "width": "200px",
                                                            "height": "125px"
                                                        }} />
                                                        :
                                                        icon ? <FontAwesomeIcon className='card-icon' icon={icon} /> : false
                                                    }
                                                    <Card.Title style={{ "padding": "20px", "font-size": "19px" }}>{title}</Card.Title>
                                                </div>
                                            }
                                        </Card.Header>
                                        {accordion ?
                                            <Accordion.Collapse eventKey={key}>
                                                <Card.Body>
                                                    <Card.Text style={{ "font-size": "12px" }}>
                                                        {description}
                                                    </Card.Text>
                                                    {type && type === 'button' ?
                                                        <Button className='btn btn-primary ' onClick={() => this.handleClick(item.id)}>
                                                            {buttonLabel}
                                                        </Button>
                                                        :
                                                        <Link to={path} className='sidebar-nav-link btn btn-primary '>
                                                            {buttonLabel}
                                                        </Link>
                                                    }
                                                </Card.Body>
                                            </Accordion.Collapse> :


                                            <Card.Body>
                                                <Card.Text style={{ "font-size": "12px" }}>
                                                    {description}
                                                </Card.Text>
                                                {type && type === 'button' ?
                                                    <Link to={show ? link : `${path}/:${item.id}`} onClick={() => this.handleClick(item.id)} className='sidebar-nav-link btn btn-primary '>
                                                        {show ? 'Abrir' : buttonLabel}
                                                    </Link>
                                                    :


                                                    <Link to={show ? link : `${path}/:${item.id}`}  onClick={() => this.handleClick(item.id)} className='sidebar-nav-link btn btn-primary '>
                                                        {show ? 'Abrir' : buttonLabel}
                                                    </Link>
                                                }
                                            </Card.Body>
                                        }

                                    </Card>
                            </>

                        )
                    })
                }
            </Accordion>
        );
    }
}
