import React, { Component } from "react";
import { Button, Card } from "react-bootstrap";

class LogoutModal extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="modal-wrapper">
                <div className="modal-background" onClick={() => this.props.onModalCancel()}> </div>
                <div className="modal-content">

                    <Button className="close-button" onClick={() => this.props.onModalCancel()}>
                        x
                    </Button>
                    <div className="modal-info">
                        <p className="modal-text">{this.props.message}</p>
                        <p className="modal-text2">{this.props.message2}</p>

                    </div>
                    <div className="button-group">
                        <Button variant="primary" onClick={() => this.props.onModalClick()}>
                            Si
                        </Button>
                        <Button variant="danger" onClick={() => this.props.onModalCancel()}>
                            No
                        </Button>
                    </div>

                </div>


            </div>);
    }
}

export default LogoutModal;