import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';

export function* traerFaqs() {

    try {
        const company = getFromSession("company_id"); //`company/${company}/conversations`
        let faqs = [];

        yield firebaseDatabase.collection(`company/${company}/faqs`).get().then(docs => {
            docs.forEach(doc => {
                faqs.push(doc);

            });
        });
        return faqs;
    } catch (error) {
        console.error('controller.faqs.traerFaqs', error);
        throw error;

    }
}

export function* editFaq(idFaq, data) {
    try {
        let respuesta;
        let pregunta;
        let resp = false;
        const company = getFromSession("company_id");

        yield firebaseDatabase.collection(`company/${company}/faqs`).where('idDoc', '==', idFaq).get().then(docs => {
            docs.forEach(doc => {
                respuesta = doc.data().respuesta;
                pregunta = doc.data().pregunta;
            });
        })

        yield firebaseDatabase.collection(`company/${company}/faqs`).doc(idFaq).update({
            respuesta: data.respuesta ? data.respuesta : respuesta,
            pregunta: data.pregunta ? data.pregunta : pregunta
        }).then(() => {
            console.log('Document succesfully updated')
            resp = true;

        }).catch((error) => {
            console.error("Error", error);
            resp = false;
        })

        return resp;
    } catch (error) {
        console.error('controller.faqs.editarFaq', error);
        throw error;
    }
}

export function* deleteFaq(idFaq) {
    try {
        let resp = false;

        const company = getFromSession("company_id");

        yield firebaseDatabase.collection(`company/${company}/faqs`).doc(idFaq).delete().then(() => {
            console.log("Document successfully deleted!");
            resp = true;
        }).catch((error) => {
            console.error("Error removing document: ", error);
            resp = false;
        });
        return resp;

    } catch (error) {
        console.error('controller.faqs.deleteFaq', error);
        throw error;
    }

}

export function* addFaq(dataForm) {
    try {
        const company = getFromSession("company_id");
        let resp = false;

        const docRef = firebaseDatabase.collection(`company/${company}/faqs`).doc();
        let body = {
            pregunta: dataForm.pregunta,
            respuesta: dataForm.respuesta,
            idDoc: docRef.id
        }
        yield docRef.set(body).then(() => {
            console.log("Document added successfully");
            resp = true;

        }).catch((error) => {
            console.error("Document not added ", error);
            resp = false;
        });

        return resp;

    } catch (error) {
        console.error('controller.addFaq', error);
        throw error;

    }

}