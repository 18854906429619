
import {
  COMPANY_DATA_SUCCESS,
  GET_USERS_SUCCESS,
  LOAD_MENU_SUCCES,
  USER_DATA,
  USER_HEADERS,
  UPDATE_FIELD_ATTRIBUTE,
  DATA_DOC_CARGADOS_SUCESS,
  DATA_DOC_CARGADOS,
  LINE_CHART_SUCESS,
  LINE_CHART,
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItemsOCR: false,
  dataCompany: false,
  selectedForm: false,
  loading: false,
  dataDocumentosCargados: false,
  lineChart: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  newState.showTable = false;
  switch (type) {
    case GET_USERS_SUCCESS:
      const { data } = payload;
      newState.users = data;

      return {
        ...newState
      };


    case COMPANY_DATA_SUCCESS:
      //console.log("reducer payload data company", payload)
      newState.dataCompany = payload;

      return {
        ...newState
      };

    case LOAD_MENU_SUCCES:
      //console.log("reducer payload chat bot", payload)
      newState.menuItemsOCR = payload;

      return {
        ...newState
      };

    case UPDATE_FIELD_ATTRIBUTE:
      let { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;
      newState.selectedForm = newFormObject;
      newState.message = false;

      return {
        ...newState
      };
    case DATA_DOC_CARGADOS:
      newState.loading = true;
      return {
        ...newState
      };

    case DATA_DOC_CARGADOS_SUCESS:
      newState.dataDocumentosCargados = payload;
      newState.loading = false;
      return {
        ...newState
      };



    case LINE_CHART:
      newState.loading = true;
      return {
        ...newState
      };

    case LINE_CHART_SUCESS:
      newState.lineChart = payload;
      newState.loading = false;

      console.log("lineChart", newState.lineChart)
      return {
        ...newState
      };






    default:
      return {
        ...newState
      };
  }
};

export default reducer;
