import {
    AGREGAR_FAQ_SUCCESS,
    BORRAR_FAQ_SUCCESS,
    EDITAR_FAQ_SUCCESS,
    EDITAR_TEMA_SUCCESS,
    LIMPIAR_FORM,
    GET_FAQS_SUCCESS,
    GET_TEMAS_SUCCESS,
    GET_TEMAS,
    LIMPIAR_FORM_NIVEL2,
    UPDATE_FIELD_ATTRIBUTE,
    AGREGAR_TEMA_SUCCESS,
    BORRAR_TEMA_SUCCESS,
    LISTAR_USUARIOS_SUCCESS,
    GET_HABILIDADES_SUCCESS,
    CREAR_USUARIO_SUCCESS,
    EDITAR_USUARIO_SUCCESS,
    SELECT_ROW_SUCCESS,
    ABRIR_MODAL_USUARIOS,
    CERRAR_MODAL_USUARIOS,
    ABRIR_MODAL_BORRAR,
    ABRIR_MODAL_BORRAR_SUCCESS,
    ELIMINAR_USUARIO_SUCCESS,
    VARIABLES_CONFIGURACION_SUCCESS,
    SELECCIONAR_CONF_SUCCESS,
    CERRAR_MODAL_CONF,
    EDITAR_CONF_SUCCESS,
    CONSULTAR_HORARIO_SUCCESS,
    UPDATE_HORARIO,
    HORARIO_HABIL_DIAS_SUCCESS,
    ABRIR_MODAL_HABILIDADES,
    SELECT_ROW_HABILIDAD_SUCCESS,
    CERRAR_MODAL_EDITAR_HABILIDAD,
    EDITAR_HABILIDAD_SUCCESS,
    CREAR_HABILIDAD_SUCCESS,
    MODAL_ELIMINAR_HABILIDAD,
    CERRAR_MODAL_ELIMINAR_HABILIDAD,
    ELIMINAR_HABILIDAD_SUCCESS,
    CLIENT_FORM_WEB_SUCCESS,
    GUARDAR_CLIENT_FORM_WEB_SUCCESS,
    ENCUESTA_FORM_WEB_SUCCESS,
    GUARDAR_ENCUESTA_SUCCESS,
    GUARDAR_DOCUMENT_FORM_WEB_SUCCESS,
    DOCUMENT_FORM_SUCCESS,
    CRUCE_FORM_SUCCESS,
    GUARDAR_CRUCE_FORM_SUCCESS,
    MANAGE_SPINER

} from './constants';
export const initialState = {
    selectedForm: {},
    faqsTable: false,

    usersTable: false,
    faqsDisplay: false,
    modalEditarFaq: false,
    modalBorrarFaq: false,
    getFaqs: false,
    ocultarModal: false,
    temasDisplay: false,
    temasTable: false,
    getTemas: false,
    opcionesNivel1: false,
    spinnerTemas: false,

    showToast: false,
    toastDetail: false,
    usuariosDisplay: false,
    habilidades: false,
    getUsuarios: false,
    abrirModalEditar: false,
    abrirModalBorrar: false,
    tituloModal: false,
    disabledEmailField: false,
    uidUser: false,
    existeCorreo: false,
    message: false,
    confVariables: false,
    abrirModalEditarConf: false,
    getConf: false,
    updateHorario: false,
    diasEspecificos: false,
    fechasEspecificas: false,
    actualizarHorario: false,
    modalHabilidades: false,
    abrirModalEditarHabilidad: false,
    abrirModalEliminarHabilidad: false,
    idDocHabilidad: false,
    nombreHabilidad: false,
    max_convs_conf: false,
    dataConfig: false,
    valuesInitialFormWeb: false,
    valuesInitialDocument: false,
    updateClientForm: false,
    horario: false,
    clientFormEnabled: false,
    initialValuesEncuesta: false,
    encuestaActiva: false,
    dataSelect: false,
    manageSpinerState: false,
}


const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type } = action;
    //newState.getFaqs = false;
    newState.ocultarModal = false;
    //newState.getTemas = false;
    //newState.showToast = false;


    switch (type) {
        case UPDATE_FIELD_ATTRIBUTE:
            let { value: { fieldName, fieldValue } } = action;
            let newFormObject = { ...newState.selectedForm };
            newFormObject[fieldName] = fieldValue;
            newState.selectedForm = newFormObject;

            return {
                ...newState
            };

        case GET_FAQS_SUCCESS:
            newState.faqsDisplay = payload.faqs;
            newState.getFaqs = payload.updateTable;
            newState.showToast = false;


            return {
                ...newState
            };
        case AGREGAR_FAQ_SUCCESS:

            newState.getFaqs = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.selectedForm = {};
            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;
            //console.log('payloadd', payload);

            return {
                ...newState
            };
        case EDITAR_FAQ_SUCCESS:
            newState.getFaqs = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.selectedForm = {};
            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;


            return {
                ...newState
            };
        case BORRAR_FAQ_SUCCESS:
            newState.getFaqs = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;

            return {
                ...newState
            };
        case EDITAR_HABILIDAD_SUCCESS:
            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;
            newState.abrirModalEditarHabilidad = payload.cerrarModal;
            return {
                ...newState
            };
        case CREAR_HABILIDAD_SUCCESS:

            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;

            return {
                ...newState
            };
        case MODAL_ELIMINAR_HABILIDAD:

            newState.abrirModalEliminarHabilidad = true;
            newState.idDocHabilidad = action.value.idDoc;
            newState.nombreHabilidad = action.value.label;

            return {
                ...newState
            };
        case ELIMINAR_HABILIDAD_SUCCESS:

            newState.toastDetail = payload.toastDetail;
            newState.showToast = payload.showToast;
            newState.abrirModalEliminarHabilidad = payload.cerrarModal;
            return {
                ...newState
            };
        case LISTAR_USUARIOS_SUCCESS:
            newState.usuariosDisplay = payload.usuarios;
            newState.showToast = false

            return {
                ...newState
            }
        case VARIABLES_CONFIGURACION_SUCCESS:

            newState.confVariables = payload.configuracion;
            newState.max_convs_conf = payload.maxConvs;
            newState.dataConfig = payload.final;

            return {
                ...newState
            }
        case GET_TEMAS:
            newState.spinnerTemas = true;

            return {
                ...newState
            };

        case GET_TEMAS_SUCCESS:
            newState.temasDisplay = payload.temas;
            newState.getTemas = payload.updateTable;
            newState.opcionesNivel1 = payload.opcionesNivel1;
            newState.spinnerTemas = false;
            newState.showToast = false;


            return {
                ...newState
            };

        case SELECT_ROW_SUCCESS:
            newState.abrirModalEditar = payload.abrirModalEditar;
            newState.tituloModal = "Editar Usuario";
            newState.disabledEmailField = true;

            return {
                ...newState
            };

        case SELECT_ROW_HABILIDAD_SUCCESS:
            newState.abrirModalEditarHabilidad = payload.abrirModalEditar
            return {
                ...newState
            };
        case CONSULTAR_HORARIO_SUCCESS:

            newState.horario = payload.horario;
            newState.showToast = false;

            return {
                ...newState
            };


        case HORARIO_HABIL_DIAS_SUCCESS:
            newState.updateHorario = payload.resp;
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;

            return {
                ...newState
            };
        case ABRIR_MODAL_HABILIDADES:
            newState.modalHabilidades = true;

            return {
                ...newState
            }

        case ABRIR_MODAL_USUARIOS:
            newState.abrirModalEditar = true;
            newState.tituloModal = "Añadir Usuario"
            newState.disabledEmailField = false;

            return {
                ...newState
            }

        case CERRAR_MODAL_USUARIOS:
            newState.abrirModalEditar = false;
            newState.abrirModalBorrar = false;
            newState.message = false;
            newState.modalHabilidades = false;

            return {
                ...newState
            }

        case SELECCIONAR_CONF_SUCCESS:
            newState.abrirModalEditarConf = payload.abrirModalEditarConf;

            return {
                ...newState
            }

        case EDITAR_CONF_SUCCESS:
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            newState.getConf = payload.updateTable;

            newState.ocultarModal = payload.ocultarModal;

            return {
                ...newState
            }

        case CERRAR_MODAL_CONF:
            newState.abrirModalEditarConf = false;

            return {
                ...newState
            }

        case EDITAR_TEMA_SUCCESS:
            newState.getTemas = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.selectedForm = {};
            return {
                ...newState
            };
        case AGREGAR_TEMA_SUCCESS:

            newState.getTemas = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.selectedForm = {};
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;

            return {
                ...newState
            };
        case CREAR_USUARIO_SUCCESS:
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            newState.getUsuarios = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;
            newState.message = payload.message;
            newState.abrirModalEditar = !payload.ocultarModal;

            return {
                ...newState
            };
        case EDITAR_USUARIO_SUCCESS:
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            newState.getUsuarios = payload.updateTable;
            newState.abrirModalEditar = payload.ocultarModal;

            return {
                ...newState
            }
        case ELIMINAR_USUARIO_SUCCESS:
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            newState.getUsuarios = payload.updateTable;
            newState.abrirModalBorrar = payload.modalBorrar;
            return {
                ...newState
            }

        case BORRAR_TEMA_SUCCESS:
            newState.getTemas = payload.updateTable;
            newState.ocultarModal = payload.ocultarModal;

            return {
                ...newState
            };

        case LIMPIAR_FORM_NIVEL2:
            newState.selectedForm.nivel2 = [];
            newState.selectedForm.nivel2Text = "";

        case ABRIR_MODAL_BORRAR:
            newState.abrirModalBorrar = true;
            return {
                ...newState
            }
        case ABRIR_MODAL_BORRAR_SUCCESS:
            newState.uidUser = payload;
            return {
                ...newState
            }
        case LIMPIAR_FORM:
            newState.selectedForm.nivel1Text = "";
            newState.selectedForm.nivel2Text = "";
            newState.selectedForm.nivel2 = [];
            newState.selectedForm.nivel1 = [];
            newState.selectedForm.nivel3 = [];
            return {
                ...newState
            };
        case GET_HABILIDADES_SUCCESS:

            newState.habilidades = payload;
            newState.showToast = false;

            return {
                ...newState
            }

        case CERRAR_MODAL_EDITAR_HABILIDAD:

            newState.abrirModalEditarHabilidad = false;


            return {
                ...newState
            }
        case CERRAR_MODAL_ELIMINAR_HABILIDAD:

            newState.abrirModalEliminarHabilidad = false;
            return {
                ...newState
            }


        case UPDATE_HORARIO:

            newState.updateHorario = false;
            return {
                ...newState
            }

        case CLIENT_FORM_WEB_SUCCESS:
            newState.valuesInitialFormWeb = payload.listaPreguntas;
            newState.updateClientForm = false;
            newState.clientFormEnabled = payload.clientFormEnable;
            newState.showToast = false;
            console.log("payload,CLIENT_FORM_WEB_SUCCESS", payload)
            return {
                ...newState
            }

        case DOCUMENT_FORM_SUCCESS:
            newState.valuesInitialFormWeb = payload.listaPreguntas;
            newState.updateClientForm = false;
            newState.clientFormEnabled = payload.clientFormEnable;
            newState.showToast = false;
            // console.log("payload,DOCUMENT_FORM_SUCCESS", payload)
            return {
                ...newState
            }

        case CRUCE_FORM_SUCCESS:

            console.log("payload,CRUCE_FORM_SUCCESS", payload)

            newState.initialValuesEncuesta = payload.listaPreguntas;
            newState.dataSelect = payload.listaDataDocument
            newState.encuestaActiva = payload.encuestaActiva;
            newState.showToast = false

            //newState.showToast = payload.showToast;
            //newState.toastDetail = payload.toastDetail;



            return {
                ...newState
            }



        case GUARDAR_CLIENT_FORM_WEB_SUCCESS:
            newState.updateClientForm = payload.updateForm;
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            return {
                ...newState
            }


        case GUARDAR_DOCUMENT_FORM_WEB_SUCCESS:
            newState.updateClientForm = payload.updateForm;
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;
            return {
                ...newState
            }


        case ENCUESTA_FORM_WEB_SUCCESS:
            newState.initialValuesEncuesta = payload.listaPreguntasEncuesta;
            newState.encuestaActiva = payload.encuestaActiva;
            newState.showToast = false

            return {
                ...newState
            }

        case GUARDAR_CRUCE_FORM_SUCCESS:
            newState.updateClientForm = payload.updateForm;
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;

            return {
                ...newState
            }

        case GUARDAR_ENCUESTA_SUCCESS:
            newState.updateClientForm = payload.updateForm;
            newState.showToast = payload.showToast;
            newState.toastDetail = payload.toastDetail;

            return {
                ...newState
            }
        case MANAGE_SPINER:
            newState.manageSpinerState = payload;

            return {
                ...newState
            }
        default:
            return {
                ...newState
            };
    }

}

export default reducer;

