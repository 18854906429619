import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getUsers, getListaPrueba } from "../selectors";
import {
    ADD_ITEM,
    GET_USERS
} from '../constants';
import { Card, TextField } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PublicIcon from '@material-ui/icons/Public';
import { Button } from 'react-bootstrap';


class ComponentWhatsApp extends Component {
    componentWillMount() {
        const { getUsers } = this.props;
        getUsers();
        // const queryString = window.location.search;
        //  const urlParams = new URLSearchParams(queryString);
        //   const oobCode = urlParams.get('oobCode');
        //bienvenidaPage(oobCode)
        //  console.log("bienvenidaPage(oobCode)", bienvenidaPage(oobCode))
    }


    render() {
        const {
            items,
        } = this.props;

        return (
            <div className="modal-wrapper">
                <div className="modal-background" onClick={() => this.props.onModalCancel()}> </div>
                <div className="modal-content">

                    <h1>Publicar en WhatsApp</h1>
                    <Button className="close-button" onClick={() => this.props.onModalCancel()}>
                        x
                    </Button>
                    <div className="modal-info">


                    </div>
                    <div className="button-group">
                        <Card>
                            <Box  component="span"  m={1}>
                                <TextField label="Nombre del chatBot">
                                </TextField>
                            </Box>

                            <Box  component="span"  m={1}>
                                <TextField label="Número de telefono">
                                </TextField>
                            </Box>
                        </Card>
                    </div>
                    <div className="button-group">

                        <Button variant="danger" onClick={() => this.props.onModalCancel()}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => this.props.onModalCancel()}>
                            Aceptar
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        items: getListaPrueba(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        cargarItems: () => dispatch({ type: ADD_ITEM, value: 1 }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentWhatsApp);