import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
 import '../../index.css'; 

import './dataView.css';

import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import React, { Component } from 'react';
import { Button } from 'primereact/button';




export default class DataViewLazyDemo extends Component {

    constructor(props) {
        super(props);

        if (props.dataInfo.data.length > 6) {
            this.rows = 6;
        } else {
            this.rows = props.dataInfo.data.length;
        }

        this.state = {
            datasource: props.dataInfo.data,
            updAgentes: false,
            layout: 'grid',
            loading: false,
            first: 0,
            totalRecords: props.dataInfo.data.length || 0,
            products: props.dataInfo.data.slice(0, this.rows)
        };

        this.itemTemplate = this.itemTemplate.bind(this);
        this.onPage = this.onPage.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const{changeFlag} =this.props;

        if (this.props.dataInfo.data.length > 6) {
            this.rows = 6;

        } else {
            this.rows = this.props.dataInfo.data.length;
        }

        if (this.props.updAgentes) {

            this.setState({
                datasource: this.props.dataInfo.data,
                totalRecords: this.props.dataInfo.data.length || 0,
                products: this.props.dataInfo.data.slice(0, this.rows),
            });
            changeFlag();
        }
      

      
    }

    

    onPage(event) {

        this.setState({
            loading: true
        });

        setTimeout(() => {

            const startIndex = event.first;
            let endIndex = event.first + this.rows;
            let p;
            let data = this.state.datasource;

            if (endIndex > this.state.totalRecords) {
                endIndex = this.state.totalRecords;

                p = data.slice(startIndex, endIndex)

                const diff = this.rows - p.length
                for (let i = 0; i < diff; i++) {
                    p.push({})
                }
            } else {
                p = data.slice(startIndex, endIndex)
            }

            this.setState({
                first: startIndex,
                products: p,
                loading: false
            });


        }, 1000);
    }

    /* 
        renderListItem(data) {
            return (
                <div className="p-col-12">
                    {data.nombre && (<div className="product-list-item">
                        <div className="product-list-detail">
                            <div className="product-name">{data.carga}</div>
                            <div className="product-description">{data.convAsignadas}</div>
                        </div>
                        <div className="product-list-action">
                            <span className="product-price">${data.convResueltas}</span>
                        </div>
                    </div>)}
                </div>
            );
        }
     */
    renderGridItem(data, action) {
        

        let urlImg;
        if (data.estado) {
            if (data.estado === 'Disponible') {
                urlImg = "/assets/icons/circle-green.png"
            } else if (data.estado === 'Desconectado') {
                urlImg = "/assets/icons/circle-red.png"

            } else {
                urlImg = "/assets/icons/circle-yellow.png"
            }
        }

        return (

            <div className="p-col-12 p-md-4">
                {data.nombre ? (
                    <div className="product-grid-item card">
                        <div className="product-grid-item-top">
                            {data.estado === 'Disponible' || data.estado === 'Ausente' ? (
                                <Button icon="pi pi-power-off"
                                    className="p-button-rounded p-button-danger"
                                    title="Cerrar sesión"
                                    onClick={() => action(data)}
                                />

                            ) : <Button icon="pi pi-power-off"
                                className="p-button-rounded p-button-danger"
                                title="Cerrar sesión"
                                onClick={() => action(data)}
                                disabled />}
                        </div>
                        <div className="product-grid-item-content">
                            <div className="product-name p-text-capitalize">{data.nombre} {/* {data.idDoc} */}</div>
                            <div className="product-description p-text-capitalize">{data.habilidad}</div>
                            <div className="product-description">
                                <img className="icon-table2" src={urlImg} />
                                {data.estado}
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Conversaciones Asignadas: </h4></span>
                                <span> {data.convAsignadas}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Conversaciones Cerradas: </h4></span>
                                <span> {data.convCerradas}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Conversaciones máximas: </h4></span>
                                <span> {data.maxConvs}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Conversaciones resueltas: </h4></span>
                                <span> {data.convResueltas}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Carga: </h4></span>
                                <span> {data.carga}</span>
                            </div>

                            <div className='row rowFixed'>
                                <span><h4>Porcentaje Satisfacción: </h4></span>
                                <span>{data.porcSatisfaccion}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Tiempo promedio de respuesta: </h4></span>
                                <span>{data.tiempoRespuesta}</span>
                            </div>

                            <div className='row rowFixed'>
                                <span><h4>Tiempo de conexión actual: </h4></span>
                                <span>{data.tiempoConexion}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Duración promedio de conversación:</h4></span>
                                <span>{data.tiempoConversacion}</span>
                            </div>
                            <div className='row rowFixed'>
                                <span><h4>Última conversación tomada:</h4></span>
                                <span>{data.ultimaConversacionTomada}</span>
                            </div>
                        </div>
                        <div className="product-grid-item-bottom">

                        </div>
                    </div>
                ) : null
                }
            </div>
        );
    }

    itemTemplate(product, layout) {
        const { action } = this.props;

        if (!product) {
            return;
        }
        if (layout === 'list')
            return this.renderListItem(product);
        else if (layout === 'grid')
            return this.renderGridItem(product, action);
    }

    renderHeader() {

        let onOptionChange = (e) => {
            this.setState({ loading: true }, () => {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                        layout: e.value
                    });
                }, 1000);
            });
        };

        return (
            <div style={{ textAlign: 'left' }}>
                <DataViewLayoutOptions layout={this.state.layout} onChange={onOptionChange} />
            </div>
        );
    }

    render() {
     
        /* 
                const { dataInfo } = this.props;
                let data = dataInfo ? dataInfo.data : null;
                console.log('dataINFOOOO', data); */

          

        return (
            <div className="dataview-demo">
                <div className="card">
                    <DataView emptyMessage='No hay agentes conectados' value={this.state.products} layout={this.state.layout} /* header={header} */
                        itemTemplate={this.itemTemplate} lazy paginator paginatorPosition={'both'} rows={this.rows}
                        totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage} /* loading={this.state.loading} */ />
                </div>
            </div>
        );
    }
}
