export const compareArrays = (a, b) => JSON.stringify(a) === JSON.stringify(b);

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
        seconds,
    )}`;
}

export const emptyJson = (value) => {
    return value === undefined || JSON.stringify(value) === '{}' ? true : false;
}

export const emptyObject = (value) => {
    return typeof value !== 'object' && value !== undefined && value !== null && value !== "null" ? value : '';
}

export function convertMsToTimeObj(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;
    hours = ("0" + hours).slice(-2)
    seconds = ("0" + seconds).slice(-2)
    minutes = ("0" + minutes).slice(-2)
    days = ("0" + days).slice(-2)

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    return {
        days, hours, minutes, seconds
    }       
    ;
}

