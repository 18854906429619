import { terminarSesionAgente } from "./connectedUsers";
import { devolverConversacionALaCola } from "./conversation";
import { firebaseAuthentication, firebaseField } from "./firebase";
import { firebaseDatabase } from "./firebase";
import { isMobile, isDesktop, isOpera, isChrome, isFirefox, isEdge, isTablet } from 'react-device-detect';

export const storeInSession = (item, value) => {
    sessionStorage.setItem(item, value);
}

export const getFromSession = (item) => {
    try {
        return JSON.parse(sessionStorage.getItem(item));
    } catch (error) {
        return sessionStorage.getItem(item)
    }

}

export const saveInSession = (item, value) => {
    sessionStorage.setItem(item, JSON.stringify(value));
}

export const logout = async () => {
    console.log('**saliendo...**')
    try {
        const idUser = getFromSession('connectedUser');
        const company = getFromSession("company_id");
        let resp = false;
        let idDocConv
       
        await firebaseDatabase.collection(`company/${company}/conversations`).where('estado', 'in', [1, 2, 3, 4, 5, 6, 7, 8, 9])
            .where('agente.idDoc', '==', idUser).get().then(results => {
                results.forEach(conv => {
                    idDocConv = conv.ref.id

                    firebaseDatabase.collection(`company/${company}/conversations`).doc(idDocConv).update({
                        estado: 0,
                        agente: firebaseField.delete(),
                        fecha_asignacion: firebaseField.delete(),
                        fecha_aceptacion: firebaseField.delete()
                    }).then(() => {
                        console.log('Conversations update!');
                    })
                });
            })

        await firebaseDatabase.collection(`company/${company}/connectedUsers`).doc(idUser).update({
            estado: 2,
            fecha_fin: new Date(),
            
        }).then(() => {
            resp = true;
            console.log('Fecha fin y cambio de estado realizado!');

        }).catch((error) => {
            console.error('Ha ocurrido un problema cerrando sesión', error);
        })
        
        if (resp) {
            sessionStorage.clear();
            
            sessionStorage.removeItem('inSession')
            firebaseAuthentication.signOut();
            if(isFirefox){
                window.location.href = '/';
            }else{
                window.location.href =window.location.href;
            }
            
            console.log('Sesión finalizada completamente');
           
        }else{
            console.error('No se realizó sessionStorage(clear) y firebaseAuthentication.signOut(); ');
        }

        return resp;
    } catch (error) {
        console.error('session.logout', error);
        throw error;
    }
    //Se debe cerrar las conversaciones que haya:
}

