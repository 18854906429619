import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getUsers, getListaPrueba } from "../selectors";
import {
    ADD_ITEM,
    GET_USERS
} from '../constants';
import { Button, Paper, TextField } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';


class ComponentVistaPrevia extends Component {
    componentWillMount() {
        const { getUsers } = this.props;
        getUsers();
        // const queryString = window.location.search;
        //  const urlParams = new URLSearchParams(queryString);
        //   const oobCode = urlParams.get('oobCode');
        //bienvenidaPage(oobCode)
        //  console.log("bienvenidaPage(oobCode)", bienvenidaPage(oobCode))
    }
    render() {
        const {
            items,
        } = this.props;

        return (
            <div className="App bienvenida-container">
                <div className='crear-intent-previa'>
                    <Paper elevation={10} className="paper-previa" >
                        <div className="contanier-chat">

                            <h4>Vista Previa</h4>
                            <br></br>
                            <p className="text-left">Mensaje1</p>
                            <p className="text-left">Mensaje2</p>
                            <p className="text-right">respuesta1</p>
                            <p className="text-right">respuesta2</p>
                            <br></br>
                            <div className="items-mensaje">
                                <TextField label="Escribe un mensaje..."></TextField>
                                <IconButton>
                                    <SendIcon></SendIcon>
                                </IconButton>
                            </div>

                        </div>
                    </Paper>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        items: getListaPrueba(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        cargarItems: () => dispatch({ type: ADD_ITEM, value: 1 }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentVistaPrevia);