import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { CONVS_NOTAS_OCULTAS, MESSAGE_DATA_CERO_NOTAS, MODAL_ESPERA_DATA, TABLE_NOTAS_TITLE, TITLE_TABLE_CONTACT } from "./constants";
import { getConvsAgentes, getDataTableAgentes, getDataTableNotasOcultas, getManageSpinerState, getSelectedForm, getShowSnackBar, getStateFilterButton } from "./selectors";
import { BarChartDemo } from '../../components/Charts/barrChart';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstNotasComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }

    componentWillMount() {
        const { obtenerTablaNotasOcultas, activarSpinner } = this.props
        obtenerTablaNotasOcultas()
        activarSpinner()
    }

    componentDidUpdate(prevProps) {
        const { obtenerTablaNotasOcultas, estadoBotonFiltrarRangos, showToast, activarSpinner } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;
        console.log(prevProps.selectedForm.fechaRango, "***********", seleccionRango)
        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerTablaNotasOcultas(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            this.setState({
                seleccionRango: seleccionRango
            })
            obtenerTablaNotasOcultas(seleccionRango)
            activarSpinner()

        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }
                this.setState({
                    seleccionRango: rango
                })
                obtenerTablaNotasOcultas(rango)
                activarSpinner()

            } else {
                showToast()
            }

        }

    }
    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }
    render() {
        const {
            dataTableNotasOcultas,
            spinerState,
        } = this.props;
        return (
            <div>
                <div>
                    <h4 className="tituloComponente" >ESTADISTICAS DE NOTAS OCULTAS</h4>
                </div>
                {spinerState.notasOcultas ? this.spinnerGraficos() : ""}
                {dataTableNotasOcultas.data?.length > 0 ?
                    <div className="containerGeneral">
                        <div className="p-mt-3">
                            {dataTableNotasOcultas.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={dataTableNotasOcultas.columnsTable?.length > 0 ? dataTableNotasOcultas.columnsTable : []}
                                    datosTable={dataTableNotasOcultas.data?.length > 0 ? dataTableNotasOcultas.data : []}
                                    titleTable={TABLE_NOTAS_TITLE}
                                    exporExcel={false}
                                    nameTable={"Notas ocultas"}
                                />
                            )}
                        </div>
                    </div>
                    :
                    <div>
                        <h4 className="messageCero">{MESSAGE_DATA_CERO_NOTAS}</h4>
                    </div>
                }
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableNotasOcultas: getDataTableNotasOcultas(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),

    }
}


const mapDispachToProps = dispatch => {
    return {
        obtenerTablaNotasOcultas: (value) => dispatch({ type: CONVS_NOTAS_OCULTAS, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value })

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstNotasComponent);