import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Button, TextField, Typography } from "@material-ui/core";
import ComponentNombreBot from "./componentNombreBot";
import { CREATE_AGENT, GET_BOTS, LIST_CLOUD_PROJECTS, UPLOAD_ICON } from "./constants";
import ComponentCrearChatBot from "./componentCrearChatBot";
import { FileUpload } from 'primereact/fileupload';
import { storage } from "../../controller/firebase";
import { selectListProjects, selectLoading, selectLoading_icon, selectorShowToast, selectorToastDetail } from "./selectors";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Toast } from "primereact/toast";

class ComponentPopUpNombreBot extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout, } = this.props;

        this.state = {
            disabledButton: true,
            nombreBot: '',
            tablaBots: false,
            bot: false
        }
    }

    componentWillMount() {
        const { listProjectSelector } = this.props;
        //console.log("listProjectSelector will mount", listProjectSelector)
    }


    onNombreChange = (event) => {
        this.setState({ nombreBot: event.target.value });
        // console.log("nombreBot", this.state.nombreBot)
        if (event.target.value.length <= 5) {
            this.setState({ disabledButton: true });
        } else {
            this.setState({ disabledButton: false });
        }
    }
    onCrearChatBot = () => {
        //console.log('¡Hola, después de 3 segundos!');
        this.setState({ bot: true });
    }

    onUpload = (event) => {

        const file = event.files[0];
        //console.log("UPLLOAD ICONO", file)
        const { uploadIcon } = this.props;
        uploadIcon(file)
    }
    componentDidUpdate(prevProps) {
        const { showToastSelector, toastDetailSelector, loading } = this.props;

        if (prevProps.showToastSelector !== showToastSelector && !loading) {
            this.toast.show({
                severity: toastDetailSelector.severity,
                summary: toastDetailSelector.summary,
                detail: toastDetailSelector.detail,
            });
        }
    }

    onButonClickCrearBot = () => {
        const { createAgent, getBots, validateProjects, listProjectSelector } = this.props;

        //const resp = validateProjects(this.state.nombreBot)
        //this.onCrearChatBot()
        //console.log("listProjectSelector BOTON", listProjectSelector)

        createAgent(this.state.nombreBot)
        getBots(this.state.nombreBot);
        //this.props.onModalCancel()
        setTimeout(() => {
            this.onCrearChatBot()
        }, 3000)
    }

    handlerCrearChatBot = () => {
        const { getBotsSelector, listProjectSelector, onModalCancel } = this.props
        const mostrarPopUp = !getBotsSelector ||
            !getBotsSelector.nombreChatBot ||
            getBotsSelector.nombreChatBot.trim().length < 1
        // console.log("STATE BOT", this.state.bot)

        // console.log("mostrarPopUp", mostrarPopUp)
        // console.log("getBotsSelector", getBotsSelector)
        // console.log("nombreChatBot", getBotsSelector?.nombreChatBot)
        let componenteHTML = false

        if (mostrarPopUp && !this.state.bot) {
            componenteHTML =
                <>
                    <div className="modal-background-bot" onClick={() => this.props.onModalCancel()}> </div>
                    <div className="modal-content-bot">
                        <h2>Nombre del robot</h2>
                        <Tooltip
                            title={
                                <React.Fragment >
                                    <Typography className="title-tooltip">
                                        Recuerda que el Nombre debe tener
                                        como mínimo 6 caracteres
                                    </Typography>
                                    <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>

                                </React.Fragment>
                            }
                        >
                            <TextField
                                onChange={this.onNombreChange}
                                id="filled-size-normal"
                                defaultValue={this.state.nombreBot}
                                variant="filled" />
                        </Tooltip>

                        <div className="align-button-auto">
                            <FileUpload mode="basic"
                                name="demo[]"
                                url={"/api/upload"}
                                customUpload
                                accept="image/*"
                                maxFileSize={1000000}
                                chooseLabel={'Icono'}
                                auto
                                uploadHandler={this.onUpload} />
                            <Button variant="contained"
                                color="secondary"
                                onClick={() => this.props.onModalCancel()}
                            >Cancelar
                            </Button>
                            <Button color='primary'
                                variant="contained"
                                onClick={() => this.onButonClickCrearBot()}
                                disabled={this.state.disabledButton}
                            >Crear Bot
                            </Button>
                        </div>
                    </div>
                </>
        } else {
            componenteHTML =
                <ComponentCrearChatBot
                    listProjectSelector={listProjectSelector}
                    getBotsSelector={getBotsSelector}
                    nombreBot={this.state.nombreBot}
                    onModalCancel={onModalCancel} />
        }
        return componenteHTML
    }

    render() {
        const { loading } = this.props
        return (
            <div className="modal-wrapper-bot">
                {loading &&
                    <div className="loading-wrapper">
                        <>
                            <CircularProgress color="secondary"></CircularProgress>
                            {/* {this.handlerCrearChatBot()} */}
                        </>
                    </div>
                }
                {!loading ?
                    <>
                        {this.handlerCrearChatBot()}
                        <Toast ref={(el) => (this.toast = el)} />
                    </>
                    : ''
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        listProjectSelector: selectListProjects(state),
        showToastSelector: selectorShowToast(state),
        loading: selectLoading_icon(state),
        toastDetailSelector: selectorToastDetail(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getBots: () => dispatch({ type: GET_BOTS }),
        createAgent: (value) => dispatch({ type: CREATE_AGENT, value }),
        uploadIcon: (value) => dispatch({ type: UPLOAD_ICON, value }),
        validateProjects: (value) => dispatch({ type: LIST_CLOUD_PROJECTS, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentPopUpNombreBot);