import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session'


export function* gethabilidades() {
    try {
        const company = getFromSession("company_id");
        let habilidades = [];

        yield firebaseDatabase.collection(`company/${company}/habilidades`).get().then(docs => {
            docs.forEach(doc => {
                habilidades.push(doc);

            });
        });
        return habilidades;

    } catch (error) {

    }

}

export function* deleteHabilidad(idDoc){
    try{
        let resp;
        const company = getFromSession("company_id");
        yield firebaseDatabase.collection(`company/${company}/habilidades`).doc(idDoc).delete().then(() => {
            resp = true;
            console.log("Document successfully deleted! in deleteHabilidad ");
        }).catch((error) => {
            resp = false;
            console.error("Error removing document: ", error);
        });
return resp;
    }catch(error){
        console.error('controller.habilidades.deleteHabilidad', error);
        throw error;
    }

}

export function* anadirHabilidad(nombre) {
    try {
        let resp;
        const company = getFromSession("company_id");
        const docRef = firebaseDatabase.collection(`company/${company}/habilidades`).doc();
        let body = {
            nombre: nombre,
            id: docRef.id
        }
        yield docRef.set(body).then(() => {
            console.log("Documento creado exitosamente");
            resp = true;
        }).catch(err => {
            resp = false;
            console.error("No se ha podido completar la solicitud", err);
        })

        return resp;
    } catch (err) {
        console.error('controller.habilidades.editHabilidadporId', err);
        throw err;
    }

}

export function* editHabilidadporId(id, nombre) {
    try {
        let resp;
        const company = getFromSession("company_id");

        yield firebaseDatabase.collection(`company/${company}/habilidades`).doc(id).update({
            nombre: nombre,
        }).then(() => {
            console.log("Documento actualizado exitosamente, editHabilidadporId");
            resp = true
        }).catch((error) => {
            console.error("Error", error);
            resp = false;
        })
        return resp;
    } catch (err) {
        console.error('controller.habilidades.editHabilidadporId', err);
        throw err;
    }
}

export function* gethabilidadporId(id) {
    try {
        const company = getFromSession("company_id");
        let habilidad;

        yield firebaseDatabase.collection(`company/${company}/habilidades`).where('id', '==', id).get().then(docs => {
            docs.forEach(doc => {
                habilidad = doc.data();

            });
        });

        console.log('getHabilidadpor Id', habilidad)
        return habilidad;

    } catch (error) {
        console.error('controller.habilidades.gethabilidadporId', error)
        throw error;
    }

}