import React, { Component } from "react";
import "./style.css";
//import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { DOWNLOAD_DOC } from "./constants";

class ComponentGestionDocumentos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idDoc: false, 
            labelTable:{
                columna2:'Nombres',
                columna3:'Campos',
                columna4:'Acciones',
            }
        }

    }
 


    
    idDocumento = (newData) => {
        this.setState({ idDoc: newData });
    }
    descargarDocumento = (newData) => {
        const {
            downloadDoc
        } = this.props;
    }

 
    render() {
        const {
            user,
            data,
            pdfData,
            cruceData,
            crossDoc
        } = this.props;
        return (
            <div className="App borrador-container">

               
                    <Paper elevation={10} className="paper-conversaciones-ocr">
                        {/* <ComponentTableDoc
                            downloadDoc={this.descargarDocumento}
                            labelTable={this.state.labelTable}
                            idDocumento={this.idDocumento}
                            /> */}
                    </Paper>
                
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {
       // downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value })

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentGestionDocumentos);