import {
    GUARDAR_ENVIO_ERROR,
    GUARDAR_ENVIO_SUCCES,
    SEND_ENVIO_ERROR,
    SEND_ENVIO_SUCCES,
    UPDATE_FIELD_ATTRIBUTE,
    LIST_ENVIO_SUCCES,
    LIST_ENVIO_ERROR,
    LOAD_CSV_ERROR,
    LOAD_CSV_SUCCES,
    LIST_USERS_SUCCES,
    LIST_USERS_ERROR,
    LIST_USERS, IR_NUEVO,
    VER_ENVIO_SUCCES,
    VER_ENVIO_ERROR,
    REMOVE_FILE,
    EDITAR_ENVIO,
    EDITAR_ENVIO_ERROR,
    EDITAR_ENVIO_SUCCESS,
    LIMPIAR_FORM,
    BORRAR_ENVIO,
    BORRAR_ENVIO_SUCCESS,
    BORRAR_ENVIO_ERROR,
    TOTAL_ENVIOS,
    TOTAL_ENVIOS_SUCCESS,
    TOTAL_ENVIOS_ERROR,
    TOTAL_MENSAJES_LEIDOS,
    TOTAL_MENSAJES_LEIDOS_SUCCESS,
    TOTAL_MENSAJES_LEIDOS_ERROR,
    CAMPOS_PLANTILLA,
    CAMPOS_PLANTILLA_SUCCESS,
    CAMPOS_PLANTILLA_ERROR,
    ARCHIVOS_ADJUNTOS,
    ARCHIVOS_ADJUNTOS_SUCCESS,
    ARCHIVOS_ADJUNTOS_ERROR,
    DISABLE_CHOOSE_BUTTON,
    CONTAR_USUARIOS,
    CONTAR_USUARIOS_SUCCESS,
    PAGINAR_DESTINATARIOS,
    PAGINAR_DESTINATARIOS_SUCCES,
    PAGINAR_DESTINATARIOS_SUCCESS,
    PROM_TIEMPO_LECTURA,
    PROM_TIEMPO_LECTURA_SUCCESS,
    FILTRAR_DESTINATARIOS,
    FILTRAR_DESTINATARIOS_SUCCESS,
    FILTRAR_DESTINATARIOS_ERROR,
    TOTAL_DESTINATARIOS,
    TOTAL_DESTINATARIOS_SUCCESS,
    TOTAL_DESTINATARIOS_ERROR,
    TOTAL_LEIDOS,
    TOTAL_LEIDOS_SUCESS,
    ENVIOS_INDIVIDUALES,
    ENVIOS_INDIVIDUALES_SUCCESS,
    PAGINAR_ENVIOS_INDIVIDUALES,
    PAGINAR_ENVIOS_INDIVIDUALES_SUCCESS,
    FILTRAR_ENVIOS_API,
    FILTRAR_ENVIOS_API_SUCCESS,
    DESCARGAR_CSV_ENVIOS,
    DESCARGAR_CSV_ENVIOS_SUCCESS,
    FILTRAR_LISTA_LEADS_SUCCESS,
    CONTAR_LEADS_POR_ENVIO,
    SELECT_CONVERSATION_DETAIL_SUCCESS
} from './constants';

export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function guardarEnvioSucces(payload) {
    return {
        type: GUARDAR_ENVIO_SUCCES,
        payload
    };
}

export function guardarEnvioError(payload) {
    return {
        type: GUARDAR_ENVIO_ERROR,
        payload
    };
}
export function sendEnvioSucces(payload) {
    return {
        type: SEND_ENVIO_SUCCES,
        payload
    };
}

export function sendEnvioError(payload) {
    return {
        type: SEND_ENVIO_ERROR,
        payload
    };
}

export function listEnvioSucces(payload) {
    return {
        type: LIST_ENVIO_SUCCES,
        payload
    };
}

export function listEnvioError(payload) {
    return {
        type: LIST_ENVIO_ERROR,
        payload
    };
}
export function loadCsvSucces(payload) {
    return {
        type: LOAD_CSV_SUCCES,
        payload
    };
}
export function loadCsvError(payload) {
    return {
        type: LOAD_CSV_ERROR,
        payload
    };
}

export function listUsers(payload) {
    return {
        type: LIST_USERS,
        payload
    };
}
export function listUsersSucces(payload) {
    return {
        type: LIST_USERS_SUCCES,
        payload
    };
}
export function listUsersError(payload) {
    return {
        type: LIST_USERS_ERROR,
        payload
    };
}
export function irNuevo(payload) {
    return {
        type: IR_NUEVO,
        payload
    };
}
export function verEnvioSucces(payload) {
    return {
        type: VER_ENVIO_SUCCES,
        payload
    };
}
export function verEnvioError(payload) {
    return {
        type: VER_ENVIO_ERROR,
        payload
    };
}

export function removeFile(payload) {
    return {
        type: REMOVE_FILE,
        payload
    }
}

export function editarEnvio(payload) {
    return {
        type: EDITAR_ENVIO,
        payload
    }
}


export function editarEnvioSuccess(payload) {
    return {
        type: EDITAR_ENVIO_SUCCESS,
        payload
    }
}


export function editarEnvioError(payload) {
    return {
        type: EDITAR_ENVIO_ERROR,
        payload
    }
}

export function borrarEnvio(payload) {
    return {
        type: BORRAR_ENVIO,
        payload
    }
}
export function borrarEnvioSuccess(payload) {
    return {
        type: BORRAR_ENVIO_SUCCESS,
        payload
    }
}
export function borrarEnvioError(payload) {
    return {
        type: BORRAR_ENVIO_ERROR,
        payload
    }
}


export function limpiarCamposForm(payload) {
    return {
        type: LIMPIAR_FORM,
        payload
    }
}

export function obtenerTotalEnviados(payload) {
    return {
        type: TOTAL_ENVIOS,
        payload
    }
}

export function obtenerTotalEnviadosSuccess(payload) {
    return {
        type: TOTAL_ENVIOS_SUCCESS,
        payload
    }
}

export function obtenerTotalEnviadosError(payload) {
    return {
        type: TOTAL_ENVIOS_ERROR,
        payload
    }
}



export function obtenerMensajesLeidos(payload) {
    return {
        type: TOTAL_MENSAJES_LEIDOS,
        payload
    }

}
export function obtenerMensajesLeidosSuccess(payload) {
    return {
        type: TOTAL_MENSAJES_LEIDOS_SUCCESS,
        payload
    }
}
export function obtenerMensajesLeidosError(payload) {
    return {
        type: TOTAL_MENSAJES_LEIDOS_ERROR,
        payload
    }

}

export function traerCamposComboPlantilla(payload) {
    return {
        type: CAMPOS_PLANTILLA,
        payload
    }
}

export function traerCamposComboPlantillaSuccess(payload) {
    return {
        type: CAMPOS_PLANTILLA_SUCCESS,
        payload
    }
}
export function traerCamposComboPlantillaError(payload) {
    return {
        type: CAMPOS_PLANTILLA_ERROR,
        payload
    }
}

export function adjuntarArchivosEnviosMasivos(payload) {
    return {
        type: ARCHIVOS_ADJUNTOS,
        payload
    }
}
export function adjuntarArchivosEnviosMasivosSuccess(payload) {
    return {
        type: ARCHIVOS_ADJUNTOS_SUCCESS,
        payload
    }
}
export function adjuntarArchivosEnviosMasivosError(payload) {
    return {
        type: ARCHIVOS_ADJUNTOS_ERROR,
        payload
    }
}
export function desactivarBotonElegirArchivo(payload) {
    return {
        type: DISABLE_CHOOSE_BUTTON,
        payload
    }
}

export function contarUsuariosListados(payload) {
    return {
        type: CONTAR_USUARIOS,
        payload
    }
}

export function contarUsuariosListadosSuccess(payload) {
    return {
        type: CONTAR_USUARIOS_SUCCESS,
        payload
    }
}

export function paginarDestinatarios(payload) {
    return {
        type: PAGINAR_DESTINATARIOS,
        payload
    }
}
export function paginarDestinatariosSuccess(payload) {
    return {
        type: PAGINAR_DESTINATARIOS_SUCCESS,
        payload
    }
}

export function calcularTiempoPromedioLectura(payload) {
    return {
        type: PROM_TIEMPO_LECTURA,
        payload
    }

}
export function calcularTiempoPromedioLecturaSuccess(payload) {
    return {
        type: PROM_TIEMPO_LECTURA_SUCCESS,
        payload
    }
}
export function filtrarDestinatarios(payload) {
    return {
        type: FILTRAR_DESTINATARIOS,
        payload
    }

}

export function filtrarDestinatariosSuccess(payload) {
    return {
        type: FILTRAR_DESTINATARIOS_SUCCESS,
        payload
    }

}
export function filtrarDestinatariosError(payload) {
    return {
        type: FILTRAR_DESTINATARIOS_ERROR,
        payload
    }
}
export function traerDestinatarios(payload) {
    return {
        type: TOTAL_DESTINATARIOS,
        payload
    }
}
export function traerDestinatariosSuccess(payload) {
    return {
        type: TOTAL_DESTINATARIOS_SUCCESS,
        payload
    }
}
export function traerDestinatariosError(payload) {
    return {
        type: TOTAL_DESTINATARIOS_ERROR,
        payload
    }
}
export function realizarConteoLeidos(payload) {
    return {
        type: TOTAL_LEIDOS,
        payload
    }
}
export function realizarConteoLeidosSuccess(payload) {
    return {
        type: TOTAL_LEIDOS_SUCESS,
        payload
    }
}

export function obtenerEnviosIndividuales(payload) {
    return {
        type: ENVIOS_INDIVIDUALES,
        payload
    }
}

export function obtenerEnviosIndividualesSuccess(payload) {
    return {
        type: ENVIOS_INDIVIDUALES_SUCCESS,
        payload
    }
}


export function paginarEnviosApi(payload) {
    return {
        type: PAGINAR_ENVIOS_INDIVIDUALES,
        payload
    }
}

export function paginarEnviosApiSuccess(payload) {
    return {
        type: PAGINAR_ENVIOS_INDIVIDUALES_SUCCESS,
        payload
    }
}

export function filtrarEnviosApi(payload) {
    return {
        type: FILTRAR_ENVIOS_API,
        payload
    }
}

export function filtrarEnviosApiSuccess(payload) {
    return {
        type: FILTRAR_ENVIOS_API_SUCCESS,
        payload
    }
}

export function descargarCsvEnvioMasivo(payload) {
    return {
        type: DESCARGAR_CSV_ENVIOS,
        payload
    }

}

export function descargarCsvEnvioMasivoSuccess(payload) {
    return {
        type: DESCARGAR_CSV_ENVIOS_SUCCESS,
        payload
    }

}

export function filtrarListaLeadsSuccess(payload) {
    return {
        type: FILTRAR_LISTA_LEADS_SUCCESS,
        payload
    }

}

export function contarLeadsPorEnvio(payload) {
    return {
        type: CONTAR_LEADS_POR_ENVIO,
        payload
    }

}

export function selectConversationDetailSuccess(payload) {

    return {
        type: SELECT_CONVERSATION_DETAIL_SUCCESS,
        payload
    }

}