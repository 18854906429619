import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../index.css';

import './charts.css'
import { Card } from 'primereact/card';

import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export default class PieChartDemo extends Component {

    constructor(props) {
        super(props);

    }



    render() {
        const { data,
            tipoPie,
            chartData,
            titlePie,
        } = this.props;
        let numeros;
        let labels;

        if (data) {
            if (data.numeros === 'Sin Datos' || data.numeros?.length === 0) {
                /*   numeros = [0, 0, 0]
                  labels = ['A', 'B', 'C'] */
            } else {
                numeros = data.numeros;
                labels = data.labels;
            }
        }
        
        this.chartData = {
            labels: labels,
            datasets: [
                {
                    data: numeros,
                    backgroundColor: [
                        "#42A5F5",
                        "#66BB6A",
                        "#FFA726",
                        '#eb215a',
                        '#c41ce6',
                        '#0ac9bd',
                        '#15eb19',
                        '#3B0DF8',
                        '#06C1F8',
                        '#F6B107',
                        '#F97602',
                        '#47F605',
                        '#05F9C5',
                        '#8208F4'
                    ],
                    hoverBackgroundColor: [
                        "#64B5F6",
                        "#81C784",
                        "#FFB74D",
                        "#f2527f",
                        '#cd55e6',
                        '#62e3db',
                        '#59e35b',
                        '#6F50F3',
                        '#4FCAEE',
                        '#F7C548',
                        '#F5943D',
                        '#7AF84B',
                        '#62F7D7',
                        '#9E48EF'
                    ]
                }
            ]
        };
        // 
        // console.log("chartData", this.chartData);
        this.lightOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            }
        };

        let header = (textTooltip, j) => {
            return [
                <span key={j} className="pieChart" data-title={textTooltip}>
                    <img className="icon-table4 float-right" src="/assets/icons/info.png" />
                </span>
            ]
        }

        return (
            <div>
                {tipoPie ?
                    <div className="card">
                        <h5 className="tituloChart" >{titlePie}</h5>
                        <Chart type="pie" data={chartData} options={this.lightOptions} />
                    </div>
                    :
                    <Card title={data ? data.titulo : 'Título de la tarjeta'} header={header(data ? data.tooltip : 'Tooltip', 'j')}>
                        <Chart type="pie" data={this.chartData} options={this.lightOptions} />
                    </Card>
                }
            </div>
        )
    }
}

