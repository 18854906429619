import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getUsers } from "./selectors";
import { consultarEmpresa } from '../../../controller/company'
import {
    GET_USERS, VERIFICATED_EMAIL
} from './constants';
import { Button, Card } from "@material-ui/core";

class ComponentBienvenidaCompra extends Component {
    componentWillMount() {
        // document.getElementById("scripts-chatbot").innerHTML = "";
        // document.getElementById("container-chatbot").innerHTML = "";
        // const { getUsers, bienvenidaPage } = this.props;
        // getUsers();
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        // const oobCode = urlParams.get('oobCode');
        // bienvenidaPage(oobCode)
        //  console.log("bienvenidaPage(oobCode)", bienvenidaPage(oobCode))
    }

    render() {
        const {
            userHeaders,
            users,
        } = this.props;

        return (
            <div className="App bienvenida-container">
                <Card className="card-container">
                    <img src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fimages%2FLogo.png?alt=media&token=975ba68f-1054-4859-8fb3-3b6954213c2f"
                        alt="Logo" width="250"
                        height="130" />
                    <p>

                        <h2>

                            ¡Transacción completada!
                        </h2>
                    </p>
                    <p>
                        Estimado cliente, queremos agradecerte por elegirnos.
                        Te invitamos a revisar tu correo electrónico para verificar si la transacción se ha realizado de manera exitosa.
                        En breve, recibirás un correo con los detalles del producto que has adquirido.
                    </p>

                    <p>
                        Si tienes alguna pregunta o necesitas asistencia,
                        no dudes en contactarnos a través de nuestro servicio de atención
                        al cliente en <strong>chataesores@gmail.com</strong>
                    </p>
                    <Button color="primary" variant="contained" href={process.env.REACT_APP_INICIO}>
                        Volver
                    </Button>

                    {/* 
                        <Button color="primary" variant="contained" href={process.env.REACT_APP_LOGIN}>
                            Iniciar sesión
                        </Button> */}
                </Card>
                {false &&
                    <Card className="card-container">
                        <img src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fimages%2F5219070.png?alt=media&token=71360887-7008-4a32-8970-b5263caec3e9"
                            alt="Logo" width="250"
                            height="130" />
                        <h2>
                            ¡Lo sentimos tenemos un incoveniente!
                            por favor intenta nuevamente
                        </h2>

                    </Card>
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        bienvenidaPage: (value) => dispatch({ type: VERIFICATED_EMAIL, value }),
        getUsers: () => dispatch({ type: GET_USERS, value: 1 })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentBienvenidaCompra);