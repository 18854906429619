import "./style.css";
import 'primeflex/primeflex.css';
import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CSVReader } from 'react-papaparse';
import SideBar from "../../components/sidebar/sidebar";
import { menuItems, menuItemsChatBot } from "../../Constants";
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { getFromSession } from '../../controller/session'
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from "primereact/dialog";
import PieChart from "../../components/Charts/pieChart"
import moment from "moment";
import LoadingOverlay from 'react-loading-overlay';
import { Paginator } from 'primereact/paginator';
import EstadisticasEnviosComponent from "./estadisticasEnvios";
import EnvioIndividual from "./envioIndividual";
import CustomReduxForm from "../../components/materialform/customform";
import { InputTextarea } from "primereact/inputtextarea";
import {
    getSelectedForm,
    getEnvios,
    getFormFiles,
    getVerLista,
    getCsv,
    getEnvio,
    getUsuarios,
    getTotalCsv,
    ocultarDialogo,
    toastDetail,
    toastMensaje,
    updateTable,
    getCamposPlantillaCombo,
    getSelectedReduxFormEnvios,
    getParametrosArchivo,
    archivoSeleccionado,
    getSpinnerDestinatarios,
    getSpinnerArchivo,
    getTotalDestinatarios,
    getBusquedaActiva,
    getListaCompletaDestinatariosFiltrados,
    getTotalLeidosEnvio,
    getDataCsvDestinatarios,
    getLeadsPorEnvio,
    getConvDataEnvio,
    getOpenModalEnvioDetail,

} from "./selectors";

import {
    UPDATE_FIELD_ATTRIBUTE,
    GUARDAR_ENVIO,
    SEND_ENVIO,
    LIST_ENVIO,
    LOAD_CSV,
    IR_NUEVO,
    VER_ENVIO,
    REMOVE_FILE,
    LIMPIAR_FORM,
    BORRAR_ENVIO,
    CAMPOS_PLANTILLA,
    FORM_ENVIOS_NAME,
    ARCHIVOS_ADJUNTOS,
    DISABLE_CHOOSE_BUTTON,
    PAGINAR_DESTINATARIOS,
    FILTRAR_DESTINATARIOS,
    TOTAL_DESTINATARIOS,
    TOTAL_LEIDOS,
    DESCARGAR_CSV_ENVIOS,
    OPEN_CONVERSATION_ENVIO_MASIVO,
    CLOSE_MODAL_CONVERSATION_DETAIL_ENVIOS,

} from "./constants";


import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from "primereact/fileupload";
import { CSVLink } from "react-csv";
import PrintConversationData from "../../components/printConversation/printConversation";
import Messages from "../../components/chatCard/messages";


class ComponentEnviosMasivos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openModal: false,
            openModalBorrar: false,
            envioItem: false,
            globalFilter: null,
            globalFilterDestinatarios: null,
            basicFirst: 0,
            basicRows: 10,
            multiSortMeta: [],
            listaPaginada: false,
            generandoCsv: false,
            btnDescargarCsv: false,
            menu: false,

        };
        this.ocultarModal = this.ocultarModal.bind(this);
        this.abrirModal = this.abrirModal.bind(this);
        this.abrirModalBorrar = this.abrirModalBorrar.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.rightToolbarTemplateEnvio = this.rightToolbarTemplateEnvio.bind(this);
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.onBasicFilterChange = this.onBasicFilterChange.bind(this);
        this.generarCSV = this.generarCSV.bind(this)
    }

    paginarDestinatariosFiltrados(event) {
        const { listaDestinatariosFiltradaCompleta } = this.props
        //console.log("listaDestinatariosFiltradaCompleta", listaDestinatariosFiltradaCompleta);
        let listaPaginada;
        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = listaDestinatariosFiltradaCompleta.slice(event.first, last)
        } else {
            listaPaginada = listaDestinatariosFiltradaCompleta.slice(event.first, event.rows)
        }
        // console.log("listaDestinatariosFiltradaCortada", listaPaginada);
        this.setState({
            listaPaginada: listaPaginada
        })
    }

    onBasicPageChange(event) {
        const { envio, paginarDestinatarios, busquedaActiva } = this.props;
        //console.log('event', event);
        let idEnvio = envio.data().idDoc;
        this.setState({
            basicFirst: event.first,
            basicRows: event.rows
        });
        if (busquedaActiva) {
            this.paginarDestinatariosFiltrados(event);
        } else {
            this.setState({
                listaPaginada: false,
            })
            paginarDestinatarios({ idEnvio, event });
        }
    }

    onBasicFilterChange = (text) => {
        console.log('event', text);
        const { filtrarDestinatarios, envio /* totalDestinatarios  */ } = this.props
        let textInput = text
        let idEnvio = envio.data().idDoc;
        let numFilas = this.state.basicRows;
        filtrarDestinatarios({ textInput, /* totalDestinatarios, */ numFilas, idEnvio });
    }

    verDestinatariosEnvio = (envio) => {
        const { verEnvio, traerDestinatarios, realizarConteoLeidos } = this.props;
        verEnvio(envio.id);

        let fecha_cargue = envio.fechaCargue
        if (new Date(fecha_cargue) < new Date("January 31, 2022 00:00:00")) {
            traerDestinatarios(envio.id)
        }

        if (envio && envio.data.estado == 5 && new Date(fecha_cargue) > new Date("January 31, 2022 00:00:00")) {
            realizarConteoLeidos(envio.id)
        }
    }

    verDetallesConversacionEnvio = (envio) => {
        const { openConversationEnvio } = this.props;

        openConversationEnvio({ idconv: envio.idconv })

    }

    componentDidMount() {
        const { listEnvios, traerCamposComboPlantilla } = this.props;
        listEnvios();
        traerCamposComboPlantilla();
    }

    componentDidUpdate(prevProps) {
        const { ocultarDialogo, toastDetail, toastMensaje, updateTable, listEnvios, archivoSeleccionado, busquedaActiva, verEnvio, envio, dataDestinatariosCsv } = this.props;

        if (updateTable) {
            listEnvios()
        }

        if (ocultarDialogo) {
            this.ocultarModal();
        }
        if (prevProps.busquedaActiva != busquedaActiva) {
            if (busquedaActiva == false) {
                this.setState({
                    listaPaginada: false,
                    basicFirst: 0,
                    globalFilterDestinatarios: ""
                })
                verEnvio(envio.data().idDoc)
            }

        }
        if (toastMensaje) {
            this.toast.show({ severity: toastDetail.severity, summary: toastDetail.summary, detail: toastDetail.detail });
        }

        if (archivoSeleccionado) {
            let chooseButton = document.querySelector('.p-fileupload-choose');
            if (chooseButton) {
                chooseButton.style.display = 'none'
            }
        }

        if (prevProps.dataDestinatariosCsv != dataDestinatariosCsv) {
            if (dataDestinatariosCsv) {
                this.setState({
                    generandoCsv: false,
                    btnDescargarCsv: true
                });

            }
        }
    }

    handleOnError = (err) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        const { removeFile } = this.props;
        removeFile();
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
    }

    rightToolbarTemplate(accion) {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-help" onClick={() => this.abrirModal()} />
            </React.Fragment>
        )
    }



    generarCSV() {
        const { generarCSVEnvio, envio } = this.props

        this.setState({
            generandoCsv: true
        })
        generarCSVEnvio(envio.data().idDoc)

    }


    rightToolbarTemplateEnvio() {
        const { dataDestinatariosCsv, envio } = this.props
        return (
            <React.Fragment>
                <Button label="Generar CSV" icon="pi pi-file-excel"
                    loading={this.state.generandoCsv} className="p-button-help p-ml-2" onClick={this.generarCSV} />
                {dataDestinatariosCsv && this.state.btnDescargarCsv ? (<CSVLink data={dataDestinatariosCsv} filename={`csvEnvio-${envio.data().nombre}.csv`} >
                    <Button label="Descargar CSV" icon="pi pi-download" className="p-button-success p-ml-2"
                        onClick={e => {
                            this.setState({
                                btnDescargarCsv: false
                            })
                            return false; // 👍🏻 You are stopping the handling of component
                        }}
                    />
                </CSVLink>) : false}
            </React.Fragment>
        )
    }


    validations = (values) => {
        const errors = {}
        const requiredFields = [
            'nombre',
            'plantilla',
        ]
        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Obligatorio'
            }
        })
        return errors
    }

    archivoSeleccionadoR = (event) => {

        let chooseButton = document.querySelector('.p-fileupload-choose');
        //console.log('chooseButton',chooseButton);
        if (chooseButton) {
            chooseButton.style.display = 'none';
        }


    }
    habilitarBotonElegir = (event) => {
        let chooseButton = document.querySelector('.p-fileupload-choose');
        if (chooseButton) {
            chooseButton.style.display = 'inline-block';
        }
        let confirmarButton = document.querySelector('.uploadButton');
        if (confirmarButton) {
            confirmarButton.style.display = 'inline-block';
        }
    }
    deshabilitarBotonSubir = (event) => {
        let uploadButton = document.querySelector('.uploadButton');
        if (uploadButton) {
            uploadButton.style.display = 'none';
        }

    }

    renderEnvioForm(buttons) {
        const {
            selectedReduxForm,
            csv,
            totalCsv,
            loadCsv,
            guardarEnvio,
            opcionesPlantillaCombo,
            adjuntarArchivosEnviosMasivos,
            parametrosArchivo,
            loadingArchivo,
        } = this.props

        let vistaPreviaSeleccion = selectedReduxForm && selectedReduxForm.plantilla ? JSON.parse(selectedReduxForm.plantilla) : false;

        let dataMensaje = vistaPreviaSeleccion ? vistaPreviaSeleccion.data : 'Seleccione una plantilla';
        let type = vistaPreviaSeleccion ? vistaPreviaSeleccion.templateType : false;

        if (parametrosArchivo) {
            this.deshabilitarBotonSubir()
        }
        let ENVIO_FORM = [
            {
                name: "nombre",
                placeholder: "Escriba el nombre del envío...",
                options: [],
                disable: false,
                typeForm: 'text',
                display: true,
                label: 'Nombres',
                type: 'text',
            },
            {
                label: "Plantilla",
                name: "plantilla",
                placeholder: "Seleccione una plantilla...",
                type: 'text',
                typeForm: 'select',
                options: opcionesPlantillaCombo,
                disable: false,
                display: true,
            },

        ];
        const uploadOptions = { className: 'p-button-success  uploadButton' };
        let vistaPrevia = <div className="vistaPreviaEnvio p-mt-3">
            <h5>Vista previa mensaje</h5>
            <InputTextarea
                placeholder={dataMensaje}
                rows={5}
                cols={77}
                disabled
            />
            {type && type !== 'TEXT' ?
                (<LoadingOverlay
                    className="spinnerArchivos"
                    active={loadingArchivo}
                    spinner
                    text="Cargando archivo, un momento..."
                >
                    <FileUpload
                        customUpload
                        emptyTemplate={<p className="p-m-0">Seleccione o arrastre un archivo hasta aquí.</p>}
                        maxFileSize={20000000}
                        uploadLabel='Confirmar'
                        cancelLabel='Cancelar'
                        chooseLabel='Elegir'
                        invalidFileSizeMessageDetail="El archivo no puede pesar mas de {0}."
                        invalidFileSizeMessageSummary="{0}: Tamaño de archivo inválido"
                        uploadHandler={($event) => adjuntarArchivosEnviosMasivos($event)}
                        onSelect={this.archivoSeleccionadoR}
                        onRemove={this.habilitarBotonElegir}
                        onClear={this.habilitarBotonElegir}
                        uploadOptions={uploadOptions}
                    ></FileUpload>
                </LoadingOverlay>

                ) : false}
        </div>

        return (

            <div className="card">

                <div className='p-col-12' style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                    <CSVReader
                        onDrop={loadCsv}
                        onError={this.handleOnError}
                        addRemoveButton
                        onRemoveFile={this.handleOnRemoveFile}
                        accept='text/csv, .csv, application/vnd.ms-excel, .txt'
                    >
                        <div className="p-text-normal p-text-left">Click aquí para cargar el archivo de destinatarios</div>
                    </CSVReader>
                    <div className="p-text-normal p-text-left">Total Registros cargados: {totalCsv ? totalCsv : 0}</div>

                    <CustomReduxForm
                        positionButtons='buttonsSaveCancel'
                        submitable={false}
                        formName={FORM_ENVIOS_NAME}
                        items={ENVIO_FORM}
                        handleSubmit={() => guardarEnvio({ selectedReduxForm, csv, totalCsv, parametrosArchivo })}
                        className="splash mt-0"
                        buttons={buttons(() => guardarEnvio({ selectedReduxForm, csv, totalCsv, parametrosArchivo }))}
                        validations={this.validations}
                        aditionalElement={vistaPrevia}
                    />

                </div>
            </div>

        );
    }

    renderVisualizaConversation() {
        const { convDataEnvio, closedModalEnvioDetail } = this.props
        return (
            <div>
                {/* <PrintConversationData
                    datos={false}
                    verModalDescargarConversacion={false}
                    mensajes={convDataEnvio.messages} cerrarModalDescargarDatos={closedModalEnvioDetail}
                >
                </PrintConversationData> */}



                <Dialog visible={true} style={{ width: '450px' }} header="Conversación" modal className="p-fluid" onHide={closedModalEnvioDetail}>
                    <Messages mensajes={convDataEnvio.messages} ></Messages>

                </Dialog>
            </div>

        );
    }

    renderDialogConfirmacion() {
        const { borrarEnvio } = this.props

        let idEnvio = this.state.envioItem.id;

        return (
            <div>
                <div className="p-grid" style={{ 'marginTop': '0.5rem', 'marginLeft': '2rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2rem' }}></i>
                    <div className="p-text-normal" style={{ 'marginLeft': '1rem' }}>¿Está seguro que desea borrar el elemento?</div>
                </div>
                <div className="p-grid" style={{ 'marginTop': '0.5rem' }}>
                    <div style={{ 'marginLeft': 'auto', 'marginRight': '2rem' }}>
                        <Button label="Si" onClick={() => borrarEnvio(idEnvio)} ></Button>
                        <Button label="No" onClick={() => this.ocultarModal()} style={{ 'marginLeft': '1rem' }} className="p-button-secondary"></Button>
                    </div>
                </div>
            </div>
        )

    }

    ocultarModal() {
        const { removeFile, limpiarEnvioForm } = this.props;
        removeFile();
        limpiarEnvioForm();
        this.setState({
            openModal: false,
            openModalBorrar: false
        });
    }

    abrirModal() {
        this.setState({
            openModal: true,
        })
    }

    abrirModalBorrar(item) {
        this.setState({
            openModalBorrar: true,
            envioItem: { ...item }
        })
    }

    renderModal(open, body, onHide, headerTitle, id) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw", top: "60px" }}
                baseZIndex={500}
            >
                {body}
            </Dialog>
        )
    }

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
        } else {
            this.setState({ menu: menuItemsChatBot });
        }
    }


    render() {
        const {
            envios,
            sendEnvio,
            verLista,
            envio,
            usuarios,
            irNuevo,
            listEnvios,
            loadingUsuarios,
            totalDestinatarios,
            totalLeidosEnvio,
            leadsPorEnvio,
            listaDestinatariosFiltradaCompleta,
            openModalEnvioDetail,
        } = this.props
        let user = getFromSession('company');


        let getFormButtons = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary",
                    action: false,
                    onClick: handlerSafe,
                    display: true,
                    style: "marginRight"
                },
                {
                    label: "Cancelar",
                    className: "btn btn-primary",
                    action: false,
                    onClick: () => this.ocultarModal(),
                    display: true
                }
            ]
        }



        let parametros = false;
        let parametros2 = false;

        if (envio && (envio.data().estado == 5 || envio.data().estado == 3)) {

            let leidos = 0;
            let noLeidos = 0;
            let leads = 0;
            let enviados = 0

            let labels = ['Leídos', 'No leídos'];
            let titulo = 'Estado de los mensajes';
            let tooltip = 'Gráfico que muestra el estado de los mensajes enviados a los destinatarios';


            if (totalDestinatarios) {
                enviados = totalDestinatarios.length;
                totalDestinatarios.forEach(mensaje => {

                    if (mensaje.status === "read") {
                        leidos += 1;
                    } else {
                        noLeidos += 1;
                    }
                    if (mensaje.leads) {
                        leads += 1;
                    }

                });
            } else if (totalLeidosEnvio) {
                leidos = totalLeidosEnvio;
                noLeidos = envio.data().registros - totalLeidosEnvio;
                leads = leadsPorEnvio;
                enviados = envio.data().registros;
            }

            parametros = {
                'labels': labels,
                'titulo': titulo,
                'tooltip': tooltip,
                'numeros': [leidos, noLeidos]
            }

            parametros2 = {
                'labels': ['Enviados', 'Leads'],
                'titulo': 'Leads obtenidos del envio',
                'tooltip': 'Gráfico que muestra los leads obtenidos de los mensajes leidos por los destinatarios',
                'numeros': [enviados, leads]
            }

        }

        const headerEnvios = (

            <div className="table-header">
                <h2 className="p-m-1 p-text-center">Lista de envíos</h2>
                <span className="p-m-1 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Buscar..." />


                </span>
            </div>
        );

        const headerDestinatarios = (
            <div className="table-header">
                <h3 className="p-m-1 p-text-center">Lista de destinatarios</h3>
                <span className="p-m-1 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search" value={this.state.globalFilterDestinatarios} onInput={(e) => this.setState({ globalFilterDestinatarios: e.target.value })} placeholder="Buscar por teléfono"
                        /* onInput={this.onBasicFilterChange} */ />
                    <Button label="Buscar" className="p-button-raised ml-2" onClick={() => this.onBasicFilterChange(this.state.globalFilterDestinatarios)} />
                    <Button label="Limpiar" className="p-button-raised ml-2" onClick={() => this.onBasicFilterChange("")} />
                </span>
            </div>
        );

        const actionBodyTemplate = (rowData) => {
            //console.log('rowData', rowData);
            return (
                <React.Fragment>
                    {rowData.data.estado === 0 ? (<div>
                        <Button icon="pi pi-eye" title='Ver Envío'
                            className="p-button-rounded p-button-info p-mr-0" onClick={() => this.verDestinatariosEnvio(rowData)}></Button>
                        <Button icon="pi pi-trash" title='Eliminar' className="p-button-rounded p-button-warning p-ml-1" onClick={() => this.abrirModalBorrar(rowData)}></Button>
                    </div>) : <Button icon="pi pi-eye" style={{ fontSize: '73%', padding: '6px' }} label="Ver Envío" title='Ver Envío'
                        className="p-button-rounded p-button-info p-mr-0" onClick={() => this.verDestinatariosEnvio(rowData)} />}
                </React.Fragment>
            );
        }

        const actionEnvioTemplate = (rowData) => {
            return (
                <React.Fragment>
                    {rowData.leads ?
                        <div>
                            <Button icon="pi pi-eye" title='Conversación' label='Conversación'
                                className="p-button-rounded p-button-info p-mr-0" onClick={() => this.verDetallesConversacionEnvio(rowData)}></Button>
                        </div>
                        : ""}
                </React.Fragment>
            );
        }

        const estado = (rowData) => {
            let estado = false;
            switch (rowData.data.estado) {
                case 0:
                    estado = "Borrador"
                    break;
                case 1:
                    estado = "Registrando numeros"
                    break;
                case 2:
                    estado = "En proceso de envío"
                    break;
                case 5:
                    estado = "Enviado"
                    break;
                default:
                    estado = ""
                    break;
            }
            return (
                <React.Fragment>
                    {estado}
                </React.Fragment>
            );
        }

        let fecha_cargue = envio ? envio.data().fecha_cargue : false;
        let fecha_ini_envio = envio ? envio.data().fecha_ini_envio : false;
        let fecha_fin_envio = envio ? envio.data().fecha_fin_envio : false;
        let estadoLabel = envio ? envio.data().estado : false;

        if (estadoLabel === 0) {
            estadoLabel = 'En Borrador'
        } else if (estadoLabel === 1) {
            estadoLabel = 'Registrando numeros'
        } else if (estadoLabel === 2) {
            estadoLabel = 'En proceso de envío'
        } else if (estadoLabel === 5) {
            estadoLabel = 'Enviado'
        }

        if (fecha_cargue) {
            fecha_cargue = fecha_cargue.toDate();
            fecha_cargue = moment(fecha_cargue).format("lll");
        }
        if (fecha_ini_envio) {
            fecha_ini_envio = fecha_ini_envio.toDate();
            fecha_ini_envio = moment(fecha_ini_envio).format("lll");
        }
        if (fecha_fin_envio) {
            fecha_fin_envio = fecha_fin_envio.toDate();
            fecha_fin_envio = moment(fecha_fin_envio).format("lll");
        }

        return (
            <React.Fragment>
                <SideBar logo={user.logo} items={this.state.menu}></SideBar>
                <div>
                    <LoadingOverlay
                        className="spinnerEnviosMasivos"
                        active={loadingUsuarios ? loadingUsuarios.loading : false}
                        spinner
                        text={loadingUsuarios ? loadingUsuarios.text : "Cargando....Por favor espere."}>

                        <Toast ref={(el) => this.toast = el} />
                        {this.renderModal(
                            this.state.openModal,
                            this.renderEnvioForm(getFormButtons),
                            this.ocultarModal,
                            'Nuevo Envío',
                            "formEnvios",

                        )}
                        {this.renderModal(
                            this.state.openModalBorrar,
                            this.renderDialogConfirmacion(getFormButtons),
                            this.ocultarModal,
                            'Confirmación',
                            "dialogoConfirmacionEliminar"
                        )}
                        {openModalEnvioDetail ? this.renderVisualizaConversation() : ""}


                        <div className="card">
                            <h3 className="h3-title p-mt-3">Módulo de Envíos Masivos Whatsapp</h3>
                            <TabView>
                                <TabPanel header="Envíos">
                                    <div className="p-pt-2 p-pr-6 p-pl-6">
                                        {!verLista && envio ?
                                            <Card>
                                                <div className="p-card-title">
                                                    <h3 className="p-m-1 p-text-center">Datos del Envío</h3></div>
                                                <div className="p-card-content">
                                                    {(envio.data().estado == 5 || envio.data().estado == 3) ? (

                                                        <div className="p-grid ">
                                                            <div className="p-col" />
                                                            <div className="p-col">
                                                                <div className="pie-envios">
                                                                    <PieChart data={parametros}
                                                                    ></PieChart>
                                                                </div>
                                                            </div>
                                                            <div className="p-col">

                                                                <div className="pie-envios">
                                                                    <PieChart data={parametros2}
                                                                    ></PieChart>
                                                                </div>
                                                            </div>
                                                            <div className="p-col" />
                                                        </div>) : null}
                                                    <div className="p-grid p-ml-4 p-mt-2">
                                                        <div className="p-col-6">
                                                            <div className="p-grid">
                                                                <h4>Nombre:</h4>
                                                                <div className="p-text-normal p-text-left p-ml-2">{envio.data().nombre}</div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-6">
                                                            <div className="p-grid">
                                                                <h4>Fecha de creación:</h4>
                                                                <div className="p-text-normal p-text-left p-ml-2">{fecha_cargue}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {envio.data().estado == 3 || envio.data().estado == 5 ? (
                                                        <div className="p-grid p-ml-4 p-mt-0">
                                                            <div className="p-col-6">
                                                                <div className="p-grid">
                                                                    <h4>Fecha inicio de envío:</h4>
                                                                    <div className="p-col-8 p-pt-0 p-text-normal p-text-left">{fecha_ini_envio}</div>
                                                                </div>
                                                            </div>
                                                            <div className="p-col-6">
                                                                <div className="p-grid">
                                                                    <h4>Fecha fin de envío:</h4>
                                                                    <div className="p-text-normal p-text-left p-ml-2">{fecha_fin_envio}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ) : false}

                                                    <div className="p-grid p-ml-4 p-mt-0">
                                                        <div className="p-col-6">
                                                            <div className="p-grid">
                                                                <h4>Estado:</h4>
                                                                <div className="p-text-normal p-text-left p-ml-2">{estadoLabel}</div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-6">
                                                            <div className="p-grid">
                                                                <h4>Número de registros:</h4>
                                                                <div className="p-text-normal p-text-left p-ml-2">{envio.data().registros}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="p-grid p-ml-4 p-mt-0">
                                                        <div className="p-col-12">
                                                            <div className="p-grid">
                                                                <h4>Plantilla de mensaje:</h4>
                                                                <div className="p-col-8 p-pt-0 p-text-normal p-text-left">{envio.data().mensaje}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Card>
                                                    <div>

                                                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplateEnvio}></Toolbar>
                                                        <DataTable ref={(el) => this.dt = el}
                                                            value={totalDestinatarios ? totalDestinatarios : this.state.listaPaginada ? this.state.listaPaginada : usuarios}
                                                            header={headerDestinatarios}
                                                            dataKey="id" columnResizeMode="fit" /* globalFilter={this.state.globalFilter} */
                                                            emptyMessage="No hay registros encontrados"
                                                            paginator={new Date(fecha_cargue) > new Date("January 31, 2022 00:00:00") ? false : true}
                                                            rows={10} rowsPerPageOptions={[5, 10, 25]}>
                                                            <Column field="telefono" header="Telefono" ></Column>
                                                            <Column field="mensaje" header="Mensaje" ></Column>
                                                            <Column field="status" header="Estado" ></Column>
                                                            <Column field="response" header="Respuesta" ></Column>
                                                            <Column field="errorReason" header="Error" ></Column>
                                                            <Column field="errorCode" header="Error Code"></Column>
                                                            <Column header="Acciones" body={actionEnvioTemplate} style={{ width: '15%' }}></Column>
                                                        </DataTable>
                                                        {new Date(fecha_cargue) > new Date("January 31, 2022 00:00:00") ?
                                                            <Paginator first={this.state.basicFirst} template="FirstPageLink PrevPageLink PageLinks NextPageLink
                                                             LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                                rows={this.state.basicRows} totalRecords={envio.data().registros} rowsPerPageOptions={[10, 20, 30]}
                                                                onPageChange={this.onBasicPageChange} currentPageReportTemplate={`Mostrando {first} a 
                                                                ${listaDestinatariosFiltradaCompleta && listaDestinatariosFiltradaCompleta.length > 0 &&
                                                                        listaDestinatariosFiltradaCompleta.length < this.state.basicRows ?
                                                                        listaDestinatariosFiltradaCompleta.length : this.state.basicRows} 
                                                                de un total de  ${listaDestinatariosFiltradaCompleta && listaDestinatariosFiltradaCompleta.length >
                                                                        0 ? listaDestinatariosFiltradaCompleta.length : envio.data().registros}`}></Paginator> : null

                                                        }
                                                    </div>
                                                </Card>
                                                <div className="p-grid p-mt-2">
                                                    <div className="p-col-6 p-text-left">
                                                        <Button label="Volver" onClick={(e) => listEnvios()} />
                                                    </div>
                                                    <div className="p-col-6 p-text-right">
                                                        {envio.data().estado === 0 ?
                                                            <Button label="Enviar" onClick={(e) => sendEnvio(envio)} /> : ''}
                                                    </div>
                                                </div>
                                            </Card>
                                            : ""}

                                        {verLista && envios &&
                                            <Card>
                                                <Toolbar className="p-mb-4" right={this.rightToolbarTemplate(irNuevo)}></Toolbar>
                                                <DataTable className="listaEnvios" ref={(el) => this.dt = el} value={envios} header={headerEnvios}
                                                    globalFilter={this.state.globalFilter}
                                                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} envíos"
                                                >
                                                    <Column field="fechaCargue" header="Fecha Creación" sortable></Column>
                                                    <Column field="fechaIni" header="Fecha Envío" sortable></Column>
                                                    <Column field="data.nombre" header="Nombre" sortable></Column>
                                                    <Column field="data.mensaje" header="Mensaje" style={{ width: '35%' }} sortable></Column>
                                                    <Column field="data.registros" header="Destinatarios" sortable></Column>
                                                    <Column header="Estado" body={estado}></Column>
                                                    <Column header="Acciones" body={actionBodyTemplate} style={{ width: '10%' }}></Column>
                                                </DataTable>
                                            </Card>
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel header="Estadísticas">
                                    <EstadisticasEnviosComponent></EstadisticasEnviosComponent>
                                </TabPanel>
                                <TabPanel header="Envíos Individuales">
                                    <EnvioIndividual></EnvioIndividual>
                                </TabPanel>
                            </TabView>
                        </div>
                    </LoadingOverlay>
                </div>
            </React.Fragment>
        )
    }

}
const mapStateToProps = state => {
    return {
        selectedReduxForm: getSelectedReduxFormEnvios(state),
        selectedForm: getSelectedForm(state),
        envios: getEnvios(state),
        formFiles: getFormFiles(state),
        verLista: getVerLista(state),
        csv: getCsv(state),
        envio: getEnvio(state),
        usuarios: getUsuarios(state),
        totalCsv: getTotalCsv(state),
        ocultarDialogo: ocultarDialogo(state),
        toastDetail: toastDetail(state),
        toastMensaje: toastMensaje(state),
        updateTable: updateTable(state),
        opcionesPlantillaCombo: getCamposPlantillaCombo(state),
        parametrosArchivo: getParametrosArchivo(state),
        archivoSeleccionado: archivoSeleccionado(state),
        loadingUsuarios: getSpinnerDestinatarios(state),
        loadingArchivo: getSpinnerArchivo(state),
        totalDestinatarios: getTotalDestinatarios(state),
        busquedaActiva: getBusquedaActiva(state),
        listaDestinatariosFiltradaCompleta: getListaCompletaDestinatariosFiltrados(state),
        totalLeidosEnvio: getTotalLeidosEnvio(state),
        dataDestinatariosCsv: getDataCsvDestinatarios(state),
        leadsPorEnvio: getLeadsPorEnvio(state),
        convDataEnvio: getConvDataEnvio(state),
        openModalEnvioDetail: getOpenModalEnvioDetail(state),

    }

}
const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        guardarEnvio: (value) => dispatch({ type: GUARDAR_ENVIO, value }),
        sendEnvio: (value) => dispatch({ type: SEND_ENVIO, value }),
        listEnvios: (value) => dispatch({ type: LIST_ENVIO, value }),
        loadCsv: (value) => dispatch({ type: LOAD_CSV, value }),
        irNuevo: (value) => dispatch({ type: IR_NUEVO, value }),
        verEnvio: (value) => dispatch({ type: VER_ENVIO, value }),
        removeFile: (value) => dispatch({ type: REMOVE_FILE, value }),
        limpiarEnvioForm: (value) => dispatch({ type: LIMPIAR_FORM, value }),
        borrarEnvio: (value) => dispatch({ type: BORRAR_ENVIO, value }),
        traerCamposComboPlantilla: (value) => dispatch({ type: CAMPOS_PLANTILLA, value }),
        adjuntarArchivosEnviosMasivos: (value) => dispatch({ type: ARCHIVOS_ADJUNTOS, value }),
        disableChooseButton: (value) => dispatch({ type: DISABLE_CHOOSE_BUTTON, value }),
        paginarDestinatarios: (value) => dispatch({ type: PAGINAR_DESTINATARIOS, value }),
        filtrarDestinatarios: (value) => dispatch({ type: FILTRAR_DESTINATARIOS, value }),
        traerDestinatarios: (value) => dispatch({ type: TOTAL_DESTINATARIOS, value }),
        realizarConteoLeidos: (value) => dispatch({ type: TOTAL_LEIDOS, value }),
        generarCSVEnvio: (value) => dispatch({ type: DESCARGAR_CSV_ENVIOS, value }),
        openConversationEnvio: (value) => dispatch({ type: OPEN_CONVERSATION_ENVIO_MASIVO, value }),
        closedModalEnvioDetail: (value) => dispatch({ type: CLOSE_MODAL_CONVERSATION_DETAIL_ENVIOS, value }),

    }

}
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentEnviosMasivos);
