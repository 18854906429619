import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { Button } from "primereact/button";
import { CSVLink } from "react-csv";
import PieChart from "../../../components/Charts/pieChart";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getDataDocumentCargados, getLineChart, getLoading } from "./selectors";
import LineChart from "../../../components/Charts/lineChart";
import { Card } from 'primereact/card';

class ComponentEstadisticasGenerales extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }



    render() {
        const {
            dataGraficaDocument,
            loading,
            lineChartSelector
        } = this.props;
        console.log("lineChartSelector", lineChartSelector)
        return (
            <div className="App borrador-container">
                <Paper elevation={10} className="paper-conversaciones-ocr">
                    {loading &&

                        <div className="loading-wrapper">
                            <CircularProgress color="secondary"></CircularProgress>
                        </div>

                       }

                    {/* <div className="p-grid">
                     <div className="p-col-9 margin-left">
                            <Card >
                                <LineChart
                                    data={lineChartSelector}></LineChart>
                            </Card>

                            <div className="downloadButton">


                                {lineChartSelector?.dataDownload?.length > 0 ?
                                    <CSVLink data={lineChartSelector?.dataDownload}
                                        filename={`descargar-data-csvData.csv`}>
                                        <Button label="Descargar Data" icon="pi pi-file-excel"
                                            className="p-button-info p-ml-2"
                                        >

                                        </Button>
                                    </CSVLink>

                                    : ''
                                }

                            </div>
                        </div>
                    </div> */}



                    <div className="p-grid  p-mt-2 margin-left-pie">
                        <div className="p-col-5">
                            <PieChart data={dataGraficaDocument}
                            ></PieChart>

                            <div className="downloadButton">


                                {dataGraficaDocument?.graficas?.length > 0 ?
                                    <CSVLink data={dataGraficaDocument?.graficas}
                                        filename={`descargar-data-csvData.csv`}>
                                        <Button label="Descargar Data" icon="pi pi-file-excel"
                                            className="p-button-info p-ml-2"
                                        >

                                        </Button>
                                    </CSVLink>

                                    : ''
                                }

                            </div>
                        </div>





                    </div>



                </Paper>

            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        dataGraficaDocument: getDataDocumentCargados(state),
        loading: getLoading(state),
        lineChartSelector: getLineChart(state)
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentEstadisticasGenerales);