import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';

export  function* obtenerNotasConv(idConv) {

    try {
        const company = getFromSession("company_id"); //`company/${company}/conversations`
        let notasOcultas = [];

       yield firebaseDatabase.collection(`company/${company}/conversations`).doc(idConv).collection('notasOcultas')
            .orderBy('fecha', 'asc').get().then(docs => {
                docs.forEach(doc => {
                    notasOcultas.push(doc);
                });

            });
      
        return notasOcultas;
    } catch (error) {
        console.error('controller.notasOcultas.obtenerNotasConv', error);
        throw error;
    }
}