import React, { Component, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from '../constants';
import { getMenuItemsChatBot, getUsers } from "../selectors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Paginator } from 'primereact/paginator';
import { Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';


const ComponentTable = (props) => {

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [first, setFirst] = useState(0); // Estado para controlar el primer registro mostrado
    const [rows, setRows] = useState(10); // Estado para controlar el número de filas por página

    const SelectedRow = () => {
        console.log("selectedProducts", selectedProducts)
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        console.log("event.first", props.data.length)
        console.log("event.rows", props.data.length)
    };

    const verDoc = (event) => {

        console.log("event.rows", event)
        props.onOpenTableHandler(event)
    };

    const renderStatus = (rowData) => {
        return (
            <Tooltip title="Ver documentos del lote" arrow>
                <IconButton onClick={() => verDoc(rowData)}>
                    <RemoveRedEyeIcon />
                </IconButton>
            </Tooltip>
        )
    }
    const estadoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.nombre}

                {/* Renderizar el CircularProgress junto al nombre si isLoading es true */}
                {rowData.estado}
                {rowData.estado === 'Orden de procesamiento' || rowData.estado === 'En procesamiento' ?

                    <CircularProgress size={20} style={{ marginLeft: 10, color: 'red', borderRadius: '50%', padding: '5px' }} />
                    : ''
                }
                {rowData.estado === 'Procesado' ?

                    <DoneOutlineIcon size={60} style={{ marginLeft: 10, color: 'green', borderRadius: '50%', padding: '5px' }} />
                    : ''
                }



            </React.Fragment>
        );
    };

    return (
        <Paper elevation={5} className="tabla-crear-pregunta">
            {/* {!props.loadingTable &&

                <div className="loading-wrapper">
                    <CircularProgress color="secondary"></CircularProgress>
                </div>

            } */}

            <h2 style={{ "padding": "20px" }}>{props.title}</h2>

            <DataTable value={props.data} selectionMode={rowClick ? null : 'checkbox'} first={first}
                rows={rows} paginator rowsPerPageOptions={[5, 10, 20]}
                selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" tableStyle={{ minWidth: '50rem' }}>

                {!props.lote && (
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                )}

                <Column field={props.lote ? 'nroLote' : 'fecha'} sortable header={props.lote ? 'Nro Lote ' : 'Fecha de cargue'} style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                {props.lote && (
                    <Column field="fecha" header="Fecha de Cargue" sortable style={{ 'width': '13rem', 'font-size': '12px' }} />
                )}
                <Column field={props.lote ? 'lote' : 'name'} sortable header={props.lote ? 'Lote ' : 'Nombre'} style={{ 'width': '13rem', 'font-size': '12px' }}></Column>
                <Column field={props.lote ? 'cantidad' : 'id'} sortable header={props.lote ? 'Nro Documentos ' : 'Id'} style={{ 'width': '13rem', 'font-size': '12px' }}></Column>
                {!props.lote && (
                    <Column field="lote" header="Lote" sortable style={{ 'width': '13rem', 'font-size': '12px' }} />
                )}

                {/* 
                <Column field={props.lote ? 'estado' : 'estado'} sortable header={props.lote ? 'Estado ' : 'Estado'} style={{ 'width': 'auto', 'font-size':'12px'  }}></Column>
                Verifica si mostrarColumna es verdadero antes de renderizar la columna */}
                {!props.lote && (
                    <Column field="estado" sortable header="Estado" body={estadoBodyTemplate} style={{ 'width': 'auto', 'font-size': '12px' }} />
                )}

                {props.lote && (
                    <Column field="Ver Documentos" header="Ver Documentos" body={renderStatus} style={{ 'width': 'auto', 'font-size': '12px' }} />
                )}

            </DataTable>
            {/*<Column header="Acciones" body={(rowData) => this.actionButtons(rowData)} sortable style={{ width: 'auto' }}></Column>*/}
            {!props.lote ?
                <Button onClick={() => props.procesarDocs(selectedProducts)} color='primary' variant="contained">Procesar documentos</Button>
                :
                ''
            }
        </Paper>
    );

}


export default ComponentTable;