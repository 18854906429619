
import React, { Component } from 'react';
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";
import { message } from '../../pages/adminPanel/selector';
import { isThisISOWeek, set } from 'date-fns';

export default class Messages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numMessages: 0,
        }

        this.handleEvent = this.handleEvent.bind(this)
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mensajes } = this.props;        
        if (prevState.name !== this.state.name) { this.handler() }
        if(mensajes && mensajes.length > 0 && mensajes.length > this.state.numMessages){
            this.setState({numMessages:mensajes.length})
            this.scrollToBottom();
        }
    }

    componentWillUnmount() {

    }

    scrollToBottom() {
       this.el.scrollIntoView({ behavior: 'smooth' });
    }

    // Prototype methods, Bind in Constructor (ES2015)
    handleEvent() { }

    // Class Properties (Stage 3 Proposal)
    handler = () => { this.setState() }

    renderMessage(message, j) {
        let fecha = false;
        let mensaje = false;
        
        if (message.fecha){
            fecha = message.fecha.toDate()
            fecha = moment(fecha).format("ll, h:mm:ss a")
            
            let clase = message.from.tipo === "cliente" ? "incoming_msg" : "outgoing_msg"
            let clase2 = message.from.tipo === "cliente" ? "received_msg pl-0" : "sent_msg"
            let from = message.from.tipo === "cliente" ? message.from.cliente.nombre : message.from.agente.nombre
            //MENSAJE DE TEXTO
        if (message.mensaje.type === "text") {
            if (message.from.tipo === "cliente") {
                mensaje = <p> {message.mensaje.text}</p>;
            } else {
                mensaje = <p style={{ whiteSpace: "pre-wrap" }}>{message.mensaje.text}</p>
            }
            //MENSAJE DE IMAGEN      
        } else if (message.mensaje.type === "image") {
            if (message.mensaje.caption) {
                mensaje = (
                    <div>
                        <img src={message.mensaje.url} />
                        <p>{message.mensaje.caption}</p>{" "}
                    </div>
                );
            } else {
                mensaje = <img src={message.mensaje.url} />;
            }
        }
        else if (message.mensaje.type === "file") {
            if (message.mensaje.caption) {
                mensaje = (
                    <div>
                        <p>{message.mensaje.caption}<br />
                            <a href={message.mensaje.url} className="fas fa-download" target="blank_">Descargar Documento</a></p>

                    </div>
                );
            } else {
                mensaje = <a href={message.mensaje.url} target="blank_">Descargar Documento</a>;
            }
        }
        //MENSAJE DE AUDIO            
        else if (message.mensaje.type === "audio") {

            mensaje = (
                <div>  <p>
                    <audio controls>
                        <source src={message.mensaje.url}></source>
                    </audio>
                </p>
                </div>
            );
        }
        //MENSAJE DE VIDEO
        else if (message.mensaje.type === "video") {
            mensaje = (
                <div>  <p>
                    <video width="320" height="240" controls>
                        <source src={message.mensaje.url}></source>
                    </video>
                </p>
                </div>
            );
        }
        //MENSAJE DE QUICK REPLYS
        else if (message.mensaje.type === "quick_reply") {
            mensaje = (
                <div>
                    <div className="quick_reply">
                        <div className="titulo_quick">{message.mensaje.title} </div>
                        <div className="subtitulo_quick">{message.mensaje.subtitle} </div>
                        <div className="buttons_quick">
                            {message.mensaje.options && message.mensaje.options.map((item, j) => {
                                return <div className="button_quick" key={j}>
                                    {item.label}
                                </div>
                            })
                            }
                        </div>
                    </div>
                </div>
            );
        }
        //MENSAJE DE LIST de whatsapp
        else if (message.mensaje.type === "list") {
            mensaje = (
                <div>
                    <div className="list">
                        <div className="titulo_list">{message.mensaje.title} </div>
                        <div className="body_list">{message.mensaje.body} </div>
                        <div className="opciones_list">
                            {message.mensaje.items && message.mensaje.items.map((item, j) => {
                                return <div className="opcion_list" key={j}>
                                    <div className="option-title">{item.title}</div>
                                    <div className="option-subtitle">
                                        {item.subtitle}
                                    </div>
                                    {item.options && item.options.map((opt, j) => {
                                        return <div className="det_list" key={j}>
                                            <div className="option-title">{opt.title}</div>  <div>{opt.description}</div>
                                        </div>
                                    })
                                    }
                                </div>
                            })
                            }
                        </div>
                    </div>
                </div>
            );
        }//MENSJAE DE TIPO BUTTON_REPLY (respuesta a un quick reply)
        else if (message.mensaje.type === "button_reply") {
            mensaje = <p> {message.mensaje.parentText}</p>;
        }//MENSJAE DE TIPO BUTTON_REPLY (respuesta a un quick reply)
        else if (message.mensaje.type === "list_reply") {
            mensaje = <p> {message.mensaje.parentText}</p>;
        }
        
        return (
            <div key={`msg_${j}`} className={clase} >
                <div className={`${clase2} card-${message.mensaje.type}`}>
                    <div className="received_withd_msg" >
                        <span className="time_date"> {from} dice:</span>
                        {mensaje}
                        <span className="time_date mt-0"> {fecha} </span>
                        <div ref={el => { this.el = el; }} />
                    </div>
                </div>
            </div>
        )

    }
        }    
        //UBICACION
        // CONTACTO
        

    render() {
        const { mensajes } = this.props;        

        return (
            <div className="card-body">
                <div className="mesgs pl-0">
                    <ScrollToBottom className="msg_history scroll">
                        {mensajes &&
                            mensajes.map((item, j) => {

                                { return this.renderMessage(item.data(), j) }

                            })}
                    </ScrollToBottom>
                </div>
            </div>
        )
    }
}
