import React, { Component } from "react";
//import "./style.css";
import { connect } from "react-redux";

import CustomForm from "../../components/customform/customform";

import CustomReduxForm from "../../components/materialform/customform";

import { getAuthForm, getSelectedForm, getSelectedTab, getMessage, getAuthError, getLogSuccess, getLogo, getRecoveryPass } from "./selectors";
import { Alert } from 'react-bootstrap';


import {
    AUTH_FORMS,
    UPDATE_FIELD_ATTRIBUTE,
    getLoginButton,
    AUTH_LOAD_PARAMS,
    SELECT_TAB,
    TAB_LOG_IN,
    LOG_USER,
    FORM_LOGIN_NAME,
    RECOVERY_PASS,
    authFormFun,
} from './constants';

import { Link, Redirect } from "react-router-dom";
import { getFromSession } from "../../controller/session";
import ComponentChange from './componentChange'


class ComponentAuth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            path: false,
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { loadCompany, urlParams, logSuccess } = this.props;
        loadCompany(urlParams);

        const company = getFromSession("company");
        const currentUser = getFromSession("currentUser");
        console.log("COMPANYY componente", company)

        // console.log("logSuccess componente", logSuccess)
        // console.log("currentUser componente", currentUser)

        if (company) {

            if (!company.cuenta_nueva) {
                // console.log("agent panel")
                this.setState({ path: '/agentPanel' });
            } else {
                //  console.log("inicio")
                this.setState({ path: '/inicio' });
            }
        } else {
            this.setState({ path: '/' });
        }

    }

    componentDidUpdate(prevProps) {
        const { logSuccess } = this.props;
        if (sessionStorage.getItem('inSession') === '1') {
            sessionStorage.removeItem('inSession')
            console.log("logSuccess did update", logSuccess)
            const company = getFromSession("company");
            console.log("company did update", company)
            if (company) {
                if (!company.cuenta_nueva) {
                    //         console.log("agent panel")
                    this.setState({ path: '/agentPanel' });
                } else {
                    //       console.log("inicio")
                    this.setState({ path: '/inicio' });
                }
            } else {
                this.setState({ path: '/' });
            }

        }

    }




    handleClick() {
        // console.log(selectedTab);
        this.props.history.push('/agentPanel');
        // console.log(this.props);
    }

    validations = (values) => {
        const errors = {}

        const requiredFields = [
            'company',
            'password',
            'login',
        ]

        requiredFields.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Obligatorio'
            }
        })
        if (
            values.login &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)
        ) {
            errors.login = 'Correo inválido'
        }

        return errors
    }

    render() {
        const {
            updateAttributes,
            selectedForm,
            tabSelected,
            message,
            error,
            logUser,
            logSuccess,
            urlParams,
            recoveryPass,
            openRecoveryPass,
            handleSafe,
            getAuthForm,
            logo
        } = this.props;

        const currentUser = getFromSession("currentUser");


        return (
            tabSelected === `tab-${TAB_LOG_IN}` ?
                <div>

                    <div className="App auth-container">
                        {openRecoveryPass ? <ComponentChange /> :
                            logSuccess && currentUser ? <Redirect to={this.state.path} /> :
                                <div className='auth-list_wrapper'>
                                    {message ?
                                        <React.Fragment>
                                            {error
                                                ? <Alert className="alert danger" variant="danger">{message}</Alert>
                                                : <Alert className="alert success" variant="success">{message}</Alert>
                                            }
                                        </React.Fragment>
                                        : ''
                                    }

                                    <div className="card-header text-center">
                                        {urlParams && urlParams.company
                                            ?
                                            <img className="logo-img" src={logo} alt="logo" />
                                            : <img className="logo-img" src="./assets/images/logo2.png" alt="logo" />
                                        }

                                        <span className="splash-description">Por favor indica tu cuenta de empresa, usuario y clave para iniciar sesión.</span>
                                    </div>
                                    <div className="splash-container">
                                        <div className="card">
                                            <div className="card-body"  >
                                                <CustomReduxForm
                                                    submitable={false}
                                                    handleSafe={handleSafe}
                                                    items={authFormFun(handleSafe)}//{AUTH_FORMS} 
                                                    formName={FORM_LOGIN_NAME}
                                                    handleSubmit={() => logUser(selectedForm)}
                                                    buttons={getLoginButton(() => logUser(selectedForm))}
                                                    className="splash-login"
                                                    validations={this.validations}
                                                    aditionalElement={<p>¿Aún no estas registrado? <a href={process.env.REACT_APP_REGISTRO}><u><font face="Arial Black">Crea una cuenta</font></u></a></p>}
                                                >

                                                </CustomReduxForm>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        false &&
                                        <CustomForm
                                            /* FORMULARIO VIEJO, SI SEQUIERE ACTIVAR REMOVER EL FALSE */
                                            formName="authForm"
                                            items={AUTH_FORMS}
                                            /* formTitle = "Log in" */
                                            values={selectedForm}
                                            updateAction={updateAttributes}
                                            buttons={getLoginButton(() => logUser(selectedForm))}
                                            style="splash-container hidden"
                                            bodyStyle="body"
                                            styleFormgroup="group"
                                        />
                                    }
                                </div>
                        }
                    </div></div>
                : ''
        );
    }
}

const mapStateToProps = state => {
    return {
        logSuccess: getLogSuccess(state),
        authForm: getAuthForm(state),
        selectedForm: getSelectedForm(state),
        tabSelected: getSelectedTab(state),
        message: getMessage(state),
        error: getAuthError(state),
        logo: getLogo(state),
        openRecoveryPass: getRecoveryPass(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        selectTab: (value) => dispatch({ type: SELECT_TAB, value }),
        logUser: (value) => {
            dispatch({ type: LOG_USER, value })
        },
        recoveryPass: (value) => dispatch({ type: RECOVERY_PASS, value }),
        handleSafe: (value) => dispatch({ type: RECOVERY_PASS, value }),
        loadCompany: (value) => dispatch({ type: AUTH_LOAD_PARAMS, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps,
)(ComponentAuth);