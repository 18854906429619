import { getFormValues, } from 'redux-form'
import {
     FORM_USUARIOS_NAME,
     FORM_CONF_NAME,
     FORM_REGHOURS_NAME,
     FORM_WEEKHOURS_NAME,
     FORM_SELECT_DIA,
     FORM_HABILIDADES_NAME
} from './constants';


export const getSelectedForm = (state) => state.localReducer.selectedForm;
export const getFaqs = (state) => state.localReducer.faqsDisplay;
export const updateTable = (state) => state.localReducer.getFaqs;
export const ocultarModal = (state) => state.localReducer.ocultarModal;
export const getTemas = (state) => state.localReducer.temasDisplay;
export const updateTableTemas = (state) => state.localReducer.getTemas;
export const opcionesNivel1 = (state) => state.localReducer.opcionesNivel1;
export const showToast = (state) => state.localReducer.showToast;
export const toastDetail = (state) => state.localReducer.toastDetail;
export const loadingTemas = (state) => state.localReducer.spinnerTemas;
export const getUsuarios = (state) => state.localReducer.usuariosDisplay;
export const getHabilidades = (state) => state.localReducer.habilidades;
export const updateTableUsuarios = (state) => state.localReducer.getUsuarios;
export const getSelectedReduxForm = (state) => getFormValues(FORM_USUARIOS_NAME)(state);
export const getSelectedReduxFormConf = (state) => getFormValues(FORM_CONF_NAME)(state);
export const getSelectedReduxFormRegHours = (state) => getFormValues(FORM_REGHOURS_NAME)(state);
export const getSelectedReduxFormWeekendHours = (state) => getFormValues(FORM_WEEKHOURS_NAME)(state);
export const getSelectedFormDiaEspecifico = (state) => getFormValues(FORM_SELECT_DIA)(state);
export const getSelectedReduxFormHabilidad = (state) => getFormValues(FORM_HABILIDADES_NAME)(state);
export const abrirModalEditar = (state) => state.localReducer.abrirModalEditar;
export const tituloModal = (state) => state.localReducer.tituloModal;
export const disabledEmailField = (state) => state.localReducer.disabledEmailField;
export const abrirModalBorrar = (state) => state.localReducer.abrirModalBorrar;
export const uidUser = (state) => state.localReducer.uidUser;
export const existeCorreo = (state) => state.localReducer.existeCorreo;
export const message = (state) => state.localReducer.message;
export const getConfVariables = (state) => state.localReducer.confVariables;
export const getDataConfig = (state) => state.localReducer.dataConfig;
export const abrirModalEditarConf = (state) => state.localReducer.abrirModalEditarConf;
export const updateTableConf = (state) => state.localReducer.getConf;
export const updateHorario = (state) => state.localReducer.updateHorario;

export const modalHabilidades = (state) => state.localReducer.modalHabilidades;
export const abrirModalEditarHabilidad = (state) => state.localReducer.abrirModalEditarHabilidad;
export const abrirModalEliminarHabilidad = (state) => state.localReducer.abrirModalEliminarHabilidad;
export const idDocHabilidad = (state) => state.localReducer.idDocHabilidad;
export const nombreHabilidad = (state) => state.localReducer.nombreHabilidad;
export const getValuesInitialFormWeb = (state) => state.localReducer.valuesInitialFormWeb;
export const getValuesInitialDocument = (state) => state.localReducer.valuesInitialDocument;
export const getUpdateClientForm = (state) => state.localReducer.updateClientForm;

export const getHorario = (state) => state.localReducer.horario;
export const getConfWebClient = (state) => state.localReducer.clientFormEnabled;
export const getInitialValuesEncuesta = (state) => state.localReducer.initialValuesEncuesta;
export const getDataSelect = (state) => state.localReducer.dataSelect;


export const encuestaActiva = (state) => state.localReducer.encuestaActiva;

export const getManageSpinerState = (state) => state.localReducer.manageSpinerState;



