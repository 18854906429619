import "./style.css";

import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";
import CustomForm from "../../components/customform/customform";
import {
    getFaqs,
    getSelectedForm,
    updateTable,
    ocultarModal,
    toastDetail,
    showToast

} from "./selector";
import {
    AGREGAR_FAQ,
    BORRAR_FAQ,
    EDITAR_FAQ,
    GET_FAQS,
    UPDATE_FIELD_ATTRIBUTE,

} from "./constants";
import { Card } from 'primereact/card';

import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';



class FaqsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null,
            openModal: false,
            openModalBorrar: false,
            faqItem: false,
            displayButton: false,
            isNewForm: false,
            pregunta: false,
            respuesta: false,
            titulo: false
        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.abrirModal = this.abrirModal.bind(this);
        this.abrirModalBorrar = this.abrirModalBorrar.bind(this);
        this.ocultarModal = this.ocultarModal.bind(this);
        this.abrirModalNuevo = this.abrirModalNuevo.bind(this);
    }

    componentWillMount() {
        const { listarFaqs } = this.props;
        listarFaqs();

    }

    componentDidUpdate(prevProps) {
        const { updateTable, listarFaqs, ocultarModal, toastDetail, showToast } = this.props;

        if (showToast) {
            this.toast.show({ severity: toastDetail.severity, summary: toastDetail.summary, detail: toastDetail.detail });
        }

        if (this.props.selectedForm && prevProps.selectedForm !== this.props.selectedForm && prevProps.selectedForm
            && this.props.selectedForm !== {}) {
            this.setState({
                displayButton: true
            })
        }

        if (updateTable) {
            listarFaqs();

        }

        if (ocultarModal) {
            this.ocultarModal();
            listarFaqs();
        }
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Añadir faq" icon="pi pi-plus" className="p-button-help" onClick={() => this.abrirModalNuevo()} />
            </React.Fragment>
        )
    }

    abrirModalNuevo() {
        this.setState({
            displayButton: false,
            openModal: true,
            isNewForm: true,
            pregunta: false,
            respuesta: false,
            titulo: 'Añadir nuevo elemento'
        });
    }

    abrirModal(rowData) {
        console.log('rowDataEDIT', rowData)
        this.setState({
            displayButton: false,
            openModal: true,
            faqItem: { ...rowData },
            pregunta: rowData.pregunta,
            respuesta: rowData.respuesta,
            isNewForm: false,
            titulo: 'Editar elemento'
        });
    }
    abrirModalBorrar(rowData) {
        this.setState({
            openModalBorrar: true,
            faqItem: { ...rowData },
        });
    }

    ocultarModal() {
        this.setState({
            openModal: false,
            openModalBorrar: false,
        });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" title='Editar' className="p-button-rounded p-button-info p-mr-1" onClick={() => this.abrirModal(rowData)} />
                <Button icon="pi pi-trash" title='Borrar' className="p-button-rounded p-button-help p-mr-1" onClick={() => this.abrirModalBorrar(rowData)}></Button>
            </React.Fragment>
        );
    }

    renderForm(faqItem, itemsForm) {
        const { editarFaq, agregarFaq } = this.props;
        let idFaq = faqItem.id
        let action;
        let flag = this.state.isNewForm

        if (flag) {
            action = agregarFaq
        } else {
            action = editarFaq
        }
        return (
            <div>{itemsForm(idFaq, action)}</div>
        )
    }


    renderBorrarDialog(faqItem, borrarFaq) {
        let idFaq = faqItem.id
        return (
            <div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem', 'margin-left': '2rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2rem' }}></i>
                    <div className="p-text-normal" style={{ 'margin-left': '1rem' }}>¿Está seguro que desea borrar el elemento?</div>
                </div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem' }}>
                    <div style={{ 'margin-left': 'auto', 'margin-right': '2rem' }}>
                        <Button label="Si" onClick={() => borrarFaq(idFaq)} ></Button>
                        <Button label="No" onClick={() => this.ocultarModal()} style={{ 'margin-left': '1rem' }} className="p-button-secondary"></Button>
                    </div>
                </div>
            </div>
        )
    }

    renderModal(open, body, onHide, headerTitle, id) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw" }}
            >
                {body}
            </Dialog>
        );
    }

    render() {
        const {
            selectedForm,
            updateAttributes,
            faqs,
            borrarFaq,

        } = this.props;

        let FORM_FAQ = [
            {
                name: "pregunta",
                placeholder: "Escriba la pregunta",
                value: this.state.pregunta,
                options: [],
                disable: false,
                typeForm: 'normal-text-area',
                labelClass: true,
                display: true,
                label: 'Pregunta'
            },
            {
                name: "respuesta",
                placeholder: "Escriba la respuesta",
                value: this.state.respuesta,
                options: [],
                disable: false,
                typeForm: 'normal-text-area',
                labelClass: true,
                display: true,
                label: 'Respuesta'

            }
        ]

        let getSaveButton = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary",
                    action: false,
                    style: "primary",
                    onClick: handlerSafe,
                    display: this.state.displayButton
                }
            ]
        }

        let customForm = (idFaq = false, action) => {
            return [
                <CustomForm
                    formName="formFaq"
                    items={FORM_FAQ}
                    values={selectedForm}
                    updateAction={updateAttributes}
                    style="splash-container-search"
                    positionButton='fixed-button'
                    buttons={getSaveButton(() => action({ values: selectedForm, 'idFaq': idFaq }))

                    }
                    onEnterFunction={false}
                >
                </CustomForm>
            ]
        }


        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })} />
                </span>
            </div>
        );

        return (
            <React.Fragment>
                <Toast ref={(el) => this.toast = el} />
                <Card className='faqs' >
                    <Toast ref={(el) => this.toast = el} />
                    {this.renderModal(
                        this.state.openModal,
                        this.renderForm(this.state.faqItem, customForm),
                        this.ocultarModal,
                        this.state.titulo,
                        "editarFaq"
                    )}
                    {this.renderModal(
                        this.state.openModalBorrar,
                        this.renderBorrarDialog(this.state.faqItem, borrarFaq),
                        this.ocultarModal,
                        "Confirmar",
                        "confirmarBorrado"
                    )}

                    <div className='p-card-title'>Preguntas frecuentes</div>
                    <div className='p-card-content'>
                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>

                        <DataTable columnResizeMode="fit" value={faqs} className="p-datatable-gridlines" globalFilter={this.state.globalFilter}
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} dataKey="id"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} ">
                            <Column field="pregunta" header="Pregunta"></Column>
                            <Column field="respuesta" header="Respuesta"></Column>
                            <Column headerStyle={{ width: '100px' }} header="Acciones" body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedForm: getSelectedForm(state),
        faqs: getFaqs(state),
        updateTable: updateTable(state),
        ocultarModal: ocultarModal(state),
        toastDetail: toastDetail(state),
        showToast: showToast(state),
    };
};

const mapDispachToProps = dispatch => {

    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        listarFaqs: (value) => dispatch({ type: GET_FAQS, value }),
        editarFaq: (value) => dispatch({ type: EDITAR_FAQ, value }),
        borrarFaq: (value) => dispatch({ type: BORRAR_FAQ, value }),
        agregarFaq: (value) => dispatch({ type: AGREGAR_FAQ, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(FaqsComponent);