export const getSelectedForm = (state) => state.selectedForm;
export const mostrarRangosFecha = (state) => state.mostrarRangosFecha;


//----------GRÁFICAS---------------
export const convsEnCurso = (state) => state.convsEnCurso;
export const totalConvs = (state) => state.totalConvs;

export const usuariosEnEspera = (state) => state.usuariosEnEspera;
export const totalUsuarios = (state) => state.totalUsuarios;

export const convsCerradas = (state) => state.convsCerradas;
export const totalConvsCerradas = (state) => state.totalConvsCerradas;

export const carga = (state) => state.carga;
export const cargaTotal = (state) => state.cargaTotal;

export const tmpEsperaProm = (state) => state.tmpEsperaProm;
export const promedioTmpEsp = (state) => state.promedioTmpEsp;

export const tmpRespuesta = (state) => state.tmpRespuesta;
export const promedioTmpResp = (state) => state.promedioTmpResp;
//-----------------------------------------------------------------
export const convsResueltas = (state) => state.convsResueltas;
export const porcSatisfaccion = (state) => state.porcSatisfaccion;

export const infoAgentes = (state) => state.infoAgentes;
export const isUpdAgentes = (state) => state.updAgentes;

export const datosConvsAbandonadas = (state)=>state.datosConvsAbandonadas;
export const ocultarGrafica = (state)=>state.ocultarGrafica;

export const estadisticaEncuesta = (state) => state.estadisticaEncuesta;



