import React, { Component } from "react";
//import "./style.css";
import { connect } from "react-redux";
import { getMenuItems, getMenuItemsChatBot, getUsers, selectPlanes, selectPlanesList } from "./selectors";
import {
    ADQUIRIR_PRODUCTO,
    GET_PLANES,
    GET_USERS, LOAD_MENU
} from './constants';
import SideBar from "../../components/sidebar/sidebar";
import { getFromSession } from "../../controller/session";
import { Box, Tabs, Tab } from "@material-ui/core";
import ComponentMensual from "./mensual";
import ComponentAnual from "./anual";
import { useLocation } from 'react-router-dom';
import ComponentConfPagos from "./pagos/confirmarPagos";

class ComponentPlanes extends Component {


    constructor(props) {
        super(props);
        const { items, onLogout, plan } = this.props;
        const tipoPlan = plan ? 'anual' : 'mensual'
        this.state = {
            activePath: '/',
            tipo: tipoPlan,
            confirmar: false,
            cardPlanes: false,
            plan: false,
            planesState: false,
            planSelector: false,
            path: '/autobot',
            id: false
        }
    }
    onButonMes = (e, newValue) => {
        this.setState({ tipo: newValue });
        const { getPlanes, finSuscribcionBot, planesSelectorOCR, planesSelectorBot, getPlanesOCR, getPlanesBot, finSuscribcionOCR } = this.props;
        if(finSuscribcionBot){
            getPlanesBot(newValue)
            this.setState({ planesState: planesSelectorBot });
        }
        if(finSuscribcionOCR){
            getPlanesOCR(newValue)
            this.setState({ planesState: planesSelectorOCR });
        }
        else {
            console.log("onBotonMes")
            getPlanes(newValue);
        }
    }

    onPagosHandler = (newValue) => {
        console.log("NEWVALUE", newValue)
        this.setState({ id: newValue });
        this.setState({ confirmar: true });
    }

    actionCard = (itemId) => {

        console.log("item.id", itemId);
        if (itemId === '00001') {
            this.setState({ plan: 'Gratis' });
        }
        if (itemId === '00002') {
            this.setState({ plan: 'Pymes' });
        }
    }
    onBackToCard = () => {
        this.setState({ confirmar: false });

    }

    componentWillMount() {
        const { loadMenuChatbot, getPlanesOCR, finSuscribcionOCR, getPlanes, planesSelectorBot, planes, finSuscribcionBot, getPlanesBot } = this.props;
        loadMenuChatbot();
        if (finSuscribcionBot) {
            console.log("prueba***Gratis")

            getPlanesBot('mensual', 'autoBot');

            console.log("planesSelectorBot", planesSelectorBot)
            this.setState({ cardPlanes: planesSelectorBot });


        }else if(finSuscribcionOCR){
            console.log("prueba***Gratis")

            getPlanesOCR('mensual', 'autoBot');

            console.log("planesSelectorBot", finSuscribcionOCR)
            this.setState({ cardPlanes: finSuscribcionOCR });
        } 
        else {
            
            console.log("CARD INICIO");

            setTimeout(() => {
                getPlanes('mensual');
                this.setState({ cardPlanes: planes });

            }, 500)
        }


    }


    render() {
        const {
            planes,
            planesSelectorBot,
            menuItemsChatBot,
            planesSelectorOCR,
            getTransaccionBot,
            getTransaccionOCR
        } = this.props;
        const user = getFromSession("currentUser");
        const company = getFromSession("company");

        return (
            <div className="App home-container-planes">
                {menuItemsChatBot && <SideBar logo={company.logo} items=''></SideBar>}
                {this.state.confirmar ?
                    <ComponentConfPagos
                        tipo={this.state.tipo}
                        getTransaccionBot={getTransaccionBot}
                        getTransaccionOCR={getTransaccionOCR}
                        id={this.state.id}
                        onBackToCard={this.onBackToCard}
                        plan={this.state.plan}
                    />
                    :
                    <div className='user-list_wrapper-planes'>
                        <Box sx={{ width: '100%' }}
                            variant="fullWidth"
                        >
                            <Tabs className="tabs-container"
                                value={this.state.tipo}
                                style={{ display: 'initial' }}
                                onChange={this.onButonMes}
                                centered
                            >
                                <Tab value="mensual" style={{ "fontSize": "24px", "font-weight": "bold" }}
                                    sx={{ width: 200 }}
                                    label="Mensual"

                                />
                                <Tab value="anual" style={{ "fontSize": "24px", "font-weight": "bold" }}
                                    sx={{ width: 200 }}
                                    label="Anual"

                                />
                            </Tabs>
                        </Box>
                        <div className="subs-containere">
                            {this.state.tipo === 'mensual' ?
                                <ComponentMensual
                                    planesMensuales={planes ? planes : planesSelectorBot ? planesSelectorBot : planesSelectorOCR  }
                                    actionCard={this.onPagosHandler}
                                    confirmar={this.state.confirmar}
                                /> :
                                <ComponentAnual
                                    planesAnual={planes ? planes : planesSelectorBot ? planesSelectorBot : planesSelectorOCR  }
                                    actionCard={this.onPagosHandler}
                                    confirmar={this.state.confirmar}
                                />}
                        </div>
                    </div>
                }


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsChatBot: getMenuItemsChatBot(state),
        planesSeleccionados: selectPlanes(state),
        planes: selectPlanesList(state)
    };

};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenu: (value) => dispatch({ type: LOAD_MENU, value }),
        loadMenuChatbot: (value) => dispatch({ type: LOAD_MENU, value }),
        adquirirProducto: (value) => dispatch({ type: ADQUIRIR_PRODUCTO, value }),
        getPlanes: (value, value1) => dispatch({ type: GET_PLANES, value, value1 }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentPlanes);