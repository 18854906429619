import {
    GET_FAQS,
    GET_FAQS_SUCCESS,
    UPDATE_FIELD_ATTRIBUTE,
    GET_FAQS_ERROR,
    EDITAR_FAQ,
    EDITAR_FAQ_ERROR,
    EDITAR_FAQ_SUCCESS,
    BORRAR_FAQ,
    BORRAR_FAQ_ERROR,
    BORRAR_FAQ_SUCCESS,
    AGREGAR_FAQ,
    AGREGAR_FAQ_SUCCESS,
    AGREGAR_FAQ_ERROR,

    GET_TEMAS,
    GET_TEMAS_ERROR,
    GET_TEMAS_SUCCESS,
    EDITAR_TEMA,
    EDITAR_TEMA_ERROR,
    EDITAR_TEMA_SUCCESS,
    BORRAR_TEMA,
    BORRAR_TEMA_ERROR,
    BORRAR_TEMA_SUCCESS,
    AGREGAR_TEMA,
    AGREGAR_TEMA_ERROR,
    AGREGAR_TEMA_SUCCESS,
    LIMPIAR_FORM_NIVEL2,
    LIMPIAR_FORM,
    LISTAR_USUARIOS,
    LISTAR_USUARIOS_SUCCESS,
    LISTAR_USUARIOS_ERROR,
    SELECT_ROW,
    ABRIR_MODAL_BORRAR,

    EDITAR_USUARIO,
    EDITAR_USUARIO_SUCCESS,
    EDITAR_USUARIO_ERROR,
    GET_HABILIDADES,
    GET_HABILIDADES_SUCCESS,
    GET_HABILIDADES_ERROR,
    CREAR_USUARIO,
    CREAR_USUARIO_ERROR,
    CREAR_USUARIO_SUCCESS,
    SELECT_ROW_SUCCESS,
    ABRIR_MODAL_USUARIOS,
    CERRAR_MODAL_USUARIOS,
    ELIMINAR_USUARIO_SUCCESS,
    ELIMINAR_USUARIO_ERROR,
    ELIMINAR_USUARIO,
    ABRIR_MODAL_BORRAR_SUCCESS,
    VALIDAR_CORREO,
    VALIDAR_CORREO_SUCCESS,
    CLEAR_PASSWORD,
    VARIABLES_CONFIGURACION,
    VARIABLES_CONFIGURACION_SUCCESS,
    VARIABLES_CONFIGURACION_ERROR,
    EDITAR_CONF_SUCCESS,
    EDITAR_CONF_ERROR,
    EDITAR_CONF,
    SELECCIONAR_CONF,
    SELECCIONAR_CONF_SUCCESS,
    SELECCIONAR_CONF_ERROR,
    CERRAR_MODAL_CONF,
    CONSULTAR_HORARIO,
    CONSULTAR_HORARIO_SUCCESS,
    CONSULTAR_HORARIO_ERROR,
    UPDATE_HORARIO,
    HORARIO_HABIL_DIAS,
    HORARIO_HABIL_DIAS_SUCCESS,
    HORARIO_HABIL_DIAS_ERROR,
    ABRIR_MODAL_HABILIDADES,
    EDITAR_HABILIDAD,
    EDITAR_HABILIDAD_SUCCESS,
    EDITAR_HABILIDAD_ERROR,
    SELECT_ROW_HABILIDAD,
    SELECT_ROW_HABILIDAD_SUCCESS,
    SELECT_ROW_HABILIDAD_ERROR,
    CERRAR_MODAL_EDITAR_HABILIDAD,
    CREAR_HABILIDAD,
    CREAR_HABILIDAD_ERROR,
    MODAL_ELIMINAR_HABILIDAD,
    CREAR_HABILIDAD_SUCCESS,
    CERRAR_MODAL_ELIMINAR_HABILIDAD,
    ELIMINAR_HABILIDAD,
    ELIMINAR_HABILIDAD_SUCCESS,
    ELIMINAR_HABILIDAD_ERROR,
    CLIENT_FORM_WEB,
    CLIENT_FORM_WEB_SUCCESS,
    GUARDAR_CLIENT_FORM_WEB,
    GUARDAR_CLIENT_FORM_WEB_SUCCESS,
    GUARDAR_CLIENT_FORM_WEB_ERROR,
    CLIENT_FORM_WEB_ERROR,
    ENCUESTA_FORM_WEB,
    ENCUESTA_FORM_WEB_SUCCESS,
    ENCUESTA_FORM_WEB_ERROR,
    GUARDAR_ENCUESTA,
    GUARDAR_ENCUESTA_SUCCESS,
    GUARDAR_ENCUESTA_ERROR,
    GUARDAR_DOCUMENT_FORM_WEB,
    GUARDAR_DOCUMENT_FORM_WEB_SUCCESS,
    GUARDAR_DOCUMENT_FORM_WEB_ERROR,
    DOCUMENT_FORM_SUCCESS,
    DOCUMENT_FORM_ERROR,
    DOCUMENT_FORM,
    CRUCE_FORM,
    CRUCE_FORM_SUCCESS,
    CRUCE_FORM_ERROR,
    GUARDAR_CRUCE_FORM,
    GGUARDAR_CRUCE_FORM_SUCCESS,
    GUARDAR_CRUCE_FORM_ERROR,
    GUARDAR_CRUCE_FORM_SUCCESS,
    MANAGE_SPINER

} from './constants'

export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}


export function listarFaqs(payload) {
    return {
        type: GET_FAQS,
        payload
    }
}

export function listarFaqsSucces(payload) {
    return {
        type: GET_FAQS_SUCCESS,
        payload
    }
}

export function listarFaqsError(payload) {
    return {
        type: GET_FAQS_ERROR,
        payload
    }
}

export function editarFaq(payload) {
    return {
        type: EDITAR_FAQ,
        payload
    }
}

export function editarFaqSuccess(payload) {
    return {
        type: EDITAR_FAQ_SUCCESS,
        payload
    }
}

export function editarFaqError(payload) {
    return {
        type: EDITAR_FAQ_ERROR,
        payload
    }
}

export function borrarFaq(payload) {
    return {
        type: BORRAR_FAQ,
        payload
    }
}

export function borrarFaqSuccess(payload) {
    return {
        type: BORRAR_FAQ_SUCCESS,
        payload
    }
}

export function borrarFaqError(payload) {
    return {
        type: BORRAR_FAQ_ERROR,
        payload
    }
}

export function agregarFaq(payload) {
    return {
        type: AGREGAR_FAQ,
        payload
    }
}

export function agregarFaqSuccess(payload) {
    return {
        type: AGREGAR_FAQ_SUCCESS,
        payload
    }
}

export function agregarFaqError(payload) {
    return {
        type: AGREGAR_FAQ_ERROR,
        payload
    }
}

export function listarTemas(payload) {
    return {
        type: GET_TEMAS,
        payload
    }
}

export function listarTemasSuccess(payload) {
    return {
        type: GET_TEMAS_SUCCESS,
        payload
    }
}

export function listarTemasError(payload) {
    return {
        type: GET_TEMAS_ERROR,
        payload
    }
}

export function borrarTema(payload) {
    return {
        type: BORRAR_TEMA,
        payload
    }
}

export function borrarTemaSuccess(payload) {
    return {
        type: BORRAR_TEMA_SUCCESS,
        payload
    }
}

export function borrarTemaError(payload) {
    return {
        type: BORRAR_TEMA_ERROR,
        payload
    }
}

export function editarTema(payload) {
    return {
        type: EDITAR_TEMA,
        payload
    }
}

export function editarTemaSuccess(payload) {
    return {
        type: EDITAR_TEMA_SUCCESS,
        payload
    }
}

export function editarTemaError(payload) {
    return {
        type: EDITAR_TEMA_ERROR,
        payload
    }
}

export function agregarTema(payload) {
    return {
        type: AGREGAR_TEMA,
        payload
    }
}

export function agregarTemaSuccess(payload) {
    return {
        type: AGREGAR_TEMA_SUCCESS,
        payload
    }
}

export function agregarTemaError(payload) {
    return {
        type: AGREGAR_TEMA_ERROR,
        payload
    }
}

export function limpiarFormNivel1(payload) {
    return {
        type: LIMPIAR_FORM_NIVEL2,
        payload
    }
}

export function limpiarForm(payload) {
    return {
        type: LIMPIAR_FORM,
        payload
    }
}

export function listarUsuarios(payload) {
    return {
        type: LISTAR_USUARIOS,
        payload
    }
}

export function listarUsuariosSuccess(payload) {
    return {
        type: LISTAR_USUARIOS_SUCCESS,
        payload
    }
}
export function listarUsuariosError(payload) {
    return {
        type: LISTAR_USUARIOS_ERROR,
        payload
    }
}

export function editarUsuario(payload) {
    return {
        type: EDITAR_USUARIO,
        payload
    }
}

export function editarUsuarioSuccess(payload) {
    return {
        type: EDITAR_USUARIO_SUCCESS,
        payload
    }
}
export function editarUsuarioError(payload) {
    return {
        type: EDITAR_USUARIO_ERROR,
        payload
    }
}

export function crearUsuario(payload) {
    return {
        type: CREAR_USUARIO,
        payload
    }
}

export function crearUsuarioSuccess(payload) {
    return {
        type: CREAR_USUARIO_SUCCESS,
        payload
    }
}
export function crearUsuarioError(payload) {
    return {
        type: CREAR_USUARIO_ERROR,
        payload
    }
}

export function traerHabilidades(payload) {
    return {
        type: GET_HABILIDADES,
        payload
    }
}



export function traerHabilidadesSuccess(payload) {
    return {
        type: GET_HABILIDADES_SUCCESS,
        payload
    }
}
export function traerHabilidadesError(payload) {
    return {
        type: GET_HABILIDADES_ERROR,
        payload
    }
}

export function seleccionarFila(payload) {
    return {
        type: SELECT_ROW,
        payload
    }
}

export function seleccionarFilaSuccess(payload) {
    return {
        type: SELECT_ROW_SUCCESS,
        payload
    }
}

export function abrirModalEditar(payload) {
    return {
        type: ABRIR_MODAL_USUARIOS,
        payload
    }
}

export function cerrarModalEditar(payload) {
    return {
        type: CERRAR_MODAL_USUARIOS,
        payload
    }
}

export function eliminarUsuario(payload) {
    return {
        type: ELIMINAR_USUARIO,
        payload
    }
}
export function eliminarUsuarioSuccess(payload) {
    return {
        type: ELIMINAR_USUARIO_SUCCESS,
        payload
    }
}
export function eliminarUsuarioError(payload) {
    return {
        type: ELIMINAR_USUARIO_ERROR,
        payload
    }
}

export function abrirModalBorrar(payload) {
    return {
        type: ABRIR_MODAL_BORRAR,
        payload
    }
}

export function abrirModalBorrarSuccess(payload) {
    return {
        type: ABRIR_MODAL_BORRAR_SUCCESS,
        payload
    }
}

export function validarCorreoExistente(payload) {
    return {
        type: VALIDAR_CORREO,
        payload
    }
}

export function validarCorreoExistenteSuccess(payload) {
    return {
        type: VALIDAR_CORREO_SUCCESS,
        payload
    }
}

export function clearPasswordField() {
    return {
        type: CLEAR_PASSWORD
    }
}

export function traerVariablesConfiguracion() {
    return {
        type: VARIABLES_CONFIGURACION
    }
}


export function traerVariablesConfiguracionSuccess(payload) {
    return {
        type: VARIABLES_CONFIGURACION_SUCCESS,
        payload
    }
}

export function traerVariablesConfiguracionError(payload) {
    return {
        type: VARIABLES_CONFIGURACION_ERROR,
        payload
    }
}

export function editarConfiguracion(payload) {
    return {
        type: EDITAR_CONF,
        payload
    }
}

export function editarConfiguracionSuccess(payload) {
    return {
        type: EDITAR_CONF_SUCCESS,
        payload
    }
}

export function editarConfiguracionError(payload) {
    return {
        type: EDITAR_CONF_ERROR,
        payload
    }
}
export function seleccionarConf(payload) {
    return {
        type: SELECCIONAR_CONF,
        payload
    }
}
export function seleccionarConfSuccess(payload) {
    return {
        type: SELECCIONAR_CONF_SUCCESS,
        payload
    }
}

export function seleccionarConfError(payload) {
    return {
        type: SELECCIONAR_CONF_ERROR,
        payload
    }

}

export function cerrarModalEditarConf(payload) {
    return {
        type: CERRAR_MODAL_CONF,
        payload
    }
}

export function anadirDiasHorario(payload) {
    return {
        type: HORARIO_HABIL_DIAS,
        payload
    }
}

export function anadirDiasHorarioSuccess(payload) {
    return {
        type: HORARIO_HABIL_DIAS_SUCCESS,
        payload
    }
}

export function anadirDiasHorarioError(payload) {
    return {
        type: HORARIO_HABIL_DIAS_ERROR,
        payload
    }
}

export function consultarHorario(payload) {
    return {
        type: CONSULTAR_HORARIO,
        payload
    }
}

export function consultarHorarioSuccess(payload) {
    return {
        type: CONSULTAR_HORARIO_SUCCESS,
        payload
    }
}
export function consultarHorarioError(payload) {
    return {
        type: CONSULTAR_HORARIO_ERROR,
        payload
    }
}

export function cambiarBanderUpdateHorario(payload) {
    return {
        type: UPDATE_HORARIO,
        payload
    }
}

export function abrirModalHabilidades(payload) {
    return {
        type: ABRIR_MODAL_HABILIDADES,
        payload
    }
}

export function cerrarModalEditarHabilidad(payload) {
    return {
        type: CERRAR_MODAL_EDITAR_HABILIDAD,
        payload
    }
}

export function cerrarModalEliminarHabilidad(payload) {
    return {
        type: CERRAR_MODAL_ELIMINAR_HABILIDAD,
        payload
    }
}

export function seleccionarFilaHabilidad(payload) {
    return {
        type: SELECT_ROW_HABILIDAD,
        payload
    }
}

export function seleccionarFilaHabilidadSuccess(payload) {
    return {
        type: SELECT_ROW_HABILIDAD_SUCCESS,
        payload
    }
}

export function seleccionarFilaHabilidadError(payload) {
    return {
        type: SELECT_ROW_HABILIDAD_ERROR,
        payload
    }
}

export function editarHabilidad(payload) {
    return {
        type: EDITAR_HABILIDAD,
        payload
    }
}

export function editarHabilidadSuccess(payload) {
    return {
        type: EDITAR_HABILIDAD_SUCCESS,
        payload
    }
}

export function editarHabilidadError(payload) {
    return {
        type: EDITAR_HABILIDAD_ERROR,
        payload
    }
}

export function crearHabilidad(payload) {
    return {
        type: CREAR_HABILIDAD,
        payload
    }
}

export function crearHabilidadSuccess(payload) {
    return {
        type: CREAR_HABILIDAD_SUCCESS,
        payload
    }
}

export function crearHabilidadError(payload) {
    return {
        type: CREAR_HABILIDAD_ERROR,
        payload
    }
}
export function abrirModalEliminarHabilidad(payload) {
    return {
        type: MODAL_ELIMINAR_HABILIDAD,
        payload
    }
}

export function eliminarHabilidad(payload) {
    return {
        type: ELIMINAR_HABILIDAD,
        payload
    }
}

export function eliminarHabilidadSuccess(payload) {
    return {
        type: ELIMINAR_HABILIDAD_SUCCESS,
        payload
    }
}

export function eliminarHabilidadError(payload) {
    return {
        type: ELIMINAR_HABILIDAD_ERROR,
        payload
    }
}

export function traerConfFormClienteWeb(payload) {
    return {
        type: CLIENT_FORM_WEB,
        payload
    }

}
export function traerConfFormClienteWebError(payload){
    return{
        type:CLIENT_FORM_WEB_ERROR,
        payload
    }
}
export function traerConfFormClienteWebSuccess(payload) {
    return {
        type: CLIENT_FORM_WEB_SUCCESS,
        payload
    }

}

export function traerConfFormDocument(payload) {
    return {
        type: DOCUMENT_FORM,
        payload
    }

}
export function traerConfFormDocumentError(payload){
    return{
        type:DOCUMENT_FORM_ERROR,
        payload
    }
}
export function traerConfFormDocumentSuccess(payload) {
    return {
        type: DOCUMENT_FORM_SUCCESS,
        payload
    }

}


export function guardarFormularioClienteWeb(payload) {
    return {
        type: GUARDAR_CLIENT_FORM_WEB,
        payload
    }

}

export function guardarFormularioClienteWebSuccess(payload) {
    return {
        type: GUARDAR_CLIENT_FORM_WEB_SUCCESS,
        payload
    }

}
export function guardarFormularioClienteWebError(payload) {
    return {
        type: GUARDAR_CLIENT_FORM_WEB_ERROR,
        payload
    }

}

export function guardarDocumentFormWeb(payload) {
    return {
        type: GUARDAR_DOCUMENT_FORM_WEB,
        payload
    }

}

export function guardarDocumentFormWebSuccess(payload) {
    return {
        type: GUARDAR_DOCUMENT_FORM_WEB_SUCCESS,
        payload
    }

}
export function guardarDocumentFormWebError(payload) {
    return {
        type: GUARDAR_DOCUMENT_FORM_WEB_ERROR,
        payload
    }

}




export function guardarCruceForm(payload) {
    return {
        type: GUARDAR_CRUCE_FORM,
        payload
    }

}

export function guardarCruceFormSuccess(payload) {
    return {
        type: GUARDAR_CRUCE_FORM_SUCCESS,
        payload
    }

}
export function guardarCruceFormError(payload) {
    return {
        type: GUARDAR_CRUCE_FORM_ERROR,
        payload
    }

}



export function traerConfEncuestaClienteWeb(payload){
    return{
        type:ENCUESTA_FORM_WEB,
        payload
    }
}
export function traerConfEncuestaClienteWebSuccess(payload){
    return{
        type:ENCUESTA_FORM_WEB_SUCCESS,
        payload
    }
}

export function traerConfEncuestaClienteWebError(payload){
    return{
        type:ENCUESTA_FORM_WEB_ERROR,
        payload
    }
}



export function traerConfCruce(payload){
    return{
        type:CRUCE_FORM,
        payload
    }
}
export function traerConfCruceSuccess(payload){
    return{
        type:CRUCE_FORM_SUCCESS,
        payload
    }
}

export function traerConfCruceError(payload){
    return{
        type:CRUCE_FORM_ERROR,
        payload
    }
}

export function guardarFormEncuestaClienteWeb(payload){
    return{
        type:GUARDAR_ENCUESTA,
        payload

    }
}


export function guardarFormEncuestaClienteWebSuccess(payload){
    return{
        type:GUARDAR_ENCUESTA_SUCCESS,
        payload

    }
}

export function guardarFormEncuestaClienteWebError(payload){
    return{
        type:GUARDAR_ENCUESTA_ERROR,
        payload

    }
}
export function manageSpiner(payload){
    return{
        type:MANAGE_SPINER,
        payload

    }
}