import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getListaPrueba = (state) => state.listaPrueba;
export const getMenuItemsChatBot = (state) => state.menuItemsChatBot;
export const getuploadImage = (state) => state.uploadImage;
export const selectGetBots = (state) => state.getBots;
export const selectGetIntents = (state) => state.getIntent;
export const selectIntent = (state) => state.editInten;
export const selectUploadIntent = (state) => state.uploadIntent;
export const selectDeleteAgent = (state) => state.deleteAgent;
export const selectDeleteIntentDialogflow = (state) => state.deleteIntentDialogflow;
export const selectUploadIcon = (state) => state.uploadIcon;
export const selectBotActivo = (state) => state.bot_activo;
export const selectListProjects = (state) => state.list_projects;
export const selectupdateWidget = (state) => state.updateWidget;
export const selectLoading = (state) => state.loading;
export const selectLoading_icon = (state) => state.loading_icon;

export const selectorShowToast = (state) => state.showToast;
export const selectorToastDetail = (state) => state.toastDetail;
export const selectorFinSuscribcion = (state) => state.finSubcribcion;
export const selectorPlanes = (state) => state.planes;







export const usersSelector = createStructuredSelector({
    users: getUsers,
});