import React, { Component, useContext } from "react";
import Container from "react-bootstrap/Container";
import ComponentEnviosMasivos from "./component";
import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { watchAgent } from "./saga";
import { Provider } from "react-redux";
import { reducer as formReducer } from "redux-form";
import { getFromSession } from "../../controller/session";
import { Redirect } from "react-router-dom";


const rootReducer = combineReducers({
    form: formReducer,
    localReducer:reducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(watchAgent);

export default class EnviosMasivosPage extends Component {
    render() {
      const currentUser = getFromSession("currentUser");
      return (
  
            <Provider store={store}>
                <Container className="main-wrapper home">
                  {currentUser ? (
                  <ComponentEnviosMasivos />
                ) : (
                    <Redirect to="/auth" />
                  )}
                </Container>
            </Provider>
      
  
      );
    }
  }