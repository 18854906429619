
import "./style.css";

import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";

import { Column } from 'primereact/column';

import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { getConsultaPaginada, getEnviosApi, getEnviosApiPaginados, getFiltroEnviosActivo, getlistaEnviosApiFiltrada } from "./selectors";
import { ENVIOS_INDIVIDUALES, FILTRAR_ENVIOS_API, PAGINAR_ENVIOS_INDIVIDUALES } from "./constants";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';


class EnviosIndividualesComponent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            globalFilter: null,
            basicFirst: 0,
            basicRows: 10,
            firstLastState: 2,
            listaPaginada: false
        };
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.onBasicFilterChange = this.onBasicFilterChange.bind(this);
    }

    paginarEnviosFiltrados(event) {
        const { listaFiltradaCompletaEnvios } = this.props
    
        let listaPaginada;
        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = listaFiltradaCompletaEnvios.slice(event.first, last)
        } else {
            listaPaginada = listaFiltradaCompletaEnvios.slice(event.first, event.rows)
        }
        //console.log("listaDestinatariosFiltradaCortada", listaPaginada);
        this.setState({
            listaPaginada: listaPaginada
        })
    }

    onBasicPageChange(event) {
  
        const {
            paginarEnviosApi, enviosApiPaginados, enviosApi, filtroBusquedaActivo

        } = this.props
      
        if (filtroBusquedaActivo) {
            this.paginarEnviosFiltrados(event);
        } else {
            let ultimoEnvio = false;
            let primerEnvio = false;
            let nextPage = this.state.firstLastState < event.first;

            this.setState({
                firstLastState: event.first,
                listaPaginada: false,
            });

            if (enviosApiPaginados) {
                if (enviosApiPaginados.length === event.rows && event.first > 0) {
                    //console.log('entro a paginar conversacionesPaginadas');
                    ultimoEnvio = enviosApiPaginados[event.rows - 1];
                    primerEnvio = enviosApiPaginados[0];
                } else {
                    //console.log('entro a ultima pagina');
                    ultimoEnvio = enviosApiPaginados[enviosApiPaginados.length - 1];
                    primerEnvio = enviosApiPaginados[0];
                }
            } else if (!enviosApiPaginados && enviosApi) {
                if (enviosApi.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimoEnvio = enviosApi[event.rows - 1];
                    primerEnvio = enviosApi[0];
                }
            }

            this.setState({
                basicFirst: event.first,
                basicRows: event.rows,
            });

            if (nextPage) {
                paginarEnviosApi({ event, "ultimoEnvio": ultimoEnvio, nextPage });
            } else {
                paginarEnviosApi({ event, "ultimoEnvio": primerEnvio, nextPage });
            }


        }

    }

    onBasicFilterChange = (text) => {
        console.log('event', text);
        const { filtrarEnviosPorTelefono } = this.props
        let textInput = text
        let numFilas = this.state.basicRows;
        filtrarEnviosPorTelefono({ textInput, numFilas });
    }




    componentWillMount() {
        const {
            obtenerEnviosApi

        } = this.props
        obtenerEnviosApi(this.state.basicRows);

    }

    componentDidUpdate(prevProps) {
        const {
            enviosApiPaginados, enviosApi, consultaPaginada, filtroBusquedaActivo,
            obtenerEnviosApi
        } = this.props

        const paginatorPrev = document.querySelector('.p-paginator-prev');
        const paginatorNext = document.querySelector('.p-paginator-next');

        if (enviosApiPaginados) {
            if (!consultaPaginada) {
                paginatorNext.classList.add('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else {
                if (enviosApiPaginados.length < this.state.basicRows) {
                    paginatorNext.classList.add('p-disabled');
                } else {
                    paginatorNext.classList.remove('p-disabled');
                }
                if (this.state.basicFirst == 0) {
                    paginatorPrev.classList.add('p-disabled');
                } else {
                    paginatorPrev.classList.remove('p-disabled');
                }

            }
        } else if (!enviosApiPaginados && enviosApi.length === this.state.basicRows) {
            paginatorNext.classList.remove('p-disabled');
            paginatorPrev.classList.add('p-disabled');
        }

        if (prevProps.filtroBusquedaActivo != filtroBusquedaActivo) {
            if (filtroBusquedaActivo == false) {
                //console.log('busquedaActivada', busquedaActiva);

                this.setState({
                    listaPaginada: false,
                    basicFirst: 0,
                    globalFilter: ""
                })
                //console.log("***busquedaActiva***", busquedaActiva, this.state.listaPaginada);
                obtenerEnviosApi(this.state.basicRows);
            }

        }


    }

    render() {
        const { enviosApi, enviosApiPaginados } = this.props

        const header = (
            <div className="table-header">
                <span className="p-m-1 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        type="search" value={this.state.globalFilter} onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Buscar por teléfono" />
                    <Button label="Buscar" className="p-button-raised ml-2" onClick={() => this.onBasicFilterChange(this.state.globalFilter)} />
                    <Button label="Limpiar" className="p-button-raised ml-2" onClick={() => this.onBasicFilterChange("")} />
                </span>
            </div>
        );

        return (
            <React.Fragment>
                <Card>
                    <DataTable ref={(el) => this.dt = el} value={this.state.listaPaginada ? 
                    this.state.listaPaginada : enviosApiPaginados ? enviosApiPaginados : enviosApi} header={header}
                        dataKey="id" columnResizeMode="fit"
                        emptyMessage="No hay registros encontrados"
                    >
                        <Column field="fechaEnvio" header="Fecha" ></Column>
                        <Column field="telefono" header="Telefono" ></Column>
                        <Column field="mensaje" header="Mensaje" ></Column>
                        <Column field="status" header="Estado" ></Column>
                        <Column field="url" header="Archivo" ></Column>
                        <Column field="errorReason" header="Error" ></Column>
                        <Column field="errorCode" header="Error Code"></Column>

                    </DataTable>
                    <Paginator first={this.state.basicFirst} template="FirstPageLink PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport"
                        rows={this.state.basicRows} rowsPerPageOptions={[10, 20, 30]} onPageChange={this.onBasicPageChange}
                        currentPageReportTemplate="Página {currentPage} de la lista de envíos"
                        totalRecords={20000}>
                    </Paginator>
                </Card>
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        enviosApi: getEnviosApi(state),
        enviosApiPaginados: getEnviosApiPaginados(state),
        consultaPaginada: getConsultaPaginada(state),
        listaFiltradaCompletaEnvios: getlistaEnviosApiFiltrada(state),
        filtroBusquedaActivo: getFiltroEnviosActivo(state)

    };
}

const mapDispachToProps = dispatch => {
    return {
        obtenerEnviosApi: (value) => dispatch({ type: ENVIOS_INDIVIDUALES, value }),
        paginarEnviosApi: (value) => dispatch({ type: PAGINAR_ENVIOS_INDIVIDUALES, value }),
        filtrarEnviosPorTelefono: (value) => dispatch({ type: FILTRAR_ENVIOS_API, value }),


    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EnviosIndividualesComponent);