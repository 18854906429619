import { createStructuredSelector } from 'reselect';
import { getFormValues, } from 'redux-form'
import { FORM_LOGIN_NAME } from './constants';

export const getAuthForm = (state) => state.localReducer.authForm;
// export const getSelectedForm = (state) => state.localReducer.selectedForm;
export const getSelectedForm = (state) => getFormValues(FORM_LOGIN_NAME)(state);
export const getSelectedTab = (state) => state.localReducer.tabSelected;
export const getMessage = (state) => state.localReducer.message;
export const getAuthError = (state) => state.localReducer.error;
export const getLogSuccess = (state) => state.localReducer.logSuccess;
export const getLogo = (state) => state.localReducer.logo;
export const getRecoveryPass = (state) => state.localReducer.recoveryPass;

export const getFormRecovery = (state) => getFormValues("authFormRecovery")(state);

export const authSelector = createStructuredSelector({
    authForm: getAuthForm,
    selectedFotm: getSelectedForm,
    tabSelected: getSelectedTab,
    message: getMessage,
    error: getAuthError,
    logSuccess: getLogSuccess,
    logo: getLogo
});