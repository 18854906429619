import {
  UPDATE_FIELD_ATTRIBUTE,
  CONVS_EN_CURSO_SUCCESS,
  USUARIOS_EN_ESPERA,
  USUARIOS_EN_ESPERA_SUCCESS,
  CONVS_CERRADAS_SUCCESS,
  CARGA_SUCCESS,
  TMPESP_PROM_SUCCESS,
  TMP_RESPUESTA_SUCCESS,
  PORC_SATISFACCION_SUCCESS,
  CONVS_RESUELTAS,
  CONVS_ABANDONADAS_SUCCESS,
  CONVS_RESUELTAS_SUCCESS,
  INFO_AGENTES_SUCCESS,
  MOSTRAR_RANGOS,
  OCULTAR_RANGOS,
  UPD_AGENTES_DONE_SUCCESS,

  OCULTAR_ESTADISTICAS,
  OCULTAR_ESTADISTICAS_SUCCESS,
  UPD_AGENTES_UPDATE,
  CHANGE_FLAG,
  OCULTAR_ESTADISTICA_ENCUESTA_SUCCESS,


} from './constants';


export const initialState = {

  selectedForm: {},
  mostrarRangosFecha: false,
  rango: false,

  //-------graficas--------
  convsEnCurso: false,
  totalConvs: false,
  usuariosEnEspera: false,
  totalUsuarios: false,
  convsCerradas: false,
  totalConvsCerradas: false,
  carga: false,
  cargaTotal: false,
  tmpEsperaProm: false,
  promedioTmpEsp: false,
  tmpRespuesta: false,
  promedioTmpResp: false,
  //-------------------------
  convsResueltas: false,
  porcSatisfaccion: false,

  datosConvsAbandonadas: false,

  infoAgentes: false,
  listaInfoAgentes: [],

  infoAgentesUpd: false,
  ocultarGrafica: false,

  estadisticaEncuesta: false,

  updAgentes: false,

};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  switch (type) {
    case UPDATE_FIELD_ATTRIBUTE:
      const { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;

      newState.selectedForm = newFormObject;
      newState.message = false;



      return {
        ...newState
      };


    case OCULTAR_ESTADISTICAS_SUCCESS:
      newState.ocultarGrafica = payload;
      return {
        ...newState
      }
    case OCULTAR_ESTADISTICA_ENCUESTA_SUCCESS:
      newState.estadisticaEncuesta = payload;
      return {
        ...newState
      }


    //------------Gráficas----------------------

    case CONVS_ABANDONADAS_SUCCESS:

      newState.datosConvsAbandonadas = payload;
      return {
        ...newState
      };


    case CONVS_EN_CURSO_SUCCESS:
      newState.convsEnCurso = payload.payload;
      newState.totalConvs = payload.size;

      return {
        ...newState
      };

    case USUARIOS_EN_ESPERA_SUCCESS:

      newState.usuariosEnEspera = payload.payload;
      newState.totalUsuarios = payload.size;

      return {
        ...newState
      };

    case CONVS_CERRADAS_SUCCESS:
      newState.convsCerradas = payload.payload;
      newState.totalConvsCerradas = payload.size;

      return {
        ...newState
      };

    case CARGA_SUCCESS:
      newState.carga = payload.lista;
      newState.cargaTotal = payload.carga;


      return {
        ...newState
      };

    case TMPESP_PROM_SUCCESS:
      newState.tmpEsperaProm = payload.payload;
      newState.promedioTmpEsp = payload.promedioTmpEsp;

      return {
        ...newState
      };
    case TMP_RESPUESTA_SUCCESS:
      newState.tmpRespuesta = payload.payload;
      newState.promedioTmpResp = payload.promedioTmpResp;

      return {
        ...newState
      };

    case CONVS_RESUELTAS_SUCCESS:
      newState.convsResueltas = payload;
      return {
        ...newState
      }
    case PORC_SATISFACCION_SUCCESS:
      newState.porcSatisfaccion = payload;
      return {
        ...newState
      };

    case CHANGE_FLAG:
      newState.updAgentes = false;
      return {
        ...newState
      };

    case INFO_AGENTES_SUCCESS:

      if (payload) {
        newState.listaInfoAgentes.push(payload);
        newState.listaInfoAgentes = eliminarSesionAgente(newState.listaInfoAgentes)
    
        newState.infoAgentes = { data: newState.listaInfoAgentes}
        newState.updAgentes = true;
      }
      return {
        ...newState
      };
    case UPD_AGENTES_UPDATE:

      newState.updAgentes = false;

      return {
        ...newState
      };
    case UPD_AGENTES_DONE_SUCCESS:

      newState.infoAgentesUpd = payload;
      for (let i = 0; i < newState.infoAgentes.data.length; i++) {
        if (newState.infoAgentesUpd.data.uid === newState.infoAgentes.data[i].uid) {
          ///caso para una modificación
          newState.infoAgentes.data[i] = newState.infoAgentesUpd.data;
          break;
        }
      }
      //caso para un agente que se conecto por primera vez en el día
      const existeUid = newState.infoAgentes.data.find(
        agente => agente.uid === newState.infoAgentesUpd.data.uid
      )
      if (!existeUid) {
        newState.infoAgentes.data.push(newState.infoAgentesUpd.data)
      } else if (existeUid && newState.infoAgentesUpd.data.estado === 'Desconectado') {
        //caso para un agente que se desconectó y ya no debe aparecer en pantalla
        newState.infoAgentes.data.forEach(function (info, index, object) {
          if (info.estado === 'Desconectado') {
            object.splice(index, 1);
          }
        })
        //console.log('infoAgentes en reducer caso desconectado', newState.infoAgentes.data);

      }

      newState.updAgentes = true;
      return {
        ...newState
      };

    case MOSTRAR_RANGOS:
      newState.mostrarRangosFecha = true;

      return {
        ...newState
      };

    case OCULTAR_RANGOS:
      newState.mostrarRangosFecha = false;

      return {
        ...newState
      };

    default:
      return {
        ...newState
      };

  }
};

function eliminarSesionAgente(data) {
  //AQUI VAMOS A ELIMINAR DUPLICADOS Y SESIONES DE AGENTES DESCONECTADAS
  let agentesDisponibles = [];
  const dataOrdenada = data.sort(function (a, b) {
    a = a.fechaIni.seconds;
    b = b.fechaIni.seconds;
    return b - a;
  });
  let hash = {};
  const dataFiltrada = dataOrdenada.filter((current) => {
    let existe = !hash[current.uid];
    hash[current.uid] = true;
    return existe;
  });
  //console.log('data filtrada', dataFiltrada);

  dataFiltrada.forEach((data) => {
    //console.log("data estado", data.estado);
    if (data.estado === "Disponible" || data.estado === "Ausente") {
      agentesDisponibles.push(data);
    }
  });

  //console.log('entro a eliminar sesion agente', agentesDisponibles);
  return agentesDisponibles;

}

export default reducer;
