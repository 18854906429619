export const UPDATE_FIELD_ATTRIBUTE  = '@supervisorPanel/updateAttribute';
export const MOSTRAR_RANGOS = '@supervisorPanel/mostrarCamposRangos';
export const OCULTAR_RANGOS= '@supervisorPanel/ocultarCamposRangos';
//----------graficas---------
export const CONVS_EN_CURSO = '@supervisorPanel/obtenerConvsEnCurso';
export const CONVS_EN_CURSO_SUCCESS = '@supervisorPanel/obtenerConvsEnCursoSucess';
export const CONVS_EN_CURSO_ERROR = '@supervisorPanel/obtenerConvsEnCursoError';

export const USUARIOS_EN_ESPERA = '@supervisorPanel/obtenerUsuariosEnEspera';
export const USUARIOS_EN_ESPERA_SUCCESS = '@supervisorPanel/obtenerUsuariosEnEsperaSuccess';
export const USUARIOS_EN_ESPERA_ERROR = '@supervisorPanel/obtenerUsuariosEnEsperaError';

export const CONVS_CERRADAS = '@supervisorPanel/obtenerConvsCerradas';
export const CONVS_CERRADAS_SUCCESS = '@supervisorPanel/obtenerConvsCerradasSuccess';
export const CONVS_CERRADAS_ERROR = '@supervisorPanel/obtenerConvsCerradasError';

export const CARGA = '@supervisorPanel/obtenerCarga';
export const CARGA_SUCCESS = '@supervisorPanel/obtenerCargaSuccess';
export const CARGA_ERROR = '@supervisorPanel/obtenerCargaError';

export const TMPESP_PROM = '@supervisorPanel/obtenerTmpEspPromedio';
export const TMPESP_PROM_SUCCESS = '@supervisorPanel/obtenerTmpEspPromedioSuccess';
export const TMPESP_PROM_ERROR = '@supervisorPanel/obtenerTmpEspPromedioError';

export const TMP_RESPUESTA = '@supervisorPanel/obtenerTmpRespuesta';
export const TMP_RESPUESTA_SUCCESS = '@supervisorPanel/obtenerTmpRespuestaSuccess';
export const TMP_RESPUESTA_ERROR = '@supervisorPanel/obtenerTmpRespuestaError';

export const CONVS_RESUELTAS = '@supervisorPanel/obtenerConvsResueltas';
export const CONVS_RESUELTAS_SUCCESS = '@supervisorPanel/obtenerConvsResueltasSuccess';
export const CONVS_RESUELTAS_ERROR = '@supervisorPanel/obtenerConvsResueltasError';

export const PORC_SATISFACCION = '@supervisorPanel/obtenerPorcSatisfaccion';
export const PORC_SATISFACCION_SUCCESS = '@supervisorPanel/obtenerPorcSatisfaccionSuccess';

export const INFO_AGENTES = '@supervisorPanel/obtenerInfoAgentes';
export const INFO_AGENTES_SUCCESS = '@supervisorPanel/obtenerInfoAgentesSuccess';
export const INFO_AGENTES_ERROR = '@supervisorPanel/obtenerInfoAgentesError';

export const UPD_AGENTES_UPDATE = '@supervisorPanel/updAgente';
export const UPD_AGENTES_UPDATE_SUCCESS = '@supervisorPanel/updAgenteSuccess';
export const UPD_AGENTES_UPDATE_ERROR = '@supervisorPanel/updAgenteError';

export const UPD_AGENTES_DONE_SUCCESS = '@supervisorPanel/updAgenteDoneSuccess';
export const CERRAR_SESION_AGENTE = '@supervisorPanel/cerrarSesionAgente';

export const CONVS_ABANDONADAS= '@supervisorPanel/obtenerPorcConvsAbandonadas';
export const CONVS_ABANDONADAS_SUCCESS= '@supervisorPanel/obtenerPorcConvsAbandonadasSuccess';
export const CONVS_ABANDONADAS_ERROR= '@supervisorPanel/obtenerPorcConvsAbandonadasError';

export const OCULTAR_ESTADISTICAS = '@supervisorPanel/ocultarEstadisticasConvsAbandonadas';
export const OCULTAR_ESTADISTICAS_SUCCESS = '@supervisorPanel/ocultarEstadisticasConvsAbandonadasSuccess';
export const CHANGE_FLAG = '@supervisorPanel/changeFlag';

export const OCULTAR_ESTADISTICA_ENCUESTA = '@supervisorPanel/ocultarEstadisticaEncuesta';
export const OCULTAR_ESTADISTICA_ENCUESTA_SUCCESS = '@supervisorPanel/ocultarEstadisticaEncuestaSuccess';
export const OCULTAR_ESTADISTICA_ENCUESTA_ERROR = '@supervisorPanel/ocultarEstadisticaEncuestaError';






