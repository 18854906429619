import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../../index.css";


import React, { Component } from "react";
import { Chart } from "primereact/chart";


export default class ComboChartDemo extends Component {


    render() {
        const { data } = this.props;
        const colores = ["#FFA726", "#42A5F5", "#27AE60", "#2980B9", "#FF5733", "#E91E63", "#00BCD4",
            "#FDD835", "#795548", "#1E88E5", '#EC407A', '#AB47BC', '#42A5F5', '#7E57C2', '#66BB6A', '#FFCA28',
            '#26A69A',"#4dd0e5","#ec5017","#4b42f2", "#42f279", "#dc42f2", "#0fe13e","#b2e10f","#ab0fe1","#0fe175"
        ];
        let labelData = data && data.dataLabel ? data.dataLabel : '';
        let ejeX = [];
        let ejeY = [];
        let coloresSeleccion = [];
        let mostrarEjesCuadricula = false;
        let mostrarEjeX
        if (data) {
        
            let mostrarCeros = data.mostrarCeros ? data.mostrarCeros : false;
            mostrarEjesCuadricula = data.mostrarEjes ? data.mostrarEjes : false;
            mostrarEjeX = !data.mostrarEjex?data.mostrarEjex:mostrarEjesCuadricula;

            for (let i = 0; i < data.ejeY.length; i++) {
                if (mostrarCeros) {
                    ejeY.push(data.ejeY[i]);
                    ejeX.push(data.ejeX[i]);
                } else {
                    if (data.ejeY[i] > 0) {
                        ejeY.push(data.ejeY[i]);
                        ejeX.push(data.ejeX[i]);
                    }
                }
            }
            let copia = [...colores];
            ejeY.forEach(e => {
                let posicion = Math.floor(Math.random() * copia.length)
                coloresSeleccion.push(copia[posicion])
                copia.splice(posicion, 1);
            });
        }


        this.basicData = {
            labels: ejeX,
            datasets: [
                {
                    label: labelData,
                    backgroundColor: coloresSeleccion,
                    data: ejeY
                },

            ]
        };

        let horizontalOptions = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };


        this.lightOptions = {
            legend: {
                display: false,
                labels: {
                    fontColor: "#495057"
                }
            },
            scales: {
                x:
                {
                    ticks: {
                        display: mostrarEjeX,
                        fontColor: "#495057",
                        min: 0,
                        max: 5,
                    },
                    grid: {
                        display: mostrarEjesCuadricula
                    }
                }
                ,
                y:
                {
                    ticks: {
                        display: mostrarEjesCuadricula,
                        min: 0,
                        max: 100,
                        fontColor: "#495057"
                    },
                    grid: {
                        display: mostrarEjesCuadricula
                    }
                }
                ,

            }
        };

        return (

            <Chart type="bar" data={this.basicData} options={this.lightOptions} />

        );
    }
}

