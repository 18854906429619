import React, { Component } from 'react';
import { convertMsToTimeObj } from '../../../controller/utilidades';
export default class Cronometro extends Component {
    
    componentInterval = false;

    constructor(props) {
        super(props)
        this.id = props.id
        this.state = {
            duracion: 0,
            fechaActual: props.fechaActual,
            tiempo: 0
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.id !== this.props.id){
            clearInterval(this.componentInterval)
            this.calcularDuracion()   
        }
    }
    

    calcularDuracion() {
        const { fechaAceptacion, id, duracionConversacionObj } = this.props;
        let duracionConversacionLocal = false;
        let localTiempo = localStorage.getItem(""+id)
        localTiempo = localTiempo ? +localTiempo : 0;
        console.log("id", id)
        console.log("tiempo", localTiempo)
        if (fechaAceptacion ) {
            let today = this.state.fechaActual ? this.state.fechaActual : (new Date()).getTime();
            let tiempoDuracion = duracionConversacionObj + (localTiempo * 1000);
            
            this.componentInterval = setInterval(() => {
                tiempoDuracion += 1000;
                today += 1000;
                localTiempo += 1
                localStorage.setItem(""+id, localTiempo);
                let obj = today - fechaAceptacion
                duracionConversacionLocal = convertMsToTimeObj(tiempoDuracion)
                this.setState({
                    duracion: duracionConversacionLocal,
                    tiempo: this.state.tiempo+1
                })
            }, 1000);
        }
    }

    componentDidMount(){
        const { id } = this.props;
        const localTiempo = localStorage.getItem(""+id)
        this.calcularDuracion()
    }

    componentWillUnmount(){
        const { id } = this.props;
        localStorage.clear()
        clearInterval(this.componentInterval)
    }

    render() {
        const {duracionConversacionObj, className} = this.props
        let tiempo = convertMsToTimeObj(duracionConversacionObj)
        return (
            <div className={`row info-chat-row ${className}` }>
                <div className="col-5 pr-0">
                    <img className="icon-table2" src="/assets/icons/crono.png" />
                </div>
                <div className="col-7 pl-0">
                    {this.state.duracion.hours}: 
                    {this.state.duracion.minutes}: 
                    {this.state.duracion.seconds}
                </div>
            </div>)
    }
}