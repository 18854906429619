import {
    BOT_ACTIVO_SUCCESS,
    CARDS_ELE_SUCCESS,
    COMPANY_DATA_SUCCESS,
    GET_PLANES,
    GET_PLANES_SUCCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LOAD_MENU_SUCCES
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}

export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}

export function cardsEleSucces (payload) {
    return {
        type: CARDS_ELE_SUCCESS,
        payload
    };
}

export function botActivoSuccess (payload) {
    return {
        type: BOT_ACTIVO_SUCCESS,
        payload
    };
}

export function companyDataSuccess (payload) {
    return {
        type: COMPANY_DATA_SUCCESS,
        payload
    };
}
