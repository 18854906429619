import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import './tablePaginator.css';
import { Paginator } from 'primereact/paginator';
import { CSVLink } from "react-csv";


export default class DataTableWithPaginator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            convs: this.props.data,
            globalFilter: null,
            basicFirst: 0,
            basicRows: 10,
            firstLastState: 2,
            paginaAnterior: {},
            listaPaginada: false,
            generandoCsv: false,
            btnDescargarCsv: false,

        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.generarCSV = this.generarCSV.bind(this)
    }

    componentDidMount() {
        let datosIniciales = this.props.data;
        this.setState({
            convs: datosIniciales
        })

    }
    componentDidUpdate(prevProps) {
        const { dataPaginada, consultaPaginada, data, totalConvsFiltradas,
            dataToDownloadCsv, nombreConsulta
        } = this.props
        if (this.state.convs != this.props.data) {
            this.setState({
                convs: this.props.data
            });
        }
        const paginatorPrev = document.querySelector('.p-paginator-prev');
        const paginatorNext = document.querySelector('.p-paginator-next');



        if (prevProps.dataToDownloadCsv != dataToDownloadCsv) {
            if (dataToDownloadCsv) {
                this.setState({
                    generandoCsv: false,
                    btnDescargarCsv: true
                });

            }
        }


        if (prevProps.totalConvsFiltradas != totalConvsFiltradas) {
            if (!totalConvsFiltradas) {
                this.setState({
                    listaPaginada: false
                })
            }
        }

        if (dataPaginada) {
            //console.log('entro al iff');
            if (!consultaPaginada) {
                paginatorNext.classList.add('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else {
                if (dataPaginada.length < this.state.basicRows) {
                    paginatorNext.classList.add('p-disabled');
                } else {
                    paginatorNext.classList.remove('p-disabled');
                }
                if (this.state.basicFirst == 0) {
                    paginatorPrev.classList.add('p-disabled');
                } else {
                    paginatorPrev.classList.remove('p-disabled');
                }

            }
        } else if (!dataPaginada) {

            /* if (this.state.convs.length < this.state.basicRows) {
                paginatorNext.classList.add('p-disabled');

            } */

            if (!this.state.listaPaginada && (data.length === this.state.basicRows)) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else if (this.state.listaPaginada.length < this.state.basicRows) {
                paginatorNext.classList.add('p-disabled');

            } else if ((this.state.listaPaginada.length === this.state.basicRows) && this.state.basicFirst == 0) {
                paginatorNext.classList.remove('p-disabled');
                paginatorPrev.classList.add('p-disabled');

            }

        }
    }

    paginarConvsFiltradas(event) {
        const { totalConvsFiltradas } = this.props
        console.log('totalConvsFiltradas', totalConvsFiltradas.length);

        let listaPaginada;

        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = totalConvsFiltradas.slice(event.first, last)
        } else {
            listaPaginada = totalConvsFiltradas.slice(event.first, event.rows)
        }
        console.log("listaFiltradaCortada", listaPaginada);
        this.setState({
            listaPaginada: listaPaginada,
            basicFirst: event.first,
            basicRows: event.rows
        })
    }

    onBasicPageChange(event) {
        const { paginarData, seleccionRangoBusqueda, data,
            dataPaginada, totalConvsFiltradas } = this.props

        if (totalConvsFiltradas) {
            this.paginarConvsFiltradas(event)

        } else {
            let ultimaConv = false;
            let primeraConv = false;
            let nextPage = this.state.firstLastState < event.first;


            this.setState({
                firstLastState: event.first,
                listaPaginada: false,
            });

            if (dataPaginada) {
                if (dataPaginada.length === event.rows && event.first > 0) {
                    //console.log('entro a paginar conversacionesPaginadas');
                    ultimaConv = dataPaginada[event.rows - 1];
                    primeraConv = dataPaginada[0];
                } else {
                    //console.log('entro a ultima pagina');
                    ultimaConv = dataPaginada[dataPaginada.length - 1];
                    primeraConv = dataPaginada[0];
                }
            } else if (!dataPaginada && data) {
                if (data.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimaConv = data[event.rows - 1];
                    primeraConv = data[0];
                }
            }
            this.setState({
                basicFirst: event.first,
                basicRows: event.rows
            });

            if (nextPage) {
                paginarData({ event, "ultimaConv": ultimaConv, nextPage, seleccionRangoBusqueda });
            } else {
                paginarData({ event, "ultimaConv": primeraConv, nextPage, seleccionRangoBusqueda });
            }
        }
    }



    generarCSV() {
        const { descargarCSV, seleccionRangoBusqueda, nombreConsulta } = this.props

        this.setState({
            generandoCsv: true
        })
        descargarCSV({ seleccionRangoBusqueda, nombreConsulta })

    }



    rightToolbarTemplate() {
        const { dataToDownloadCsv, nombreConsulta } = this.props

      //  console.log("dataToDownloadCsv*******", dataToDownloadCsv)
        return (
            
            <React.Fragment>

                <Button label="Generar CSV" icon="pi pi-file-excel"
                    loading={this.state.generandoCsv} className="p-button-help p-ml-2" onClick={this.generarCSV} />
                {!this.state.generandoCsv && this.state.btnDescargarCsv ? (<CSVLink data={dataToDownloadCsv} filename={nombreConsulta ? `csv-${nombreConsulta}.csv` : `csvData.csv`}>
                    <Button label="Descargar CSV" icon="pi pi-download" className="p-button-success p-ml-2"
                        onClick={e => {
                            this.setState({
                                btnDescargarCsv: false
                            })
                            return false; // 👍🏻 You are stopping the handling of component
                        }}
                    />
                </CSVLink>) : false}

            </React.Fragment>
        )
    }



    render() {
        const {
            key,
            columns,
            dataPaginada,
            tituloTabla

        } = this.props

        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{tituloTabla}</h5>

            </div>
        );

        const dynamicColumns = columns.map((col, i) => {
            return <Column key={col.field} field={col.field} header={col.header} />;
        });


        return (
            <div>
                <div className="card">
                    <DataTable id="convsCerradasTable" columnResizeMode="fit" className="p-datatable-gridlines"
                        ref={(el) => this.dt = el}
                        value={this.state.listaPaginada ? this.state.listaPaginada : dataPaginada ?
                            dataPaginada : this.state.convs}
                        dataKey={key} header={header} emptyMessage="No hay registros">
                        {dynamicColumns}

                    </DataTable>
                    <Paginator first={this.state.basicFirst} rows={this.state.basicRows} rowsPerPageOptions={[10, 20, 30, 50, 100]}
                        template="FirstPageLink PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport"
                        currentPageReportTemplate="Página {currentPage} de la lista de conversaciones"
                        onPageChange={this.onBasicPageChange} totalRecords={1000000}>

                    </Paginator>
                </div>
                <Toolbar className="p-mb-4" right={this.rightToolbarTemplate}></Toolbar>

            </div>

        );
    }
}


