import React, { Component, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from '../constants';
import { getMenuItemsChatBot, getUsers } from "../selectors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import { Paginator } from 'primereact/paginator';
import { Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';


const ComponentTable = (props) => {

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [first, setFirst] = useState(0); // Estado para controlar el primer registro mostrado
    const [rows, setRows] = useState(10); // Estado para controlar el número de filas por página

    const SelectedRow = () => {
        console.log("selectedProducts", selectedProducts)
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
        console.log("event.first", props.data.length)
        console.log("event.rows", props.data.length)
    };

    const NameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.nombre}

                {/* Renderizar el CircularProgress junto al nombre si isLoading es true */}
                    <>
                        {rowData.name.length <= 20 ?
                            <>
                                {rowData.name}
                            </>
                            :
                            <>
                                {rowData.name.match(/.{1,20}/g).join('\n')}
                            </>
                        }
                    </>
                  
            </React.Fragment>
        );
    };


    const estadoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.nombre}

                {/* Renderizar el CircularProgress junto al nombre si isLoading es true */}
                {rowData.estado}
                {rowData.estado === 'Orden de procesamiento' || rowData.estado === 'En procesamiento' ?

                    <CircularProgress size={20} style={{ marginLeft: 10, color: 'red', borderRadius: '50%', padding: '5px' }} />
                    : ''
                }
                {rowData.estado === 'Procesado' ?

                    <DoneOutlineIcon size={60} style={{ marginLeft: 10, color: 'green', borderRadius: '50%', padding: '5px' }} />
                    : ''
                } 



            </React.Fragment>
        );
    };

    return (
        <Paper elevation={5} className="tabla-crear-pregunta">
            {/* {!props.loadingTable &&

                <div className="loading-wrapper">
                    <CircularProgress color="secondary"></CircularProgress>
                </div>

            } */}

            <h2 style={{ "padding": "20px" }}>Seleccione los documentos para procesar</h2>

            <DataTable value={props.data} first={first} rows={rows} paginator rowsPerPageOptions={[5, 10, 20]}
                selectionMode={rowClick ? null : 'checkbox'}
                selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field={'fecha'} header={'Fecha de cargue'} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                <Column field={'name'} header={'Nombre'} body={NameBodyTemplate}  sortable style={{ 'width': '13rem', 'font-size': '12px' }}></Column>
                <Column field={'lote'} header={'Lote'} sortable style={{ 'width': '13rem', 'font-size': '12px' }}></Column>
                <Column field={'id'} header={'Id'} sortable style={{ 'width': '13rem', 'font-size': '12px' }}></Column>
                <Column field={'estado'} header={'Estado'} body={estadoBodyTemplate} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
            </DataTable>
            {/*<Column header="Acciones" body={(rowData) => this.actionButtons(rowData)} sortable style={{ width: 'auto' }}></Column>*/}
            <Button onClick={() => props.procesarDocs(selectedProducts)} color='primary' variant="contained">Procesar documentos</Button>

        </Paper>
    );

}


export default ComponentTable;