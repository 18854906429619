import { 

    faAngleDoubleRight, 
    faUserCircle, 
    faSignOutAlt,
  
} from "@fortawesome/free-solid-svg-icons";


export const SWITCHE_FORM = [
    {
        label: "Estado",
        name: "ausente",
        placeholder: "Estado",
        type: "switch",
        typeForm:"switch",
        value: false,
        options: [],
        disable: false,
        display:true,
        className:'estado_switch',
    }
];


export const ITEM_TITLE = [
    {
        position: 'right',
        path: '#',
        name: 'ChatAsesores',
        css: faAngleDoubleRight,
        key: 4,
        label: 'Chat Asesores',
        className:'logo',
        active: false,
    }
]

export const getItemLogout = (logoutAction) => [
    {
        position: 'left',
        path: '/',
        name: 'Exit',
        css: faSignOutAlt,
        key: 5,
        label: 'Exit',
        className:'logout',
        active: false,
        action: logoutAction,
    }
]

export const getItemHeader = (headerAction) => [
    {
        position: 'right',
        path: '#',
        name: 'ChatAsesores',
        css: faAngleDoubleRight,
        key: 4,
        label: '',
        className:'logo',
        active: false,
        action: headerAction,
    }
]

export const getUserItem = (name, picture) => {
    const ITEM_PROFILE = [
        {
            
            position: 'left',
            path: '/profile',
            name: name,
            css: faUserCircle,
            key: 6,
            label: name,
            className:'',
            active: false,
            picture: picture
        }
    ]

    return ITEM_PROFILE;
}

export const BUTTON_LIST =  [
    {
        variant: 'contained',
        className: 'pruebaButton1',
        onClick: ()=>{},
        style: '',
        label: 'Inicio',
        path: '/inicio',
        display:'block',
        color: 'primary',
        id: '1'
    },
    {
        variant: 'contained',
        className: 'pruebaButton2',
        onClick: ()=>{},
        style: '',
        label: 'Mi ChatBot',
        display:'block',
        color: 'primary',
        path: '/autobot',
        id: '2'
    },    
    {
        variant: 'contained',
        className: 'pruebaButton3',
        onClick: ()=>{},
        style: '',
        label: 'Mis Imagenes',
        display:'block',
        path: '/imageOCR',
        color: 'primary',
        id:'3'
    },    
    {
        variant: 'contained',
        className: 'pruebaButton4',
        onClick: ()=>{},
        style: '',
        label: 'Perfil',
        display:'block',
        color: 'primary',
        path:'/perfil',
        id:'4'
    }

]