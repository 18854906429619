import React, { useEffect, useState, Component } from 'react';
import { firebaseAuthentication } from '../../controller/firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        firebaseAuthentication.onAuthStateChanged(setCurrentUser)
    }, [])

    return (
        <AuthContext.Provider
            value = {{currentUser}}
        >
            {children}
        </AuthContext.Provider>
    );
}

