import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getUsers, getListaPrueba } from "../selectors";
import {
    ADD_ITEM,
    GET_USERS
} from '../constants';
import { Button } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PublicIcon from '@material-ui/icons/Public';
import ComponentWhatsApp from "../publicarWhatsApp/component";
import { Icon } from "@material-ui/core";

class ComponentPublicar extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout,  } = this.props;
       
        this.state = {
            activePath: '/',
            whatsapp: false,
            web: false,
        }
    }


    componentWillMount() {
        const { getUsers } = this.props;
        getUsers();
        // const queryString = window.location.search;
        //  const urlParams = new URLSearchParams(queryString);
        //   const oobCode = urlParams.get('oobCode');
        //bienvenidaPage(oobCode)
        //  console.log("bienvenidaPage(oobCode)", bienvenidaPage(oobCode))
    }
    render() {
        const {
            items,
        } = this.props;

        return (
            <div className="modal-wrapper">
                <div className="modal-background" onClick={() => this.props.onModalCancel()}> </div>
                <div className="modal-content">

                    <Button className="close-button" onClick={() => this.props.onModalCancel()}>
                        x
                    </Button>
                    <div className="modal-info">


                    </div>
                    <div className="button-group">
                        <IconButton className="opciones_respuesta">
                            <WhatsAppIcon />
                            <p>WhatsApp</p>
                            <Button variant="primary" onClick={() => this.props.onModalWhatsApp()}>
                                Ver Pluggin
                            </Button>
                        </IconButton>
                        <IconButton className="opciones_respuesta">
                            <PublicIcon />
                            <p>Web</p>
                            <Button variant="primary" onClick={() => this.props.onModalClick()}>
                                Configuracion
                            </Button>
                        </IconButton>
                    </div>
                    <div className="button-group">

                        <Button variant="danger" onClick={() => this.props.onModalCancel()}>
                            Cancelar
                        </Button>
                        <Button variant="primary" onClick={() => this.props.onModalClick()}>
                            Publicar
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        items: getListaPrueba(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        cargarItems: () => dispatch({ type: ADD_ITEM, value: 1 }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentPublicar);