import { takeLatest, put, call, take, select } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../Constants';
import * as selectors from "./selectors"
import { collection, addDoc, setDoc, doc, getDoc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { firebaseDatabase, firebaseField, storage } from "../../controller/firebase";
import { ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import axios from "axios";
import { eventChannel } from "@redux-saga/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  COMPANY_DATA,
  COMPARAR_ARRAY,
  CROSS_DOC,
  DOWNLOAD_DOC,
  FIN_SUSCRIBCION,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_OCR_DOC,
  GET_OCR_SIZE,
  GET_PLANES,
  GET_TRANSACCION,
  GET_USERS, LOAD_MENU, OCR_ACTIVO, OCR_CREATE, PRO_OCR_DOC, SHOW_DOC_PROC, UPLOAD_BUCKET, UPLOAD_BUCKET_SUCCESS, UPLOAD_DOC_SUCCESS,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  uploadBucketSuccess,
  ocrActivoSuccess,
  ocrActivoActionSuccess,
  ocrCreateSuccess,
  contadorBucketSuccess,
  getOCRDocumentosSuccess,
  proOCRDocumentosSuccess,
  getOcrSizeSuccess,
  documentosProcesadosSuccess,
  showDocumentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  crossDocSuccess,
  UploadDocuSuccess,
  getPlanesSuccess,
  finSuscribcionSuccess,
  getTransaccionSuccess,
  documentosActualizadosSuccess,
} from './actions';

import { getFromSession } from "../../controller/session";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  let user = getFromSession("currentUser");
  let resp = false;
  let producto = false;
  let rol = user.rol;


  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const docResp = yield docRef.get();

    producto = docResp.data()
  } catch (error) {
    throw error;
  }

  if (producto?.productoOCR?.plan === 'Gratis') {
    menuItemsOCR.forEach((menu) => {
      //console.log("menu", menu)
      if (menu.roles.includes(rol)) {
        list.push(menu);
      } else if (menu.roles.includes(rol)) {
        list.push(menu);
      }
    });
  }
  if (producto?.productoOCR?.plan === 'Pymes') {
    menuItemsOCR.forEach((menu) => {
      //console.log("menu", menu)
      if (menu.roles.includes(rol)) {
        list.push(menu);
      } else if (menu.roles.includes(rol)) {
        list.push(menu);
      }
    });
  }

  console.log("MENU CHATBOT", list)






  yield put(loadMenuSucces(list));
}

function* companyData(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}
function* uploadToBucket(action) {
  let lote = false;
  let company = false;
  let datosConsulta = false;
  const user = getFromSession("currentUser");

  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  try {
    //console.log("docRefffff", docRef.id)

    let contador = 0;
    let longitud = 0;
    if (action.value.length > 1) {
      const docRef = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`), {
        fecha_creacion: firebaseField.serverTimestamp(),
        usuario: {
          name: resp.nombres,
          //company: `${resp.tipo_documento + resp.num_documento}`,
          company: 'Comfenalco',
          correo: resp.correo
        },
        aplicacion: "chat-asesores",
        estado: 0,
        nro_documentos: action.value.length
      })

      const idCargue = docRef.id
      lote = docRef.id
      //console.log("LOTEEEE", lote)
      company = resp.tipo_documento + resp.num_documento

      const cambios = {
        id: idCargue
      };

      const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${idCargue}`);
      docRefUpdate.update(cambios)
        .then(() => {
          console.log('Documento actualizado correctamente.');
        })
        .catch((error) => {
          console.error('Error al actualizar el documento:', error);
        });



      let cont = 0;
      console.log("mas de unooo")
      longitud = action.value.length;
      for (const archivo of action.value) {
        let newName = archivo.name.replace(/\s+/g, '');
        let spaceRef = ref(storage, `${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`);
        yield uploadBytes(spaceRef, archivo).then((snapshot) => {
        }).catch(error => {
          console.error('Error cargando el documento:', error);
        });

        // Supongamos que tienes la referencia al documento
        let documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('documentosOCR').doc(`${idCargue}`);

        // Añade una nueva colección al documento
        let nuevaColeccionRef = yield documentRef.collection('Documentos');

        // Añade un documento a la nueva colección
        //const nuevoDocumentoRef = nuevaColeccionRef.doc('documento' + `${contador}`);

        let datosDocumento = {
          name: newName,
          url_bucket: `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`,
          tamano: archivo.size,
          //company: 'Comfenalco',
          company: resp.tipo_documento + resp.num_documento,
          fecha_cargue: firebaseField.serverTimestamp(),
          estado: 0,
          tipo: archivo.type
        };
        // console.log("DATOS CARGUE DOCUMENTO", datosDocumento.name)
        let documentosCargue = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos`), {
          datosDocumento
        })

        let idCargueDoc = documentosCargue.id
        //console.log("DATTE BF", documentosCargue)
        const cambios = {
          id: idCargueDoc
        };

        const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos/`).doc(`${idCargueDoc}`);
        docRefUpdate.update({
          'datosDocumento.id': `${idCargueDoc}`
        })
          .then(() => {
            console.log('Documento actualizado correctamente.');
          })
          .catch((error) => {
            console.error('Error al actualizar el documento:', error);
          });

        contador = contador + 1
        yield put(contadorBucketSuccess({
          contador: contador,
          length: longitud,
          nombre: newName
        }));

        //yield getOCRDocumentos(lote)

        let toastDetail = false;
        let showTable = false;
        cont = cont + 1;

        if (documentosCargue) {
          //console.log("CONT", cont)
          toastDetail = {
            'summary': 'Realizado!',
            'severity': 'success',
            'detail': 'Se ha cargado el documento'
          }
          showTable = true
        } else {
          toastDetail = {
            'summary': 'No realizado!',
            'severity': 'error',
            'detail': 'Ha ocurrido un error'
          }

        }

        yield put(UploadDocuSuccess({
          toastDetail: toastDetail,
          showToast: true,
          showTable
        }));


      }

      datosConsulta = {
        usuario: {
          nombre: resp.nombres,
          correo: resp.correo,
          company: resp.num_documento
        },
        company: company,
        documentosOcr: lote,
        estado_doc: 1,
        estado_lote: 0,
      }

    } else {
      console.log("unooo", action.value[0].name)

      const docRef = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`), {
        fecha_creacion: firebaseField.serverTimestamp(),
        usuario: {
          name: resp.nombres,
          company: `${resp.tipo_documento + resp.num_documento}`,
          //company: 'Comfenalco',
          correo: resp.correo
        },
        aplicacion: "chat-asesores",
        estado: 0,
        nro_documentos: action.value.length
      })

      const idCargue = docRef.id
      lote = docRef.id
      company = resp.tipo_documento + resp.num_documento

      const cambios = {
        id: idCargue
      };

      const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${idCargue}`);
      docRefUpdate.update(cambios)
        .then(() => {
          console.log('Documento actualizado correctamente.');
        })
        .catch((error) => {
          console.error('Error al actualizar el documento:', error);
        });

      longitud = 1;
      yield put(contadorBucketSuccess({
        length: longitud,
        contador: 1,
        nombre: action.value[0].name
      }));


      //yield getOCRDocumentos(lote)

      for (const archivo of action.value) {
        const newName = archivo.name.replace(/\s+/g, '');
        const spaceRef = ref(storage, `${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`);
        yield uploadBytes(spaceRef, archivo).then((snapshot) => {
        }).catch(error => {
          console.error('Error cargando el documento:', error);
        });
        // Supongamos que tienes la referencia al documento
        const documentRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('documentosOCR').doc(`${idCargue}`);

        // Añade una nueva colección al documento
        const nuevaColeccionRef = documentRef.collection('Documentos');

        // Añade un documento a la nueva colección
        const nuevoDocumentoRef = nuevaColeccionRef.doc('documento' + `${contador}`);
        const datosDocumento = {
          //id: idCargue,
          name: newName,
          url_bucket: `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`,
          tamano: archivo.size,
          //company: 'Comfenalco',
          company: resp.tipo_documento + resp.num_documento,
          fecha_cargue: firebaseField.serverTimestamp(),
          estado: 0,
          tipo: archivo.type
        };

        const documentosCargue = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos`), {
          datosDocumento
        })
        const idCargueDoc = documentosCargue.id
        const cambios = {
          id: idCargueDoc
        };

        const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos/`).doc(`${idCargueDoc}`);
        docRefUpdate.update({
          'datosDocumento.id': `${idCargueDoc}`
        })
          .then(() => {
            console.log('Documento actualizado correctamente.');
          })
          .catch((error) => {
            console.error('Error al actualizar el documento:', error);
          });

        datosConsulta = {
          usuario: {
            nombre: resp.nombres,
            correo: resp.correo,
            company: resp.num_documento
          },
          company: company,
          documentosOcr: lote,
          estado_doc: 1,
          estado_lote: 0,
        }

        //    console.log("procesar documentos", documento)


      }
    }
  } catch (error) {
    //console.log('uploadIntent', error)
    throw error;
  }
  //yield getOCRDocumentos()

  let toastDetail = false;
  let showTable = false;

  if (action) {
    toastDetail = {
      'summary': 'Realizado!',
      'severity': 'success',
      'detail': 'Se han cargado todos los documentos'
    }
    showTable = true
  } else {
    toastDetail = {
      'summary': 'No realizado!',
      'severity': 'error',
      'detail': 'Ha ocurrido un error'
    }

  }

  yield put(uploadBucketSuccess({
    toastDetail: toastDetail,
    showToast: true,
    showTable
  }));
  //    console.log("procesar documentos", documento)

  // console.log("datos para consulta", datosConsulta)

  // yield getDocumentosProcesados();
  // yield getDocumentosFallidos();



}

function* ocrCreate(action) {

  const user = getFromSession("currentUser");
  let resp = false;
  let response = false;

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  //console.log("resp", resp)

  if (resp.ocr_activo === false) {
    yield firebaseDatabase.collection(`company/`).doc(`${resp.tipo_documento + resp.num_documento}`).update({
      ocr_activo: true
    })

    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRefMAX_SIZE_DOC = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MAX_SIZE_DOC');
    const MAX_SIZE_DOC = {
      administrable: true,
      value: 4
    };

    docRefMAX_SIZE_DOC.set(MAX_SIZE_DOC);

    response = resp.ocr_activo

    const docRefCruceConf = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('CRUCE_CONF');
    const cruceConf = {
      activador: 'CRUCE',
      administrable: true,
      name: 'Cruce',
    };

    docRefCruceConf.set(cruceConf);

    const docRefCruceConfEnabled = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('CRUCE_CONF_ENABLED');
    const cruceConfEnbled = {
      value: true,
    };

    docRefCruceConfEnabled.set(cruceConfEnbled);

    const docRefDocConf = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('DOCUMENT_FORM');
    const DocuementoConf = {
      activador: 'DOCUMENT_FORM',
      administrable: true,
      name: 'Formulario de documentos',
    };

    docRefDocConf.set(DocuementoConf);

    const docRefDocConfEnabled = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('DOCUMENT_FORM_ENABLED');
    const DocuementoConfEnbled = {
      value: true,
    };

    docRefDocConfEnabled.set(DocuementoConfEnbled);


    
    const docRef2 = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/habilidades`), {
      nombre: 'Habilidad General',

    });
    const docId = docRef2.id;
    //console.log("docIDDDD", docId)
    const updateRef = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/habilidades`).doc(docId);
    const res = yield updateRef.update({ id: docId });

    const updateRefUser = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/usuarios`)
    const querySnapshot = yield getDocs(updateRefUser);
    let userRef = false;
    querySnapshot.forEach((doc) => {
      userRef = doc.id;
    });
    const resUser = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/usuarios`).doc(userRef)

    yield resUser.update({
      skill: {
        id: docId,
        nombre: 'Habilidad General'
      }
    });


  } else {
    response = true;
  }

  yield getMaxSize
  console.log("RESPONSE ocrActive", response)
  yield put(ocrCreateSuccess(response))


}

function* isOcrEnabled(action) {
  const user = getFromSession("currentUser");
  //console.log("user", user)
  let resp = false;
  let ocrActivo = false
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const ocrActivoResp = yield docRef.get();
    if (ocrActivoResp.exists) {
      ocrActivo = ocrActivoResp.data().ocr_activo;
      //console.log('Valor del campo:', ocrActivo);
      yield put(ocrActivoActionSuccess(ocrActivo));
    } else {
      console.log('El documento no existe.');
    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }

  //return (botActivo)
}

function* getOCRDocumentos(action) {
  const user = getFromSession("currentUser");
  let consultaData = []
  consultaData = yield consultarDataDocument()

  //console.log("consultaData", consultaData)

  yield put(getOCRDocumentosSuccess(consultaData))

}

function* realTime(){
  //console.log("LOTE", action)
  const user = getFromSession("currentUser");

  let resp = false;
  let documentos = [];
  let estado = false;
  let documentData = false;
  let alertFlag = false;
  let ultimoLote = false;
  //let ultimoLote = false;

  //try {
  const companySnapshot = yield firebaseDatabase.collection('company')
    .where('correo', '==', `${user.mail}`).get();

  companySnapshot.forEach(doc => {
    resp = doc.data();
  });

  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  const documentosSnapshotLote = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)

  yield documentosSnapshotLote.orderBy("fecha_creacion", "desc").limit(1).get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        // doc.data() contiene los datos del último 
        ultimoLote = doc.id
        // console.log("Último documento:", ultimoLote);
      });
    })
    .catch(function (error) {
      console.error("Error al obtener el último documento:", error);
    });
 
  const documentosOCRSnapshotCargados = firebaseDatabase.
    collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${ultimoLote}/Documentos`)


 
  const channel = eventChannel((emit) => documentosOCRSnapshotCargados.onSnapshot(emit));

  let consultaData = []
  try {
    //yield put(clearConversationStart(response));
    let previousConsultaData = null
    while (true) { 
      consultaData = []
      console.log("while ****")
      try {
        //conversations = [];
        const realData = yield take(channel);
        console.log("realData", realData)
        const documentosActuales = yield select(selectors.selectorProcesarDocumentos);
        console.log("documentosActuales", documentosActuales);
        //let documentosAProcesar = [...documentosActuales];
        let documentosAProcesar = documentosActuales && documentosActuales.length > 0 ? [...documentosActuales] : [];
        let documentosActualizados = false;

        realData.docs.forEach((doc) => {
          documentData = doc.data();
          console.log(documentData);
          if (documentData.datosDocumento.estado === 0) {
            estado = 'Cargado en bucket'
          } else if (documentData.datosDocumento?.estado === 1) {
            estado = 'Orden de procesamiento'
          } else if (documentData.datosDocumento?.estado === 2) {
            estado = 'En procesamiento'
          } else if (documentData.datosDocumento?.estado === 3) {
            estado = 'Procesado'
          } else if (documentData.datosDocumento?.estado === 4) {
            estado = 'Procesado con error'
          }
          const dataDocument = {
            lote: ultimoLote,
            fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
            name: documentData.datosDocumento.name,
            size: documentData.datosDocumento.tamano,
            id: doc.id,
            //resumen: documentData.respuesta.extraccion.textoFull ? documentData.respuesta.extraccion.textoFull : '',
            estado: estado
          };

       
          if (documentData.datosDocumento.estado !== 3) {
            console.log("documento procesado")
            consultaData.push(dataDocument);
            //array2.push(documentData)
          }
          

          documentosAProcesar.map((docuProc, i) => {
            if (docuProc.id === dataDocument.id) {
              documentosAProcesar[i] = dataDocument;
            }
          });

          console.log(" =========== Revisandoe estados ===============");
          
          console.log("documentosAProcesar", documentosAProcesar);
          console.log(documentosActualizados);
          let validarDocumentos = true;

          documentosAProcesar.forEach((docupro) => {
            console.log("Estado(" + docupro.id + "): ", docupro.estado);
            if (docupro.estado !== 'Procesado') {
              validarDocumentos = false;
            }
        
          });
          if(documentosAProcesar && documentosAProcesar.length > 0){
            documentosActualizados = validarDocumentos;
          }


        });

        if (documentosActualizados) {
          console.log("documentosActualizados: ", documentosActualizados);
          yield put (documentosActualizadosSuccess(documentosActualizados))
        } else {
          console.log("Aún no: ", documentosActualizados);
        }
        // if (consultaData.length !== 0 && alertFlag) {
        //   console.log("alertFlag", alertFlag)
        //   alert = "documento procesado"

        // }
        // if (consultaData.length === 0 && alertFlag) {
        //   console.log("alertFlag", alertFlag)
        //   alert = "se han procesado todos los documentos"

        // }
        yield put(getOCRDocumentosSuccess({
          consultaData: consultaData,
       
        }))

      } catch (e) {
        console.error(e.message);
      }
    }

  } catch (err) {
    console.error("get documentos pendientes:" + err);
  }

}

function* consultarDataDocument(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let estado = false;
  let documentData = false;
  let ultimoLote = false;

  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      //.where('estado', '==', 0)
      .get();

    documentosSnapshot.forEach(doc => {
      //console.log('getOCRDocumentos fecha', moment(doc.data().fecha_creacion.toDate()).format("ll, h:mm:ss a") );
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    const documentosSnapshotLote = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)

    yield documentosSnapshotLote.orderBy("fecha_creacion", "desc").limit(1).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() contiene los datos del último 
          ultimoLote = doc.id
          // console.log("Último documento:", ultimoLote);
        });
      })
      .catch(function (error) {
        console.error("Error al obtener el último documento:", error);
      });

    const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${ultimoLote}/Documentos`)
      .get();
    documentosOCRSnapshotCargados.forEach(doc => {
      documentData = doc.data();

      if (documentData.datosDocumento.estado === 0) {
        estado = 'Cargado en bucket'
      } else if (documentData.datosDocumento?.estado === 1) {
        estado = 'Orden de procesamiento'
      } else if (documentData.datosDocumento?.estado === 2) {
        estado = 'En procesamiento'
      } else if (documentData.datosDocumento?.estado === 3) {
        estado = 'Procesado'
      } else if (documentData.datosDocumento?.estado === 4) {
        estado = 'Procesado con error'
      }
      const dataDocument = {
        lote: ultimoLote,
        fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        name: documentData.datosDocumento.name,
        size: documentData.datosDocumento.tamano,
        id: doc.id,
        //resumen: documentData.respuesta.extraccion.textoFull ? documentData.respuesta.extraccion.textoFull : '',
        estado: estado
      };

      if (documentData.datosDocumento.estado !== 3) {
        consultaData.push(dataDocument);
      }
    });

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  return consultaData
}


function* procesarDocs(action) {


  //  console.log("procesarDocs", action)
  const user = getFromSession("currentUser");
  let resp = false;
  let response = false;
 
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  let datosLote = action.value

  for (const doc of datosLote) {

    const LoteRefUpdate = yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${doc.lote}`);
    LoteRefUpdate.update({
      estado: 1
    })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });
  }
  for (const doc of datosLote) {

    const LoteRefUpdate = yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${doc.lote}`);
    LoteRefUpdate.update({
      estado: 1
    })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });
      let documentoActualizar = false;
      yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${doc.lote}/Documentos/`).doc(doc.id).get().then(docs =>{
        documentoActualizar = docs.data();
    
    });
    
    if(documentoActualizar.datosDocumento.estado !== 3){
 
      const docRefUpdate = yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${doc.lote}/Documentos/`).doc(doc.id);
      docRefUpdate.update({
        'datosDocumento.estado': 1
      })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });
      
    }

  }

  // response = yield consultarDataDocument()

  // console.log("RESPONSE PROC DOC", response)
  // yield onSnapshot(datosLote)
  console.log("PROCESAR DOCUMENTOS", action)
  yield put(proOCRDocumentosSuccess(
    action.value
  ))

}

// function* compararArray(action) {
//   console.log("array1", action.array1)
//    console.log("array2", action.array2) 
//    console.log("estado", action.estado) 

//   if (action.array1.length !== action.array2.length) {
//     return false;
//   }

//   for (let i = 0; i < action.array1.length; i++) {
//     if (action.array1[i][action.estado] !== action.array2[i][action.estado]) {
//       return false;
//     }
//   }

//   return true;
// }




function* onSnapshot(action) {
  let dataDocument = false;
  //console.log("onSnapshot lotes", action)
  const user = getFromSession("currentUser");
  let resp = false;
  let response = [];

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action[0].lote}/Documentos/`)
    .onSnapshot((snapshot) => {
      //console.log("snapshot", snapshot)
      snapshot.docChanges().forEach((change) => {
        //console.log("CAHANGE", change.type)
        if (change.type === "modified") {
          //console.log("change", change.type)
          const documentData = change.doc.data()

          const dataDocument = {
            lote: action[0].lote,
            fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
            name: documentData.datosDocumento.name,
            size: documentData.datosDocumento.tamano,
            id: change.doc.id,
            resumen: documentData.respuesta?.extraccion?.textoFull ? documentData.respuesta?.extraccion?.textoFull : '',
            estado: documentData.datosDocumento.estado
          };

          response.push(dataDocument)

          console.log("responseresponse", response)
        }
      });
    });
  yield consultarDataDocument();

  //  yield put(getOCRDocumentosSuccess(response))
}

function* getMaxSize(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let sizeMaxDoc = false;
  let sizeMaxDocMB = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MAX_SIZE_DOC');

    const maxSizeDoc = yield docRef.get();
    if (maxSizeDoc.exists) {
      sizeMaxDocMB = maxSizeDoc.data().value;
      sizeMaxDoc = sizeMaxDocMB * 1048576
      //console.log('Valor del campo:', sizeMaxDoc);
    } else {
      console.log('El documento no existe.');
    }
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  yield put(getOcrSizeSuccess({
    valueMB: sizeMaxDocMB,
    value: sizeMaxDoc
  }));
  return resp
}


function* getDocumentosProcesados(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let ultimoLote = false;
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      // .where('estado', '==', 1)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    const documentosSnapshotLote = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)

    yield documentosSnapshotLote.orderBy("fecha_creacion", "desc").limit(1).get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() contiene los datos del último 
          ultimoLote = doc.id
          console.log("Último documento:", doc.data());
        });
      })
      .catch(function (error) {
        console.error("Error al obtener el último documento:", error);
      });



    const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${ultimoLote}/Documentos`)
      .where('datosDocumento.estado', '==', 3).get();

    documentosOCRSnapshot.forEach(doc => {
      const documentData = doc.data();
      // console.log("consultaDatas", documentData)
      const dataDocument = {
        lote: ultimoLote,
        fecha_cargue: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        name: documentData.datosDocumento.name,
        size: documentData.datosDocumento.tamano,
        id: doc.id,
        resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
        fecha_proc: moment(documentData.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a")
      };
      consultaData.push(dataDocument);


    });


    //    console.log("documentos procesados", consultaData)

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  yield put(documentosProcesadosSuccess({ consultaData }))

}

function* getDocumentosFallidos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      .where('estado', '==', 1)
      .where('estado', '==', 2)
      .where('estado', '==', 4).get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        .where('datosDocumento.estado', '==', 4).get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        const dataDocument = {
          lote: id,
          fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
          name: documentData.datosDocumento.name,
          size: documentData.datosDocumento.tamano,
          id: doc.id,
          resumen: documentData.resumen ? documentData.resumen : ''
        };
        consultaData.push(dataDocument);
      });
    }
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosFallidosSuccess(consultaData))
}


function* mostrarDocumentosProcesados(action) {
  console.log("ACTION MOSTRAR DOCUMENTOS PROCESADOS SAGA DOCS", action)
  let user = getFromSession("currentUser");
  let dataVistaPrevia = [];
  let resp = false
  let dataDocument = false;
  let datosRender = {};
  let showDataDocument = [];
  let DataBD = false;
  let nuevoArreglo = false;
  const resultado = {};
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('DOCUMENT_FORM');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
          //console.log('datadata DOCUMENT_FORM', DataBD);
          // for (const document in data) {
          //   if (data.hasOwnProperty(document)) {
          //     const objetoInterno = data[document];
          //     //console.log(`Objeto externo: ${document}`);
          //     for (const claveInterna in objetoInterno) {
          //       let valorInterno = objetoInterno[claveInterna];
          //       //console.log(`claveInterna: ${claveInterna}`);
          //       if (claveInterna === 'tipoDocumento') {
          //         dataVistaPrevia.push({
          //           tipoDocumento: valorInterno
          //         })
          //       }
          //       console.log(`**************************************+`);
          //       if (typeof (valorInterno) === 'object') {
          //         valorInterno.map((element) => {
          //           dataVistaPrevia.push({
          //             campos: element
          //           })
          //         })
          //       }
          // if (objetoInterno.hasOwnProperty(claveInterna)) {

          //   if (typeof(valorInterno) === 'object') {
          //     valorInterno.map((element)=>{
          //       console.log(`Clave: ${claveInterna}`);
          //       console.log("TIpos Elemento", element)

          //   })
          //   }
          //   // dataVistaPrevia.push(valorInterno)
          // }
          //     }

          //   }

          // }
        } else {
          console.log('El documento no existe');
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  console.log('DataBD*********', DataBD)
  let arrayCheckbox = []
  let arrayText = []
  let arraysolo = []
  let arregloNew = {};

  let arregloNew2 = {};
  let arregloNew3 = {};
  let textoFull = false;
  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)

      .get().then(docs => {
        docs.forEach(doc => {
          //console.log('datosDocumento', doc.data())




          if (doc.data().datosDocumento.name === action.value.name) {

            //console.log("doc.data().datosDocumento.name", doc.data().datosDocumento.name)
            //console.log("action.value.name", action.value.name)
            dataDocument = doc.data()?.respuesta.pagina
            textoFull = doc.data()?.respuesta.texto_Full
            //console.log("dataDocument", JSON.stringify(dataDocument))


            let separador2 = '-'
            let separador = '/'
            dataDocument.forEach((objeto) => {

              if (objeto.etiqueta.includes(separador)) {
                //console.log("objeto", objeto)
                const etiqueta = objeto.etiqueta.split("/")[0];
                const nombreEtiqueta = objeto.etiqueta.split("/")[1];
                if (etiqueta) {

                  arregloNew[etiqueta] = arregloNew[etiqueta] || [];

                  for (const propiedad in objeto.value) {
                    //console.log("PROPIEDAD", propiedad)
                    if (propiedad === 'booleanValue') {
                      arregloNew[etiqueta].push({
                        [nombreEtiqueta]: objeto.value.booleanValue,
                      });
                    }
                  }

                }
              }
              else if (objeto.etiqueta.includes(separador2)) {
                const etiqueta = objeto.etiqueta.split("-")[0];
                if (etiqueta) {
                  arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

                  if (objeto.value.subdatos) {
                    arregloNew2[etiqueta].push(
                      objeto.value.subdatos.map((subdato) => ({
                        [subdato.Type]: subdato.mentiontext,
                      }))
                    );
                  }
                }
              } else {
                
                const etiqueta = objeto.etiqueta;
                if(objeto.value.texto !== ''){
                  arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
                  arregloNew3[etiqueta].push(objeto.value.texto)
                }
              }
            });


        
            resultado.checkbox = arregloNew;
            resultado.campoUnico = arregloNew3;
            resultado.texto = arregloNew2;
            resultado.textoFull = textoFull;

            //console.log("resultado", JSON.stringify(resultado));

            if (DataBD) {

              if (DataBD.length !== 0) {
                DataBD.forEach((element) => {
                  //console.log("ELMENT DATA DB", doc.data().respuesta.extraccion.tipoDeDocumento)
                  //console.log("ELMENT DOCUMENT FORM DATA DB", element.tipoDocumento)

                  if (doc.data().respuesta?.tipoDeDocumento === element.tipoDocumento) {
                    //console.log("doc.data().respuesta?.tipoDocumento ", doc.data().respuesta?.tipoDocumento )
                    //console.log("element.tipoDocumento ", element.tipoDocumento )
                    element.campos.forEach((e) => {
                      

                      let arraySubdatos = [];
                      for (const propiedad in resultado.texto) {
                        if (typeof resultado.texto[propiedad] === "object") {
                          for (const propiedadSub in resultado.texto[propiedad]) {
                            // Imprime el elemento de la matriz
                            resultado.texto[propiedad][propiedadSub].forEach((data) => {
                              //console.log("e.value, data", e.value, Object.keys(data).toString())
                              if (e.value === Object.keys(data).toString()) {
                               // console.log("e.value, data", e.value, Object.keys(data))
                                //datosRender[propiedadSub] =  Object.values(data).toString();
                                // Agregamos una variable para almacenar los valores del arreglo
                                let valores = Object.values(data);

                                arraySubdatos.push(valores)

                              }
                            })
                          }
                        } else {
                          // Imprime el valor del objeto
                        }
                      }
                      console.log("arraySubdatos", arraySubdatos);
                      
                      datosRender[e.value] = arraySubdatos
                      for (const propiedad in resultado) {

                        if (typeof resultado[propiedad] === "object") {
                          for (const propiedadSub in resultado[propiedad]) {
                            //console.log("e.value", e.value)
                            //console.log("propiedadSub", propiedadSub)
                            if (e.value === propiedadSub) {
                              datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                            }

                            //console.log("resultado.texto", JSON.stringify(resultado.texto))
                          }
                        } else if (e.value === propiedad) {
                          datosRender[propiedad] = resultado[propiedad];
                        }
                      }

                    });

                  }
                })
              }
            }
            if (Object.keys(datosRender).length === 0) {
              datosRender = resultado;
            }


            //console.log('datosDocumento if', doc.data().respuesta.extraccion)
            //dataDocument = doc.data().respuesta.extraccion
            // for (const key in dataDocument) {
            //   if (dataDocument.hasOwnProperty(key)) { // Para asegurarte de que solo consideras las propiedades propias del objeto
            //     for (let i = 0; i < dataVistaPrevia.length; i++) {
            //       if (key === dataVistaPrevia[i]) {
            //         //datosRender.push(key);
            //         //console.log(`La clave ${key} coincide con el elemento ${dataVistaPrevia[i]} en el arreglo.`);
            //         //console.log("dataDocument", dataDocument)
            //         datosRender[key] = dataDocument[key];
            //         console.log("datosRender", datosRender)

            //       }
            //     }
            //   }
            //   showDataDocument = datosRender
            // } 
          }
        });
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  // console.log("dataVistaPrevia",dataVistaPrevia)
  //  console.log("datosRender", datosRender)
  //  console.log("dataDocument", dataDocument)
  //  if(datosRender.length !== 0){
  //    showDataDocument = datosRender
  //    console.log("Arreglo no vacio")
  //   }else if(datosRender.length === 0){
  //     console.log("Arreglo vacio")
  //     showDataDocument = dataDocument
  //   }
  // if (dataVistaPrevia.length === 0) {
  //   showDataDocument = dataDocument
  // }


  //console.log("resultadoresultado", JSON.stringify(resultado))

  // arrayCheckbox.forEach((subarreglo) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado[clave] = [];
  //   }

  //   resultado[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // arraysolo.forEach(() => {

  // })

  // console.log("arrayText", arrayText)
  // // arrayText.forEach((subarreglo) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado2[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado2[clave] = [];
  //   }

  //   resultado2[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // arrayText.forEach((subarreglo, i) => {
  //   const clave = subarreglo[0]; // Obtener la primera posición del subarreglo
  //   const valor = subarreglo[1]; // Obtener la segunda posición del subarreglo

  //   if (!resultado[clave]) {
  //     // Si la clave no existe en el resultado, crear un nuevo arreglo
  //     resultado[clave] = valor;
  //   }

  //   resultado[clave].push(valor); // Agregar el valor al arreglo correspondiente
  // });

  // const arregloNuevo = [];

  // arraysolo.forEach((objeto) => {
  //   console.log("OBJETO", objeto)
  //   const objetoNuevo = {
  //     label: objeto.etiqueta,
  //     value: [objeto.text],
  //   };

  //   arregloNuevo.push(objetoNuevo);
  // });
  // const objetoNuevo = arraysolo.reduce((resultado, objeto) => {
  //   resultado[objeto.etiqueta] = [objeto.text];

  //   return resultado;
  // }, {});

  // console.log(objetoNuevo);


  // console.log(arregloNuevo);
  // console.log("resultado2", resultado)
  // console.log("resultado2", resultado2)
  // console.log("objetoNuevo", objetoNuevo)

  // resultado3.checkbox = resultado;
  // resultado3.texto = resultado2;
  // resultado3.solo = objetoNuevo;
  // console.log("arrayText", arrayText)
  //console.log("showDataDocument", extraccionDocument.result.Documentos_Base64[0].pagina)
  //console.log("datosRender", datosRender)

 
  yield put(showDocumentosProcesadosSuccess(datosRender));
}

function* downloadDoc(action) {
  console.log("URL PDF SAGA IMAGE", action)
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put(downloadDocuSuccess())
}


function* crossDoc(action) {
  //console.log("mis documentos CROSS", action.value)
  let user = getFromSession("currentUser");
  let cruzar = false;
  let resp = false
  let cruce = false;
  let documentos = [];
  let consultaData = [];
  let showDocument = false;
  let valoresParaCruce = [];
  let DataBD = false;
  let valorCorrespondiente = false;
  let datosDocPrincipal = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('CRUCE_CONF');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  //console.log("DataBD", DataBD)

  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .doc(action.value.id)
      .get().then(doc => {
        datosDocPrincipal = {
          nombre: doc.data().datosDocumento.name,
          tipoDocumento: doc.data().respuesta?.tipoDeDocumento
        }
        const BdTipoDocumento = doc.data().respuesta?.tipoDeDocumento;
        //console.log("dataDB", DataBD)
        if (DataBD) {

          DataBD.forEach((element) => {
            //console.log("elment DATABD", element)

            Object.values(element).forEach((resp) => {
              //console.log("resp elment DATABD", resp)
              valoresParaCruce.push(resp)
              if (resp === BdTipoDocumento) {
                cruzar = true;
              }

            })
          })
        }
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  valoresParaCruce = valoresParaCruce.filter((valor, indice, self) => self.indexOf(valor) === indice);

  //console.log("ARRAY DE CRUCE", valoresParaCruce)


  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //.where('estado', '==', 1)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  if (cruzar) {
    for (const id of documentos) {
      const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
        //.where('respuesta.extraccion.id', '==', cruce)
        .get();

      documentosOCRSnapshot.forEach(doc => {
        const documentData = doc.data();
        if (documentData.respuesta) {
          documentData.respuesta.pagina.forEach((resp) => {
            valoresParaCruce.forEach((val) => {
              if (resp.etiqueta === val) {
                //console.log("resp.etiqueta", resp)
                valorCorrespondiente = resp.value.texto;
              }
              if (resp.value.subdatos) {
                resp.value.subdatos.forEach((subadto) => {
                  Object.values(subadto).forEach((dato) => {

                    //console.log("subadto", dato, val, subadto.mentiontext);
                    if (dato === val) {
                      //console.log("coinciden")
                      valorCorrespondiente = subadto.mentiontext;
                    }
                  })
                })
              }
            })
          })
        }

        //console.log("documentData", documentData)
      });
    }

    console.log("valorCorrespondiente", valorCorrespondiente)

    //for (const id of documentos) {
      const ref = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
        .get().then((snapshot) => {
          snapshot.forEach(doc => {

            const datos = doc.data();
            //console.log("datos", doc.data())
            if (datos?.respuesta?.pagina) {
              const elementos = datos.respuesta.pagina.map((pagina) => {
                
              //  console.log("pagina", pagina)
                
                if (pagina.value.texto === valorCorrespondiente) {
                  consultaData.push({
                    lote: action.value.lote,
                    fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                    name: datos?.datosDocumento.name,
                    size: datos?.datosDocumento.tamano,
                    id: doc.id,
                    resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                    estado: datos?.datosDocumento.estado
                  });
                }
                Object.values(pagina).forEach((dato) => {
                  if (dato.subdatos) {
                    dato.subdatos.forEach((subdato) => {
                      if (subdato.mentiontext === valorCorrespondiente) {
                        consultaData.push({
                          lote: action.value.lote,
                          fecha_cargue: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: datos?.datosDocumento.name,
                          size: datos?.datosDocumento.tamano,
                          id: doc.id,
                          resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                          estado: datos?.datosDocumento.estado
                        });
                      }
                    })
                  }

                })
              });
            }
          })
        });
      // const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      //   .where('respuesta.extraccion.id', '==', valorCorrespondiente)
      //   .get();
      //let documentosOCRSnapshot = false;



      //for (let valor of valoresParaCruce) {
      //let estado = false;
      //   console.log("valorCorrespondiente", valorCorrespondiente)
      //console.log("id doc", id)
      const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
        .get()
        .then((docs) => {
          const documentosFiltrados = [];
          docs.forEach(doc => {
            const data = doc.data();
            //console.log("data", data)

            const pagina = data?.respuesta?.pagina;

            for (let valor of valoresParaCruce) {
              if (valor === data?.respuesta?.tipoDeDocumento) {
                if (pagina && Array.isArray(pagina)) {
                  //console.log("pagina", pagina)
                  // Buscamos en todos los elementos de respuesta.pagina
                  for (const item of pagina) {
                    if (item.value && item.value.subdatos && Array.isArray(item.value.subdatos)) {
                      //console.log("item.value.subdatos", item.value.subdatos)
                      const subdato = item.value.subdatos.find(sub => sub.mentiontext === valorCorrespondiente);
                      if (subdato) {
                        consultaData.push({
                          lote: action.value.lote,
                          fecha_cargue: data.respuesta?.fecha_de_procesamiento ? moment(data.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                          name: data.datosDocumento.name,
                          size: data.datosDocumento.tamano,
                          id: doc.id,
                          resumen: data.respuesta?.texto_Full ? data.respuesta?.texto_Full : '',
                          estado: data.datosDocumento.estado
                        });
                        //break; // Terminamos la búsqueda en esta página al encontrar una coincidencia
                      }
                    }
                  }
                }
              }
            }

          });
          // documentosFiltrados contiene los documentos que cumplen con la condición
        }).catch((error) => {
          console.log("error", error)
        });

      // const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
      // //.where('datosDocumento.estado', '==', 1)
      // .get();




      // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`).where("pagina.value.subdatos", "array-contains", { Type: 'Nombre_Menor', mentiontext: valorCorrespondiente })
      //   .get()
      //   .then((querySnapshot) => {
      //     querySnapshot.forEach((doc) => {
      //       // Aquí puedes acceder a los documentos que cumplen con la condición
      //       console.log("Documento encontrado:", doc.id, doc.data());
      //     });
      //   })
      //   .catch((error) => {
      //     console.error("Error al realizar la consulta:", error);
      //   });






      // Recorremos el campo pagina
      // Comparamos el elemento con el valor almacenado en la variable
      //return pagina === valor;

      // Imprimimos el resultado de la comparación

      //}




      // documentosOCRSnapshot.forEach(doc => {
      //   const documentData = doc.data();
      //   const dataDocument = {
      //     lote: id,
      //     fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
      //     name: documentData.datosDocumento.name,
      //     size: documentData.datosDocumento.tamano,
      //     id: doc.id,
      //     resumen: documentData.resumen ? documentData.resumen : ''
      //   };
      //   consultaData.push(dataDocument);
      //   if (consultaData.length !== 0) {
      //     showDocument = true
      //   }

      //   //console.log("documentData", documentData)
      // });
    //}
  }

  //console.log("consultaData", consultaData)

  if (consultaData.length !== 0) {
    showDocument = true
  }



  yield put(crossDocSuccess({
    consultaData: consultaData,
    showDocument: showDocument,
    datosDocPrincipal: datosDocPrincipal
  }))
}

function* getPlanes(action) {
  console.log("GET PLANES OCR", action)
  let fecha_prueba_gratis = false
  const user = getFromSession("currentUser");

  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  let planChatbot = resp.productoChatbot?.plan
  let planGratis = resp.productoChatbot?.pruebaGratis
  let planOcr = resp.productoOCR?.plan
  //let tipo = resp.producto?.tipo
  let respuesta = [];
  yield firebaseDatabase.collection(`planes/${action.value}/subplanesOCR`).get().then(docs => {
    docs.forEach(doc => {
      if (planOcr) {

        if (planOcr === 'Gratis') {
          fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

          const fecha_hoy = new Date()
          fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

          const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

          // Convierte la diferencia de milisegundos a días
          const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

          // Redondea el resultado a un número entero
          fecha_prueba_gratis = Math.round(dias)

          if (planOcr === doc.data().title && action.value === 'mensual') {
            if (fecha_prueba_gratis >= 15 || planGratis === false) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else if (planOcr === doc.data().title && action.value === 'anual') {
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }
          }
          else {
            respuesta.push({
              ...doc.data(),
              key: doc.id
            });
          }

        }

        if (planOcr === 'Pymes') {
          fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

          const fecha_hoy = new Date()
          fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

          const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

          // Convierte la diferencia de milisegundos a días
          const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

          // Redondea el resultado a un número entero
          fecha_prueba_gratis = Math.round(dias)

          if (planOcr === doc.data().title && action.value === resp.productoChatbot?.tipo) {
            if (fecha_prueba_gratis >= 30) {
              respuesta.push({
                ...doc.data(),
                key: doc.id
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else if (planOcr === doc.data().title && action.value === resp.productoChatbot?.tipo) {
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu suscripción terminó',
                actualizar: true
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else {
            respuesta.push({
              ...doc.data(),
              key: doc.id
            });
          }

        }
      }
      else {
        respuesta.push({
          ...doc.data(),
          key: doc.id
        });
      }

    });
  });
  // console.log("RESPUESTAS PLANES", respuesta)
  yield put(getPlanesSuccess(respuesta));
}
function* finSuscribcion(action) {
  const user = getFromSession("currentUser");
  //console.log("user", user)
  let resp = false;
  let fecha_prueba_gratis = false
  let fecha_hoy = false;
  let producto = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const docResp = yield docRef.get();
    producto = docResp.data()
    if (docResp.exists) {
      if (docResp.data().productoOCR.fecha_compra) {


        fecha_prueba_gratis = docResp.data().productoOCR.fecha_compra

        fecha_hoy = new Date()
        fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

        const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

        // Convierte la diferencia de milisegundos a días
        const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

        // Redondea el resultado a un número entero
        fecha_prueba_gratis = Math.round(dias)
        if (producto?.productoChatbot?.plan === 'Gratis') {
          if (fecha_prueba_gratis >= 15) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoChatbot.planActivo': false,
              'ocr_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                console.log('Elemento actualizado correctamente');
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        } else if ((!producto.productoOCR.pruebaGratis && producto.productoOCR.pruebaGratis === false) && producto.productoOCR.plan === 'Pymes' && producto.productoOCR.tipo === 'mensual') {
          if (fecha_prueba_gratis >= 30) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoOCR.planActivo': false,
              'ocr_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                console.log('Elemento actualizado correctamente');
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        } else if ((!producto.productoOCR.pruebaGratis && producto.productoOCR.pruebaGratis === false) && producto.productoOCR.plan === 'Pymes' && producto.productoOCR.tipo === 'anual') {
          if (fecha_prueba_gratis >= 365) {
            const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);
            const producto_adquirido = {
              'productoOCR.planActivo': false,
              'ocr_activo': false
            };

            // yield docRefCompany.set(producto_adquirido);
            yield docRefCompany.update(producto_adquirido)
              .then(() => {
                console.log('Elemento actualizado correctamente');
                //updateIcon = true
                // console.log("updateICON", updateIcon)
              })
              .catch((error) => {
                console.error('Error al actualizar el elemento:', error);
              });
          }
        }
      }

    }

  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }
  console.log('fecha_prueba_gratis', fecha_prueba_gratis);

  console.log('plan', producto?.productoOCR?.plan);
  console.log('tipo', producto?.productoOCR?.tipo);
  yield put(finSuscribcionSuccess({
    fecha_prueba_gratis: fecha_prueba_gratis,
    plan: producto?.productoOCR?.plan,
    tipo: producto?.productoOCR?.tipo
  }));
}


function* adquirirProducto(action) {
  console.log("adquirirProducto", action)
  let plan = false;
  let fecha_suscripcion = new Date();
  let producto = false;
  let valor = false;
  let pruebaGratis = false;
  let productoAdquirido = [];
  let resp = false;
  let producto_adquirido = false;

  const user = getFromSession("currentUser");

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  if (action.id === '00001') {
    pruebaGratis = true
    plan = 'Gratis'
  }
  if (action.id === '00002') {
    plan = 'Pymes'
    valor = '89000'
  }
  if (action.id === '00003') {
    plan = 'Empresarial'
  }
  if (action.id === '00004') {
    plan = 'Empresarial'
  }
  if (action.id === '00005') {
    plan = 'Gratis'
    pruebaGratis = true
  }
  if (action.id === '00006') {
    plan = 'Pymes'
    valor = '1068000'
  }



  if (action.producto === 'ChatBot AutoGestionable') {
    producto = 'chatbot'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: false,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoChatbot: productoAdquirido
    };

  } else if (action.producto === 'Reconocimiento de Imágenes') {
    producto = 'OCR'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        planActivo: true,
        pruebaGratis: false,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoOCR: productoAdquirido
    };
  }

  if (resp.producto) {
    if (resp.productoChatbot) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          console.log('Elemento actualizado correctamente');
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
    if (resp.productoOCR) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          console.log('Elemento actualizado correctamente');
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
  } else {
    const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

    yield docRefCompany.update(producto_adquirido)
      .then(() => {
        console.log('Elemento actualizado correctamente');
        //updateIcon = true
        // console.log("updateICON", updateIcon)
      })
      .catch((error) => {
        console.error('Error al actualizar el elemento:', error);
      });
  }



}
function* getTransaccion(action) {
  let company = false;
  let resp = false;
  let ref = action.referencia;
  let productoBD = false;
  let transaccionAprobada = false;
  let pruebaGratis = false;
  let plan = false;
  let valor = false;
  let fechaFin = false;
  let fechaIni = new Date();
  const user = getFromSession("currentUser");
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        company = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }


  if (!action.data) {
    if (action.id === '00001') {
      pruebaGratis = true
      plan = 'Gratis'
    }
    if (action.id === '00002') {
      plan = 'Pymes Mensual'
      valor = 89000

    }
    if (action.id === '00003') {
      plan = 'Empresarial'
    }
    if (action.id === '00004') {
      plan = 'Empresarial'
    }
    if (action.id === '00005') {
      plan = 'Gratis'
      pruebaGratis = true
    }
    if (action.id === '00006') {
      plan = 'Pymes'
      valor = 1068000
    }
    if (plan === 'Gratis') {
      yield adquirirProducto(action)
    }
    else {


      const headers = {
        "Authorization": 'Bearer prv_test_NuNWT77DIyXLrpRIt3T25pR2wXrfnCmn'
      }

      const urlDespliegue = `https://sandbox.wompi.co/v1/transactions?reference=${ref}`;

      resp = yield call(axios.get, urlDespliegue, { headers });

      if (resp.data.data.length === 1) {
        productoBD = resp.data.data[0]
        yield adquirirProducto(action)


        if (action.producto === 'ChatBot AutoGestionable') {

          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/CHATBOT/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        } else {
          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/OCR/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        }




        const messages = `<p>¡Hola!</p> 
      
      <p>Haz adquirido ${action.producto} en el plan ${plan},  por valor de ${valor}</p>`

        const body = {
          from_: "Compra productos Avanti <chatasesores@gmail.com>",
          to_: `${user.mail}`,
          subject_: `${action.producto} Avanti-it`,
          body: messages,
          user: "chatasesores@gmail.com",
          pass: "wdnwdcqgpdhwoskg"
        }
        const mail = yield call(axios.post, process.env.REACT_APP_CHATASESORES_SEND_MAIL, body, headers);

      }
    }
    yield put(getTransaccionSuccess(productoBD));
  }
}



export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(UPLOAD_BUCKET, uploadToBucket);
  yield takeLatest(OCR_CREATE, ocrCreate);
  yield takeLatest(OCR_ACTIVO, isOcrEnabled);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(PRO_OCR_DOC, procesarDocs);
  yield takeLatest(GET_OCR_SIZE, getMaxSize);
  yield takeLatest(GET_DOC_PROC, getDocumentosProcesados);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(CROSS_DOC, crossDoc);
  yield takeLatest(GET_PLANES, getPlanes);
  yield takeLatest(FIN_SUSCRIBCION, finSuscribcion);
  yield takeLatest(GET_TRANSACCION, getTransaccion);
  yield takeLatest(UPLOAD_DOC_SUCCESS, realTime);
  yield takeLatest(UPLOAD_BUCKET_SUCCESS, realTime);
  //yield takeLatest(COMPARAR_ARRAY, compararArray);










}
