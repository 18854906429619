import {
    UPDATE_FIELD_ATTRIBUTE,
    CONVS_EN_CURSO,
    CONVS_EN_CURSO_SUCCESS,
    CONVS_EN_CURSO_ERROR,
    CHANGE_FLAG,

    USUARIOS_EN_ESPERA,
    USUARIOS_EN_ESPERA_SUCCESS,
    USUARIOS_EN_ESPERA_ERROR,

    CONVS_CERRADAS,
    CONVS_CERRADAS_SUCCESS,
    CONVS_CERRADAS_ERROR,

    CARGA,
    CARGA_SUCCESS,
    CARGA_ERROR,

    TMPESP_PROM,
    TMPESP_PROM_SUCCESS,
    TMPESP_PROM_ERROR,

    TMP_RESPUESTA,
    TMP_RESPUESTA_SUCCESS,
    TMP_RESPUESTA_ERROR,

    CONVS_RESUELTAS,
    CONVS_RESUELTAS_SUCCESS,
    CONVS_RESUELTAS_ERROR,

    PORC_SATISFACCION,
    PORC_SATISFACCION_SUCCESS,

    INFO_AGENTES,
    INFO_AGENTES_SUCCESS,
    INFO_AGENTES_ERROR,

    CONVS_ABANDONADAS,
    CONVS_ABANDONADAS_ERROR,
    CONVS_ABANDONADAS_SUCCESS,

    MOSTRAR_RANGOS,
    OCULTAR_RANGOS,
    UPD_AGENTES_UPDATE,
    UPD_AGENTES_UPDATE_SUCCESS,
    UPD_AGENTES_UPDATE_ERROR,
    UPD_AGENTES_DONE_SUCCESS,
    CERRAR_SESION_AGENTE,
    OCULTAR_ESTADISTICAS,
    OCULTAR_ESTADISTICAS_SUCCESS,
    OCULTAR_ESTADISTICA_ENCUESTA,
    OCULTAR_ESTADISTICA_ENCUESTA_SUCCESS,
    OCULTAR_ESTADISTICA_ENCUESTA_ERROR,

} from './constants'

export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}



export function obtenerConvsEnCurso(payload) {


    return {
        type: CONVS_EN_CURSO,
        payload
    }

}

export function obtenerConvsEnCursoSuccess(payload) {


    return {
        type: CONVS_EN_CURSO_SUCCESS,
        payload
    }

}

export function obtenerConvsEnCursoError(payload) {

    return {
        type: CONVS_EN_CURSO_ERROR,
        payload
    }
}


export function obtenerUsuariosEnEspera(payload) {

    return {
        type: USUARIOS_EN_ESPERA,
        payload
    }
}
export function obtenerUsuariosEnEsperaSuccess(payload) {
    //console.log('PAYLOAD', payload);

    return {
        type: USUARIOS_EN_ESPERA_SUCCESS,
        payload
    }
}

export function obtenerUsuariosEnEsperaError(payload) {
    return {
        type: USUARIOS_EN_ESPERA_ERROR,
        payload
    }
}


export function obtenerConvsCerradas(payload) {

    return {
        type: CONVS_CERRADAS,
        payload
    }
}

export function obtenerConvsCerradasSuccess(payload) {


    return {
        type: CONVS_CERRADAS_SUCCESS,
        payload
    }
}

export function obtenerConvsCerradasError(payload) {

    return {
        type: CONVS_CERRADAS_ERROR,
        payload
    }
}

export function obtenerCarga(payload) {
    return {
        type: CARGA,
        payload
    }
}
export function obtenerCargaSuccess(payload) {
    return {
        type: CARGA_SUCCESS,
        payload
    }
}

export function obtenerCargaError(payload) {
    return {
        type: CARGA_ERROR,
        payload
    }
}

export function obtenerTmpEspPromedio(payload) {
    return {
        type: TMPESP_PROM,
        payload
    }
}


export function obtenerTmpEspPromedioSuccess(payload) {
    return {
        type: TMPESP_PROM_SUCCESS,
        payload
    }
}

export function obtenerTmpEspPromedioError(payload) {
    return {
        type: TMPESP_PROM_ERROR,
        payload
    }
}

export function obtenerTmpRespuesta(payload) {
    return {
        type: TMP_RESPUESTA,
        payload
    }
}

export function obtenerTmpRespuestaSuccess(payload) {
    return {
        type: TMP_RESPUESTA_SUCCESS,
        payload
    }
}

export function obtenerTmpRespuestaError(payload) {
    return {
        type: TMP_RESPUESTA_ERROR,
        payload
    }
}


export function obtenerConvsResueltas(payload) {
    return {
        type: CONVS_RESUELTAS,
        payload
    }
}
export function obtenerConvsResueltasSuccess(payload) {

    //console.log('CONVSRESUELTAS', payload);
    return {
        type: CONVS_RESUELTAS_SUCCESS,
        payload
    }
}
export function obtenerConvsResueltasError(payload) {
    return {
        type: CONVS_RESUELTAS_ERROR,
    }
}

export function obtenerPorcSatisfaccion(payload) {
    return {
        type: PORC_SATISFACCION,
        payload
    }
}
export function obtenerPorcSatisfaccionSuccess(payload) {
    return {
        type: PORC_SATISFACCION_SUCCESS,
        payload
    }
}

export function obtenerInfoAgentes(payload) {
    return {
        type: INFO_AGENTES,
        payload
    }

}


export function obtenerInfoAgentesSuccess(payload) {

    return {
        type: INFO_AGENTES_SUCCESS,
        payload
    }

}

export function obtenerInfoAgentesError(payload) {
    return {
        type: INFO_AGENTES_ERROR,
        payload
    }
}

export function obtenerPorcConvsAbandonadas(payload) {
    return {
        type: CONVS_ABANDONADAS,
        payload
    }
}

export function obtenerPorcConvsAbandonadasSuccess(payload) {
    return {
        type: CONVS_ABANDONADAS_SUCCESS,
        payload
    }
}
export function obtenerPorcConvsAbandonadasError(payload) {
    return {
        type: CONVS_ABANDONADAS_ERROR,
        payload
    }
}

export function mostrarCamposRangos() {
    return {
        type: MOSTRAR_RANGOS

    }
}
export function ocultarCamposRangos() {
    return {
        type: OCULTAR_RANGOS

    }
}

export function updAgente(payload) {
    return {
        type: UPD_AGENTES_UPDATE,
        payload
    }
}

export function updAgenteSuccess(payload) {
    return {
        type: UPD_AGENTES_UPDATE_SUCCESS,
        payload
    }
}

export function updAgenteError() {
    return {
        type: UPD_AGENTES_UPDATE_ERROR
    }
}

export function updAgenteDoneSuccess(payload) {
    return {
        type: UPD_AGENTES_DONE_SUCCESS,
        payload
    }
}
export function cerrarSesionAgente(payload) {
    return {
        type: CERRAR_SESION_AGENTE,
        payload
    }
}

export function ocultarEstadisticasConvsAbandonadas(payload) {
    return {
        type: OCULTAR_ESTADISTICAS,
        payload
    }
}
export function ocultarEstadisticasConvsAbandonadasSuccess(payload) {
    return {
        type: OCULTAR_ESTADISTICAS_SUCCESS,
        payload
    }
}
export function ocultarEstadisticaEncuesta(payload) {
    return {
        type: OCULTAR_ESTADISTICA_ENCUESTA,
        payload
    }
}

export function ocultarEstadisticaEncuestaSuccess(payload) {
    return {
        type: OCULTAR_ESTADISTICA_ENCUESTA_SUCCESS,
        payload
    }
}

export function ocultarEstadisticaEncuestaError(payload) {
    return {
        type: OCULTAR_ESTADISTICA_ENCUESTA_ERROR,
        payload
    }
}

export function changeFlag(payload) {
    return {
        type: CHANGE_FLAG,
        payload
    }
}

