import React, { Component } from "react";
import "./style.css";
import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { COMPANY_DATA, CROSS_DOC, DOWNLOAD_DOC, SHOW_DOC_PROC } from "./constants";
import SideBar from "../../../../components/sidebar/sidebar";
import { menuItemsOCR } from "../../../../Constants";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFromSession } from "../../../../controller/session";
import { getDataCompanySelector, selectorCrossDocument, selectorLoadingCircular } from "./selectors";
import { selectorDatosDocPrincipal, selectorShowCrossDocument } from "../selectors";
import PDFViewer from "./ocrTable/vistaPreviaDoc";
import ComponentError from "../errorPopUpComponent";
import { InputText } from "primereact/inputtext";

class ComponentDocumentosCruzados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idDoc: false,
            filtroTable: [],
            pdfData: false,
            bodyError: 'Por favor ingrese al apartado de administracion y configure un parametro de cruce',
            labelTable: {
                columna1: 'Fecha de cargue',
                columna2: 'Nombre',
                columna3: 'Resumen',
                columna4: 'Acciones',
            }
        }

    }

    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { crossDocumentSelector } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = crossDocumentSelector.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }


    componentWillMount() {
        const {
            crossDoc,
            crossDocu,
            companyData,
            cargarDocumento
        } = this.props;
        companyData();
        if (cargarDocumento) {
            //crossDocu()
        } else {
            crossDoc();
        }
    }

    pdfData = (newData) => {
        this.setState({ pdfData: newData });
    }

    idDocumento = (newData) => {
        const {
            mostrarDocumentosProcesados,
            showDocumentSelector
        } = this.props;
        this.setState({ idDoc: newData });
        mostrarDocumentosProcesados(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
        //console.log("DATA DOC CRUCE", newData)
    }

    componentDidUpdate(prevProps) {
        const { crossDocumentSelector } = this.props;

        if (prevProps.crossDocumentSelector !== crossDocumentSelector) {
            // console.log("intentsData", intentsData)
            //  console.log("prevProps.intentsData", prevProps.intentsData)
            this.setState({
                filtroTable: [...crossDocumentSelector]
            })
            //getOCRDocumentos()
        }


    }


    descargarDocumento = (newData) => {
        const {
            downloadDoc
        } = this.props;
        console.log("descargarDocumento", newData)
        downloadDoc(newData)
        //EJECUTAR FUNCION QUE TRAE LOS DATOS DEL PDF PARA VISTA PREVIA
    }
    handlerClose = () => {
        this.setState({ pdfData: false });

    }


    render() {
        const {
            data,
            pdfData,
            selectorDataCompany,
            cargarDocumento,
            handlerCloseCruce,
            loading,
            crossDocumentSelector,
            showCrossDocumentSelector,
            showDocumentSelector,
            selectorDatosDocPrincipal,
            showDocument
        } = this.props;
        console.log("selectorDatosDocPrincipal", selectorDatosDocPrincipal)
        const user = getFromSession("company_id")
        return (





            <>
                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}
                {loading &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                }
                {!loading && !showCrossDocumentSelector ?
                    <ComponentError
                        onModalCancelError={handlerCloseCruce}
                        errorUpload={this.state.errorUpload}
                        errorTitle={'Error cruzando documentos'}
                        body={this.state.bodyError}
                    />
                    :
                    ''
                }
                {this.state.pdfData ?

                    <PDFViewer
                        handlerClose={this.handlerClose}
                        pdfUrl={this.state.pdfData}
                        idDoc={showDocument}
                    /> :
                    <Paper elevation={10} className="main-wrapper home container">

                        <div className="button-ocr-left">
                            <Button onClick={() => handlerCloseCruce()} color='primary' variant="contained" style={{ "margin": "10px" }}>Volver</Button>
                        </div>
                        <div className="search-container">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                            </span>

                        </div>


                        <ComponentTableDoc
                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                            downloadDoc={this.descargarDocumento}
                            labelTable={this.state.labelTable}
                            id={user}
                            pdfData={this.pdfData}
                            idDocumento={this.idDocumento}
                            data={this.state.filtroTable}
                            title={'Cruce'} />
                    </Paper>
                }
            </>





        );
    }
}

const mapStateToProps = state => {
    return {
        selectorDataCompany: getDataCompanySelector(state),
        crossDocumentSelector: selectorCrossDocument(state),
        loading: selectorLoadingCircular(state),
        showCrossDocumentSelector: selectorShowCrossDocument(state),
        selectorDatosDocPrincipal: selectorDatosDocPrincipal(state),


    };
};

const mapDispachToProps = dispatch => {
    return {
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        crossDoc: (value) => dispatch({ type: CROSS_DOC, value }),
        //mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),





    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDocumentosCruzados);