import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";
import { Button, TextField, TextareaAutosize  } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

class ComponentText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            respuesta: props.respuestaText? props.respuestaText : '',
        }
    }
     onTextHandler = (e) => {
        this.setState({
            respuesta: e.target.value
        })
    //console.log("COMPONENTE TEXTO", this.state.respuesta)
        this.onSendHandler(e)
    }

     onSendHandler = (e) => {
        const { onChange, index } = this.props;
        onChange({ value: e.target.value, index, type: 'text' })
    }

    componentDidMount() {
    }

    render() {
        const user = getFromSession("currentUser");

        return (
            <div className="formGroup-text">
                <TextareaAutosize 
                onChange={this.onTextHandler} 
                value={this.state.respuesta} 
                placeholder="Ingrese la respuesta">
                </TextareaAutosize>
            </div>
        );
    }
}

export default ComponentText;