import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from "@material-ui/core";
import "./style.css";

const File = (props) => {
    //console.log("PROPES", props.uploadToBucket)
    const [files, setFiles] = useState([]);
    const [fileSend, setFileSend] = useState([]);

    const handleDragEnd = (result) => {
        if (!result.destination) return; // No destination, do nothing

        const newFiles = Array.from(files);
        const [reorderedFile] = newFiles.splice(result.source.index, 1); // Remove dragged file
        newFiles.splice(result.destination.index, 0, reorderedFile); // Insert file at new position

        setFiles(newFiles); // Update state with new file order
        console.log("FILESS DRAG", files)
    };
    const handleDeleteFile = (fileId) => {
        console.log("FILES DELETE", files)
        console.log("FILES DELETE ID", fileId)
        const updatedFiles = files.filter((file) => file.name !== fileId
        );
        setFiles(updatedFiles);
        setFileSend(updatedFiles);
    };

    const handleFileChange = (event) => {
        const fileList = event.target.files;

        if (fileList && fileList.length > 0) {
            const allowedFormats = ['application/pdf', 'image/png', 'image/jpeg'];
            const validFiles = [];

            for (const file of fileList) {
                if (allowedFormats.includes(file.type)) {
                    validFiles.push(file); // Agregar el objeto de archivo completo
                } else {
                    alert(`El archivo '${file.name}' no tiene un formato permitido.`);
                }
            }

            if (validFiles.length > 0) {
                // Si hay archivos válidos, agregarlos al estado
                setFiles([...files, ...validFiles]);
                setFileSend([...files, ...validFiles]);
            }
        }
    };
    const handlerFileUpload = () => {
        props.dataDocument(fileSend)
        //props.uploadToBucket()
        setFiles([])
        setFileSend([])
    }

    return (
        <>
            <>
                <div className='file-upload-title'>
                    Reconocimiento de documentos e imágenes a texto
                </div>
                <p className='file-upload-description' style={{ "font-size": "1.25rem" }}>
                    El convertidor de imagen a texto le permite extraer texto de la imagen
                </p>
            </>


            <div className='dragdrop'>
                <CloudUploadIcon style={{ "fontSize": "120px" }} />
                <h2 style={{ "padding": "20px" }}>Seleccione los archivos en el recuadro inferior</h2>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div className='droppable-container' {...provided.droppableProps} ref={provided.innerRef}>
                                <div className='input-file'>
                                    <input type="file" multiple onChange={handleFileChange} />
                                </div>
                                <ul className='document-list'>
                                    {files.map((fileSend, index) => (
                                        <Draggable key={fileSend.id} draggableId={fileSend.id} index={index}>
                                            {(provided) => (
                                                <li
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        padding: '8px',
                                                        margin: '4px',
                                                        border: '1px solid gray',
                                                        borderRadius: '4px',
                                                        background: 'white',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <span>{fileSend.name}</span>
                                                    <span>{fileSend.size} bytes</span>
                                                    <DeleteIcon style={{ "cursor": "pointer" }} onClick={() => handleDeleteFile(fileSend.name)}>Eliminar</DeleteIcon>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                                </ul>
                                {provided.placeholder}
                                <Button onClick={() => handlerFileUpload()} color='primary' variant="contained">Cargar Documentos</Button>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <p style={{ "fontSize": "14px" }}>
                Puede cargar archivos con extensión JPEG, PNG, PDF.
            </p>

            <div className='description-tool-container'>
                <div className='description-tool-box'>
                    <section className='description-tool-section'>
                        <div className='description-tool-item'>
                            <h4 className='description-tool-title'>
                                Cómo cargar documentos
                            </h4>
                            <FileCopyIcon style={{ "fontSize": "40px" }} />
                            <div className='description-tool-text'>
                                <p style={{ "textAlign": "justify" }}>
                                    Para comenzar a trabajar con el documento o documentos, haga clic en el botón "Elegir archivo" para cargarlos. También tiene la opción de arrastrar y soltar el archivo o archivos en el área marcada como "Sin archivos seleccionados". Una vez que haya cargado el archivo o archivos, simplemente haga clic en el botón "Cargar Documentos".

                                </p>
                            </div>
                        </div>
                        <div className='description-tool-item'>

                            <h4 className='description-tool-title'>
                                Cómo extraer texto de un documento
                            </h4>
                            <AutorenewIcon style={{ "fontSize": "40px" }} />
                            <div className='description-tool-text'>

                                <p style={{ "textAlign": "justify" }}>
                                    Después de hacer clic en el botón "Cargar Documentos", el sistema te dirigirá a una ventana donde podrás visualizar todos los documentos cargados en una tabla. Desde aquí, podrás seleccionar uno o varios documentos de los cuales deseas extraer los datos. Para hacerlo, simplemente selecciona el documento o todos los documentos deseados y luego haz clic en el botón "Procesar Documentos".
                                </p>
                            </div>
                        </div>
                        <div className='description-tool-item'>

                            <h4 className='description-tool-title'>
                                Ver datos procesados
                            </h4>
                            <CheckCircleIcon style={{ "fontSize": "40px" }} />
                            <div className='description-tool-text'>

                                <p style={{ "textAlign": "justify" }}>
                                    Una vez que los documentos hayan sido cargados y procesados, podrás ver los datos resultantes del proceso. Para acceder a estos datos, simplemente haz clic en el botón "Documentos Procesados", ubicado en la parte superior derecha de la ventana donde se procesaron los documentos. Al hacerlo, se mostrará una tabla que contiene todos los documentos que fueron procesados. En esta tabla, encontrarás una columna titulada "Acciones". Para visualizar el documento y sus datos, basta con hacer clic en el primer botón de vista previa, identificado por un icono de un ojo.
                                </p>
                            </div>
                        </div>

                        {/* <div className='description-tool-item'>
                            <h4 className='description-tool-title'>
                                Extraer solo texto
                            </h4>
                            <div className='description-tool-text'>
                                Utiliza la herramienta "Extraer texto PDF" para extraer el contenido de los objetos de texto
                                únicamente. Todos los demás tipos de objetos serán descartados. Se aplica la función OCR
                                automáticamente si no se encuentran objetos de texto.
                            </div>
                        </div> */}
                    </section>
                </div>

            </div>
        </>
    );
};
//Para ver las recomendaciones sobre la carga de documento haga clic aquí
export default File;
