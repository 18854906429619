import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import "./style.css";
import ComponentAutogestionable from './component';

import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';

import { AuthProvider } from '../../components/authprovider/auth';
import { getFromSession } from '../../controller/session';
import { Redirect } from 'react-router-dom';

const rootReducer = combineReducers({
    form: formReducer,
    reducer
});

const formStore = createStore(rootReducer);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);

export default class AutobotsPage extends Component {
    render() {
        const currentUser = getFromSession('currentUser');
        return (
            <AuthProvider>
                <Provider store={formStore}>
                    <Provider store={store}>
                        <Container className='main-wrapper home' >
                            {
                                currentUser ?  <ComponentAutogestionable /> :
                                <Redirect to="/auth"/>
                            }
                        </Container>
                    </Provider>
                </Provider>
            </AuthProvider>
        );
    }
}
