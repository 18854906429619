import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../../controller/session";
import { Button } from "@material-ui/core";

class ComponentInfoTooltip extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    render() {
        const {
        } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="modal-wrapper-infotooltip">
                <div className="modal-background-infotooltip" onClick={() => this.props.onInfoClickClose()}> </div>
                <div className="modal-content-infotooltip">
                    <h2 style = {{"margin":"10px"}}>Tipos de respuesta</h2>
                    <div className="description-text-tooltip">
                        <h4><ul>
                            <li style = {{"margin-bottom":"15px"}}>
                                <strong>Texto</strong>: Ingresa el mensaje que te gustaría que tu chatbot
                                respondiera. Por ej: "Cómo se llama la empresa".
                                Como respuesta ingresa "Mi empresa se llama X".
                            </li>
                            <li style = {{"margin-bottom":"15px"}}>
                                <strong>Adjunto</strong>: Puedes eviar como respuesta adjuntos de tipo (Imágen, video, pdf, archivos de word, excel y audios).
                                para ello deberas dar clic sobre la opción de respuesta "Adjuntos", seleccionar el archivo de respuesta
                                dar clic en el botón subir, podrás ver en la parte superior el nombre del archivo cargado, cuando veas
                                el nombre podrás hacer clic en la opción guardar respuesta
                            </li>
                            <li style = {{"margin-bottom":"15px"}}>
                                <strong>Botón</strong>: Con esta opción podrás darle la opción a tus clientes de selección por ejemplo "Te puedo ayudar en algo más"
                                "Si", "No". Recuerda que por cada opción deberas crear una nueva pregunta a la cual se direccionara al
                                cliente
                            </li>
                            <li style = {{"margin-bottom":"15px"}}>
                                <strong>Listas</strong>: Puedes mostrarle a tus clientes listas con opciones de los productos, vienes o servicios que les ofreces
                                por ej: como titulo podrias poner: "Te ofrecemos las siguientes opciones", como subtitulo :"Seleccione alguno de los siguientes elementos"
                                y como elementos de la lista podrias poner "productos", "servicios", "contactos". Recuerda que por cada opción deberas crear una nueva pregunta a la cual se direccionara al
                                cliente
                            </li>
                        </ul>
                        </h4>
                    </div>
                    <div className="align-button-auto">
                        <Button variant="contained" color="secondary" onClick={() => this.props.onInfoClickClose()}>Cerrar</Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentInfoTooltip);