import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../controller/session";

import CustomCardPlanes from "../../components/customcard-planes/customcardPlanes";
import ComponentConfPagos from "./pagos/confirmarPagos";

class ComponentMensual extends Component {
    constructor(props) {
        super(props);
         
        this.state = {
            activePath: '/',
            confirmar: false,
        }

    }

    componentWillMount() {

    }

    onModalWhatsAppCancel = () => {
        this.setState({ confirmar: false }
        )
    }


    render() {
        const { planesMensuales, actionCard } = this.props;

        const user = getFromSession("currentUser");

        return (
            <div className="App borrador-container">
             

                {planesMensuales && 
                    <CustomCardPlanes
                        items={
                            planesMensuales
                        }
                        onItemClick={actionCard} 
                />
                }

            </div>

        );
    }
}

export default ComponentMensual;