export const GET_USERS = '@users/getAll';
export const GET_USERS_SUCCESS = '@users/getUsersSuccess';
export const GET_USERS_ERROR = '@users/getUsersError';
export const LOAD_MENU = '@autogestionable/loadMenu';
export const LOAD_MENU_SUCCES = '@autogestionable/loadMenuSucess';
export const ADD_ITEM = '@autogestionable/agregarItem';
export const UPLOAD_IMAGE_SUCCESS = '@autogestionable/uploadImageSuccess';
export const UPLOAD_IMAGE = '@autogestionable/uploadImage';
export const UPLOAD_INTENT = '@autogestionable/uploadIntent';
export const UPLOAD_INTENT_SUCCESS = '@autogestionable/uploadIntentSuccess';
export const CREATE_AGENT = '@autogestionable/createAgent';
export const CREATE_AGENT_SUCCESS = '@autogestionable/createAgentSuccess';
export const GET_BOTS = '@autogestionable/getBots';
export const GET_BOTS_SUCCESS = '@autogestionable/getBotsSuccess';
export const UPDATE_PROFILE = '@autogestionable/updateProfile';
export const UPDATE_PROFILE_SUCCESS = '@autogestionable/updateProfileSuccess';
export const GET_INTENT = '@autogestionable/getIntents';
export const GET_INTENTS_SUCCESS = '@autogestionable/getIntentsSucccess';
export const DELETE_BOTS = '@autogestionable/deleteBots';
export const DELETE_INTENT = '@autogestionable/deleteIntent';
export const DELETE_INTENT_SUCCESS = '@autogestionable/deleteIntentSuccess';
export const EDIT_INTENT = '@autogestionable/editIntent';
export const EDIT_INTENT_SUCCESS = '@autogestionable/editIntentSuccess';
export const CREATE_DIALOGFLOW_INTENT = '@autogestionable/createDialogflowIntent';
export const CREATE_DIALOGFLOW_INTENT_SUCCESS= '@autogestionable/createDialogflowIntentSuccess';
export const DELETE_DIALOGFLOW_AGENT = '@autogestionable/deleteDialogflowAgent';
export const DELETE_DIALOGFLOW_AGENT_SUCCESS= '@autogestionable/deleteDialogflowAgentSuccess';
export const DELETE_DIALOGFLOW_INTENT = '@autogestionable/deleteDialogflowIntent';
export const DELETE_DIALOGFLOW_INTENT_SUCCESS= '@autogestionable/deleteDialogflowIntentSuccess';
export const LIST_DIALOGFLOW_INTENT = '@autogestionable/listDialogflowIntent';
export const LIST_DIALOGFLOW_INTENT_SUCCESS= '@autogestionable/listDialogflowIntentSuccess';
export const UPDATE_DIALOGFLOW_INTENT = '@autogestionable/updateDialogflowIntent';
export const UPDATE_DIALOGFLOW_INTENT_SUCCESS= '@autogestionable/updateDialogflowIntentSuccess';
export const UPLOAD_ICON = '@autogestionable/uploadIcon';
export const UPLOAD_ICON_SUCCESS= '@autogestionable/uploadIconSuccess';
export const REQUESTS_BACK = '@autogestionable/requestBack';
export const REQUESTS_BACK_SUCCESS = '@autogestionable/requestBackSuccess';
export const NOMBRE_BOT = '@autogestionable/nombreBot';
export const NOMBRE_BOT_SUCCESS = '@autogestionable/nombreBotSuccess';
export const BOT_ACTIVO = '@autogestionable/botActivo';
export const BOT_ACTIVO_SUCCESS = '@autogestionable/botActivoSuccess';
export const LIST_CLOUD_PROJECTS = '@autogestionable/listCloud';
export const LIST_CLOUD_PROJECTS_SUCCESS = '@autogestionable/listCloudSuccess';
export const FIN_SUSCRIBCION = '@autogestionable/finSuscribcion';
export const FIN_SUSCRIBCION_SUCCESS = '@autogestionable/finSuscribcionSuccess';
export const GET_PLANES = '@autogestionable/getPlanes';
export const GET_PLANES_SUCCESS = '@autogestionable/getPlanesSuccess';
export const GET_TRANSACCION_SUCCES = '@autogestionable/getTransaccionSuccess'
export const GET_TRANSACCION = '@autogestionable/getTransaccion'








export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];
