import CustomField from './customfield';
import React from 'react'
import { reduxForm } from 'redux-form';
import CustomButton from '../custombutton/button';

const CustomReduxTemplateForm = (props) => {
  const { items, formName, dataObject, updateAction, styleFormgroup, onEnterFunction, formRow,
    itemsButtons, positionButton, styleButton} = props;
  
  
  return (
    <div key={`form-${formName}`} name={formName} className={formRow}  >
      { dataObject && items && items.map((field, j) => {
        /* console.log('FIELD', field.); */
        const displayNone = 'displayNone'
        const classnameStyle = field.display? `form-${styleFormgroup}`: displayNone;
      
        return <div key={j} className={classnameStyle}>
          <CustomField
            id={j}
            label={field.label}
            hint={field.hint}
            type={field.type}
            typeForm={field.typeForm}
            value={field.value?field.value:dataObject[field.name]}
            options={field.options}
            disable={field.disable}
            placeholder={field.placeholder}
            updateAction={updateAction}
            name={field.name}
            onEnterFunction={onEnterFunction}
            optionlabel={field.optionlabel}
            labelClass={field.labelClass}
            />
        </div>
      }
      )}

      <CustomButton items={itemsButtons} positionButton={positionButton} styleButton={styleButton}></CustomButton>
    
    </div>
  )
}

export default reduxForm({
  form: "customSector",              // <------ same form name
  destroyOnUnmount: false,     // <------ preserve form data
})(CustomReduxTemplateForm)