import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsPerfil, PATH_API } from '../../Constants';

import { 
  GET_USERS, LOAD_MENU,
} from './constants';

import { 
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
} from './actions';

import { firebaseDatabase } from "../../controller/firebase";
import { getFromSession } from "../../controller/session";

function* getUsers() {
  const urlUsers = `${ PATH_API }${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
      method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if(fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const {data} = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImagePerfilUsuario(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsPerfil.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}



export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImagePerfilUsuario);
}
