import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

class ComponentConfirmar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorBot: false,
            colorBottom: false
        }

    }

    componentWillMount() {
        const { errorTitle } = this.props
        if (errorTitle === 'Error creando la pregunta') {
            this.setState({
                colorBottom: 'secondary',
            });
        } else {
            this.setState({
                colorBottom: 'primary',
            });
        }

    }

    render() {
        const {
            title,
            body,
            cerrarModal,
            botonText
        } = this.props;

        return (
            <>

                <div className="modal-background"  onClick={() => { cerrarModal() }} ></div>
                <div class="modal-content">

                    <div class="modal-header">
                        <h5 class="modal-title">{title}</h5>
                        <button onClick={() => { cerrarModal() }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>{body}</p>
                    </div>
                    <div class="modal-footer">
                        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
                        <button type="button" onClick={() => { cerrarModal() }} class="btn btn-primary">{botonText}</button>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispachToProps = dispatch => {
    return {

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentConfirmar);