import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
/* import './form.css'; */
import MaterialUiForm from './form';
import CustomButton  from '../custombutton/button';
import { Button, ButtonGroup, Switch } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from 'redux'
import { reduxForm } from 'redux-form';
import KeyboardEventHandler from 'react-keyboard-event-handler';

class CustomReduxForm extends Component {
    render() {
        const { 
            items, 
            formTitle, 
            className, 
            formName, 
            buttons, 
            handleSubmit,
            handleUpload,
            handleRemove, 
            card, 
            formSubTitle, 
            submitable, 
            validations,
            invalid, 
            pristine, 
            reset, 
            handleSafe,
            submitting,
            positionButtons,
            positionFields,
            aditionalElement,
            handleDownload,
            dynamicDisable,
        } = this.props;

        return (
                card ? 
                <Card key={`card-${formName}`} className={`card-${className}`}>
                    <Card.Header>
                        {formTitle}
                        <p>{formSubTitle}</p>
                    </Card.Header>
                    <Card.Body>
                        <form tabIndex={0} className={`form-container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={!invalid ? ['enter'] : undefined}
                                onKeyEvent={handleSubmit}>
                                <MaterialUiForm 
                                    submitable={submitable} 
                                    items={items} 
                                    validations={validations} 
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit} 
                                    handleUpload={handleUpload}
                                    handleDownload ={handleDownload}
                                    handleRemove={handleRemove}
                                    dynamicDisable={dynamicDisable}
                                    handleSafe={handleSafe}
                                    buttons={buttons}
                                    positionFields={positionFields}
                                />
                            </KeyboardEventHandler>
                            {
                            buttons ?   
                            <div className="button-wrapper">
                                <ButtonGroup color="primary" aria-label="primary button group">
                                    {
                                        buttons.map((button,i) => {
                                            return (
                                            <Button
                                                key={`button-${button.label}`} 
                                                variant={button.variant}
                                                color={button.style}
                                                type={`${submitable ? 'submit' : 'button'}`}
                                                className={button.className}
                                                onClick={!submitable ? handleSubmit : undefined}
                                                disabled={button.primary ? invalid : (pristine || submitting)}
                                                size={button.size}
                                            >
                                                {button.label}
                                            </Button>
                                            )
                                        }) 
                                    }
                                </ButtonGroup>
                            </div> :''
                            // <div>
                            //     <button type="submit" disabled={pristine || submitting}>
                            //     Submit
                            //     </button>
                            //     <button type="button" disabled={pristine || submitting} onClick={reset}>
                            //     Clear Values
                            //     </button>
                            // </div>
                            }
                        </form>
                    </Card.Body>
                </Card>
                : <React.Fragment>
                        <div className={`form-wrapper wrapper-${className}`}>
                        { formTitle ? 
                            <h2 className="form-title">
                                {formTitle}
                            </h2>
                        : '' }
                        <p className="form-sub-title">
                            {formSubTitle}
                        </p>
                        <form tabIndex={0} className={`form-container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={!invalid ? ['enter'] : undefined}
                                onKeyEvent={handleSubmit}>
                                <MaterialUiForm 
                                    submitable={submitable} 
                                    items={items} 
                                    validations={validations} 
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit}
                                    handleUpload={handleUpload}
                                    handleDownload ={handleDownload}
                                    handleSafe={handleSafe}
                                    handleRemove={handleRemove}
                                    dynamicDisable={dynamicDisable}
                                    buttons={buttons}
                                    positionFields={positionFields}
                                />
                            </KeyboardEventHandler>
                            {aditionalElement?aditionalElement:false}
                            {
                            buttons ?   
                            <div className={`button-wrapper ${positionButtons?positionButtons:false}`}> 
                                <ButtonGroup color="primary" aria-label="primary button group">
                                    {
                                        buttons.map((button,i) => {
                                            return (
                                            <Button
                                                key={`button-${button.label}`} 
                                                variant={button.variant}
                                                color={button.style}
                                                type={`${submitable ? 'submit' : 'button'}`}
                                                className={button.className}
                                                onClick={!submitable ? button.primary ? handleSubmit : () => button.onClick() : undefined}
                                                disabled={button.primary ? invalid || pristine : false}
                                                size={button.size}
                                            >
                                                {button.label}
                                            </Button>
                                            )
                                        }) 
                                    }
                                </ButtonGroup>
                            </div> :''
                            // <div>
                            //     <button type="submit" disabled={pristine || submitting}>
                            //     Submit
                            //     </button>
                            //     <button type="button" disabled={pristine || submitting} onClick={reset}>
                            //     Clear Values
                            //     </button>
                            // </div>
                            }
                        </form>
                        </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: props.formName,
        validate: props.validations,
        // other props...
    }
}

export default compose(
    connect(mapStateToProps),
    reduxForm({    
        //other redux-form options...
    })
)(CustomReduxForm);