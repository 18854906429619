import {
  UPDATE_FIELD_ATTRIBUTE,
  SELECT_TAB,
  TAB_LOG_IN,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  CONNECTEDUSER_SUCCESS,
  AUTH_LOAD_PARAMS_SUCCESS,
  RECOVERY_PASS,
  RETURN_HOME,
} from './constants';

export const initialState = {
  authForm: {},
  selectedForm: {},
  error: false,
  message: false,
  logSuccess: false,
  tabSelected: `tab-${TAB_LOG_IN}`,
  logo: false,
  recoveryPass: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;
  newState.error= false;
  newState.message= false;

  switch (type) {
    case UPDATE_FIELD_ATTRIBUTE:
      const { value: { fieldName, fieldValue } } = action;
      let newFormObject = { ...newState.selectedForm };
      newFormObject[fieldName] = fieldValue;
      newState.authForm = newFormObject;
      newState.selectedForm = newFormObject;
      newState.message = false;

      return {
        ...newState
      };

    case SELECT_TAB:
      newState.tabSelected = value;
      newState.message = false;

      return {
        ...newState
      };

    case RECOVERY_PASS:
      newState.recoveryPass = true;
      return { ...newState };

    case RETURN_HOME:
      newState.recoveryPass = false;
      return { ...newState };

    case AUTH_SUCCESS:
      newState.error = false;
      newState.message = payload.message;
      newState.authForm = {};
      newState.selectedForm = {};
      newState.logSuccess = false;

      return {
        ...newState
      };

    case AUTH_ERROR:
      newState.error = true;
      newState.message = payload.message;
      newState.authForm = {};
      newState.selectedForm = {};
      newState.logSuccess = false;

      return {
        ...newState
      };

    case CONNECTEDUSER_SUCCESS:

      newState.logSuccess = false;
      console.log('LLEGO AL REDUCER');


      return {
        ...newState
      };

    case AUTH_LOGIN_SUCCESS:
      newState.error = false;
      newState.message = payload.message;
      newState.authForm = {};
      newState.selectedForm = {};
      newState.logSuccess = true;
      newState.conversationData = {};
      return {
        ...newState
      };

    case AUTH_LOAD_PARAMS_SUCCESS:
      newState.logo = payload;

      return {
        ...newState
      };


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
