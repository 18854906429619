import {
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LOAD_MENU_SUCCES
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}
export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}