import {
    COMPANY_DATA_SUCCESS,
    CROSS_DOC_SUCCESS,
    DOWNLOAD_DOC_SUCCESS,
    GET_DOC_FALL_SUCCESS,
    GET_DOC_PROC_SUCCESS,
    GET_OCR_DOC_SUCCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LOAD_MENU_SUCCES,
    SHOW_DOC_PROC_SUCCESS,
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}
export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}

export function companyDataSuccess (payload) {
    return {
        type: COMPANY_DATA_SUCCESS,
        payload
    };
}


export function getOCRDocumentosSuccess (payload) {
    return {
        type: GET_OCR_DOC_SUCCESS,
        payload
    };
}

export function documentosProcesadosSuccess (payload) {
    return {
        type: GET_DOC_PROC_SUCCESS,
        payload
    };
}

export function documentosFallidosSuccess (payload) {
    return {
        type: GET_DOC_FALL_SUCCESS,
        payload
    };
}


export function downloadDocuSuccess (payload) {
    return {
        type: DOWNLOAD_DOC_SUCCESS,
        payload
    };
}

export function showDocumentosProcesadosSuccess (payload) {
    return {
        type: SHOW_DOC_PROC_SUCCESS,
        payload
    };
}
export function crossDocSuccess (payload) {
    return {
        type: CROSS_DOC_SUCCESS,
        payload
    };
}
