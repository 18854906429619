import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { CONVS_COLAS_ATENCION, MESSAGE_COLAS_ATENCION_CERO, MESSAGE_COLAS_ATENCION_CERO_CHATBOT, MESSAGE_DATA_CERO_TRANFERENCIAS, MODAL_ESPERA_DATA, TABLE_TRANSFERENCIAS_TITLE, TITLE_TABLE_COLAS_ATENCION, TITLE_TABLE_CONTACT } from "./constants";
import { getConvsAgentes, getDataColasAtencion, getDataTableAgentes, getDataTableTransferencias, getManageSpinerState, getSelectedForm, getShowSnackBar, getStateFilterButton } from "./selectors";
import { BarChartDemo } from '../../components/Charts/barrChart';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstColasAtencionComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const {
            obtenerTablaColasAtencion,
            activarSpinner
        } = this.props
        obtenerTablaColasAtencion()
        activarSpinner()

    }

    componentDidUpdate(prevProps) {
        const { 
            obtenerTablaColasAtencion, 
            estadoBotonFiltrarRangos,
            showToast,
            activarSpinner,
            spinerState,
            showSnackBar,
         } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerTablaColasAtencion(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerTablaColasAtencion(seleccionRango)
            activarSpinner()



        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                obtenerTablaColasAtencion(rango)
                activarSpinner()
            } else {
                showToast()
            }

        }



    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }
    render() {
        const {
            dataColasAtencion,
            spinerState
        } = this.props;
        return (
            <div>
                <div>
                    <h4 className="tituloComponente" >ESTADISTICAS DE COLAS DE ATENCION</h4>
                </div>
                {spinerState.colasAtencion ? this.spinnerGraficos() : ""}
                {dataColasAtencion.data?.length > 0 ?
                    <div className="containerGeneral">
                        <div>
                            {dataColasAtencion.barChart?.datasets?.length > 0 ?
                                <BarChartDemo
                                    dataBar={dataColasAtencion.barChart?.datasets?.length > 0 ? dataColasAtencion.barChart : {}}
                                    titleBar={"SOLICITUDES EN COLA DE ATENCION Y SU PROMEDIO DE ESPERA EN MINUTOS"}
                                    tipoStyle={true}

                                />
                                : ''
                            }

                        </div>
                        <div className="p-mt-3">
                            {dataColasAtencion.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={dataColasAtencion.columnsTable?.length > 0 ? dataColasAtencion.columnsTable : []}
                                    datosTable={dataColasAtencion.data?.length > 0 ? dataColasAtencion.data : []}
                                    titleTable={TITLE_TABLE_COLAS_ATENCION}
                                    nameTable={"Colas de atención"}

                                />
                            )}
                        </div>
                    </div>
                    :
                    <div>
                        <h4 className="messageCero">{MESSAGE_COLAS_ATENCION_CERO}</h4>
                    </div>
                }


            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataColasAtencion: getDataColasAtencion(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),
    }
}


const mapDispachToProps = dispatch => {
    return {
        obtenerTablaColasAtencion: (value) => dispatch({ type: CONVS_COLAS_ATENCION, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value })

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstColasAtencionComponent);