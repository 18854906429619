export const getDynamicDisable = (state) => state.localReducer.dynamicDisable;
export const getShowSnackBar = (state) => state.localReducer.showSnackBar;
export const getFormFiles = (state) => state.localReducer.formFiles;
export const getSeeReports = (state) => state.localReducer.seeReports;
export const getManageBase = (state) => state.localReducer.manageBase;
export const getImportAndExport = (state) => state.localReducer.importAndExport;
export const getAuditLogs = (state) => state.localReducer.auditLog;
export const getStartWaiting = (state) => state.localReducer.startWaiting;
export const getToastMensaje = (state) => state.localReducer.toastMensaje;
export const getUrlAuditLog = (state) => state.localReducer.urlDialogFlow;

