import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';
import PieChart from "../../components/Charts/pieChart"

import { GRAFICOS_TEMAS, MODAL_ESPERA_DATA, TOAST_MESSAGE } from "./constants";
import {
    convsTemaNivel1,
    convsTemaNivel2,
    convsTemaNivel3,
    getManageSpinerState,
    getSelectedForm,
    getShowSnackBar,
    getStateFilterButton,
} from "./selectors";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Button } from 'primereact/button';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";




class EstTipificacionComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            iniciaComponente: true

        }
    }

    componentWillMount() {
        const { obtenerDatosGraficaTemas, activarSpinner } = this.props
        this.exportCSV = this.exportCSV.bind(this);
        obtenerDatosGraficaTemas()
        activarSpinner()
    }

    componentDidUpdate(prevProps) {
        const {
            obtenerDatosGraficaTemas,
            showToast,
            estadoBotonFiltrarRangos,
            activarSpinner
        } = this.props

        //console.log('prevProps', prevProps.selectedForm);
        //console.log('selectedForm', this.props.selectedForm);

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerDatosGraficaTemas(seleccionRango);
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            console.log('entrooooo aquiii');
            obtenerDatosGraficaTemas(seleccionRango);
            activarSpinner()
            /*   this.setState({
                  iniciaComponente: false
              }) */

        }

        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {

            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }
                obtenerDatosGraficaTemas(rango)
                activarSpinner()
            } else {
                showToast()


            }


        }

    }

    exportCSV(dt) {
        dt.exportCSV();
    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }

    render() {
        const {
            convsTemaNivel1,
            convsTemaNivel2,
            convsTemaNivel3,
            spinerState,
            showSnackBar,
        } = this.props;

        let dataNivel1 = [];
        let dataNivel2 = [];
        let dataNivel3 = [];

        if (convsTemaNivel1 && convsTemaNivel1.labels.length > 0) {
            for (let i = 0; i < convsTemaNivel1.labels.length; i++) {
                let datos = {
                    nombreNivel1: convsTemaNivel1.labels[i],
                    total: convsTemaNivel1.numeros[i]
                }
                dataNivel1.push(datos);
            }
        }

        if (convsTemaNivel2 && convsTemaNivel2.labels.length > 0) {
            convsTemaNivel2.labels.forEach((item, i) => {

                let datos = {
                    nombreNivel2: item,
                    total: convsTemaNivel2.numeros[i]
                }
                dataNivel2.push(datos);

            });

        }

        if (convsTemaNivel3 && convsTemaNivel3.labels.length > 0) {

            convsTemaNivel3.labels.forEach((item, i) => {

                let datos = {
                    nombreNivel3: item,
                    total: convsTemaNivel3.numeros[i]
                }
                dataNivel3.push(datos);

            });

        }




        return (
            <div className="  p-mt-3 p-p-3">
                {showSnackBar ? this.showSnackBar(
                    showSnackBar, ""
                )
                    : ""}

                <div>
                    <h4 className="tituloComponente">ESTADISTICAS DE TIPIFICACIONES</h4>
                </div>

                <div className="p-grid justify-content-center">

                    <div className="p-col-4 p-mt-2 ">
                        {spinerState.pieTemas ? this.spinnerGraficos() : ""}
                        <div className="">
                            <PieChart data={convsTemaNivel1}
                            ></PieChart>
                        </div>

                    </div>
                    <div className=" p-col-4 p-mt-2">
                        {spinerState.pieTemas ? this.spinnerGraficos() : ""}
                        <div className="">
                            <PieChart data={convsTemaNivel2}
                            ></PieChart>
                        </div>
                    </div>


                    {dataNivel3 && dataNivel3.length > 0 ?
                        <div className="p-grid p-mt-2 ">
                            <div className="p-col-5 center">
                                {spinerState.pieTemas ? this.spinnerGraficos() : ""}
                                <PieChart data={convsTemaNivel3}
                                ></PieChart>
                            </div>
                        </div>
                        : ""}
                </div>
                <div className="p-grid p-mt-2">
                    <div className="p-col">
                        {spinerState.pieTemas ? this.spinnerGraficos() : ""}
                        <Card>
                            <div className='p-card-header p-grid' style={{ paddingRight: '0.3rem', textAlign: 'right' }}>
                                <div className='p-card-title p-col-7' style={{ textAlign: 'end' }}>Nivel 1</div>
                                <div className='p-col'>
                                    <Button label="Descargar CSV" icon="pi pi-cloud-download" className=" p-button-help p-ml-2 p-button-sm p-p-1" onClick={() => this.exportCSV(this.dt1)} />
                                </div>

                            </div>
                            <DataTable value={dataNivel1}
                                dataKey="id" ref={(el) => this.dt1 = el}>
                                <Column field="nombreNivel1" header="Nombre"></Column>
                                <Column field="total" header="Total"></Column>
                            </DataTable>
                        </Card>
                    </div>
                    <div className="p-col">
                        {spinerState.pieTemas ? this.spinnerGraficos() : ""}
                        <Card >
                            <div className='p-card-header p-grid' style={{ paddingRight: '0.3rem', textAlign: 'right' }}>
                                <div className='p-card-title p-col-7' style={{ textAlign: 'end' }}>Nivel 2</div>
                                <div className='p-col'>
                                    <Button label="Descargar CSV" icon="pi pi-cloud-download" className=" p-button-help p-ml-2 p-button-sm p-p-1" onClick={() => this.exportCSV(this.dt2)} />
                                </div>

                            </div>
                            <DataTable value={dataNivel2}
                                dataKey="id" ref={(el) => this.dt2 = el}>
                                <Column field="nombreNivel2" header="Nombre"></Column>
                                <Column field="total" header="Total"></Column>
                            </DataTable>
                        </Card>

                    </div>
                    {dataNivel3 && dataNivel3.length > 0 ?
                        <div className="p-col">
                            {spinerState.pieTemas ? this.spinnerGraficos() : ""}

                            <Card>
                                <div className='p-card-header p-grid' style={{ paddingRight: '0.3rem', textAlign: 'right' }}>
                                    <div className='p-card-title p-col-7' style={{ textAlign: 'end' }}>Nivel 3</div>
                                    <div className='p-col'>
                                        <Button label="Descargar CSV" icon="pi pi-cloud-download" className=" p-button-help p-ml-2 p-button-sm p-p-1" onClick={() => this.exportCSV(this.dt3)} />
                                    </div>

                                </div>
                                <DataTable value={dataNivel3}
                                    dataKey="id" ref={(el) => this.dt3 = el}>
                                    <Column field="nombreNivel3" header="Nombre"></Column>
                                    <Column field="total" header="Total"></Column>
                                </DataTable>
                            </Card>
                        </div>
                        : ""}
                </div>

            </div>


        )

    }

}


const mapStateToProps = state => {

    return {
        convsTemaNivel1: convsTemaNivel1(state),
        convsTemaNivel2: convsTemaNivel2(state),
        convsTemaNivel3: convsTemaNivel3(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),

    }
}


const mapDispachToProps = dispatch => {
    return {
        obtenerDatosGraficaTemas: (value) => dispatch({ type: GRAFICOS_TEMAS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value }),
    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstTipificacionComponent);