import React, { Component } from "react";
//import "./style.css";
import { connect } from "react-redux";
import CustomForm from "../../components/materialform/customform";
import { getAuthForm, getSelectedForm, getSelectedTab, getMessage, getAuthError } from "./selectors";
import { Alert } from 'react-bootstrap';

import {
    AUTH_FORMS,
    UPDATE_FIELD_ATTRIBUTE,
    getSignButton,

    SELECT_TAB,
    TAB_SING_IN,
    SIGN_USER,
    FORM_RECOVERY,
    getModalButton,
    RETURN_HOME,
    SEND_EMAIL,
    getFormRecoveryPass
} from './constants';



class ComponentChange extends Component {

    validations = (values) => {
        const errors = {
            sectionRecovery:{}
        }
        
        if(values.sectionRecovery && values.sectionRecovery.emailRecovery){
            if (!values.sectionRecovery["emailRecovery"]) {
                errors.sectionRecovery["emailRecovery"] = 'Campo obligatorio'
            }
    
            if (
                values.sectionRecovery.emailRecovery &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.sectionRecovery.emailRecovery)
            ) {
                errors.sectionRecovery["emailRecovery"] = 'Correo inválido'
            }
        }
        

        return errors
    }

    render() {
        const {
            updateAttributes,
            selectedForm,
            tabSelected,
            returnHome,
            sendEmail,
            signUser,
            message,
            error,
        } = this.props;
        console.log("MESSAGE****", message)
        return (

            <div className="App auth-container">
                <div className='auth-list_wrapper'>
                    {message ?
                        <React.Fragment>
                            {error
                                ? <Alert className="alert danger" variant="danger">{message}</Alert>
                                : <Alert className="alert success" variant="success">{message}</Alert>
                            }
                        </React.Fragment>
                        : ''
                    }
                    {/*<TabPanel value={1} items={tabs(selectTab)} className='authentication-tabs'/>*/}
                    <CustomForm
                        formName="authFormRecovery"
                        items={getFormRecoveryPass(returnHome, sendEmail)}
                        /* formTitle = "Sign in" */
                        values={false}
                        updateAction={false}
                        validations={this.validations}
                    // buttons={false}
                    />
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        authForm: getAuthForm(state),
        selectedForm: getSelectedForm(state),
        tabSelected: getSelectedTab(state),
        message: getMessage(state),
        error: getAuthError(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        selectTab: (value) => dispatch({ type: SELECT_TAB, value }),
        signUser: (value) => dispatch({ type: SIGN_USER, value }),
        returnHome: (value) => dispatch({ type: RETURN_HOME, value }),
        sendEmail: (value) => dispatch({ type: SEND_EMAIL, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentChange);