

 function obtenerRangoFechasDiasAtras(numeroDias) {

    let numero = numeroDias;
    let rango = {};
    let hoy = new Date();
    let fechaIni;
    var fechaFin;
  
    let diasAtras = hoy.setDate(hoy.getDate() - numero);
    fechaIni = new Date(diasAtras).setHours(0, 0, 0, 0);
    fechaIni = new Date(fechaIni);
  
    if (numero === 0) {
      fechaFin = new Date()
      //fechaFin = new Date(fechaFin);
    } else if (numero === 7 || numero === 15) {
      fechaFin = new Date();
      fechaFin.setDate(fechaFin.getDate() - 1);
      fechaFin.setHours(23, 59, 59, 59);
    } else {
      fechaFin = new Date(diasAtras).setHours(23, 59, 59, 59);
      fechaFin = new Date(fechaFin);
    }
    rango['fechaIni'] = fechaIni;
    rango['fechaFin'] = fechaFin;
  
    return rango;
  }
  
  export  function obtenerRangoDeFechas(caso) {
    let numero;
    let rango = {};
    let hoy = new Date();
    let fechaIni;
    let fechaFin;

    if (typeof caso === 'object' && caso.fechaIni && caso.fechaFin) {
      return caso;
    }
    if (caso === undefined || caso === "HOY"||caso ===false) {
      numero = 0;
      rango = obtenerRangoFechasDiasAtras(numero)
    }
    else if (caso === "AYER") {
      numero = 1;
      rango = obtenerRangoFechasDiasAtras(numero)
  
    } else if (caso === "7DIAS") {
      numero = 7;
      rango = obtenerRangoFechasDiasAtras(numero)
    } else if (caso === "15DIAS") {
      numero = 15;
      rango = obtenerRangoFechasDiasAtras(numero)
    } else if (caso === "ESTA_SEMANA") {
      let dia = hoy.getDay()
      let diff = hoy.getDate() - dia + (dia === 0 ? -6 : 1);
      fechaIni = new Date(hoy.setDate(diff)).setHours(0, 0, 0, 0);
      fechaIni = new Date(fechaIni);
      fechaFin = new Date();
      fechaFin.setDate(fechaFin.getDate() - 1);
      fechaFin.setHours(23, 59, 59, 59);
      rango['fechaIni'] = fechaIni;
      rango['fechaFin'] = fechaFin;
    } else if (caso === "ESTE_MES") {
      fechaIni = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
      fechaFin = hoy;
      rango['fechaIni'] = fechaIni;
      rango['fechaFin'] = fechaFin;
    } else if (caso === "ANTERIOR_MES") {
      let mesAnterior = hoy.setMonth(hoy.getMonth() - 1);
      mesAnterior = new Date(mesAnterior);
      fechaIni = new Date(mesAnterior.getFullYear(), mesAnterior.getMonth(), 1);
      fechaFin = new Date(mesAnterior.getFullYear(), mesAnterior.getMonth() + 1, 0);
      fechaFin.setHours(23, 59, 59, 59);
      rango['fechaIni'] = fechaIni;
      rango['fechaFin'] = fechaFin;
    }
    return rango;
  }