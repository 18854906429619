import React, { Component } from 'react';
import SideNav from './sidenav';
import './sidebar.css';

export default class SideBar extends Component {
  render() {
    
    const {items, onLogout, logo, switchFormName} = this.props;
    return (
      <SideNav 
      switchFormName={switchFormName} 
      items = {items} 
      logo={logo}
      onLogout = {onLogout}>

      </SideNav>
    );
  }
}
