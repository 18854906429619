import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { BAR_AGENTE_TITLE, BAR_AGENTE_TITLE2, TITLE_TABLE_CONTACT, MESSAGE_DATA_CERO_AGENTE, CONVS_AGENTES, TOAST_MESSAGE, MODAL_ESPERA_DATA, TMP_PROM_AGENTES, CONVS_RESUELTAS_AGENTES, GRAFICO_PROM_RESP_AGENTES, GRAFICO_PROM_ESP_AGENTES, HEADER_DATOS_PROM_AGENTES, HEADER_TABLE_ATENCION_CANAL, GET_TABLA_DATOS_ATENCION_CANAL, GET_TABLA_DATOS_ATENCION_AGENTE, HEADER_TABLE_ATENCION_AGENTE, ENCUENTAS_AGENTES, ENCUESTAS_AGENTES, HEADER_TABLE_CONTACT, PAGINAR_CONVS_CERRADAS, OCULTAR_MODAL_DESCARGAR, SINGLE_BUSQUEDA, MODAL_DESCARGAR_DATOS, SELECT_CONVERSATIONDETAIL, DESCARGAR_CSV_CONVS_CERRADAS, CONVS_CERRADAS_AGENTES, CONVS_CERRADAS_AGENTES_SUCCESS, CONVS_CERRADAS_AGENTE, PAGINAR_CONVS_CERRADAS_AGENTES, DESCARGAR_CSV_CONVS_CERRADAS_AGENTES, UPDATE_FIELD_ATTRIBUTE, FILTRAR_BUSQUEDA, LANZAR_EVENTOS, LIMPIAR_FORM, FILTRAR_BUSQUEDA_AGENTE, BAR_AGENTE_TITLE3, TITLE_TABLE_CONTACT2 } from "./constants";
import { getConvsAgentes, getConvsResueltasAgentes, getDataBarTmpEspAgentes, getDataBarTmpRespAgentes, getDataTableAgentes, getSelectedForm, getStateFilterButton, getTablesAgentProm, getTmpPromConvsAgente, getDataRegistroAtencionCanal, getDataRegistroAtencionAgente, getEncuestaAgente, mensajes, verModalDescargarConversacion, ocultarGrafica, getColumns, getConvsFiltradas, getConvsCerradasPaginadas, getConsultaPaginada, getConvsToDownloadCsv, getlistaCompletaConvsPaginadas, estadosDisplay, agentesDisplay, getManageSpinerState, getShowSnackBar } from "./selectors";
import { BarChartDemo } from '../../components/Charts/barrChart';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import DataTable from './dataTable/DataTableInformes';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Card } from "primereact/card";
import PieChart from "../../components/Charts/pieChart"
import SearchAccordion from "../../components/searchaccordion/searchaccordion";
import { CSVLink } from "react-csv";
import { Button } from "primereact/button";
import { Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstAgentesComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const {
            obtenerGraficoBarrasAgentes,
            activarSpinner,
            obtenerTmpPromConvsAgentes,
            obtenerConvsResueltasAgentes,
            graficarTmpPromRespAgentes,
            graficarTmpPromEspAgentes,
            obtenerTablaAtencionCanal,
            obtenerTablaAtencionAgente,
            obtenerEncuestaAgente,
            obtenerConvsCerradasAgentes

        } = this.props
        obtenerGraficoBarrasAgentes()
        activarSpinner()
        obtenerTmpPromConvsAgentes()
        obtenerConvsResueltasAgentes()
        //        graficarTmpPromRespAgentes()
        //       graficarTmpPromEspAgentes()
        obtenerTablaAtencionCanal()
        obtenerTablaAtencionAgente()
        obtenerEncuestaAgente();
        obtenerConvsCerradasAgentes()

    }

    componentDidUpdate(prevProps) {
        const {
            obtenerGraficoBarrasAgentes,
            showToast,
            estadoBotonFiltrarRangos,
            activarSpinner,
            obtenerTmpPromConvsAgentes,
            obtenerConvsResueltasAgentes,
            graficarTmpPromRespAgentes,
            graficarTmpPromEspAgentes,
            obtenerTablaAtencionCanal,
            obtenerTablaAtencionAgente,
            obtenerEncuestaAgente,
            obtenerConvsCerradasAgentes

        } = this.props
        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            activarSpinner()
            obtenerGraficoBarrasAgentes(seleccionRango)
            obtenerTmpPromConvsAgentes(seleccionRango)
            obtenerConvsResueltasAgentes(seleccionRango)
            //graficarTmpPromRespAgentes(seleccionRango)
            //graficarTmpPromEspAgentes(seleccionRango)
            obtenerTablaAtencionCanal(seleccionRango)
            obtenerTablaAtencionAgente(seleccionRango)
            obtenerEncuestaAgente(seleccionRango);
            obtenerConvsCerradasAgentes(seleccionRango);

            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerGraficoBarrasAgentes(seleccionRango)
            obtenerTmpPromConvsAgentes(seleccionRango)
            obtenerConvsResueltasAgentes(seleccionRango)
            //graficarTmpPromRespAgentes(seleccionRango)
            //graficarTmpPromEspAgentes(seleccionRango)
            obtenerTablaAtencionCanal(seleccionRango)
            obtenerTablaAtencionAgente(seleccionRango)
            obtenerEncuestaAgente(seleccionRango);
            obtenerConvsCerradasAgentes(seleccionRango);

            activarSpinner()
        }

        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                obtenerGraficoBarrasAgentes(rango)
                obtenerTmpPromConvsAgentes(rango)
                obtenerConvsResueltasAgentes(rango)
                //  graficarTmpPromRespAgentes(rango)
                //                graficarTmpPromEspAgentes(rango)
                obtenerTablaAtencionCanal(rango)
                obtenerTablaAtencionAgente(rango)
                obtenerEncuestaAgente(seleccionRango);
                obtenerConvsCerradasAgentes(seleccionRango);


                activarSpinner()
            } else {
                showToast()
            }

        }
    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }

    render() {
        const {
            convsAgentes,
            dataConv,
            tmpPromConvsAgente,
            convsResueltasAgente,
            dataBarTmpEspAgentes,
            dataBarTmpRespAgentes,
            tablesPromAgent,
            encuestaAgentes,
            dataRegistroAtencionCanal,
            dataRegistroAtencionAgente,
            selectConversationDetail,
            abrirModalDescargarDatos,
            convsCerradasFiltered,
            mensajes,
            singleSearch,
            verModalDescargarConversacion,
            cerrarModalDescargarDatos,
            verGrafica,
            paginarConvsCerradas,
            columnsTable,
            selectedForm,
            convsCerradasPaginadas,
            consultaPaginada,
            totalConvsFiltradas,
            descargarCSV,
            convsToDownloadCsv,
            obtenerConvsCerradasAgentes,
            updateAttributes,
            filtrarBusqueda,
            estadosDisplay,
            desplegarEventosBuscador,
            agentesDisplay,
            limpiarForm,
            showToast,
            spinerState,
            showSnackBar,


        } = this.props;
        let convertirSegundos = (segundos) => {
            let respuesta;
            if (segundos > 1) {
                let valorMin = 604801;
                let intervalos = {
                    'semanas': 604800,
                    'dias': 86400,
                    'hrs': 3600,
                    'min': 60,
                    'seg': 1
                }
                for (let intervalo of Object.keys(intervalos)) {
                    let resultado = segundos / intervalos[intervalo];
                    if (resultado > 1 && resultado < valorMin) {
                        valorMin = Number((resultado).toFixed(2));
                        respuesta = valorMin.toString() + ' ' + intervalo;
                    }
                }
            } else if (segundos === 0 || segundos === "0") {
                respuesta = segundos?.toString() + ' seg';
            } else {
                respuesta = "Sin datos";
            }
            return respuesta;

        }




        return (
            <div>

                {showSnackBar ? this.showSnackBar(
                    showSnackBar, ""
                )
                    : ""}
                <div>
                {spinerState.estadisticasPorAgente ? this.spinnerGraficos() : ""}

                    <h4 className="tituloComponente" > ESTADISTICAS DE AGENTES</h4>
                </div>
                {dataConv.data?.length > 0 ?
                    <div className="containerGeneral">
                        <div className="p-grid mb-4 p-jc-center">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='Total conversaciones'>
                                    <div className="p-text-bold">{convsResueltasAgente.totalConvs ? convsResueltasAgente.totalConvs : "Sin datos"}</div>
                                </Card>
                            </div>
                        </div>
                        <h3 className="subtituloComponente">Tiempos promedios de:</h3>
                        {spinerState.estadisticasPorAgente ? this.spinnerGraficos() : ""}
                        <div className="p-grid mb-4 p-jc-center">
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='En Cola'>
                                    <div className="p-text-bold">{convertirSegundos(tmpPromConvsAgente.cola)}</div>
                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='Espera'>
                                    <div className="p-text-bold">{convertirSegundos(tmpPromConvsAgente.espera)}</div>
                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='Duración de conversación del asesor'>
                                    <div className="p-text-bold">{convertirSegundos(tmpPromConvsAgente.duracion)}</div>
                                </Card>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                <Card className="cardNumeros" title='Tiempo de Respuesta'>
                                    <div className="p-text-bold">{convertirSegundos(tmpPromConvsAgente.respuesta)}</div>
                                </Card>
                            </div>
                        </div>

                        <div>
                            {spinerState.tablaConvsPorAgente ? this.spinnerGraficos() : ""}
                            {spinerState.tablaConvsPorAgente ? this.spinnerGraficos() : ""}

                            <BarChartDemo
                                dataBar={convsAgentes}
                                titleBar={BAR_AGENTE_TITLE}
                                tipoStyle={false}
                                agente={true}

                            />
                        </div>

                        <div className="p-grid">
                            {convsResueltasAgente ? (
                                <div className="p-mt-3 p-col-4 p-ai-center">
                                    {spinerState.pieConvsResueltasAgentes ? this.spinnerGraficos() : ""}
                                    <PieChart data={convsResueltasAgente}></PieChart>
                                </div>
                            ) : false}

                            {convsResueltasAgente && convsResueltasAgente.canalesAgentes ? (
                                <div className="p-mt-3 p-col-4 p-ai-center">
                                    {spinerState.pieConvsResueltasAgentes ? this.spinnerGraficos() : ""}
                                    <PieChart data={convsResueltasAgente.canalesAgentes}></PieChart>
                                </div>
                            ) : false}
                            {encuestaAgentes ? (
                                <div className="p-mt-3 p-col-4 p-ai-center">
                                    {spinerState.pieEncuestasAgentes ? this.spinnerGraficos() : ""}
                                    <PieChart data={encuestaAgentes}></PieChart>
                                </div>
                            ) : false}


                        </div>
                        {spinerState.estadisticasPorAgente ? this.spinnerGraficos() : ""}
                        {tmpPromConvsAgente && tmpPromConvsAgente.graficoAgente ? (
                            <div>
                                <BarChartDemo
                                    dataBar={tmpPromConvsAgente.graficoAgente}
                                    titleBar={BAR_AGENTE_TITLE2}
                                    tipoStyle={false}
                                />
                            </div>
                        ) : false}
                        {spinerState.estadisticasPorAgente ? this.spinnerGraficos() : ""}
                        {tmpPromConvsAgente && tmpPromConvsAgente.graficaFueraHorario ? (
                            <div>
                                <BarChartDemo
                                    dataBar={tmpPromConvsAgente.graficaFueraHorario}
                                    titleBar={BAR_AGENTE_TITLE3}
                                    tipoStyle={false}
                                />
                            </div>
                        ) : false}


                        <div className="p-mt-3">

                            {dataConv.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={dataConv.dataFueraHorario?.length > 0 ? dataConv.columnsTableFueraHorario : []}
                                    datosTable={dataConv.columnsTableFueraHorario?.length > 0 ? dataConv.dataFueraHorario : []}
                                    titleTable={TITLE_TABLE_CONTACT2}
                                    exporExcel={false}
                                    nameTable={"Agentes"}

                                />
                            )}
                            <h4>* todos los tiempos son en <b>segundos</b></h4>

                        </div>




                        <div className="p-mt-3">

                            {spinerState.tablaConvsPorAgente ? this.spinnerGraficos() : ""}
                            {dataConv.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={dataConv.data?.length > 0 ? dataConv.columnsTable : []}
                                    datosTable={dataConv.columnsTable?.length > 0 ? dataConv.data : []}
                                    titleTable={TITLE_TABLE_CONTACT}
                                    exporExcel={false}
                                    nameTable={"Agentes"}

                                />
                            )}
                            <h4>* todos los tiempos son en <b>segundos</b></h4>

                        </div>

                        <div className="p-mt-3">
                        {spinerState.tablaDatosAtencionCanal ? this.spinnerGraficos() : ""}

                            {dataRegistroAtencionCanal ? (
                                <DataTableCrudDemo
                                    headerTable={HEADER_TABLE_ATENCION_CANAL}
                                    datosTable={dataRegistroAtencionCanal}
                                    titleTable={"Consolidado de atención por canal"}
                                    exporExcel={false}
                                    nameTable={"Agentes"}

                                />
                            ) : false}
                            <h4>* todos los tiempos son en <b>segundos</b></h4>

                        </div>

                        <div className="p-mt-3">
                            {spinerState.tablaDatosAtencionAgente ? this.spinnerGraficos() : ""}
                            {dataRegistroAtencionAgente ? (
                                <DataTableCrudDemo
                                    headerTable={HEADER_TABLE_ATENCION_AGENTE}
                                    datosTable={dataRegistroAtencionAgente}
                                    titleTable={"Consolidado de atención por agente"}
                                    exporExcel={false}
                                    nameTable={"Agentes"}

                                />
                            ) : false}
                            <h4>* todos los tiempos son en <b>segundos</b></h4>

                        </div>

                        <SearchAccordion
                            selectedForm={selectedForm} updateAttributes={updateAttributes}
                            filtrarBusqueda={filtrarBusqueda} optionsEstados={estadosDisplay} lanzarEventos={desplegarEventosBuscador}
                            optionsAgentes={agentesDisplay} getConversations={obtenerConvsCerradasAgentes} limpiarForm={limpiarForm}
                            showToast={showToast} totalConvsFiltradas={totalConvsFiltradas}
                        ></SearchAccordion>

                        <div className="p-mt-3">
                        {spinerState.tablaConvsCerradasAgentes ? this.spinnerGraficos() : ""}

                            {convsCerradasFiltered && (<DataTable
                                selectItem={selectConversationDetail} abrirModalDescargarDatos={abrirModalDescargarDatos}
                                data={convsCerradasFiltered} itemsModal={mensajes} globalFilter={singleSearch}
                                verModalDescargarConversacion={verModalDescargarConversacion} dataConv={dataConv}
                                cerrarModalDescargarDatos={cerrarModalDescargarDatos}
                                columns={columnsTable} verGrafica={verGrafica} paginarConvsCerradas={paginarConvsCerradas}
                                selectedForm={selectedForm} seleccionRangoBusqueda={this.state.seleccionRango}
                                convsCerradasPaginadas={convsCerradasPaginadas} consultaPaginada={consultaPaginada}
                                totalConvsFiltradas={totalConvsFiltradas} descargarCSV={descargarCSV} convsToDownloadCsv={convsToDownloadCsv}

                            >
                            </DataTable>)}
                            <h4>* todos los tiempos son en <b>segundos</b></h4>
                        </div>



                    </div>
                    :
                    <div>
                        <h4 className="messageCero">{MESSAGE_DATA_CERO_AGENTE}</h4>
                    </div>
                }
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        convsAgentes: getConvsAgentes(state),
        dataConv: getDataTableAgentes(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        tmpPromConvsAgente: getTmpPromConvsAgente(state),
        convsResueltasAgente: getConvsResueltasAgentes(state),
        dataBarTmpEspAgentes: getDataBarTmpEspAgentes(state),
        dataBarTmpRespAgentes: getDataBarTmpRespAgentes(state),
        tablesPromAgent: getTablesAgentProm(state),
        dataRegistroAtencionCanal: getDataRegistroAtencionCanal(state),
        dataRegistroAtencionAgente: getDataRegistroAtencionAgente(state),
        encuestaAgentes: getEncuestaAgente(state),

        // Tabla Conversaciones por agente
        convsCerradasFiltered: getConvsFiltradas(state),
        mensajes: mensajes(state),
        verModalDescargarConversacion: verModalDescargarConversacion(state),
        columnsTable: getColumns(state),
        verGrafica: ocultarGrafica(state),
        convsCerradasPaginadas: getConvsCerradasPaginadas(state),
        consultaPaginada: getConsultaPaginada(state),
        totalConvsFiltradas: getlistaCompletaConvsPaginadas(state),
        convsToDownloadCsv: getConvsToDownloadCsv(state),

        // Buscador de Conversaciones
        estadosDisplay: estadosDisplay(state),
        agentesDisplay: agentesDisplay(state),

        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),






        // dataConv: dataConv(state),






        //columnsTable: getConvsAgentes(state),

    }
}


const mapDispachToProps = dispatch => {
    return {
        obtenerGraficoBarrasAgentes: (value) => dispatch({ type: CONVS_AGENTES, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value }),
        obtenerTmpPromConvsAgentes: (value) => dispatch({ type: TMP_PROM_AGENTES, value }),
        obtenerConvsResueltasAgentes: (value) => dispatch({ type: CONVS_RESUELTAS_AGENTES, value }),
        graficarTmpPromRespAgentes: (value) => dispatch({ type: GRAFICO_PROM_RESP_AGENTES, value }),
        graficarTmpPromEspAgentes: (value) => dispatch({ type: GRAFICO_PROM_ESP_AGENTES, value }),
        obtenerTablaAtencionCanal: (value) => dispatch({ type: GET_TABLA_DATOS_ATENCION_CANAL, value }),
        obtenerTablaAtencionAgente: (value) => dispatch({ type: GET_TABLA_DATOS_ATENCION_AGENTE, value }),
        obtenerEncuestaAgente: (value) => dispatch({ type: ENCUESTAS_AGENTES, value }),

        // Tabla Conversaciones por agente
        selectConversationDetail: (value) => dispatch({ type: SELECT_CONVERSATIONDETAIL, value }),
        abrirModalDescargarDatos: (value) => dispatch({ type: MODAL_DESCARGAR_DATOS, value }),
        singleSearch: (value) => dispatch({ type: SINGLE_BUSQUEDA, value }),
        cerrarModalDescargarDatos: () => dispatch({ type: OCULTAR_MODAL_DESCARGAR, payload: 1 }),
        paginarConvsCerradas: (value) => dispatch({ type: PAGINAR_CONVS_CERRADAS_AGENTES, value }),
        descargarCSV: (value) => dispatch({ type: DESCARGAR_CSV_CONVS_CERRADAS_AGENTES, value }),
        obtenerConvsCerradasAgentes: (value) => dispatch({ type: CONVS_CERRADAS_AGENTE, value }),

        // Buscador de conversaciones
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        filtrarBusqueda: (value) => dispatch({ type: FILTRAR_BUSQUEDA_AGENTE, value }),
        desplegarEventosBuscador: (value) => dispatch({ type: LANZAR_EVENTOS, value: 1 }),
        limpiarForm: (value) => dispatch({ type: LIMPIAR_FORM, value }),











    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstAgentesComponent);