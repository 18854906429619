import { MANAGE_NAV_BUTTON, SET_URL_AUDITLOG, START_WAITING } from "./constants";

export function startWaiting(payload) {
    return {
        type: START_WAITING,
        payload
    };
}

export function manageNaveButton(payload) {
    return {
        type: MANAGE_NAV_BUTTON,
        payload
    };
}

export function setUrlAuditLog(payload) {
    return {
        type: SET_URL_AUDITLOG,
        payload
    };
}
