import React from 'react';
import "./style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Paper } from "@material-ui/core";
import { Button } from "@material-ui/core";

const PDFViewer = ({ pdfUrl, handlerClose, data, idDoc }) => {
  // ...

  function renderizarValor(valor) {
    if (Array.isArray(valor)) {
      return (
        <React.Fragment>
          {valor.map((item, index) => (
            <div key={index}>
              {renderizarValor(item)}
            </div>
          ))}
        </React.Fragment>
      );
    } else if (typeof valor === 'object') {
      return renderizarObjeto(valor);
    } else if (typeof valor === 'boolean') {
      return <input type="checkbox" checked={valor} disabled={valor} />;
    } else {
      return valor;
    }
  }

  function renderizarObjeto(objeto) {

    if (!objeto) {
      return null;
    }

    const elementosRenderizados = [];

    Object.entries(objeto).forEach(([clave, valor]) => {
      // Verificar si la clave es "checkbox", "texto" o "campoUnico" y omitirla
      if (clave !== 'checkbox' && clave !== 'texto' && clave !== 'campoUnico') {
        elementosRenderizados.push(
          <div key={clave} className='valor-render-interno'>
            <b>{clave}:</b> {renderizarValor(valor)}
          </div>
        );
      } else {
        elementosRenderizados.push(renderizarValor(valor));
      }

    });
    return (
      <React.Fragment>
        {elementosRenderizados}
        {/* <br /> Agregar un salto de línea */}
      </React.Fragment>
    );
  }

  function recorrerArrayObjeto(objeto) {
    if (!objeto) {
      return null;
    }
    const elementosRenderizados = [];
    objeto.forEach((elem) => {
      console.log(elem);
      const res = renderizarObjeto(elem.data);
      elementosRenderizados.push(
        <div className='valor-render'>{res}</div>)
    });
    return elementosRenderizados;
  }



  const elementosRenderizados = recorrerArrayObjeto(idDoc);
  return (

    <div className="App borrador-container modal-wrapper-bot" >
      {!idDoc &&

        <div className="loading-wrapper">
          <CircularProgress color="secondary"></CircularProgress>
        </div>
      }
      <div className="modal-content-ocr">
        <div className="button-ocr-left">
          <Button onClick={() => handlerClose()} color='primary' variant="contained" style={{ "marginBottom": "10px" }}>Cerrar Documento</Button>
        </div>
        <Paper elevation={10} className="container-pdf">
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            width="100%"
            height="600px"
          ></iframe>
          <div className="datos-pdf">
            <div className='respuestas-container'>
              {elementosRenderizados}
            </div>
           
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default PDFViewer;