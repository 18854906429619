import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import "./style.css";
import ComponentAuth from './component';
import ComponentSign from './componentSign';

import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    localReducer: reducer,
    form: formReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);

export default class AuthPage extends Component {
    render() {
        return (
            <Provider store={store}>
                <Container className='main-wrapper auth' >
                    <ComponentAuth urlParams={this.props.match.params} />
                    <ComponentSign />
                </Container>
            </Provider>
        );
    }
}
