
import {
  COMPANY_DATA_SUCCESS,
  GET_DOC_PROC_SUCCESS,
  GET_OCR_DOC_SUCCESS,
  GET_USERS_SUCCESS,
  GET_DOC_PROC,
  LOAD_MENU_SUCCES,
  USER_DATA,
  USER_HEADERS,
  GET_DOC_FALL_SUCCESS,
  GET_DOC_FALL,
  SHOW_DOC_PROC_SUCCESS,
  SHOW_DOC_PROC,
  DOCUMENT_FORM_SUCCESS,
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItemsOCR: false,
  dataCompany: false,
  documentData: false,
  proccessDocument: false,
  failDocument: false,
  showDoc: false
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  newState.showTable = false;
  switch (type) {
    case GET_USERS_SUCCESS:
      const { data } = payload;
      newState.users = data;

      return {
        ...newState
      };


    case COMPANY_DATA_SUCCESS:
      //console.log("reducer payload data company", payload)
      newState.dataCompany = payload;

      return {
        ...newState
      };

    case LOAD_MENU_SUCCES:
      //console.log("reducer payload chat bot", payload)
      newState.menuItemsOCR = payload;

      return {
        ...newState
      };


    case GET_OCR_DOC_SUCCESS:
      newState.documentData = payload;

      return {
        ...newState
      };





    case GET_DOC_PROC_SUCCESS:
      newState.loadingCircular = false;
      newState.proccessDocument = payload;

      return {
        ...newState
      };


    case GET_DOC_PROC:
      newState.loadingCircular = true;
      return {
        ...newState
      };

    case GET_DOC_FALL_SUCCESS:
      newState.loadingCircular = false;
      newState.failDocument = payload;

      return {
        ...newState
      };


    case GET_DOC_FALL:
      newState.loadingCircular = true;
      return {
        ...newState
      };


    case SHOW_DOC_PROC_SUCCESS:
      newState.loadingCircular = false;
      newState.showDoc = payload;

      return {
        ...newState
      };


    case SHOW_DOC_PROC:
      newState.loadingCircular = true;
      return {
        ...newState
      };





    case DOCUMENT_FORM_SUCCESS:
      newState.valuesInitialFormWeb = payload.listaPreguntas;
      newState.updateClientForm = false;
      newState.clientFormEnabled = payload.clientFormEnable;
      newState.showToast = false;
      return {
        ...newState
      }


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
