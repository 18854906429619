import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Button, Card, TextField } from "@material-ui/core";
import ComponentTable from "./intentTable/componentTable";
import ComponentCrearChatBot from "./componentCrearChatBot";
import ComponentPopUpNombreBot from "./popUpNombreBot";
import { DELETE_BOTS, DELETE_DIALOGFLOW_AGENT, GET_BOTS } from "./constants";
import { selectDeleteAgent, selectGetBots } from "./selectors";
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';

class ComponentNombreBot extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout } = this.props;
        this.state = {
            bot: false,
            popupCrearBot: false,
            labelTable: 'Mis Bots',
            botonPreguntasTabla: true,
            nombreBot: '',
            columnsTable: {
                columna1: 'Nombre Bot',
                columna2: 'Fecha Creacion'
            },
            columnField: {
                columna1: 'nombreChatBot',
                columna2: 'fecha'
            }
        }
    }

    onEditButton = (value) => {
        this.setState({ popupCrearBot: true });
        this.setState({ nombreBot: value });
    }

    onDeleteButton = (value) => {
        const { deleteBots, botsData, deleteAgent } = this.props

        const bot = botsData.find(
            val => val.nombreChatBot === value
        );

        const infoBot = {
             idBot : bot.id,
             nombreBot : bot.nombreChatBot
        }
        deleteBots(infoBot);
        deleteAgent();
    }

    onButonCrearBot = () => {
        this.setState({ popupCrearBot: true });
    }

    onModalCancel = () => {
        this.setState({ popupCrearBot: false })
    }

    componentDidMount() {
        const { getBots, deleteAgentSelector } = this.props;
        getBots(this.props.nombreBot);  
    }

    componentDidUpdate(){
        const { getBots, deleteAgentSelector } = this.props;
        if(deleteAgentSelector){
            getBots(this.props.nombreBot);
        }
    }

    onButonClickCrearPregunta = (value) => {
        this.setState({ bot: true });
        this.setState({ nombreBot: value });
    }

    render() { 
        const { botsData } = this.props;
        //  console.log("intentsData component", intentsData)
        return (
            <div className="App home-container">
                {this.state.bot ?
                    <ComponentCrearChatBot
                        nombreBot={this.state.nombreBot}
                        componenteCrearChatBot={this.state.bot}
                    />
                    :
                    <div className='chatbot-wrapper'>
                        <div className="titulo">
                            <h1>
                                General
                            </h1>
                        </div>
                        {this.state.popupCrearBot ?
                            <ComponentPopUpNombreBot
                                onModalCancel={this.onModalCancel}
                                nombreBot={this.props.nombreBot}
                            />
                            :
                            <Card className='card-container-chatbot'>
                                <div className='addBot'>
                                    <img src="https://img.freepik.com/premium-vector/cute-robot-cartoon-vector-icon-illustration-techology-robot-icon-concept-isolated-premium-vector-flat-cartoon-style_138676-1474.jpg" />
                                    <Button color='primary' variant="contained" onClick={() => this.onButonCrearBot()} >Añadir Bot</Button>
                                </div>
                                <div className="tabla-bots">
                                    {  /*   <h2>Nombre del robot</h2>
                                <TextField
                                    onChange={this.onNombreChange}
                                    id="filled-size-normal"
                                    defaultValue={this.state.nombreBot}
                                    variant="filled"
                                />*/}
                                </div>
                                <div className="tabla-intents">
                                    <ComponentTable
                                        onEdit={this.onEditButton}
                                        onAddQuestion={this.onButonClickCrearPregunta}
                                        columnField={this.state.columnField}
                                        columnsTable={this.state.columnsTable}
                                        labelTable={this.state.labelTable}
                                        data={botsData}
                                        onDelete={this.onDeleteButton}
                                        preguntasTabla={this.state.botonPreguntasTabla}
                                    />
                                </div>
                                
                                <div className="right">
                                    <Widget/>
                                </div>
                            </Card>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        botsData: selectGetBots(state),
        deleteAgentSelector: selectDeleteAgent(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getBots: () => dispatch({ type: GET_BOTS }),
        deleteBots: (value) => dispatch({ type: DELETE_BOTS, value }),
        deleteAgent:() => dispatch({type: DELETE_DIALOGFLOW_AGENT})
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentNombreBot);