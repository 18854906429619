import "./style.css";
import { InputText } from 'primereact/inputtext';
import Image from 'react-bootstrap/Image';
import moment from "moment";
import { Beforeunload } from 'react-beforeunload';
import { Button } from 'primereact/button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import { menuItemsChatBot, menuItems } from "../../Constants";
import {
    FILTER_CONVERSATIONS,
    MESSAGE_FORMS,
    GET_CONVERSATIONS,
    SELECT_CONVERSATION,
    PAGINATE_CONVERSATIONS,
    SEARCH_CONVERSATIONS,
    SELECT_ROWS_PER_PAGE,
    SEND_MESSAGE,
    TEMAS_HEADERS,
    UPDATE_FIELD_ATTRIBUTE,
    CLEAN_FORM,
    INIT_TOGGLE_STATE,
    TOMAR_CONTROL, SET_TEMA, FAQS_HEADERS, ADJUNTAR_ARCHIVOS, TRANSFER, END_CONVERSATION, MODAL_ADJUNTAR_OPEN, RESUELTA, LISTAR_TEMAS, LISTAR_USUARIOS_CONECTADOS, LISTAR_USUARIOS_CONECTADOS_CLOSE, LISTAR_TEMAS_CLOSE, MODAL_ADJUNTAR_CLOSE, LANZAR_EVENTOS, LISTENER_LOGOUT, LISTAR_FAQS, LISTAR_FAQS_CLOSE, PRINT_CONVERSATION, CLOSE_MODAL_PRINT,
    LOAD_MENU,

    TRANSFERENCIAS_CONVERSACION,
    INFO_GENERAL,
    NOTAS_OCULTAS,
    MODAL_NOTAS_OPEN,
    ADD_NOTA_OCULTA,
    CONSULTAR_DIALOG_FLOW_PROPERTY
} from './constants';
import { connect } from "react-redux";

import React, { Component } from "react";
import { Toast } from 'primereact/toast';
import {
    getConversationDataDisplay,
    getConversationId,

    getConversationsListFiltered,
    getItemsPerPage,

    getMessageForm,
    getMessagesDisplay,
    getPage,
    getPagination,
    getPlaySound, getPlaySoundConv,
    getSelectedForm,
    getTotalConversationsList,
    showSpinner, temasDisplay, usuariosDisplay, verPanelAdjuntar, verPanelTemas, verPanelTransfer,
    showSpinnerAdjuntar, showToast, toastDetail, estadosDisplay, agentesDisplay,
    sessionTimeoutSelector,
    verPanelFaqs,
    faqsDisplay,
    showSpinnnerTemas,
    showSpinnerFaqs,
    verPanelPrint,
    infoConv,
    convMensajes,
    //menuItems,
    getTransferenciasConv,
    showInfoGeneral,
    showTransferencias,
    showNotasOcultas,
    modalNotasOcutas,
    getNotasOcultasConv,
    conversacionesPaginadas,
    getConsultaPaginada,
    getConversations_,
    getLimitDiasConvs,
    getConvsFiltradas,
    getManageSpinerState,
} from "./selectors";

import ChatWindowCard from "../../components/chatCard/chatCard";
import Info from "../../components/chatCard/info";


import beep from '../../static/sounds/beep.mp3'
import classic_phone from '../../static/sounds/classic_phone.mp3'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import SideBar from "../../components/sidebar/sidebar";
import { getFromSession } from "../../controller/session";
import { Paginator } from "primereact/paginator";


class ComponentAgentPanel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            basicFirst: 0,
            basicRows: 10,
            firstLastState: 2,
            paginaAnterior: {},
            globalFilter: null,
            menu: false
        };
        this.onBasicPageChange = this.onBasicPageChange.bind(this);
        this.onBasicFilterChange = this.onBasicFilterChange.bind(this);

    }


    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { getConversations, loadMenu } = this.props;
        loadMenu();

        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
        } else {
            this.setState({ menu: menuItemsChatBot });
        }

        let limit = this.state.basicRows;
        getConversations(limit);


    }


    cerrar() {
        let company = getFromSession('company_id')
        let idDoc = getFromSession('connectedUser');
        var url = process.env.REACT_APP_AGENT_CLOSE + '?idDoc=' + idDoc + '&idCompany=' + company + '&from=xxx';
        navigator.sendBeacon(url, '');
    }
    onBasicFilterChange(text) {
        const { filterConversationslist } = this.props;

        if (text === "cleanInputSearch") {
            this.setState({ globalFilter: "" })
        }
        filterConversationslist(text)
    }
    onBasicPageChange(event) {
        const { paginarConversaciones, conversacionesPaginadas, conversations, convsFiltradas } = this.props

        let ultimaConv = false;
        let primeraConv = false;
        let nextPage = this.state.firstLastState < event.first;
        this.setState({
            firstLastState: event.first
        })
        if (convsFiltradas) {
            this.setState({
                basicFirst: event.first,
                basicRows: event.rows,
            });
            paginarConversaciones({ event, bigQuery: true, nextPage });
        } else {
            if (conversacionesPaginadas) {
                if (conversacionesPaginadas.length === event.rows && event.first > 0) {

                    ultimaConv = conversacionesPaginadas[event.rows - 1].data();
                    primeraConv = conversacionesPaginadas[0].data();
                } else {

                    ultimaConv = conversacionesPaginadas[conversacionesPaginadas.length - 1].data();
                    primeraConv = conversacionesPaginadas[0].data();
                }
            } else if (!conversacionesPaginadas && conversations) {
                if (conversations.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimaConv = conversations[event.rows - 1].data();
                    primeraConv = conversations[0].data();
                }
            }


            if (nextPage) {
                if (ultimaConv)
                    this.setState({
                        basicFirst: event.first,
                        basicRows: event.rows,
                    });
                paginarConversaciones({ event, "ultimaConv": ultimaConv, nextPage });
            } else {
                if (primeraConv)
                    this.setState({
                        basicFirst: event.first,
                        basicRows: event.rows,
                    });
                paginarConversaciones({ event, "ultimaConv": primeraConv, nextPage });
            }
        }

    }


    browser() {
        var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
        switch (true) {
            case test(/edg/i): return "Microsoft Edge";
            case test(/trident/i): return "Microsoft Internet Explorer";
            case test(/firefox|fxios/i): return "Mozilla Firefox";
            case test(/opr\//i): return "Opera";
            case test(/ucbrowser/i): return "UC Browser";
            case test(/samsungbrowser/i): return "Samsung Browser";
            case test(/chrome|chromium|crios/i): return "Google Chrome";
            case test(/safari/i): return "Apple Safari";
            default: return "Other";
        }
    }


    componentDidMount() {
        const { initToogleState } = this.props;
        initToogleState();
        /*
               window.onbeforeunload = (e) => {
                    let company = getFromSession('company_id')
                    let mail =  getFromSession('currentUser') .mail;
                    var url = process.env.REACT_APP_AGENT_CLOSE;     
                    navigator.sendBeacon(url + '?mail=' + mail + '&idCompany=' + company + '&from=zzz', '');
                }
        */
    }
    componentDidUpdate(prevProps) {
        const { playSound, playSoundConv, toastMensaje, toastDetalle, conversacionesPaginadas,
            consultaPaginada, conversations, convsFiltradas } = this.props;

        if (toastMensaje) {
            this.toast.show({ severity: toastDetalle.severity, summary: toastDetalle.summary, detail: toastDetalle.detail });
        }

        if (playSound) {
            const audioEl = document.getElementById("audio-element");
            audioEl.play();
        }
        const paginatorPrev = document.querySelector('.p-paginator-prev');
        const paginatorNext = document.querySelector('.p-paginator-next');

        if (conversacionesPaginadas) {
            if (!consultaPaginada) {
                //console.log('entro cargando consulta paginada', consultaPaginada);
                paginatorNext.classList.add('p-disabled');
                paginatorPrev.classList.add('p-disabled');
            } else {

                if (conversacionesPaginadas.length < this.state.basicRows) {
                    //console.log('entro a existe consultaPaginada 1 condicion if');
                    paginatorNext.classList.add('p-disabled');
                } else {
                    //console.log('entro a existe consultaPaginada else');
                    paginatorNext.classList.remove('p-disabled');
                }
                if (this.state.basicFirst == 0) {
                    paginatorPrev.classList.add('p-disabled');
                } else {
                    paginatorPrev.classList.remove('p-disabled');
                }

            }
        } else if (!conversacionesPaginadas && (conversations.length === this.state.basicRows) || (convsFiltradas.length === this.state.basicRows)) {
            paginatorNext.classList.remove('p-disabled');
            paginatorPrev.classList.add('p-disabled');
        }

        if (playSoundConv) {
            const audioEl = document.getElementById("audio-element-conv");
            audioEl.play();
        } else {
            const audioEl = document.getElementById("audio-element-conv");
            audioEl.pause();
        }



    }



    bodyConv = (doc) => {


        let data = doc.data();

        if (data.canal !== "whatsapp" && data.canal !== "web" && data.canal !== "facebook") {


        }
        let imgCanal;
        if (data.canal === 'whatsapp') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/whatsapp.png",
                style: "icon-table",
                tittle: "Whatsapp"
            };
        } else if (data.canal === 'web') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/world-wide-web.svg",
                style: "icon-table",
                tittle: "Web"
            };
        } else if (data.canal === 'facebook') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/logo-facebook.png",
                style: "icon-table",
                tittle: "Facebook"
            }
        } else if (data.canal === 'instagram') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/instagram-logo.png",
                style: "icon-table",
                tittle: "Instagram"
            }
        }
        let imgEstado;
        if (data.estado > 0 && data.estado < 10) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-green.png",
                style: "icon-table2",
                text: "Abierta",
                tittle: "Conversación abierta"
            };

        } else if (data.estado === 0) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-yellow.png",
                style: "icon-table2",
                text: "Pendiente",
                tittle: "Conversación pendiente"
            };
        } else if (data.estado === 10 || data.estado === 11) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-red.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación cerrada"
            };
        } else if (data.estado === 12) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-gray.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación cerrada"
            };
        }

        let fechaIni;
        fechaIni = data.fecha_ini.toDate();
        fechaIni = moment(fechaIni).format("ll, h:mm:ss a")

        let resuelta;
        if (data.resuelta) {
            resuelta = {
                type: "imageText",
                source: "/assets/icons/check.svg",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación resuelta"
            };
        } else {
            resuelta = {
                type: "imageText",
                source: "/assets/icons/unCheck.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "No resuelta"
            }

        }

        let estilo = 'card-chat-list';
        if (data.estado === 1 || data.estado === 3) {
            estilo += " new";
        }

        if (doc.id === (this.selectedProduct1 ? this.selectedProduct1.id : -1)) {
            estilo += " selected";
        }

        let contacto;
        if (data.canal === 'whatsapp') {
            contacto = data.cliente.telefono ? data.cliente.telefono : 'Sin teléfono';
        } else {
            contacto = data.cliente.mail ? data.cliente.mail : 'Sin correo';
        }

        let agenteNombre;


        if (data.agente) {
            let agenteApellido = data.agente.apellidos ? ` ${data.agente.apellidos}` : ""
            if (data.agente.nombres) {
                agenteNombre = data.agente.nombres + agenteApellido
            } else if (data.agente.nombre) {
                agenteNombre = data.agente.nombre
            }
        } else {
            agenteNombre = 'Sin asignar'
        }

        const rowData = {
            estado: imgEstado,
            nombre: data.cliente.nombre + " - " + contacto,
            agente: agenteNombre,
            fecha_ini: fechaIni,
            resuelta: resuelta,
            canal: imgCanal,
            id: { value: doc.id },
            conversacion: doc.data(),
            rowStyle: { value: estilo }
        };


        return <div className={estilo} >
            <div className="row align-items-start">
                <div className="state col-1">
                    <span>
                        <Image className={rowData.estado.style} src={rowData.estado.source} title={rowData.estado.tittle}></Image>
                    </span>
                </div>
                <div className="col-7 px-0">
                    <div className="name">{rowData.nombre}</div>
                    <div className="solved">Resuelta:
                        <span>
                            <Image className={rowData.resuelta.style} src={rowData.resuelta.source} title={rowData.resuelta.tittle}></Image>
                        </span>
                    </div>
                    <div className="channel">Canal:
                        <span>
                            <Image className={rowData.canal.style} src={rowData.canal.source} title={rowData.canal.tittle}></Image>
                        </span>
                    </div>
                </div>
                <div className="info-chat col-4">
                    <div className="time-data">{rowData.fecha_ini}</div>
                    <div className="agent-chat p-text-capitalize">{rowData.agente}</div>
                </div>
            </div>
        </div>;
    }

    bodyConvBigQuery = (doc) => {


        let data = doc;

        if (data.canal !== "whatsapp" && data.canal !== "web" && data.canal !== "facebook") {


        }
        let imgCanal;
        if (data.canal === 'whatsapp') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/whatsapp.png",
                style: "icon-table",
                tittle: "Whatsapp"
            };
        } else if (data.canal === 'web') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/world-wide-web.svg",
                style: "icon-table",
                tittle: "Web"
            };
        } else if (data.canal === 'facebook') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/logo-facebook.png",
                style: "icon-table",
                tittle: "Facebook"
            }
        } else if (data.canal === 'instagram') {
            imgCanal = {
                type: "image",
                source: "/assets/icons/instagram-logo.png",
                style: "icon-table",
                tittle: "Instagram"
            }
        }
        let imgEstado;
        if (data.estado > 0 && data.estado < 10) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-green.png",
                style: "icon-table2",
                text: "Abierta",
                tittle: "Conversación abierta"
            };

        } else if (data.estado === 0) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-yellow.png",
                style: "icon-table2",
                text: "Pendiente",
                tittle: "Conversación pendiente"
            };
        } else if (data.estado === 10 || data.estado === 11) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-red.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación cerrada"
            };
        } else if (data.estado === 12) {
            imgEstado = {
                type: "imageText",
                source: "/assets/icons/circle-gray.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación cerrada"
            };
        }

        let fechaIni;
        fechaIni = new Date(data.fecha_ini.value);
        fechaIni = moment(fechaIni).format("ll, h:mm:ss a")

        let resuelta;
        if (data.resuelta) {
            resuelta = {
                type: "imageText",
                source: "/assets/icons/check.svg",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "Conversación resuelta"
            };
        } else {
            resuelta = {
                type: "imageText",
                source: "/assets/icons/unCheck.png",
                style: "icon-table2",
                text: "Cerrada",
                tittle: "No resuelta"
            }

        }

        let estilo = 'card-chat-list';
        if (data.estado === 1 || data.estado === 3) {
            estilo += " new";
        }

        if (data.idConv === (this.selectedProduct1 ? this.selectedProduct1.id : -1)) {
            estilo += " selected";
        }

        // let contacto;
        // let auxCliente = data.cliente ? data.cliente.split(" ") : []
        // if (data.canal === 'whatsapp') {
        //     contacto = auxCliente.length > 0 ? auxCliente[auxCliente.length - 1] : 'Sin teléfono';
        // } else {
        //     contacto = auxCliente.length > 0 ? auxCliente[auxCliente.length - 1] : 'Sin correo';
        // }

        let agenteNombre;


        if (data.agente_nombre) {
            agenteNombre = data.agente_nombre;

        } else {
            agenteNombre = 'Sin asignar'
        }

        const rowData = {
            estado: imgEstado,
            nombre: data.cliente,
            agente: agenteNombre,
            fecha_ini: fechaIni,
            resuelta: resuelta,
            canal: imgCanal,
            id: { value: data.idConv },
            conversacion: doc,
            rowStyle: { value: estilo }
        };

        return <div className={estilo} >
            <div className="row align-items-start">
                <div className="state col-1">
                    <span>
                        <Image className={rowData.estado.style} src={rowData.estado.source} title={rowData.estado.tittle}></Image>
                    </span>
                </div>
                <div className="col-7 px-0">
                    <div className="name">{rowData.nombre}</div>
                    <div className="solved">Resuelta:
                        <span>
                            <Image className={rowData.resuelta.style} src={rowData.resuelta.source} title={rowData.resuelta.tittle}></Image>
                        </span>
                    </div>
                    <div className="channel">Canal:
                        <span>
                            <Image className={rowData.canal.style} src={rowData.canal.source} title={rowData.canal.tittle}></Image>
                        </span>
                    </div>
                </div>
                <div className="info-chat col-4">
                    <div className="time-data">{rowData.fecha_ini}</div>
                    <div className="agent-chat p-text-capitalize">{rowData.agente}</div>
                </div>
            </div>
        </div>;
    }

    openModalComponent() {
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="loading-wrapperx">
                    <LinearProgress />

                    <p className="loading-text">
                        Cargando información ...

                    </p>
                </div>
            </Modal>
        );
    }

    render() {

        const {
            totalConversationslist, conversations,
            selectConversation,
            filterConversationslist,
            usuariosConectadosHeaders,
            conversationDetail, conversationId,
            messagesDisplay,
            spinnerBoolean,
            sendMessage,
            selectedForm,
            updateAttributes,
            setTema,
            transferir,
            endConversation,
            fijarResuelta, listarUsuariosconectados, verPanelTransfer, ocultarTransfer,
            usuariosDisplay_,
            listarTemas,
            temasHeaders,
            verPanelTemas,
            temasDisplay,
            faqsDisplay,
            verPanelFaqs,
            ocultarModalTemas,
            abrirModalAdjuntar,
            ocultarModalAdjuntar,
            verPanelAdjuntar,
            adjuntarArchivosUpload,
            spinnerAdjuntar,
            spinnerTemas,
            spinnerFaqs,
            tomarControlConversation,
            ocultarModalFaqs,
            listarFaqs,
            printConversation,
            infoConv,
            convMensajes,
            verPanelPrint,
            cerrarModalPrint,
            //menuItems,
            listaTransferencias,
            listaNotas,
            obtenerTransferenciasConv,
            showInfoGeneral,
            showNotasOcultas,
            showTransferencias,
            activarTabInfoGeneral,
            activarTabNotasOcultas,
            modalNotasOcutas,
            abrirModalNotasOcultas,
            addNotaOculta,
            conversacionesPaginadas,
            consultarDialogFlow,
            totalConversations,
            convsFiltradas,
            limitDiasConv,
            spinnerState
        } = this.props;

        let company = getFromSession('company')
        // console.log("company agent panel", company)
        let selectedProduct1 = false;
        //invocar buscardor
        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <Button icon="pi pi-arrow-circle-left" tooltip="Volver" className="p-button-raised ml-2 p-button-sm p-button-info" onClick={() => this.onBasicFilterChange("cleanInputSearch")} />

                    {/* <i className="pi pi-search" /> */}
                    <InputText icon="pi pi-search" type="search" placeholder="Buscar..." value={this.state.globalFilter}
                        onInput={(e) => this.setState({ globalFilter: e.target.value })} />

                    <Button icon="pi pi-search" tooltip="Buscar" className="p-button-raised ml-2 p-button-sm p-button-info" onClick={() => this.onBasicFilterChange(this.state.globalFilter)} />
                    <Button icon="pi pi-times" tooltip="Limpiar" className="p-button-raised ml-2 p-button-sm p-button-danger" onClick={() => this.onBasicFilterChange("cleanInputSearch")} />
                </span>
            </div>
        );

        //consultarDialogFlow();

        let reportTemplate = "Mostrando {first} a {last} de un total de {totalRecords} " + `de los últimos ${limitDiasConv} días`

        return (

            <React.Fragment>
                <Beforeunload onBeforeunload={(event) => { event.preventDefault(); this.cerrar(); }} />
                <SideBar switchFormName='switchUserState' logo={company.logo} items={this.state.menu}></SideBar>
                <div className="container-admin-panel col-md-12 p-0">
                    {spinnerState ? this.openModalComponent() : ""}
                    <Toast ref={(el) => this.toast = el} />
                    <div className="col-md-12 p-0">
                        <div className="row">
                            <div className="col-md-4 pr-0 list-column">
                                <div className="card">

                                    <div className="card-body p-0">
                                        {conversations || convsFiltradas ? (
                                            <div>
                                                <DataTable ref={(el) => this.dt = el} value={convsFiltradas ? convsFiltradas : conversations} scrollable scrollHeight="100vh"
                                                    //<DataTable ref={(el) => this.dt = el} value={conversacionesPaginadas ? conversacionesPaginadas : conversations} scrollable scrollHeight="100vh"
                                                    dataKey="id" header={header} onRowClick={e => { selectConversation(convsFiltradas ? { id: e.data.idConv } : e.data) }}
                                                    selection={selectedProduct1} onSelectionChange={e => this.selectedProduct1 = e.value} selectionMode="single"
                                                    emptyMessage="No hay más conversaciones de los últimos 60 días">

                                                    <Column body={convsFiltradas ? this.bodyConvBigQuery : this.bodyConv}></Column>
                                                </DataTable>
                                                <Paginator first={this.state.basicFirst} template="FirstPageLink PrevPageLink NextPageLink 
                                                RowsPerPageDropdown CurrentPageReport" rows={this.state.basicRows} /* rowsPerPageOptions={[10, 20, 30]} */ onPageChange={this.onBasicPageChange}
                                                    currentPageReportTemplate={`Página {currentPage} de conversaciones de un total de ${totalConversationslist} en la plataforma`}
                                                    totalRecords={20000}>
                                                </Paginator>

                                            </div>
                                        ) : (<div>....</div>)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 pl-0">
                                <ChatWindowCard loading={spinnerBoolean} conversation={conversationDetail}
                                    id={conversationId} selectedForm={selectedForm} updateAttributes={updateAttributes}
                                    MESSAGE_FORMS={MESSAGE_FORMS}
                                    setTema={setTema} transferir={transferir}
                                    verPanelTransfer={verPanelTransfer} verPanelTemas={verPanelTemas} temasDisplay={temasDisplay}
                                    onHide={ocultarTransfer} usuariosDisplay={usuariosDisplay_}
                                    mensajes={messagesDisplay} sendMessage={sendMessage} usuariosConectadosHeaders={usuariosConectadosHeaders}
                                    loadingAdjuntar={spinnerAdjuntar} loadingTemas={spinnerTemas} loadingFaqs={spinnerFaqs} verPanelFaqs={verPanelFaqs} faqsDisplay={faqsDisplay}
                                    temasHeaders={temasHeaders} onHideTemas={ocultarModalTemas} onHideAdjuntar={ocultarModalAdjuntar} faqsHeaders={FAQS_HEADERS} onHideFaqs={ocultarModalFaqs}
                                    verPanelAdjuntar={verPanelAdjuntar} modalAdjuntarOpen={abrirModalAdjuntar} adjuntarArchivosUpload={adjuntarArchivosUpload}
                                    verPanelPrint={verPanelPrint} infoConv={infoConv} convMensajes={convMensajes} cerrarModalPrint={cerrarModalPrint} modalNotasOcutas={modalNotasOcutas}
                                    addNotaOculta={addNotaOculta}
                                >
                                </ChatWindowCard>

                            </div>
                            <div className="col-md-2 details-column">
                                {conversationId ? (
                                    <Info conversation={conversationDetail} resuelta={fijarResuelta}
                                        listarUsuariosconectados={listarUsuariosconectados}
                                        id={conversationId} listarTemas={listarTemas} endConversation={endConversation}
                                        tomarControlConversation={tomarControlConversation} listarFaqs={listarFaqs}
                                        printConversation={printConversation} listaTransferencias={listaTransferencias}
                                        obtenerTransferenciasConv={obtenerTransferenciasConv} showTransferencias={showTransferencias}
                                        showNotasOcultas={showNotasOcultas} showInfoGeneral={showInfoGeneral} activarInfoGeneral={activarTabInfoGeneral}
                                        activarNotasOcultas={activarTabNotasOcultas} abrirModalNotasOcultas={abrirModalNotasOcultas}
                                        listaNotas={listaNotas}
                                    >

                                    </Info>
                                ) : null}


                            </div>
                        </div>
                    </div>
                    <audio id="audio-element">
                        <source src={beep}></source>
                    </audio>
                    <audio id="audio-element-conv">
                        <source src={classic_phone}></source>
                    </audio>
                </div>
            </React.Fragment>
        );

    }

}
/**
 * el html accede a la data o valore fijados aqui,
 * aqui se accede a los selectores
 * @
 */


const mapStateToProps = state => {
    return {
        totalConversationslist: getTotalConversationsList(state),
        itemsPerPage: getItemsPerPage(state),
        pagination: getPagination(state),
        page: getPage(state),
        conversations: getConversations_(state),
        conversationsListFiltered: getConversationsListFiltered(state),
        conversationDetail: getConversationDataDisplay(state),
        conversationId: getConversationId(state),
        messagesDisplay: getMessagesDisplay(state),
        selectedForm: getSelectedForm(state),
        messageForm: getMessageForm(state),
        spinnerBoolean: showSpinner(state),
        playSound: getPlaySound(state),
        playSoundConv: getPlaySoundConv(state),
        verPanelTransfer: verPanelTransfer(state),
        usuariosConectadosHeaders: state.usuariosConectadosHeaders,
        usuariosDisplay_: usuariosDisplay(state),
        verPanelAdjuntar: verPanelAdjuntar(state),
        verPanelFaqs: verPanelFaqs(state),
        spinnerAdjuntar: showSpinnerAdjuntar(state),
        verPanelTemas: verPanelTemas(state),
        toastMensaje: showToast(state),
        toastDetalle: toastDetail(state),
        temasDisplay: temasDisplay(state),
        faqsDisplay: faqsDisplay(state),
        temasHeaders: TEMAS_HEADERS,
        estadosDisplay: estadosDisplay(state),
        agentesDisplay: agentesDisplay(state),
        sessionTimeout: sessionTimeoutSelector(state),
        spinnerTemas: showSpinnnerTemas(state),
        spinnerFaqs: showSpinnerFaqs(state),
        verPanelPrint: verPanelPrint(state),
        infoConv: infoConv(state),
        convMensajes: convMensajes(state),
        conversacionesPaginadas: conversacionesPaginadas(state),
        //menuItems: menuItems(state),
        listaTransferencias: getTransferenciasConv(state),
        listaNotas: getNotasOcultasConv(state),
        showInfoGeneral: showInfoGeneral(state),
        showTransferencias: showTransferencias(state),
        showNotasOcultas: showNotasOcultas(state),
        modalNotasOcutas: modalNotasOcutas(state),
        consultaPaginada: getConsultaPaginada(state),
        convsFiltradas: getConvsFiltradas(state),
        spinnerState: getManageSpinerState(state),
    };
};

/**
 * el html llama las acciones definidas aqui
 * 
 */
const mapDispachToProps = dispatch => {

    return {
        getConversations: (value) => dispatch({ type: GET_CONVERSATIONS, value }),
        searchConversations: () => dispatch({ type: SEARCH_CONVERSATIONS, value: 1 }),
        selectConversation: (value) => dispatch({ type: SELECT_CONVERSATION, value }),
        filterConversationslist: (value) => dispatch({ type: FILTER_CONVERSATIONS, value }),
        paginarConversaciones: (value) => dispatch({ type: PAGINATE_CONVERSATIONS, value }),
        selectRowsPerPage: (value) => dispatch({ type: SELECT_ROWS_PER_PAGE, value }),
        sendMessage: (value) => dispatch({ type: SEND_MESSAGE, value }),
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        cleanSelectedForm: () => dispatch({ type: CLEAN_FORM }),

        setTema: (value) => dispatch({ type: SET_TEMA, value }),
        transferir: (value) => dispatch({ type: TRANSFER, value }),
        endConversation: (value) => dispatch({ type: END_CONVERSATION, value }),
        fijarResuelta: (value) => dispatch({ type: RESUELTA, value }),
        listarTemas: (value) => dispatch({ type: LISTAR_TEMAS }),
        listarUsuariosconectados: () => dispatch({ type: LISTAR_USUARIOS_CONECTADOS, value: 1 }),
        listarFaqs: (value) => dispatch({ type: LISTAR_FAQS, value: 1 }),

        desplegarEventosBuscador: (value) => dispatch({ type: LANZAR_EVENTOS, value: 1 }),
        ocultarTransfer: () => dispatch({ type: LISTAR_USUARIOS_CONECTADOS_CLOSE, payload: 1 }),
        ocultarModalTemas: () => dispatch({ type: LISTAR_TEMAS_CLOSE, payload: 1 }),
        ocultarModalAdjuntar: () => dispatch({ type: MODAL_ADJUNTAR_CLOSE, payload: 1 }),
        ocultarModalFaqs: () => dispatch({ type: LISTAR_FAQS_CLOSE, payload: 1 }),
        abrirModalAdjuntar: () => dispatch({ type: MODAL_ADJUNTAR_OPEN, payload: 1 }),
        adjuntarArchivosUpload: (value) => dispatch({ type: ADJUNTAR_ARCHIVOS, value }),
        listenerLogout: (value) => dispatch({ type: LISTENER_LOGOUT, value }),
        tomarControlConversation: (value) => dispatch({ type: TOMAR_CONTROL, value }),
        initToogleState: (value) => dispatch({ type: INIT_TOGGLE_STATE, value }),
        printConversation: (value) => dispatch({ type: PRINT_CONVERSATION, value }),
        cerrarModalPrint: (value) => dispatch({ type: CLOSE_MODAL_PRINT, value }),
        loadMenu: (value) => dispatch({ type: LOAD_MENU, value }),
        obtenerTransferenciasConv: (value) => dispatch({ type: TRANSFERENCIAS_CONVERSACION, value }),
        activarTabInfoGeneral: (value) => dispatch({ type: INFO_GENERAL, value }),
        activarTabNotasOcultas: (value) => dispatch({ type: NOTAS_OCULTAS, value }),
        abrirModalNotasOcultas: (value) => dispatch({ type: MODAL_NOTAS_OPEN, value }),
        addNotaOculta: (value) => dispatch({ type: ADD_NOTA_OCULTA, value }),
        consultarDialogFlow: (value) => dispatch({ type: CONSULTAR_DIALOG_FLOW_PROPERTY, value }),

    };
};
export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentAgentPanel);
