import './form.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import React, { Component } from 'react';



import CustomReduxTemplateForm from "./customReduxTemplateForm"
import { connect } from "react-redux";

//import { Card } from 'react-bootstrap';


class CustomForm extends Component {
    render() {
        const { items, formName, values, buttons, updateAction,
            style, bodyStyle, styleFormgroup, classnameDiv, onEnterFunction, formRow,
            positionButton, styleButton} = this.props;
           
        const styleDiv = classnameDiv? classnameDiv:"card";
        return (

            <div className={`${style}`}>
                <div className={`${styleDiv}`} key={`card-${formName}`}>
                    <div className={`card-${bodyStyle}`}  >
                       
                            <CustomReduxTemplateForm
                                items={items}
                                formName={formName}
                                dataObject={values}
                                updateAction={updateAction}
                                styleFormgroup={styleFormgroup}
                                onEnterFunction={onEnterFunction}
                                formRow={formRow}
                                itemsButtons={buttons}
                                positionButton={positionButton} 
                                styleButton = {styleButton}
                                
                            />                         
                          
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(storeState, componentProps) {
    const { formData } = storeState;
    return { formData };
}

export default connect(mapStateToProps)(CustomForm);