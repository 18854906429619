export const GET_USERS = '@users/getAll';
export const GET_USERS_SUCCESS = '@users/getUsersSuccess';
export const GET_USERS_ERROR = '@users/getUsersError';
export const LOAD_MENU_SUCCES = '@inicio/loadMenuSucess';
export const LOAD_MENU = '@inicio/loadMenu';
export const CARDS_ELE = '@inicio/cardsEle';
export const CARDS_ELE_SUCCESS = '@inicio/cardsEleSuccess';
export const BOT_ACTIVO = '@inicio/botActivo';
export const BOT_ACTIVO_SUCCESS = '@inicio/botActivoSuccess';
export const COMPANY_DATA = '@inicio/companyData';
export const COMPANY_DATA_SUCCESS = '@inicio/companyDataSuccess';



export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];

export const CARD_ITEMS =   [
    {
        id: '1050',
        type: 'link',
        buttonLabel: 'Adquirir',
        title: 'ChatBot Autogestionable',
        path: '/planes',
        description: 'Crea fácilmente interacciones para conversar con tus clientes.',
        key: '0001',
        picture: 'https://atrevete.academy/blog/wp-content/uploads/2019/06/0_oz2e-hQtsHOWzoB4_.jpg',
        show: false
    },
    {
        id: '1051',
        type: 'button',
        buttonLabel: 'Adquirir',
        title: 'Reconocimiento de imagenes',
        path: '/planes',
        description: 'Extrae  textos y caracteres desde diferentes tipos de archivos.',
        key: '0002',
        picture: 'https://economia3.com/wp-content/uploads/2021/11/04-OCR-digitalizar-facturas-e3.jpg',
        show: true
    }

]

