import React, { Component } from 'react';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import './printConversation.css'
import moment from "moment";
import ReactToPrint from "react-to-print";


export default class PrintConversationData extends Component {


    renderModal(open, body, onHide, headerTitle, id, footer) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw" }}
                footer={footer}

            >
                {body}
            </Dialog>
        );
    }

    renderPlantillaHtml(datos, plantillaMensajes) {
        
        return (
            <div >
                {datos ? (
                    <div className="card" id="dataConversacion" >
                        <div className="p-grid" ref={(el) => (this.componentRef = el)}>
                            <div className="p-col-6">
                                <div className="p-text-bold littleFontSize">Fecha Inicio: </div>
                                <div className="p-text-normal littleFontSize">{datos.fechaIni} </div>
                                <div className="p-text-bold littleFontSize">Cliente y contacto:</div>
                                <div className="p-text-normal littleFontSize">{datos.cliente} </div>
                                <div className="p-text-bold littleFontSize">Agente que atendió:  </div>
                                <div className="p-text-normal littleFontSize">{datos.agente} </div>

                                <div className="p-text-bold littleFontSize">Asunto: </div>
                                <div className="p-text-normal littleFontSize">{datos.asunto} </div>
                                <div className="p-text-bold littleFontSize">Tema Nivel 1: </div>
                                <div className="p-text-normal littleFontSize">{datos.temaNivel1 ? datos.temaNivel1 : 'Sin tema'}</div>
                                <div className="p-text-bold littleFontSize">Tema Nivel 2: </div>
                                <div className="p-text-normal littleFontSize">{datos.temaNivel2 ? datos.temaNivel2 : 'Sin tema'}</div>
                                <div className="p-text-bold littleFontSize">Tema Nivel 3: </div>
                                <div className="p-text-normal littleFontSize">{datos.temaNivel3 ? datos.temaNivel3 : 'Sin tema'}</div>
                            </div>
                            <div className="p-col-6">
                                <div className="p-text-bold littleFontSize">Fecha Fin: </div>
                                <div className="p-text-normal littleFontSize">{datos.fechaFin} </div>
                                <div className="p-text-bold littleFontSize">Canal: </div>
                                <div className="p-text-normal littleFontSize">{datos.canal} </div>
                                <div className="p-text-bold littleFontSize">Email del agente: </div>
                                <div className="p-text-normal littleFontSize">{datos.emailAgente} </div>
                                <div className="p-text-bold littleFontSize">Conversación resuelta:</div>
                                <div className="p-text-normal littleFontSize">{datos.resuelta} </div>
                                <div className="p-text-bold littleFontSize">Encuesta de satisfacción: </div>
                                <div className="p-grid">
                                    <div className="p-col-6">
                                        <div className="p-text-bold littleFontSize">Calificación: </div>
                                        <div className="p-text-normal littleFontSize">{datos.encuestaRating ? datos.encuestaRating : 'Sin calificar'}</div>
                                        <div className="p-text-bold littleFontSize">Atención: </div>
                                        <div className="p-text-normal littleFontSize">{datos.encuestaAtencion ? datos.encuestaAtencion : 'Sin calificar'}</div>

                                    </div>
                                    <div className="p-col-6">
                                        <div className="p-text-bold littleFontSize">Conocimientos: </div>
                                        <div className="p-text-normal littleFontSize">{datos.encuestaConocimientos ? datos.encuestaConocimientos : 'Sin calificar'}</div>
                                        <div className="p-text-bold littleFontSize">Satisfacción: </div>
                                        <div className="p-text-normal littleFontSize">{datos.encuestaSatisfaccion ? datos.encuestaSatisfaccion : 'Sin calificar'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12" >
                                <div className="p-text-bold mediumFontSize">Conversación: </div>
                                {plantillaMensajes}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

        );
    }

    renderMessage(message, j) {
        let fecha = message.fecha.toDate();
        fecha = moment(fecha).format("lll");
        let cadena;
        let destinatario;
        let mensaje = false;
        //MENSAJE DE TEXTO
        if (message.mensaje.type === "text") {
            mensaje = message.mensaje.text;
            //MENSAJE DE IMAGEN
        } else if (message.mensaje.type === "image") {
            if (message.mensaje.caption) {
                mensaje = (
                    <div>
                        <img style={{ maxWidth: "30%" }} src={message.mensaje.url} />
                        <p>{message.mensaje.caption}</p>{" "}
                    </div>
                );
            } else {
                mensaje = <img src={message.mensaje.url} />;
            }
        }
        else if (message.mensaje.type === "file") {
            if (message.mensaje.caption) {
                mensaje = (
                    <div>
                        <p>{message.mensaje.caption}<br />
                            <a href={message.mensaje.url} className="fas fa-download" target="blank_">Descargar Documento</a></p>

                    </div>
                );
            } else {
                mensaje = <a href={message.mensaje.url} target="blank_">Descargar Documento</a>;
            }
        }
        //MENSAJE DE AUDIO
        else if (message.mensaje.type === "audio") {
            mensaje = (
                <div>  <p>
                    <audio controls>
                        <source src={message.mensaje.url}></source>
                    </audio>
                </p>
                </div>
            );
        }
        //MENSAJE DE VIDEO
        else if (message.mensaje.type === "video") {
            mensaje = (
                <div>
                    <img style={{ maxWidth: "25%" }} src="/assets/images/coverVideo.png" />
                    <p>Video adjunto</p>
                </div>
            );
        } else if (message.mensaje.type === "quick_reply") {
            let options = message.mensaje.options ? message.mensaje.options : false
            let opciones = ""
            if (options) {
                options.forEach(option => {
                    opciones += `*${option.value}* `
                });
            }

            mensaje = (<div>{message.mensaje.title}<br></br>
                {message.mensaje.subtitle ? message.mensaje.subtitle : ""}
                <br></br>Opciones:<span style={{ "fontWeight": "bold" }}>{opciones}</span></div>)

        } else if (message.mensaje.type === "button_reply") {
            mensaje = (<span style={{ "fontWeight": "bold" }}>*{message.mensaje.text}*</span>);
        } else if (message.mensaje.type === "list") {
        
            let options = message.mensaje.items[0].options ? message.mensaje.items[0].options : false
            let opciones = ""
            if (options) {
                options.forEach(option => {
                    opciones += `*${option.description}* `
                });
            }

            mensaje = (<div>{message.mensaje.title}<br></br>
                {message.mensaje.body ? message.mensaje.body : ""}
                <br></br>Opciones:<span style={{ "fontWeight": "bold" }}>{opciones}</span></div>)

        } else if (message.mensaje.type === "list_reply") {
            mensaje = (<span style={{ "fontWeight": "bold" }}>*{message.mensaje.description}*</span>);

        }else{
            console.log("falta este tipo", message);
        }


        destinatario = message.from.tipo === "cliente" ? "Cliente dice: " : "Agente dice: "
        cadena = fecha + ' ' + destinatario;


        return (

            <div key={`msg_${j}`} >
                <div className='p-col-12 p-pb-0 p-pt-0'>
                    <div className="p-text-bold littleFontSize"> {cadena} </div>
                    <div className="p-text-normal littleFontSize">{mensaje} </div>
                </div>

            </div>
        )
    }

    render() {
        const { datos, mensajes, verModalDescargarConversacion, cerrarModalDescargarDatos } = this.props;

        const footer = (
            <div>
                <ReactToPrint
                    trigger={() => <Button label='Imprimir' className="p-button-primary p-button-sm p-mr-1" />}
                    content={() => this.componentRef}
                    documentTitle={"conversacion"}
                    pageStyle={'padding'}

                />
            </div>
        );

        const plantillaMensajes = (
            <div>
                {mensajes && mensajes.map((item, j) => {
                    { return this.renderMessage(item.data(), j) }
                })}
            </div>
        )

        return (
            <div>
                {this.renderModal(
                    verModalDescargarConversacion,
                    this.renderPlantillaHtml(
                        datos,
                        plantillaMensajes
                    ),
                    cerrarModalDescargarDatos,
                    "Datos de la conversación",
                    "dataConversacion",
                    footer
                )}
            </div>
        );
    }

}