import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getMenuItemsChatBot = (state) => state.menuItemsChatBot;
export const getMenuItems = (state) => state.menuItems;
export const selectPlanes = (state) => state.planes;
export const selectPlanesList = (state) => state.planesList;
export const selectTransaccion = (state) => state.transaccion;




export const usersSelector = createStructuredSelector({
    users: getUsers,
});