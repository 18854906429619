import {
    UPDATE_FIELD_ATTRIBUTE,
    SELECT_TAB,
    SIGN_USER,
    LOG_USER,
    AUTH_SUCCESS,
    AUTH_ERROR,
    AUTH_LOGIN_SUCCESS,
    CONNECTEDUSER_SUCCESS,
    CONNECTEDUSER,
    AUTH_LOAD_PARAMS_ERROR,
    AUTH_LOAD_PARAMS_SUCCESS,
    RETURN_HOME
} from './constants'

export function updateAttributes (payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function selectTab (payload) {
    return {
        type: SELECT_TAB,
        payload
    };
}

export function signUser (payload) {
    return {
        type: SIGN_USER,
        payload
    };
}

export function logUSer (payload) {
    return {
        type: LOG_USER,
        payload
    };
}

export function authSuccess (payload) {
    return {
        type: AUTH_SUCCESS,
        payload
    };
}

export function logSuccess (payload) {
    return {
        type: AUTH_LOGIN_SUCCESS,
        payload
    };
}

export function consultarconnectedUserSuccess(payload){
    console.log('ENTROOO AQUIIII***********');
    return {
        type: CONNECTEDUSER_SUCCESS,
        payload
    }

}

export function consultarconnectedUser(){
    return {
        type: CONNECTEDUSER
    };

}

export function authError (payload) {
    return {
        type: AUTH_ERROR,
         payload
    };
}

export function loadParamsError (payload) {
    return {
        type: AUTH_LOAD_PARAMS_ERROR,
         payload
    };
}

export function loadParamsSuccess (payload) {
    return {
        type: AUTH_LOAD_PARAMS_SUCCESS,
         payload
    };
}

export function returnHome (payload) {
    return {
        type: RETURN_HOME,
         payload
    };
}


