import {
  GET_USERS_SUCCESS,
  LOAD_MENU_SUCCES,
  USER_DATA,
  ADD_ITEM,
  USER_HEADERS,
  UPLOAD_IMAGE_SUCCESS,
  GET_BOTS_SUCCESS,
  GET_INTENTS_SUCCESS,
  EDIT_INTENT_SUCCESS,
  DELETE_INTENT_SUCCESS,
  UPLOAD_INTENT_SUCCESS,
  DELETE_DIALOGFLOW_AGENT_SUCCESS,
  DELETE_DIALOGFLOW_INTENT_SUCCESS,
  UPLOAD_ICON_SUCCESS,
  CREATE_AGENT_SUCCESS,
  CREATE_AGENT,
  BOT_ACTIVO_SUCCESS,
  LIST_CLOUD_PROJECTS_SUCCESS,
  CREATE_DIALOGFLOW_INTENT_SUCCESS,
  CREATE_DIALOGFLOW_INTENT,
  UPDATE_DIALOGFLOW_INTENT,
  UPDATE_DIALOGFLOW_INTENT_SUCCESS,
  DELETE_DIALOGFLOW_INTENT,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPLOAD_ICON,
  PRUEBA_GRATIS_SUCCESS,
  GET_PLANES_SUCCESS,
  FIN_SUSCRIBCION_SUCCESS,
  GET_TRANSACCION_SUCCES
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  listaPrueba: [],
  menuItemsChatBot: false,
  uploadImage: false,
  getBots: false,
  getIntent: false,
  editInten: false,
  deleteIntent: false,
  uploadIntent: false,
  uploadIcon: false,
  deleteAgent: false,
  deleteIntentDialogflow: false,
  createAgentSuccess: false,
  loading: false,
  loading_icon: false,
  bot_activo: false,
  list_projects: false,
  agentCreated: false,
  planes: false,
  showToast: false,
  toastDetail: false,
  finSubcribcion: false,
  transaccion: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;
  //console.log("REDUCER TYPE", type)


  newState.uploadIntent = false;
  newState.updateWidget = false;
  switch (type) {
    case GET_USERS_SUCCESS:
      const { data } = payload;
      newState.users = data;

      return {
        ...newState
      };


    case LOAD_MENU_SUCCES:
      newState.menuItemsChatBot = payload;


      return {
        ...newState
      };


    case UPLOAD_IMAGE_SUCCESS:
      //console.log("UPLOAD_IMAGE_SUCCESS", payload)
      newState.uploadImage = payload;

      return {
        ...newState
      };


    case GET_BOTS_SUCCESS:
      newState.getBots = payload;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };

    case CREATE_AGENT:
      newState.createAgentSuccess = payload;
      newState.loading = true;
      newState.showToast = false;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };


    case CREATE_DIALOGFLOW_INTENT_SUCCESS:
      //console.log("CREAR AGENTE", payload)
      //newState.createAgentSuccess = payload;
      //console.log("showToast", payload)
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)
      newState.loading = false
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;

      //console.log("CREAR INTENTT SUCCESS newState.showToast", newState.showToast)
      //console.log("CREAR INTENTT SUCCESS newState.toastDetail", newState.toastDetail)

      return {
        ...newState
      };




    case UPDATE_PROFILE_SUCCESS:
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      //console.log("CREAR AGENTE", payload)
      //newState.createAgentSuccess = payload;
      newState.loading = false
      //newState.updateWidget = true
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };

    case UPDATE_PROFILE:
      //newState.createAgentSuccess = payload;
      newState.loading = true;
      newState.showToast = false;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };

    case CREATE_DIALOGFLOW_INTENT:
      //newState.createAgentSuccess = payload;
      newState.loading = true;
      newState.showToast = false;

      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };

    case UPDATE_DIALOGFLOW_INTENT_SUCCESS:
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      newState.loading = false;
      //console.log("CREAR AGENTE", payload)
      //newState.createAgentSuccess = payload;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };


    case UPDATE_DIALOGFLOW_INTENT:
      //newState.createAgentSuccess = payload;
      newState.loading = true;
      newState.showToast = false;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };





    case DELETE_DIALOGFLOW_INTENT_SUCCESS:
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      //newState.createAgentSuccess = payload;

      newState.loading = false;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };


    case DELETE_DIALOGFLOW_INTENT:
      //newState.createAgentSuccess = payload;
      newState.showToast = false;
      newState.loading = true;
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };



    case CREATE_AGENT_SUCCESS:
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      newState.loading = false
      //console.log("CREAR AGENTE", payload)
      newState.createAgentSuccess = payload;
      newState.updateWidget = true
      //console.log("GETBOTTSSS REDUCERRR", newState.getBots)

      return {
        ...newState
      };
    case GET_INTENTS_SUCCESS:
      newState.getIntent = payload;
      newState.showToast = false;

      return {
        ...newState
      };

    case LIST_CLOUD_PROJECTS_SUCCESS:
      newState.list_projects = payload;
      return {
        ...newState
      };


    case EDIT_INTENT_SUCCESS:
      newState.editInten = payload;


      return {
        ...newState
      };

    case BOT_ACTIVO_SUCCESS:
      newState.bot_activo = payload;
      //console.log("newState.bot_activo", newState.bot_activo)

      return {
        ...newState
      };




    case DELETE_INTENT_SUCCESS:
      newState.deleteIntent = payload;


      return {
        ...newState
      };
    case UPLOAD_INTENT_SUCCESS:
      newState.uploadIntent = payload;


      return {
        ...newState
      };
    case DELETE_DIALOGFLOW_AGENT_SUCCESS:
      newState.deleteAgent = payload;


      return {
        ...newState
      };

    case DELETE_DIALOGFLOW_INTENT_SUCCESS:
      newState.deleteIntentDialogflow = payload;


      return {
        ...newState
      };

    case UPLOAD_ICON_SUCCESS:
      newState.uploadIcon = payload;
      newState.showToast = payload.showToast;
      newState.toastDetail = payload.toastDetail;
      newState.loading_icon = false;


      return {
        ...newState
      };


    case UPLOAD_ICON:
      newState.loading_icon = true;
      newState.showToast = false;


      return {
        ...newState
      };


    case FIN_SUSCRIBCION_SUCCESS:
      newState.finSubcribcion = payload;


      return {
        ...newState
      };

    case GET_TRANSACCION_SUCCES:
      newState.transaccion = payload;

      return {
        ...newState
      };



    case ADD_ITEM:
      const nuevoIntent = `frase entrenamiento ${Math.floor(Math.random() * 100)}`;
      //   console.log("reducer payload chat bot", payload)
      newState.listaPrueba = [...newState.listaPrueba, nuevoIntent];

      return {
        ...newState
      };

    case GET_PLANES_SUCCESS:
      newState.planes = payload;
      //console.log("newState.planes", newState.planes)


      return {
        ...newState
      };





    default:
      return {
        ...newState
      };
  }
};

export default reducer;
