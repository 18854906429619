import {
    COMPANY_DATA_SUCCESS,
    CONTADOR_BUCKET_SUCCESS,
    CROSS_DOC_SUCCESS,
    DOCUMENTOS_CARGADOS_SUCCESS,
    DOWNLOAD_DOC_SUCCESS,
    FIN_SUSCRIBCION_SUCCESS,
    GET_DOC_FALL_SUCCESS,
    GET_DOC_PROC_SUCCESS,
    GET_OCR_DOC_SUCCESS,
    GET_OCR_SIZE_SUCCESS,
    GET_PLANES_SUCCESS,
    GET_TRANSACCION_SUCCES,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LOAD_MENU_SUCCES,
    OCR_ACTIVO_SUCCESS,
    OCR_CREATE_SUCCESS,
    PRO_OCR_DOC_SUCCESS,
    SHOW_DOC_PROC_SUCCESS,
    UPLOAD_BUCKET_SUCCESS,
    UPLOAD_DOC_SUCCESS
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}
export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}

export function companyDataSuccess (payload) {
    return {
        type: COMPANY_DATA_SUCCESS,
        payload
    };
}

export function uploadBucketSuccess (payload) {
    return {
        type: UPLOAD_BUCKET_SUCCESS,
        payload
    };
}

export function contadorBucketSuccess (payload) {
    return {
        type: CONTADOR_BUCKET_SUCCESS,
        payload
    };
}

export function ocrActivoActionSuccess (payload) {
    return {
        type: OCR_ACTIVO_SUCCESS,
        payload
    };
}
export function ocrCreateSuccess (payload) {
    return {
        type: OCR_CREATE_SUCCESS,
        payload
    };
}
export function getOCRDocumentosSuccess (payload) {
    return {
        type: GET_OCR_DOC_SUCCESS,
        payload
    };
}
export function proOCRDocumentosSuccess (payload) {
    return {
        type: PRO_OCR_DOC_SUCCESS,
        payload
    };
}

export function getOcrSizeSuccess (payload) {
    return {
        type: GET_OCR_SIZE_SUCCESS,
        payload
    };
}

export function documentosProcesadosSuccess (payload) {
    return {
        type: GET_DOC_PROC_SUCCESS,
        payload
    };
}

export function documentosFallidosSuccess (payload) {
    return {
        type: GET_DOC_FALL_SUCCESS,
        payload
    };
}

export function showDocumentosProcesadosSuccess (payload) {
    return {
        type: SHOW_DOC_PROC_SUCCESS,
        payload
    };
}

export function downloadDocuSuccess (payload) {
    return {
        type: DOWNLOAD_DOC_SUCCESS,
        payload
    };
}

export function crossDocSuccess (payload) {
    return {
        type: CROSS_DOC_SUCCESS,
        payload
    };
}
export function UploadDocuSuccess (payload) {
    return {
        type: UPLOAD_DOC_SUCCESS,
        payload
    };
}
export function documentosActualizadosSuccess (payload) {
    return {
        type: DOCUMENTOS_CARGADOS_SUCCESS,
        payload
    };
}

export function getPlanesSuccess (payload) {
    return {
        type: GET_PLANES_SUCCESS,
        payload
    };
}
export function finSuscribcionSuccess (payload) {
    return {
        type: FIN_SUSCRIBCION_SUCCESS,
        payload
    };
}
export function getTransaccionSuccess (payload) {
    return {
        type: GET_TRANSACCION_SUCCES,
        payload
    };
}
