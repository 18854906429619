import React, { Component } from 'react';
import { BUTTON_LIST, getItemLogout, SWITCHE_FORM } from './constants';

import './sidebar.css';
import NavItem from './navitem';
import Nav from 'react-bootstrap/Nav';

import { getFromSession, logout } from '../../controller/session';

import CustomReduxForm from "../../components/materialform/customform";
import LogoutModal from './logoutModal';
import { Button } from 'react-bootstrap';
import CustomCard from '../customcard/customcard';
import CustomButton from '../custombutton/button';
import { Link } from 'react-router-dom';
import CustomLinks from '../customLinks/customLinks';

export default class SideNav extends Component {
    constructor(props) {
        super(props);

        const { items, onLogout } = this.props;

        this.state = {
            activePath: '/',
            items: items,
            onLogout: onLogout,
            activated: false,
            modal: false,
            sidenavOptions: false
        }
    }

    onItemClick = (path) => {
        this.setState({ activePath: path });
    }

    onLogoutClick = () => {

        this.setState({ activePath: '/', activated: false }, () => {
            logout();
        });
    }
    onModalClick = () => {

        this.setState({ modal: true }

        );
    }
    onModalCancel = () => {

        this.setState({ modal: false }

        );
    }

    onHeaderClick = (path) => {
        this.setState({ activated: !this.state.activated });
    }

    componentWillMount() {
        const user = getFromSession("currentUser");
        const company = getFromSession("company");

        if (company.cuenta === '1029' ||
            company.cuenta === '1024' ||
            company.cuenta === '1020' ||
            company.cuenta === '1022' ||
            company.cuenta === '1025' ||
            company.cuenta === '1021' ||
            company.cuenta === '1023') {
            this.setState({ sidenavOptions: false });
        } else {
            this.setState({ sidenavOptions: true });
        }
    }

    render() {
        const { onLogout, activated } = this.state;
        const { items, logo } = this.props;
        const { switchFormName } = this.props;
        const user = getFromSession("currentUser");
        const company = getFromSession("company");
        //console.log("companyitems", logo)


        return (
            <React.Fragment>

                {this.state.modal && user.rol === "AGENTE" ?
                    <LogoutModal
                        onModalClick={() => { this.onLogoutClick() }}
                        onModalCancel={() => { this.onModalCancel() }}
                        message="¿Estas seguro que desea salir de la sesión?"
                        message2="Si tienes conversaciones asignadas estas pasarán a cola de espera y serán asignadas a otro agente"
                    /> : ''
                }
                {this.state.modal && user.rol === "SUPERVISOR" ?
                    <LogoutModal
                        onModalClick={() => { this.onLogoutClick() }}
                        onModalCancel={() => { this.onModalCancel() }}
                        message="¿Estas seguro que desea salir de la sesión?"
                    /> : ''
                }
                {
                    user &&
                    <div className="header_container content-top row justify-content-between">
                        <div className="col-12 bg-light">
                            <div className="navbar navbar-light w-100 align-items-center">
                                <div className="col-auto">
                                    {
                                        user.rol !== 'AGENTE' && (
                                            <button className="navbar-toggler" onClick={() => this.onHeaderClick()}>
                                                <span className="navbar-toggler-icon"></span>
                                            </button>
                                        )
                                    }
                                    <div className="image-brand">
                                        {logo ?
                                            <img src={logo} />
                                            : ''}

                                    </div>
                                </div>
                                {company && company.cuenta_nueva ?
                                    <div className='sidenav-botonera'>
                                        {this.state.sidenavOptions ?
                                            <CustomLinks
                                                items={
                                                    BUTTON_LIST
                                                }
                                                styleLinks={'sidenav-botonera'}
                                            >
                                            </CustomLinks> : ''
                                        }

                                    </div>
                                    : ""}
                                <div className="col-auto user-info">
                                    {switchFormName &&
                                        <CustomReduxForm
                                            submitable={false}
                                            items={SWITCHE_FORM}
                                            formName={switchFormName}
                                            buttons={[]}
                                            className="user-state-switch"
                                        />
                                    }
                                    <div className="navbar-brand">
                                        {user.apellidos ?
                                            `${user.nombres} ${user.apellidos} `
                                            : `${user.nombres}`}
                                    </div>
                                    <a href="#" onClick={() => this.onModalClick()} className="btn btn-light btn-exit" title="Cerrar sesión"><span className="sr-only">Cerrar sesion</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={`side-nav-wrapper ${activated ? ' activated ' : ''}`}>

                    <Nav className="side-nav-nar" activeKey="1" as="ul">
                        <NavItem
                            items={items}
                            onItemClick={() => this.onItemClick}
                        />
                        <NavItem
                            items={getItemLogout(this.onLogoutClick)}
                            onItemClick={() => this.onLogoutClick}
                        />
                    </Nav>
                </div>
                <div className={`overlay ${activated ? ' activated ' : ''}`} onClick={() => this.onHeaderClick()}></div>
            </React.Fragment>
        );
    }
}