import {
  COMPANY_DATA_SUCCESS,
  GET_DOC_PROC_SUCCESS,
  GET_OCR_DOC_SUCCESS,
  GET_USERS_SUCCESS,
  GET_DOC_PROC,
  LOAD_MENU_SUCCES,
  USER_DATA,
  USER_HEADERS,
  GET_DOC_FALL_SUCCESS,
  GET_DOC_FALL,
  SHOW_DOC_PROC_SUCCESS,
  SHOW_DOC_PROC,
  CROSS_DOC_SUCCESS,
  CROSS_DOC,
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItemsOCR: false,
  dataCompany: false,
  documentData: false,
  proccessDocument: false,
  failDocument: false,
  showDoc: false,
  crossDoc: false,
  showCross: false,
  loadingCircular: false,
  loadingCircularCruce: false,
  datosDocPrincipal: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  newState.showTable = false;
  switch (type) {
    case GET_USERS_SUCCESS:
      const { data } = payload;
      newState.users = data;

      return {
        ...newState
      };


    case COMPANY_DATA_SUCCESS:
      //console.log("reducer payload data company", payload)
      newState.dataCompany = payload;

      return {
        ...newState
      };

    case LOAD_MENU_SUCCES:
      //console.log("reducer payload chat bot", payload)
      newState.menuItemsOCR = payload;

      return {
        ...newState
      };


    case GET_OCR_DOC_SUCCESS:
      newState.documentData = payload;

      return {
        ...newState
      };





    case GET_DOC_PROC_SUCCESS:
      newState.loadingCircular = false;
      newState.proccessDocument = payload;
      

      return {
        ...newState
      };


    case GET_DOC_PROC:
      newState.loadingCircular = true;
      return {
        ...newState
      };

    case GET_DOC_FALL_SUCCESS:
      newState.loadingCircular = false;
      newState.failDocument = payload;

      return {
        ...newState
      };


    case GET_DOC_FALL:
      newState.loadingCircular = true;
      return {
        ...newState
      };


    case SHOW_DOC_PROC_SUCCESS:
      newState.loadingCircular = false;
      newState.showDoc = payload; 
      console.log("REDUCER SHOW DOC", newState.showDoc)

      return {
        ...newState
      };


    case SHOW_DOC_PROC:
      newState.loadingCircular = true;
      return {
        ...newState
      };
 
    case CROSS_DOC_SUCCESS:
      newState.loadingCircularCruce = false;
      newState.crossDoc = payload.consultaData;
      newState.showCross = payload.showDocument;
      newState.datosDocPrincipal = payload.datosDocPrincipal; 
      console.log("loadingCircular reducer MIS", payload)

      return {
        ...newState
      };


    case CROSS_DOC:

      newState.loadingCircular = true;
      newState.loadingCircularCruce = true;
      console.log("loadingCircular no success MIS", payload)

      return {
        ...newState
      };


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
