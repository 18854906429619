import {
    CREATE_DIALOGFLOW_INTENT_SUCCESS,
    DELETE_DIALOGFLOW_AGENT_SUCCESS,
    DELETE_DIALOGFLOW_INTENT_SUCCESS,
    DELETE_INTENT_SUCCESS,
    EDIT_INTENT_SUCCESS,
    GET_BOTS_SUCCESS,
    GET_INTENTS_SUCCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LIST_DIALOGFLOW_INTENT_SUCCESS,
    LOAD_MENU_SUCCES,
    UPDATE_DIALOGFLOW_INTENT_SUCCESS,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_ICON_SUCCESS,
    UPLOAD_INTENT_SUCCESS,
    CREATE_AGENT_SUCCESS,
    REQUESTS_BACK_SUCCESS,
    NOMBRE_BOT_SUCCESS,
    BOT_ACTIVO_SUCCESS,
    LIST_CLOUD_PROJECTS_SUCCESS,
    UPDATE_PROFILE_SUCCESS,
    PRUEBA_GRATIS_SUCCESS,
    GET_PLANES_SUCCESS,
    FIN_SUSCRIBCION_SUCCESS,
    GET_TRANSACCION_SUCCES
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}
export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}

export function uploadImageSuccess (payload) {
    return {
        type: UPLOAD_IMAGE_SUCCESS,
        payload
    };
}
export function uploadIntentSuccess (payload) {
    return {
        type: UPLOAD_INTENT_SUCCESS,
        payload
    };
}
export function getBotsSuccess (payload) {
    return {
        type: GET_BOTS_SUCCESS,
        payload
    };
}
export function getIntentsSuccess (payload) {
    return {
        type: GET_INTENTS_SUCCESS,
        payload
    };
}
export function editIntentsSuccess (payload) {
    return {
        type: EDIT_INTENT_SUCCESS,
        payload
    };
}
export function deleteIntentsSuccess (payload) {
    return {
        type: DELETE_INTENT_SUCCESS,
        payload
    };
}
export function createDialogflowIntentSuccess (payload) {
    return {
        type: CREATE_DIALOGFLOW_INTENT_SUCCESS,
        payload
    };
}
export function deleteDialogflowAgentSuccess (payload) {
    return {
        type: DELETE_DIALOGFLOW_AGENT_SUCCESS,
        payload
    };
}
export function deleteDialogflowIntentSuccess (payload) {
    return {
        type: DELETE_DIALOGFLOW_INTENT_SUCCESS,
        payload
    };
}
export function listDialogflowIntentSuccess (payload) {
    return {
        type: LIST_DIALOGFLOW_INTENT_SUCCESS,
        payload
    };
}
export function updateDialogflowIntentSuccess (payload) {
    return {
        type: UPDATE_DIALOGFLOW_INTENT_SUCCESS,
        payload
    };
}
export function uploadIconSuccess (payload) {
    return {
        type: UPLOAD_ICON_SUCCESS,
        payload
    };
}
export function updateProfileSuccess (payload) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload
    };
}

export function createAgentSuccess (payload) {
    return {
        type: CREATE_AGENT_SUCCESS,
        payload
    };
}
export function nombreBotSuccess (payload) {
    return {
        type: NOMBRE_BOT_SUCCESS,
        payload
    };
}


export function requestBackendAction (payload) {
    return {
        type: REQUESTS_BACK_SUCCESS,
        payload
    };
}


export function botActivoActionSuccess (payload) {
    return {
        type: BOT_ACTIVO_SUCCESS,
        payload
    };
}

export function listCloudSuccess (payload) {
    return {
        type: LIST_CLOUD_PROJECTS_SUCCESS,
        payload
    };
}
export function finSuscribcionSuccess (payload) {
    return {
        type: FIN_SUSCRIBCION_SUCCESS,
        payload
    };
}
export function getPlanesSuccess (payload) {
    return {
        type: GET_PLANES_SUCCESS,
        payload
    };
}
export function getTransaccionSuccess (payload) {
    return {
        type: GET_TRANSACCION_SUCCES,
        payload
    };
}





