import React, { Component } from "react";
import "./style.css";
//import "../widget-style.css";
//import "./widget.css";
import { connect } from "react-redux";
import { getUsers, getListaPrueba, getuploadImage, selectIntent, selectGetBots } from "../selectors";
import {
    ADD_ITEM,
    CREATE_DIALOGFLOW_INTENT,
    EDIT_INTENT,
    GET_BOTS,
    GET_INTENT,
    GET_USERS,
    UPDATE_DIALOGFLOW_INTENT,
    UPLOAD_IMAGE,
    UPLOAD_INTENT
} from '../constants';
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import ComponentVistaPrevia from "../vistaPrevia/component";
import ComponentRespuesta from "./respuestas";
import ComponentError from "../errorPopUpComponent";
import ComponentInfoTooltip from "./infotooltip";
import ImageIcon from '@material-ui/icons/Image';
import TitleIcon from '@material-ui/icons/Title';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoIcon from '@material-ui/icons/Info';
import ListIcon from '@material-ui/icons/List';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { withRouter } from "react-router";
import { ConsoleView } from "react-device-detect";
import { Widget } from 'react-chat-widget';
import ComponentWidget from './widget-chatbot'
import 'react-chat-widget/lib/styles.css';


class ComponentCrearIntent extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout } = this.props;
        this.state = {
            activePath: '/',
            nombreBot: '',
            previa: false,
            frase: '',
            listaFrases: [],
            infoTooltip: false,
            bodyError: 'La pregunta debe contener "Nombre", "Frases de entrenamiento" y al menos una respuesta',
            respCont: 0,
            respuesta: {},
            respuestaText: {},
            respuestaList: {},
            respuestaButton: {},
            respuestaImagen: {},
            errorBot: false,
            opcionesRespuesta: [],
            renderNumeroRespuesta: [],
        }

    }
    onButonClick = () => {
        this.setState({ previa: true }
        );
    }

    onInfoClickOpen = () => {
        this.setState({ infoTooltip: true }
        );
    }
    onInfoClickClose = () => {
        this.setState({ infoTooltip: false }
        );
    }

    onNombreHandler = (e) => {
        this.setState({ nombreBot: e.target.value }
        );
    }

    editFrase = (event) => {
        let listaFrasesLocal = this.state.listaFrases
        let idFrase = event.target.id?.split("-")[1]
        listaFrasesLocal[+idFrase] = event.target.value
        this.setState({ listaFrases: listaFrasesLocal })
    }
    deleteFrase = (index) => {
        let listaFrasesLocal = this.state.listaFrases
        let newFrasesList = []
        listaFrasesLocal.map((item, i) => {
            if (i !== index) {
                newFrasesList.push(item)

            }
        })
        this.setState({ listaFrases: newFrasesList })

    }

    // showWidgetChat = () => {
    //     var widgetChatElement = document.getElementById("react-widget-frame");
    //     widgetChatElement.classList.remove("react-widget-hide");
    //     widgetChatElement.classList.add("react-widget-show");
    // }
    onValueRespuesta = (payload) => {
        let opcionesSeleccionada = this.state.opcionesRespuesta
        if (payload.type === 'text') {
            //console.log("payload.value RESPUESTA TEXT", payload.value)
            opcionesSeleccionada.map((item, i) => {
                if (i === payload.index) {
                    item.value = payload.value
                    this.setState({
                        respuestaText: {
                            texto: item.value
                        }
                    })
                }
            })
        }

        if (payload.type === 'list') {
            opcionesSeleccionada.map((item, i) => {
                if (i === payload.index) {
                    item.value = payload.value
                    this.setState({
                        respuestaList: {
                            list: item.value
                        }
                    })
                }
            })
        }
        if (payload.type === 'button') {
            //console.log("payload.value RESPUESTA BOTON", payload.value)
            opcionesSeleccionada.map((item, i) => {
                if (i === payload.index) {
                    item.value = payload.value
                    this.setState({
                        respuestaButton: {
                            button: item.value
                        }
                    })
                }
            })
        }

        if (payload.type === 'imagen') {
            opcionesSeleccionada.map((item, i) => {
                if (i === payload.index) {
                    item.value = payload.value
                    this.setState({
                        respuestaImagen: {
                            imagen: item.value
                        }
                    })
                }
            })
        }
        this.setState({ opcionesRespuesta: opcionesSeleccionada })
    }
    componentDidMount() {
        //this.showWidgetChat()
        // document.getElementById("scripts-chatbot").innerHTML = "";
        // document.getElementById("container-chatbot").innerHTML = "";

        // const containerChatbot = document.createElement("div");
        // containerChatbot.setAttribute("id", "chatbot-avanti")

        // document.getElementById("container-chatbot").appendChild(containerChatbot)

        // document.getElementById('chatbot-avanti').classList.add("ver-pluggin");
        // document.getElementById("chatbot-avanti").setAttribute("data-company", "1287");
        // this.setState({botWidget: true})

        // const script = document.createElement("script");
        // const scriptMain = document.createElement("script");
        // const scriptMain2 = document.createElement("script");

        // script.src = "https://cliente-chat-desa.firebaseapp.com/static/js/main-chatbot.js";
        // script.async = true;

        // scriptMain.src = "https://cliente-chat-desa.firebaseapp.com/static/js/main2-chatbot.js";
        // scriptMain.async = true;

        // scriptMain2.src = "https://cliente-chat-desa.firebaseapp.com/static/js/runtime-chatbot.js";
        // scriptMain2.async = true;

        // document.getElementById("scripts-chatbot").appendChild(script)
        // document.getElementById("scripts-chatbot").appendChild(scriptMain)
        // document.getElementById("scripts-chatbot").appendChild(scriptMain2)
    }
    componentWillMount() {
        const { getUsers, cargarItems, uploadImage, intent, editIntent } = this.props;
        getUsers();
        uploadImage();

        //document.getElementById('chatbot-avanti').classList.add("ver-pluggin");
        //document.getElementById("chatbot-avanti").setAttribute("data-company", "1287");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        //console.log("QUERY COMPONENT RESPUESTA", queryString)
        //console.log("intent compoenente respuesta ************", intent)

        if (intent) {
            editIntent(intent)
        }
    }


    componentWillUnmount() {

        //alert("WILLL UNMOUNTT")
        //document.getElementById("scripts-chatbot").innerHTML = "";
        //document.getElementById("container-chatbot").innerHTML = "";

        //document.getElementById('chatbot-avanti').classList.remove("ver-pluggin");

        //document.getElementById('chatbot-avanti').classList.remove("ver-pluggin");
    }


    componentDidUpdate(prevProps) {
        const { intentSelect } = this.props
        if (prevProps.intentSelect !== intentSelect) {
            const emptyFrasesEntrenamiento = intentSelect.fraseEntrenamiento && Array.isArray(intentSelect.fraseEntrenamiento) ? intentSelect.fraseEntrenamiento : [];
            const emptyOpcionesRespuesta = intentSelect.opciones_respuesta && Array.isArray(intentSelect.opciones_respuesta) ? intentSelect.opciones_respuesta : [];

            this.setState({
                nombreBot: intentSelect.nombreIntent,
                listaFrases: [...emptyFrasesEntrenamiento],
                respuestaText: intentSelect.respuestaTexto,
                respuestaList: intentSelect.respuestaLista,
                respuestaButton: intentSelect.respuestaBoton,
                respuestaImagen: intentSelect.respuestaImagen,
                opcionesRespuesta: [...emptyOpcionesRespuesta]
            })

        }
        //console.log("intent state", JSON.stringify(this.state));
    }

    seleccionarRespuesta = (tipoRespuesta, numeroRespuesta) => {
        const { getBots } = this.props
        //getBots('chatbot4.0')
        let printNumeroRespuesta = numeroRespuesta
        const opcionesSeleccionada = this.state.opcionesRespuesta;
        opcionesSeleccionada.push({ tipoRespuesta, valorRespuesta: '' })
        this.setState({ opcionesRespuesta: opcionesSeleccionada })

        printNumeroRespuesta = printNumeroRespuesta + 1
        const contadorRespuesta = this.state.renderNumeroRespuesta;
        contadorRespuesta.push(printNumeroRespuesta)
        this.setState({ respCont: printNumeroRespuesta })
        this.setState({ renderNumeroRespuesta: contadorRespuesta })
    }
    onFraseClick = (e) => {
        this.setState({ frase: e.target.value })

    }

    onFraseEntrenamiento = () => {
        this.state.listaFrases.push(this.state.frase)
        this.setState({ frase: '' })
    }
    deleteRespuesta = (id) => {
        // console.log("ID DELETE *****", id)
        let respuestasActualizadas = [...this.state.opcionesRespuesta]
        respuestasActualizadas.splice(id, 1)

        this.setState({
            opcionesRespuesta: respuestasActualizadas
        })
    }

    onModalCancelError = () => {
        this.setState({ errorBot: false });
    }

    onModalOpenError = () => {
        this.setState({ errorBot: true });
        // console.log("ERROR MODAL*********")
    }
    onGoBack = () => {
        window.history.back()
    }

    onSendIntent = () => {
        const {
            uploadIntent,
            returnToNombreBot,
            nombreBot,
            getIntents,
            getBots,
            intent,
            loading,
            intentData,
            updateIntentDialogflow,
            createIntentDialogflow,
            confirmarPopUp,
            updatePopUp
        } = this.props;

        const respuesta = {
            nombreBot: nombreBot,
            nombreIntent: this.state.nombreBot,
            fraseEntrenamiento: this.state.listaFrases,
            respuestaTexto: this.state.respuestaText,
            respuestaLista: this.state.respuestaList,
            respuestaBoton: this.state.respuestaButton,
            respuestaImagen: this.state.respuestaImagen,
            opcionesRespuesta: this.state.opcionesRespuesta
        }

        console.log("UPDATE*¨*¨* ", respuesta.opcionesRespuesta)

        // if(respuesta.respuestaBoton.button === undefined){
        //     console.log("VACIIIO BOTN unde")
        //     respuesta.respuestaBoton = {}
        // }
        // if(respuesta.respuestaBoton.button !== undefined){
        //     console.log("VACIIIO BOTN no unde")
        //     respuesta.respuestaBoton = respuesta.respuestaBoton
        // }
        // if(respuesta.respuestaTexto === undefined){
        //     console.log("VACIIIO TEXT")
        //     respuesta.respuestaTexto = {}
        // }
        // if(respuesta.respuestaLista === undefined){
        //     console.log("VACIIIO LIST")
        //     respuesta.respuestaLista = {}
        // }
        // if(respuesta.respuestaImagen === undefined){
        //     console.log("VACIIIO LIST")
        //     respuesta.respuestaImagen = {}
        // }

        if (respuesta.nombreIntent === '' ||
            respuesta.fraseEntrenamiento.length === 0 ||
            respuesta.opcionesRespuesta.length === 0) {

            this.setState({
                bodyError: 'La pregunta debe contener "Nombre", "Frases de entrenamiento" y al menos una respuesta',
                errorBot: true
            });
            console.log("ERROR MODAL*********")
            return;
        }

        let invalidateText = false;
        let invalidateText3option = false;
        let invalidateBoton = false;
        let invalidateImg = false;
        let invalidateList = false;
        let respType = false;
        respuesta.opcionesRespuesta.forEach(doc => {
            respType = doc.tipoRespuesta;
            if (doc.tipoRespuesta === 'text' && !doc.value) {
                invalidateText = true
            }
            if (doc.tipoRespuesta === 'button' && doc.value === undefined) {
                invalidateBoton = true
            }

            if (doc.tipoRespuesta === 'button' && doc.value !== undefined) {
                //invalidateBoton = true
                if (doc.tipoRespuesta === 'button' && doc.value.title === undefined) {
                    invalidateBoton = true
                }
                if (doc.tipoRespuesta === 'button' && doc.value.title === '') {
                    invalidateBoton = true
                }
                if (doc.tipoRespuesta === 'button' && doc.value.subtitle === undefined) {
                    invalidateBoton = true
                }
                if (doc.tipoRespuesta === 'button' && doc.value.subtitle === '') {
                    invalidateBoton = true
                }


                if (doc.tipoRespuesta === 'button' && doc.value.options[0].value === '') {
                    invalidateBoton = true
                }
                if (doc.tipoRespuesta === 'button' && doc.value.options[0].value === undefined) {
                    invalidateBoton = true
                }

                if (doc.tipoRespuesta === 'button' && doc.value.options[1].value === undefined) {
                    invalidateBoton = true
                }
                if (doc.tipoRespuesta === 'button' && doc.value.options[1].value === '') {
                    invalidateBoton = true
                }

                if (doc.tipoRespuesta === 'button' && doc.value.options[2]) {
                    console.log("botton opcion 2")
                    if (doc.value.options[2].value === '') {
                        invalidateText3option = true
                    }
                }
            }
            if (doc.tipoRespuesta === 'imagen' && !doc.value) {
                invalidateImg = true;
            }
            if (doc.tipoRespuesta === 'list' && !doc.value) {
                invalidateList = true;
            }
            if (doc.tipoRespuesta === 'list' && doc.value) {
                if (doc.value.title === '') {
                    invalidateList = true;
                }
                if (doc.tipoRespuesta === 'list' && doc.value.subtitle === '') {
                    invalidateList = true;
                }
                if (doc.tipoRespuesta === 'list' && doc.value.items[0].options.length === 0) {
                    //console.log("doc.value.items[0].options", doc.value.items[0].options)
                    invalidateList = true;
                }
            }
        })

        if (invalidateList === true) {
            this.setState({
                bodyError: 'Por favor ingrese todos los campos de la lista',
                errorBot: true
            });
            return;
        }
        if (invalidateImg === true) {
            this.setState({
                bodyError: 'Por favor seleccione la imagen y luego presione el botón subir para cargar la foto',
                errorBot: true
            });
            return;
        }

        if (invalidateText === true) {
            this.setState({
                bodyError: 'Por favor ingrese un valor para la respuesta de texto',
                errorBot: true
            });
            return;
        }
        if (invalidateBoton === true) {
            this.setState({
                bodyError: 'Por favor ingrese todos los campos de el boton',
                errorBot: true
            });
            return;
        }
        if (invalidateBoton === true) {
            this.setState({
                bodyError: 'Por favor ingrese un valor para la tercer respuesta o elimine la opcion',
                errorBot: true
            });
            return;
        }
        if (invalidateText3option === true) {
            this.setState({
                bodyError: 'Por favor ingrese un valor para la tercera opcion del botón o elimine la opcion',
                errorBot: true
            });
            return;
        }


        if (intent && intent.idIntent) {
            const intentDataObj = {
                intent: intent,
                intentData: intentData,
                respuesta: respuesta
            }
            updateIntentDialogflow(intentDataObj)
            updatePopUp(respType)
            
          

            //funcion update firebase y dialogflow
            //validar si la imagen ya está cargada

        } else {
            uploadIntent(respuesta);
            createIntentDialogflow(respuesta);

            // console.log("POP CONFIGU", confirmarPopUp)
            confirmarPopUp(respType);



        }
        //getBots();
        //setTimeout(() => {
            //getIntents(getBotsSelector.nombreChatBot);
            getIntents(nombreBot)
        //}, 3000)
        returnToNombreBot();



        //console.log("COMPONENTE RESPUESTA REPUESTA LISTA", respuesta.respuestaLista)

        //console.log("ONSENDINTENT", intent)
        // console.log("ONSENDINTENTDATA", intentData)
        //console.log("ONSENDINTENTIDD", intent.nombreIntent)


    }

    render() {
        const {
            imageData,
            nombreBot,
            companyData,
            returnToNombreBot,
            botsData
        } = this.props;
        //console.log("image en component", imageData.cuenta)

        const onEnterClick = (e) => {
            if (e.key === 'Enter') {
                this.onFraseEntrenamiento()
                this.frase = ''
            }
        }


        return (
            <div className='chatbot-wrapper'>
                {this.state.errorBot ?
                    <ComponentError
                        onModalCancelError={this.onModalCancelError}
                        errorBot={this.state.errorBot}
                        errorTitle={'Error creando la pregunta'}
                        body={this.state.bodyError}
                    /> :
                    <>
                        <div className="titulo">

                            <h1>
                                Diseña tus conversaciones
                            </h1>
                            <Tooltip
                                title={
                                    <React.Fragment >
                                        <Typography className="title-tooltip">Crea resuestas a preguntas especificas de tu negocio que los visitantes hacen en tu sitio</Typography>
                                    </React.Fragment>
                                }
                            >
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="App bienvenida-container">
                            <div className='crear-intent'>
                                <div className="left">
                                    {imageData ?
                                        <>
                                            <Paper elevation={10} className="paper-conversaciones" >

                                                <div className="boton-volver">

                                                    <Button className="boton" color='primary' variant="contained" onClick={() => { returnToNombreBot() }}>
                                                        Volver
                                                    </Button>
                                                </div>
                                                <div className="title-crear-pregunta">

                                                    <h2>
                                                        Configura tu Bot con las palabras claves que suelen escribir tus
                                                        clientes para interpretarlas, y darles una respuesta personalizada.
                                                    </h2>
                                                </div>

                                                <div className="label-align">
                                                    <h3>
                                                        Pon un nombre a la pregunta así el Bot podrá encontrarla
                                                    </h3>
                                                </div>

                                                <TextField
                                                    label="Nombre de pregunta del ChatBot"
                                                    variant="outlined"
                                                    value={this.state.nombreBot}
                                                    onChange={this.onNombreHandler} />

                                                <div className="label-align">
                                                    <h3>
                                                        Si el cliente escribe:
                                                    </h3>
                                                </div>

                                                <div className="text-field" >
                                                    <Tooltip
                                                        title={
                                                            <React.Fragment >
                                                                <Typography className="title-tooltip">Por favor despues de escribir
                                                                    la frase haz clic en el botón "agregar frase de entrenamiento", o Presione
                                                                    la tecla "ENTER"
                                                                </Typography>

                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <TextField
                                                            onKeyDown={onEnterClick}
                                                            variant="outlined"
                                                            value={this.state.frase}
                                                            placeholder=""
                                                            label="Frases de entrenamiento"
                                                            onChange={this.onFraseClick}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                <div className="boton">
                                                    <Button className="boton" color='primary' variant="contained" onClick={() => { this.onFraseEntrenamiento() }}>
                                                        Agregar frase de entrenamiento
                                                    </Button>
                                                </div>

                                                {this.state.listaFrases?.map((item, i) => {
                                                    return (
                                                        <div className="text-field" key={i}>
                                                            <TextField id={`textfield-${i}`} value={item} onChange={this.editFrase}>  </TextField> <IconButton>
                                                                <DeleteIcon onClick={() => { this.deleteFrase(i) }} style={{ "cursor": "pointer" }} />
                                                                </IconButton> 
                                                        </div>
                                                    )
                                                })}

                                                {this.state.infoTooltip ?
                                                    <ComponentInfoTooltip
                                                        onInfoClickClose={this.onInfoClickClose} />
                                                    : <>

                                                        <div className="label-align">
                                                            <h3>
                                                                Entonces el bot responderá:
                                                            </h3>
                                                        </div>
                                                        <Tooltip
                                                            title={
                                                                <React.Fragment >
                                                                    <Typography className="title-tooltip">Por favor seleccione uno de los tipos de respuesta</Typography>

                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <div className="respuestas-container">
                                                                {this.state.opcionesRespuesta?.map((resp, i) => {
                                                                    if (resp.tipoRespuesta === "text") {
                                                                        //console.log("value tiporespuesta text", resp.value)
                                                                    } else {
                                                                        //console.log("elsse", resp.tipoRespuesta)

                                                                    }
                                                                    return (
                                                                        <div key={i}>
                                                                            <div className="numero-respuesta">
                                                                                Respuesta número: {this.state.renderNumeroRespuesta[i]}
                                                                            </div>
                                                                            <div className="respuestas-elementos">
                                                                                <ComponentRespuesta
                                                                                    respuestaText={resp.value}
                                                                                    respuestaButton={resp.value}
                                                                                    nombreBot={botsData}
                                                                                    imageData={imageData}
                                                                                    index={i}
                                                                                    onChange={this.onValueRespuesta}
                                                                                    component={resp.tipoRespuesta}
                                                                                    respuestaList={resp.value}
                                                                                    respuestaImg={resp.value}>
                                                                                </ComponentRespuesta>
                                                                                <DeleteIcon onClick={() => this.deleteRespuesta(i)} />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}

                                                            </div>
                                                        </Tooltip>

                                                        <h3>Seleccione el tipo de respuesta

                                                            <IconButton onClick={() => { this.onInfoClickOpen() }}>
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </h3>
                                                        <div className="boton" style={{ "display": "flex", "justifyContent": "center" }}>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment >
                                                                        {/* <img src="http://avanti-it.co/assets/img/Logo.png" />*/}
                                                                        <Typography className="title-tooltip">Respuesta con archivos adjuntos </Typography>
                                                                        <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton onClick={() => { this.seleccionarRespuesta('imagen', this.state.respCont) }} className="opciones_respuesta">
                                                                    <AttachFileIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                    <p style={{ "fontSize": "15px", "marginRight": "50px" }}
                                                                    >Adjuntos</p>
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment >
                                                                        <Typography className="title-tooltip">Respuesta con Texto</Typography>
                                                                        <em >{"Puedes dar click al signo de admiración para ver mas detalles de las respuestas"}</em>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton onClick={() => { this.seleccionarRespuesta('text', this.state.respCont) }} className="opciones_respuesta">
                                                                    <TitleIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                    <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Texto</p>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment >
                                                                        <Typography className="title-tooltip">Respuesta con botones</Typography>
                                                                        <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton onClick={() => { this.seleccionarRespuesta('button', this.state.respCont) }} className="opciones_respuesta">
                                                                    <CheckBoxOutlineBlankIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                    <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Botón</p>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={
                                                                    <React.Fragment >
                                                                        <Typography className="title-tooltip">Respuesta multiples</Typography>
                                                                        <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <IconButton onClick={() => { this.seleccionarRespuesta('list', this.state.respCont) }} className="opciones_respuesta">
                                                                    <ListIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                    <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Lista</p>
                                                                </IconButton>
                                                            </Tooltip>

                                                        </div>
                                                        <div className="boton">
                                                            <Button color='primary' variant="contained" onClick={this.onSendIntent}>Guarda la respuesta</Button>
                                                        </div>
                                                    </>
                                                }
                                            </Paper>

                                        </>
                                        :
                                        <Paper elevation={10} className="paper-conversaciones" >
                                            <div className="title-crear-pregunta">
                                                <h2>
                                                    Configura tu Bot con las palabras claves que suelen escribir tus
                                                    clientes para interpretarlas, y darles una respuesta personalizada.
                                                </h2>
                                            </div>

                                            <div className="label-align">
                                                <h3>
                                                    Pon un nombre a la pregunta así el Bot podrá encontrarla
                                                </h3>
                                            </div>

                                            <TextField
                                                label="Nombre de pregunta del ChatBot"
                                                variant="outlined"
                                                value={this.state.nombreBot}
                                                onChange={this.onNombreHandler} />

                                            <div className="label-align">
                                                <h3>
                                                    Si el cliente escribe:
                                                </h3>
                                            </div>

                                            <div className="text-field" >
                                                <Tooltip
                                                    title={
                                                        <React.Fragment >
                                                            <Typography className="title-tooltip">Por favor despues de escribir
                                                                la frase haz clic en el botón "agregar frase de entrenamiento", o Presione
                                                                la tecla "ENTER"
                                                            </Typography>

                                                        </React.Fragment>
                                                    }
                                                >
                                                    <TextField
                                                        onKeyDown={onEnterClick}
                                                        variant="outlined"
                                                        value={this.state.frase}
                                                        placeholder=""
                                                        label="Frases de entrenamiento"
                                                        onChange={this.onFraseClick}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className="boton">
                                                <Button className="boton" color='primary' variant="contained" onClick={() => { this.onFraseEntrenamiento() }}>
                                                    Agregar frase de entrenamiento
                                                </Button>
                                            </div>

                                            {this.state.listaFrases?.map((item, i) => {
                                                return (
                                                    <div className="text-field" key={i}>
                                                        <TextField id={`textfield-${i}`} value={item} onChange={this.editFrase}>  </TextField> <DeleteIcon onClick={() => { this.deleteFrase(i) }} />
                                                    </div>
                                                )
                                            })}

                                            {this.state.infoTooltip ?
                                                <ComponentInfoTooltip
                                                    onInfoClickClose={this.onInfoClickClose} />
                                                : <>

                                                    <div className="label-align">
                                                        <h3>
                                                            Entonces el bot responderá:
                                                        </h3>
                                                    </div>
                                                    <Tooltip
                                                        title={
                                                            <React.Fragment >
                                                                <Typography className="title-tooltip">Por favor seleccione uno de los tipos de respuesta</Typography>

                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <div className="respuestas-container">
                                                            {this.state.opcionesRespuesta?.map((resp, i) => {
                                                                if (resp.tipoRespuesta === "text") {
                                                                    //console.log("value tiporespuesta text", resp.value)
                                                                } else {
                                                                    //console.log("elsse", resp.tipoRespuesta)

                                                                }
                                                                return (
                                                                    <div key={i}>
                                                                        <div className="numero-respuesta">
                                                                            Respuesta número: {this.state.renderNumeroRespuesta[i]}
                                                                        </div>
                                                                        <div className="respuestas-elementos">
                                                                            <ComponentRespuesta
                                                                                respuestaText={resp.value}
                                                                                respuestaButton={resp.value}
                                                                                nombreBot={botsData}
                                                                                imageData={imageData}
                                                                                index={i}
                                                                                onChange={this.onValueRespuesta}
                                                                                component={resp.tipoRespuesta}
                                                                                respuestaList={resp.value}
                                                                                respuestaImg={resp.value}>
                                                                            </ComponentRespuesta>
                                                                            <DeleteIcon onClick={() => this.deleteRespuesta(i)} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </Tooltip>

                                                    <h3>Seleccione el tipo de respuesta

                                                        <IconButton onClick={() => { this.onInfoClickOpen() }}>
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </h3>
                                                    <div className="boton" style={{ "display": "flex", "justifyContent": "center" }}>
                                                        <Tooltip
                                                            title={
                                                                <React.Fragment >
                                                                    {/* <img src="http://avanti-it.co/assets/img/Logo.png" />*/}
                                                                    <Typography className="title-tooltip">Respuesta con archivos adjuntos </Typography>
                                                                    <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { this.seleccionarRespuesta('imagen', this.state.respCont) }} className="opciones_respuesta">
                                                                <AttachFileIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                <p style={{ "fontSize": "15px", "marginRight": "50px" }}
                                                                >Adjuntos</p>
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip
                                                            title={
                                                                <React.Fragment >
                                                                    <Typography className="title-tooltip">Respuesta con Texto</Typography>
                                                                    <em >{"Puedes dar click al signo de admiración para ver mas detalles de las respuestas"}</em>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { this.seleccionarRespuesta('text', this.state.respCont) }} className="opciones_respuesta">
                                                                <TitleIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Texto</p>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={
                                                                <React.Fragment >
                                                                    <Typography className="title-tooltip">Respuesta con botones</Typography>
                                                                    <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { this.seleccionarRespuesta('button', this.state.respCont) }} className="opciones_respuesta">
                                                                <CheckBoxOutlineBlankIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Botón</p>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={
                                                                <React.Fragment >
                                                                    <Typography className="title-tooltip">Respuesta multiples</Typography>
                                                                    <em >{"Puedes dar click al signo de admiración para ver más detalles de las respuestas"}</em>
                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <IconButton onClick={() => { this.seleccionarRespuesta('list', this.state.respCont) }} className="opciones_respuesta">
                                                                <ListIcon style={{ "fontSize": "35px", "marginRight": "50px" }} />
                                                                <p style={{ "fontSize": "15px", "marginRight": "50px" }}>Lista</p>
                                                            </IconButton>
                                                        </Tooltip>

                                                    </div>
                                                    <div className="boton">
                                                        <Button color='primary' variant="contained" onClick={this.onSendIntent}>Guarda la respuesta</Button>
                                                    </div>
                                                </>
                                            }
                                        </Paper>
                                    }


                                </div>
                                {/* 
                                <div className="right-widget-hide">
                                   {companyData.cuenta ?
                                        <iframe className="iframe" style={{ "border": "none" }} src={`${process.env.REACT_APP_CHAT_WIDGET}?companyId=${companyData.cuenta}`} />
                                        : <h1>La compañia selecciona no existe</h1>
                                    }

                                </div>
                                */}
                            </div>


                        </div>

                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        botsData: selectGetBots(state),
        userHeaders: state.userHeaders,
        users: getUsers(state),
        items: getListaPrueba(state),
        imageData: getuploadImage(state),
        intentSelect: selectIntent(state),

    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        getBots: (value) => dispatch({ type: GET_BOTS, value }),
        cargarItems: () => dispatch({ type: ADD_ITEM, value: 1 }),
        uploadImage: () => dispatch({ type: UPLOAD_IMAGE, value: 1 }),
        uploadIntent: (value) => dispatch({ type: UPLOAD_INTENT, value }),
        getIntents: (value) => dispatch({ type: GET_INTENT, value }),
        editIntent: (value) => dispatch({ type: EDIT_INTENT, value }),
        createIntentDialogflow: (value) => dispatch({ type: CREATE_DIALOGFLOW_INTENT, value }),
        updateIntentDialogflow: (value) => dispatch({ type: UPDATE_DIALOGFLOW_INTENT, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps,
)(withRouter(ComponentCrearIntent));