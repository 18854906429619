import React, { Component } from "react";
//import "./style.css";
import { connect } from "react-redux";
import CustomForm from "../../components/customform/customform";
import { getAuthForm, getSelectedForm, getSelectedTab, getMessage, getAuthError } from "./selectors";
import { Alert } from 'react-bootstrap';

import {
    AUTH_FORMS,
    UPDATE_FIELD_ATTRIBUTE,
    getSignButton,
    
    SELECT_TAB,
    TAB_SING_IN,
    SIGN_USER
} from './constants';



class ComponentSign extends Component {
    render() {
        const {
            updateAttributes,
            selectedForm,
            tabSelected,
         
            signUser,
            message,
            error,
        } = this.props;

        return (
            tabSelected === `tab-${TAB_SING_IN}` ?
                <div className="App auth-container">
                    <div className='auth-list_wrapper'>
                        { message ? 
                            <React.Fragment>
                                { error 
                                    ? <Alert className="alert danger" variant="danger">{message}</Alert>
                                    : <Alert className="alert success" variant="success">{message}</Alert>
                                }
                            </React.Fragment>
                            : ''
                        }
                        {/*<TabPanel value={1} items={tabs(selectTab)} className='authentication-tabs'/>*/}
                        <CustomForm 
                            formName="authForm"
                            items = { AUTH_FORMS } 
                            /* formTitle = "Sign in" */
                            values = { selectedForm }
                            updateAction = { updateAttributes } 
                            buttons = { getSignButton(() => signUser(selectedForm)) }
                        />
                    </div>
                </div>
            : ''
            );
        }
}

const mapStateToProps = state => {
    return {
        authForm: getAuthForm(state),
        selectedForm: getSelectedForm(state),
        tabSelected: getSelectedTab (state),
        message: getMessage(state),
        error: getAuthError(state),
    };
};

const mapDispachToProps = dispatch => {
    return { 
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        selectTab: (value) => dispatch({ type: SELECT_TAB, value }),
        signUser: (value) => dispatch({ type: SIGN_USER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentSign);