import React, { Component } from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './customcardPlanes.css';
import { Image, Button } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import RedeemIcon from '@material-ui/icons/Redeem';
import { Box } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
//import AutoAwesomeIcon from '@material-ui/icons/AutoAwesome';

export default class CustomCardPlanes extends Component {
    handleClick = (itemId) => {
        const { onItemClick } = this.props;
        console.log("handleClick", itemId)
        onItemClick(itemId);
    }

    render() {
        const { items, onItemClick } = this.props;
        console.log("ITEMS", items)
        return (
            <Accordion className='cards-wrapper-planes' defaultActiveKey="0">
                {
                    items.map((item, i) => {
                        const { title, price, description, path, icon, actual, labelPlan, buttonLabel, key, type, role, picture, accordion } = item;
                        return (
                            <Card key={i} className="card-planes" style={{ "border-bottom": "1px solid" }}>
                                <Card.Header>
                                    {accordion ?
                                        <Accordion.Toggle as={Button} variant="link" eventKey={key}>
                                            {picture ?
                                                <Image className='card-image' src={picture} roundedCircle /> :
                                                icon ? <FontAwesomeIcon className='card-icon' icon={icon} /> : false
                                            }
                                            <Card.Title>{title}</Card.Title>
                                        </Accordion.Toggle>
                                        : <div className="header-wrapper-planes">
                                            {picture ?
                                                <Image className='card-image' src={picture} roundedCircle />
                                                :
                                                icon ? <FontAwesomeIcon className='card-icon' icon={icon} /> : false
                                            }
                                            <Card.Title>{title}</Card.Title>
                                            {price ?

                                                <Card.Title>{price}</Card.Title>

                                                : <Card.Title> </Card.Title>
                                            }
                                        </div>
                                    }
                                </Card.Header>
                                {accordion ?
                                    <Accordion.Collapse eventKey={key}>
                                        <Card.Body style={{ "display": "grid" }}>
                                            <Card.Text>
                                                {description}
                                            </Card.Text>
                                            {type && type === 'button' ?
                                                <Button className='btn btn-primary ' style={{ "margin-top": "auto" }} onClick={() => this.handleClick(item.id)}>
                                                    {buttonLabel}
                                                </Button>
                                                :
                                                <Link to={path} className='sidebar-nav-link btn btn-primary '>
                                                    {buttonLabel}
                                                </Link>
                                            }
                                        </Card.Body>
                                    </Accordion.Collapse> :
                                    <Card.Body style={{ "display": "grid" }}>
                                        <Box>
                                            {description?.map((item, i) => {
                                                return <div key={i} className="text-field" ><CheckIcon color="primary" /> {item}  </div>
                                            })}
                                        </Box>
                                        {type && type === 'button' && !actual ?
                                            <Button className='btn btn-primary' style={{ "margin-top": "auto" }} onClick={() => this.handleClick(item.id)}>
                                                {buttonLabel}
                                            </Button>
                                            :
                                            <>
                                                {actual ?
                                                    <div className='mi-plan-actual'>

                                                        <StarIcon />
                                                        <h4 style={{ "margin-top": "auto" }}>
                                                            {labelPlan}

                                                        </h4>
                                                    
                                                    </div>

                                                    :
                                                    ''
                                                }
                                            </>
                                        }

                                    </Card.Body>
                                }

                            </Card>
                        )
                    })
                }
            </Accordion>
        );
    }
}