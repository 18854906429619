import {
    ADQUIRIR_PRODUCTO,
    ADQUIRIR_PRODUCTO_SUCCES,
    GET_PLANES_SUCCES,
    GET_TRANSACCION_SUCCES,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LOAD_MENU_SUCCES
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}

export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}


export function adquirirProducto (payload) {
    return {
        type: ADQUIRIR_PRODUCTO,
        payload
    };
}

export function adquirirProductoSucces (payload) {
    return {
        type: ADQUIRIR_PRODUCTO_SUCCES,
        payload
    };
}


export function getPlanesSuccess (payload) {
    return {
        type: GET_PLANES_SUCCES,
        payload
    };
}
export function getTransaccionSuccess (payload) {
    return {
        type: GET_TRANSACCION_SUCCES,
        payload
    };
}

