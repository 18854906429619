import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './customLinks.css';

export default class CustomLinks extends Component {
    handleClick = (itemId) => { 
        const { onItemClick } = this.props;
        console.log("handleClick", onItemClick)
        onItemClick(itemId);
    }

    render() {
        const { items, styleLinks } = this.props;

        return (
            <div className={`${styleLinks}`}>
                {items.map((item, i) => {
                    return (
                        <Link key={i} to={item.path} className={`sidebar-nav-link ${item.className}`}>
                            <Button color={item.color} variant={item.variant}>
                                {item.label}
                            </Button>
                        </Link>
                    )
                })}


            </div>
        );
    }
}
