import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import validate from './validateClientWebForm';
import { Button } from 'primereact/button';
import { connect } from 'react-redux'


const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderFieldTextArea = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <textarea rows="2" cols="32" className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);



const renderSelectTypeFormField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <select className="selectStyle p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus"  {...input} type={type} placeholder={label} >

                <option value="text">Respuesta texto/Caja de texto</option>
                <option value="select">Respuesta selección única/Lista opciones</option>
                <option value="radioButton">Respuesta selección única/Radio Buttons</option>
                <option value="ratingStars">Respuesta calificación estrellas/5 botones de Estrellas</option>

            </select>
        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderOptions = ({ fields, meta: { error } }) => (
    <div className="p-grid p-ml-5 p-mt-2">
        <div className="p-dir-col">
            {fields.map((option, index) => (
                <div key={index}>
                    <div className="p-grid">
                        <div className="p-col"><h4>{index + 1}</h4></div>
                        <Field
                            name={`${option}.name`}
                            type="text"
                            component={renderField}
                            label="Nombre"
                        />
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderField}
                            label="Valor"
                        />
                        <Button
                            tooltip="Quitar Opción"
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}

        </div>
        {error && <li className="error">{error}</li>}
        <div className='p-grid p-align-end p-ml-3'>
            <Button icon="pi pi-plus" label="Añadir opción" tooltip="Añadir opción" className="mt-2 ml-5 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({})}></Button>

        </div>

    </div>
);

const renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div className="pl-4">
        <div>
            {fields.map((pregunta, index) => (
                <div key={index}>
                    <div className="p-grid p-mt-2">
                        <div className="p-grid p-dir-col mt-3 mr-1">
                            {index !== 0 ? <Button
                                type="button"
                                tooltip="Mover hacia arriba"
                                onClick={() => fields.swap(index, index - 1)}
                                className="swapButtons p-button-raised p-button-rounded p-button-secondary"
                                icon="pi pi-angle-up"
                            /> : false}

                            {index < fields.length - 1 ?
                                <Button
                                    type="button"
                                    tooltip="Mover hacia abajo"
                                    onClick={() => fields.swap(index, index + 1)}
                                    className="swapButtons mt-1 p-button-raised p-button-rounded p-button-secondary"
                                    icon="pi pi-angle-down"
                                /> : false}

                        </div>
                        <div className="p-col-fixed" style={{ width: '30px' }}><h4>{index + 1}</h4></div>

                        <Field
                            name={`${pregunta}.type`}
                            label="Tipo de respuesta"
                            component={renderSelectTypeFormField}
                            type="text"
                        >
                        </Field>


                        <Field
                            name={`${pregunta}.nombre`}
                            type="text"
                            component={renderFieldTextArea}
                            label="Pregunta"
                        />

                        <Field
                            name={`${pregunta}.nombreCorto`}
                            type="text"
                            component={renderField}
                            label="Identificador de la pregunta/nombre corto"
                        />

                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                            icon="pi pi-times"
                        />
                        <Button
                            type="button"
                            tooltip="Insertar campo abajo"
                            onClick={() => fields.insert(index + 1, { 'type': 'text' })}
                            className="ml-1 removeButtonStyle p-button-raised p-button-rounded p-button-success"
                            icon="pi pi-plus"
                        />
                    </div>
                    {fields.get(index).type === "select" || fields.get(index).type === "radioButton" ?
                        <FieldArray name={`${pregunta}.opcionesRespuesta`} component={renderOptions} />
                        : null
                    }
                </div>


            ))}
        </div>
        <div className='p-grid  p-mt-2 p-justify-center'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir pregunta" label='Añadir pregunta' icon="pi pi-plus" className="mt-3 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({ 'type': 'text' })} />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>


    </div>
)

const renderFieldOCR = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderFieldTextAreaOCR = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <textarea rows="2" cols="32" className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderSelectTypeFormFieldOCR = ({ input, renderValues, documentos, label, type, meta: { touched, error } }) => (

    <div>
        <label>{label}</label>
        <div>

            {
                /* 
                console.log("list input ITEMS", input)
            <div>
                <input className="p-inputtext p-component mr-2 p-1"  {...input} type={type} placeholder={label} />

            </div>
        */}

            <select className="selectStyle p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus"  {...input} type={type} placeholder={label} >
                <option value={'-1'}>Selecione una opción</option>
                {renderValues ? renderValues.map((i, key) => {
                    return <option value={i.tipoDocumento}>{i.tipoDocumento}</option>
                }) : ''
                }

                {documentos ? documentos.map((i, key) => {
                    return <option value={i.value}>{i.value}</option>
                }) : ''
                }

                {/* <option value="select">Respuesta selección única/Lista opciones</option>
                <option value="radioButton">Respuesta selección única/Radio Buttons</option>
                <option value="ratingStars">Respuesta calificación estrellas/5 botones de Estrellas</option> */}

            </select>

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}

    </div>
);

const renderOptionsOCR = ({ fields, meta: { error } }) => (
    <div className="p-grid p-ml-5 p-mt-2">
        <div className="p-dir-col">
            {fields.map((option, index) => (
                <div key={index}>
                    <div className="p-grid">
                        <div className="p-col"><h4>{index + 1}</h4></div>
                        <Field
                            name={`${option}.name`}
                            type="text"
                            component={renderField}
                            label="Nombre"
                        />
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderField}
                            label="Valor"
                        />
                        <Button
                            tooltip="Quitar Opción"
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}

        </div>
        {error && <li className="error">{error}</li>}
        {/* <div className='p-grid p-align-end p-ml-3'>
            <Button icon="pi pi-plus" label="Añadir opción" tooltip="Añadir opción" className="mt-2 ml-5 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({})}></Button>

        </div> */}

    </div>
);


const renderQuestionsOCR = ({ fields, optionList, setOptions, initialValues, dataSelect, documentos, documentos2, setDocumentos2, setDocumentos, meta: { touched, error, submitFailed } }) => (

    <div className="pl-4">
        <div>
            {fields.map((pregunta, index) => (
                <div key={index}>
                    <div className="p-grid p-mt-3">
                        <div className="p-grid p-dir-col mt-3 mr-1">
                            {index !== 0 ? <Button
                                type="button"
                                tooltip="Mover hacia arriba"
                                onClick={() => fields.swap(index, index - 1)}
                                className="swapButtons p-button-raised p-button-rounded p-button-secondary"
                                icon="pi pi-angle-up"
                            /> : false}

                            {index < fields.length - 1 ?
                                <Button
                                    type="button"
                                    tooltip="Mover hacia abajo"
                                    onClick={() => fields.swap(index, index + 1)}
                                    className="swapButtons mt-1 p-button-raised p-button-rounded p-button-secondary"
                                    icon="pi pi-angle-down"
                                /> : false}

                        </div>
                        <div className="p-col-fixed" style={{ width: '30px' }}><h4>{index + 1}</h4></div>

                        {/* <Field
                            name={`${pregunta}.type`}
                            label="Tipo de respuesta"
                            component={renderSelectTypeFormField}
                            type="text"
                        >
                        

                        </Field> 
                        console.log("optionList", optionList[index].documentos)
                        
                        
                        <div className='fields'>
                            <Field
                                name={`${pregunta}.docCruce`}
                                type="text"
                                component={renderFieldOCR}
                                label="Documento para cruzar"
                            />

                            <Field
                                name={`${pregunta}.valorCruce`}
                                type="text"
                                component={renderFieldOCR}
                                label="Campo"
                            />

                        </div>
                        <Field
                            name={`${pregunta}.valorPpal-${index}`}
                            type="text"
                            renderValues={dataSelect}
                            onChange={() => { console.log("render options document") }}
                            component={renderSelectTypeFormFieldOCR}
                            label={`Campo del documento -${index}`}
                        />
                        console.log("pregunta", pregunta)
                        */
                        }

                        {optionList[index] && optionList[index].crucesDocumento.map((field, key) => {

                            return (
                                <div className='fields'>
                                    <Field
                                        name={`${pregunta}.docPpal-${key}`}
                                        type="text"
                                        value={field.docPpal}
                                        //list={[{ label: "primerVAlor", value: 1 }, { label: "segundoVAlor", value: 2 }]}
                                        renderValues={dataSelect}
                                        onChange={(e) => {
                                            //console.log("dataSelect TARGET", dataSelect);
                                            const result = dataSelect.filter((documento) => documento.tipoDocumento === e.target.value);
                                            //console.log("e.target.value", e.target.value);
                                            console.log("RESULT", result)
                                            field.documentos = result && result.length > 0 ? result[0].campos : []
                                            optionList[index].crucesDocumento[key] = field
                                            //  console.log(" optionList[index].fields[key]",  optionList[index].fields[key]);

                                            setOptions(optionList)
                                            // setDocumentos(dataSelect[e.target.value].campos)
                                        }}
                                        component={renderSelectTypeFormFieldOCR}
                                        //setDocumentos={setDocumentos}
                                        label={`Documento-${key}`}
                                    />
                                    <Field
                                        name={`${pregunta}.valorPpal-${key}`}
                                        type="text"
                                        value={field.valorPpal}
                                        documentos={field ? field.documentos : []}
                                        onChange={() => { console.log("render options document") }}
                                        component={renderSelectTypeFormFieldOCR}
                                        label={`Campo del documento -${key}`}
                                    />
                                    <Button
                                        type="button"
                                        tooltip="QuitarRR"
                                        onClick={() => {
                                            const tempList = [...optionList]
                                            tempList[index].crucesDocumento.splice(key, 1)
                                            setOptions([...tempList])
                                        }
                                        }
                                        className="removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                                        icon="pi pi-trash"
                                    />
                                </div>
                            )
                        })
                        }



                        <div className='p-grid  p-mt-2 p-justify-center'>
                            <Button style={{ marginLeft: 30 }} tooltip="Añadir"
                                label='' icon="pi pi-plus"
                                className="mt-3 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success"
                                type="button"
                                onClick={(e) => {
                                    let newOptionList = [...optionList];
                                    newOptionList[index].crucesDocumento[newOptionList[index].crucesDocumento.length] = { documentos: [], docPpal: '', valorPpal: '' }
                                    //optionList[index].fields.push({ documentos: [] })
                                    setOptions(newOptionList)
                                    console.log("OPTIONLIST", newOptionList[index].crucesDocumento)
                                    return;
                                    // setDocumentos(dataSelect[e.target.value].campos)
                                }}
                            />


                            <Button
                             style={{ marginLeft: 30 }} 
                                type="button"
                                tooltip="Quitar"
                                onClick={() => fields.remove(index)}
                                className="mt-3 mr-2 removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                                icon="pi pi-times"
                            />

                            {/* <Button
                            type="button"
                            tooltip="Insertar campo abajo"
                            onClick={() => fields.insert(index + 1, { 'type': 'select' })}
                            className="ml-1 removeButtonStyle p-button-raised p-button-rounded p-button-success"
                            icon="pi pi-plus"
                            />  */}
                        </div>

                        {/* <div className='fields'>

                            <Field
                                name={`${pregunta}.docPpal`}
                                type="text"
                                //list={[{ label: "primerVAlor", value: 1 }, { label: "segundoVAlor", value: 2 }]}
                                renderValues={dataSelect}
                                onChange={(e) => {
                                    console.log("dataSelect[e.target.value]", dataSelect[e.target.value]);
                                    optionList[index].documentos = dataSelect[e.target.value].campos
                                    setOptions(optionList)
                                    // setDocumentos(dataSelect[e.target.value].campos)
                                }}
                                component={renderSelectTypeFormFieldOCR}
                                //setDocumentos={setDocumentos}
                                label="Documento principal"
                            />

                            <Field
                                name={`${pregunta}.valorPpal`}
                                type="text"
                                documentos={optionList[index] ? optionList[index].documentos : []}
                                onChange={() => { console.log("render options document") }}
                                component={renderSelectTypeFormFieldOCR}
                                label="Campo del documento"
                            />
                        </div>

                        <div className='fields'>
                            <Field
                                name={`${pregunta}.docCruce`}
                                type="text"
                                //list={[{ label: "primerVAlor", value: 1 }, { label: "segundoVAlor", value: 2 }]}
                                renderValues={dataSelect}
                                onChange={(e) => {
                                    console.log("render options document", e.target.value);
                                    optionList[index].documentos2 = dataSelect[e.target.value].campos
                                    setOptions(optionList)
                                    // setDocumentos(dataSelect[e.target.value].campos)
                                }}
                                component={renderSelectTypeFormFieldOCR}

                                //setDocumentos={setDocumentos2}
                                label="Documento Cruce"
                            />

                            <Field
                                name={`${pregunta}.valorCruce`}
                                type="text"
                                documentos={optionList[index] ? optionList[index].documentos2 : []}
                                onChange={() => { console.log("render options document") }}
                                component={renderSelectTypeFormFieldOCR}
                                label="Campo del documento para cruzar"
                            /> */}
                        {/*

                             <Field
                                name={`${pregunta}.valorPpal`}
                                type="text"
                                component={renderFieldOCR}
                                label="Campo"
                            /> 
                        </div>
                        */}

                        {/* 
                        <div className='fields'>
                            <Field
                                name={`${pregunta}.docCruce`}
                                type="text"
                                component={renderFieldOCR}
                                label="Documento para cruzar"
                            />

                            <Field
                                name={`${pregunta}.valorCruce`}
                                type="text"
                                component={renderFieldOCR}
                                label="Campo"
                            />

                        </div> 
                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                            icon="pi pi-times"
                        />
                        */}
                        {/* 
                        <Button
                            type="button"
                            tooltip="Insertar campo abajo"
                            onClick={() => fields.insert(index + 1, { 'type': 'text' })}
                            className="ml-1 removeButtonStyle p-button-raised p-button-rounded p-button-success"
                            icon="pi pi-plus"
                        /> 
                    */}
                    </div>
                    {fields.get(index).type === "select" || fields.get(index).type === "radioButton" ?
                        <FieldArray name={`${pregunta}.opcionesRpuesta`} component={renderOptionsOCR} />
                        : null
                    }
                </div>


            ))}
        </div>

        <div className='p-grid  p-mt-2 p-justify-center'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir" label='Añadir'
                icon="pi pi-plus" className="mt-3 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success"
                type="button"
                onClick={() => {
                    //console.log("CLI")

                    optionList.push(
                        {
                            "crucesDocumento": [
                                {
                                    "docPpal": "cedula",
                                    "valorPpal": "medicamento",
                                    "documentos": [
                                        { "value": "textoFull" },
                                        { "value": "medicamento" }]
                                }]
                        }
                    )
                    fields.push({
                        //optionList,
                        // crucesDocumento: [
                        //     {
                        //         docPpal: '',
                        //         valorPpal: '',
                        //         documentos: []
                        //     }
                        // ], fields: [{ documentos: [], crucesDocumento: [] }],
                        type: 'text'
                    }

                    )

                    //optionList[index].fields.push({ documentos: [] })
                    setOptions(optionList)
                    return;
                }
                } />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>


    </div>
)
const FieldArraysForm = props => {

    //const [documentos2, setDocumentos2] = React.useState([]);
    const { handleSubmit, pristine, dataSelect, initialValues, adminOCR, reset, submitting } = props; 
    let optionList = []
    const [options, setOptions] = React.useState(optionList);
    //console.log("FIELDS", props)

    if(adminOCR){
        if (initialValues && initialValues.preguntas) {
            initialValues.preguntas.map((pregunta) => {
                let claves = [...Object.keys(pregunta)];
                // console.log("CLAVE", claves)
                let cantidadInicial = (claves.length - 1) / 2;
                let crucesDocumento = [];
    
    
                for (let i = 0; i < cantidadInicial; i++) {
                    const result = dataSelect.filter((documento) => documento.tipoDocumento === pregunta[`docPpal-${i}`]);
                    // console.log("DATA SELECT FOR", dataSelect)
                    let cruce = {
                        docPpal: pregunta[`docPpal-${i}`],
                        valorPpal: pregunta[`valorPpal-${i}`],
                        documentos: result[0] ? result[0].campos : []
                    }
                    crucesDocumento.push(cruce)
                    let clave = claves[i];
    
                    // console.log("pregunta Clave", pregunta[clave]);
                }
                //console.log("crucesDocumento", crucesDocumento)
    
                optionList.push({ crucesDocumento, fields: [{ documentos: [], crucesDocumento }] })
            })
           // console.log("optionList", JSON.stringify(optionList))
        } else {
            optionList.push({
                crucesDocumento: [
                    {
                        docPpal: '',
                        valorPpal: '',
                        documentos: []
                    }
                ], fields: [{ documentos: [], crucesDocumento: [] }]
            })
        }
    }
    // console.log("optionList", optionList)

    //console.log("optionsvlista values", options)
    //console.log("dataSelect", dataSelect)
    return (

        <form onSubmit={handleSubmit} >
            <FieldArray name={adminOCR ? "preguntas" : "preguntasEncuesta"}
                setOptions={setOptions}
                optionList={options}
                initialValues={initialValues}
                dataSelect={dataSelect}
                component={adminOCR ? renderQuestionsOCR : renderQuestions} />
            <div className="p-mt-2 p-grid p-justify-center">
                <div>
                    <Button icon="pi pi-save" label="Guardar" tooltip="Guardar" className="mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-info" type="submit" disabled={submitting || pristine} />
                    <Button icon="pi pi-ban" label="Limpiar" tooltip="limpiar" className="p-button-raised p-button-rounded p-button-success p-button-warning" type="button" disabled={pristine || submitting} onClick={reset} />
                </div>

            </div>
        </form>
    );
};





const mapStateToProps = (state) => {
    //console.log('***preguntasEncuesta****', state.form.fieldArraysEncuestaForm );
    return {
        initialValues: {

            "preguntas": state.localReducer.initialValuesEncuesta

        },

    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'fieldArraysEncuestaForm',
    enableReinitialize: true,
    validate,

})(FieldArraysForm));
