import {
  initialize
} from "redux-form";
import {
  put,
  takeLatest
} from "redux-saga/effects";
import {
  consultaProperty,
  editProperty,
  guardarFormClienteWeb,
  guardarNuevoHorario
} from "../../controller/configuration";
import {
  getFromSession, storeInSession
} from "../../controller/session";
import moment from "moment";
import {
  firebaseDatabase
} from "../../controller/firebase";
import {
  traerFaqs,
  editFaq,
  deleteFaq,
  addFaq
} from "../../controller/faqs";
import {
  anadirHabilidad,
  deleteHabilidad,
  editHabilidadporId,
  gethabilidades
} from "../../controller/habilidades";
import {
  addTemaNivel1,
  addTemaNivel2,
  addTemaNivel3,
  deleteTemaNivel1,
  deleteTemaNivel2,
  deleteTemaNivel3,
  editTema,
  lstTemas
} from "../../controller/temas";
import {
  getUsuarios,
  editUser,
  crearDatosGeneralesUsuario,
  cambiarUsuarioContraseña,
  deleteUserInColletion,
  consultarCorreoExistente,
  editSkillInUser
} from "../../controller/usuarios";
import {
  editarFaqSuccess,
  listarFaqsError,
  listarFaqsSucces,
  editarFaqError,
  borrarFaqError,
  borrarFaqSuccess,
  agregarFaqError,
  agregarFaqSuccess,
  listarTemasError,
  listarTemasSuccess,
  editarTemaError,
  editarTemaSuccess,
  borrarTemaError,
  agregarTemaError,
  agregarTemaSuccess,
  borrarTemaSuccess,
  listarUsuariosError,
  listarUsuariosSuccess,
  editarUsuarioError,
  traerHabilidadesError,
  traerHabilidadesSuccess,
  editarUsuarioSuccess,
  crearUsuarioError,
  crearUsuarioSuccess,
  seleccionarFilaSuccess,
  eliminarUsuarioError,
  abrirModalBorrarSuccess,
  eliminarUsuarioSuccess,
  traerVariablesConfiguracionSuccess,
  seleccionarConfSuccess,
  editarConfiguracionError,
  editarConfiguracionSuccess,
  consultarHorarioError,
  consultarHorarioSuccess,
  anadirDiasHorarioError,
  anadirDiasHorarioSuccess,
  seleccionarFilaHabilidadSuccess,
  editarHabilidadError,
  editarHabilidadSuccess,
  crearHabilidadError,
  crearHabilidadSuccess,
  eliminarHabilidadError,
  eliminarHabilidadSuccess,
  traerConfFormClienteWebSuccess,
  guardarFormularioClienteWebSuccess,
  traerConfFormClienteWebError,
  traerConfEncuestaClienteWebSuccess,
  traerConfEncuestaClienteWebError,
  guardarFormEncuestaClienteWebError,
  guardarFormEncuestaClienteWebSuccess,
  guardarDocumentFormWebSuccess,
  traerConfFormDocumentSuccess,
  traerConfFormDocumentError,
  traerConfCruceSuccess,
  traerConfCruceError,
  guardarCruceFormSuccess,
  manageSpiner,

} from "./action";
import {
  EDITAR_FAQ,
  GET_FAQS,
  BORRAR_FAQ,
  AGREGAR_FAQ,
  GET_TEMAS,
  EDITAR_TEMA,
  BORRAR_TEMA,
  AGREGAR_TEMA,
  LISTAR_USUARIOS,
  EDITAR_USUARIO,
  GET_HABILIDADES,
  CREAR_USUARIO,
  SELECT_ROW,
  ELIMINAR_USUARIO,
  ABRIR_MODAL_BORRAR,
  VARIABLES_CONFIGURACION,
  SELECCIONAR_CONF,
  EDITAR_CONF,
  CONSULTAR_HORARIO,
  HORARIO_HABIL_DIAS,
  SELECT_ROW_HABILIDAD,
  EDITAR_HABILIDAD,
  CREAR_HABILIDAD,
  ELIMINAR_HABILIDAD,
  CAMPO_TYPE_BOOLEAN,
  CAMPO_TYPE_NUMBER,
  CAMPO_TYPE_TEXT,
  CLIENT_FORM_WEB,
  GUARDAR_CLIENT_FORM_WEB,
  ENCUESTA_FORM_WEB,
  GUARDAR_ENCUESTA,

  CONSULTAR_DIALOG_FLOW_PROPERTY,
  GUARDAR_DOCUMENT_FORM_WEB,
  DOCUMENT_FORM,
  CRUCE_FORM,
  GUARDAR_CRUCE_FORM
} from "./constants";


function tipoVar(value) {
  return typeof value;
}

function* listarFaqs() {
  try {
    let objFaqs = yield traerFaqs();
    let faqsDisplay = [];
    //console.log('objFaqs', objFaqs)

    objFaqs.forEach(faq => {
      let idFaq = faq.ref.id;
      const faqs = {
        pregunta: faq.data().pregunta,
        respuesta: faq.data().respuesta,
        id: idFaq,
      }
      faqsDisplay.push(faqs);
    });
    yield put(listarFaqsSucces({
      faqs: faqsDisplay
    }));

  } catch (error) {
    console.error('saga.listarFaqs', error);
    put(listarFaqsError(error));
  }
}

export function* listarTemas() {
  try {
    let temas = yield lstTemas();
    // console.log('objTemas', temas)

    let opcionesNivel1 = [];

    temas.forEach(tema => {
      opcionesNivel1.push({
        'label': tema.nivel1,
        'value': tema.idNivel1
      });
    });
    opcionesNivel1 = eliminarDuplicados(opcionesNivel1);
    //console.log('temas', temas);

    yield put(listarTemasSuccess({
      'temas': temas,
      'opcionesNivel1': opcionesNivel1
    }));

  } catch (error) {
    console.error('saga.listarTemas', error);
    yield put(listarTemasError(error))
  }
}

export function* listarUsuarios() {
  try {
    let objUsuarios = yield getUsuarios();
    //console.log("objUsuarios *****************", objUsuarios)
    let usuariosDisplay = [];

    objUsuarios.forEach(usuario => {
      let idUsuario = usuario.ref.id;
      let dataUser = usuario.data();
      //console.log("idUsuario", dataUser)
      const usuarios = {
        nombres: dataUser.nombres,
        apellidos: dataUser.apellidos,
        correo: dataUser.mail,
        nickname: dataUser.nickname,
        rol: dataUser.rol,
        skill: {
          nombre: dataUser.skill.nombre,
          id: dataUser.skill.id
        },
        habilidadId: dataUser.skill.id,
        id: idUsuario,
        uid: dataUser.uid,

      }
      usuariosDisplay.push(usuarios);
    });

    yield put(listarUsuariosSuccess({
      usuarios: usuariosDisplay
    }))


  } catch (error) {
    console.error('saga.listarUsuarios', error);
    yield put(listarUsuariosError(error))
  }
}
export function* seleccionarFila(action) {
  console.log('action', action)
  try {
    yield put(initialize('usuarios', action.value))
    yield put(seleccionarFilaSuccess({
      abrirModalEditar: true
    }));

  } catch (error) {
    console.error('saga.seleccionarFila', error);
    throw error

  }
}

export function* seleccionarHabilidad(action) {
  console.log('action', action)
  try {
    yield put(initialize('habilidades', action.value))
    yield put(seleccionarFilaHabilidadSuccess({
      abrirModalEditar: true
    }));

  } catch (err) {
    console.error('saga.seleccionarHabilidad', err);


  }
}

export function* seleccionarConf(action) {
  console.log('action**', action);

  try {
    yield put(initialize('configuracion', action.value))
    yield put(seleccionarConfSuccess({
      abrirModalEditarConf: true
    }));

  } catch (error) {
    console.error('saga.seleccionarConf', error);
    throw error;

  }
}
export function* crearHabilidad(action) {
  try {
    let toastDetail;
    console.log('action**', action);
    let resp = yield anadirHabilidad(action.value.nombre);


    if (resp) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha creado la habilidad!'
      }
      yield put(crearHabilidadSuccess({
        'limpiarForm': true,
        'toastDetail': toastDetail,
        showToast: true
      }))
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error intente mas tarde!'
      }
      yield put(crearHabilidadSuccess({
        limpiarForm: false,
        toastDetail: toastDetail,
        showToast: true
      }))
    }

  } catch (error) {
    console.error('saga.crearHabilidad', error);
    yield put(crearHabilidadError(error));
    throw error;

  }

}
export function* editarHabilidad(action) {

  let toastDetail;
  try {
    let resp = yield editHabilidadporId(action.value.value, action.value.label);

    if (resp) {
      let resp2 = yield editSkillInUser(action.value.value, action.value.label);
      if (resp2) {
        toastDetail = {
          'summary': 'Realizado!',
          'severity': 'success',
          'detail': 'Se ha editado la habilidad!'
        }
        yield put(editarHabilidadSuccess({
          "toastDetail": toastDetail,
          showToast: true,
          cerrarModal: false
        }))
      }
    } else {
      toastDetail = {
        'summary': 'No Realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error intente mas tarde!'
      }
      yield put(editarHabilidadSuccess({
        "toastDetail": toastDetail,
        showToast: true,
        cerrarModal: true
      }))
    }
  } catch (err) {
    console.error("saga.editarHabilidad", err);
    yield put(editarHabilidadError(err))
    throw err;
  }
}

export function* eliminarHabilidad(action) {
  console.log('action**', action);
  try {
    let toastDetail;

    let resp = yield deleteHabilidad(action.value);
    if (resp) {
      if (resp) {
        toastDetail = {
          'summary': 'Realizado!',
          'severity': 'success',
          'detail': 'Se ha eliminado la habilidad!'
        }
        yield put(eliminarHabilidadSuccess({
          "toastDetail": toastDetail,
          "showToast": true,
          "cerrarModal": false
        }))
      }
    } else {
      toastDetail = {
        'summary': 'No Realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error intente mas tarde!'
      }
      yield put(eliminarHabilidadSuccess({
        "toastDetail": toastDetail,
        "showToast": true,
        "cerrarModal": true
      }))
    }

  } catch (error) {
    console.error("saga.eliminarHabilidad", error);
    yield put(eliminarHabilidadError(error))
  }
}

export function* editarConf(action) {
  //console.log("EDIT CONIG", action);
  let toastDetail;
  try {
    const company = getFromSession("company_id");
    var val = action.value.value;
    const type = action.value.type;
    let docId = false;
    yield firebaseDatabase.collection(`company/${company}/configuration`)
      .where("name", "==", action.value.nombre)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Obtener el ID del documento
          docId = doc.id;
          //console.log("ID del documento:", docId);
        });
      })
      .catch((error) => {
        console.log("Error al obtener el documento:", error);
      });

    if (type == "boolean") {
      if (val === "false") {
        val = false;
      } else {
        val = true;
      }
    }
    if (type == "number") {
      val = parseInt(action.value.value);
    }
    let resp = yield editProperty(company, docId, val);

    if (resp) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha actualizado el mensaje'
      }

      yield put(editarConfiguracionSuccess({
        toastDetail: toastDetail,
        showToast: true,
        updateTable: true,
        ocultarModal: true
      }));
    } else {
      toastDetail = {
        'summary': 'Error!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema, intente más tarde'
      }
      yield put(editarConfiguracionSuccess({
        toastDetail: toastDetail,
        showToast: true,
        updateTable: false,
        ocultarModal: false
      }));
    }
    yield put(editarConfiguracionSuccess({
      toastDetail: toastDetail,
      showToast: false,
      updateTable: false,
      ocultarModal: false
    }));
  } catch (error) {
    console.error('saga.editarConf', error);
    yield put(editarConfiguracionError(error));
  }
}

function eliminarDuplicados(lista) {
  let set = new Set(lista.map(JSON.stringify))
  let listaSinDuplicados = Array.from(set).map(JSON.parse);
  return listaSinDuplicados;
}

export function* editarTema(formValues) {
  try {
    console.log('formValues', formValues);
    let idsNiveles = formValues.value.idsJson;
    let values = formValues.value.values;

    yield editTema(idsNiveles, values);
    yield put(editarTemaSuccess({
      updateTable: true,
      ocultarModal: true
    }));

  } catch (error) {
    console.error('saga.editarTema', error);
    yield put(editarTemaError(error));
  }
}

export function* agregarTema(formValues) {
  try {
    console.log('formValuesAgregarTema', formValues.value);
    let nombreNivel1Text = formValues.value.nivel1Text && formValues.value.nivel1Text !== "" ? formValues.value.nivel1Text : false;
    let idNivel1 = formValues.value.nivel1 && formValues.value.nivel1 !== "" ? formValues.value.nivel1 : false;
    let nombreNivel2 = formValues.value.nivel2Text && formValues.value.nivel2Text !== "" ? formValues.value.nivel2Text : false;
    let idNivel2 = formValues.value.nivel2 && formValues.value.nivel2 !== "" ? formValues.value.nivel2 : false;
    let nombreNivel3 = formValues.value.nivel3 && formValues.value.nivel3 !== "" ? formValues.value.nivel3 : false;
    let resp = false;
    let toastDetail;

    if (nombreNivel1Text) {
      resp = yield addTemaNivel1(nombreNivel1Text);
    } else if (idNivel1 && nombreNivel2) {
      resp = yield addTemaNivel2(idNivel1, nombreNivel2);
    } else if (idNivel1 && idNivel2 && nombreNivel3) {
      resp = yield addTemaNivel3(idNivel1, idNivel2, nombreNivel3);
    }


    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Tema agregado'
      }
      yield put(agregarTemaSuccess({
        updateTable: true,
        ocultarModal: true,
        toastDetail: toastDetail,
        showToast: true
      }))
    } else {

      toastDetail = {
        'summary': 'Tema no agregado',
        'severity': 'error',
        'detail': 'Debe llenar todos los campos'
      }
      yield put(agregarTemaSuccess({
        updateTable: false,
        ocultarModal: false,
        toastDetail: toastDetail,
        showToast: true
      }))
    }

  } catch (error) {
    console.error('saga.agregarTema', error);
    yield put(agregarTemaError(error))
  }
}

export function* borrarTema(formValues) {
  try {
    console.log('formValuesBorrar', formValues)
    let nombreLastNivel = formValues.value.nombreNivel;

    if (nombreLastNivel === "nivel1") {
      console.log('entro aqui a nivel 1')
      yield deleteTemaNivel1(formValues.value.lastNivelId);
    } else if (nombreLastNivel === "nivel2") {
      console.log('entro aqui a nivel 2')
      yield deleteTemaNivel2(formValues.value.nivel1Id, formValues.value.lastNivelId);
    } else {
      console.log('entro aqui a nivel 3')
      yield deleteTemaNivel3(formValues.value.nivel1Id, formValues.value.nivel2Id, formValues.value.lastNivelId);
    }

    yield put(borrarTemaSuccess({
      updateTable: true,
      ocultarModal: true
    }))

  } catch (error) {
    console.error('saga.borrarTema', error);
    yield put(borrarTemaError(error))
  }
}


function* crearFormObj(action) {
  const res = [];
  console.log(action)
  action.forEach(element => {
    var form = CAMPO_TYPE_TEXT;
    if (element.type === "number") {
      form = CAMPO_TYPE_NUMBER;
    }
    if (element.type === "boolean") {
      form = CAMPO_TYPE_BOOLEAN;
    }
    // if(element.type === "boolean"){
    //   form=CAMPO_TYPE_BOOLEAN;
    // }
    res.push(form);
  });

  //return [{type: element.type, nombre: element.nombre, value: element.value.toString(),idDoc: element.idDoc,form:form}];
}

function* crearForm(action) {
  const res = [];
  action.forEach(element => {
    var form = CAMPO_TYPE_TEXT;
    if (element.type === "number") {
      form = CAMPO_TYPE_NUMBER;
    }
    if (element.type === "boolean") {
      form = CAMPO_TYPE_BOOLEAN;
    }

    res.push({
      type: element.type,
      nombre: element.nombre,
      value: element.value.toString(),
      idDoc: element.idDoc,
      form: form
    })
  });
  return res;
}

export function* traerVariablesConfiguracion(action) {
  try {

    const company = getFromSession("company_id");
    const reference = yield firebaseDatabase.collection(`company/${company}/configuration`).
      where("administrable", "==", true).get();
    const respuesta = [];
    if (reference) {
      yield reference.docs.map((doc, i) => {

        respuesta.push({
          data: doc.data(),
          //doc: doc.f_.path.segments[8]
        })
      });

    }
    const form = [];
    const noForm = [];
    const tempActivo = [];
    if (respuesta.length > 0) {
      respuesta.forEach(element => {
        if (element.data.activador && tipoVar(element.data.value) !== "object" && element.data.value) {
          tempActivo.push(element.data.activador);
        }
      });
      respuesta.forEach(element => {
        const type = tipoVar(element.data.value);
        if (type === "object") {
          if (tempActivo.length > 0) {
            tempActivo.forEach(item => {
              if (item === element.data.activador) {
                form.push({
                  type: type,
                  nombre: element.data.name,
                  value: element.data.value,
                  idDoc: element.doc
                });
              }
            });
          }
        } else {
          noForm.push({
            type: type,
            nombre: element.data.name,
            value: element.data.value,
            idDoc: element.doc
          })
        }
      });

    }
    //console.log("RESPUESTA ADMIN 4", respuesta)
    //const objetos=  yield crearFormObj(form);
    const final = yield crearForm(noForm);

    yield put(traerVariablesConfiguracionSuccess({
      final
    }));
  } catch (error) {

  }
}


export function* editarFaq(formValues) {
  try {
    let idFaq = formValues.value.idFaq;
    let values = formValues.value.values;
    let toastDetail;

    let resp = yield editFaq(idFaq, values);

    if (resp) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha editado la FAQ'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }
    yield put(editarFaqSuccess({
      updateTable: true,
      ocultarModal: true,
      toastDetail: toastDetail,
      showToast: true
    }));
  } catch (error) {
    console.error('saga.editarFaq', error);
    yield put(editarFaqError(error));
  }
}

export function* borrarFaq(idFaq) {
  try {
    let resp = yield deleteFaq(idFaq.value);
    let toastDetail;
    if (resp) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha eliminado la FAQ'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }
    yield put(borrarFaqSuccess({
      updateTable: true,
      ocultarModal: true,
      toastDetail: toastDetail,
      showToast: true
    }))
  } catch (error) {
    console.error('saga.borrarFaq', error);
    yield put(borrarFaqError(error))
  }
}

export function* agregarFaq(formData) {
  try {
    let values = formData.value.values
    let resp = yield addFaq(values);
    let toastDetail;
    /*  console.log('resp', resp);
  */
    if (resp) {
      toastDetail = {
        'summary': 'Realizado!',
        'severity': 'success',
        'detail': 'Se ha agregado una nueva FAQ'
      }
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un error'
      }

    }


    yield put(agregarFaqSuccess({
      updateTable: true,
      ocultarModal: true,
      toastDetail: toastDetail,
      showToast: true
    }))
  } catch (error) {
    console.error('saga.agregarFaq', error);
    yield put(agregarFaqError(error))
  }
}

export function* editarUsuario(formData) {
  try {
    console.log('formDataEditarUsuario', formData)
    let toastDetail;
    const uidUsuario = formData.value.uid;
    const idUsuario = formData.value.id;
    const password = formData.value.password ? formData.value.password : false;
    let form = formData.value;
    let respPassword = false;

    if (password) {
      console.log('entro a cambiar contraseña')
      let respEditar = yield editUser(form, idUsuario);
      if (respEditar) {
        respPassword = yield cambiarUsuarioContraseña(uidUsuario, password);
      }

      if (respEditar && respPassword) {
        toastDetail = {
          'summary': 'Realizado!',
          'severity': 'success',
          'detail': 'Se ha actualizado el usuario'
        }
      } else if (respEditar && !respPassword) {
        toastDetail = {
          'summary': 'Atención',
          'severity': 'warning',
          'detail': 'Se ha actualizado la información general, pero no la contraseña'
        }
      } else {
        toastDetail = {
          'summary': 'No realizado!',
          'severity': 'error',
          'detail': 'Ha ocurrido una falla y no se ha actualizado el usuario'
        }

      }

      yield put(editarUsuarioSuccess({
        toastDetail: toastDetail,
        showToast: true,
        updateTable: true,
        ocultarModal: false
      }))

    } else if (!password) {
      console.log('entro a no existe contraseña')
      let resp = yield editUser(form, idUsuario);

      if (resp) {
        toastDetail = {
          'summary': 'Realizado!',
          'severity': 'success',
          'detail': 'Se ha actualizado el usuario'
        }
      } else {
        toastDetail = {
          'summary': 'No realizado!',
          'severity': 'error',
          'detail': 'Ha ocurrido una falla y no se ha actualizado el usuario'
        }
      }

      yield put(editarUsuarioSuccess({
        toastDetail: toastDetail,
        showToast: true,
        updateTable: true,
        ocultarModal: false
      }))

    }
  } catch (error) {
    console.error('saga.editarUsuario', error);
    yield put(editarUsuarioError);

  }
}

export function* eliminarUsuario(action) {
  try {

    let uidUser = action.value.uid;
    let idDoc = action.value.idDoc;
    let toastDetail;

    let resp = yield deleteUserInColletion(idDoc, uidUser);

    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Usuario eliminado'
      }
      yield (put(eliminarUsuarioSuccess({
        showToast: true,
        toastDetail: toastDetail,
        updateTable: true,
        modalBorrar: false
      })))
    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }
      yield (put(eliminarUsuarioSuccess({
        showToast: true,
        toastDetail: toastDetail,
        updateTable: false,
        ocultarModal: false
      })))
    }
  } catch (error) {
    console.error('saga.eliminarUsuario', error);
    yield put(eliminarUsuarioError(error))

  }
}

function eliminarCerosFecha(fecha) {
  let listaFecha = fecha.split("-")
  listaFecha.forEach((ele, index) => {
    listaFecha[index] = ele.replace(/^(0+)/g, "");
  });
  listaFecha = listaFecha.join("-")
  return listaFecha;
}

export function* guardarFormularioClienteWeb(values) {
  try {
    const company = getFromSession("company_id");
    console.log('Values form saga', values);
    let valuesForm = values.value.preguntas;
    let clientForm = {};
    let toastDetail;

    valuesForm.forEach((currentValue, index) => {
      clientForm['pregunta' + (index + 1)] = currentValue;
    });

    let resp = yield guardarFormClienteWeb(clientForm, company, 'CLIENT_FORM');
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Formulario web modificado!'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }

    }

    yield put(guardarFormularioClienteWebSuccess({
      toastDetail: toastDetail,
      showToast: true,
      updateForm: true,
    }));


  } catch (error) {
    console.error('saga.guardarFormularioClienteWeb', error);
  }
}

export function* guardarDocumentFormWeb(values) {
  try {
    const company = getFromSession("company_id");
    console.log('Values form saga doc', values);
    let valuesForm = values.value.preguntas;
    let clientForm = [];
    let toastDetail;

    valuesForm.forEach((currentValue, index) => {
      clientForm[index] = currentValue;
    });

    clientForm.map((element, index) => {
      if (!element.campos) {
        clientForm[index].campos = [{ "value": element.campoDocumento }]
        // delete clientForm[index].campoDocumento;
      }
    })

    console.log('clientForm saga doc', clientForm);

    let resp = yield guardarFormClienteWeb(clientForm, company, 'DOCUMENT_FORM');
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Formulario guardado!'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }

    }

    yield put(guardarDocumentFormWebSuccess({
      toastDetail: toastDetail,
      showToast: true,
      updateForm: true,
    }));


  } catch (error) {
    console.error('saga.guardarFormularioClienteWeb', error);
  }
}

export function* guardarCruceForm(values) {
  try {
    const company = getFromSession("company_id");
    let valuesForm = values.value.preguntas;
    let clientForm = [];
    let toastDetail;
    console.log('Values form saga cruce', values.value);

    valuesForm.forEach((currentValue, index) => {
      clientForm[index] = currentValue;
    });

    // console.log('clientForm saga cruce', clientForm);


    let resp = yield guardarFormClienteWeb(clientForm, company, 'CRUCE_CONF');
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Formulario guardado!'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }

    }

    yield put(guardarCruceFormSuccess({
      toastDetail: toastDetail,
      showToast: true,
      updateForm: true,
    }));


  } catch (error) {
    console.error('saga.guardarFormularioClienteWeb', error);
  }
}


export function* anadirDiasHorario(value) {
  try {
    console.log('valueeess**', value);
    const company = getFromSession("company_id");
    let toastDetail;
    let nuevoHorario = {};
    let valoresDiasEspeciales = value.value.diasEspeciales ? value.value.diasEspeciales : [];
    let valoresFechasEspeciales = value.value.fechasEspeciales ? value.value.fechasEspeciales : [];

    nuevoHorario['REG_HOURS'] = {
      ini: value.value.reg_hours_ini,
      fin: value.value.reg_hours_fin
    };
    nuevoHorario['WEEKEND_HOURS'] = {
      ini: value.value.weekend_hours_ini,
      fin: value.value.weekend_hours_fin
    };

    valoresDiasEspeciales.forEach(dia => {
      nuevoHorario[dia.nombreDia] = {
        ini: dia.ini,
        fin: dia.fin
      }
    });
    valoresFechasEspeciales.forEach(valor => {
      let fecha = eliminarCerosFecha(valor.fecha);
      nuevoHorario[fecha] = {
        ini: valor.ini,
        fin: valor.fin
      }
    });
    console.log('nuevoHorario', nuevoHorario);

    let resp = yield guardarNuevoHorario(nuevoHorario, company)
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Horario guardado'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'No se ha podido editar el horario'
      }
    }
    yield put(anadirDiasHorarioSuccess({
      resp: resp,
      toastDetail: toastDetail,
      showToast: true
    }))
  } catch (err) {
    console.error('saga.anadirDiasHorario', err);
    yield put(anadirDiasHorarioError(err))
  }
}







export function* crearUsuario(formData) {
  try {
    yield put(manageSpiner(true));
    console.log('formData crearUsuario', formData);
    let toastDetail;
    let form = formData.value;

    let existeCorreo = yield consultarCorreoExistente(form.correo);
    console.log('existeCorreo', existeCorreo);

    if (existeCorreo) {
      toastDetail = {
        'summary': 'El correo ya existe',
        'severity': 'error',
        'detail': 'Por favor escriba un correo diferente'
      }
      yield put(crearUsuarioSuccess({
        showToast: true,
        toastDetail: toastDetail,
        message: 'El correo ya está registrado',
        updateTable: false,
        ocultarModal: false
      }))
    } else {

      let resp = yield crearDatosGeneralesUsuario(form);
      if (resp) {
        toastDetail = {
          'summary': 'Realizado',
          'severity': 'success',
          'detail': 'usuario creado'
        }
        yield put(crearUsuarioSuccess({
          showToast: true,
          message: false,
          toastDetail: toastDetail,
          updateTable: true,
          ocultarModal: true
        }))
      } else {
        toastDetail = {
          'summary': 'No realizado!',
          'severity': 'error',
          'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
        }
        yield put(crearUsuarioSuccess({
          showToast: true,
          message: false,
          toastDetail: toastDetail,
          updateTable: false,
          ocultarModal: false
        }))
      }

    }

    yield put(manageSpiner(false));

  } catch (error) {
    console.error('saga.crearUsuario', error);
    yield put(crearUsuarioError(error))

  }
}



export function* traerHabilidades() {
  try {
    let habilidades = yield gethabilidades();
    let opcionesHabilidades = []

    habilidades.forEach(habilidad => {
      let id = habilidad.ref.id
      const opcion = {
        label: habilidad.data().nombre,
        value: id,
      }
      opcionesHabilidades.push(opcion)
    });

    yield put(traerHabilidadesSuccess(opcionesHabilidades))

  } catch (error) {
    console.error('saga.traerHabilidades', error);
    yield put(traerHabilidadesError(error))

  }
}

export function* abrirModalBorrar(uid) {
  console.log('saga abrir', uid)
  yield put(abrirModalBorrarSuccess(uid.value));
}



export function* consultarHorario() {
  try {
    const company = getFromSession("company_id");
    let horario = yield consultaProperty(company, 'HORARIO_HABIL');


    let diasEspeciales = [];
    let fechasEspeciales = [];
    const weekday = ["DOMINGO", "LUNES", "MARTES", "MIERCOLES", "JUEVES", "VIERNES", "SABADO"]
    const regFecha = /[0-9-]/
    if (horario) {
      for (let clave in horario) {
        if (weekday.includes(clave)) {
          let newJson = horario[clave];
          newJson["nombreDia"] = clave
          diasEspeciales.push(newJson)
        } else if (regFecha.test(clave)) {
          let newJson = horario[clave];
          let formatDate = new Date(clave);
          let momentFormat = moment(formatDate).format('YYYY-MM-DD')
          newJson["fecha"] = momentFormat
          fechasEspeciales.push(newJson)
        }
      }

    }

    yield put(consultarHorarioSuccess({
      "horario": {
        "reg_hours": horario.REG_HOURS,
        "weekend_hours": horario.WEEKEND_HOURS,
        "diasEspeciales": diasEspeciales,
        "fechasEspeciales": fechasEspeciales,
      }
    }))

  } catch (error) {
    console.error('saga.consultarHorario', error);
    yield put(consultarHorarioError(error));
  }
}

function sortObjectByKeys(o) {
  return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
}

export function* traerConfFormClienteWeb() {
  try {
    const company = getFromSession("company_id");
    let confClienteForm = yield consultaProperty(company, 'CLIENT_FORM');
    let clientFormEnable = yield consultaProperty(company, 'CLIENT_FORM_ENABLED');
    let listaPreguntas = [];
    if (clientFormEnable) {
      let dataOrdenada = sortObjectByKeys(confClienteForm)
      for (let clave in dataOrdenada) {
        let pregunta = dataOrdenada[clave]
        listaPreguntas.push(pregunta)
      }
    }

    yield put(traerConfFormClienteWebSuccess({ listaPreguntas, clientFormEnable }))
  } catch (error) {
    console.error('saga.traerConfFormClientWeb', error);
    yield put(traerConfFormClienteWebError(error));
    throw error

  }
}

export function* traerConfFormDocument() {
  try {
    const company = getFromSession("company_id");
    let confDocumentForm = yield consultaProperty(company, 'DOCUMENT_FORM');
    let documentFormEnable = yield consultaProperty(company, 'DOCUMENT_FORM_ENABLED');
    let listaPreguntas = [];
    let prueba = []
    // console.log("documentFormEnable", documentFormEnable)
    if (documentFormEnable) {
      if (confDocumentForm) {
        let dataOrdenada = sortObjectByKeys(confDocumentForm)
        for (let clave in dataOrdenada) {
          let pregunta = dataOrdenada[clave]
          listaPreguntas.push(pregunta)
        }
      }

      //  console.log("traerConfFormDocument", listaPreguntas)
    }

    // prueba = listaPreguntas
    // listaPreguntas.map((element, index) => {
    //   if (element.campos.length === 1) {
    //     console.log("IFFFFF")

    //     //listaPreguntas[index].campoDocumento = listaPreguntas[index].campos.value;
    //     // listaPreguntas.pop(listaPreguntas[index].campoDocumento);
    //   }
    // })

    // console.log("traerConfFormDocument pruebaaa", prueba)
    console.log('saga.traerConfFormDocument', listaPreguntas);
    yield put(traerConfFormDocumentSuccess({ listaPreguntas, documentFormEnable }))
  } catch (error) {
    console.error('saga.traerConfFormDocument', error);
    yield put(traerConfFormDocumentError(error));
    throw error

  }
}

export function* traerConfCruceDocument() {
  try {
    const company = getFromSession("company_id");
    let confCruceForm = yield consultaProperty(company, 'CRUCE_CONF');
    let listaDataDocument = yield consultaProperty(company, 'DOCUMENT_FORM');
    let confCruceFormEnable = yield consultaProperty(company, 'DOCUMENT_FORM_ENABLED');

    console.log("confCruceForm", confCruceForm)

    let listaPreguntas = confCruceForm;

    //console.log("confCruceFormEnable", confCruceFormEnable)
    // if (confCruceFormEnable) {
    //   if (confCruceForm) {
    //     let dataOrdenada = sortObjectByKeys(confCruceForm)
    //     for (let clave in dataOrdenada) {
    //       let pregunta = dataOrdenada[clave].tipoDocumento
    //       listaPreguntas.push(pregunta)
    //     }
    //   }
    // }

    // listaDataDocument.map((element, index) =>{
    //   if(!element.campos){
    //     console.log('saga.index', index);
    //     listaDataDocument[index] = ({
    //       campos: element.tipoDocumento
    //     })
    //   }
    // })

    console.log('saga.traerConfCRUCEDocument', listaDataDocument);
    yield put(traerConfCruceSuccess({ listaPreguntas, confCruceFormEnable, listaDataDocument }))
  } catch (error) {
    console.error('saga.traerConfCRUCEDocument', error);
    yield put(traerConfCruceError(error));
    throw error

  }
}



export function* traerConfEncuestaClienteWeb() {
  try {
    const company = getFromSession("company_id");
    let confEncuestaForm = yield consultaProperty(company, 'ENCUESTA');
    let encuestaActiva = yield consultaProperty(company, 'SURVEY_ENABLED');

    let listaPreguntasEncuesta = []

    if (encuestaActiva) {
      let dataOrdenada = sortObjectByKeys(confEncuestaForm)
      for (let clave in dataOrdenada) {
        let pregunta = dataOrdenada[clave]
        listaPreguntasEncuesta.push(pregunta)
      }
    }


    yield put(traerConfEncuestaClienteWebSuccess({ listaPreguntasEncuesta, encuestaActiva }))
  } catch (error) {
    yield put(traerConfEncuestaClienteWebError(error))
    throw error

  }
}
export function* guardarFormEncuestaClienteWeb(values) {
  try {

    console.log('action***', values);
    const company = getFromSession("company_id");
    let valuesForm = values.value.preguntasEncuesta;
    let encuestaForm = {};
    let toastDetail;

    valuesForm.forEach((currentValue, index) => {
      encuestaForm['pregunta' + (index + 1)] = currentValue;
    });

    let resp = yield guardarFormClienteWeb(encuestaForm, company, 'ENCUESTA');
    if (resp) {
      toastDetail = {
        'summary': 'Realizado',
        'severity': 'success',
        'detail': 'Formulario web modificado!'
      }

    } else {
      toastDetail = {
        'summary': 'No realizado!',
        'severity': 'error',
        'detail': 'Ha ocurrido un problema con el servidor intente mas tarde'
      }

    }

    yield put(guardarFormEncuestaClienteWebSuccess({
      toastDetail: toastDetail,
      showToast: true,
      updateForm: true,
    }));

  } catch (error) {
    yield put(guardarFormEncuestaClienteWebError(error))
    throw error

  }

}

export function* consultarDialogFlow() {
  const company = getFromSession("company_id");
  let dialogFlowProject = yield consultaProperty(company, 'DIALOGFLOW_PROJECT');
  //console.log('dialogFlowProject', dialogFlowProject);
  storeInSession('dialogFlowProject', dialogFlowProject);
}

export function* watchAgent() {
  yield takeLatest(GET_FAQS, listarFaqs);
  yield takeLatest(EDITAR_FAQ, editarFaq);
  yield takeLatest(BORRAR_FAQ, borrarFaq);
  yield takeLatest(AGREGAR_FAQ, agregarFaq);
  yield takeLatest(GET_TEMAS, listarTemas);
  yield takeLatest(EDITAR_TEMA, editarTema);
  yield takeLatest(BORRAR_TEMA, borrarTema);
  yield takeLatest(AGREGAR_TEMA, agregarTema);
  yield takeLatest(LISTAR_USUARIOS, listarUsuarios);
  yield takeLatest(EDITAR_USUARIO, editarUsuario);
  yield takeLatest(GET_HABILIDADES, traerHabilidades);
  yield takeLatest(CREAR_USUARIO, crearUsuario);
  yield takeLatest(SELECT_ROW, seleccionarFila);
  yield takeLatest(ELIMINAR_USUARIO, eliminarUsuario);
  yield takeLatest(ABRIR_MODAL_BORRAR, abrirModalBorrar);
  yield takeLatest(VARIABLES_CONFIGURACION, traerVariablesConfiguracion);
  yield takeLatest(SELECCIONAR_CONF, seleccionarConf);
  yield takeLatest(EDITAR_CONF, editarConf);
  yield takeLatest(CONSULTAR_HORARIO, consultarHorario);
  yield takeLatest(HORARIO_HABIL_DIAS, anadirDiasHorario);
  yield takeLatest(SELECT_ROW_HABILIDAD, seleccionarHabilidad);
  yield takeLatest(EDITAR_HABILIDAD, editarHabilidad);
  yield takeLatest(CREAR_HABILIDAD, crearHabilidad);
  yield takeLatest(ELIMINAR_HABILIDAD, eliminarHabilidad);
  yield takeLatest(CLIENT_FORM_WEB, traerConfFormClienteWeb);

  yield takeLatest(GUARDAR_CLIENT_FORM_WEB, guardarFormularioClienteWeb);
  yield takeLatest(GUARDAR_DOCUMENT_FORM_WEB, guardarDocumentFormWeb);
  yield takeLatest(GUARDAR_CRUCE_FORM, guardarCruceForm);


  yield takeLatest(ENCUESTA_FORM_WEB, traerConfEncuestaClienteWeb);
  yield takeLatest(GUARDAR_ENCUESTA, guardarFormEncuestaClienteWeb);
  yield takeLatest(DOCUMENT_FORM, traerConfFormDocument);
  yield takeLatest(CRUCE_FORM, traerConfCruceDocument);



  yield takeLatest(CONSULTAR_DIALOG_FLOW_PROPERTY, consultarDialogFlow);

}