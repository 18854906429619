import moment from "moment";
import { getFromSession } from '../../controller/session'
import {
  GUARDAR_ENVIO,
  SEND_ENVIO, LIST_ENVIO, LOAD_CSV, LIST_USERS, VER_ENVIO, BORRAR_ENVIO, TOTAL_ENVIOS, TOTAL_MENSAJES_LEIDOS, CAMPOS_PLANTILLA, ARCHIVOS_ADJUNTOS, PAGINAR_DESTINATARIOS, PROM_TIEMPO_LECTURA, FILTRAR_DESTINATARIOS, TOTAL_DESTINATARIOS, TOTAL_LEIDOS, ENVIOS_INDIVIDUALES, PAGINAR_ENVIOS_INDIVIDUALES, FILTRAR_ENVIOS_API, DESCARGAR_CSV_ENVIOS, FILTRAR_LISTA_LEADS, OPEN_CONVERSATION_ENVIO_MASIVO

} from './constants';
import { eventChannel } from "redux-saga";
import { put, takeLatest, take } from "redux-saga/effects";
import {
  guardarEnvioSucces,
  guardarEnvioError, listEnvioSucces, listEnvioError,
  loadCsvError, loadCsvSucces, listUsersSucces, listUsersError, listUsers,
  verEnvioSucces, borrarEnvioError, borrarEnvioSuccess, sendEnvioSucces, sendEnvioError, obtenerTotalEnviadosError, obtenerTotalEnviadosSuccess, obtenerMensajesLeidosError, obtenerMensajesLeidosSuccess, traerCamposComboPlantillaError, traerCamposComboPlantillaSuccess, adjuntarArchivosEnviosMasivosError, adjuntarArchivosEnviosMasivosSuccess, contarUsuariosListados, calcularTiempoPromedioLecturaSuccess, filtrarDestinatariosError, traerDestinatariosError, traerDestinatariosSuccess, filtrarDestinatariosSuccess, realizarConteoLeidosSuccess, obtenerEnviosIndividualesSuccess, paginarEnviosApiSuccess, filtrarEnviosApiSuccess, descargarCsvEnvioMasivoSuccess, contarLeadsPorEnvio, selectConversationDetailSuccess
} from './actions';


import { enviar, crearCargueMasivoWhatsapp, eliminarBorradorEnvio, buscarEnDestinatarios, buscarEnEnviosApi } from "../../controller/enviosMasivos";
import { firebaseDatabase, storage } from "../../controller/firebase";

import { obtenerRangoDeFechas } from "../../controller/manejoRangoFechas";
import { getTemplateList } from "../../controller/whatsapp";
import { traerDataGenerarCSV } from "../../controller/csv";
import {
  where,
  getCountFromServer,
  query,
  collection,
} from "firebase/firestore";
import { obtenerMensajes } from "../../controller/conversation";



function* guardarEnvio(action) {
  try {

    const company = getFromSession('company_id');
    let csv = action.value.csv ? action.value.csv : false;
    let total = action.value.totalCsv ? action.value.totalCsv : false;
    let parametrosArchivo = action.value.parametrosArchivo ? action.value.parametrosArchivo : false;

    let { nombre, plantilla } = action.value.selectedReduxForm;

    let toastDetail = {}
    let cerrarModal = false
    let convert = JSON.parse(plantilla);
    let text = convert.data;
    let template_name = convert.elementName;
    let type = convert.templateType;


    if (type === 'TEXT') {
      if (csv && total && nombre && text) {
        console.log('entro a tipo TEXT');
        yield crearCargueMasivoWhatsapp(company, nombre, total, text, template_name, type, csv, parametrosArchivo, guardarEnvioSucces, listUsers);
      } else {
        toastDetail['summary'] = 'Formulario incorrecto'
        toastDetail['severity'] = 'error'
        toastDetail['detail'] = 'Debe llenar todos los campos'
        yield put(guardarEnvioSucces({ toastDetail, cerrarModal }))
      }
    } else {
      if (csv && total && nombre && text && parametrosArchivo) {
        yield crearCargueMasivoWhatsapp(company, nombre, total, text, template_name, type, csv, parametrosArchivo, guardarEnvioSucces, listUsers);
      } else {
        toastDetail['summary'] = 'Campos vacios o no hay archivos seleccionados!'
        toastDetail['severity'] = 'error'
        toastDetail['detail'] = 'Debe seleccionar un archivo, luego Confirmar!'
        yield put(guardarEnvioSucces({ toastDetail, cerrarModal }))
      }

    }



  } catch (error) {
    console.error('saga.guardarEnvio', error);
    yield put(guardarEnvioError(error));
  }
}

function* adjuntarArchivos(event) {
  try {
    console.log(event)

    let timestampDate = Date.now();
    let parametros = [];
    let files = event.value.files;
    const company = getFromSession('company_id');

    parametros = yield realizarCargueArchivoAStorage(files, company, timestampDate);

    yield put(adjuntarArchivosEnviosMasivosSuccess(parametros));

  } catch (error) {
    yield put(adjuntarArchivosEnviosMasivosError(error))
    throw error;
  }
}

async function realizarCargueArchivoAStorage(files, company, timestampDate) {
  let parametros = [];
  await Promise.all(files.map(async (file) => {
    let ref = `${company}/enviosMasivos/${timestampDate}_${file.name}`;
    const storageRef = storage.ref(ref);
    let typeArchivo = file.type
    let tipo = typeArchivo.split(["/"])[0];
    let diccionarioTiposArchivo = {
      "application": "file",
      "image": "image",
      "audio": "audio",
      "video": "video",
    }
    let tipoArchivo = diccionarioTiposArchivo[tipo];
    tipoArchivo = !tipoArchivo ? "file" : tipoArchivo;
    const task = await storageRef.put(file).then(result => {
      parametros.push({
        tipo: tipoArchivo,
        nombre: file.name,
        url: 'https://storage.googleapis.com/' + result.ref._delegate._location.bucket + '/' + result.ref._delegate._location.path_
      })
    });

  }));
  return parametros;
}


function* loadCsv(data) {
  const { value } = data;
  try {
    yield put(loadCsvSucces(value))

  } catch (e) {
    console.error(e);
    yield put(loadCsvError(e))
  }

}



function* enviarMensajes(action) {
  try {
    console.log('actionEnviarMensajes', action);

    let idEnvio = action.value.id;
    let toastDetail = {
      'summary': 'Envío de mensajes'
    };
    const company = getFromSession('company_id');
    const resp = yield enviar(company, idEnvio);
    if (resp) {
      toastDetail['severity'] = 'success'
      toastDetail['detail'] = 'Se ha realizado el envío'
    } else {
      toastDetail['severity'] = 'error'
      toastDetail['detail'] = 'No se ha realizado el envío'
    }

    console.log('resp ENVIAR mensajes', resp);
    yield put(sendEnvioSucces({ toastDetail }));
  } catch (error) {
    console.error('saga.obtenerTmpPromedioEspConvsCerradas', error);
    yield put(sendEnvioError(error));
  }
}

function* obtenerMensajesLeidos(action) {
  try {

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession('company_id');
    let size = 0;
    let porcentajeLeidos = 0;
    let reference = firebaseDatabase.collectionGroup('destinatarios').where('company', '==', company).
      where('fecha_envio', '>', timeIni).where('fecha_envio', "<", timeFin);
    let numeroLeidos = 0;
    let numeroNoLeidos = 0;
    let numFailed = 0;
    let numeroLeads = 0;
    let porcentajeLeads = 0;

    yield reference.get().then(docs => {
      size = docs.size;
      docs.forEach(doc => {
        if (doc.data().status === "read") {
          numeroLeidos += 1;
        } else if (doc.data().status === "submitted") {
          numeroNoLeidos += 1;
        } else if (doc.data().status === "failed") {
          numFailed += 1;
        }
        if (doc.data().leads) {
          numeroLeads += 1;
        }
      });
    });
    if (numeroLeidos > 0) {
      porcentajeLeidos = Math.ceil(numeroLeidos / size * 100);
    } else {
      numeroLeidos = 0;
    }
    if (numeroLeads > 0) {
      porcentajeLeads = Math.ceil((numeroLeads / size) * 100);
    }

    yield put(obtenerMensajesLeidosSuccess({
      'numeroLeidos': numeroLeidos,
      'numeroNoLeidos': numeroNoLeidos, 'porcentajeLeidos': porcentajeLeidos,
      'numFailed': numFailed,
      numeroLeads,
      porcentajeLeads,
    }))

  } catch (error) {
    console.error('saga.obtenerMensajesLeidos', error);
    yield put(obtenerMensajesLeidosError(error));
  }
}

function* obtenerTotalLeads(action) {
  try {

    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession('company_id');
    let size = 0;
    let porcentajeLeads = 0;
    let reference = firebaseDatabase.collectionGroup('destinatarios').where('company', '==', company).
      where('fecha_envio', '>', timeIni).where('fecha_envio', "<", timeFin).where('leads', '==', true);
    let numeroLeidos = 0;
    let numeroNoLeidos = 0;
    let numFailed = 0;
    yield reference.get().then(docs => {
      size = docs.size;
      docs.forEach(doc => {
        if (doc.data().status === "read") {
          numeroLeidos += 1;
        } else if (doc.data().status === "submitted") {
          numeroNoLeidos += 1;
        } else if (doc.data().status === "failed") {
          numFailed += 1;
        }
      });
    });
    if (numeroLeidos > 0) {
      porcentajeLeads = Math.ceil(numeroLeidos / size * 100);
    } else {
      numeroLeidos = 0;
    }


    yield put(obtenerMensajesLeidosSuccess({
      'numeroLeidos': numeroLeidos,
      'numeroNoLeidos': numeroNoLeidos, 'porcentajeLeads': porcentajeLeads,
      'numFailed': numFailed
    }))

  } catch (error) {
    console.error('saga.obtenerTotalLeads', error);
    yield put(obtenerMensajesLeidosError(error));
  }
}

function* obtenerTotalEnviados(action) {
  try {
    let numeroEnviados = 0;
    let rango = obtenerRangoDeFechas(action.value);
    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession('company_id');
    let reference = firebaseDatabase.collection(`company/${company}/enviosMasivos`)
      .where('estado', "in", [5]).where('fecha_ini_envio', '>', timeIni).
      where('fecha_ini_envio', "<", timeFin);

    let numFailed = 0;
    yield reference.get().then(docs => {
      docs.forEach(doc => {
        let status = doc.data().status ? doc.data().status : false;
        numeroEnviados += doc.data().registros;
      });
    });

    yield put(obtenerTotalEnviadosSuccess({ 'numeroEnviados': numeroEnviados }))

  } catch (error) {
    console.error('saga.obtenerTotalEnviados', error);
    yield put(obtenerTotalEnviadosError(error));
  }
}

function* listEnvios(action) {
  try {
    const company = getFromSession('company_id');
    let reference = firebaseDatabase.collection(`company/${company}/enviosMasivos`)
      .orderBy('fecha_cargue', 'desc');
    let fechaIni;
    let fechaCargue;
    let envios = [];

    yield reference.get().then(docs => {
      docs.forEach(doc => {

        fechaCargue = doc.data().fecha_cargue.toDate();;
        fechaCargue = moment(fechaCargue).format("lll");
        if (doc.data().fecha_ini_envio) {
          fechaIni = doc.data().fecha_ini_envio.toDate();
          fechaIni = moment(fechaIni).format("lll");
        } else {
          fechaIni = 'Sin fecha de envío'
        }
        envios.push({ data: doc.data(), id: doc.id, fechaIni, fechaCargue });
      });
    });

    let response = {
      envios
    }
    yield put(listEnvioSucces(response));

  } catch (error) {
    console.error('sagaEnvios.listEnvios', error);
    yield put(listEnvioError(error));
  }
}

function* traerCamposComboPlantilla() {
  try {
    let resp = yield getTemplateList();

    let opcionesCombo = [];
    let templatesList = [];
    if (resp && resp.data.templates && resp.data.templates.length > 0) {
      let templates = resp.data.templates;
      templates.forEach(template => {
        if (template.status === "APPROVED") {

          templatesList.push(template);
          let label = template.elementName + '-' + template.templateType;
          let value = JSON.stringify(template)

          opcionesCombo.push({ 'label': label, 'value': value });
        }
      });
      /* console.log('templates', templatesList);
      console.log('RESP opciones', opcionesCombo); */
      yield put(traerCamposComboPlantillaSuccess(opcionesCombo))
    }

  } catch (err) {
    yield put(traerCamposComboPlantillaError(err));
    throw err;

  }
}
function* traerDestinatarios(action) {
  try {

    const company = getFromSession('company_id');
    const idEnvio = action.value;


    let reference = firebaseDatabase.collection(`company/${company}/enviosMasivos/${idEnvio}/destinatarios`);
    const channel = eventChannel(emit => reference.onSnapshot(emit));
    let usuarios = false;

    while (true) {
      usuarios = [];
      const realData = yield take(channel);
      realData.docs.forEach(doc => {
        usuarios.push(doc.data());
      });
      let response = {
        usuarios
      }

      yield put(traerDestinatariosSuccess(response));
    }

  } catch (error) {
    console.error("saga.traerDestinatarios", error);
    yield put(traerDestinatariosError(error))

  }
}

function* calcularTiempoPromedioLectura(action) {
  try {
    let rango = obtenerRangoDeFechas(action.value);

    const timeIni = new Date(rango.fechaIni);
    const timeFin = new Date(rango.fechaFin);
    const company = getFromSession('company_id');

    let reference = firebaseDatabase.collectionGroup('destinatarios').where('company', '==', company)
      .where('estado', '==', 2).where('fecha_envio', '>', timeIni).where('fecha_envio', "<", timeFin);
    let size = 0;
    let fechaEnvio;
    let fechaLectura;
    let sumaDiferencias = 0;
    let promedioLectura;
    yield reference.get().then(docs => {
      docs.forEach(doc => {
        if (doc.data().date_read) {
          size += 1;
          fechaLectura = doc.data().date_read.toDate();
          fechaLectura = new Date(fechaLectura)
          fechaEnvio = doc.data().fecha_envio.toDate();
          fechaEnvio = new Date(fechaEnvio)
          sumaDiferencias += ((fechaLectura.getTime() - fechaEnvio.getTime()) / 1000);
        }
      });
      promedioLectura = sumaDiferencias / size;
      console.log('Promedio lectura', promedioLectura);
      //promedioLectura = new Date(promedioLectura);
      if (isNaN(promedioLectura)) {

        promedioLectura = "Sin datos";
      }
    });

    yield put(calcularTiempoPromedioLecturaSuccess(promedioLectura))

  } catch (error) {
    console.error('saga.calcularTiempoPromedioLectura', error);
    throw error;
  }
}


function* filtrarDestinatarios(values) {
  try {
    const {
      value: { textInput, /* totalDestinatarios,  */numFilas, idEnvio },
    } = values;
    const company = getFromSession('company_id');
    //console.log('values Filtrar Destinatarios', values);
    let listaDestinatariosFiltrada = yield buscarEnDestinatarios(textInput/* totalDestinatarios */, idEnvio, company);

    let primeraPagina = listaDestinatariosFiltrada.slice(0, numFilas);
    console.log('primera pagina', primeraPagina);

    yield put(filtrarDestinatariosSuccess({
      "usuarios": primeraPagina,
      "textInput": textInput,
      "listaFiltradaCompleta": listaDestinatariosFiltrada
    }))

  } catch (error) {
    console.error('saga.filtrarDestinatarios', error);
    yield put(filtrarDestinatariosError(error))
  }
}

async function obtenerReference(idDoc, company, limit, first) {
  let reference;
  if (first == 0) {
    reference = firebaseDatabase.collection(`company/${company}/enviosMasivos/${idDoc}/destinatarios`)
      .orderBy('index').limit(limit);
  } else if (first > 0) {
    reference = firebaseDatabase.collection(`company/${company}/enviosMasivos/${idDoc}/destinatarios`)
      .orderBy('index').startAt(first).limit(limit);
  }
  return reference;
}

function* paginarDestinatarios(values) {

  const company = getFromSession('company_id');
  let idDoc = values.value.idEnvio;
  let limit = values.value.event.rows ? values.value.event.rows : 10;
  let first = values.value.event.first ? values.value.event.first : 0;
  let page = values.value.event.page ? values.value.event.page : 0;

  let reference = yield obtenerReference(idDoc, company, limit, first, page);
  const channel = eventChannel(emit => reference.onSnapshot(emit));
  let usuarios = false;
  while (true) {
    usuarios = [];
    const realData = yield take(channel);
    realData.docs.forEach(doc => {
      usuarios.push(doc.data());
    });
    let response = {
      usuarios
    }

    yield put(listUsersSucces(response));
  }
}


function* listarUsuarios(action) {
  try {
    const company = getFromSession('company_id');
    const idEnvio = action.payload ? action.payload.value : action.value;

    let reference = firebaseDatabase.collection(`company/${company}/enviosMasivos/${idEnvio}/destinatarios`)
      .orderBy('fecha_registro').limit(10);

    const channel = eventChannel(emit => reference.onSnapshot(emit));
    let usuarios = false;

    while (true) {
      usuarios = [];
      const realData = yield take(channel);
      realData.docs.forEach(doc => {
        usuarios.push(doc.data());
      });
      let response = {
        usuarios
      }

      yield put(listUsersSucces(response));
    }

  } catch (error) {
    console.error('sagaEnvios.listUsers', error);
    yield put(listUsersError(error));
  }
}

function* borrarEnvio(action) {

  try {
    let toastDetail = {};

    yield eliminarBorradorEnvio(action.value);

    toastDetail['summary'] = 'Elemento eliminado'
    toastDetail['severity'] = 'success'
    toastDetail['detail'] = 'Se ha eliminado el borrador de envío'

    yield put(borrarEnvioSuccess({ toastDetail, ocultarModalDialogo: true }))

  } catch (error) {
    console.error('saga.borrarEnvio', error);
    yield put(borrarEnvioError(error));

  }

}

function* verEnvio(action) {
  try {

    const company = getFromSession('company_id');
    const env = yield firebaseDatabase.collection(`company/${company}/enviosMasivos`).doc(action.value);
    let envio = false;
    yield env.get().then(docref => {
      envio = docref;
    });

    /* let idEnvio = envio.data().idDoc; */

    yield put(verEnvioSucces({ envio }));
    yield put(contarLeadsPorEnvio(yield contarLeadsPorEnvioMasivo(company, action.value)))
  } catch (error) {
    console.error('sagaEnvios.listUsers', error);
    yield put(listUsersError(error));
  }
}

function* contarLeadsPorEnvioMasivo(company, idDoc) {
  try {
    const coll = collection(firebaseDatabase, `company/${company}/enviosMasivos/${idDoc}/destinatarios`);
    const query_ = query(coll, where("estado", "==", 6), where("leads", "==", true));

    const snapshot = yield getCountFromServer(query_);
    return snapshot.data().count;
  } catch (e) {
    console.error(e);
    throw e
  }
}

function* realizarConteoLeidos(action) {
  try {

    const company = getFromSession('company_id');
    const idEnvio = action.value;

    let leidos = 0;
    yield firebaseDatabase.collection(`company/${company}/enviosMasivos/${idEnvio}/destinatarios`)
      .get().then((response) => {
        response.forEach(doc => {

          if (doc.data().status == "read") {
            leidos += 1
          }
        });
      })


    yield put(realizarConteoLeidosSuccess(leidos))

  } catch (error) {
    console.error('saga.realizarConteoLeidos', error);
    throw error;
  }

}
function* obtenerEnviosIndividuales(action) {
  try {
    let initialRows = action.value;
    let listaEnviosIndividuales = [];
    let mensaje;
    let url;
    const company = getFromSession('company_id');
    let reference = firebaseDatabase.collection(`company/${company}/enviosAPI`).orderBy("fecha", "desc").limit(initialRows);
    const channel = eventChannel(emit => reference.onSnapshot(emit));
    while (true) {
      listaEnviosIndividuales = [];
      const realData = yield take(channel);
      realData.docs.forEach(doc => {
        let docData = doc.data();
        mensaje = docData.msge.caption ? docData.msge.caption : docData.msge.text;
        url = docData.msge.caption ? docData.msge.url : ""
        docData['mensaje'] = mensaje;
        docData['url'] = url;
        docData['fechaEnvio'] = moment(docData.fecha.toDate()).format("lll");
        listaEnviosIndividuales.push(docData);

      });
      yield put(obtenerEnviosIndividualesSuccess({ "enviosApi": listaEnviosIndividuales }))
    }

  } catch (error) {
    console.error('saga.obtenerEnviosIndividuales');
    throw error;

  }

}
function* paginarEnviosApi(action) {
  try {
    const { first, rows } = action.value.event;
    const { ultimoEnvio, nextPage } = action.value;

    const company = getFromSession("company_id");
    let reference = firebaseDatabase.collection(`company/${company}/enviosAPI`).orderBy("fecha", "desc")

    if (first === 0) {
      reference = false;
    } else if (first > 0) {
      if (nextPage) {
        reference = reference.startAfter(ultimoEnvio.fecha).limit(rows)
      } else {
        reference = reference.endBefore(ultimoEnvio.fecha).limitToLast(rows)
      }
    }

    let listaEnviosIndividuales = [];
    let esPrimeraPagina = false;
    let mensaje;
    let url;
    if (reference) {
      yield reference.get().then((response) => {
        response.forEach(doc => {
          let docData = doc.data();
          mensaje = docData.msge.caption ? docData.msge.caption : docData.msge.text;
          url = docData.msge.caption ? docData.msge.url : ""
          docData['mensaje'] = mensaje;
          docData['url'] = url;
          docData['fechaEnvio'] = moment(docData.fecha.toDate()).format("lll");
          listaEnviosIndividuales.push(docData);
        });
      })
    } else {
      esPrimeraPagina = true;
    }

    yield put(paginarEnviosApiSuccess(
      {
        "enviosApiPaginados": listaEnviosIndividuales,
        "esPrimeraPagina": esPrimeraPagina
      }));

  } catch (error) {
    console.error('saga.paginarEnviosApi', paginarEnviosApi);
    throw error

  }

}


function* filtrarEnviosApi(values) {
  try {

    const {
      value: { textInput, numFilas },
    } = values;
    const company = getFromSession('company_id');
    let mensaje;
    let url;
    //console.log('values Filtrar Destinatarios', values);
    let listaEnviosApiFiltrada = yield buscarEnEnviosApi(textInput, company);
    let lista = []
    listaEnviosApiFiltrada.forEach(doc => {
      let docData = doc.data();
      mensaje = docData.msge.caption ? docData.msge.caption : docData.msge.text;
      url = docData.msge.caption ? docData.msge.url : ""
      docData['mensaje'] = mensaje;
      docData['url'] = url;
      docData['fechaEnvio'] = moment(docData.fecha.toDate()).format("lll");
      lista.push(docData);
    });

    let primeraPagina = lista.slice(0, numFilas);
    console.log('primera pagina', primeraPagina);

    yield put(filtrarEnviosApiSuccess({
      "enviosApi": primeraPagina,
      "textInput": textInput,
      "listaFiltradaCompleta": lista
    }))

  } catch (error) {
    console.error('saga.filtrarEnviosApi', error);
    throw error
  }

}

function* descargarCsvEnvioMasivo(data) {
  try {
    const { value } = data //idEnvio
    let resp = false
    resp = yield traerDataGenerarCSV(false, "envioMasivo", value)
    yield put(descargarCsvEnvioMasivoSuccess(resp))

  } catch (error) {
    console.error("saga.descargarCsvEnvioMasivo", error);
    throw error
  }

}

function* verConversationEnvio(action) {
  try {
    console.log("verConversationEnvio", action);
    const { value: { idconv } } = action //idEnvio
    const company = getFromSession('company_id');
    let convEnvio = false;
    const env = yield firebaseDatabase.collection(`company/${company}/conversations`).doc(idconv).
      get();

    console.log("convEnvio", env.data())
    let conversation = yield obtenerMensajes(idconv, 1500);
    yield put(selectConversationDetailSuccess({ messages: conversation, convData: env.data() }));
  } catch (error) {
    console.error("saga.descargarCsvEnvioMasivo", error);
    throw error
  }

}

export function* watchAgent() {
  yield takeLatest(GUARDAR_ENVIO, guardarEnvio);
  yield takeLatest(SEND_ENVIO, enviarMensajes);
  yield takeLatest(LIST_ENVIO, listEnvios);
  yield takeLatest(LOAD_CSV, loadCsv);
  yield takeLatest(LIST_USERS, listarUsuarios);
  yield takeLatest(VER_ENVIO, verEnvio);
  yield takeLatest(VER_ENVIO, listarUsuarios);
  yield takeLatest(BORRAR_ENVIO, borrarEnvio);
  yield takeLatest(TOTAL_ENVIOS, obtenerTotalEnviados);
  yield takeLatest(TOTAL_MENSAJES_LEIDOS, obtenerMensajesLeidos);
 // yield takeLatest(FILTRAR_LISTA_LEADS, obtenerTotalLeads);
  yield takeLatest(CAMPOS_PLANTILLA, traerCamposComboPlantilla);
  yield takeLatest(ARCHIVOS_ADJUNTOS, adjuntarArchivos);
  yield takeLatest(PAGINAR_DESTINATARIOS, paginarDestinatarios);
  yield takeLatest(PROM_TIEMPO_LECTURA, calcularTiempoPromedioLectura);
  yield takeLatest(FILTRAR_DESTINATARIOS, filtrarDestinatarios);
  yield takeLatest(TOTAL_DESTINATARIOS, traerDestinatarios);
  yield takeLatest(TOTAL_LEIDOS, realizarConteoLeidos);
  yield takeLatest(ENVIOS_INDIVIDUALES, obtenerEnviosIndividuales);
  yield takeLatest(PAGINAR_ENVIOS_INDIVIDUALES, paginarEnviosApi);
  yield takeLatest(FILTRAR_ENVIOS_API, filtrarEnviosApi);
  yield takeLatest(DESCARGAR_CSV_ENVIOS, descargarCsvEnvioMasivo);
  yield takeLatest(OPEN_CONVERSATION_ENVIO_MASIVO, verConversationEnvio)

}