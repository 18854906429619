import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import validate from './validateClientWebForm';
import { Button } from 'primereact/button';
import { connect } from 'react-redux'
const renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="p-inputtext p-component mr-2 p-1" {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>

);


const renderSelectTypeFormField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <select className="selectStyle p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus"  {...input} type={type} placeholder={label} >

                <option value="text">Respuesta texto/Caja de texto</option>
                <option value="select">Respuesta seleccion única/Lista opciones</option>
                <option value="radio">Respuesta seleccion única/Radio Buttons</option>

            </select>
        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderOptions = ({ fields, meta: { error } }) => (
    <div className="p-grid p-ml-5 p-mt-2">
        <div className="p-dir-col">
            {fields.map((option, index) => (
                <div key={index}>
                    <div className="p-grid">
                        <div className="p-col"><h4>{index + 1}</h4></div>
                        <Field
                            name={`${option}.label`}
                            type="text"
                            component={renderField}
                            label="Nombre"
                        />
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderField}
                            label="Campo del documento"
                        />
                        <Button
                            tooltip="Quitar Opción"
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}

        </div>
        {error && <li className="error">{error}</li>}
        <div className='p-grid p-align-end p-ml-3'>
            <Button icon="pi pi-plus" label="Añadir opción" tooltip="Añadir opción" className="mt-2 ml-5 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({})}></Button>

        </div>

    </div>
);

const renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div className="pl-4">
        <div>
            {fields.map((pregunta, index) => (
                <div key={index}>
                    <div className="p-grid p-mt-2">
                        <div className="p-grid p-dir-col mt-3 mr-1">
                            {index !== 0 ? <Button
                                type="button"
                                tooltip="Mover hacia arriba"
                                onClick={() => fields.swap(index, index - 1)}
                                className="swapButtons p-button-raised p-button-rounded p-button-secondary"
                                icon="pi pi-angle-up"
                            /> : false}

                            {index < fields.length - 1 ?
                                <Button
                                    type="button"
                                    tooltip="Mover hacia abajo"
                                    onClick={() => fields.swap(index, index + 1)}
                                    className="swapButtons mt-1 p-button-raised p-button-rounded p-button-secondary"
                                    icon="pi pi-angle-down"
                                /> : false}

                        </div>
                        <div className="p-col-fixed" style={{ width: '30px' }}><h4>{index + 1}</h4></div>

                        <Field
                            name={`${pregunta}.type`}
                            label="Tipo de respuesta"
                            component={renderSelectTypeFormField}
                            type="text"
                        >
                        </Field>


                        <Field
                            name={`${pregunta}.nombre`}
                            type="text"
                            component={renderField}
                            label="Pregunta"
                        />

                        <Field
                            name={`${pregunta}.nombreCorto`}
                            type="text"
                            component={renderField}
                            label="Identificador de la pregunta/nombre corto"
                        />

                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                            icon="pi pi-times"
                        />
                        <Button
                            type="button"
                            tooltip="Insertar campo abajo"
                            onClick={() => fields.insert(index + 1, { 'type': 'text' })}
                            className="ml-1 removeButtonStyle p-button-raised p-button-rounded p-button-success"
                            icon="pi pi-plus"
                        />
                    </div>
                    {fields.get(index).type === "select" || fields.get(index).type === "radio" ?
                        <FieldArray name={`${pregunta}.opcionesRespuesta`} component={renderOptions} />
                        : null
                    }
                </div>


            ))}
        </div>
        <div className='p-grid  p-mt-2 p-justify-center'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir pregunta" label='Añadir pregunta' icon="pi pi-plus" className="mt-3 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({ 'type': 'text' })} />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>


    </div>
)


const renderFieldOCR = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="p-inputtext p-component mr-2 p-1" {...input} type={type} placeholder={label} />

        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>

);


const renderSelectTypeFormFieldOCR = ({ input, label, type, meta: { touched, error } }) => (
    <div>
        <label>{label}</label>
        <div>
            <select className="selectStyle p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-inputwrapper-focus"  {...input} type={type} placeholder={label} >

                <option value="text">Campo Único</option>
                <option value="select">Multiples Campos</option>
                {/* <option value="radio">Respuesta seleccion única/Radio Buttons</option> */}

            </select>
        </div>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
    </div>
);

const renderOptionsOCR = ({ fields, meta: { error } }) => (
    <div className="p-grid p-ml-5 p-mt-2">
        <div className="p-dir-col">
            {fields.map((option, index) => (
                <div key={index}>
                    <div className="p-grid">
                        <div className="p-col"><h4>{index + 1}</h4></div>
                        {/* <Field
                            name={`${option}.label`}
                            type="text"
                            component={renderFieldOCR}
                            label="Nombre"
                        /> */}
                        <Field
                            name={`${option}.value`}
                            type="text"
                            component={renderFieldOCR}
                            label="Campo del documento"
                        />
                        <Button
                            tooltip="Quitar Opción"
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-success p-button-danger"
                            icon="pi pi-times"
                        />
                    </div>
                </div>
            ))}

        </div>
        {error && <li className="error">{error}</li>}
        <div className='p-grid p-align-end p-ml-3'>
            <Button icon="pi pi-plus" label="Añadir opción" tooltip="Añadir opción" className="mt-2 ml-5 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({})}></Button>

        </div>

    </div>
);

const renderQuestionsOCR = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div className="pl-4">
        <div>
            {fields.map((pregunta, index) => (
                <div key={index}>
                    <div className="p-grid p-mt-2">
                        <div className="p-grid p-dir-col mt-3 mr-1">
                            {index !== 0 ? <Button
                                type="button"
                                tooltip="Mover hacia arriba"
                                onClick={() => fields.swap(index, index - 1)}
                                className="swapButtons p-button-raised p-button-rounded p-button-secondary"
                                icon="pi pi-angle-up"
                            /> : false}

                            {index < fields.length - 1 ?
                                <Button
                                    type="button"
                                    tooltip="Mover hacia abajo"
                                    onClick={() => fields.swap(index, index + 1)}
                                    className="swapButtons mt-1 p-button-raised p-button-rounded p-button-secondary"
                                    icon="pi pi-angle-down"
                                /> : false}

                        </div>
                        <div className="p-col-fixed" style={{ width: '30px' }}><h4>{index + 1}</h4></div>

                        {/* <Field
                            name={`${pregunta}.type`}
                            label="Tipo de respuesta"
                            component={renderSelectTypeFormFieldOCR}
                            type="text"
                        >
                        </Field> */}


                        <Field
                            name={`${pregunta}.tipoDocumento`}
                            type="text"
                            component={renderFieldOCR}
                            label="Documento"
                        />

                        {/* <Field
                            name={`${pregunta}.campos`}
                            type="text"
                            component={renderField}
                            label="Campo del documento"
                        /> */}
                        {/* {fields.get(index).type === "text" ?
                            <Field
                                name={`${pregunta}.campoDocumento`}
                                type="text"
                                component={renderField}
                                label="Campo del documento"
                            /> : ''} */}

                        <Button
                            type="button"
                            tooltip="Quitar"
                            onClick={() => fields.remove(index)}
                            className="removeButtonStyle p-button-raised p-button-rounded p-button-danger"
                            icon="pi pi-times"
                        />
                        {/* <Button
                            type="button"
                            tooltip="Insertar campo abajo"
                            onClick={() => fields.insert(index + 1, { 'type': 'text' })}
                            className="ml-1 removeButtonStyle p-button-raised p-button-rounded p-button-success"
                            icon="pi pi-plus"
                        /> */}
                    </div>
                    {fields.get(index).type === "select" || fields.get(index).type === "text" ?
                        <FieldArray name={`${pregunta}.campos`} component={renderOptionsOCR} />
                        : null
                    }
                </div>


            ))}
        </div>
        <div className='p-grid  p-mt-2 p-justify-center'>
            <Button style={{ marginLeft: 30 }} tooltip="Añadir" label='Añadir' icon="pi pi-plus" className="mt-3 mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-success" type="button" onClick={() => fields.push({ 'type': 'text' })} />
            {(touched || submitFailed) && error && <span style={{ color: "red" }}>{error}</span>}
        </div>


    </div>
)








const FieldArraysForm = props => {
    const { handleSubmit, pristine, adminOCR, reset, submitting } = props;

    return (
        <form onSubmit={handleSubmit} >
            <FieldArray name="preguntas" component={adminOCR ? renderQuestionsOCR : renderQuestions} />
            <div className="p-mt-2 p-grid p-justify-center">
                <div>
                    <Button icon="pi pi-save" label="Guardar" tooltip="Guardar" className="mr-2 p-button p-button-raised p-button-rounded p-button-sm p-button-info" type="submit" disabled={submitting || pristine} />
                    <Button icon="pi pi-ban" label="Limpiar" tooltip="limpiar" className="p-button-raised p-button-rounded p-button-success p-button-warning" type="button" disabled={pristine || submitting} onClick={reset} />
                </div>

            </div>
        </form>
    );
};

const mapStateToProps = (state) => {

    return {
        initialValues: { "preguntas": state.localReducer.valuesInitialFormWeb },

    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'fieldArraysClientForm',
    enableReinitialize: true,
    validate,

})(FieldArraysForm));
