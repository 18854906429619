import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getMenuItemsChatBot = (state) => state.menuItemsChatBot;
export const getMenuItems = (state) => state.menuItems;
export const getCardItems = (state) => state.cardItems;
export const getBotsSelector = (state) => state.bot_activo;
export const getDataCompanySelector = (state) => state.dataCompany;

export const usersSelector = createStructuredSelector({
    users: getUsers,
});