import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export class BarChartDemo extends Component {

    constructor(props) {
        super(props);

        this.options = this.getLightTheme();
    }

    getLightTheme() {

        let stackedOptions1 = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                },

                ]
            }
            
        };
        let stackedOptions = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                },
                {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    id: 'y',
                    gridLines: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    },
                    ticks: {
                        min: 0,
                        //max: 100,
                        fontColor: '#495057'
                    }
                }

                ]
            }
            // tooltips: {
            //     mode: 'index',
            //     intersect: false
            // },
            // responsive: true,
            // scales: {
            //     xAxes: [{
            //         stacked: true,
            //         ticks: {
            //             fontColor: '#495057'
            //         },
            //         gridLines: {
            //             color: '#ebedef'
            //         }
            //     }],
            //     yAxes: [{
            //         type: 'linear',
            //         display: true,
            //         position: 'left',
            //         id: 'y2',
            //         stacked: true,
            //         ticks: {
            //             fontColor: '#495057'
            //         },
            //         gridLines: {
            //             color: '#ebedef'
            //         }
            //     },{
            //         type: 'linear',
            //         display: true,
            //         position: 'right',
            //         id: 'y',
            //         gridLines: {
            //             drawOnChartArea: false,
            //             color: '#ebedef'
            //         },
            //         ticks: {
            //             min: 0,
            //             max: 100,
            //             fontColor: '#495057'
            //         }
            //     }
            // ],

            // },
            // legend: {
            //     labels: {
            //         fontColor: '#495057'
            //     }
            // }
        };

        let stackedOptions3 = {
            legend: {
                labels: {
                    fontColor: '#495057'
                }
            },
            tooltips: {
                mode: 'index',
                intersect: false
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    ticks: {
                        fontColor: '#495057'
                    },
                    gridLines: {
                        color: '#ebedef'
                    }
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#495057'
                    }
                },
                
                ]
            }
            
        };



        return {
            stackedOptions,
            stackedOptions1,
            stackedOptions3,

        }
    }

    render() {
        const { stackedOptions,stackedOptions1,stackedOptions3 } = this.options;
        const { dataBar, titleBar,tipoStyle,agente} = this.props;
        
        return (
            <div>


                <div className="card">
                    <h5 className="tituloChart">{titleBar}</h5>
                    <Chart className="barChart" type="bar" data={dataBar} options={tipoStyle? stackedOptions: agente ? stackedOptions3 : stackedOptions1} />
                   
                </div>
            </div>
        )
    }
}