import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { BAR_TRANSFERENCIAS_TITLE, CONVS_TRANSFERENCIAS, HEADER_TRANSFERENCIAS_CHATBOT, MESSAGE_DATA_CERO_TRANFERENCIAS, MODAL_ESPERA_DATA, TABLE_TRANSFERENCIAS_CHATBOT_TITLE, TABLE_TRANSFERENCIAS_TITLE, TOAST_MESSAGE } from "./constants";
import { getDataTableTransferencias, getManageSpinerState, getSelectedForm, getShowSnackBar, getStateFilterButton } from "./selectors";

import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { BarChartDemo } from '../../components/Charts/barrChart';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstTransferenciasComponent extends Component {

    
    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const { obtenerTablaTransferencias, activarSpinner } = this.props
        obtenerTablaTransferencias()
        activarSpinner()

    }

    componentDidUpdate(prevProps) {
        const { obtenerTablaTransferencias, showToast, estadoBotonFiltrarRangos, activarSpinner } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;

        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerTablaTransferencias(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerTablaTransferencias(seleccionRango)
            activarSpinner()
        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }
                obtenerTablaTransferencias(rango)
                activarSpinner()
            } else {
                showToast()
            }
        }
    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }

    render() {
        const {
            dataTableTransferencias,
            showSnackBar,
            spinerState,
        } = this.props;
        return (
            <div>
                {showSnackBar ? this.showSnackBar(
                    showSnackBar, "Sucess"
                )
                    : ""}
                <div>
                    <h4 className="tituloComponente" >ESTADISTICAS DE TRANSFERENCIAS</h4>
                </div>
                {spinerState.transferenciaEntreAgentes ? this.spinnerGraficos() : ""}
                {dataTableTransferencias.data?.length > 0 ?
                    <div className="containerGeneral">
                        <div className="p-mt-3">
                            {dataTableTransferencias.data?.length > 0 && (
                                <DataTableCrudDemo
                                headerTable={dataTableTransferencias.columnsTable?.length > 0 ? dataTableTransferencias.columnsTable : []}
                                datosTable={dataTableTransferencias.data?.length > 0 ? dataTableTransferencias.data : []}
                                titleTable={TABLE_TRANSFERENCIAS_TITLE}
                                exporExcel={false}
                                nameTable={"Tranferencias"}
                                
                                />
                                )}
                        </div>
                        <div>
                            {spinerState.transferenciaEntreAgentes ? this.spinnerGraficos() : ""}
                            {dataTableTransferencias.bar?.datasets?.length > 0 ?
                                <BarChartDemo
                                    dataBar={dataTableTransferencias.bar?.datasets?.length > 0 ? dataTableTransferencias.bar : {}}
                                    titleBar={BAR_TRANSFERENCIAS_TITLE}
                                    agente={true}

                                />
                                : ''
                            }

                        </div>
                        <div className="p-mt-3">
                            {dataTableTransferencias.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={HEADER_TRANSFERENCIAS_CHATBOT}
                                    datosTable={dataTableTransferencias.table2?.length > 0 ? dataTableTransferencias.table2 : []}
                                    titleTable={TABLE_TRANSFERENCIAS_CHATBOT_TITLE}
                                    exporExcel={false}
                                    nameTable={"Tranferencias desde chatbot"}

                                />
                            )}
                        </div>
                    </div>
                    :
                    <div>
                        <h4 className="messageCero">{MESSAGE_DATA_CERO_TRANFERENCIAS}</h4>
                    </div>
                }
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableTransferencias: getDataTableTransferencias(state),
        selectedForm: getSelectedForm(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),
    }
}


const mapDispachToProps = dispatch => {
    return {

        obtenerTablaTransferencias: (value) => dispatch({ type: CONVS_TRANSFERENCIAS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        activarSpinner:(value)=> dispatch({type:MODAL_ESPERA_DATA, value})

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstTransferenciasComponent);