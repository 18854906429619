import {
  ADQUIRIR_PRODUCTO_SUCCES,
  BOT_ACTIVO_SUCCESS,
  CARDS_ELE_SUCCESS,
  COMPANY_DATA_SUCCESS,
  GET_USERS_SUCCESS,
  LOAD_MENU_SUCCES,
  USER_DATA,
  USER_HEADERS,
} from './constants';

export const initialState = {
  users: USER_DATA,
  userHeaders: USER_HEADERS,
  menuItems: false,
  menuItemsChatBot: false,
  planes: false,
  cardItems: false,
  dataCompany: false,
  bot_activo: false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type } = action;

  switch (type) {
    case GET_USERS_SUCCESS:
      newState.users = payload.data;

      return {
        ...newState
      };
    case LOAD_MENU_SUCCES:
      newState.menuItemsChatBot = payload;

      return {
        ...newState
      };

    case CARDS_ELE_SUCCESS:
      newState.cardItems = payload;

      return {
        ...newState
      };

    case BOT_ACTIVO_SUCCESS:
      newState.bot_activo = payload;
      //console.log("newState.bot_activo", newState.bot_activo)

      return {
        ...newState
      };

    case COMPANY_DATA_SUCCESS:
      newState.dataCompany = payload;
      //console.log("newState.bot_activo", newState.dataCompany)

      return {
        ...newState
      };


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
