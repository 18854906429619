import "./style.css";

import 'primeflex/primeflex.css';
import { connect } from "react-redux";
import React, { Component } from "react";
import CustomForm from "../../components/customform/customform";
import {
    getSelectedForm,
    getTemas,
    ocultarModal,
    updateTableTemas,
    opcionesNivel1,
    toastDetail,
    showToast,
    loadingTemas

} from "./selector";
import {
    AGREGAR_TEMA,
    BORRAR_TEMA,
    EDITAR_TEMA,
    GET_TEMAS,
    LIMPIAR_FORM,
    LIMPIAR_FORM_NIVEL2,
    UPDATE_FIELD_ATTRIBUTE

} from "./constants";
import { Card } from 'primereact/card';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import LoadingOverlay from 'react-loading-overlay';



class TemasComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalFilter: null,
            nivel1: false,
            nivel2: false,
            nivel3: false,
            openModalEditar: false,
            openModalBorrar: false,
            openModalAgregarNivel1: false,
            openModalAgregarNivel2: false,
            openModalAgregarNivel3: false,
            opcionesNivel2: false,
            temaItem: false,
        };
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.abrirModalEditar = this.abrirModalEditar.bind(this);
        this.abrirModalBorrar = this.abrirModalBorrar.bind(this);
        this.abrirModalAgregarNivel1 = this.abrirModalAgregarNivel1.bind(this);
        this.abrirModalAgregarNivel2 = this.abrirModalAgregarNivel2.bind(this);
        this.abrirModalAgregarNivel3 = this.abrirModalAgregarNivel3.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.ocultarModal = this.ocultarModal.bind(this);
    }


    componentWillMount() {
        const { listarTemas } = this.props
        listarTemas();

    }

    componentDidUpdate(prevProps) {
        const { updateTableTemas, listarTemas, ocultarModal, limpiarSelectedForm, showToast, toastDetail } = this.props;

        if (showToast) {
            this.toast.show({ severity: toastDetail.severity, summary: toastDetail.summary, detail: toastDetail.detail });
        }
        if (this.props.selectedForm.nivel1 && prevProps.selectedForm.nivel1 !== this.props.selectedForm.nivel1) {
            limpiarSelectedForm();
            this.obtenerOpcionesNivel2(this.props.selectedForm.nivel1);
        }
      /*   console.log('pprevProps', prevProps);
        console.log('updateTable', updateTableTemas); */

        if (updateTableTemas) {
          
            listarTemas();
        }
        if (ocultarModal) {
            this.ocultarModal();
            listarTemas();
        }
    }

    obtenerOpcionesNivel2(opcionNivel1) {
        const { temas } = this.props
        let opcionesNivel2 = []

        temas.forEach(tema => {
            if (tema.idNivel1 === opcionNivel1) {
                if (!tema.nivel2) {
                }
                opcionesNivel2.push({ 'label': tema.nivel2 ? tema.nivel2 : 'No hay niveles disponibles', 'value': tema.idNivel2 ? tema.idNivel2 : 'false' })
            }
        });

        let set = new Set(opcionesNivel2.map(JSON.stringify))
        let listaSinDuplicados = Array.from(set).map(JSON.parse);

        this.setState({
            opcionesNivel2: listaSinDuplicados
        })
    }

    abrirModalEditar(rowData) {
        console.log('rowDataEDIT', rowData)
        this.setState({
            openModalEditar: true,
            temaItem: { ...rowData },
            nivel1: rowData.nivel1,
            nivel2: rowData.nivel2,
            nivel3: rowData.nivel3,
        });
    }

    abrirModalBorrar(rowData) {
        this.setState({
            openModalBorrar: true,
            temaItem: { ...rowData },
        });
    }

    abrirModalAgregarNivel1() {
        this.setState({
            openModalAgregarNivel1: true,
        });
    }

    abrirModalAgregarNivel2() {
        this.setState({
            openModalAgregarNivel2: true,

        });
    }

    abrirModalAgregarNivel3() {
        const { limpiarSelectedForm } = this.props
        limpiarSelectedForm();
        this.setState({
            openModalAgregarNivel3: true,
            opcionesNivel2: false,
        });
    }

    ocultarModal() {
        const { limpiarForm } = this.props;
        this.setState({
            openModalEditar: false,
            openModalBorrar: false,
            openModalAgregarNivel1: false,
            openModalAgregarNivel2: false,
            openModalAgregarNivel3: false,
        });
        limpiarForm();
    }

    renderEditForm(temaItem, itemsForm) {
        const { editarTema } = this.props;
        let action;
        let idNivel1 = temaItem.idNivel1 ? temaItem.idNivel1 : false;
        let idNivel2 = temaItem.idNivel2 ? temaItem.idNivel2 : false;
        let idNivel3 = temaItem.idNivel3 ? temaItem.idNivel3 : false;
        let idsJson = { idNivel1, idNivel2, idNivel3 };

        action = editarTema;
        return (
            <div>{itemsForm(idsJson, action)}</div>
        )
    }

    renderModalAgregarNivel1(itemsForm) {
        return (
            <div>{itemsForm()}</div>
        )
    }

    renderModalAgregarNivel2(itemsForm) {
        return (
            <div>{itemsForm()}</div>
        )
    }

    renderModalAgregarNivel3(itemsForm) {
        return (
            <div>{itemsForm()}</div>
        )
    }

    renderBorrarDialog(faqItem, borrarFaq) {
        let lastNivelId;
        let nombreNivel;
        let nivel2Id;
        let nivel1Id;
        let jsonData;
        let tema;
    
        //console.log('Objeto seleccionado*****',faqItem)
        if (faqItem.idNivel3 && faqItem.nivel3 !== "") {
            lastNivelId = faqItem.idNivel3
            nombreNivel = 'nivel3'
            nivel2Id = faqItem.idNivel2;
            nivel1Id = faqItem.idNivel1;
            jsonData = { lastNivelId, nombreNivel, nivel1Id, nivel2Id }
            tema = faqItem.nivel3;
        } else {
            lastNivelId = faqItem.idNivel2 ? faqItem.idNivel2 : faqItem.idNivel1;
            nombreNivel = faqItem.idNivel2 ? nombreNivel = 'nivel2' : nombreNivel = 'nivel1';
            if (nombreNivel === 'nivel1') {
                jsonData = { lastNivelId, nombreNivel }
                tema = faqItem.nivel1;
                
            } else {
                nivel1Id = faqItem.idNivel1;
                jsonData = { lastNivelId, nombreNivel, nivel1Id }
                tema = faqItem.nivel2;
            }
        }

        return (
            <div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem', 'margin-left': '2rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ 'fontSize': '2rem' }}></i>
                    <div className="p-text-normal" style={{ 'margin-left': '1rem' }}>¿Está seguro que desea borrar el 
                    tema: <b>{tema}</b>, del nivel: <b>{nombreNivel}</b>?</div>
                </div>
                <div className="p-grid" style={{ 'margin-top': '0.5rem' }}>
                    <div style={{ 'margin-left': 'auto', 'margin-right': '2rem' }}>
                        <Button label="Si" onClick={() => borrarFaq(jsonData)} ></Button>
                        <Button label="No" onClick={() => this.ocultarModal()} style={{ 'margin-left': '1rem' }} className="p-button-secondary"></Button>
                    </div>
                </div>
            </div>
        )
    }

    rightToolbarTemplate(splitButton) {
        return (
            <React.Fragment>
                <div>{splitButton()}</div>
            </React.Fragment>
        )
    }
    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" title='Editar' className="p-button-rounded p-button-info p-mr-1" onClick={() => this.abrirModalEditar(rowData)} />
                <Button icon="pi pi-trash" title='Borrar' className="p-button-rounded p-button-help p-mr-1" onClick={() => this.abrirModalBorrar(rowData)} ></Button>
            </React.Fragment>
        );
    }

    renderModal(open, body, onHide, headerTitle, id) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw" }}
            >
                {body}
            </Dialog>
        );
    }

    render() {
        const {
            temas,
            selectedForm,
            updateAttributes,
            borrarTema,
            agregarTema,
            opcionesNivel1,
            loadingTemas,
        } = this.props;


        const items = [
            {
                label: 'Añadir tema nivel 1',
                command: (e) => {
                    this.abrirModalAgregarNivel1()
                }
            },
            {
                label: 'Añadir tema nivel 2',
                command: (e) => {
                    this.abrirModalAgregarNivel2()
                }

            },
            {
                label: 'Añadir tema nivel 3',
                command: (e) => {
                    this.abrirModalAgregarNivel3()
                }

            },
        ]

        let getSaveButton = (handlerSafe) => {
            return [
                {
                    label: "Guardar",
                    className: "btn btn-primary",
                    action: false,
                    style: "primary",
                    onClick: handlerSafe,
                    display: true
                }
            ]
        }

        let FORM_TEMAS = [
            {
                name: "editNivel1",
                placeholder: this.state.nivel1,
                value: this.state.nivel1,
                options: [],
                disable: false,
                typeForm: 'normal-text-area',
                labelClass: true,
                display: true,
                label: 'Nivel 1'
            },
            {
                name: "editNivel2",
                placeholder: this.state.nivel2,
                value: this.state.nivel2,
                options: [],
                disable: false,
                typeForm: 'normal-text-area',
                labelClass: true,
                display: true,
                label: 'Nivel 2'

            },
            {
                name: "editNivel3",
                placeholder: this.state.nivel3,
                value: this.state.nivel3,
                options: [],
                disable: false,
                typeForm: 'normal-text-area',
                labelClass: true,
                display: true,
                label: 'Nivel 3'
            }
        ]

        let FORM_NIVEL1 = [
            {
                name: "nivel1Text",
                placeholder: 'Escriba el nombre del tema para el nivel 1',
                value: false,
                options: [],
                disable: false,
                typeForm: 'text',
                labelClass: true,
                display: true,
                label: 'Nivel 1'
            }
        ]


        let FORM_NIVEL2 = [
            {
                name: "nivel1",
                placeholder: 'Seleccione el nivel 1',
                value: false,
                options: opcionesNivel1,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: true,
                display: true,
                label: 'Nivel 1'
            },
            {
                name: "nivel2Text",
                placeholder: 'Escriba el nombre del nivel 2',
                value: false,
                options: [],
                disable: false,
                typeForm: 'text',
                labelClass: true,
                display: true,
                label: 'Nivel 2'
            },
        ]

        let FORM_NIVEL3 = [
            {
                name: "nivel1",
                placeholder: 'Seleccione el nivel 1',
                value: false,
                options: opcionesNivel1,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: true,
                display: true,
                label: 'Nivel 1'
            },
            {
                name: "nivel2",
                placeholder: 'Seleccione el nivel 2 ',
                value: false,
                options: this.state.opcionesNivel2,
                disable: false,
                typeForm: 'primeFace-dropdown',
                labelClass: true,
                display: true,
                label: 'Nivel 2'
            },
            {
                name: "nivel3",
                placeholder: 'Escriba el nombre del nivel 3',
                value: false,
                options: [],
                disable: false,
                typeForm: 'text',
                labelClass: true,
                display: true,
                label: 'Nivel 3'
            }
        ]

        let getAddButtonNivel1 = (handlerSafe) => {
            return [
                {
                    label: "Añadir",
                    className: "btn btn-primary",
                    action: false,
                    style: "primary",
                    onClick: handlerSafe,
                    display: true
                }
            ]
        }

        let customFormAddNivel1 = () => {
            return [
                <CustomForm
                    formName="formFaq"
                    items={FORM_NIVEL1}
                    values={selectedForm}
                    updateAction={updateAttributes}
                    style="splash-container-search"
                    positionButton='fixed-button'
                    buttons={getAddButtonNivel1(() => agregarTema(selectedForm))}>
                </CustomForm>
            ]
        }

        let customFormAddNivel2 = () => {
            return [
                <CustomForm
                    formName="formFaq"
                    items={FORM_NIVEL2}
                    values={selectedForm}
                    updateAction={updateAttributes}
                    style="splash-container-search"
                    positionButton='fixed-button'
                    buttons={getAddButtonNivel1(() => agregarTema(selectedForm))}>
                </CustomForm>
            ]
        }

        let customFormAddNivel3 = () => {
            return [
                <CustomForm
                    formName="formFaq"
                    items={FORM_NIVEL3}
                    values={selectedForm}
                    updateAction={updateAttributes}
                    style="splash-container-search"
                    positionButton='fixed-button'
                    buttons={getAddButtonNivel1(() => agregarTema(selectedForm))}>
                </CustomForm>
            ]
        }


        let customForm = (idsJson = false, action) => {
            return [
                <CustomForm
                    formName="formFaq"
                    items={FORM_TEMAS}
                    values={selectedForm}
                    updateAction={updateAttributes}
                    style="splash-container-search"
                    positionButton='fixed-button'
                    buttons={getSaveButton(() => action({ values: selectedForm, 'idsJson': idsJson }))}>
                </CustomForm>
            ]
        }

        let splitButton = () => {
            return [
                <SplitButton label="Añadir tema" icon="pi pi-plus" model={items} className="p-button-help" />
            ]

        }

        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                        onInput={(e) => this.setState({ globalFilter: e.target.value })} />
                </span>
            </div>
        );

        return (
            <React.Fragment>
                <LoadingOverlay
                    active={loadingTemas}
                    spinner
                    text='Cargando tabla'
                >
                    <Card className='temas' >
                        <Toast ref={(el) => this.toast = el} />
                        {this.renderModal(
                            this.state.openModalEditar,
                            this.renderEditForm(this.state.temaItem, customForm),
                            this.ocultarModal,
                            "Editar elemento",
                            "editarTema"
                        )}
                        {this.renderModal(
                            this.state.openModalBorrar,
                            this.renderBorrarDialog(this.state.temaItem, borrarTema),
                            this.ocultarModal,
                            "Confirmar",
                            "confirmarBorrado"
                        )}
                        {this.renderModal(
                            this.state.openModalAgregarNivel1,
                            this.renderModalAgregarNivel1(customFormAddNivel1),
                            this.ocultarModal,
                            'Añadir tema nivel 1',
                            "agregarTema"
                        )}
                        {this.renderModal(
                            this.state.openModalAgregarNivel2,
                            this.renderModalAgregarNivel2(customFormAddNivel2),
                            this.ocultarModal,
                            'Añadir tema nivel 2',
                            "agregarTema"
                        )}
                        {this.renderModal(
                            this.state.openModalAgregarNivel3,
                            this.renderModalAgregarNivel3(customFormAddNivel3),
                            this.ocultarModal,
                            'Añadir tema nivel 3',
                            "agregarTema"
                        )}
                        <div className='p-card-title'>Temas</div>
                        <div className='p-card-content'>
                            <Toolbar className="p-mb-4" right={this.rightToolbarTemplate(splitButton)}></Toolbar>

                            <DataTable value={temas} resizableColumns columnResizeMode="fit" className="p-datatable-gridlines" globalFilter={this.state.globalFilter}
                                paginator rows={10} rowsPerPageOptions={[5, 10, 25]} header={header} dataKey="id"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de un total de {totalRecords} ">
                                <Column field="nivel1" header="Nivel 1"></Column>
                                <Column field="nivel2" header="Nivel 2"></Column>
                                <Column field="nivel3" header="Nivel 3"></Column>
                                <Column headerStyle={{ width: '100px' }} header="Acciones" body={this.actionBodyTemplate}></Column>
                            </DataTable>
                        </div>

                    </Card>
                </LoadingOverlay>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    
    return {
        selectedForm: getSelectedForm(state),
        temas: getTemas(state),
        updateTableTemas:updateTableTemas(state),
        ocultarModal: ocultarModal(state),
        opcionesNivel1: opcionesNivel1(state),
        showToast: showToast(state),
        toastDetail: toastDetail(state),
        loadingTemas: loadingTemas(state)
    };
}

const mapDispachToProps = dispatch => {
    return {
        updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
        listarTemas: (value) => dispatch({ type: GET_TEMAS, value }),
        editarTema: (value) => dispatch({ type: EDITAR_TEMA, value }),
        borrarTema: (value) => dispatch({ type: BORRAR_TEMA, value }),
        agregarTema: (value) => dispatch({ type: AGREGAR_TEMA, value }),
        limpiarSelectedForm: (value) => dispatch({ type: LIMPIAR_FORM_NIVEL2, value }),
        limpiarForm: (value) => dispatch({ type: LIMPIAR_FORM, value }),
    }
}
export default connect(
    mapStateToProps,
    mapDispachToProps
)(TemasComponent);