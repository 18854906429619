import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';

import { CONVS_CONECTED_USERS, MESSAGE_DATA_CERO_CONECTED_USERS, MODAL_ESPERA_DATA, TABLE_CONECTEDUSERS_TITLE, TOAST_MESSAGE } from "./constants";
import { getDataTableConectedUsers, getManageSpinerState, getSelectedForm, getShowSnackBar, getStateFilterButton } from "./selectors";

import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";



class EstConectedUserComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true
        }

    }


    componentWillMount() {
        const {
            obtenerTablaConectedUsers,
            activarSpinner
        } = this.props

        obtenerTablaConectedUsers()
        activarSpinner()

    }

    componentDidUpdate(prevProps) {
        const { obtenerTablaConectedUsers, showToast, estadoBotonFiltrarRangos,
            activarSpinner } = this.props
        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;


        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerTablaConectedUsers(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {

            obtenerTablaConectedUsers(seleccionRango)
            activarSpinner()


        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                obtenerTablaConectedUsers(rango)
                activarSpinner()
            } else {
                showToast()
            }

        }

    }
    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }
    render() {
        const {
            dataTableConectedUsers,
            showSnackBar,
            spinerState,
        } = this.props;
        return (
            <div>
                {showSnackBar ? this.showSnackBar(
                    showSnackBar, ""
                )
                    : ""}
                <div>
                    <h4 className="tituloComponente">ESTADISTICAS DE USUARIOS CONECTADOS</h4>
                </div>
                {spinerState.estadisticaConectedUser ? this.spinnerGraficos() : ""}
                {dataTableConectedUsers.data?.length > 0 ?
                    <div className="containerGeneral">
                        <div className="p-mt-3">

                            {dataTableConectedUsers.data?.length > 0 && (
                                <DataTableCrudDemo
                                    headerTable={dataTableConectedUsers.columnsTable?.length > 0 ? dataTableConectedUsers.columnsTable : []}
                                    datosTable={dataTableConectedUsers.data?.length > 0 ? dataTableConectedUsers.data : []}
                                    titleTable={TABLE_CONECTEDUSERS_TITLE}
                                    exporExcel={false}
                                    nameTable={"Accesos de usuarios"}

                                />
                            )}
                        </div>
                    </div>

                    :
                    <div>
                        <h4 className="messageCero">{MESSAGE_DATA_CERO_CONECTED_USERS}</h4>
                    </div>
                }
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableConectedUsers: getDataTableConectedUsers(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        selectedForm: getSelectedForm(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),
    }
}


const mapDispachToProps = dispatch => {
    return {

        obtenerTablaConectedUsers: (value) => dispatch({ type: CONVS_CONECTED_USERS, value }),
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value })

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstConectedUserComponent);