import {
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    VERIFICATED_EMAIL,
    VERIFICATED_EMAIL_ERROR,
    VERIFICATED_EMAIL_SUCCESS
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}

export function verificatedEmail (payload) {
    return {
        type: VERIFICATED_EMAIL,
        payload
    };
}
export function verificatedEmailSucces (payload) {
    return {
        type: VERIFICATED_EMAIL_SUCCESS,
        payload
    };
}
export function verificatedEmailError (payload) {
    return {
        type: VERIFICATED_EMAIL_ERROR,
        payload
    };
}