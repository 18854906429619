import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItems, menuItemsChatBot, PATH_API } from '../../Constants';
import { collection, addDoc } from "firebase/firestore";


import {
  ADQUIRIR_PRODUCTO,
  GET_PLANES,
  GET_TRANSACCION,
  GET_USERS, LOAD_MENU,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  adquirirProductoSucces,
  getPlanesSuccess,
  getTransaccionSuccess,
} from './actions';

import { firebaseDatabase } from "../../controller/firebase";
import { getFromSession } from "../../controller/session";
import { consultaProperty } from "../../controller/configuration";

import axios from "axios";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }



  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}


function* loadMenu(action) {
  let list = [];
  let company = getFromSession("company_id");
  let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItems.forEach((menu) => {
    if (menu.roles.includes(rol) && menu.configuration === enviosMasivos) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* loadMenuChatBot(action) {
  let list = [];
  let company = getFromSession("company_id");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsChatBot.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });
  console.log("loadMenuChatBot")

  yield put(loadMenuSucces(list));
}

function* adquirirProducto(action) {
  console.log("adquirirProducto", action)
  let plan = false;
  let fecha_suscripcion = new Date();
  let producto = false;
  let valor = false;
  let pruebaGratis = false;
  let productoAdquirido = [];
  let resp = false;
  let producto_adquirido = false;

  const user = getFromSession("currentUser");

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  if (action.id === '00001') {
    pruebaGratis = true
    plan = 'Gratis'
  }
  if (action.id === '00002') {
    plan = 'Pymes'
    valor = '89000'
  }
  if (action.id === '00003') {
    plan = 'Empresarial'
  }
  if (action.id === '00004') {
    plan = 'Empresarial'
  }
  if (action.id === '00005') {
    plan = 'Gratis'
    pruebaGratis = true
  }
  if (action.id === '00006') {
    plan = 'Pymes'
    valor = '1068000'
  }



  if (action.producto === 'ChatBot AutoGestionable') {
    producto = 'chatbot'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        planActivo: true,
        pruebaGratis: false,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoChatbot: productoAdquirido
    };

  } else if (action.producto === 'Reconocimiento de Imágenes') {
    producto = 'OCR'
    if (pruebaGratis) {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: pruebaGratis,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    else {
      productoAdquirido = {
        plan: plan,
        tipo: action.tipo,
        fecha_compra: fecha_suscripcion,
        producto: producto,
        pruebaGratis: false,
        planActivo: true,
        valor: valor,
        referencia: action.referencia ? action.referencia : ''
      }
    }
    producto_adquirido = {
      productoOCR: productoAdquirido
    };
  }

  if (resp.producto) {
    if (resp.productoChatbot) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          console.log('Elemento actualizado correctamente');
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
    if (resp.productoOCR) {
      const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

      // yield docRefCompany.set(producto_adquirido);
      yield docRefCompany.update(producto_adquirido)
        .then(() => {
          console.log('Elemento actualizado correctamente');
          //updateIcon = true
          // console.log("updateICON", updateIcon)
        })
        .catch((error) => {
          console.error('Error al actualizar el elemento:', error);
        });
    }
  } else {
    const docRefCompany = yield firebaseDatabase.collection(`company`).doc(resp.tipo_documento + resp.num_documento);

    yield docRefCompany.update(producto_adquirido)
      .then(() => {
        console.log('Elemento actualizado correctamente');
        //updateIcon = true
        // console.log("updateICON", updateIcon)
      })
      .catch((error) => {
        console.error('Error al actualizar el elemento:', error);
      });
  }



}


function* getPlanes(action) {
  let fecha_prueba_gratis = false
  const user = getFromSession("currentUser");
  console.log("window.location.pathname", window.location.pathname)
  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  let planChatbot = resp.productoChatbot?.plan
  let planGratis = resp.productoChatbot?.pruebaGratis
  let planOcr = resp.productoOCR?.plan
  //let tipo = resp.producto?.tipo
  let respuesta = [];
  if (window.location.pathname === '/planes/:1050' || window.location.pathname === '/autobot') {
    yield firebaseDatabase.collection(`planes/${action.value}/subplanes`).get().then(docs => {
      docs.forEach(doc => {
        if (planChatbot) {

          if (planChatbot === 'Gratis') {
            fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

            const fecha_hoy = new Date()
            fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

            const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

            // Convierte la diferencia de milisegundos a días
            const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

            // Redondea el resultado a un número entero
            fecha_prueba_gratis = Math.round(dias)

            if (planChatbot === doc.data().title && action.value === 'mensual') {
              if (resp.productoChatbot.pruebaGratis === false) {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'No disponible'
                });
              }
              if (fecha_prueba_gratis >= 15 || planGratis === false) {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Tu prueba gratuita ha finalizado'
                });
              } else {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Mi plan actual'
                });
              }

            }
            else if (planChatbot === doc.data().title && action.value === 'anual') {
              if (fecha_prueba_gratis >= 15) {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Tu prueba gratuita ha finalizado'
                });
              } else {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Mi plan actual'
                });
              }
            }
            else {
              respuesta.push({
                ...doc.data(),
                key: doc.id
              });
            }

          }

          if (planChatbot === 'Pymes') {
            fecha_prueba_gratis = resp.productoChatbot?.fecha_compra

            const fecha_hoy = new Date()
            fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

            const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

            // Convierte la diferencia de milisegundos a días
            const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

            // Redondea el resultado a un número entero
            fecha_prueba_gratis = Math.round(dias)

            if (planChatbot === doc.data().title && action.value === resp.productoChatbot?.tipo) {
              if (fecha_prueba_gratis >= 30) {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id
                });
              } else {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Mi plan actual'
                });
              }

            }
            else if (planChatbot === doc.data().title && action.value === resp.productoChatbot?.tipo) {
              if (fecha_prueba_gratis >= 15) {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Tu suscripción terminó',
                  actualizar: true
                });
              } else {
                respuesta.push({
                  ...doc.data(),
                  key: doc.id,
                  actual: true,
                  labelPlan: 'Mi plan actual'
                });
              }

            }
            else {
              respuesta.push({
                ...doc.data(),
                key: doc.id
              });
            }

          }
        }


        else {
          respuesta.push({
            ...doc.data(),
            key: doc.id
          });
        }

      });
    });

  }
  if (window.location.pathname === '/planes/:1051' || window.location.pathname === '/imageOCR') {

    yield firebaseDatabase.collection(`planes/${action.value}/subplanesOCR`).get().then(docs => {
      docs.forEach(doc => {
        if (planOcr === 'Gratis') {
          fecha_prueba_gratis = resp.productoOcr?.fecha_compra

          const fecha_hoy = new Date()
          fecha_prueba_gratis = new Date(fecha_prueba_gratis.seconds * 1000)

          const diferenciaMilisegundos = fecha_hoy - fecha_prueba_gratis;

          // Convierte la diferencia de milisegundos a días
          const dias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);

          // Redondea el resultado a un número entero
          fecha_prueba_gratis = Math.round(dias)

          if (planOcr === doc.data().title && action.value === 'mensual') {
            if (resp.productoOcr.pruebaGratis === false) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'No disponible'
              });
            }
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }

          }
          else if (planOcr === doc.data().title && action.value === 'anual') {
            if (fecha_prueba_gratis >= 15) {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Tu prueba gratuita ha finalizado'
              });
            } else {
              respuesta.push({
                ...doc.data(),
                key: doc.id,
                actual: true,
                labelPlan: 'Mi plan actual'
              });
            }
          }
          else {
            respuesta.push({
              ...doc.data(),
              key: doc.id
            });
          }
        }
        else {
          respuesta.push({
            ...doc.data(),
            key: doc.id
          });
        }

      });
    });

  }
  yield put(getPlanesSuccess(respuesta));

}


function* getTransaccion(action) {
  //console.log("ACTION:VALUE transaccion", action)

  let company = false;
  let resp = false;
  let ref = action.referencia;
  let productoBD = false;
  let transaccionAprobada = false;
  let pruebaGratis = false;
  let plan = false;
  let valor = false;
  let fechaIni = new Date();
  let fechaFin = new Date();
  const user = getFromSession("currentUser");
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        company = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }


  if (!action.data) {
    if (action.id === '00001') {
      pruebaGratis = true
      plan = 'Gratis'
    }
    if (action.id === '00002') {
      plan = 'Pymes'
      valor = 89000
      fechaFin.setDate(fechaFin.getDate() + 30);
    }
    if (action.id === '00003') {
      plan = 'Empresarial'
    }
    if (action.id === '00004') {
      plan = 'Empresarial'
    }
    if (action.id === '00005') {
      plan = 'Gratis'
      pruebaGratis = true
    }
    if (action.id === '00006') {
      plan = 'Pymes'
      valor = 1068000
      fechaFin.setDate(fechaFin.getDate() + 365);
    }
    if (plan === 'Gratis') {
      yield adquirirProducto(action)
    }
    else {


      const headers = {
        "Authorization": 'Bearer prv_test_NuNWT77DIyXLrpRIt3T25pR2wXrfnCmn'
      }

      const urlDespliegue = `https://sandbox.wompi.co/v1/transactions?reference=${ref}`;

      resp = yield call(axios.get, urlDespliegue, { headers });
      //console.log("RESP WOMPI", resp)
      if (resp?.data?.data[0]?.status === 'APPROVED') {
        productoBD = resp.data.data[0]
        yield adquirirProducto(action)


        if (action.producto === 'ChatBot AutoGestionable') {

          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/CHATBOT/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        } else {
          const docRef = yield addDoc(collection(firebaseDatabase, `ventas/PRODUCTOS/OCR/`), {
            producto: action.producto,
            company: company.cuenta,
            referencia: action.referencia,
            valor: valor,
            fecha: new Date()
          })
        }




        const messages = `<p>¡Hola!</p> 
      
          <p>Haz adquirido ${action.producto} en el plan ${plan},  por valor de ${valor}, fecha inicio ${fechaIni} fin de la subcripcion ${fechaFin}</p>`

        const body = {
          from_: "Compra productos Avanti <chatasesores@gmail.com>",
          to_: `${user.mail}`,
          subject_: `${action.producto} Avanti-it`,
          body: messages,
          user: "chatasesores@gmail.com",
          pass: "wdnwdcqgpdhwoskg"
        }
        const mail = yield call(axios.post, process.env.REACT_APP_CHATASESORES_SEND_MAIL, body, headers);

      }
    }
    yield put(getTransaccionSuccess(productoBD));
  }
}

export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuChatBot);
  yield takeLatest(ADQUIRIR_PRODUCTO, adquirirProducto);
  yield takeLatest(GET_PLANES, getPlanes);
  yield takeLatest(GET_TRANSACCION, getTransaccion);

}
