import {
  GET_USERS_SUCCESS,
  USER_DATA,
  USER_HEADERS,
} from './constants';
  
  export const initialState = {
    users: USER_DATA,
    userHeaders: USER_HEADERS
  };
  
  const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type } = action;

    switch (type) {
      case GET_USERS_SUCCESS:
          const { data  } = payload;
          newState.users = data;

          return {
            ...newState
          };
      default:
        return {
          ...newState
        };
    }
  };
  
  export default reducer;
  