//aqui va query de estados

import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';

export function* lstrEstados(){
/* let estados=[];

const company = getFromSession("company_id");
yield firebaseDatabase.collection(`company/${company}/estados`).orderBy('nombre').get().then(docs=>{
 
    docs.forEach(doc=>{
        estados.push(doc);
        
    });
});
return estados; */
}