import {
    COMPANY_DATA_SUCCESS,
    DATA_DOC_CARGADOS_SUCESS,
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    LINE_CHART_SUCESS,
    LOAD_MENU_SUCCES,
    UPDATE_FIELD_ATTRIBUTE,
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS,
        payload
    };
}
export function updateAttributes(payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}


export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}
export function loadMenuSucces (payload) {
    return {
        type: LOAD_MENU_SUCCES,
        payload
    };
}

export function companyDataSuccess (payload) {
    return {
        type: COMPANY_DATA_SUCCESS,
        payload
    };
}
export function dataDocumentSuccess (payload) {
    return {
        type: DATA_DOC_CARGADOS_SUCESS,
        payload
    };
}
export function obtenerDatosGraficaLineChartSuccess (payload) {
    return {
        type: LINE_CHART_SUCESS,
        payload
    };
}


