import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getBotsSelector, getCardItems, getDataCompanySelector, getMenuItems, getMenuItemsChatBot, getUsers } from "./selectors";
import { Card, Spinner } from 'react-bootstrap';
import {
    ADQUIRIR_PRODUCTO,
    CARDS_ELE,
    BOT_ACTIVO,
    CARD_ITEMS,
    GET_USERS, LOAD_MENU, COMPANY_DATA
} from './constants';
import SideBar from "../../components/sidebar/sidebar";
import CustomCard from "../../components/customcard/customcard";
import { getFromSession } from "../../controller/session";
import ComponentPlanes from "../planes/component";


class ComponentInicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activePath: '/',
            planes: false,
            adquirido: true,
        }

    }
    componentDidMount(){
        const { companyData } = this.props;
    
        companyData();
    }

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        const { loadMenu, loadMenuChatbot, companyData, cardItemSelect, cardsItems, isBotEnabled, selectGetBots  } = this.props;
        // loadMenu();
        loadMenuChatbot();
        cardsItems();
        isBotEnabled();
        companyData();

        //console.log("cardsItems component", cardItemSelect)
    }

    actionCard = (itemId) => {
        console.log("item.id", itemId);
       // this.setState({ planes: true })
    }

    render() {
        const {
            userHeaders,
            users,
            menuItems,
            dataCompany,
            cardItemSelect,
            selectGetBots,
            menuItemsChatBot
        } = this.props;
        const user = getFromSession("currentUser");
        return (
            <div className="App home-container">
                {menuItemsChatBot && <SideBar logo={dataCompany.logo} items=''></SideBar>}
                <div className='user-list_wrapper'>
                    {/*  <img src="http://avanti-it.co/assets/img/Logo.png"
                    alt="Logo" width="250"
                 height="130" />*/}
                    {this.state.planes ?
                        <ComponentPlanes /> :
                        <>
                            <h1 className="tangerine">
                                {user.apellidos ?
                                    `Bienvenido ${user.nombres} ${user.apellidos} `
                                    : `Bienvenido ${user.nombres}`}
                            </h1>
                            <h3 style={{ "margin": "10px", "fontSize": "16px", "color": "black" }}>
                                Nos alegra que estes con nosotros
                            </h3>
                            <p style={{ "fontSize": "14px", "color": "black" }}>
                                ¡Es para nosotros un placer que te hayas unido a esta comunidad sin igual.
                                es un mercado lleno de posibilidades!
                            </p>

                            <div className="card-list">
                                {cardItemSelect ?
                                    <CustomCard
                                        items={
                                            cardItemSelect
                                        }
                                        onItemClick={this.actionCard} />
                                    :
                                    ''
                                }

                            </div>
                        </>
                    }
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItems: getMenuItems(state),
        menuItemsChatBot: getMenuItemsChatBot(state),
        cardItemSelect: getCardItems(state),
        selectGetBots: getBotsSelector(state),
        dataCompany: getDataCompanySelector(state)
        

    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        loadMenu: (value) => dispatch({ type: LOAD_MENU, value }),
        loadMenuChatbot: (value) => dispatch({ type: LOAD_MENU, value }),
        cardsItems: (value) => dispatch({ type: CARDS_ELE, value }),
        isBotEnabled: (value) => dispatch({ type: BOT_ACTIVO, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),


    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentInicio);