export const UPDATE_FIELD_ATTRIBUTE = '@authentication/updateAttribute';
export const GET_USERS = '@authentication/users';
export const SELECT_TAB = '@authentication/tab';
export const SIGN_USER = '@authentication/signUser';
export const LOG_USER = '@authentication/logUser';
export const AUTH_SUCCESS = '@authentication/authSuccess';
export const AUTH_ERROR = '@authentication/authError';
export const AUTH_LOGIN_SUCCESS = '@authentication/loginSuccess';
export const AUTH_LOAD_PARAMS = '@authentication/loadParams';
export const AUTH_LOAD_PARAMS_SUCCESS = '@authentication/loadParamsSucccess';
export const AUTH_LOAD_PARAMS_ERROR = '@authentication/loadParamsError';
export const FORM_LOGIN_NAME = 'loginForm';
export const VALIDATE_FIELDS = '@@redux-form/CHANGE';


export const RECOVERY_PASS = '@authentication/recoveryPass';
export const SEND_EMAIL = '@authentication/sendEmail';
export const RETURN_HOME = '@authentication/returnHome';
export const TAB_SING_IN = 'sign-in';
export const TAB_LOG_IN = 'log-in';
export const CONNECTEDUSER_SUCCESS = '@authentication/consultarconnectedUserSuccess';
export const CONNECTEDUSER = '@authentication/consultarconnectedUser';

export const AUTH_HEADERS = [
    { label: "id", column: "id" },
    { label: "email", column: "email" },
    { label: "first name", column: "first_name" },
    { label: "last name", column: "last_name" },
    { label: " ", column: " " },
];

export const AUTH_FORMS = [
    {
        label: "Empresa",
        name: "company",
        placeholder: "Id empresa",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        display: true
    },
    {
        label: "Login",
        name: "login",
        placeholder: "Username",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        display: true
    },
    {
        label: "Password",
        name: "password",
        placeholder: "Password",
        hint: "",
        type: "password",
        typeForm: "password",
        value: false,
        options: [],
        disable: false,
        display: true
    },

]

export const authFormFun = (handlerSafe) => {
    return [
        {
            label: "Empresa",
            name: "company",
            placeholder: "Id empresa",
            type: "number",
            typeForm: "text",
            value: false,
            options: [],
            disable: false,
            display: true
        },
        {
            label: "Login",
            name: "login",
            placeholder: "Username",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            disable: false,
            display: true
        },
        {
            label: "Password",
            name: "password",
            placeholder: "Password",
            hint: "",
            type: "password",
            typeForm: "password",
            value: false,
            options: [],
            disable: false,
            display: true
        },
        {
            name: "addRecurso",
            label: "¿Olvidaste tu contraseña?",
            type: "button",
            typeForm: "button",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "rs-20 buttonAdd no-space",
            onClick: handlerSafe,
        }

    ];
}

const getFormSendEmail = (handleAtras, handleSend) => {
    return [
        {
            options: ["Ingresa el correo electrónico de tu cuenta a recuperar, se enviara un correo de recuperación para que modifique su contraseña"],
            name: "instruccion",
            type: "label",
            typeForm: "label",
            value: false,
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            label: "Correo electrónico",
            name: "emailRecovery",
            placeholder: "Ingresa tu correo electrónico",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            disable: false,
            display: true,
            className: "rs-60"
        },
        {
            name: "volverHome",
            label: "Volver",
            type: "button",
            typeForm: "button",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "btnVolver",
            onClick: handleAtras,
        },
        {
            name: "sendEmail",
            label: "Enviar",
            type: "button",
            typeForm: "button",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "btnEnviar",
            onClick: handleSend,
        }
    ]
}
export const getFormRecoveryPass = (handleAtras, handleSend) => {
    return [
        {
            label: "Recupera tu cuenta",
            name: "sectionRecovery",
            type: "section",
            typeForm: "section",
            className: "rs-60 recuperarPass",
            fields: getFormSendEmail(handleAtras, handleSend),
        }
    ]
}

export const getLoginButton = (handlerSafe) => {
    return [
        {
            label: "Login",
            className: "btn btn-primary btn-lg btn-block",
            action: false,
            //style: "primary",
            onClick: handlerSafe,
            display: true,
            primary: true
        }
    ]
}

export const getModalButton = (handlerSend, handlerClose) => {
    return [
        {
            label: "Cancelar",
            className: "btn btn-primary btn-lg btn-block",
            action: false,
            //style: "primary",
            onClick: handlerClose,
            display: true,
            secondary: true,
        },
        {
            label: "Enviar",
            className: "btn btn-primary btn-lg btn-block",
            action: false,
            //style: "primary",
            onClick: handlerSend,
            display: true,
            primary: true
        }
    ]
}

export const getSignButton = (handlerSafe) => {
    return [
        {
            label: "Sign in",
            className: "auth-btn-sign",
            action: false,
            style: "primary",
            onClick: handlerSafe
        }
    ]
}

export const tabs = (selectTab) => {
    return [
        {
            label: "Login in",
            className: "auth-tab-sign",
            key: TAB_LOG_IN,
            onClick: selectTab
        },
        {
            label: "Sign in",
            className: "auth-tab-sign",
            key: TAB_SING_IN,
            onClick: selectTab
        }
    ];
}