import React, { Component } from 'react';
import { menuItems, menuItemsChatBot, URL_AUDIT_LOG, URL_DIALOG_FLOW } from '../../Constants';
import SideBar from "../../components/sidebar/sidebar";
import { getFromSession } from "../../controller/session";
import { connect } from "react-redux";
import "./style.css";
import { Button, ButtonGroup } from 'react-bootstrap';
import { faBook, faCogs, faDatabase, faDownload } from '@fortawesome/free-solid-svg-icons';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Modal from '@material-ui/core/Modal';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Toast } from 'primereact/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DOWNLOAD_FILE, getRecursosEconomicos, IMPORT_AND_EXPORT, IMPORT_FILE, MANAGE_BASE, MANAGE_NAV_BUTTON, REBOOT_PAGE, REMOVE_FILE, SEE_REPORTS, UPLOAD_FILE } from './constants';
import CustomForm from "../../components/materialform/customform";
import { Redirect } from "react-router-dom";
import { getAuditLogs, getDynamicDisable, getImportAndExport, getManageBase, getSeeReports, getShowSnackBar, getStartWaiting, getToastMensaje, getUrlAuditLog } from './selectors';

class FlujoMensajesComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false,
        }
    }
    componentDidUpdate() {
        const { rebootPage, toastMensaje } = this.props;
        const valores = window.location.search;
        var type = false;
        if (valores.length > 0) {
            const urlParams = new URLSearchParams(valores);
            type = urlParams.get('selectType');

            rebootPage({ value: type })

        }

        if (toastMensaje && toastMensaje === "success") {
            this.toast.show({ severity: 'success', summary: 'Operación exitosa', detail: 'La operación se completo satisfactoriamente' });
        }
        else if (toastMensaje) {
            this.toast.show({ severity: 'error', summary: 'La acción solicitada finalizo con errores', detail: 'En la solicitud se presento un error inesperado, verifíque si sus ajustes surtieron efecto' });
        }

    }

    iframe = () => {
        return {
            __html: this.props.customIframe
        }
    }

    openWindow = (target) => {
        window.open(target);
    }

    modalSubmitEspera(props) {
        const { startWaiting } = props;

        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="await-modal">
                    <React.Fragment>
                        <Alert>
                            {startWaiting.error ? startWaiting.message : "Procesando solicitud, espere por favor"}
                        </Alert>
                    </React.Fragment>
                    <div className="await-progress">
                        <CircularProgress />
                    </div>
                </div>
            </Modal>
        );
    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }
    
    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";
        
        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
                this.setState({ menu: menuItems });
        } else {
            this.setState({ menu: menuItemsChatBot });
        }
    }

    componentWillMount() {
        document.getElementById("scripts-chatbot").innerHTML = "";
        document.getElementById("container-chatbot").innerHTML = "";

        const company = getFromSession("company");

        if (!company.cuenta_nueva) {
            this.setState({ menu: menuItems });
        } else {
            this.setState({ menu: menuItemsChatBot });
        }

    }

    render() {
        const dialogFlowProject = "chaticopruebas-cnhr";//getFromSession("dialogFlowProject");

        let urlDialogFlow = URL_DIALOG_FLOW.replace('DIALOG_PROJECT', dialogFlowProject);
        let urlAuditLog = URL_AUDIT_LOG.replace('PROJECTURL', dialogFlowProject);
        const {
            step3completo,
            handleUpload,
            handleRemove,
            handleDownload,
            importarFile,
            dynamicDisable,
            showSnackBar,
            manageNavButton,
            importAndExport,
            auditLog,
            seeReports,
            manageBase,
            startWaiting,
            rebootPage,
        } = this.props;
        let user = getFromSession('company');

        //console.log("company flujo panel", user);
        return (

            <React.Fragment>
                {showSnackBar ? this.showSnackBar(
                    showSnackBar, "Sucess"
                )
                    : ""}

                <Toast ref={(el) => this.toast = el} />

                <SideBar logo={user.logo}  items={this.state.menu}></SideBar>
                <div className='base-container flujoMensajes'>
                    <h1 className='flow-title'>Base de Conocimiento</h1><br />
                    <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                        <Button onClick={() => this.openWindow(urlDialogFlow)}>
                            <FontAwesomeIcon className='nav-icon' icon={faDatabase} />
                            &nbsp; Gestionar Base de Conocimiento
                        </Button>
                        <Button
                            className={seeReports ? "outlined" : ""}
                            onClick={() => manageNavButton({ value: "seeReports" })}>
                            <FontAwesomeIcon className='nav-icon' icon={faBook} />
                            &nbsp; Ver Reporte
                        </Button>
                        <Button
                            className={importAndExport ? "outlined" : ""}
                            onClick={() => manageNavButton({ value: "importAndExport" })}>
                            <FontAwesomeIcon className='nav-icon' icon={faDownload} />
                            &nbsp; Importar y Exportar
                        </Button>
                        <Button
                            className={auditLog ? "outlined" : ""}
                            onClick={() => this.openWindow(urlAuditLog)}>
                            <FontAwesomeIcon className='nav-icon' icon={faBook} />
                            &nbsp; Informes de Auditoria
                        </Button>
                    </ButtonGroup>
                </div>
                {importAndExport ?
                    <React.Fragment>
                        <CustomForm
                            submitable={false}
                            items={getRecursosEconomicos(handleUpload, importarFile,
                                handleDownload)}
                            handleUpload={handleUpload}
                            importarFile={importarFile}
                            handleRemove={handleRemove}
                            handleDownload={handleDownload}
                            dynamicDisable={dynamicDisable}
                            formName={"Adjuntar Intents"}
                        />
                    </React.Fragment>
                    : ""}

                {seeReports ?
                    <div>
                        <Redirect to="/informesPanel?selectType=chatBot" />
                    </div>
                    : ""}


                {manageBase ? "" : ""}

                {startWaiting ? this.modalSubmitEspera(this.props) : ""}

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        customIframe: '<iframe src="http://www.w3schools.com" width="540" height="450"></iframe>',
        dynamicDisable: getDynamicDisable(state),
        showSnackBar: getShowSnackBar(state),
        importAndExport: getImportAndExport(state),
        auditLog: getAuditLogs(state),
        manageBase: getManageBase(state),
        seeReports: getSeeReports(state),
        startWaiting: getStartWaiting(state),
        toastMensaje: getToastMensaje(state),
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        handleUpload: (value) => dispatch({ type: UPLOAD_FILE, value }),
        manageNavButton: (value) => dispatch({ type: MANAGE_NAV_BUTTON, value }),
        rebootPage: (value) => dispatch({ type: REBOOT_PAGE, value }),
        handleRemove: (value) => dispatch({ type: REMOVE_FILE, value }),
        importarFile: (value) => dispatch({ type: IMPORT_FILE, value }),
        handleDownload: (value) => dispatch({ type: DOWNLOAD_FILE, value }),
    }
}

export default connect(
    mapStateToProps, mapDispachToProps
)(FlujoMensajesComponent);
