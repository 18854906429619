import React, { Component } from "react";
import "./style.css";
import ComponentTableDoc from "./ocrTable/componentTable";
import { connect } from "react-redux";
import { Paper } from "@material-ui/core";
import { COMPANY_DATA, CROSS_DOC, DOWNLOAD_DOC, SHOW_DOC_PROC } from "./constants";
import SideBar from "../../../components/sidebar/sidebar";
import { menuItemsOCR } from "../../../Constants";
import { InputText } from "primereact/inputtext";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getFromSession } from "../../../controller/session";
import { getDataCompanySelector, selectorCrossDocument, selectorLoadingCircular, selectorProccessDocument } from "./selectors";
import PDFViewer from "./ocrTable/vistaPreviaDoc";
import ComponentError from "../errorPopUpComponent";
import { selectorShowDocument } from "../misDocumentos/selectors";

class ComponentDocumentosCruzados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idDoc: false,
            pdfData: false,
            filtroTable: false,
            bodyError: 'Por favor ingrese al apartado de administracion y configure un parametro de cruce',
            labelTable: {
                columna1: 'Fecha de cargue',
                columna2: 'Nombre',
                columna3: 'Resumen',
                columna4: 'Acciones',
                columna7: 'Id Documento'
            }
        }

    }

    componentWillMount() {
        const {
            crossDoc,
            cruzarDocu,
            companyData,
            cargarDocumento
        } = this.props;
        companyData();
        if (cargarDocumento) {
            // crossDoc()
        } else {
            crossDoc();
        }
    }


    idDocumento = (newData) => {
        const {
            mostrarDocumentosProcesados,
            showDocumentSelector
        } = this.props;
        this.setState({ idDoc: newData });
        mostrarDocumentosProcesados(newData)
    }
    descargarDocumento = (newData) => {
        const {
            downloadDoc,
        } = this.props;
        downloadDoc(newData)
    }
    filtrarTabla = (e) => {
        //console.log("VALUE FILTER TABLE", e.target.value)
        let texto = e.target.value
        const { crossDocumentSelector } = this.props;
        // console.log("TEXTTIO", texto)
        //console.log("filter intentsData", intentsData)
        let listaFiltrada = crossDocumentSelector.filter(function (objeto) {
            var valorPropiedad = String(objeto.name).toLowerCase();
            var textoBusqueda = texto.toLowerCase();

            // Verificar si el valor de la propiedad contiene el texto de búsqueda
            return valorPropiedad.includes(textoBusqueda);
        });
        //console.log("listaFiltrada", listaFiltrada)

        this.setState({
            filtroTable: listaFiltrada
        })
    }

    pdfData = (newData) => {
        this.setState({ pdfData: newData });
    }
    handlerClose = () => {
        this.setState({ pdfData: false });

    }
    componentDidUpdate(prevProps) {
        const {
            crossDocumentSelector
        } = this.props;
        if(prevProps.crossDocumentSelector !== crossDocumentSelector){
            this.setState({
                filtroTable: crossDocumentSelector
            })
        }
    }

    render() {
        const {
            data,
            pdfData,
            selectorDataCompany,
            cargarDocumento,
            proccesDocumentSelector,
            showDocument,
            handlerCloseCruce,
            loading,
            showDocumentSelector,
            loadingCargarDocumentos,
            crossDocumentSelector,
            selectorDatosDocPrincipal,
            showCrossDocumentSelector,
            onCerrarCruce
        } = this.props;
        console.log("loadingCircularOCRRR", loadingCargarDocumentos)

        console.log("crossDocumentSelector", crossDocumentSelector)

        console.log("selectorDatosDocPrincipal", selectorDatosDocPrincipal)

        
        const user = getFromSession("company_id")
        return (
            <>

                {menuItemsOCR && <SideBar logo={selectorDataCompany.logo} items={menuItemsOCR}></SideBar>}
                {loadingCargarDocumentos &&

                    <div className="loading-wrapper">
                        <CircularProgress color="secondary"></CircularProgress>
                    </div>

                }
                {!loadingCargarDocumentos && !showCrossDocumentSelector ?
                    <ComponentError
                        onModalCancelError={handlerCloseCruce}
                        errorUpload={this.state.errorUpload}
                        errorTitle={'Error cruzando documentos'}
                        body={this.state.bodyError}
                    />
                    :
                    ''
                }
                {this.state.pdfData ?

                    <PDFViewer
                        handlerClose={this.handlerClose}
                        pdfUrl={this.state.pdfData}
                        idDoc={showDocumentSelector}
                    /> :
                    <Paper elevation={10} className="main-wrapper home container">
                        <div className="button-ocr-left">
                            <Button style={{ "marginTop": "10px" }} onClick={() => onCerrarCruce()} color='primary' variant="contained" >Volver</Button>
                        </div>
                        <div className="search-container">
                            <span className="p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText type="search" onChange={this.filtrarTabla} placeholder="Buscar documento..." />
                            </span>

                        </div>
                        <ComponentTableDoc
                            downloadDoc={this.descargarDocumento}
                            labelTable={this.state.labelTable}
                            id={user}
                            pdfData={this.pdfData} 
                            idDocumento={this.idDocumento}
                            data={this.state.filtroTable}
                            selectorDatosDocPrincipal={selectorDatosDocPrincipal}
                            title={'Cruce'} />
                    </Paper>
                }
            </>


        );
    }
}

const mapStateToProps = state => {
    return {
        selectorDataCompany: getDataCompanySelector(state),
        crossDocumentSelector: selectorCrossDocument(state),
        loadingCargarDocumentos: selectorLoadingCircular(state),
        showDocumentSelector: selectorShowDocument(state)

    };
};

const mapDispachToProps = dispatch => {
    return {
        downloadDoc: (value) => dispatch({ type: DOWNLOAD_DOC, value }),
        companyData: (value) => dispatch({ type: COMPANY_DATA, value }),
        crossDoc: (value) => dispatch({ type: CROSS_DOC, value }),
        mostrarDocumentosProcesados: (value) => dispatch({ type: SHOW_DOC_PROC, value }),




    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentDocumentosCruzados);