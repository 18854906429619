import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { getFromSession } from "../../controller/session";
import { Card } from "@material-ui/core";
import { CARD_ITEMS } from "./constants";
import CustomCardPlanes from "../../components/customcard-planes/customcardPlanes";
import ComponentConfPagos from "./pagos/confirmarPagos";

class ComponentAnual extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePath: '/',
        }

    }

    componentWillMount() {

    }

    onModalWhatsAppCancel = () => {
        this.setState({ confirmar: false }
        )
    }



    render() {

        const { planesAnual, actionCard } = this.props;
        const user = getFromSession("currentUser");
        console.log("PLANES", planesAnual)

        return (
            <div className="App borrador-container">
            
                {planesAnual &&
                    <CustomCardPlanes
                        items={
                            planesAnual
                        }
                        onItemClick={actionCard}
                    />
                }
            </div>

        );
    }
}

export default ComponentAnual;