export const GET_USERS = '@ocr/getAll';
export const GET_USERS_SUCCESS = '@ocr/getUsersSuccess';
export const GET_USERS_ERROR = '@ocr/getUsersError';
export const LOAD_MENU = '@ocr/loadMenu';
export const LOAD_MENU_SUCCES = '@ocr/loadMenuSucess';
export const COMPANY_DATA_SUCCESS = '@ocr/companyDataSucess';
export const COMPANY_DATA = '@ocr/companyData';
export const UPLOAD_BUCKET_SUCCESS = '@ocr/uploadBucketSuccess';
export const UPLOAD_BUCKET = '@ocr/uploadBucket';
export const OCR_ACTIVO_SUCCESS = '@ocr/ocrActivoSuccess';
export const OCR_ACTIVO = '@ocr/ocrActivo';
export const OCR_CREATE = '@ocr/ocrCreate';
export const OCR_CREATE_SUCCESS = '@ocr/ocrCreateSuccess';
export const CONTADOR_BUCKET_SUCCESS = '@ocr/ocrContadorSuccess';
export const GET_OCR_DOC = '@ocr/getOcrDocuments';
export const GET_OCR_DOC_SUCCESS = '@ocr/getOcrDocumentsSuccess';
export const PRO_OCR_DOC = '@ocr/prOcrDocuments';
export const PRO_OCR_DOC_SUCCESS = '@ocr/proOcrDocumentsSuccess';
export const GET_OCR_SIZE = '@ocr/getOcrSize';
export const GET_OCR_SIZE_SUCCESS = '@ocr/getOcrSizeSuccess';
export const GET_DOC_PROC = '@ocr/getOcrProcDocu';
export const GET_DOC_PROC_SUCCESS = '@ocr/getOcrProcDocuSuccess';
export const GET_DOC_FALL = '@ocr/getOcrFallDocu';
export const GET_DOC_FALL_SUCCESS = '@ocr/getOcrFallDocuSuccess';

export const EMPTY_LIST = '@ocr/getOcrEmptyList';

export const COMPARAR_ARRAY = '@ocr/getCompararArray';

export const DOCUMENTOS_CARGADOS_SUCCESS = '@ocr/ocrDocuCargadosSuccess';



export const DOWNLOAD_DOC = '@ocr/downloadDocu';
export const DOWNLOAD_DOC_SUCCESS = '@ocr/downloadDocuSuccess';
export const CROSS_DOC = '@ocr/crossDoc';
export const CROSS_DOC_SUCCESS = '@ocr/crossDocSuccess';

export const SHOW_DOC_PROC = '@ocr/showOcrProcDocu';
export const SHOW_DOC_PROC_SUCCESS = '@ocr/showOcrProcDocuSuccess';
export const UPLOAD_DOC_SUCCESS = '@ocr/uploadDocuSuccess';
export const GET_PLANES = '@ocr/getPlanes';
export const GET_PLANES_SUCCESS = '@ocr/getPlanesSuccess';

export const FIN_SUSCRIBCION = '@ocr/finSuscribcion';
export const FIN_SUSCRIBCION_SUCCESS = '@ocr/finSuscribcionSuccess';
export const GET_TRANSACCION_SUCCES = '@ocr/getTransaccionSuccess'
export const GET_TRANSACCION = '@ocr/getTransaccion'







export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "first_name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const USER_DATA = [
    {id: "1001", email: "email@email.com", name: "Alex", last_name: "vera"}, 
    {id: "1002", email: "joe@email.com", name: "Joe", last_name: "Doe"}, 
    {id: "1003", email: "jane@email.com", name: "Jane", last_name: "Smith"}, 
];
