import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getMenuItemsChatBot, getUsers, getuploadImage, selectBotActivo, selectGetBots, selectIntent, selectorFinSuscribcion, selectorPlanes, selectorPruebaGratis } from "./selectors";
import { Spinner } from 'react-bootstrap';
import {
    BOT_ACTIVO,
    FIN_SUSCRIBCION,
    GET_BOTS,
    GET_PLANES,
    GET_TRANSACCION,
    GET_USERS, LIST_CLOUD_PROJECTS, LOAD_MENU, PRUEBA_GRATIS, UPLOAD_IMAGE, UPLOAD_INTENT
} from './constants';
import SideBar from "../../components/sidebar/sidebar";
import CustomCard from "../../components/customcard/customcard";
import ComponentPlanes from "../planes/component";
import { CARD_ITEMS } from "../inicio/constants";
import { menuItems } from "../agentPanel/selectors";
import { getFromSession } from "../../controller/session";
import { Box, Button, Card, TextField } from "@material-ui/core";
import { InputText } from "primereact/inputtext";
import ComponentNombreBot from "./componentNombreBot";
import ComponentPopUpNombreBot from "./popUpNombreBot";
import ComponentCrearChatBot from "./componentCrearChatBot";
import ComponentVideo from "./componentVideo";


class ComponentAutogestionable extends Component {
    constructor(props) {
        super(props);
        const { items, onLogout } = this.props;
        this.state = {
            activePath: '/',
            chatbot: false,
            crearIntent: false,
            bot_activo: false,
            image: false,
            finSuscribcion: false,
            botonCont: 0,
            disabled: true,
            actualizarPlanTitle: false,
            actualizarPlanSubtitle: false,
        }
    }

    onButonClick = () => {
        const { getBots, getBotsSelector } = this.props;
        getBots()
        this.setState({ chatbot: true });
        if (getBotsSelector) {
            if (getBotsSelector.nombreChatBot !== '') {
                console.log("intentSelectintentSelect", getBotsSelector)
                this.setState({ crearIntent: true });
            }
        }
    }

    onModalCancel = () => {
        this.setState({ chatbot: false });
    }
    componentDidMount() {
        const { uploadImage, companyData } = this.props;
        uploadImage();
        this.setState({
            image: companyData.logo
        })
        //console.log("image", companyData.logo)
        //console.log("image", this.state.image)

    }

    componentWillMount() {
        const { getUsers, finSuscribcion, finSuscribcionSelector, loadMenuChatbot, companyData, getBots, validateProjects, getBotActivo, getBotsSelector, isBotEnabled } = this.props;
        getUsers();
        getBots();
        finSuscribcion();
        loadMenuChatbot();
        isBotEnabled();
        //const resp = validateProjects(this.state.nombreBot)
        //this.setState({ bot_activo: getBotActivo });
        if (finSuscribcionSelector.fecha_prueba_gratis) {
            if (finSuscribcionSelector.fecha_prueba_gratis >= 15) {
                this.setState({ finSuscribcion: true })
                if (finSuscribcionSelector.tipo === 'Gratis') {
                    this.setState({
                        actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
                        actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                    })

                }
            }
        }

    }
    componentDidUpdate(prevProps) {
        const { finSuscribcionSelector } = this.props;
        if (prevProps.companyData.productoChatbot && this.state.botonCont === 0) {
            this.setState({ disabled: false, botonCont: 1 })
        }
        if (prevProps.finSuscribcionSelector.fecha_prueba_gratis !== finSuscribcionSelector.fecha_prueba_gratis) {
            if(finSuscribcionSelector.tipo === 'Gratis'){
                if (finSuscribcionSelector.fecha_prueba_gratis >= 15) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'Gratis') {
                        this.setState({
                            actualizarPlanTitle: 'Tu prueba gratuita ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })
    
                    }
                }
            }
            if(finSuscribcionSelector.plan === 'Pymes'){
                if (finSuscribcionSelector.fecha_prueba_gratis >= 30) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'mensual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })
    
                    }
                }
            }
            if(finSuscribcionSelector.plan === 'Pymes'){
                if (finSuscribcionSelector.fecha_prueba_gratis >= 365) {
                    this.setState({ finSuscribcion: true })
                    if (finSuscribcionSelector.tipo === 'anual') {
                        this.setState({
                            actualizarPlanTitle: 'Tu suscribción ha finalizado',
                            actualizarPlanSubtitle: 'Selecciona uno de nuestros planes para seguir teniendo todos los beneficios que ofrecemos para tu negocio.'
                        })
    
                    }
                }
            }
            
            
        }
    }


    render() {
        const {
            userHeaders,
            getBotsSelector,
            menuItemsChatBot,
            listProjectSelector,
            users,
            companyData,
            getPlanes,
            uploadImage,
            planesSelector,
            getTransaccion,
            getBotActivo
        } = this.props;
        const user = getFromSession("currentUser");
        uploadImage()
        return (
            <div className="App home-container">

                {menuItemsChatBot && <SideBar logo={companyData.logo} items={menuItemsChatBot}></SideBar>}

                {/*

<ComponentWidget
company={companyData.cuenta} />*/}

                {this.state.finSuscribcion ?
                    <div className="App home-container">
                        <div className="noPlan">
                            <h1 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanTitle}
                            </h1>
                            <h3 style={{ "padding": "20px" }}>
                                {this.state.actualizarPlanSubtitle}
                            </h3>
                        </div>

                        <ComponentPlanes
                            finSuscribcionBot={true}
                            getPlanesBot={getPlanes}
                            planesSelectorBot={planesSelector}
                            getTransaccionBot={getTransaccion} />

                    </div>
                    :
                    <>
                        {getBotActivo ?
                            <ComponentCrearChatBot
                                listProjectSelector={listProjectSelector} />
                            :
                            <>
                                {this.state.chatbot ?
                                    <ComponentPopUpNombreBot
                                        listProjectSelector={listProjectSelector}
                                        getBotsSelector={getBotsSelector}
                                        onModalCancel={this.onModalCancel}
                                    />
                                    :
                                    <div className="contenido">
                                        <div className="texto-contenido">
                                            <h1 className="texto-contenido-title">
                                                Diseña tus conversaciones
                                            </h1>
                                            <br></br>
                                            <p className="title" style={{ "fontSize": "16px", "color": "black" }} >
                                                <strong>Configura tu Bot con las palabras claves</strong> que suelen
                                                <br></br>
                                                escribir tus clientes para interpretarlas,
                                                <br></br>
                                                y <strong>darles una respuesta personalizada</strong>
                                            </p>
                                            <hr></hr>
                                            <p style={{ "fontSize": "12px", "color": "black" }}>
                                                Un bot reacciona de acuerdo a los parámetros
                                                <br></br>
                                                de respuesta que le asignes a cada intención o pregunta
                                                <br></br>

                                            </p>
                                            <hr></hr>
                                            <br></br><br></br><br></br>
                                            <Button color="primary" disabled={this.state.disabled} variant="contained" onClick={() => this.onButonClick()} >
                                                Comenzar
                                            </Button>
                                        </div>
                                        <div className="imagen-container">
                                            <ComponentVideo />
                                            {/*<video src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fvideo%2FVideo_1684439769.mp4?alt=media&token=6bdc5971-d56b-485b-8284-c0cd12c2daf3" autoPlay={true} loop={true}></video>
                            <img src="https://firebasestorage.googleapis.com/v0/b/chat-asesores-desa.appspot.com/o/Avanti-it%2Fimages%2FChatBot.png?alt=media&token=89a62716-f43e-4b93-b424-745a382d53e2"></img>*/}
                                        </div>


                                    </div>
                                }
                            </>
                        }



                    </>

                }






            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        users: getUsers(state),
        menuItemsChatBot: getMenuItemsChatBot(state),
        getBotsSelector: selectGetBots(state),
        getBotActivo: selectBotActivo(state),
        companyData: getuploadImage(state),
        finSuscribcionSelector: selectorFinSuscribcion(state),
        planesSelector: selectorPlanes(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsers: () => dispatch({ type: GET_USERS, value: 1 }),
        getBots: (value) => dispatch({ type: GET_BOTS, value }),
        loadMenuChatbot: (value) => dispatch({ type: LOAD_MENU, value }),
        isBotEnabled: (value) => dispatch({ type: BOT_ACTIVO, value }),
        //validateProjects: (value) => dispatch({ type: LIST_CLOUD_PROJECTS, value }),
        uploadImage: () => dispatch({ type: UPLOAD_IMAGE, value: 1 }),
        finSuscribcion: () => dispatch({ type: FIN_SUSCRIBCION, value: 1 }),
        getPlanes: (value) => dispatch({ type: GET_PLANES, value }),
        getTransaccion: (referencia, id, tipo, producto, data) => dispatch({ type: GET_TRANSACCION, referencia, id, tipo, producto, data }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentAutogestionable);