import { connect } from "react-redux";
import React, { Component } from "react";
import 'primeflex/primeflex.css';
import "./style.css";
import {
    BAR_CHATBOT_TITLE,
    CONVS_CHATBOT,
    DATOS_COMENTARIOS_INSATISFACCION,
    DATOS_COMENTARIOS_INSATISFACCION_PAGINADOS,
    DATOS_CONVS_CHATBOT,
    DATOS_GRAFICA_TOP_10,
    DATOS_INTENTS_PORCENTAJE,
    DATOS_RESP_BOT,
    DATOS_RESP_UNKNOWN_BOT,
    GENERAR_DATA_CSV,
    GRAFICA_QUERY_SENTIMENT,
    GRAFICA_TASA_COINCIDENCIAS,
    HEADER_TABLE_CHATBOT,
    HEADER_TABLE_CHATBOT_CALIFICATON,
    HEADER_TABLE_CHATBOT_INTENT,
    HEADER_TABLE_CHATBOT_MESSAGES_UNKNOWN,
    MESSAGE_DATA_CERO_CHATBOT,
    MODAL_ESPERA_DATA,
    PAGINAR_COMENTARIOS_INSATISFACCION,
    PAGINAR_DATA_CONVS_CHABOT,
    PAGINAR_DATA_UNKNOWNRESP_BOT,
    PIE_CHATBOT_TITLE,
    PIE_INTENTS_AGRUPADOS,
    TABLE_CHATBOT_CALIFICATION_TITLE,
    TABLE_CHATBOT_INTENT_TITLE,
    TABLE_CHATBOT_MESSAGES_UNKNOWN_TITLE,
    TABLE_CHATBOT_TITLE, TOAST_MESSAGE
} from "./constants";

import {
    getComentariosInsatisfaccion,
    getComentariosInsatisfaccionPaginados,
    getConsultaPaginada,
    getCsvConvsBot,
    getCsvDataInsatisfaccion,
    getCsvDataUnknownBot,
    getDataCsvAgrupados,
    getDataCsvPie,
    getDataGeneradapCsv,
    getDataGraficaTasaCoincidencia,
    getDataIntentsPorcentajes,
    getDataPieTop10,
    getDataSentimentAnalysis,
    getDataTableChatbot,
    getDatosPieIntentsAgrupados,
    getDatosTablaConvsChatBot,
    getDatosTablaConvsChatBotPaginados,
    getDatosTablaDataUnknown,
    getDatosTablaDataUnknownPaginados,
    getManageSpinerState,
    getSelectedForm,
    getShowSnackBar,
    getStateFilterButton
} from "./selectors";

import { BarChartDemo } from '../../components/Charts/barrChart';
import { DataTableCrudDemo } from '../../components/tablePrime/tableSencilla';
import PieChart from '../../components/Charts/pieChart'
import BarChart from "../../components/Charts/barChart";

import { Button } from 'primereact/button';

import { Card } from 'primereact/card';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DataTableWithPaginator from "../../components/tableWithPaginator/tablePaginator";
import { CSVLink } from "react-csv";
import { LinearProgress, Snackbar } from "@material-ui/core";
import { Alert } from "react-bootstrap";

class EstChatBotComponent extends Component {


    constructor(props) {
        super(props)

        this.state = {
            iniciaComponente: true,
            basicFirst: 0,
            basicRows: 10,
            seleccionRango: false

        }

    }


    componentWillMount() {
        const {
            obtenerDataGraficaQuerySentiment,
            obtenerGraficoBarrasChatbot,
            obtenerDataConvsChatbot,
            obtenerDatosRespUnknownBot,
            obtenerDatosIntentsPorcentaje,
            obtenerDataTasaCoincidenciaBot,
            obtenerComentariosInsatisfaccion,
            obtenerDatosGraficaTop10,
            activarSpinner
        } = this.props
        obtenerDataGraficaQuerySentiment()
        obtenerDataConvsChatbot()
        obtenerDatosRespUnknownBot()
        obtenerDatosIntentsPorcentaje()
        obtenerDataTasaCoincidenciaBot()
        obtenerComentariosInsatisfaccion()
        obtenerDatosGraficaTop10()
        activarSpinner()

    }

    componentDidUpdate(prevProps) {
        const {
            obtenerDataGraficaQuerySentiment,
            estadoBotonFiltrarRangos,
            showToast,
            obtenerDataConvsChatbot,
            obtenerDatosRespUnknownBot,
            obtenerDatosIntentsPorcentaje,
            obtenerDataTasaCoincidenciaBot,
            obtenerComentariosInsatisfaccion,
            obtenerDatosGraficaTop10,
            activarSpinner
        } = this.props

        let seleccionRango = this.props.selectedForm.fechaRango;
        let anteriorSeleccionRango = prevProps.selectedForm.fechaRango;
        let fechaIni = this.props.selectedForm.fechaIni;
        let fechaFin = this.props.selectedForm.fechaFin;


        if (seleccionRango == anteriorSeleccionRango && this.state.iniciaComponente) {
            if (seleccionRango == 'CUSTOM' && fechaIni && fechaFin) {
                let ini = new Date(fechaIni)
                let fin = new Date(fechaFin)

                if (ini.getTime() < fin.getTime()) {
                    let rango = { "fechaIni": ini, "fechaFin": fin }
                    this.setState({
                        seleccionRango: rango
                    })
                    seleccionRango = rango;
                }
            }
            obtenerDataGraficaQuerySentiment(seleccionRango)
            obtenerDataConvsChatbot(seleccionRango)
            obtenerDatosRespUnknownBot(seleccionRango)
            obtenerDatosIntentsPorcentaje(seleccionRango)
            obtenerDataTasaCoincidenciaBot(seleccionRango)
            obtenerComentariosInsatisfaccion(seleccionRango)
            obtenerDatosGraficaTop10(seleccionRango)
            activarSpinner()
            this.setState({
                iniciaComponente: false
            })
        }


        if (seleccionRango && anteriorSeleccionRango != seleccionRango
            && seleccionRango != 'CUSTOM') {
            obtenerDataGraficaQuerySentiment(seleccionRango)
            obtenerDataConvsChatbot(seleccionRango)
            obtenerDatosRespUnknownBot(seleccionRango)
            obtenerDatosIntentsPorcentaje(seleccionRango)
            obtenerDataTasaCoincidenciaBot(seleccionRango)
            obtenerComentariosInsatisfaccion(seleccionRango)
            obtenerDatosGraficaTop10(seleccionRango)
            activarSpinner()
            this.setState({
                seleccionRango: seleccionRango
            })


        }
        if (seleccionRango == 'CUSTOM' && estadoBotonFiltrarRangos
            && fechaIni && fechaFin) {
            let ini = new Date(fechaIni)
            let fin = new Date(fechaFin)

            if (ini.getTime() < fin.getTime()) {
                let rango = { "fechaIni": ini, "fechaFin": fin }

                this.setState({
                    seleccionRango: rango
                })

                obtenerDataGraficaQuerySentiment(rango)
                obtenerDataConvsChatbot(rango)
                obtenerDatosRespUnknownBot(rango)
                obtenerDatosIntentsPorcentaje(rango)
                obtenerDataTasaCoincidenciaBot(rango)
                obtenerComentariosInsatisfaccion(rango)
                obtenerDatosGraficaTop10(rango)
                activarSpinner()
            } else {
                showToast()
            }

        }
    }


    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    spinnerGraficos() {
        return (

            <div className="loading-wrapperx">
                <LinearProgress />

                <p className="loading-text">
                    Cargando información ...

                </p>
            </div>

        );
    }

    render() {
        const {
            dataSentimentalAnalysis,
            dataTableChatbot,
            dataTablaConvsChatBot,
            paginarDatosConvsChatbot,
            datosTablaConvsChatBotPaginados,
            datosTablaDataUnknown,
            datosTablaDataUnknownPaginados,
            consultaPaginada,
            paginarDatosRespUnknownBot,
            datosIntentsPorcenjates,
            datosComentariosInsatisfaccion,
            datosComentariosInsatisfaccionPaginados,
            paginarComentariosInsatisfaccion,
            generarDataCSV,
            dataGraficaCoincidencia,
            dataGeneradaCsv,
            dataTop10Intents,
            datosPieIntentsAgrupados,
            dataCsvConvsBot,
            dataCsvUnknownBot,
            dataCsvInsatisfaccion,
            dataCsvTop10,
            dataCsvAgrupado,
            showSnackBar,
            spinerState,
        } = this.props;


        const header = (textTooltip, j) => {
            console.log('textTooltip', textTooltip);
            let style = textTooltip ? textTooltip.style : false;
            let text = textTooltip ? textTooltip.text : false;
            return [
                <span key={j} className={`${style}`} data-title={text}>
                    <img className="icon-table4 float-right" src="/assets/icons/info.png" />
                </span>
            ]
        }
        /* console.log('datosIntentsPorcenjates', datosIntentsPorcenjates) */

        /* console.log('datosTablaConvsChatBotPaginadosCOMPONENTE GRANDE', datosTablaConvsChatBotPaginados); */
        //  console.log("dataCsvAgrupado", dataGraficaCoincidencia?.dataCsv?.arrayCsv)



        return (
            <div>
                {showSnackBar ? this.showSnackBar(
                    showSnackBar, ""
                )
                    : ""}
                <div>
                    <h4 className="tituloComponente" >ESTADISTICAS DE CHATBOT</h4>
                </div>
                {spinerState.chatbot ? this.spinnerGraficos() : ""}
                {/*   {dataTableChatbot.data?.length > 0 ? */}
                <div className="containerGeneral">
                    <div>
                        {spinerState.tablaTasaCoincidencia ? this.spinnerGraficos() : ""}

                        {dataGraficaCoincidencia?.datasets?.length > 0 ?
                            <BarChartDemo
                                dataBar={dataGraficaCoincidencia?.datasets?.length > 0 ? dataGraficaCoincidencia : {}}
                                titleBar={BAR_CHATBOT_TITLE}
                                tipoStyle={true}

                            />

                            : ''
                        }
                        <div className="downloadButton">

                            {dataGraficaCoincidencia?.dataCsv?.arrayCsv?.length > 0 ?
                                <CSVLink data={dataGraficaCoincidencia?.dataCsv?.arrayCsv}
                                    filename={`descargar-data-csvData.csv`}>
                                    <Button label="Descargar Data" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >

                                    </Button>
                                </CSVLink>

                                : ''
                            }

                            {/*
                            {dataGraficaCoincidencia?.dataCsv?.dataCsvContestadas?.length > 0 ?
                                <CSVLink data={dataGraficaCoincidencia?.dataCsv?.dataCsvContestadas}
                                    filename={`preguntas-contestadas-csvData.csv`}>
                                    <Button label="CSV Preguntas contestadas" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >
                                        
                                    </Button>
                                </CSVLink>

                                : ''
                            }

                            {dataGraficaCoincidencia?.dataCsv?.dataCsvNoContestadas?.length > 0 ?
                                <CSVLink data={dataGraficaCoincidencia?.dataCsv?.dataCsvNoContestadas}
                                    filename={`preguntas-no-contestadas-csvData.csv`}>
                                    <Button label="CSV Preguntas no contestadas" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >
                                       
                                    </Button>
                                </CSVLink>

                                : ''
                            }

                            {dataGraficaCoincidencia?.dataCsv?.dataCsvTasa?.length > 0 ?
                                <CSVLink data={dataGraficaCoincidencia?.dataCsv?.dataCsvTasa}
                                    filename={`Tasa-de-preguntas-contestadas-csvData.csv`}>

                                    <Button label="CSV Tasa de preguntas contestadas" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >
                                    </Button>
                                </CSVLink>

                                : ''
                            }
                        */}
                        </div>
                    </div>

                    <div className="p-mt-3">
                        {dataTablaConvsChatBot && dataTablaConvsChatBot.length > 0 && (
                            <DataTableWithPaginator
                                data={dataTablaConvsChatBot}
                                columns={HEADER_TABLE_CHATBOT} paginarData={paginarDatosConvsChatbot}
                                seleccionRangoBusqueda={this.state.seleccionRango}
                                tituloTabla={"Tabla de datos conversaciones chatbot"}
                                dataPaginada={datosTablaConvsChatBotPaginados} consultaPaginada={consultaPaginada}
                                descargarCSV={generarDataCSV} nombreConsulta={"convsChatbot"} dataToDownloadCsv={dataCsvConvsBot}


                            />
                        )}
                    </div>
                    <div className="p-mt-3">
                        {dataTableChatbot.data?.length > 0 && (
                            <DataTableCrudDemo
                                headerTable={dataTableChatbot.columnsTable?.length > 0 ? dataTableChatbot.columnsTable : []}
                                datosTable={dataTableChatbot.data?.length > 0 ? dataTableChatbot.data : []}
                                titleTable={TABLE_CHATBOT_TITLE}
                                exporExcel={false}
                                nameTable={"ChatBot"}
                            />
                        )}
                    </div>
                    <div>
                        {spinerState.pieTop10 ? this.spinnerGraficos() : ""}
                        <div className="p-grid justify-content-center">
                            {dataTableChatbot.pie?.labels?.length > 0 ?
                                <div className="p-col-6 ">
                                    <PieChart
                                        chartData={dataTableChatbot.pie}
                                        titlePie={PIE_CHATBOT_TITLE}
                                        tipoPie={true}
                                    />
                                </div> : ''

                            }


                            {dataTop10Intents?.labels?.length > 0 ?
                                <div className="p-col-6 ">
                                    <PieChart
                                        chartData={dataTop10Intents}
                                        titlePie={PIE_CHATBOT_TITLE}
                                        tipoPie={true}
                                    />
                                </div> : ''

                            }
                            {datosPieIntentsAgrupados?.labels?.length > 0 ?
                                <div className="p-col-6 ">
                                    <PieChart
                                        chartData={datosPieIntentsAgrupados}
                                        titlePie={PIE_INTENTS_AGRUPADOS}
                                        tipoPie={true}
                                    />
                                </div> : ''
                            }
                        </div>


                        <div className="downloadButton">
                            {dataCsvTop10?.length > 0 ?
                                <CSVLink data={dataCsvTop10}
                                    filename={`top-10-preguntas-chatbot-csvData.csv`}>

                                    <Button label="Descargar Top 10 intents" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >
                                    </Button>
                                </CSVLink>

                                : ''
                            }

                            {dataCsvAgrupado?.length > 0 ?
                                <CSVLink data={dataCsvAgrupado}
                                    filename={`intents-agrupados-csvData.csv`}>
                                    <Button label="Descargar consultas por tema" icon="pi pi-file-excel"
                                        className="p-button-info p-ml-2"
                                    >
                                    </Button>
                                </CSVLink>

                                : ''
                            }
                        </div>

                    </div>
                    <div>
                        {spinerState.tablaSentiment ? this.spinnerGraficos() : ""}

                        {dataSentimentalAnalysis && (

                            <Card title="Analisis de satisfacción por mensajes" /* className="p-grid" */>
                                <div className="p-grid">
                                    <div className="p-col-7">
                                        <BarChart data={dataSentimentalAnalysis.datos} />
                                    </div>
                                    <div header="Tabla de datos" className="p-col-4 p-mt-5">
                                        <DataTable value={dataSentimentalAnalysis.datosTabla}
                                            dataKey="id" ref={(el) => this.dt3 = el}>
                                            <Column field="rango" header="Rango"></Column>
                                            <Column field="numMensajes" header="Número de mensajes"></Column>

                                        </DataTable>
                                    </div>
                                </div>
                            </Card>
                        )}
                    </div>

                    <div className="p-mt-3">
                        {spinerState.tablaIntentsPorcentaje ? this.spinnerGraficos() : ""}
                        {datosIntentsPorcenjates && datosIntentsPorcenjates.length > 0 && (
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_CHATBOT_INTENT}
                                datosTable={datosIntentsPorcenjates.length > 0 ? datosIntentsPorcenjates : []}
                                titleTable={TABLE_CHATBOT_INTENT_TITLE}
                                exporExcel={false}
                                nameTable={"ChatBot"}

                            />
                        )}
                    </div>
                    <div className="p-mt-3">
                        {dataTableChatbot.dataUnknown?.length > 0 && (
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_CHATBOT_MESSAGES_UNKNOWN}
                                datosTable={dataTableChatbot.dataUnknown?.length > 0 ? dataTableChatbot.dataUnknown : []}
                                titleTable={TABLE_CHATBOT_MESSAGES_UNKNOWN_TITLE}
                                exporExcel={false}
                                nameTable={"ChatBot"}

                            />
                        )}
                    </div>
                    <div className="p-mt-3">
                        {spinerState.tablaRespUnknown ? this.spinnerGraficos() : ""}
                        {datosTablaDataUnknown && datosTablaDataUnknown?.length > 0 && (
                            <DataTableWithPaginator
                                data={datosTablaDataUnknown}
                                columns={HEADER_TABLE_CHATBOT_MESSAGES_UNKNOWN} paginarData={paginarDatosRespUnknownBot}
                                seleccionRangoBusqueda={this.state.seleccionRango}
                                tituloTabla={'Tabla de datos por respuestas no contestadas de chatbot'}
                                dataPaginada={datosTablaDataUnknownPaginados} consultaPaginada={consultaPaginada}
                                descargarCSV={generarDataCSV} nombreConsulta={"dataUnknownBot"} dataToDownloadCsv={dataCsvUnknownBot}

                            />
                        )}
                    </div>
                    <div className="p-mt-3">
                        {dataTableChatbot.dataComentarios?.length > 0 && (
                            <DataTableCrudDemo
                                headerTable={HEADER_TABLE_CHATBOT_CALIFICATON}
                                datosTable={dataTableChatbot.dataComentarios?.length > 0 ? dataTableChatbot.dataComentarios : []}
                                titleTable={TABLE_CHATBOT_CALIFICATION_TITLE}
                                exporExcel={false}
                                nameTable={"ChatBot"}

                            />
                        )}
                    </div>
                    <div className="p-mt-3">
                        {spinerState.tablaIntentsPorcentaje ? this.spinnerGraficos() : ""}
                        {datosComentariosInsatisfaccion?.length > 0 && (
                            <DataTableWithPaginator
                                data={datosComentariosInsatisfaccion}
                                columns={HEADER_TABLE_CHATBOT_CALIFICATON} paginarData={paginarComentariosInsatisfaccion}
                                seleccionRangoBusqueda={this.state.seleccionRango}
                                tituloTabla={TABLE_CHATBOT_CALIFICATION_TITLE}
                                dataPaginada={datosComentariosInsatisfaccionPaginados} consultaPaginada={consultaPaginada}
                                descargarCSV={generarDataCSV} nombreConsulta={"comentariosInsatisfaccion"} dataToDownloadCsv={dataCsvInsatisfaccion}

                            />
                        )}
                    </div>
                </div>
                {/*  :
                    <div>
                        <h4 className="messageCero">{MESSAGE_DATA_CERO_CHATBOT}</h4>
                    </div> */}
                {/*   } */}
            </div>
        )

    }




}


const mapStateToProps = state => {

    return {
        dataTableChatbot: getDataTableChatbot(state),
        dataSentimentalAnalysis: getDataSentimentAnalysis(state),
        selectedForm: getSelectedForm(state),
        estadoBotonFiltrarRangos: getStateFilterButton(state),
        dataTablaConvsChatBot: getDatosTablaConvsChatBot(state),
        datosTablaConvsChatBotPaginados: getDatosTablaConvsChatBotPaginados(state),
        consultaPaginada: getConsultaPaginada(state),
        datosTablaDataUnknown: getDatosTablaDataUnknown(state),
        datosTablaDataUnknownPaginados: getDatosTablaDataUnknownPaginados(state),
        datosIntentsPorcenjates: getDataIntentsPorcentajes(state),
        datosComentariosInsatisfaccion: getComentariosInsatisfaccion(state),
        datosComentariosInsatisfaccionPaginados: getComentariosInsatisfaccionPaginados(state),
        dataGraficaCoincidencia: getDataGraficaTasaCoincidencia(state),
        dataGeneradaCsv: getDataGeneradapCsv(state),
        dataTop10Intents: getDataPieTop10(state),
        datosPieIntentsAgrupados: getDatosPieIntentsAgrupados(state),
        dataCsvConvsBot: getCsvConvsBot(state),
        dataCsvUnknownBot: getCsvDataUnknownBot(state),
        dataCsvInsatisfaccion: getCsvDataInsatisfaccion(state),
        dataCsvTop10: getDataCsvPie(state),
        dataCsvAgrupado: getDataCsvAgrupados(state),
        spinerState: getManageSpinerState(state),
        showSnackBar: getShowSnackBar(state),




    }
}


const mapDispachToProps = dispatch => {
    return {
        showToast: (value) => dispatch({ type: TOAST_MESSAGE, value }),
        obtenerDataGraficaQuerySentiment: (value) => dispatch({ type: GRAFICA_QUERY_SENTIMENT, value }),
        obtenerGraficoBarrasChatbot: (value) => dispatch({ type: CONVS_CHATBOT, value }),
        obtenerDataConvsChatbot: (value) => dispatch({ type: DATOS_CONVS_CHATBOT, value }),
        paginarDatosConvsChatbot: (value) => dispatch({ type: PAGINAR_DATA_CONVS_CHABOT, value }),
        obtenerDatosRespUnknownBot: (value) => dispatch({ type: DATOS_RESP_UNKNOWN_BOT, value }),
        paginarDatosRespUnknownBot: (value) => dispatch({ type: PAGINAR_DATA_UNKNOWNRESP_BOT, value }),
        obtenerDatosIntentsPorcentaje: (value) => dispatch({ type: DATOS_INTENTS_PORCENTAJE, value }),
        obtenerDataTasaCoincidenciaBot: (value) => dispatch({ type: GRAFICA_TASA_COINCIDENCIAS, value }),
        obtenerComentariosInsatisfaccion: (value) => dispatch({ type: DATOS_COMENTARIOS_INSATISFACCION, value }),
        paginarComentariosInsatisfaccion: (value) => dispatch({ type: PAGINAR_COMENTARIOS_INSATISFACCION, value }),
        generarDataCSV: (value) => dispatch({ type: GENERAR_DATA_CSV, value }),
        obtenerDatosGraficaTop10: (value) => dispatch({ type: DATOS_GRAFICA_TOP_10, value }),
        activarSpinner: (value) => dispatch({ type: MODAL_ESPERA_DATA, value })

    }
}

export default connect(
    mapStateToProps,
    mapDispachToProps
)(EstChatBotComponent);