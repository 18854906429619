import { takeLatest, put, call } from "redux-saga/effects";
import { GET_USERS_API, menuItemsOCR, PATH_API } from '../../../Constants';
import { collection, addDoc, setDoc, doc, getDoc, deleteDoc, updateDoc, getDocs } from "firebase/firestore";
import { firebaseDatabase, firebaseField, storage } from "../../../controller/firebase";
import { ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  COMPANY_DATA,
  CROSS_DOC,
  DOWNLOAD_DOC,
  GET_DOC_FALL,
  GET_DOC_PROC,
  GET_LOTES,
  GET_OCR_DOC,
  GET_OCR_SIZE,
  GET_USERS, LOAD_MENU, OCR_ACTIVO, OCR_CREATE, PRO_OCR_DOC, SHOW_DOC_PROC, UPLOAD_BUCKET,
} from './constants';

import {
  getUsersSuccess,
  getUsersError,
  loadMenuSucces,
  companyDataSuccess,
  uploadBucketSuccess,
  ocrActivoSuccess,
  ocrActivoActionSuccess,
  ocrCreateSuccess,
  contadorBucketSuccess,
  getOCRDocumentosSuccess,
  proOCRDocumentosSuccess,
  getOcrSizeSuccess,
  documentosProcesadosSuccess,
  showDocumentosProcesadosSuccess,
  documentosFallidosSuccess,
  downloadDocuSuccess,
  crossDocSuccess,
  UploadDocuSuccess,
  getLotesSuccess,
} from './actions';

import { getFromSession } from "../../../controller/session";
import { emptyJson } from "../../../controller/utilidades";

function* getUsers() {
  const urlUsers = `${PATH_API}${GET_USERS_API}`;
  const response = yield call(fetch, urlUsers, {
    method: 'GET',
  });

  const fireResponse = yield firebaseDatabase.collection('users').get();
  const responseData = {
    data: []
  };

  if (fireResponse) {
    fireResponse.forEach(element => {
      const { id, email, name, last_name } = element.data();
      responseData.data.push({ id, email, name, last_name });
    });
  }

  const { data } = responseData;

  if (data.length > 0) {
    yield put(getUsersSuccess(responseData));
    return;
  }

  /*
    if (response.status >= 200 && response.status < 300) {
      const successResponse = yield call([response, response.json]);
      console.log('successResponse', successResponse);
      yield put(getUsersSuccess(successResponse));
      return;
    }
  */

  const errorResponse = yield call([response, response.json]);
  yield put(getUsersError(errorResponse));
}

function* loadMenuImageOCR(action) {
  let list = [];
  let company = getFromSession("company_id");
  //let enviosMasivos = yield consultaProperty(company, "ENVIOS_MASIVOS");
  let user = getFromSession("currentUser");
  let rol = user.rol;
  menuItemsOCR.forEach((menu) => {
    if (menu.roles.includes(rol)) {
      list.push(menu);
    } else if (menu.roles.includes(rol)) {
      list.push(menu);
    }
  });

  yield put(loadMenuSucces(list));
}

function* companyData(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
  yield put(companyDataSuccess(resp));
  return resp
}
function* uploadToBucket(action) {
  let lote = false;
  let company = false;
  let datosConsulta = false;
  const user = getFromSession("currentUser");

  let resp = false;
  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });

  try {
    //console.log("docRefffff", docRef.id)

    let contador = 0;
    let longitud = 0;
    if (action.value.length > 1) {
      const docRef = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`), {
        fecha_creacion: firebaseField.serverTimestamp(),
        usuario: {
          name: resp.nombres,
          user: `${resp.tipo_documento + resp.num_documento}`,
          correo: resp.correo
        },
        aplicacion: "chat-asesores",
        estado: 1,
        nro_documentos: action.value.length
      })

      const idCargue = docRef.id
      lote = docRef.id
      console.log("LOTEEEE", lote)
      company = resp.tipo_documento + resp.num_documento

      const cambios = {
        id: idCargue
      };

      const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${idCargue}`);
      docRefUpdate.update(cambios)
        .then(() => {
          console.log('Documento actualizado correctamente.');
        })
        .catch((error) => {
          console.error('Error al actualizar el documento:', error);
        });



      console.log("mas de unooo")
      longitud = action.value.length;
      for (const archivo of action.value) {
        let newName = archivo.name.replace(/\s+/g, '');
        let spaceRef = ref(storage, `${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`);
        yield uploadBytes(spaceRef, archivo).then((snapshot) => {
        }).catch(error => {
          console.error('Error cargando el documento:', error);
        });

        // Supongamos que tienes la referencia al documento
        let documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('documentosOCR').doc(`${idCargue}`);

        // Añade una nueva colección al documento
        let nuevaColeccionRef = yield documentRef.collection('Documentos');

        // Añade un documento a la nueva colección
        //const nuevoDocumentoRef = nuevaColeccionRef.doc('documento' + `${contador}`);

        let datosDocumento = {
          name: newName,
          url_bucket: `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`,
          tamano: archivo.size,
          fecha_cargue: firebaseField.serverTimestamp(),
          estado: 0,
          tipo: archivo.type
        };
        // console.log("DATOS CARGUE DOCUMENTO", datosDocumento.name)
        let documentosCargue = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos`), {
          datosDocumento
        })
        // let idCargueDoc = documentosCargue.id
        // console.log("DATTE BF", documentosCargue)
        // const cambios = {
        //   id: idCargueDoc
        // };

        // const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos/`).doc(`${idCargueDoc}`);
        // docRefUpdate.update(cambios)
        //   .then(() => {
        //     console.log('Documento actualizado correctamente.');
        //   })
        //   .catch((error) => {
        //     console.error('Error al actualizar el documento:', error);
        //   });

        contador = contador + 1
        yield put(contadorBucketSuccess({
          contador: contador,
          length: longitud,
          nombre: newName
        }));

        yield getOCRDocumentos(lote)

        let toastDetail = false;
        let showTable = false;

        if (action) {
          toastDetail = {
            'summary': 'Realizado!',
            'severity': 'success',
            'detail': 'Se ha cargado el documento'
          }
          showTable = true
        } else {
          toastDetail = {
            'summary': 'No realizado!',
            'severity': 'error',
            'detail': 'Ha ocurrido un error'
          }

        }

        yield put(UploadDocuSuccess({
          toastDetail: toastDetail,
          showToast: true,
          showTable
        }));


      }

      datosConsulta = {
        usuario: {
          nombre: resp.nombres,
          correo: resp.correo,
          company: resp.num_documento
        },
        company: company,
        documentosOcr: lote,
        estado_doc: 1,
        estado_lote: 0,
      }

    } else {
      //console.log("unooo", action.value[0].name)

      const docRef = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`), {
        fecha_creacion: firebaseField.serverTimestamp(),
        usuario: {
          name: resp.nombres,
          user: `${resp.tipo_documento + resp.num_documento}`,
          correo: resp.correo
        },
        aplicacion: "chat-asesores",
        estado: 1,
        nro_documentos: action.value.length
      })

      const idCargue = docRef.id
      lote = docRef.id
      company = resp.tipo_documento + resp.num_documento

      const cambios = {
        id: idCargue
      };

      const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${idCargue}`);
      docRefUpdate.update(cambios)
        .then(() => {
          console.log('Documento actualizado correctamente.');
        })
        .catch((error) => {
          console.error('Error al actualizar el documento:', error);
        });

      longitud = 1;
      yield put(contadorBucketSuccess({
        length: longitud,
        contador: 1,
        nombre: action.value[0].name
      }));


      yield getOCRDocumentos(lote)

      for (const archivo of action.value) {
        const newName = archivo.name.replace(/\s+/g, '');
        const spaceRef = ref(storage, `${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`);
        yield uploadBytes(spaceRef, archivo).then((snapshot) => {
        }).catch(error => {
          console.error('Error cargando el documento:', error);
        });
        // Supongamos que tienes la referencia al documento
        const documentRef = firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('documentosOCR').doc(`${idCargue}`);

        // Añade una nueva colección al documento
        const nuevaColeccionRef = documentRef.collection('Documentos');

        // Añade un documento a la nueva colección
        const nuevoDocumentoRef = nuevaColeccionRef.doc('documento' + `${contador}`);
        const datosDocumento = {
          //id: idCargue,
          name: newName,
          url_bucket: `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${resp.tipo_documento + resp.num_documento}/productos/ocr/${newName}`,
          tamano: archivo.size,
          fecha_cargue: firebaseField.serverTimestamp(),
          estado: 0,
          tipo: archivo.type
        };

        const documentosCargue = yield addDoc(collection(firebaseDatabase, `company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos`), {
          datosDocumento
        })
        const idCargueDoc = documentosCargue.id
        const cambios = {
          id: idCargueDoc
        };

        // const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${idCargue}/Documentos/`).doc(`${idCargueDoc}`);
        // docRefUpdate.update(cambios)
        //   .then(() => {
        //     console.log('Documento actualizado correctamente.');
        //   })
        //   .catch((error) => {
        //     console.error('Error al actualizar el documento:', error);
        //   });

        datosConsulta = {
          usuario: {
            nombre: resp.nombres,
            correo: resp.correo,
            company: resp.num_documento
          },
          company: company,
          documentosOcr: lote,
          estado_doc: 1,
          estado_lote: 0,
        }

        //    console.log("procesar documentos", documento)


      }
    }
  } catch (error) {
    //console.log('uploadIntent', error)
    throw error;
  }



  let toastDetail = false;
  let showTable = false;

  if (action) {
    toastDetail = {
      'summary': 'Realizado!',
      'severity': 'success',
      'detail': 'Se han cargado todos los documento'
    }
    showTable = true
  } else {
    toastDetail = {
      'summary': 'No realizado!',
      'severity': 'error',
      'detail': 'Ha ocurrido un error'
    }

  }

  yield put(uploadBucketSuccess({
    toastDetail: toastDetail,
    showToast: true,
    showTable
  }));
  //    console.log("procesar documentos", documento)

  // console.log("datos para consulta", datosConsulta)

  // yield getDocumentosProcesados();
  // yield getDocumentosFallidos();



}

function* ocrCreate(action) {

  const user = getFromSession("currentUser");
  let resp = false;
  let response = false;

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });
  console.log("resp", resp)

  if (resp.ocr_activo === false) {
    yield firebaseDatabase.collection(`company/`).doc(`${resp.tipo_documento + resp.num_documento}`).update({
      ocr_activo: true
    })

    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRefMAX_SIZE_DOC = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MAX_SIZE_DOC');
    const MAX_SIZE_DOC = {
      administrable: true,
      value: 4
    };

    docRefMAX_SIZE_DOC.set(MAX_SIZE_DOC);

    response = resp.ocr_activo

  } else {
    response = true;
  }
  yield put(ocrCreateSuccess(response))


}

function* isOcrEnabled(action) {
  const user = getFromSession("currentUser");
  //console.log("user", user)
  let resp = false;
  let ocrActivo = false
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/`).doc(`${resp.tipo_documento + resp.num_documento}`);
    const ocrActivoResp = yield docRef.get();
    if (ocrActivoResp.exists) {
      ocrActivo = ocrActivoResp.data().ocr_activo;
      //console.log('Valor del campo:', ocrActivo);
      yield put(ocrActivoActionSuccess(ocrActivo));
    } else {
      console.log('El documento no existe.');
    }
  } catch (e) {
    console.log("erro obteniendo el campo bot_activo", e)
  }

  //return (botActivo)
}

function* getOCRDocumentos(action) {
  let lote = action.value.lote

  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let estado = false;
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    // const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //   .where('estado', '==', 1).get();

    // documentosSnapshot.forEach(doc => {
    //   const idDoc = doc.id
    //   documentos.push(idDoc);
    // });

    const documentosOCRSnapshotCargados = yield firebaseDatabase.
      collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${lote}/Documentos`)
      .get();

    documentosOCRSnapshotCargados.forEach(doc => {
      const documentData = doc.data()

      if (documentData.datosDocumento.estado === 0) {
        estado = 'Cargado en bucket'
      } else if (documentData.datosDocumento?.estado === 1) {
        estado = 'Orden de procesamiento'
      } else if (documentData.datosDocumento?.estado === 2) {
        estado = 'En procesamiento'
      } else if (documentData.datosDocumento?.estado === 3) {
        estado = 'Procesado'
      } else if (documentData.datosDocumento?.estado === 4) {
        estado = 'Procesado con error'
      }
      const dataDocument = {
        fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        name: documentData.datosDocumento.name,
        size: documentData.datosDocumento.tamano,
        id: doc.id,
        resumen: documentData.resumen ? documentData.resumen : '',
        estado: estado,
        lote: lote
      };
      if (documentData.datosDocumento.estado !== 3) {
        consultaData.push(dataDocument);
      }

      //consultaData.push(dataDocument);


    });


  } catch (error) {
    //console.log('intents', error)
    throw error;
  }

  yield put(getOCRDocumentosSuccess(consultaData))
  return (consultaData)
}


function* procesarDocs(action) {


  console.log("procesarDocs", action)
  let datosLote = action.value
  const user = getFromSession("currentUser");
  let resp = false;
  let response = false;

  yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
    docs.forEach(doc => {
      resp = doc.data();
    });
  });


  for (const doc of datosLote) {

    const LoteRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${doc.lote}`);
    LoteRefUpdate.update({
      estado: 1
    })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });
  }
  for (const doc of datosLote) {

    const LoteRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/`).doc(`${doc.lote}`);
    LoteRefUpdate.update({
      estado: 1
    })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });

    const docRefUpdate = firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${doc.lote}/Documentos/`).doc(doc.id);
    docRefUpdate.update({
      'datosDocumento.estado': 1
    })
      .then(() => {
        console.log('Documento actualizado correctamente.');
      })
      .catch((error) => {
        console.error('Error al actualizar el documento:', error);
      });


  }
  // let datosConsulta = {
  //   usuario: {
  //     nombre: resp.nombres,
  //     correo: resp.correo,
  //     company: resp.num_documento
  //   },
  //   company: resp.tipo_documento + resp.num_documento,
  //   documentosOcr: action.value,
  //   estado_doc: 1,
  //   estado_lote: 0,
  // }


  // console.log("DATOS CONSULTA", datosConsulta)

  // let body = datosConsulta

  // const headers = {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //   'Access-Control-Allow-Credentials': 'true',
  //   "Access-Control-Request-Headers": "*",
  //   'accept': "application/json, text/plain, */*",
  //   'Content-Type': 'application/json',
  //   'X-Requested-With': 'XMLHttpRequest',
  // }
  // const urlDespliegue = process.env.REACT_APP_CHATASESORES_PROCESAR_DOCUMENTO;
  // let procesarDoc = true;

  // // procesarDoc = yield call(axios.post, urlDespliegue, body, headers);

  // console.log("procesadar documentos ***", procesarDoc)




}


function* getMaxSize(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let sizeMaxDoc = false;
  let sizeMaxDocMB = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });

    const docRef = firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/configuration`).doc('MAX_SIZE_DOC');

    const maxSizeDoc = yield docRef.get();
    if (maxSizeDoc.exists) {
      sizeMaxDocMB = maxSizeDoc.data().value;
      sizeMaxDoc = sizeMaxDocMB * 1048576
      //console.log('Valor del campo:', sizeMaxDoc);
    } else {
      console.log('El documento no existe.');
    }
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  yield put(getOcrSizeSuccess({
    valueMB: sizeMaxDocMB,
    value: sizeMaxDoc
  }));
  return resp
}


function* getDocumentosProcesados(action) {
  console.log("lote getDocumentosProc", action)
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get().then(docs => {
        docs.forEach(doc => {
          resp = doc.data();
        });
      });
    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      // .where('estado', '==', 1)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const idDoc = doc.id
        documentos.push(idDoc);
      });
    });





    //for (const id of documentos) {
    // const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value}/Documentos`)
    //   .where('datosDocumento.estado', '==', 3).get();

    // documentosOCRSnapshot.forEach(doc => {
    //   const documentData = doc.data();
    //   const dataDocument = {
    //     lote: action.value,
    //     fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
    //     name: documentData.datosDocumento.name,
    //     size: documentData.datosDocumento.tamano,
    //     id: doc.id,
    //     resumen: documentData.respuesta.extraccion.textoFull ? documentData.respuesta.extraccion.textoFull : '',
    //   };
    //   consultaData.push(dataDocument);


    // });


    const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value}/Documentos`)
      .where('datosDocumento.estado', '==', 3)

      .get().then(docs => {
        docs.forEach(doc => {

          const documentData = doc.data();
          const dataDocument = {
            lote: action.value,
            fecha_cargue: documentData?.datosDocumento.fecha_cargue ? moment(documentData?.datosDocumento?.fecha_cargue?.toDate()).format("ll, h:mm:ss a") : '',
            name: documentData?.datosDocumento?.name,
            size: documentData?.datosDocumento?.tamano,
            id: doc.id,
            resumen: documentData?.respuesta?.texto_Full ? documentData?.respuesta?.texto_Full : '',
            fecha_proc: moment(documentData?.respuesta?.fecha_de_procesamiento?.toDate()).format("ll, h:mm:ss a")
          };
          consultaData.push(dataDocument);
        });
      });


    //}




    // try {


    // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/`).get().then((querySnapshot) => {
    //   querySnapshot.forEach((doc) => {
    //     nombreBot = doc.data().nombreChatBot;
    //     console.log("INTENT OMBRE BOT", nombreBot)
    //   });
    // });

    // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/productos/chatbots/${nombreBot}`)
    //   .get().then(docs => {
    //     docs.forEach(doc => {
    //       const botsData = doc.data();
    //       const intent = {
    //         nombreIntent: botsData.nombreIntent,
    //         fecha: botsData.fecha_creacion ? moment(botsData.fecha_creacion.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
    //         id: doc.id
    //       }
    //       intents.push(intent);
    //     });
    //   });

    // } catch (error) {
    //   //console.log('intents', error)
    //   throw error;
    // }
    // console.log("INTENTSSSSS", intents)

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosProcesadosSuccess(consultaData))

}

function* getDocumentosFallidos(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
      //.where('estado', '==', 1)
      .get();

    documentosSnapshot.forEach(doc => {
      const idDoc = doc.id
      documentos.push(idDoc);
    });

    //for (const id of documentos) {
    const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value}/Documentos`)
      .where('datosDocumento.estado', '==', 4).get();

    documentosOCRSnapshot.forEach(doc => {
      const documentData = doc.data();
      const dataDocument = {
        lote: action.value,
        fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        name: documentData.datosDocumento.name,
        size: documentData.datosDocumento.tamano,
        id: doc.id,
        resumen: documentData.resumen ? documentData.resumen : ''
      };
      consultaData.push(dataDocument);
    });
    //}
  } catch (error) {
    //console.log('intents', error)
    throw error;
  }
  yield put(documentosFallidosSuccess(consultaData))
}


function* mostrarDocumentosProcesados(action) {

  let user = getFromSession("currentUser");
  let dataVistaPrevia = [];
  let resp = false
  let dataDocument = false;
  let datosRender = {};
  let showDataDocument = [];
  let DataBD = false;
  let nuevoArreglo = false;
  const resultado = {};
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('DOCUMENT_FORM');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;

        } else {
          console.log('El documento no existe');
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }



  let arregloNew = {};
  let arregloNew2 = {};
  let arregloNew3 = {};
  let textoFull = false;
  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)

      .get().then(docs => {
        docs.forEach(doc => {

          if (doc.data().datosDocumento.name === action.value.name) {

            dataDocument = doc.data()?.respuesta.pagina
            textoFull = doc.data()?.respuesta.texto_Full
            // console.log("dataDocument", dataDocument)


            let separador2 = '-'
            let separador = '/';
            let dataPrueba = [];
            let position = false;
            dataDocument.forEach((objeto, index) => {

              if (position === false) position = objeto.value.posicion;
              if (objeto.value.posicion > position) {
                if (!(emptyJson(arregloNew) && emptyJson(arregloNew2) && emptyJson(arregloNew3)))
                  dataPrueba.push({
                    position,
                    data: {
                      checkbox: { ...arregloNew },
                      texto: { ...arregloNew2 },
                      campoUnico: { ...arregloNew3 },
                      //textoFull: textoFull,
                    }
                  })

                arregloNew = {};
                arregloNew2 = {};
                arregloNew3 = {};
                position = objeto.value.posicion;
              }

              if (objeto.etiqueta.includes(separador)) {
                //console.log("objeto", objeto)
                const etiqueta = objeto.etiqueta.split("/")[0];
                const nombreEtiqueta = objeto.etiqueta.split("/")[1];
                if (etiqueta) {

                  arregloNew[etiqueta] = arregloNew[etiqueta] || [];

                  for (const propiedad in objeto.value) {
                    //console.log("PROPIEDAD", propiedad)
                    if (propiedad === 'booleanValue') {
                      arregloNew[etiqueta].push({
                        [nombreEtiqueta]: objeto.value.booleanValue,
                      });
                    }
                  }

                }
              }
              else if (objeto.etiqueta.includes(separador2)) {
                const etiqueta = objeto.etiqueta.split("-")[0];
                if (etiqueta) {
                  arregloNew2[etiqueta] = arregloNew2[etiqueta] || [];

                  if (objeto.value.subdatos) {
                    arregloNew2[etiqueta].push(
                      objeto.value.subdatos.map((subdato) => ({
                        [subdato.Type]: subdato.mentiontext,
                      }))
                    );
                  }
                }
              } else {
                const etiqueta = objeto.etiqueta;
                if (objeto.value.texto !== '') {
                  arregloNew3[etiqueta] = arregloNew3[etiqueta] || [];
                  arregloNew3[etiqueta].push(objeto.value.texto)
                }
              }

              if (index + 1 === dataDocument.length) {
                dataPrueba.push({
                  position: objeto.value.posicion,
                  data: {
                    checkbox: { ...arregloNew },
                    texto: { ...arregloNew2 },
                    campoUnico: { ...arregloNew3 },
                    //textoFull: textoFull,
                  }
                })

                arregloNew = {};
                arregloNew2 = {};
                arregloNew3 = {};
                //textoFull = false;
              }
            });


            dataPrueba.push({
              position: 1000,
              data: {
                checkbox: {},
                texto: {},
                campoUnico: {},
                textoFull: textoFull,
              }
            })

            if (DataBD) {

              if (DataBD.length !== 0) {
                DataBD.forEach((element) => {

                  if (doc.data().respuesta?.tipoDeDocumento === element.tipoDocumento) {

                    element.campos.forEach((e) => {


                      let arraySubdatos = [];
                      for (const propiedad in resultado.texto) {
                        if (typeof resultado.texto[propiedad] === "object") {
                          for (const propiedadSub in resultado.texto[propiedad]) {
                            // Imprime el elemento de la matriz
                            resultado.texto[propiedad][propiedadSub].forEach((data) => {
                              //console.log("e.value, data", e.value, Object.keys(data).toString())
                              if (e.value === Object.keys(data).toString()) {

                                let valores = Object.values(data);

                                arraySubdatos.push(valores)

                              }
                            })
                          }
                        } else {
                          // Imprime el valor del objeto
                        }
                      }
                      console.log("arraySubdatos", arraySubdatos);

                      datosRender[e.value] = arraySubdatos
                      for (const propiedad in resultado) {

                        if (typeof resultado[propiedad] === "object") {
                          for (const propiedadSub in resultado[propiedad]) {
                            //console.log("e.value", e.value)
                            //console.log("propiedadSub", propiedadSub)
                            if (e.value === propiedadSub) {
                              datosRender[propiedadSub] = resultado[propiedad][propiedadSub];
                            }

                            //console.log("resultado.texto", JSON.stringify(resultado.texto))
                          }
                        } else if (e.value === propiedad) {
                          datosRender[propiedad] = resultado[propiedad];
                        }
                      }

                    });

                  }
                })
              }
            }
            if (Object.keys(datosRender).length === 0) {
              //datosRender = resultado;
              datosRender = dataPrueba;
            }

          }
        });
      });
    yield put(showDocumentosProcesadosSuccess(datosRender));
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }
}
function* downloadDoc(action) {
  console.log("URL PDF SAGA", action)
  try {
    // Obtén una referencia al almacenamiento de Firebase
    const storageRef = storage.refFromURL(action.value);

    // Obtén la URL de descarga del documento
    const downloadUrl = yield storageRef.getDownloadURL();

    // Crea un enlace temporal para descargar el documento
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'documento.pdf'; // Establece el nombre del archivo
    link.target = '_blank'; // Abre el enlace en una nueva pestaña

    // Simula un clic en el enlace para iniciar la descarga
    link.click();
  } catch (error) {
    console.error('Error al descargar el documento:', error);
  }

  yield put(downloadDocuSuccess())
}


function* crossDoc(action) {
  console.log("LOTESSS CROSS", action.value)
  let user = getFromSession("currentUser");
  let cruzar = false;
  let resp = false
  let cruce = false;
  let documentos = [];
  let consultaData = [];
  let showDocument = false;
  let valoresParaCruce = [];
  let DataBD = false;
  let valorCorrespondiente = false;
  let datosDocPrincipal = false;
  try {
    yield firebaseDatabase.collection('company').where('correo', '==', `${user.mail}`).get().then(docs => {
      docs.forEach(doc => {
        resp = doc.data();
      });
    });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  try { //.collection(`company/${company}/configuration`).doc(`${document}`)
    const documentRef = yield firebaseDatabase.collection('company').doc(`${resp.tipo_documento + resp.num_documento}`).collection('configuration').doc('CRUCE_CONF');

    // Obtén los datos del documento
    yield documentRef.get()
      .then((doc) => {
        if (doc.exists) {
          DataBD = doc.data().value;
        }
      })
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  //console.log("DataBD", DataBD)

  try {
    yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .doc(action.value.id)
      .get().then(doc => {
        datosDocPrincipal = {
          nombre: doc.data().datosDocumento.name,
          tipoDocumento: doc.data().respuesta?.tipoDeDocumento
        }
        const BdTipoDocumento = doc.data().respuesta?.tipoDeDocumento;
        //console.log("dataDB", DataBD)
        if (DataBD) {

          DataBD.forEach((element) => {
            //console.log("elment DATABD", element)

            Object.values(element).forEach((resp) => {
              //console.log("resp elment DATABD", resp)
              valoresParaCruce.push(resp)
              if (resp === BdTipoDocumento) {
                cruzar = true;
              }

            })
          })
        }
      });
  } catch (error) {
    //console.log('uploadImage', error)
    throw error;
  }

  valoresParaCruce = valoresParaCruce.filter((valor, indice, self) => self.indexOf(valor) === indice);

  //console.log("ARRAY DE CRUCE", valoresParaCruce)


  const documentosSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`)
    //.where('estado', '==', 1)
    .get();

  documentosSnapshot.forEach(doc => {
    const idDoc = doc.id
    documentos.push(idDoc);
  });

  if (cruzar) {
    //for (const id of documentos) {
    const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      //.where('respuesta.extraccion.id', '==', cruce)
      .get();

    documentosOCRSnapshot.forEach(doc => {
      const documentData = doc.data();
      if (documentData.respuesta) {
        documentData.respuesta.pagina.forEach((resp) => {
          valoresParaCruce.forEach((val) => {
            if (resp.etiqueta === val) {
              //console.log("resp.etiqueta", resp)
              valorCorrespondiente = resp.value.texto;
            }
            if (resp.value.subdatos) {
              resp.value.subdatos.forEach((subadto) => {
                Object.values(subadto).forEach((dato) => {

                  //console.log("subadto", dato, val, subadto.mentiontext);
                  if (dato === val) {
                    //console.log("coinciden")
                    valorCorrespondiente = subadto.mentiontext;
                  }
                })
              })
            }
          })
        })
      }

      //console.log("documentData", documentData)
    });
    //}

    console.log("valorCorrespondiente", valorCorrespondiente)

    //for (const id of documentos) {
    const ref = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .get().then((snapshot) => {
        snapshot.forEach(doc => {

          const datos = doc.data();
          //console.log("datos", doc.data())
          if (datos?.respuesta?.pagina) {
            const elementos = datos.respuesta.pagina.map((pagina) => {

              //  console.log("pagina", pagina)

              if (pagina.value.texto === valorCorrespondiente) {
                consultaData.push({
                  lote: action.value.lote,
                  fecha: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                  name: datos?.datosDocumento.name,
                  size: datos?.datosDocumento.tamano,
                  id: doc.id,
                  resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                  estado: datos?.datosDocumento.estado
                });
              }
              Object.values(pagina).forEach((dato) => {
                if (dato.subdatos) {
                  dato.subdatos.forEach((subdato) => {
                    if (subdato.mentiontext === valorCorrespondiente) {
                      consultaData.push({
                        lote: action.value.lote,
                        fecha: datos?.respuesta?.fecha_de_procesamiento ? moment(datos?.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                        name: datos?.datosDocumento.name,
                        size: datos?.datosDocumento.tamano,
                        id: doc.id,
                        resumen: datos?.respuesta?.texto_Full ? datos?.respuesta?.texto_Full : '',
                        estado: datos?.datosDocumento.estado
                      });
                    }
                  })
                }

              })
            });
          }
        })
      });
    // const documentosOCRSnapshot = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
    //   .where('respuesta.extraccion.id', '==', valorCorrespondiente)
    //   .get();
    //let documentosOCRSnapshot = false;



    //for (let valor of valoresParaCruce) {
    //let estado = false;
    //   console.log("valorCorrespondiente", valorCorrespondiente)
    //console.log("id doc", id)
    const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${action.value.lote}/Documentos`)
      .get()
      .then((docs) => {
        const documentosFiltrados = [];
        docs.forEach(doc => {
          const data = doc.data();
          //console.log("data", data)

          const pagina = data?.respuesta?.pagina;

          for (let valor of valoresParaCruce) {
            if (valor === data?.respuesta?.tipoDeDocumento) {
              if (pagina && Array.isArray(pagina)) {
                //console.log("pagina", pagina)
                // Buscamos en todos los elementos de respuesta.pagina
                for (const item of pagina) {
                  if (item.value && item.value.subdatos && Array.isArray(item.value.subdatos)) {
                    //console.log("item.value.subdatos", item.value.subdatos)
                    const subdato = item.value.subdatos.find(sub => sub.mentiontext === valorCorrespondiente);
                    if (subdato) {
                      consultaData.push({
                        lote: action.value.lote,
                        fecha_cargue: data.respuesta?.fecha_de_procesamiento ? moment(data.respuesta?.fecha_de_procesamiento.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
                        name: data.datosDocumento.name,
                        size: data.datosDocumento.tamano,
                        id: doc.id,
                        resumen: data.respuesta?.texto_Full ? data.respuesta?.texto_Full : '',
                        estado: data.datosDocumento.estado
                      });
                      //break; // Terminamos la búsqueda en esta página al encontrar una coincidencia
                    }
                  }
                }
              }
            }
          }

        });
        // documentosFiltrados contiene los documentos que cumplen con la condición
      }).catch((error) => {
        console.log("error", error)
      });

    // const documentosOCRSnapshotCargados = yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`)
    // //.where('datosDocumento.estado', '==', 1)
    // .get();




    // yield firebaseDatabase.collection(`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${id}/Documentos`).where("pagina.value.subdatos", "array-contains", { Type: 'Nombre_Menor', mentiontext: valorCorrespondiente })
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       // Aquí puedes acceder a los documentos que cumplen con la condición
    //       console.log("Documento encontrado:", doc.id, doc.data());
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error al realizar la consulta:", error);
    //   });






    // Recorremos el campo pagina
    // Comparamos el elemento con el valor almacenado en la variable
    //return pagina === valor;

    // Imprimimos el resultado de la comparación

    //}




    // documentosOCRSnapshot.forEach(doc => {
    //   const documentData = doc.data();
    //   const dataDocument = {
    //     lote: id,
    //     fecha: documentData.datosDocumento.fecha_cargue ? moment(documentData.datosDocumento.fecha_cargue.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
    //     name: documentData.datosDocumento.name,
    //     size: documentData.datosDocumento.tamano,
    //     id: doc.id,
    //     resumen: documentData.resumen ? documentData.resumen : ''
    //   };
    //   consultaData.push(dataDocument);
    //   if (consultaData.length !== 0) {
    //     showDocument = true
    //   }

    //   //console.log("documentData", documentData)
    // });
    //}
  }

  //console.log("consultaData", consultaData)

  if (consultaData.length !== 0) {
    showDocument = true
  }






  //console.log("DOCUMENTOS CRUCE", consultaData)



  yield put(crossDocSuccess({
    consultaData: consultaData,
    showDocument: showDocument,
    datosDocPrincipal: datosDocPrincipal
  }))
}

function* getLotes(action) {
  const user = getFromSession("currentUser");
  let resp = false;
  let documentos = [];
  let consultaData = [];
  let nroCargue = 0;
  let estado = false;
  let lotes = [];
  try {
    const companySnapshot = yield firebaseDatabase.collection('company')
      .where('correo', '==', `${user.mail}`).get();

    companySnapshot.forEach(doc => {
      resp = doc.data();
    });

    const documentosSnapshot = yield firebaseDatabase.collection
      (`/company/${resp.tipo_documento + resp.num_documento}/documentosOCR`).get();

    documentosSnapshot.forEach(doc => {
      nroCargue = nroCargue + 1
      const documentData = doc.data()
      lotes.push(doc.id)
      if (documentData.estado === 0) {
        estado = 'Cargado en bucket'
      } else if (documentData.estado === 1) {
        estado = 'Orden de procesamiento'
      } else if (documentData.estado === 2) {
        estado = 'En procesamiento'
      } else if (documentData.estado === 3) {
        estado = 'Procesado'
      } else if (documentData.estado === 4) {
        estado = 'Procesado con error'
      }

      const dataDocument = {
        nroLote: 'Lote Número ' + nroCargue,
        lote: doc.id,
        fecha: documentData.fecha_creacion ? moment(documentData.fecha_creacion.toDate()).format("ll, h:mm:ss a") : moment(new Date()).format("ll, h:mm:ss a"),
        cantidad: documentData.nro_documentos,
        estado: estado,
      };
      consultaData.push(dataDocument);

    });
    let consecutivo = 1; // Inicializamos el contador de consecutivo
    const objetosAEliminar = [];
    for (let i = 0; i < consultaData.length; i++) {
      const objeto = consultaData[i];
      let nro_documentos = 0; // Inicializamos el contador para cada lote

      for (const lote of lotes) {
        if (lote === objeto.lote) {
          console.log(`El lote ${lote} se encuentra en el segundo array con otroCampo: ${objeto.lote}`);

          const docRefUpdate = yield firebaseDatabase.collection(`company/${resp.tipo_documento + resp.num_documento}/documentosOCR/${lote}/Documentos/`);

          yield docRefUpdate.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              if (data.datosDocumento.estado === 3) {
                nro_documentos = nro_documentos + 1;
                //console.log(data); // Muestra los datos del documento en la consola
              }
            });
          }).catch(function (error) {
            console.error("Error al obtener documentos: ", error);
          });



          // Actualizamos la cantidad de documentos en el objeto en consultaData
          objeto.cantidad = nro_documentos;

          if (nro_documentos === 0) {
            // Si nro_documentos es igual a 0, marcamos el objeto para eliminarlo posteriormente
            objetosAEliminar.push(objeto);
          } else {
            // Si nro_documentos no es igual a 0, agregamos la propiedad "consecutivo" al objeto
            objeto.nroLote = consecutivo;
            // Incrementamos el contador de consecutivo
            consecutivo++;
          }
        }
      }
    }
    for (const objetoEliminar of objetosAEliminar) {
      consultaData.splice(consultaData.indexOf(objetoEliminar), 1);
    }

  } catch (error) {
    //console.log('intents', error)
    throw error;
  }


  yield put(getLotesSuccess(consultaData))
}




export function* watchUsers() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(LOAD_MENU, loadMenuImageOCR);
  yield takeLatest(COMPANY_DATA, companyData);
  yield takeLatest(UPLOAD_BUCKET, uploadToBucket);
  yield takeLatest(OCR_CREATE, ocrCreate);
  yield takeLatest(OCR_ACTIVO, isOcrEnabled);
  yield takeLatest(GET_OCR_DOC, getOCRDocumentos);
  yield takeLatest(PRO_OCR_DOC, procesarDocs);
  yield takeLatest(GET_OCR_SIZE, getMaxSize);
  yield takeLatest(GET_DOC_PROC, getDocumentosProcesados);
  yield takeLatest(GET_DOC_FALL, getDocumentosFallidos);
  yield takeLatest(SHOW_DOC_PROC, mostrarDocumentosProcesados);
  yield takeLatest(DOWNLOAD_DOC, downloadDoc);
  yield takeLatest(CROSS_DOC, crossDoc);
  yield takeLatest(GET_LOTES, getLotes);










}
