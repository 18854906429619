import { firebaseDatabase } from "./firebase";


export function* consultarEmpresa(company) {
    try {
        let resp = false;
        yield firebaseDatabase.collection('company').where('cuenta', '==', company).get().then(docs => {
            docs.forEach(doc => {
                resp = doc;
            });
        });

        return resp
    } catch (error) {
        console.log('company.consultarEmpresa', error)
        throw error;
    }

}

/**
 * Funcion para validar si un login existe en una empresa.
 * @param {*} company 
 */
export function* agenteByCompany(company, uid) {
    console.log(company, uid)
    try {

        let agente = false;
        yield firebaseDatabase.collection(`company/${company}/usuarios`).where('uid', '==', uid).get().then(docs => {
            docs.forEach(doc => {
                agente = doc;
            });
        });
        return agente;
    } catch (error) {
        console.log('company.agenteByCompany', error)
        throw error;
    }

}