import React, { Component, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap';
import {
    GET_USERS, LOAD_MENU
} from '../constants';
import { getMenuItemsChatBot, getUsers } from "../selectors";
import { DataTable } from "primereact/datatable";
import { Paginator } from 'primereact/paginator';
import Tooltip from '@material-ui/core/Tooltip';

import { Column } from "primereact/column";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button } from "@material-ui/core";
import { Paper } from "@material-ui/core";




const ComponentTableDoc = (props) => {

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    // Estado para controlar el número de filas por página
    const [basicFirst, setBasicFirst] = useState(0);
    const [basicRows, setBasicRows] = useState(10);

    const [firstLastState, setFirstLastState] = useState(2);
    const [listaPaginada, setListaPaginada] = useState(false);

    const handlePreview = (rowData) => {
        let datosDocumento = {}
        console.log("rowData", rowData);
        setRowClick(rowData);
        const pdfUrl = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${props.id}/productos/ocr/${rowData.name}`
        //console.log("pdfUrl", pdfUrl)
        props.pdfData(pdfUrl)
        datosDocumento = {
            lote: rowData.lote,
            name: rowData.name
        }
        props.idDocumento(datosDocumento)
    };

    const handleDownload = (rowData) => {
        // console.log("PROPSS", props)
        setRowClick(rowData);
        const pdfUrl = `${process.env.REACT_APP_CHATASESORES_AVATAR_IMAGE}/${props.id}/productos/ocr/${rowData.name}`
        console.log("PROPS.id", props.id)
        console.log("pdfUrl descarga", pdfUrl)
        props.downloadDoc(pdfUrl)

    };

    const handleCross = (rowData) => {
        setRowClick(rowData);
        console.log("handleCros", rowData);
        props.cruceData(rowData)
    };

    const renderStatus = (rowData) => {
        if (props.labelTable.columna4 === 'Acciones' && props.title === 'Documentos Procesados') {
            return (
                <>
                    <Tooltip title="Ver Documento" arrow>
                        <IconButton onClick={() => handlePreview(rowData)}>
                            <RemoveRedEyeIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Descargar Documento" arrow>
                        <IconButton onClick={() => handleDownload(rowData)}>
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Cruzar Documento" arrow>
                        <IconButton onClick={() => handleCross(rowData)}>
                            <ShuffleIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        } else if (props.labelTable.columna4 === 'Motivo' && props.title === 'Documentos Procesados') {
            return <span className="status-rejected">Error procesando el documento</span>;
        } else {
            return (
                <>
                    <IconButton onClick={() => handlePreview(rowData)}>
                        <RemoveRedEyeIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDownload(rowData)}>
                        <CloudDownloadIcon />
                    </IconButton>
                </>
            )

        }
    }

    const YourDataTable = ({ data }) => {
        // Función para dividir el texto después de ciertos caracteres
        const insertLineBreaks = (text, maxChars) => {
            if (text.length <= maxChars) {
                return text;
            }
            return text.match(new RegExp(`.{1,${maxChars}}`, 'g')).join('\n');
        };
    }

    const paginarConvsFiltradas = (event) => {
        const { totalConvsFiltradas } = this.props
        //console.log('totalConvsFiltradas', totalConvsFiltradas.length);

        let listaPaginada;

        if (event.page !== 0) {
            let page = event.page + 1;
            let last = event.rows * page;
            listaPaginada = totalConvsFiltradas.slice(event.first, last)
        } else {
            listaPaginada = totalConvsFiltradas.slice(event.first, event.rows)
        }
        //console.log("listaFiltradaCortada", listaPaginada);
        // this.setState({
        //     listaPaginada: listaPaginada,
        //     basicFirst: event.first,
        //     basicRows: event.rows
        // })

        setListaPaginada(listaPaginada);
        setBasicFirst(event.first)
        setBasicRows(event.rows)
    }

    const onBasicPageChange = (event) => {
        const { paginarDocumentosProcesados, seleccionRangoBusqueda, data,
            convsCerradasPaginadas, totalConvsFiltradas } = props

        if (totalConvsFiltradas) {
            this.paginarConvsFiltradas(event)

        } else {
            let ultimoDocProc = false;
            let primerDocProc = false;
            let nextPage = firstLastState < event.first;


            setFirstLastState(event.first);
            setListaPaginada(false);


            if (convsCerradasPaginadas) {
                if (convsCerradasPaginadas.length === event.rows && event.first > 0) {
                    //console.log('entro a paginar conversacionesPaginadas');
                    ultimoDocProc = convsCerradasPaginadas[event.rows - 1];
                    primerDocProc = convsCerradasPaginadas[0];
                } else {
                    //console.log('entro a ultima pagina');
                    ultimoDocProc = convsCerradasPaginadas[convsCerradasPaginadas.length - 1];
                    primerDocProc = convsCerradasPaginadas[0];
                }
            } else if (!convsCerradasPaginadas && data) {
                if (data.length === event.rows) {
                    //console.log('entro a paginar conversations');
                    ultimoDocProc = data[event.rows - 1];
                    primerDocProc = data[0];
                }
            }
            // this.setState({
            //     basicFirst: event.first,
            //     basicRows: event.rows
            // });

            setBasicFirst(event.first)
            setBasicRows(event.rows)

            if (nextPage) {
                paginarDocumentosProcesados({ event, "ultimoDocProc": ultimoDocProc, nextPage, seleccionRangoBusqueda });
            } else {
                paginarDocumentosProcesados({ event, "ultimoDocProc": primerDocProc, nextPage, seleccionRangoBusqueda });
            }
        }
    }
    const NameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {rowData.nombre}

                {/* Renderizar el CircularProgress junto al nombre si isLoading es true */}
                {props.labelTable.columna4 === 'Motivo' ?
                    <>
                        {rowData.name.length <= 20 ?
                            <>
                                {rowData.name}
                                <HighlightOffIcon size={20} style={{ marginLeft: 10, color: 'red', borderRadius: '50%', padding: '5px' }} />
                            </>
                            :
                            <>
                                {rowData.name.match(/.{1,20}/g).join('\n')}
                                <HighlightOffIcon size={20} style={{ marginLeft: 10, color: 'red', borderRadius: '50%', padding: '5px' }} />
                            </>
                        }
                    </>
                    :
                    <>
                        {rowData.name.length <= 20 ?
                            <>
                                {rowData.name}
                                <DoneOutlineIcon size={60} style={{ marginLeft: 10, color: 'green', borderRadius: '50%', padding: '5px' }} />
                            </>
                            :
                            <>
                                {rowData.name.match(/.{1,20}/g).join('\n')}
                                <DoneOutlineIcon size={60} style={{ marginLeft: 10, color: 'green', borderRadius: '50%', padding: '5px' }} />
                            </>
                        }
                    </>
                }
            </React.Fragment>
        );
    };

    const ResumenBodyTemplate = (rowData) => {

        return (
            <React.Fragment>
                {rowData.resumen.slice(0, 100)}...
            </React.Fragment>
        );
    };

    const actionButtons = (rowData) => {
        setRowClick(rowData);
        console.log(rowClick);
        return (
            <div>
                <IconButton>
                    <RemoveRedEyeIcon />
                </IconButton>
                <IconButton >
                    <CloudDownloadIcon />
                </IconButton>
            </div>
        )
    };

    return (
        <div className="tabla-crear-pregunta">
            {/* {this.state.modalEliminar && <PDFViewer
                    handlerAceptar={()=> this.onDelete(this.state.valRow)}
                    value={this.state.valRow} 
                    handlerCancelar={this.onModalElimarClose}
                    />
                } */}
            {/* {!props.documentDataSelector &&

                <div className="loading-wrapper">
                    <h1>
                        Cargando...
                    </h1>
                </div>

            } */}
            <h2 style={{ "padding": "20px" }}>{props.title}</h2>

            {props.selectorDatosDocPrincipal ?
                <div style={{ "font-size": "15px", "display": "flex", "flex-direction": "column", "align-items": "baseline" }}>
                    <p style={{ "font-size": "18px" }}>Documento Principal</p>
                    {
                        Object.entries(props.selectorDatosDocPrincipal).map(([objKey, objValue]) => (
                            <>
                                <b>
                                    <span>{objKey}:</span>
                                </b>
                                <span>
                                    {objValue}
                                </span>
                            </>
                        ))
                    }
                </div>
                : ''}
            <DataTable value={props.dataPaginada ? props.dataPaginada : props.data} selectionMode={rowClick ? null : 'checkbox'}
                 /*paginator*/ rowsPerPageOptions={[5, 10, 20]}
                selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                <Column field={'fecha_cargue'} header={props.labelTable.columna1} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>

                {props.labelTable.columna5 && (
                    <Column field={'fecha_proc'} header={props.labelTable.columna5} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                )}
                <Column field={'name'} header={props.labelTable.columna2} body={NameBodyTemplate} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>

                {/* <Column field={'lote'} header={props.labelTable.columna6} sortable style={{ 'width': 'auto' }}></Column>
                 */}
                <Column field={'id'} header={props.labelTable.columna7} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>

                <Column field={'resumen'} header={props.labelTable.columna3} body={ResumenBodyTemplate} sortable style={{ 'width': 'auto', 'font-size': '12px' }}></Column>
                {/* <Column field={'id'} header={'Id'} sortable style={{ 'width': 'auto' }}></Column> */}
                <Column header={props.labelTable.columna4}

                    body={renderStatus} style={{ width: 'auto' }}></Column>
            </DataTable>
            <Paginator first={basicFirst} rows={basicRows} rowsPerPageOptions={[10, 20, 30, 50, 100]}
                template="FirstPageLink PrevPageLink NextPageLink RowsPerPageDropdown CurrentPageReport"
                currentPageReportTemplate="Página {currentPage} de la lista de documentos procesados"
                onPageChange={onBasicPageChange} totalRecords={1000000}>

            </Paginator>

            {/* <Button onClick={() => props.procesarDocs(selectedProducts)} color='primary' variant="contained">Procesar documentos</Button> */}

        </div>


    );

}


export default ComponentTableDoc;