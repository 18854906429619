import React, { Component } from "react";
import "./style.css";
import { getFromSession } from "../../../controller/session";
import { TextField, Typography, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class ComponentLista extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: '',
            tituloLista: '',
            descripcion: '',
            listItem: [],
            resp: [],
            optiones: [],

        }
    }

    onListaRespuesta = () => {
        this.state.listItem.push(this.state.item)
        console.log("lista respuesta", this.state.listItem)
        this.setState({ item: '' })
        this.setState({
            resultado: {
                options: this.state.listItem
            }
        })
    }

    onAddItem = () => {
        this.onListaRespuesta()
        //this.onTextHandler(this.state.item)
        this.item = ''
    }

    onEnterClick = (e) => {
        if (e.key === 'Enter') {
            //this.onTextHandler(this.state.item)
            this.onListaRespuesta()
            this.item = ''
        }

        console.log("list", this.state.resp)
    }

    onFraseClick = (e) => {
        this.setState({ item: e.target.value })
    }

    onTituloClick = (e) => {
        //this.onTextHandler(e.target.value)
        this.setState({ tituloLista: e.target.value})

    }

    onDescripcionClick = (e) => {
        this.setState({ descripcion: e.target.value })
        //this.onTextHandler(e.target.value)

    }

    editFrase = (event) => {
        let listaFrasesLocal = this.state.listItem
        let idFrase = event.target.id?.split("-")[1]
        listaFrasesLocal[+idFrase] = event.target.value
        this.setState({ listItem: listaFrasesLocal })
    }
    deleteFrase = (index) => {
        let listaFrasesLocal = this.state.listItem
        let newFrasesList = []
        listaFrasesLocal.map((item, i) => {
            if (i !== index) {
                newFrasesList.push(item)
            }
        })
        this.setState({ listItem: newFrasesList })

    }

    onTextHandler = (payload) => {
        console.log("ontextHandler PAYLOAD", payload)
        const { onChange, index } = this.props;
        this.state.listItem.map((item, i) => {
            this.state.resp.push({
                title: item,
                type: 'text',
            });
        })

        this.state.optiones.push({
            title: this.state.tituloLista,
            body: this.state.descripcion,
            options: this.state.listItem
        })
        const arrayDialogflow = {
            payload: {
                fields: {
                    botonGlobal: {
                        stringValue: "Ver opciones",
                        kind: "stringValue"
                    },
                    url: {
                        stringValue: " ",
                        kind: "stringValue"
                    },
                    title: {
                        stringValue: this.state.tituloLista,
                        kind: "stringValue"
                    },
                    type: {
                        stringValue: "list",
                        kind: "stringValue"
                    },
                    body: {
                        stringValue: this.state.descripcion,
                        kind: "stringValue"
                    },
                    items: {
                        listValue: {
                            values: [
                                {
                                    structValue: {
                                        fields: {
                                            title: {
                                                stringValue: this.state.tituloLista,
                                                kind: "stringValue"
                                            },
                                            subtitle: {
                                                stringValue: this.state.descripcion,
                                                kind: "stringValue"
                                            },
                                            options: {
                                                listValue: {
                                                    values: []


                                                },
                                                kind: "stringValue"

                                            }

                                        }
                                    },
                                    kind: "structValue"
                                }
                            ]
                        },
                        kind: "listValue"
                    }
                }
            },
            platform: "PLATFORM_UNSPECIFIED",
            message: "payload"
        }

        //let optionsDialogflow = []
        console.log("LISTITEM **********", this.state.listItem)
        this.state.listItem.map((item, i) => {
            let objObtions = {
                structValue: {
                    fields: {
                        type: {
                            stringValue: "text",
                            kind: "structValue"
                        },
                        title: {
                            stringValue: item,
                            kind: "structValue"
                        },
                        postbackText: {
                            stringValue: item,
                            kind: "structValue"
                        },
                        imagen: {
                            stringValue: "",
                            kind: "structValue"
                        },
                        description: {
                            stringValue: "",
                            kind: "structValue"
                        }
                    }
                }
            }
            arrayDialogflow.payload.fields.items.listValue.values[0].structValue.fields.options.listValue.values.push(
                objObtions
            )
        })
        let lastOption = {
            structValue: {
                fields: {
                    type: {
                        stringValue: "text",
                        kind: "structValue"
                    },
                    title: {
                        stringValue: payload,
                        kind: "structValue"
                    },
                    postbackText: {
                        stringValue: payload,
                        kind: "structValue"
                    },
                    imagen: {
                        stringValue: "",
                        kind: "structValue"
                    },
                    description: {
                        stringValue: "",
                        kind: "structValue"
                    }
                }
            }
        }
        // arrayDialogflow.payload.fields.items.listValue.values[0].structValue.fields.options.listValue.values.push(
        //     lastOption
        // )
         //   console.log("arrayDialogflow***", arrayDialogflow)



        onChange({
            value: {
                botonGlobal: 'Ver opciones',
                title: this.state.tituloLista,
                subtitle: this.state.descripcion,
                items: this.state.optiones,
                dialogflow: arrayDialogflow,
                type: 'list'

            }, index, type: 'list'
        })

    }
    componentDidMount() {
        const { respuestaList } = this.props
        //console.log("respuestaList", respuestaList.items[0].options)

        if (respuestaList && respuestaList.type === 'list') {
            const listRespuestaBD = [];
            respuestaList.items[0].options.map((item, i) => {
                listRespuestaBD.push(item)
            }

            )
            //console.log("listRespuestaBD******", listRespuestaBD)

            this.setState({
                listItem: listRespuestaBD
            })

            this.setState({
                tituloLista: respuestaList.title,
                descripcion: respuestaList.subtitle,
            })
        }
    }

    render() {
        const { label, placeholder } = this.props;
        const user = getFromSession("currentUser");

        return (
            <div className="formGroup-button">
                <TextField
                    placeholder="Agrege el titulo de la lista"
                    onChange={this.onTituloClick}
                    value={this.state.tituloLista} />

                <TextField placeholder="Agrege la descripcion de la lista"
                    onChange={this.onDescripcionClick}
                    value={this.state.descripcion} />

                <Tooltip
                    title={
                        <React.Fragment >
                            <Typography className="title-tooltip">Presione la tecla "ENTER" para cargar la respuesta en la lista</Typography>

                        </React.Fragment>
                    }
                >
                    <TextField placeholder={placeholder}
                        onKeyDown={this.onEnterClick}
                        onChange={this.onFraseClick}
                        value={this.state.item} />
                </Tooltip>


                {this.state.listItem?.map((item, i) => {
                    return (
                        <div className="text-field-lista" key={i}>
                            <TextField id={`textfield-${i}`} value={item} onChange={this.editFrase}>  </TextField> <DeleteIcon onClick={() => { this.deleteFrase(i) }} />
                        </div>
                    )
                })}
                <div className="botones-respuesta-lista" onClick={() => { this.onAddItem() }}>
                    <AddCircleIcon color='primary' variant="contained" />
                    Agregar elemento de lista
                </div>

                <div className="Button" onClick={() => { this.onTextHandler(this.state.item) }}>
                    <Button color='primary' variant="contained" > Cargar lista </Button>
                </div>
            </div>

        );
    }
}


export default ComponentLista;