import { createStructuredSelector } from 'reselect';
export const getMessageForm = (state) => state.localReducer.messageForm;
export const getSelectedForm = (state) => state.localReducer.selectedForm;
export const getConversationData = (state) => state.localReducer.conversationData;
export const getListFAQ = (state) => state.localReducer.listFAQ;

/* Lista de conversaciones pura de firebase */
export const getTotalConversationsList = (state) => state.localReducer.totalConversationslist;
/* Lista de conversaciones para la pantalla */
export const getConversations_ = (state) => state.localReducer.conversations;
export const getConversationsListFiltered = (state) => state.localReducer.conversationsListFiltered;

export const getItemsPerPage = (state) => state.localReducer.itemsPerPage;
export const getPagination = (state) => state.localReducer.pagination;
export const getPage = (state) => state.localReducer.page;

export const getPlaySound = (state) => state.localReducer.playSound;
export const getPlaySoundConv = (state) => state.localReducer.playSoundConv;
export const usuariosConectados = (state) => state.localReducer.usuariosConectados;
export const usuariosDisplay = (state) => state.localReducer.usuariosDisplay;
export const verPanelTransfer = (state) => state.localReducer.verPanelTransfer;

export const objTemas = (state) => state.localReducer.objTemas;
export const temasDisplay = (state) => state.localReducer.temasDisplay;

export const objEstados = (state) => state.localReducer.objEstados;
export const estadosDisplay = (state) => state.localReducer.estadosDisplay;

export const objAgentes = (state) => state.localReducer.objAgentes;
export const agentesDisplay = (state) => state.localReducer.agentesDisplay;

export const objFaqs = (state) => state.localReducer.objFaqs;
export const faqsDisplay = (state) => state.localReducer.faqsDisplay;

export const verPanelTemas = (state) => state.localReducer.verPanelTemas;
export const verPanelAdjuntar = (state) => state.localReducer.verPanelAdjuntar;
export const verPanelFaqs = (state) => state.localReducer.verPanelFaqs;

export const showSpinnerAdjuntar = (state) => state.localReducer.spinnerAdjuntar;
export const showSpinnnerTemas = (state) => state.localReducer.spinnerTemas;
export const showSpinnerFaqs = (state) => state.localReducer.spinnerFaqs;
export const getOpenSpinner = (state) => state.localReducer.openSpinner;

export const showToast = (state) => state.localReducer.toastMensaje;

export const toastDetail = (state) => state.localReducer.toastDetail;

export const showSpinner = (state) => state.localReducer.spinnerBoolean;
export const sessionTimeoutSelector = (state) => state.localReducer.sessionTimeout;

export const convMensajes = (state) => state.localReducer.convMensajes;
export const infoConv = (state) => state.localReducer.infoConv;
export const verPanelPrint = (state) => state.localReducer.verPanelPrint;
export const getTransferenciasConv = (state) => state.localReducer.listaTransferencias;
export const getNotasOcultasConv = (state) => state.localReducer.listaNotas;

export const showTransferencias = (state) => state.localReducer.showTransferencias;
export const showNotasOcultas = (state) => state.localReducer.showNotasOcultas;
export const showInfoGeneral = (state) => state.localReducer.showInfoGeneral;
export const modalNotasOcutas = (state) => state.localReducer.modalNotasOcutas;
export const conversacionesPaginadas = (state) => state.localReducer.conversacionesPaginadas;
export const getConsultaPaginada = (state) => state.localReducer.consultaPaginada;
export const getListFaqs = (state) => state.localReducer.listFaqs;
export const getConvsFiltradas = (state) => state.localReducer.convsFiltradas;
export const getConsultaBigQuery = (state) => state.localReducer.consultaBigQuery;
export const getManageSpinerState = (state) => state.localReducer.manageSpinerState;

export const getConversationDataDisplay = (state) => {
    if (state.localReducer.conversationData && state.localReducer.conversationData.conv && state.localReducer.conversationData.conv.data()) {
        return state.localReducer.conversationData.conv.data();
    } else {
        return false;
    }
}

export const getConversationId = (state) => {
    if (state.localReducer.conversationData.conv) {
        return state.localReducer.conversationData.conv.id;
    } else {
        return false;
    }
}

export const getMessagesDisplay = (state) => {
    if (state.localReducer.conversationData) return state.localReducer.conversationData.mensajes;
}


export const conversationsSelector = createStructuredSelector({
    conversations: getConversations_,
    conversationData: getConversationData,
    listFAQ: getListFAQ,
    conversationDataDisplay: getConversationDataDisplay,
    messagesDisplay: getMessagesDisplay,
    totalConversationslist: getTotalConversationsList
});

export const menuItems = (state) => state.localReducer.menuItems;