import { consultaProperty } from "./configuration";
const https = require('https');
const requestFunc = require('request');

export function* procesarMensajeFacebook(companyId, senderId, mensaje) {
    try {    
        let messageResponse = {};
        const facebookToken = yield getFacebookProps(companyId, 'FACEBOOK_TOKEN');
            messageResponse = {
                clienteFacebok: {
                    id: senderId
                },
                tokenData: facebookToken
            }

            const respDialogFlow = mensaje; 

            if (respDialogFlow.type == 'text') {
                messageResponse.messageData = {
                    recipient: {
                        id: senderId
                    },
                    message: {
                        text: respDialogFlow.text
                    }
                }
            }
            else if (respDialogFlow.type == 'image') {
                messageResponse.messageData = {
                    recipient: {
                        id: senderId
                    },
                    message: {
                        attachment: {
                            type: "template",
                            payload: {
                                template_type: "generic",
                                elements: [{
                                title: "Image",
                                subtitle: "Click para maximizar.",
                                image_url: respDialogFlow.url
                                }]
                            }
                        }
                    }
                }
            }
            else if (respDialogFlow.type == 'file' || respDialogFlow.type == 'audio' || respDialogFlow.type == 'video') {
                let title = respDialogFlow.filename ? respDialogFlow.filename : respDialogFlow.caption;
                title = title ? title : 'Click en descargar para reproducir';
                
                messageResponse.messageData = {
                    recipient: {
                        id: senderId
                    },
                    message: {
                        attachment: {
                            type: "template",
                            payload: {
                                template_type: "generic",
                                elements: [{
                                    title: `${title}`,
                                    subtitle: "",
                                    buttons: [
                                        {
                                            type: "web_url",
                                            title: "Descargar",
                                            url: respDialogFlow.url,
                                        }
                                    ]
                                }]
                            }
                        }
                    }
                }
            }

            enviarMensajeFacebook(messageResponse.tokenData, messageResponse.messageData);
    } catch(err ){
        console.error('facebook.procesarMensajeUser:' + err);
        throw err;
    }
}

async function enviarMensajeFacebook(token, messageData) {
    try {
        requestFunc({
            uri: "https://graph.facebook.com/v2.6/me/messages",
            qs: {
                access_token: token
            },
            method: 'POST',
            json: messageData
        }, (error, response, data) => {
            if (error) {
                console.error('it was a problem: ', error.message);
            } else {
                console.log('it is ok');
            }
        });
}   catch(Err ){
        console.error('Facebook.enviarMensajeFacebook:' + Err);
        throw Err;
    }
}

function* getFacebookProps(company, prop) {
	var resp = false;
	resp = yield consultaProperty(company, prop);
	return resp;
}
