import { firebaseDatabase } from "./firebase";
import { getFromSession } from './session';

export  function* obtenerTransferenciasConv(idConv) {

    try {
        const company = getFromSession("company_id"); //`company/${company}/conversations`
        let transferencias = [];

       yield firebaseDatabase.collection(`company/${company}/conversations`).doc(idConv).collection('transferencias')
            .orderBy('fecha', 'asc').get().then(docs => {
                docs.forEach(doc => {
                    transferencias.push(doc);
                });

            });
      
        return transferencias;
    } catch (error) {
        console.error('controller.transferencias.obtenerTransferenciasConv', error);
        throw error;
    }
}